import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  response: null,
};
export const errorLogSlice = createSlice({
  name: "errorLog",
  initialState,
  reducers: {
    setLog: (state, action) => {
        console.log(action.payload)
      state.response = action.payload;
    },
  },
});

export const { setLog } = errorLogSlice.actions;
export default errorLogSlice.reducer;
