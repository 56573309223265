import React from "react";
import Nav from "./Navbar";

type Props = {
    type: string;
    children: JSX.Element;
};

function PublishLayout(props: Props) {
    return (
        <main className="w-full h-screen max-w-[90rem] mx-auto max-h-[64rem] overflow-hidden font-poppins">
            <Nav type="" />
            <div className="flex flex-col relative w-full h-[calc(100%-5.625rem)] max-h-[58.375rem]">
                {props.children}
            </div>
        </main>
    );
}

export default PublishLayout;
