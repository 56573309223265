import React from 'react'

function PageNotFound() {
  return (
    <div className='bg-black text-white h-screen w-full flex items-center justify-center'>
      <div className='flex items-center space-x-6'>
      <span className='text-3xl border-r-white border-r align-middle px-6 py-3'>404</span>
      <span className='text-xl'>This page could not be found</span>
      </div>
    </div>
  )
}

export default PageNotFound