import React from "react";
import { Mcr, Scr, TextWImage, TrueFalse, Tabs, ClickToReveal } from "../index"

export const GetTemplate = new Map([
    ['mcr', (props: any) => <Mcr {...props} />],
    ['scr', (props: any) => <Scr {...props} />],
    ['true/false', (props: any) => <TrueFalse {...props} />],
    ['textwithimage', (props: any) => <TextWImage {...props} />],
    ['tabs', (props: any) => <Tabs {...props}/>],
    ['clicktoreveal', (props: any) => <ClickToReveal {...props}/>],
])