import React from "react";

type Props = {};

function UpgradePlan({}: Props) {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-5 rounded shadow-lg">
        <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 py-2">
          <h1 className="text-2xl font-bold mb-5 text-[#AB0273]">
            Choose Your Subscription
          </h1>
          <div className="flex space-x-4">
            <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-col">
              <h2 className="font-bold text-xl mb-2 text-[#AB0273]">Basic</h2>
              <p className="mb-4">Basic subscription details...</p>
              <button className="bg-[#AB0273] hover:bg-[#AB0273] text-white font-bold py-2 px-4 rounded mt-auto">
                Choose Basic
              </button>
            </div>
            <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-col">
              <h2 className="font-bold text-xl mb-2 text-[#AB0273]">Premium</h2>
              <p className="mb-4">Premium subscription details...</p>
              <button className="bg-[#AB0273] hover:bg-[#AB0273] text-white font-bold py-2 px-4 rounded mt-auto">
                Choose Premium
              </button>
            </div>
            <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-col">
              <h2 className="font-bold text-xl mb-2 text-[#AB0273]">
                Organization
              </h2>
              <p className="mb-4">Organization subscription details...</p>
              <button className="bg-[#AB0273] hover:bg-[#AB0273] text-white font-bold py-2 px-4 rounded mt-auto">
                Choose Organization
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UpgradePlan;
