import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  Draggable,
  Droppable,
  DroppableStateSnapshot,
} from "react-beautiful-dnd";

type Item = {
  id: string;
  content: string;
  droppableId: string;
};

type CompleteDroppableObject = {
  items: Item[];
  text: string;
  droppableBoxId: string;
};

type Data = {
  activeDroppable: {
    items: Item[];
  };
  completeDroppable: CompleteDroppableObject;
  type: string;
};

type Props = {
  data: Data & { completeDroppable: CompleteDroppableObject };
  onDataChange: (data: Data) => void;
};

function MultipleDrags({ data, onDataChange }: Props) {
  const [newItemContent, setNewItemContent] = useState<string>("");
  const [hoveredIndex, setHoveredIndex] = useState<boolean>(false);
  const [indicationBox, setIndicationBox] = useState<string>("");
  const [selectedItems, setSelectedItems] = useState<Item[]>();

  useEffect(() => {
    console.log("data", data);
  }, [data]);

  const handleAddItem = () => {
    if (newItemContent.trim() === "") return;

    const newItem: Item = {
      id: uuidv4(),
      content: newItemContent,
      droppableId: "",
    };

    const newData: Data = {
      ...data,
      activeDroppable: { items: [...data.activeDroppable.items, newItem] },
    };

    onDataChange(newData);
    setNewItemContent("");
  };

  function handleDeleteItem(id: string) {
    const updatedItems = data.activeDroppable.items.filter(
      (item) => item.id !== id
    );

    const updatedData: Data = {
      ...data,
      activeDroppable: { items: updatedItems },
    };

    onDataChange(updatedData);
  }

  function handleDroppableText(newText: string) {
    onDataChange({
      ...data,
      completeDroppable: { ...data.completeDroppable, text: newText },
    });
  }

  function handleClickOnDropBox(id: string) {
    setIndicationBox(id);
  }

  function handleItemSelect(item: Item) {
    if (indicationBox) {
      const updatedActiveDroppablesItems = data.activeDroppable.items.map(
        (itm) =>
          itm.id === item.id ? { ...itm, droppableId: indicationBox } : itm
      );

      onDataChange({
        ...data,
        activeDroppable: { items: updatedActiveDroppablesItems },
        completeDroppable: {
          ...data.completeDroppable,
          droppableBoxId: "droppableBox",
        },
      });
    }
  }

  const handleMouseEnter = (value: boolean) => {
    setHoveredIndex(value);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(false);
  };

  return (
    <>
      <div className="flex items-center justify-between my-6">
        <input
          type="text"
          value={newItemContent}
          onChange={(e) => setNewItemContent(e.target.value)}
          placeholder="Enter your items..."
          className="flex-1 mr-2 p-3 bg-gray-300 text-black placeholder-gray-600 border border-gray-700 rounded-md"
        />
        <button
          onClick={handleAddItem}
          className="px-3 py-2 bg-[#AB0273] border border-[#AB0273]/30 rounded-md text-white"
        >
          Add Item +
        </button>
      </div>

      <div className="flex w-full justify-between bg-gray-200 border rounded-md">
            <div
              className={`w-[47.5%] `}
            >
              {data?.activeDroppable?.items?.map((item, index) => (
                    <div
                      onClick={() => handleItemSelect(item)}
                      className={`bg-[#E0CBD9] flex justify-between items-center m-2 p-4 min-h-[50px] ${
                        item.droppableId && "!bg-[#AB0273] text-white"
                      }`}
                    >
                      <span>{item.content}</span>
                      <button onClick={() => handleDeleteItem(item.id)}>
                        X
                      </button>
                    </div>
              ))}
            </div>

            <div
              className={`w-[47.5%] m-2
              }`}
            >
              <div className="flex flex-col space-y-2 ">
                <input
                  type="text"
                  placeholder="Enter your text here..."
                  value={data.completeDroppable.text}
                  onChange={(e) => handleDroppableText(e.target.value)}
                  className="bg-transparent border border-black placeholder-black w-full p-1"
                />
                <div
                  onMouseEnter={() => handleMouseEnter(true)}
                  onMouseLeave={handleMouseLeave}
                  onClick={() => handleClickOnDropBox("droppableBox")}
                  className={`flex flex-col space-y-2`}
                >
                  {data.completeDroppable.droppableBoxId === "droppableBox" ? (
                    <div className="border border-slate-600 p-1 min-h-[50px]">
                      <span className=" mt-1 ml-1">Selected Items</span>
                      <div className=" flex flex-col space-y-1">
                        {data.activeDroppable.items.map((item, idx) =>
                          item.droppableId === "droppableBox" ? (
                            <span className="p-4 min-h-[50px] bg-[#E0CBD9]">
                              {item.content}
                            </span>
                          ) : null
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className="text-gray-600 border border-slate-600 p-1 min-h-[50px]">
                      Click here, and then click on the item you want to drop
                      here
                    </div>
                  )}

                  {data?.completeDroppable?.items?.map((item, index) => (
                    <span className="bg-[#E0CBD9] flex justify-between items-center p-4 mb-1 min-h-[50px] w-full">
                      {item.content}
                    </span>
                  ))}
                </div>
              </div>
            </div>
      </div>
    </>
  );
}

export default MultipleDrags;
