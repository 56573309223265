import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    id: -1,
}
export const widgetSlice = createSlice({
    name: "widgt",
    initialState,
    reducers: {
        setWidgetId: (state, action) => {
            state.id = action.payload
        },
    }
})

export const {setWidgetId} = widgetSlice.actions
export default widgetSlice.reducer