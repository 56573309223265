import React, { useState } from "react";
import { ChevronRight } from "@carbon/icons-react";
import { ChevronLeft } from "@carbon/icons-react";
import { useSelector } from "react-redux";

type Props = {
  data: any;
  title: string;
  handleSelectAircraft: (aircraft: any) => void;
};

export function SelectAircraft({ data, handleSelectAircraft, title }: Props) {
    console.log(data);
    
    const { themeColor, headingFont, bodyFont } = useSelector((state: any) => state.programSettings);

  //   Code for PAGINATION : -->>
  //   const aircraftsPerPage = 5;

  //   const [currentPage, setCurrentPage] = useState(1);

  //   const startIndex = (currentPage - 1) * aircraftsPerPage;
  //   const endIndex = startIndex + aircraftsPerPage;

  //   // Get the users for the current page
  //   const displayedAircrafts = aircrafts.slice(startIndex, endIndex);

  //   const goToNextPage = () => {
  //     setCurrentPage((prevPage) => prevPage + 1);
  //   };

  //   const goToPreviousPage = () => {
  //     setCurrentPage((prevPage) => Math.max(prevPage - 1, 1)); // Ensure not to go below page 1
  //   };

  return (
    <div className={`w-full h-full flex flex-col space-y-[3rem] md:space-y-[3.4rem] lg:space-y-[5rem] ${bodyFont && `font-${bodyFont}`}`}>
      <div className={`text-[#FFFFFF] text-[1.7rem] md:text-[2rem] lg:text-[2.5rem] font-semibold border-l-4 md:border-l-[7px] pl-[1rem] md:pl-[1.5rem] leading-normal ${headingFont && `font-${headingFont}`}`}>
        {title}
      </div>

      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-[2.5rem] gap-y-[3rem] lg:gap-y-[6.25rem] lg:gap-x-[8.13rem] pl-0 lg:pl-[1.5rem]">
        {data.map((aircraft: any) => (
          <AircraftCard key={aircraft._id} themeColor={themeColor} aircraft={aircraft} handleSelect={() => handleSelectAircraft(aircraft)} />
        ))}
      </div>
    </div>
  );
}

const AircraftCard: React.FC<{ aircraft: any; themeColor: any; handleSelect: () => void }> = ({ aircraft, themeColor, handleSelect }) => {
  return (
    <button onClick={() => {handleSelect()}} className=" flex flex-col">
      <div className="w-full max-w-[14.375rem] h-[14.375rem] flex-shrink-0 overflow-hidden">
        <img
          src={aircraft.file?.url ?? ""}
          alt={`Profile of ${aircraft.name}`}
          className="w-full h-full object-cover flex"
        />
      </div>
      <div
        style={{backgroundColor: themeColor, opacity: 0.8}}
        className={`flex w-full max-w-[14.375rem] h-[3.75rem] p-[0.625rem] justify-center items-center flex-shrink-0 text-[#FFFFFF] text-center font-semibold text-[1.25rem] ${!themeColor && "bg-[#2F0220]/80"}`}
      >
        {aircraft.name}
      </div>
    </button>
  );
};

{
  /* <div className="overflow-y-scroll no-scrollbar w-full h-full flex flex-col space-y-[3.69rem] pt-[1rem] md:pt-[2.12rem] px-[3.75rem] ">
  <div className="text-[#FFFFFF] text-[2rem] md:text-[2.5rem] lg:text-[3rem] font-semibold border-l-[6px] md:border-l-8 pl-[1.37rem]">
    {data.content.title}
  </div>

  <div className="grid grid-cols-12">
    <div className="relative col-span-12">
      <button
        className={`absolute top-1/2 left-0 text-[#FFFFFF] ${
          aircrafts.length > 5 ? "block" : "hidden"
        }`}
        onClick={goToPreviousPage}
        disabled={currentPage === 1}
      >
        <ChevronLeft size={40} className="font-bold" />
      </button>

      <div className="grid grid-cols-5 gap-x-[2.19rem]">
        {displayedAircrafts.map((aircraft: any) => (
          <AircraftCard key={aircraft._id} aircraft={aircraft} />
        ))}
      </div>

      <button
        className={`absolute top-1/2 right-0 text-[#FFFFFF] ${
          aircrafts.length > 5 ? "block" : "hidden"
        }`}
        onClick={goToNextPage}
        disabled={endIndex >= aircrafts.length}
      >
        <ChevronRight size={40} className="font-bold" />
      </button>
    </div>
  </div>
</div>; */
}
