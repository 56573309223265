import React, { useEffect, useRef, useState } from "react";
import Sidebar from "./Sidebar";
import NavProgram from "./NavProgram";
import SidebarProgram from "./SidebarProgram";
import Nav from "./Navbar";
import PreviewModal from "src/utils/PreviewModal";
import { Device } from "./Preview/Layout";
import Navbar from "./Preview/Navbar";
import { cn } from "./lib/utils";
import { cva } from "class-variance-authority";
import Preview from "./Preview";
import { useParams } from "react-router-dom";
import SettingsModal from "src/utils/SettingsModal";
import useFolder, { ROOT_FOLDER } from "src/hooks/useFolder";
import { useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { v4 as uuidV4 } from "uuid";
import { ListObjects, getObectURL, putObectURL } from "src/helpers/AWS_S3";
import axios from "axios";
import { useDispatch } from "react-redux";
import { setAddToChildFiles } from "src/features/fileFolder/fileFolderSlice";
import { SketchPicker } from "react-color";
import UploadDropdowns from "./UploadDropdowns";
import UploadButton from "src/utils/UploadButton";
import { setPageState } from "src/features/templatePageState/templatePageStateSlice";
import ProgramNav from "src/utils/ProgramNav";
import MideaLogo from "../assets/TemplateNavbar/Image.svg";
import ShareLogo from "../assets/TemplateNavbar/Share.svg";
import PreviewLogo from "../assets/TemplateNavbar/Play.svg";
import ExportLogo from "../assets/TemplateNavbar/UploadSimple.svg";
import SettingLogo from "../assets/TemplateNavbar/GearSix.svg";
import {
  setBodyFont,
  setHeadingFont,
  setLogoImageFile,
  setThemeColor,
  setHeadingColor,
  setThumbnailHeadingColor,
  setThumbnailTitleColor
} from "src/features/programSettings/programSettingsSlice";

type Props = {
  type: string;
  children: JSX.Element;
};

type ButtonType = {
  name: string;
  buttonIcon: React.ReactNode;
  navigate: boolean;
  navigateUrl?: string;
};

const deviceVariants = cva("grow flex flex-col", {
  variants: {
    device: {
      default: "max-h-[64rem] h-full max-w-[90rem] w-full",
      laptop: "max-h-[64rem] h-full max-w-[90rem] w-full",
      tabPortrait:
        "max-h-[62rem] h-full max-w-[50rem] w-screen border-[12px] border-gray-300 rounded-lg",
      tabLandscape:
        "max-h-[50rem] h-full max-w-[62rem] w-screen border-[12px] border-gray-300 rounded-lg",
      mobilePortrait:
        "max-h-[50rem] h-full max-w-[35rem] w-screen border-[12px] border-gray-300 rounded-lg",
      mobileLandscape:
        "max-h-[35rem] h-full max-w-[50rem] w-screen border-[12px] border-gray-300 rounded-lg",
    },
  },
  defaultVariants: {
    device: "default",
  },
});

function ProgramLayout(props: Props) {
  const [program, setProgram] = useState<any>();
  const [businessUnit, setBusinessUnit] = useState<any>();
  const [openPreviewModal, setOpenPreviewModal] = useState(false);
  const [openSettingsModal, setOpenSettingsModal] = useState(false);
  const [coursename, setCourseName] = useState<any>();
  const [deviceVariantState, setDeviceVariantState] =
    useState<Device>("default");
  const [currentColor, setCurrentColor] = useState<any>("#791F5B");
  const [currentHeadingColor, setCurrentHeadingColor] = useState<any>("#FFFFFF");
  const [currentThumbnailHeadingColor, setCurrentThumbnailHeadingColor] = useState<any>("#FFFFFF");
  const [currentThumbnailTitleColor, setCurrentThumbnailTitleColor] = useState<any>("#FFFFFF");
  const [headingFont, setHeadingFont] = useState<any>("");
  const [bodyFont, setBodyFont] = useState<any>("");
  const [logoFile, setLogoFile] = useState<any>(null);
  const [openColorPalette, setOpenColorPalette] = useState<any | null>(null);
  const [uploadingFiles, setUploadingFiles] = useState<any>([]);
  const [url, setUrl] = useState<any>("");
  const { accessToken } = useSelector((state: any) => state.accessToken);

  const { businessUnitId, sepmId, templateId, userId } = useParams();

  const [searchParams, setSearchParams] = useSearchParams();

  const [state, setState] = useState<any>(null);
  const { pageState } = useSelector((state: any) => state.templatePageState);

  const dispatch = useDispatch();

  const programButtonsArr: ButtonType[] = [
    {
      name: "Media Library",
      buttonIcon: <img src={MideaLogo} alt="Midea Library" />,
      navigate: true,
      navigateUrl: `/user/${userId}/businessunit/${businessUnitId}/sepm/${sepmId}/template/${templateId}/media?screen=${searchParams.get(
                    "screen"
                  )}&folderId=null`,
    },
    {
      name: "Share",
      buttonIcon: <img src={ShareLogo} alt="Share" />,
      navigate: true,
      navigateUrl: `/user/${userId}/businessunit/${businessUnitId}/sepm/${sepmId}/share`,
    },
    {
      name: "Preview",
      buttonIcon: <img src={PreviewLogo} alt="Publish" />,
      navigate: false,
    },
    {
      name: "Publish",
      buttonIcon: <img src={ExportLogo} alt="Publish" />,
      navigate: true,
      navigateUrl: `/user/${userId}/businessunit/${businessUnitId}/sepm/${sepmId}/publish`,
    },
    {
      name: "Settings",
      buttonIcon: <img src={SettingLogo} alt="Settings" />,
      navigate: false,
    },
  ];

  useEffect(() => {
    console.log(currentColor, currentHeadingColor, currentThumbnailHeadingColor, currentThumbnailTitleColor);
  }, [logoFile, currentColor, currentHeadingColor, currentThumbnailHeadingColor, currentThumbnailTitleColor, headingFont, bodyFont, program]);

  useEffect(() => {
    if (pageState) {
      if (pageState.uploadButtonFor === "LogoImageFile" && pageState.file) {
        setLogoFile(pageState.file);
      }
      if (!pageState.file) {
        setLogoFile(pageState.logoFile);
      }
      if (pageState.currentColor) setCurrentColor(pageState.currentColor);
      if (pageState.currentHeadingColor) setCurrentHeadingColor(pageState.currentHeadingColor);
      if (pageState.currentThumbnailHeadingColor) setCurrentThumbnailHeadingColor(pageState.currentThumbnailHeadingColor);
      if (pageState.currentThumbnailTitleColor) setCurrentThumbnailTitleColor(pageState.currentThumbnailTitleColor);
      if (pageState.headingFont) setHeadingFont(pageState.headingFont);
      if (pageState.bodyFont) setBodyFont(pageState.bodyFont);
      if (pageState.openSettingsModal) setOpenSettingsModal(pageState.openSettingsModal);

      setState(pageState);
      dispatch(setPageState(null));
    }
  }, [pageState]);

  useEffect(() => {
    console.log(program);
    if (program && !state) {
      setLogoFile(program?.logoImageFile || null)
      setCurrentColor(program?.color || "");
      setCurrentHeadingColor(program?.headingColor || "");
      setCurrentThumbnailHeadingColor(program?.thumbnailHeadingColor || "");
      setCurrentThumbnailTitleColor(program?.thumbnailTitleColor || "");
      setHeadingFont(program?.headingFont || "");
      setBodyFont(program?.bodyFont || "");

      dispatch(setThemeColor(program?.color || ""));
      dispatch(setHeadingColor(program?.headingColor || ""));
      dispatch(setThumbnailHeadingColor(program?.thumbnailHeadingColor || ""));
      dispatch(setThumbnailTitleColor(program?.thumbnailTitleColor || ""));
    }
  }, [program]);

  async function handleProgramSetting() {
    dispatch(setThemeColor(currentColor));
    dispatch(setHeadingColor(currentHeadingColor));
    dispatch(setThumbnailHeadingColor(currentThumbnailHeadingColor));
    dispatch(setThumbnailTitleColor(currentThumbnailTitleColor));
    
    await axios
      .post(
        `${process.env.REACT_APP_SERVER_BASE_URL}/api/v1/program/programsettings/${sepmId}`,
        {
          file: logoFile,
          color: currentColor,
          headingFont,
          bodyFont,
          headingColor: currentHeadingColor,
          thumbnailHeadingColor: currentThumbnailHeadingColor,
          thumbnailTitleColor: currentThumbnailTitleColor,
        },
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      )
      .then((res) => res.data)
      .then((response) => {
        setProgram(response.data);
      })
      .catch((err) => {
        console.error(err);
      });

    setOpenSettingsModal(false);
  }

  function handleOnColorChange(color: any, type: string) {
    if(type === "themeColor") setCurrentColor(color.hex);
    if(type === "headingColor") setCurrentHeadingColor(color.hex);
    if(type === "thumbnailHeadingColor") setCurrentThumbnailHeadingColor(color.hex);
    if(type === "thumbnailTitleColor") setCurrentThumbnailTitleColor(color.hex);
  }

  const sketchPickerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (sketchPickerRef.current && !sketchPickerRef.current.contains(event.target as Node)) {
        setOpenColorPalette(null);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [sketchPickerRef]);

  return (
    <main className="relative w-full h-screen mx-auto overflow-hidden font-poppins">
      <Nav type="" />
      <div className="relative flex flex-col w-full h-[calc(100%-5.625rem)] ">
        <ProgramNav
          type="Sepm"
          buttonsArray={programButtonsArr}
          handlePreviewModal={(open) => setOpenPreviewModal(open)}
          handleSettingsModal={(open) => {
            setOpenSettingsModal(open);
          }}
          handleProgram={(program) => setProgram(program)}
        />
        <section className="flex w-full h-[calc(100%-4.375rem)] ">
        {/* <section className="flex w-full h-[calc(100%-4.375rem)] max-h-[calc(58.375rem-4.375rem)]"> */}
          <SidebarProgram />
          <div className="grid grid-cols-12 w-full h-full py-[1.125rem] px-[0.625rem] bg-[#EFEFEF]">
            {props.children}
            {/* {props.type === "courses" && <RightPanel />} */}
          </div>
        </section>
      </div>
      <PreviewModal
        onOpened={openPreviewModal}
        onClose={() => setOpenPreviewModal(false)}
        showCrossIcon={false}
      >
        <div className="flex flex-col items-center w-full mx-auto h-full overflow-hidden">
          <Navbar
            onHandleDevice={(device) => setDeviceVariantState(device)}
            programName={program?.title}
            handleClose={() => setOpenPreviewModal(false)}
          />
          <div className="grow w-full flex justify-center h-[calc(100%-3.5rem)] ">
            {deviceVariantState !== "default" &&
            deviceVariantState !== "laptop" ? (
              <iframe
                className={cn(deviceVariants({ device: deviceVariantState }))}
                title="preview section"
                src={`${process.env.REACT_APP_CLIENT_BASE_URL}/user/${userId}/businessunit/${businessUnitId}/sepm/${sepmId}/preview`}
              ></iframe>
            ) : (
              <Preview device={deviceVariantState} />
            )}
          </div>
        </div>
      </PreviewModal>

      <SettingsModal
        onOpened={openSettingsModal}
        onClose={() => setOpenSettingsModal(false)}
        showCrossIcon={true}
      >
        <div className="flex flex-col w-full max-w-[90rem] h-screen overflow-hidden ">
          <span className="m-4 font-semibold text-[#4e4e4e]">
            Course Settings
          </span>
          <div className="h-[0.0625rem] w-full bg-gray-300"></div>
          <div className="w-[70%] h-full flex flex-col mx-auto items-center ">
            <span className="w-full flex justify-start font-semibold mt-8 mb-4 px-2 text-[1.5rem]">
              {program?.title}
            </span>
            <div className="grid grid-cols-2 w-full justify-start font-normal my-4 px-2">
              <span className="col-span-1">Logo</span>
              <span className="col-span-1 flex items-start space-x-5">
                <UploadButton
                  name="LogoImageFile"
                  type="image"
                  pageState={{
                    // ...state,
                    openSettingsModal,
                    uploadButtonFor: "LogoImageFile",
                    logoFile,
                    currentColor,
                    currentHeadingColor,
                    currentThumbnailHeadingColor,
                    currentThumbnailTitleColor,
                    headingFont,
                    bodyFont
                  }}
                  handleUploadFromDevice={(file) => {
                    setLogoFile(file);
                  }}
                />
                <UploadDropdowns
                  name="LogoImageFile"
                  type="image"
                  pageState={{
                    // ...state,
                    openSettingsModal,
                    uploadButtonFor: "LogoImageFile",
                    logoFile,
                    currentColor,
                    currentHeadingColor,
                    currentThumbnailHeadingColor,
                    currentThumbnailTitleColor,
                    headingFont,
                    bodyFont
                  }}
                  handleCropFile={(cropFile) => setLogoFile(cropFile)}
                  File={logoFile}
                />

              </span>
            </div>
            <span className="w-full flex justify-start my-2 px-2 pb-2 text-[1.2rem] border-b border-gray-300">
              Theme
            </span>
            <div className="w-full flex flex-col space-y-5 px-2 my-4">
              <div className="grid grid-cols-2 w-full justify-start font-normal ">
                <div className="col-span-1">Theme color</div>
                <div className="col-span-1 relative flex items-center justify-start space-x-5">
                  <div
                    style={{ backgroundColor: currentColor }}
                    className={`flex w-10 h-10 border border-black ${
                      !currentColor && "bg-[#791F5B]"
                    }`}
                  ></div>
                  <button
                    onClick={() => setOpenColorPalette("themeColor")}
                  >
                    +
                  </button>
                  {openColorPalette === "themeColor" && (
                    <div ref={sketchPickerRef} className="absolute top-0 left-[80px]">
                      <SketchPicker
                        color={currentColor}
                        onChangeComplete={(color) => handleOnColorChange(color, "themeColor")}
                      />
                    </div>
                  )}
                  <button onClick={() => setCurrentColor("")} className=" bg-[#791F5B] p-2 rounded-lg text-[#FFFFFF] border border-black">default color</button>

                  {/* <div
                    className={`flex w-[24px] h-[24px] justify-center items-center ${
                      color === "#AB0273" &&
                      "border-2 border-[#AB0273]  rounded-full"
                    }`}
                  >
                    <input
                      className="hidden"
                      type="radio"
                      name="import-color"
                      id="purple"
                      value="purple"
                      checked={color === "#AB0273"}
                      onChange={(e) => setColor("#AB0273")}
                    />
                    <label
                      htmlFor="purple"
                      className="flex bg-[#AB0273] rounded-[50%] w-[20px] h-[20px]"
                    ></label>
                  </div>
                  <div
                    className={`flex w-[24px] h-[24px] justify-center items-center ${
                      color === "#FFFF00" &&
                      "border-2 border-[#FFFF00]  rounded-full"
                    }`}
                  >
                    <input
                      className="hidden"
                      type="radio"
                      name="import-color"
                      id="yellow"
                      value="yellow"
                      checked={color === "#FFFF00"}
                      onChange={(e) => setColor("#FFFF00")}
                    />
                    <label
                      htmlFor="yellow"
                      className="flex bg-[#FFFF00] rounded-[50%] w-[20px] h-[20px]"
                    ></label>
                  </div>
                  <div
                    className={`flex w-[24px] h-[24px] justify-center items-center ${
                      color === "#FFA500" &&
                      "border-2 border-[#FFA500]  rounded-full"
                    }`}
                  >
                    <input
                      className="hidden"
                      type="radio"
                      name="import-color"
                      id="orange"
                      value="orange"
                      checked={color === "#FFA500"}
                      onChange={(e) => setColor("#FFA500")}
                    />
                    <label
                      htmlFor="orange"
                      className="flex bg-[#FFA500] rounded-[50%] w-[20px] h-[20px]"
                    ></label>
                  </div>
                  <div
                    className={`flex w-[24px] h-[24px] justify-center items-center ${
                      color === "#008000" &&
                      "border-2 border-[#008000]  rounded-full"
                    }`}
                  >
                    <input
                      className="hidden"
                      type="radio"
                      name="import-color"
                      id="dark-green"
                      value="darkGreen"
                      checked={color === "#008000"}
                      onChange={(e) => setColor("#008000")}
                    />
                    <label
                      htmlFor="dark-green"
                      className="flex bg-[#008000] rounded-[50%] w-[20px] h-[20px]"
                    ></label>
                  </div>
                  <div
                    className={`flex w-[24px] h-[24px] justify-center items-center ${
                      color === "#00A36C" &&
                      "border-2 border-[#00A36C]  rounded-full"
                    }`}
                  >
                    <input
                      className="hidden"
                      type="radio"
                      name="import-color"
                      id="light-green"
                      value="lightGreen"
                      checked={color === "#00A36C"}
                      onChange={(e) => setColor("#00A36C")}
                    />
                    <label
                      htmlFor="light-green"
                      className={`bg-[#00A36C] rounded-full w-[20px] h-[20px]`}
                    ></label>
                  </div>
                  <div
                    className={`flex w-[24px] h-[24px] justify-center items-center ${
                      color === "#FF0000" &&
                      "border-2 border-[#FF0000]  rounded-full"
                    }`}
                  >
                    <input
                      className="hidden"
                      type="radio"
                      name="import-color"
                      id="red"
                      value="red"
                      checked={color === "#FF0000"}
                      onChange={(e) => {
                        setColor("#FF0000");
                      }}
                    />
                    <label
                      htmlFor="red"
                      className={`flex bg-[#FF0000] rounded-[50%] w-[20px] h-[20px]`}
                    ></label>
                  </div> */}
                </div>
              </div>
              <div className="grid grid-cols-2 w-full justify-start font-normal ">
                <div className="col-span-1 font-">
                  <label htmlFor="heading-font">Heading Font</label>
                </div>
                <div className="col-span-1 flex items-start space-x-5">
                  <select
                    id="heading-font"
                    name="heading-font"
                    value={headingFont}
                    onChange={(e) => setHeadingFont(e.target.value)}
                  >
                    <option value="">Select Font</option>
                    <option value="inter">Inter</option>
                    <option value="sans-serif">sans-serif</option>
                    <option value="poppins">Poppins</option>
                    <option value="mono">Mono</option>
                  </select>
                  <div className="flex items-center space-x-2">
                    <span>default font:</span>
                    <button  onClick={() => setHeadingFont("poppins")} className="p-2 border rounded-lg">poppins</button>
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-2 w-full justify-start font-normal font">
                <div className="col-span-1">
                  <label htmlFor="body-font">Body Text Font</label>
                </div>
                <div className="col-span-1 flex items-start space-x-5">
                  <select
                    id="body-font"
                    name="body-font"
                    value={bodyFont}
                    onChange={(e) => setBodyFont(e.target.value)}
                  >
                    <option value="">Select Font</option>
                    <option value="inter">Inter</option>
                    <option value="sans-serif">sans-serif</option>
                    <option value="poppins">Poppins</option>
                    <option value="mono">Mono</option>
                  </select>
                  <div className="flex items-center space-x-2">
                    <span>default font:</span>
                    <button  onClick={() => setBodyFont("poppins")} className="p-2 border rounded-lg">poppins</button>
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-2 w-full justify-start font-normal ">
                <div className="col-span-1">Heading color</div>
                <div className="col-span-1 relative flex items-center justify-start space-x-5">
                  <div
                    style={{ backgroundColor: currentHeadingColor }}
                    className={`flex w-10 h-10 border border-black ${
                      !currentHeadingColor && "bg-[#FFFFFF]"
                    }`}
                  ></div>
                  <button
                    onClick={() => setOpenColorPalette("headingColor")}
                  >
                    +
                  </button>
                  {openColorPalette === "headingColor" && (
                    <div ref={sketchPickerRef} className="absolute top-0 left-[80px]">
                      <SketchPicker
                        color={currentHeadingColor}
                        onChangeComplete={(color) => handleOnColorChange(color, "headingColor")}
                      />
                    </div>
                  )}
                  <button onClick={() => setCurrentHeadingColor("")} className=" bg-[#FFFFFF] p-2 rounded-lg text-black border border-black">default color</button>
                </div>
              </div>
              <div className="grid grid-cols-2 w-full justify-start font-normal ">
                <div className="col-span-1">Thumbnail Heading color</div>
                <div className="col-span-1 relative flex items-center justify-start space-x-5">
                  <div
                    style={{ backgroundColor: currentThumbnailHeadingColor }}
                    className={`flex w-10 h-10 border border-black ${
                      !currentThumbnailHeadingColor && "bg-[#FFFFFF]"
                    }`}
                  ></div>
                  <button
                    onClick={() => setOpenColorPalette("thumbnailHeadingColor")}
                  >
                    +
                  </button>
                  {openColorPalette === "thumbnailHeadingColor" && (
                    <div ref={sketchPickerRef} className="absolute top-0 left-[80px]">
                      <SketchPicker
                        color={currentThumbnailHeadingColor}
                        onChangeComplete={(color) => handleOnColorChange(color, "thumbnailHeadingColor")}
                      />
                    </div>
                  )}
                  <button onClick={() => setCurrentThumbnailHeadingColor("")} className=" bg-[#FFFFFF] p-2 rounded-lg text-black border border-black">default color</button>
                </div>
              </div>
              <div className="grid grid-cols-2 w-full justify-start font-normal ">
                <div className="col-span-1">Thumbnail Title color</div>
                <div className="col-span-1 relative flex items-center justify-start space-x-5">
                  <div
                    style={{ backgroundColor: currentThumbnailTitleColor }}
                    className={`flex w-10 h-10 ${
                      !currentThumbnailTitleColor && "bg-[#FFFFFF] border border-black"
                    }`}
                  ></div>
                  <button
                    onClick={() => setOpenColorPalette("thumbnailTitleColor")}
                  >
                    +
                  </button>
                  {openColorPalette === "thumbnailTitleColor" && (
                    <div ref={sketchPickerRef} className="absolute top-0 left-[80px]">
                      <SketchPicker
                        color={currentThumbnailTitleColor}
                        onChangeComplete={(color) => handleOnColorChange(color, "thumbnailTitleColor")}
                      />
                    </div>
                  )}
                  <button onClick={() => setCurrentThumbnailTitleColor("")} className=" bg-[#FFFFFF] p-2 rounded-lg text-black border border-black">default color</button>
                </div>
              </div>
            </div>
            <button
              onClick={() => handleProgramSetting()}
              className="w-full flex justify-end font-semibold mt-8 mb-4 px-2 "
            >
              <span className="text-[1.2rem] text-[#FFFFFF] bg-[#AB0273] hover:bg-[#AB0273]/80 px-4 py-2 rounded-md">
                Save
              </span>
            </button>
          </div>
        </div>
      </SettingsModal>
    </main>
  );
}

export default ProgramLayout;
