import { TextVerticalAlignment } from "@carbon/icons-react";
import React from "react";
import { useDrag } from "react-dnd";
import { useSelector } from "react-redux";

function TextWithHeadingIcon() {
  const isLeftPanelCollapsed = useSelector(
    (state: any) => state.sidebar.leftPanelCollapsed
  );
  const [{ isDragging }, dragRef] = useDrag({
    type: "text-with-heading",
    item: {
      ID: 2,
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });
  return (
    <div className="flex items-center space-x-2" ref={dragRef}>
      <div>
        <TextVerticalAlignment size={"glyph"} />
      </div>
      {!isLeftPanelCollapsed && (
        <div className="border rounded-md p-2 text-xs flex flex-col space-y-1">
          <span className="text-lg font-bold">Heading</span>
          <span className="text-xs ">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book.
          </span>
        </div>
      )}
    </div>
  );
}

export default TextWithHeadingIcon;
