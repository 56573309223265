import { useEffect, useRef } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import {
  Module,
  setModules,
} from "src/features/trackedModules/trackedModulesSlice";
import { useDispatch } from "react-redux";
import { storeTrackingData } from "src/helpers/Tracking";

type Props = {
  moduleId: string;
  businessUnitId?: string;
  sepmId?: string;
  accessToken: string;
};

export const useTemplateTracking = ({
  moduleId,
  businessUnitId = "",
  sepmId = "",
  accessToken,
}: Props) => {
  const { modules } = useSelector((state: any) => state.trackedModules);
  const dispatch = useDispatch();
  useEffect(() => {
    const entryTime = Date.now();

    const sendTrackingData = async () => {
      const exitTime = Date.now();
      const timeSpent = Math.floor((exitTime - entryTime) / 1000);

      const updateElapsedTime = (
        currentModules: Module[],
        moduleId: string,
        timeSpent: number
      ): Module[] => {
        return currentModules.map((module: Module) => {
          if (module.id === moduleId) {
            return {
              ...module,
              elapsedTime: module.elapsedTime + timeSpent,
            };
          } else if (module.children) {
            const updatedChildren = updateElapsedTime(
              module.children,
              moduleId,
              timeSpent
            );
            return {
              ...module,
              children: updatedChildren,
            };
          }
          return module;
        });
      };

      try {
        const updateModules = updateElapsedTime(modules, moduleId, timeSpent);
        storeTrackingData(updateModules, businessUnitId, sepmId, accessToken);
        sessionStorage.setItem("modules", JSON.stringify(updateModules));
        dispatch(setModules(updateModules));
      } catch (err) {
        console.error("Tracking failed", err);
      }
    };

    // Send on visibility/tab change
    const handleVisibilityChange = () => {
      if (document.visibilityState === "hidden") sendTrackingData();
    };

    window.addEventListener("beforeunload", sendTrackingData);
    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      sendTrackingData(); // On unmount
      window.removeEventListener("beforeunload", sendTrackingData);
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);
};
