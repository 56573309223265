import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  fileFolder: {
    folderId: null,
    folder: null,
    file: null,
    childFolders: [],
    childFiles: [],
  },
} as {
    fileFolder: {
    folderId: string | null;
    folder: any| null;
    file: any | null;
    childFolders: any[];
    childFiles: any[];

}}
export const fileFolderSlice = createSlice({
  name: "fileFolder",
  initialState,
  reducers: {
    setSelectFolder: (state, action) => {
      return {
        fileFolder: {
          ...state.fileFolder,
          folder: action.payload.folder,
          folderId: action.payload.folderId,
        },
      };
    },
    setSelectFile: (state, action) => {
      // console.log("inside the select file: ", action.payload.file);
      return {
        fileFolder: {
          ...state.fileFolder,
          file: action.payload.file,
        },
      };
    },
    setUpdateFolder: (state, action) => {
      return {
        fileFolder: {
          ...state.fileFolder,
          folder: action.payload.folder,
        },
      };
    },
    setChildFolders: (state, action) => {
      return {
        fileFolder: {
          ...state.fileFolder,
          childFolders: action.payload.childFolders,
        },
      };
    },
    setAddToChildFolders: (state, action) => {
         const newChildFolders = [action.payload, ...state.fileFolder.childFolders]
         return {
            fileFolder: {
                ...state.fileFolder,
                childFolders: newChildFolders
            }
         }
    },
    setChildFiles: (state, action) => {
        return {
            fileFolder: {
                ...state.fileFolder,
                childFiles: action.payload.childFiles,
            },
        };
    },
    setAddToChildFiles: (state, action) => {
         const newChildFiles = [action.payload, ...state.fileFolder.childFiles]
         return {
            fileFolder: {
                ...state.fileFolder,
                childFiles: newChildFiles
            }
         }
    },
  },
});

export const {
  setSelectFolder,
  setSelectFile,
  setUpdateFolder,
  setChildFiles,
  setChildFolders,
  setAddToChildFolders,
  setAddToChildFiles,
} = fileFolderSlice.actions;
export default fileFolderSlice.reducer;
