import React from "react";
import { ChevronLeft } from "@carbon/icons-react";
import { useNavigate, useParams } from "react-router-dom";
import DesktopLogo from "../../assets/desktop.png";
import TabletLogo from "../../assets/tablet.png";
import SmartphoneLogo from "../../assets/smartphone.png";
import LaptopLogo from "../../assets/preview/laptop.svg";
import TabPortraitLogo from "../../assets/preview/tabPortrait.svg";
import TabLandscapeLogo from "../../assets/preview/tabLandscape.svg";
import MobilePortraitLogo from "../../assets/preview/mobilePortrait.svg";
import MobileLandscapeLogo from "../../assets/preview/mobileLandscape.svg";
import { Device } from "./Layout";
import { Boxes } from "lucide-react";
import { Box, Button } from "@chakra-ui/react";

type Props = {
  programName: string;
  onHandleDevice: (device: Device) => void;
  handleClose?: () => void;
};

function Navbar({ programName, onHandleDevice, handleClose }: Props) {
  const navigate = useNavigate();
  const { businessUnitId, sepmId, templateId, userId } = useParams();

  return (
    <Box className="w-full h-[2.75rem] md:h-[3rem] xl:h-[3.5rem] grid grid-cols-3 px-4 py-2">
      <div className="col-span-1 flex space-x-6 justify-start items-center">
        <button
          onClick={() => {
            // navigate(`/user/${userId}/businessunit/${businessUnitId}/sepm/${sepmId}`);
            if (handleClose) handleClose()
          }
          }
        >
          <ChevronLeft size={32} />
        </button>
        <div className="font-semibold text-xl">{programName}</div>
      </div>
      <div className="col-span-1 flex space-x-3 justify-center items-center">
        <button
          onClick={() => onHandleDevice("laptop")}
        >
          <img src={LaptopLogo} alt="laptop-icon" />
        </button>
        <button
          onClick={() => onHandleDevice("tabPortrait")}
        >
          <img src={TabPortraitLogo} alt="tabPortrait-icon" />
        </button>
        <button
          onClick={() => onHandleDevice("tabLandscape")}
        >
          <img src={TabLandscapeLogo} alt="tabLandscape-icon" />
        </button>
        <button
          onClick={() => onHandleDevice("mobilePortrait")}
        >
          <img src={MobilePortraitLogo} alt="mobilePortrait-icon" />
        </button>
        <button
          onClick={() => onHandleDevice("mobileLandscape")}
        >
          <img src={MobileLandscapeLogo} alt="mobileLandscape-icon" />
        </button>
      </div>
      <Box className="col-span-1 flex justify-end items-center">
        <button className="flex space-x-2 items-center bg-[#00875A] hover:bg-[#00875A]/80 text-white px-4 sm:py-1 xl:py-2 rounded-md">
          {/* <img src={ExportLogo} alt="logo" /> */}
          <span>Share</span>
        </button>
      </Box>
    </Box>
  );
}

export default Navbar;
