import React, { useEffect, useState } from "react";
import InputText from "src/utils/TextInput/InputText";
import TemplateHeading from "src/utils/TextInput/TemplateHeading";
import TextArea from "src/utils/TextInput/TextArea";
import debounce from "lodash/debounce";
import axios from "axios";
import { useDispatch } from "react-redux";
import { setSaveChanges } from "src/features/saveChanges/saveChangesSlice";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import UploadButton from "src/utils/UploadButton";
import UploadDropdowns from "../UploadDropdowns";
import { setSelectFile } from "src/features/fileFolder/fileFolderSlice";
import { setPageState } from "src/features/templatePageState/templatePageStateSlice";
import RichTextEditor from "src/utils/RichTE";
import { setLog } from "src/features/errorLog/errorLogSlice";

type Props = {};

function AcknowledgementScreen({}: Props) {
  const [title, setTitle] = useState<string>("");
  const [acknowledgementText, setAcknowledgementText] = useState<string>(); // I kept this as undefined initially because the RichTextEditor was not working with empty string
  const [button1Text, setButton1Text] = useState<string>("");
  const [button2Text, setButton2Text] = useState<string>("");
  const [copyrightText, setCopyrightText] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const [bgFile, setbgFile] = useState<any | null>(null);

  const [state, setState] = useState<any>(null);
  const { pageState } = useSelector((state: any) => state.templatePageState);

  const [content, setContent] = useState<any>();
  const [saving, setSaving] = useState(false);

  const { templateId } = useParams();

  const { accessToken } = useSelector((state: any) => state.accessToken);

  const dispatch = useDispatch();

  // const debouncedAutoSave = debounce((newContent: any) => {
  //   setSaving(true);
  //   dispatch(setSaveChanges(true));
  //   // Send a POST request to your server to save the content
  //   axios
  //     .post(
  //       `http://localhost:8080/api/v1/template/save/${templateId}`,
  //       { content: newContent },
  //       {
  //         headers: { Authorization: `Bearer ${accessToken}` },
  //       }
  //     )
  //     .then(() => {
  //       setSaving(false);
  //       dispatch(setSaveChanges(false));
  //     })
  //     .catch((error) => {
  //       console.error("Auto-save failed:", error);
  //       setSaving(false);
  //       dispatch(setSaveChanges(false));
  //     });
  // }, 1000);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      dispatch(setSaveChanges(true));
      setIsLoading(true);
      axios
        .post(
          `${process.env.REACT_APP_SERVER_BASE_URL}/api/v1/template/save/${templateId}`,
          { content },
          {
            headers: { Authorization: `Bearer ${accessToken}` },
          }
        )
        .then(() => {
          dispatch(setSaveChanges(false));
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Auto-save failed:", error);
          dispatch(setLog({ mode: "failure", message: error }));
          setSaving(false);
          dispatch(setSaveChanges(false));
          setIsLoading(false);
        });
    }, 5000);
    return () => clearTimeout(timeoutId);
  }, [content]);

  useEffect(() => {
    console.log("content changed", acknowledgementText)
    handleContentChange();
  }, [
    title,
    acknowledgementText,
    button1Text,
    button2Text,
    copyrightText,
    bgFile,
  ]);

  useEffect(() => {
    // console.log(pageState, state);
    if (pageState) {
      if (pageState.uploadButtonFor === "bgImageFile" && pageState.file) {
        setbgFile(pageState.file);
      }
      if (!pageState.file) {
        setbgFile(pageState.bgFile);
      }
      if (pageState.title) setTitle(pageState.title);
      if (pageState.acknowledgementText)
        setAcknowledgementText(pageState.acknowledgementText);
      if (pageState.button1Text) setButton1Text(pageState.button1Text);
      if (pageState.button2Text) setButton2Text(pageState.button2Text);
      if (pageState.copyrightText) setCopyrightText(pageState.copyrightText);

      setState(pageState);
      dispatch(setPageState(null));
    } else {
      getContent();
    }
  }, []);

  async function getContent() {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_BASE_URL}/api/v1/template/content/${templateId}`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );

      if (response && response.data.data) {
        console.log("template details:", response.data.data);
        setTitle(response.data.data.content?.title);
        console.log(response.data.data.content?.acknowledgementText);
        setAcknowledgementText(response.data.data.content?.acknowledgementText || "");
        setButton1Text(response.data.data.content?.button1Text);
        setButton2Text(response.data.data.content?.button2Text);
        setCopyrightText(response.data.data.content?.copyrightText);
        setbgFile(response.data.data.content?.bgfile);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handleContentChange = () => {
    const newContent = {
      title,
      acknowledgementText: acknowledgementText || "",
      button1Text,
      button2Text,
      copyrightText,
      bgfile: bgFile,
    };
    setContent(newContent);
    // Call the debounced auto-save function with the new content
    // debouncedAutoSave(newContent);
  };

  return (
    <div className=" ">
      <TemplateHeading title="Acknowledgement" />
      <div>
        <InputText
          value={title}
          onChange={(e) => {
            setTitle((prevState: string) => {
              if (prevState !== e.target.value) return e.target.value;
              return prevState;
            });
          }}
          placeholder="Enter Title"
          labelTitle="Title"
        />
      </div>

      <div className="grid grid-cols-4 mt-[1.25rem] mx-[0.49rem]">
        <div className="col-span-1 font-[600]  text-[1rem] text-[#242424] leading-normal">
          Background Image
        </div>
        <div className="col-span-3 flex space-x-[0.75rem] items-center">
          <UploadButton
            name="bgImageFile"
            // handleClick={(name) => {console.log(name); setUploadButtonFor(name)}}
            type="image"
            pageState={{
              // ...state,
              uploadButtonFor: "bgImageFile",
              bgFile,
              title,
              acknowledgementText,
              button1Text,
              button2Text,
              copyrightText,
            }}
            handleUploadFromDevice={(file) => {
              setbgFile(file);
              // console.log(file);
            }}
          />
          <UploadDropdowns
            name="bgImageFile"
            type="image"
            pageState={{
              // ...state,
              uploadButtonFor: "bgImageFile",
              File,
              bgFile,
              title,
              button1Text,
              button2Text,
              copyrightText,
            }}
            handleCropFile={(cropFile) => setbgFile(cropFile)}
            File={bgFile}
          />
        </div>
      </div>

      <div className="flex flex-col space-y-[1.25rem] px-[0.49rem] pt-[1.25rem]">
        {/* <TextArea
          value={acknowledgementText}
          onChange={(e) => {
            setAcknowledgementText((prevState: string) => {
              if (prevState !== e.target.value) return e.target.value;
              return prevState;
            });
          }}
          placeholder="Enter Acknowledgement Text"
          labelTitle="Add Acknowledgement Text"
        /> */}
        <div className="font-[600] text-[1rem] text-[#242424] leading-normal">Acknowledgement Text</div>
        {acknowledgementText !== undefined ? <RichTextEditor placeholder="Enter Acknowledgement Text" value={acknowledgementText} onChange={(content, contentLength) => {console.log(content); setAcknowledgementText(content) }} />: <span>loading..</span>}
      </div>
      <div className="mt-[1.25rem]">
        <InputText
          value={button1Text}
          onChange={(e) => {
            setButton1Text((prevState: string) => {
              if (prevState !== e.target.value) return e.target.value;
              return prevState;
            });
          }}
          placeholder="Enter Button1 Text"
          labelTitle="Button 1 Name"
        />
      </div>
      <div className="mt-[1.25rem]">
        <InputText
          value={button2Text}
          onChange={(e) => {
            setButton2Text((prevState: string) => {
              if (prevState !== e.target.value) return e.target.value;
              return prevState;
            });
          }}
          placeholder="Enter Button2 Text"
          labelTitle="Button 2 Name"
        />
      </div>
      <div className="mt-[1.25rem]">
        <TextArea
          value={copyrightText}
          onChange={(e) => {
            setCopyrightText((prevState: string) => {
              if (prevState !== e.target.value) return e.target.value;
              return prevState;
            });
          }}
          placeholder="Enter Copyright Text"
          labelTitle="Copyright Text"
        />
      </div>
    </div>
  );
}

export default AcknowledgementScreen;
