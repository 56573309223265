import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    leftPanelCollapsed: false,
    rightPanelCollapsed: false
}
export const sidebarSlice = createSlice({
    name: "sidebar",
    initialState,
    reducers: {
        setLeftPanelCollapsed: (state, action) => {
            state.leftPanelCollapsed = action.payload
        },
        setRightPanelCollapsed: (state, action) => {
            state.rightPanelCollapsed = action.payload
        },
    }
})

export const {setLeftPanelCollapsed, setRightPanelCollapsed} = sidebarSlice.actions
export default sidebarSlice.reducer