import React from 'react'
import SimpleText from './Text/SimpleText';
import TextWithHeading from './Text/TextWithHeading';
import TwoColText from './Text/TwoColText';
import ThreeColText from './Text/ThreeColText';
import TextWithHyperLink from './Text/TextWithHyperLink';

type Props = {
  block: {
    blockId: number,
    serialId: number
  }
}

function Widgets(props: Props) {

  const widgetMap = new Map([
    [1, <SimpleText id={props.block.blockId} />,],
    [2, <TextWithHeading id={props.block.blockId} />],
    [3, <TwoColText id={props.block.blockId} />],
    [4, <ThreeColText id={props.block.blockId} />],
    [5, <TextWithHyperLink id={props.block.blockId} />],
  ]);

  return (
    <>
      {
        widgetMap.get(props.block.serialId)
      }
    </>
  )
}

export default Widgets