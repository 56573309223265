import React, { useState } from 'react'
import RichTextEditor from '../../../utils/RichTextEditor'
import { addBlocks, delBlocks } from '../../../features/blocks/bockSlice';
import { useDispatch } from 'react-redux';
import { useDrop } from 'react-dnd';
import { v4 as uuidv4 } from "uuid";
import { setEditorState } from '../../../features/editorState/editorStateSlice';
import { EditorState } from 'draft-js';

type Props = {
  id: Number
}

function TextWithHyperLink(props: Props) {
  // const [blockId, setBlockId] = useState(null)
  const dispatch = useDispatch();
  const [{ isOver }, dropRef] = useDrop({
    accept: [
      "simple-text",
      "text-with-heading",
      "two-col-text",
      "three-col-text",
      "text-with-hyperlink",
    ],
    drop: (item: any, monitor: any) => {
      // setId(item.ID);
      // @ts-ignore: null is not assigned to defaultBlockTag as its type
      const { targetId, internalMonitor: {registry: {dropTargets}} } = monitor;
      // console.log(targetId, dropTargets, typeof dropTargets, monitor);
      let position = Array.from(dropTargets.keys()).indexOf(targetId)
      console.log("this is the position: ", position)
      const blockId = uuidv4()
      // setBlockId(blockId)
      dispatch(addBlocks({ serialId: item.ID, blockId, position }));
      // dispatch(setEditorState({blockId, editorState: EditorState.createEmpty() }))
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });

  function handleDelete() {
    dispatch(delBlocks(props.id));
  }
  const value = "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."

  return (
    <div className='relative grow group block '>
      <RichTextEditor value={value} onChange={(html, length) => console.log(html, length)} />
      <button
        onClick={() => handleDelete()}
        className="absolute top-0 right-0 bg-red-500 text-white p-2 border rounded-sm hidden group-hover:block"
      >
        Delete
      </button>
      <div ref={dropRef} className={`border ${isOver && "border-green-400 bg-green-200/20 h-40"}`}></div>
    </div>
  )
}

export default TextWithHyperLink