import React, { useState, useEffect, useRef, MutableRefObject } from 'react';
import { Resizable, ResizeCallback, NumberSize } from 're-resizable';
import { Direction } from 're-resizable/lib/resizer';
import Draggable, { DraggableData, DraggableEvent } from 'react-draggable';

type ToolProps = {
    tool: any;
    onDragStop?: (id: number, x: number, y: number) => void;
    onResizeStop?: (id: number, width: string, height: string) => void;
};

const Tool = ({ tool, onDragStop, onResizeStop }: ToolProps) => {
    const [selectedTool, setSelectedTool] = useState<number | null>(null);
    const [isResizing, setIsResizing] = useState<boolean>(false);

    const toolRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (toolRef.current && !toolRef.current.contains(event.target as Node)) {
                setSelectedTool(null);
                setIsResizing(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [setSelectedTool]);

    useEffect(() => {
    }, [selectedTool, isResizing])


    return (

        <>
            <div className='' onClick={() => selectedTool !== tool.id && setSelectedTool(tool.id)} >
                {selectedTool === tool.id ?
                    <Draggable
                        defaultPosition={{ x: tool.x, y: tool.y }}
                        onStop={(e, data) => {
                            setSelectedTool(null);
                            if (onDragStop) onDragStop(tool.id, data.x, data.y);
                        }}
                        disabled={isResizing}
                    >
                        <div ref={toolRef} className="max-w-max">
                            <Resizable
                                defaultSize={{ width: tool.width, height: tool.height }}
                                onResizeStart={() => setIsResizing(true)}
                                onResizeStop={(e, direction, ref, d) => {
                                    if (onResizeStop) onResizeStop(tool.id, ref.style.width, ref.style.height);
                                }}

                            >
                                <div className={`tool ${selectedTool === tool.id ? "border-primary border p-2" : ""}`} style={{ width: tool.width, height: tool.height }}>
                                    {tool.content}
                                </div>
                            </Resizable>
                        </div>
                    </Draggable>
                    :
                    <Draggable
                        defaultPosition={{ x: tool.x, y: tool.y }}
                        onStop={(e: DraggableEvent, data: DraggableData) => {
                            if (onDragStop) onDragStop(tool.id, data.x, data.y);
                            setSelectedTool(null);
                        }}
                    >
                        <div className="tool p-2" style={{ width: tool.width, height: tool.height }}>
                            {tool.content}
                        </div>
                    </Draggable>
                }
            </div>
        </>
    )
};

export default Tool;
