import React, { useEffect } from "react";

function LoginSuccess() {
  useEffect(() => {
    setTimeout(() => {
      window.close();
    }, 1000);
  }, []);
  return (
    <main className="h-screen w-screen flex justify-center items-center">
      <div>Thanks for logging in!</div>
    </main>
  );
}

export default LoginSuccess;
