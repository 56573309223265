import React from 'react'

type Props = {
  id: Number
}

function ThreeColText(props: Props) {
  return (
    <div>ThreeColText</div>
  )
}

export default ThreeColText