import React, { useState } from "react";
import { useDrop } from "react-dnd";
import { useDispatch } from "react-redux";
import { addBlocks, delBlocks } from "../../../features/blocks/bockSlice";
import { v4 as uuidv4 } from "uuid";

type Props = {
  id: Number
}

function TextWithHeading(props: Props) {
  const [title, setTitle] = useState("Heading");
  const [desc, setDesc] = useState(
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
  );
  const dispatch = useDispatch();
  const [{ isOver }, dropRef] = useDrop({
    accept: [
      "simple-text",
      "text-with-heading",
      "two-col-text",
      "three-col-text",
      "text-with-hyperlink",
    ],
    drop: (item: any, monitor) => {
      // setId(item.ID);
      // @ts-ignore: null is not assigned to defaultBlockTag as its type
      const { targetId, internalMonitor: {registry: {dropTargets}} } = monitor;
      // console.log(targetId, dropTargets, typeof dropTargets, monitor);
      let position = Array.from(dropTargets.keys()).indexOf(targetId)
      console.log("this is the position: ", position)
      dispatch(addBlocks({ serialId: item.ID, blockId: uuidv4(), position }));
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });

  function handleDelete() {
    dispatch(delBlocks(props.id));
  }

  return (
    <div className="relative grow group block ">
      <div>
        <input
          className="outline-none h-full w-full text-2xl font-bold p-2 px-4"
          placeholder="Heading"
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <textarea
          className="outline-none h-[200px] w-full py-1 px-4 resize-none"
          placeholder="Type something here..."
          value={desc}
          onChange={(e) => setDesc(e.target.value)}
        />
      </div>
      <button
        onClick={() => handleDelete()}
        className="absolute top-0 right-0 bg-red-500 text-white p-2 border rounded-sm hidden group-hover:block"
      >
        Delete
      </button>
      <div ref={dropRef} className={`border ${isOver && "border-green-400 bg-green-200/20 h-40"}`}></div>
    </div>
  );
}

export default TextWithHeading;
