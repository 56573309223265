import React, { useState } from 'react';
import ReactDOM from 'react-dom';

interface Option {
  text: string;
  correct: boolean;
}

interface Feedback {
  type: "correct-incorrect" | "any";
  correctMsg?: string;
  incorrectMsg?: string;
  commonMsg?: string;
  score?: number;
  attempts?: number;
}

interface MCQData {
  question: string;
  questionType: string;
  options: Option[];
  feedback: Feedback;
  score: number;
  attempts: number;
}

interface MCQProps {
  data: MCQData;
  onChange: (data: MCQData) => void;
}

interface ConstructorArgs {
  data: MCQData;
  api: any;
}

const MCQComponent: React.FC<MCQProps> = ({ data, onChange }) => {
  const [question, setQuestion] = useState(data.question);
  const [questionType, setQuestionType] = useState(data.questionType);
  const [options, setOptions] = useState(data.options);
  const [feedback, setFeedback] = useState(data.feedback);
  const [feedbackType, setFeedbackType] = useState(data.feedback.type);
  const [score, setScore] = useState(data.score);
  const [attempts, setAttempts] = useState(data.attempts);
  const uniqueId = React.useId(); // Unique identifier for each component instance

  const handleQuestionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newQuestion = e.target.value;
    setQuestion(newQuestion);
    onChange({ question: newQuestion, questionType, options, feedback, score, attempts });
  };

  const handleQuestionTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newQuestionType = e.target.value;
    setQuestionType(newQuestionType);
    onChange({ question, questionType: newQuestionType, options, feedback, score, attempts });
  };

  const handleOptionChange = (index: number, field: keyof Option, value: string | boolean) => {
    const newOptions = [...options];
    newOptions[index] = { ...newOptions[index], [field]: value };
    setOptions(newOptions);
    onChange({ question, questionType, options: newOptions, feedback, score, attempts });
  };

  const handleFeedbackChange = (field: keyof Feedback, value: string) => {
    const newFeedback = { ...feedback, [field]: value };
    setFeedback(newFeedback);
    if (field === "type") {
      setFeedbackType(value as Feedback["type"]);
    }
    onChange({ question, questionType, options, feedback: newFeedback, score, attempts });
  };

  const handleScoreChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newScore = Number(e.target.value);
    setScore(newScore);
    onChange({ question, questionType, options, feedback, score: newScore, attempts });
  };

  const handleAttemptsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newAttempts = Number(e.target.value);
    setAttempts(newAttempts);
    onChange({ question, questionType, options, feedback, score, attempts: newAttempts });
  };

  const addOption = () => {
    const newOptions = [...options, { text: '', correct: false }];
    setOptions(newOptions);
    onChange({ question, questionType, options: newOptions, feedback, score, attempts });
  };

  const renderOptionInput = (option: Option, index: number) => {
    if (questionType === 'single') {
      return (
        <input
          type="radio"
          name={`mcq-option-${uniqueId}`} // Unique name for each set of radio buttons
          checked={option.correct}
          onChange={() => {
            const newOptions = options.map((opt, i) => ({ ...opt, correct: i === index }));
            setOptions(newOptions);
            onChange({ question, questionType, options: newOptions, feedback, score, attempts });
          }}
        />
      );
    }
    return (
      <input
        type="checkbox"
        checked={option.correct}
        onChange={(e) => handleOptionChange(index, 'correct', e.target.checked)}
      />
    );
  };

  return (
    <div className="flex flex-col space-y-4 border border-[#ddd] p-3 mt-5 mb-8 hover:bg-[#f1f1f1] rounded-lg">
      <label className='flex flex-col space-y-1 text-[1rem] font-semibold text-[#242424]'>
        <span>Question Text:</span>
        <input
          className="px-2 py-1 border font-[400]  border-[#d7d7d7] focus:border-purple-600 hover:border-purple-600 rounded-md"
          type="text"
          placeholder="Type your question text here..."
          value={question}
          onChange={handleQuestionChange}
        />
      </label>
      <label className='grid grid-cols-2'>
        <span className='col-span-1 text-[1rem] font-semibold text-[#242424]'>Question Type</span>
        <select className='col-span-1 w-48 border border-[#d7d7d7] px-2 py-1 text-[#AB0273] font-semibold hover:border-purple-600 rounded-md' value={questionType} onChange={handleQuestionTypeChange}>
          <option className="text-[#AB0273] text-[1rem] font-semibold" value="single">Single Choice</option>
          <option className="text-[#AB0273] text-[1rem] font-semibold" value="multiple">Multiple Choice</option>
        </select>
      </label>
      <div className="flex flex-col space-y-2">
        <div className='flex justify-between text-[1rem] font-semibold text-[#242424]'>
          <span>Answer Choices</span>
          <span>Correct</span>
        </div>
        {options.map((option, index) => (
          <div className="flex items-center" key={index}>
            <input
              type="text"
              placeholder="Option text"
              className='grow px-2 py-1 mr-12 border border-[#d7d7d7] focus:border-purple-600 hover:border-purple-600 rounded-md'
              value={option.text}
              onChange={(e) => handleOptionChange(index, 'text', e.target.value)}
            />
            {renderOptionInput(option, index)}
          </div>
        ))}
      </div>
      <button className='flex justify-start border text-white border-[#d7d7d7] bg-[#AB0273] hover:border-purple-600 rounded-md w-48 p-2' type="button" onClick={addOption}>
        Add a new choice...
      </button>
      <label className='grid grid-cols-2'>
        <span className='col-span-1  text-[1rem] font-semibold text-[#242424]'>FEEDBACK</span>
        <select value={feedbackType} onChange={(e) => handleFeedbackChange("type", e.target.value)} className='col-span-1 w-48 border border-[#d7d7d7] px-2 py-1 text-[#AB0273] font-semibold hover:border-purple-600 rounded-md' >
          <option className="text-[#AB0273] text-[1rem] font-semibold" value="correct-incorrect">Correct/Incorrect</option>
          <option className="text-[#AB0273] text-[1rem] font-semibold" value="any">Any answer</option>
        </select>
      </label>
      {feedbackType === "correct-incorrect" &&
        <div className="mcq-feedback flex flex-col space-y-3">
          <label className='grid grid-cols-4'>
            <span className='col-span-1 text-[1rem] font-semibold text-[#242424]'>Correct:</span>
            <input
              type="text"
              className='col-span-3 px-2 py-1 mr-12 border border-[#d7d7d7] focus:border-purple-600 hover:border-purple-600 rounded-md'
              value={feedback.correctMsg}
              onChange={(e) => handleFeedbackChange('correctMsg', e.target.value)}
            />
          </label>
          <label className='grid grid-cols-4'>
            <span className='col-span-1 text-[1rem] font-semibold text-[#242424]'>Incorrect:</span>
            <input
              type="text"
              className='col-span-3 px-2 py-1 mr-12 border border-[#d7d7d7] focus:border-purple-600 hover:border-purple-600 rounded-md'
              value={feedback.incorrectMsg}
              onChange={(e) => handleFeedbackChange('incorrectMsg', e.target.value)}
            />
          </label>
        </div>
      }
      {feedbackType === "any" &&
        <div className="mcq-feedback flex flex-col space-y-3">
          <label className='grid grid-cols-4'>
            <input
              type="text"
              className='col-span-3 px-2 py-1 mr-12 border border-[#d7d7d7] focus:border-purple-600 hover:border-purple-600 rounded-md'
              value={feedback.commonMsg}
              onChange={(e) => handleFeedbackChange('commonMsg', e.target.value)}
            />
          </label>
        </div>
      }
      <label className='grid grid-cols-4'>
        <span className='col-span-1 text-[1rem] font-semibold text-[#242424]'>Score:</span>
        <input
          type="text"
          className='col-span-3 px-2 py-1 mr-12 border border-[#d7d7d7] focus:border-purple-600 hover:border-purple-600 rounded-md'
          value={feedback.score}
          onChange={handleScoreChange}
        />
      </label>
      <label className='grid grid-cols-4'>
        <span className='col-span-1 text-[1rem] font-semibold text-[#242424]'>Attempts:</span>
        <input
          type="text"
          className='col-span-3 px-2 py-1 mr-12 border border-[#d7d7d7] focus:border-purple-600 hover:border-purple-600 rounded-md'
          value={feedback.attempts}
          onChange={handleAttemptsChange}
        />
      </label>
    </div>
  );
};

class MCQTool {
  static get toolbox() {
    return {
      title: 'MCQ',
      icon: '<svg>...</svg>', // Add your SVG icon here
    };
  }

  api: any; // Define the type for api
  data: MCQData; // Define the type for data
  reactWrapper: HTMLDivElement | null;

  constructor({ data, api }: ConstructorArgs) {
    this.api = api;
    this.data = {
      question: data.question || '',
      questionType: data.questionType || 'single',
      options: data.options || [{ text: '', correct: false }],
      feedback: data.feedback || { correct: '', incorrect: '' },
      score: data.score || 0,
      attempts: data.attempts || 1,
    };
    this.reactWrapper = null;
  }


  render() {
    this.reactWrapper = document.createElement('div');
    ReactDOM.render(
      <MCQComponent data={this.data} onChange={this.handleDataChange} />,
      this.reactWrapper
    );
    return this.reactWrapper;
  }

  handleDataChange = (data: MCQData) => {
    this.data = data;
  };

  save(): MCQData {
    return this.data;
  }

  validate(savedData: MCQData): boolean {
    if (!savedData.question.trim()) {
      return false;
    }

    if (savedData.options.length === 0) {
      return false;
    }

    return true;
  }

  destroy() {
    if (this.reactWrapper) {
      ReactDOM.unmountComponentAtNode(this.reactWrapper);
    }
  }
}

export default MCQTool;
