import React, { useState, useEffect } from "react";
import { ChevronRight } from "@carbon/icons-react";
import { ChevronLeft } from "@carbon/icons-react";
import PreviewModal from "src/utils/PreviewModal";
import PdfViewer from "src/utils/PdfViewer";
// @ts-ignore
import EyeLogo from "../../../assets/preview/Eye.svg";
// @ts-ignore
import ZoomOut from "../../../assets/preview/ArrowsOutSimple.svg";
// @ts-ignore
import ZoomIn from "../../../assets/preview/ArrowsInSimple.svg";
import { useSelector } from "react-redux";
import { Box } from "@chakra-ui/react";
import Loader from "src/utils/Loader";
import { Module, setCurrentPath, setModules } from "src/features/trackedModules/trackedModulesSlice";
import { useDispatch } from "react-redux";
import { useTemplateTracking } from "src/hooks/useTemplateTracking";
import { storeTrackingData } from "src/helpers/Tracking";
import { useParams } from "react-router-dom";

type Props = {
  data: any;
  onNext: (nextTemplateId: string) => void;
  //   handleSelectMenu: (menu: string) => void;
  //   selectedAircraft: any;
};

export function EmergencyEquipScreen({ data, onNext }: Props) {
  const [openModal, setOpenModal] = useState(false);
  const [openImageDescModal, setOpenImageDescModal] = useState(false);
  const [openImageModal, setOpenImageModal] = useState(false);
  const [openDescModal, setOpenDescModal] = useState(false);
  const [filteredData, setFilteredData] = useState<any>();
  const [catagoryName, setCatagoryName] = useState<string>("");
  // const [equipmentName, setEquipmentName] = useState<string>("");
  // const [equipmentDescription, setEquipmentDescription] = useState<string>("");
  const [selectedEquipment, setSelectedEquipment] = useState<any>(null);

  const { themeColor, headingFont, bodyFont, headingColor, thumbnailHeadingColor, thumbnailTitleColor } = useSelector(
    (state: any) => state.programSettings
  );

   const { businessUnitId, sepmId } = useParams();

  const { modules, currentPath } = useSelector((state: any) => state.trackedModules);
  const { accessToken } = useSelector((state: any) => state.accessToken);

  const dispatch = useDispatch();

  useTemplateTracking({ moduleId: data._id, businessUnitId, sepmId, accessToken });

  useEffect(() => {
    console.log(data);
  }, []);

  useEffect(() => {
    console.log(modules, currentPath)
  }, [modules, currentPath])

  function handleSelectCatagory(catagory: any) {
    setCatagoryName(catagory.name);

    const filteredDocsByCatagory = data.content.documents?.filter(
      (data: any) => data.catagoryId === catagory.id
    );
    console.log(filteredDocsByCatagory);

    const filteredData = {
      ...data,
      content: { ...data.content, documents: filteredDocsByCatagory },
    };
    console.log(filteredData);

    setFilteredData(filteredData);

    setOpenModal(true);
  }

  function handleSelectEquipment(equipment: any) {
    console.log(equipment);
    setOpenImageDescModal(true);
    setSelectedEquipment(equipment);
    // setEquipmentName(equipment.title);
    // setEquipmentDescription(equipment.descriptionText);
  }

  const [isSmallImgLoading, setIsSmallImgLoading] = useState(true);
  const [isMediumImgLoading, setIsMediumImgLoading] = useState(true);
  const [isLargeImgLoading, setIsLargeImgLoading] = useState(true);

  useEffect(() => {
    const img = new Image();
    img.src = data.smallfile?.url ?? "";
    img.onload = () => setIsSmallImgLoading(false);
    img.onerror = () => setIsSmallImgLoading(false);
  }, [data]);

  useEffect(() => {
    const img = new Image();
    img.src = selectedEquipment?.mediumfile?.url ?? "";
    img.onload = () => setIsMediumImgLoading(false);
  }, [selectedEquipment?.mediumfile?.url]);

  useEffect(() => {
    const img = new Image();
    img.src = selectedEquipment?.largefile?.url ?? "";
    img.onload = () => setIsLargeImgLoading(false);
  }, [selectedEquipment?.largefile?.url]);

  const getModuleByPath = (modulesList: Module[], path: string[]): Module | null => {
    let currentModules = modulesList;
    let targetModule: Module | null = null;

    for (let id of path) {
      targetModule = currentModules.find(module => module.id === id) || null;

      if (!targetModule) return null; // Return null if any module in the path is missing

      currentModules = targetModule.children || []; // Move to the next level
    }

    return targetModule; // Return the final module
  };

  // Function to update session storage
  const updateSessionStorage = (updatedModules: Module[]) => {
    businessUnitId && sepmId && storeTrackingData(updatedModules, businessUnitId, sepmId, accessToken)
    sessionStorage.setItem("modules", JSON.stringify(updatedModules));
  };

  // Function to handle document opening
  const handleOpenDocument = (docId: string) => {
    // Find moduleId associated with the document
    const module = findModuleByDocumentId(modules, docId);

    if (module) {
      handleLastDepthClick(module.id);
    }
  };

  // Function to find module by document ID (assuming each module has a documentId field)
  const findModuleByDocumentId = (modules: Module[], docId: string): Module | null => {
    for (let module of modules) {
      if (module.id === docId) return module;
      if (module.children) {
        const found = findModuleByDocumentId(module.children, docId);
        if (found) return found;
      }
    }
    return null;
  };

  // Function to mark last depth items as completed and update parents
  const handleLastDepthClick = (moduleId: string) => {
    const updateModulesRecursive = (modules: Module[]): Module[] => {
      return modules.map((module) => {
        if (module.id === moduleId) {
          return { ...module, status: "green" };
        }
        if (module.children) {
          const updatedChildren = updateModulesRecursive(module.children);
          const allGreen = updatedChildren.every((child) => child.status === "green");
          const anyGreen = updatedChildren.some((child) => child.status === "green" || child.status === "orange");

          return {
            ...module,
            children: updatedChildren,
            status: allGreen ? "green" : anyGreen ? "orange" : "gray",
          };
        }
        return module;
      });
    };

    const updatedModules = updateModulesRecursive(modules);

    dispatch(setModules(updatedModules));
    updateSessionStorage(updatedModules);
  };

  return (
    <div className={`relative w-full h-[calc(100%-2.625rem)] md:h-[calc(100%-3.625rem)] lg:h-[calc(100%-4.625rem)] xl:h-[calc(100%-5.25rem)] pt-[1.5rem] md:pt-[1.88rem] pl-[1.7rem] md:pl-[2.5rem] lg:pl-[3.75rem] pr-[1.7rem] md:pr-[2.44rem] ${bodyFont && `font-${bodyFont}`}`}>
      <div className="w-full h-full flex flex-col space-y-[3rem] md:space-y-[3.4rem] lg:space-y-[5rem]">
        <div
          style={{ color: headingColor, borderColor: headingColor }}
          className={`text-[1.7rem] md:text-[2rem] lg:text-[2.5rem] font-semibold  border-l-4 md:border-l-[7px] pl-[1rem] md:pl-[1.5rem] leading-normal ${!headingColor && "text-[#FFFFFF] border-[#FFFFFF]"} ${headingFont && `font-${headingFont}`}`}>
          {data.title}
        </div>

        <div className="grow min-h-0 overflow-y-auto no-scrollbar">
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-[2rem] gap-y-[3rem] lg:gap-y-[5rem] lg:gap-x-[4.81rem] pl-[0rem] lg:pl-[1.5rem]">
            {data.content.catagories.length > 0 &&
              data.content.catagories.map((categoryData: any, idx: number) => {
                const currentModules = getModuleByPath(modules, currentPath)?.children;
                const moduleStatus = currentModules && currentModules.find((module: Module) => module.id === categoryData.id)?.status;
                return (
                  <EquipmentDocs
                    key={categoryData._id}
                    status={moduleStatus}
                    index={idx + 1}
                    themeColor={themeColor}
                    thumbnailHeadingColor={thumbnailHeadingColor}
                    thumbnailTitleColor={thumbnailTitleColor}
                    data={categoryData}
                    mainData={data}
                    handleClick={(categoryData: any) => {
                      handleSelectCatagory(categoryData);
                    }}
                  />
                )
              })}
          </div>
        </div>
      </div>

      <div>
        <PreviewModal
          onOpened={openModal}
          onClose={() => setOpenModal(false)}
          showCrossIcon={false}
        >
          <>
            <div
              style={{
                background: `linear-gradient(0deg, rgba(0, 0, 0, 0.70) 0%, rgba(0, 0, 0, 0.70) 100%), url(${data.content.bgfile?.url})`,
                backgroundPosition: "50%",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundColor: "lightgray",
                color: "#146793",
              }}
              className="w-full h-full flex flex-col"
            >
              {/* <div className="text-[#FFFFFF] text-[1.2rem] md:text-[1.6rem] lg:text-[2rem] font-semibold  border-l-4 md:border-l-[7px] pl-[0.8rem] md:pl-[1.3rem] leading-normal flex xs:flex-col sm:flex-col md:flex-row xs:items-start sm:items-start md:items-center md:space-x-1">
                <span className="">{data.title} - </span>
                <span className=""> {catagoryName}</span>
              </div> */}

              <Box className="bg-white w-full h-[2.75rem] md:h-[3rem] xl:h-[3.5rem] px-4 py-[2px] md:py-1 lg:py-1.5 ">
                <div className="h-full flex space-x-4 justify-start items-center">
                  <button
                    onClick={() => {
                      // navigate(`/user/${userId}/businessunit/${businessUnitId}/sepm/${sepmId}`);
                      const newArray = [...currentPath];
                      newArray.pop();
                      dispatch(setCurrentPath(newArray));
                      setOpenModal(false)
                    }
                    }
                    className="text-black"
                  >
                    <ChevronLeft size={32} />
                  </button>
                  <div className="text-black text-lg md:text-xl lg:text-2xl font-semibold">
                    {data.title} - {catagoryName}
                  </div>
                </div>
              </Box>

              <div className="flex-1 min-h-0 overflow-y-auto no-scrollbar p-4">
                <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 pl-0 lg:pl-[1.80rem] gap-y-[4rem] lg:gap-y-[4.88rem] gap-x-[2rem] md:gap-x-[3rem] lg:gap-x-[6.44rem]">
                  {filteredData &&
                    filteredData.content.documents.map((data: any) => {
                      const currentModules = getModuleByPath(modules, currentPath)?.children;
                      const status = currentModules && currentModules.find((module: Module) => module.id === data._id)?.status;

                      return (
                        <button
                          onClick={() => {
                            handleOpenDocument(data._id)
                            handleSelectEquipment(data);
                          }}
                          className="relative flex flex-col w-full max-w-[15.625rem] space-y-[0.5rem]"
                        >
                          <div className="w-full max-w-[15.625rem] h-[12.625rem] border-2 border-solid border-white/50 bg-black/20">
                            {isSmallImgLoading ? (
                              <div className="w-full h-full flex items-center justify-center bg-gray-200">
                                <Loader loading={isSmallImgLoading} />
                              </div>
                            ) : (
                              <img
                                src={data.smallfile?.url ?? ""}
                                alt={`Profile of ${data.title}`}
                                className="w-full h-full object-cover flex"
                                loading="lazy"
                              />
                            )}
                          </div>
                          <div className="flex w-full max-w-[15.625rem] justify-start flex-shrink-0 text-[#FFFFFF] font-semibold text-[1.25rem] leading-normal">
                            {data.title}
                          </div>
                          <span className={`absolute top-2 left-2 h-4 w-4 rounded-full ${status && status === "gray" ? "bg-gray-500" : status === "orange" ? "bg-orange-500" : "bg-green-500"}`}></span>
                        </button>
                      )
                    })}
                </div>
              </div>
              {selectedEquipment && openImageDescModal && (
                <div>
                  <PreviewModal
                    onOpened={openImageDescModal}
                    onClose={() => setOpenImageDescModal(false)}
                    showCrossIcon={false}
                  >
                    <>
                      <div
                        // style={{
                        //   background: `linear-gradient(0deg, rgba(0, 0, 0, 0.70) 0%, rgba(0, 0, 0, 0.70) 100%), url(${data.content.bgfile?.url})`,
                        //   backgroundPosition: "50%",
                        //   backgroundSize: "cover",
                        //   backgroundRepeat: "no-repeat",
                        //   backgroundColor: "lightgray",
                        //   color: "#146793",
                        // }}
                        className="w-full h-full flex flex-col"
                      >
                        {/* <div className="text-[#FFFFFF] text-[1.2rem] md:text-[1.6rem] lg:text-[2rem] font-semibold  border-l-4 md:border-l-[7px] pl-[0.8rem] md:pl-[1.3rem] leading-normal flex xs:flex-col sm:flex-col md:flex-row xs:items-start sm:items-start md:items-center md:space-x-1">
                          <span className="">{catagoryName} - </span>
                          <span className=""> {selectedEquipment.title}</span>
                        </div> */}

                        <Box className="bg-white w-full h-[2.75rem] md:h-[3rem] xl:h-[3.5rem] px-4 py-[2px] md:py-1 lg:py-1.5 ">
                          <div className="h-full flex space-x-4 justify-start items-center">
                            <button
                              onClick={() => {
                                // navigate(`/user/${userId}/businessunit/${businessUnitId}/sepm/${sepmId}`);
                                // const newArray = [...currentPath];
                                // newArray.pop();
                                // setCurrentPath(newArray);
                                setOpenImageDescModal(false)
                              }
                              }
                              className="text-black"
                            >
                              <ChevronLeft size={32} />
                            </button>
                            <div className="text-black text-lg md:text-xl lg:text-2xl font-semibold">
                              {catagoryName} - {selectedEquipment.title}
                            </div>
                          </div>
                        </Box>

                        <div className="grow flex flex-col w-full justify-center items-center overflow-hidden p-4 border-t border-gray-300">
                          <div className="w-full grow flex xs:flex-col sm:flex-col md:flex-row lg:pl-[1rem] sm:space-y-[0.5rem] md:space-x-[2rem] lg:space-x-[5.29rem] 2xl:space-x-[7.29rem]">
                            <div className="relative flex flex-col items-center justify-center w-full max-w-[29.89525rem] md:max-w-[29.89525rem] lg:max-w-[35.89525rem] grow rounded-[1.25rem] bg-black/80">
                              <span className="grow">
                                {isMediumImgLoading ? (
                                  <div className="w-full h-full flex items-center justify-center bg-gray-200">
                                    <Loader loading={isMediumImgLoading} />
                                  </div>
                                ) : (
                                  <img
                                    src={selectedEquipment.mediumfile?.url ?? ""}
                                    alt="equipment image"
                                    className="w-full h-full object-cover flex"
                                    loading="lazy"
                                  />
                                )}
                              </span>
                              <button
                                onClick={() => setOpenImageModal(true)}
                                className="absolute right-3 bottom-3 md:right-3 md:bottom-3 w-[3rem] h-[3rem] md:w-[3.5rem] md:h-[3.5rem] p-[0.63rem] flex border rounded-full"
                              >
                                <img src={ZoomIn} alt="largePic" />
                              </button>
                            </div>

                            <div className="relative grow flex justify-start items-start overflow-hidden p-4">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: selectedEquipment.descriptionText,
                                }}
                                className="rte w-full h-full overflow-y-auto text-[1rem] text-black max-w-[20.1875rem] lg:max-w-[35.1875rem] max-h-[17rem] md:max-h-[25rem] 2xl:max-h-[49.0625rem]"
                              />
                              <button
                                onClick={() => setOpenDescModal(true)}
                                className="absolute right-3 bottom-3 md:right-3 md:bottom-3 w-[3rem] h-[3rem] md:w-[3.5rem] md:h-[3.5rem] p-[0.63rem] flex border rounded-full bg-black/50"
                              >
                                <img src={ZoomIn} alt="largePic" />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  </PreviewModal>
                </div>
              )}
              {selectedEquipment && openImageModal && (
                <div>
                  <PreviewModal
                    onOpened={openImageModal}
                    onClose={() => setOpenImageModal(false)}
                    showCrossIcon={false}
                  >
                    <>
                      <div
                        // style={{
                        //   background: `linear-gradient(0deg, rgba(0, 0, 0, 0.70) 0%, rgba(0, 0, 0, 0.70) 100%), url(${data.content.bgfile?.url})`,
                        //   backgroundPosition: "50%",
                        //   backgroundSize: "cover",
                        //   backgroundRepeat: "no-repeat",
                        //   backgroundColor: "lightgray",
                        //   color: "#146793",
                        // }}
                        className="w-full h-full flex flex-col"
                      >
                        {/* <div className="text-[#FFFFFF] text-[1.2rem] md:text-[1.6rem] lg:text-[2rem] font-semibold  border-l-4 md:border-l-[7px] pl-[0.8rem] md:pl-[1.3rem] leading-normal flex xs:flex-col sm:flex-col md:flex-row xs:items-start sm:items-start md:items-center md:space-x-1">
                          <span className="">{catagoryName} - </span>
                          <span className=""> {selectedEquipment.title}</span>
                        </div> */}

                        <Box className="bg-white w-full h-[2.75rem] md:h-[3rem] xl:h-[3.5rem] px-4 py-[2px] md:py-1 lg:py-1.5 ">
                          <div className="h-full flex space-x-4 justify-start items-center">
                            <button
                              onClick={() => {
                                // navigate(`/user/${userId}/businessunit/${businessUnitId}/sepm/${sepmId}`);
                                setOpenImageModal(false)
                              }
                              }
                              className="text-black"
                            >
                              <ChevronLeft size={32} />
                            </button>
                            <div className="text-black text-lg md:text-xl lg:text-2xl font-semibold">
                              {catagoryName} - {selectedEquipment.title}
                            </div>
                          </div>
                        </Box>

                        {/* <div className="grow flex flex-col w-full justify-center items-center overflow-hidden p-4">
                          <div className="grow flex flex-col">
                            <div className="relative md:ml-[1rem] grow flex flex-col w-full max-w-[81.6875rem] rounded-[1.25rem] bg-black/80">
                              <span className="grow">
                                <img
                                  className="w-full h-full object-contain"
                                  src={selectedEquipment.largefile?.url ?? ""}
                                  alt="equipment large image"
                                />
                              </span>
                              <button
                                onClick={() => setOpenImageModal(false)}
                                className="absolute right-3 bottom-3 md:right-3 md:bottom-3 w-[3rem] h-[3rem] md:w-[3.5rem] md:h-[3.5rem] p-[0.63rem] flex border rounded-full"
                              >
                                <img src={ZoomOut} alt="largePic" />
                              </button>
                            </div>
                          </div>
                        </div> */}

                        <div className="grow flex flex-col w-full justify-center items-center overflow-hidden p-4">
                          <div className="w-full grow flex xs:flex-col sm:flex-col md:flex-row lg:pl-[1rem] md:space-x-[2rem] lg:space-x-[7.29rem]">
                            <div className="relative flex flex-col items-center justify-center w-full grow rounded-[1.25rem] bg-black/80">
                              <span className="grow">
                                {isLargeImgLoading ? (
                                  <div className="w-full h-full flex items-center justify-center bg-gray-200">
                                    <Loader loading={isLargeImgLoading} />
                                  </div>
                                ) : (
                                  <img
                                    src={selectedEquipment.largefile?.url ?? ""}
                                    alt="equipment image"
                                    className="w-full h-full object-cover flex"
                                    loading="lazy"
                                  />
                                )}
                              </span>
                              <button
                                onClick={() => setOpenImageModal(false)}
                                className="absolute right-3 bottom-3 md:right-3 md:bottom-3 w-[3rem] h-[3rem] md:w-[3.5rem] md:h-[3.5rem] p-[0.63rem] flex border rounded-full"
                              >
                                <img src={ZoomOut} alt="largePic" />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  </PreviewModal>
                </div>
              )}
              {selectedEquipment && openDescModal && (
                <div>
                  <PreviewModal
                    onOpened={openDescModal}
                    onClose={() => setOpenDescModal(false)}
                    showCrossIcon={false}
                  >
                    <>
                      <div
                        // style={{
                        //   background: `linear-gradient(0deg, rgba(0, 0, 0, 0.70) 0%, rgba(0, 0, 0, 0.70) 100%), url(${data.content.bgfile?.url})`,
                        //   backgroundPosition: "50%",
                        //   backgroundSize: "cover",
                        //   backgroundRepeat: "no-repeat",
                        //   backgroundColor: "lightgray",
                        //   color: "#146793",
                        // }}
                        className="w-full h-full flex flex-col"
                      >
                        {/* <div className="text-[#FFFFFF] text-[1.2rem] md:text-[1.6rem] lg:text-[2rem] font-semibold  border-l-4 md:border-l-[7px] pl-[0.8rem] md:pl-[1.3rem] leading-normal flex xs:flex-col sm:flex-col md:flex-row xs:items-start sm:items-start md:items-center md:space-x-1">
                          <span className="">{catagoryName} - </span>
                          <span className=""> {selectedEquipment.title}</span>
                        </div> */}

                        <Box className="bg-white w-full h-[2.75rem] md:h-[3rem] xl:h-[3.5rem] px-4 py-[2px] md:py-1 lg:py-1.5 ">
                          <div className="h-full flex space-x-4 justify-start items-center">
                            <button
                              onClick={() => {
                                // navigate(`/user/${userId}/businessunit/${businessUnitId}/sepm/${sepmId}`);
                                setOpenDescModal(false)
                              }
                              }
                              className="text-black"
                            >
                              <ChevronLeft size={32} />
                            </button>
                            <div className="text-black text-lg md:text-xl lg:text-2xl font-semibold">
                              {catagoryName} - {selectedEquipment.title}
                            </div>
                          </div>
                        </Box>

                        {/* <div className="grow flex flex-col w-full justify-center items-center overflow-hidden p-4">
                          <div className="grow flex flex-col">
                            <div className="relative md:ml-[1rem] grow flex flex-col w-full max-w-[81.6875rem] rounded-[1.25rem] bg-black/80">
                              <span className="grow">
                                <img
                                  className="w-full h-full object-contain"
                                  src={selectedEquipment.largefile?.url ?? ""}
                                  alt="equipment large image"
                                />
                              </span>
                              <button
                                onClick={() => setOpenImageModal(false)}
                                className="absolute right-3 bottom-3 md:right-3 md:bottom-3 w-[3rem] h-[3rem] md:w-[3.5rem] md:h-[3.5rem] p-[0.63rem] flex border rounded-full"
                              >
                                <img src={ZoomOut} alt="largePic" />
                              </button>
                            </div>
                          </div>
                        </div> */}

                        <div className="grow flex flex-col w-full justify-center items-center overflow-hidden p-4 border-t border-gray-300">
                          <div className="w-full grow flex  lg:pl-[1rem] md:space-x-[2rem] lg:space-x-[7.29rem]">
                            <div className="relative grow flex justify-center items-start overflow-hidden py-4 px-4 lg:px-10 rounded-[1.25rem] bg-white">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: selectedEquipment.descriptionText,
                                }}
                                className="rte w-full h-full overflow-y-auto text-[1rem] text-black max-h-[20rem] 2xl:max-h-[49.0625rem]"
                              />
                              <button
                                onClick={() => setOpenDescModal(false)}
                                className="absolute right-3 bottom-3 md:right-3 md:bottom-3 w-[3rem] h-[3rem] md:w-[3.5rem] md:h-[3.5rem] p-[0.63rem] flex border rounded-full bg-black/50"
                              >
                                <img src={ZoomOut} alt="largePic" />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  </PreviewModal>
                </div>
              )}
            </div>
          </>
        </PreviewModal>
      </div>
    </div>
  );
}

const EquipmentDocs: React.FC<{
  data: any;
  mainData: any;
  status?: string;
  index: number;
  themeColor: any;
  thumbnailHeadingColor: any,
  thumbnailTitleColor: any,
  handleClick?: (data: any) => void;
}> = ({ data, status, mainData, themeColor, thumbnailHeadingColor, thumbnailTitleColor, handleClick, index }) => {

  const [isLoading, setIsLoading] = useState(true);
  const { modules, currentPath } = useSelector((state: any) => state.trackedModules);
  const dispatch = useDispatch();

  useEffect(() => {
    const img = new Image();
    img.src = data.file?.url ?? "";
    img.onload = () => setIsLoading(false);
  }, [data.file?.url]);

  // Function to update session storage
  const updateSessionStorage = (updatedModules: Module[]) => {
    sessionStorage.setItem("modules", JSON.stringify(updatedModules));
  };

  const getModuleByPath = (modulesList: Module[], path: string[]): Module | null => {
    let currentModules = modulesList;
    let targetModule: Module | null = null;

    for (let id of path) {
      targetModule = currentModules.find(module => module.id === id) || null;

      if (!targetModule) return null; // Return null if any module in the path is missing

      currentModules = targetModule.children || []; // Move to the next level
    }

    return targetModule; // Return the final module
  };

  // Function to add children dynamically when clicking a module
  const handleModuleClick = (moduleId: string, childrenArray: Module[]) => {
    console.log("Clicked on module", moduleId, childrenArray);

    const findAndUpdateModule = (modulesList: Module[]): Module[] => {
      return modulesList.map((module: Module) => {
        if (module.id === moduleId) {
          dispatch(setCurrentPath([...currentPath, module.id]))
          // Update the module if found
          return {
            ...module,
            // children: module.children ? [...module.children, ...childrenArray] : childrenArray,
            children: childrenArray,
          };
        } else if (module.children && module.children.length > 0) {
          // Recursively search in children
          return {
            ...module,
            children: findAndUpdateModule(module.children),
          };
        }
        return module;
      });
    };

    const updatedModules = findAndUpdateModule(modules);

    dispatch(setModules(updatedModules));
    updateSessionStorage(updatedModules);
  };

  return (
    <button
      onClick={() => {
        const storedChildrenData = getModuleByPath(modules, [...currentPath, data.id])?.children

        const childrenArray = storedChildrenData ? storedChildrenData : mainData.content.documents.filter((doc: any) => data.id === doc.catagoryId).map((doc: any) => {
          return {
            id: doc._id,
            title: doc.title,
            status: "gray",
            type: "module",
          }
        });
        handleModuleClick(data.id, childrenArray);
        if (handleClick) handleClick(data);
      }}
      // style={{ borderColor: `${themeColor}CC` }}
      // className={`relative flex flex-col border-2 ${!themeColor && "border-[#2F0220]/80"}`}
      className={`relative flex flex-col`}
    >
      <div style={{ borderColor: `${themeColor}CC` }} className={`w-full max-w-[16.875rem] h-[9.375rem] border-2 border-b-0 ${!themeColor && "border-[#2F0220]/80"}`}>
        {isLoading ? (
          <div className="w-full h-full flex items-center justify-center bg-gray-200">
            <Loader loading={isLoading} />
          </div>
        ) : (
          <img
            src={data.file?.url ?? ""}
            alt={`Profile of ${data.name}`}
            className="w-full h-full object-cover flex"
            loading="lazy"
          />
        )}
      </div>
      <div style={{  backgroundColor: `${themeColor}CC`, color: thumbnailTitleColor, borderColor: `${themeColor}CC` }} className={`flex w-full max-w-[16.875rem] px-[0.3125rem] justify-start flex-shrink-0 ${!thumbnailTitleColor && "text-[#FFFFFF]"}  font-semibold text-[1.25rem] leading-normal ${!themeColor && "bg-[#2F0220]/80 border-[#2F0220]/80"} border-2 border-t-0`}>
        {data.name}
      </div>
      {/* <span className="absolute top-0 right-0 text-[rgba(255,255,255,0.3)] text-4xl font-extrabold">{index}</span> */}
      <span className={`absolute top-2 left-2 h-4 w-4 rounded-full ${status && status === "gray" ? "bg-gray-500" : status === "orange" ? "bg-orange-500" : "bg-green-500"}`}></span>
    </button>
  );
};
