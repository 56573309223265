import React, { useEffect, useState } from 'react'
import Player from './Player'
import axios from 'axios';
import { useParams, useSearchParams } from 'react-router-dom';
import { setAccessToken } from 'src/features/token/tokenSlice';
import { useSelector } from 'react-redux';
import UploadImage from '../UploadImage/UploadImage';
// @ts-ignore
import AlignLeft from '../../../assets/Course/align-left.svg';
// @ts-ignore
import AlignCenter from '../../../assets/Course/align-center.svg';
// @ts-ignore
import AlignRight from '../../../assets/Course/align-right.svg';
import { Switch } from 'src/components/ui/switch';
import { ComboboxDemo } from 'src/components/ui/comboBox';

type Props = {}

function PlayerSettings({ } : Props) {

    const [course, setCourse] = useState<any>();
    const [title, setTitle] = useState<any>('');
    const [isTitleOn, setIsTitleOn] = React.useState(false);

    const [logoFile, setLogoFile] = useState<any>();
    const [isLogoOn, setIsLogoOn] = React.useState(false);

    const [isPlaybackOn, setIsPlaybackOn] = React.useState(false);
    const [isNavigationOn, setIsNavigationOn] = React.useState(true);
    const [isTOCon, setIsTOCon] = React.useState(true);
    const [isPaginationOn, setIsPaginationOn] = React.useState(false);

    const [backgroundColor, setBackgroundColor] = useState<string>('#ffffff');
    const [primaryAccent, setPrimaryAccent] = useState<any>('#AB0273');
    const [secondaryAccent, setSecondaryAccent] = useState<any>('#d1d1d1');

    const [alignment, setAlignment] = useState<any>('');

    const [openDropdown, setOpenDropdown] = useState(false);

    const [topic, setTopic] = useState<any>()
    
    const { courseId } = useParams();
    const { accessToken } = useSelector((state: any) => state.accessToken);

    useEffect(() => {
        (async () => {
            try {
                const res = await axios.get(
                    `${process.env.REACT_APP_SERVER_BASE_URL}/api/v1/course1/get/${courseId}`,
                    {
                        headers: { Authorization: `Bearer ${accessToken}` },
                    }
                );
                if (res && res.data) {
                    console.log("check response", res.data.data.course);
                    const courseData = res.data.data.course;
                    setCourse(courseData);
                    setTitle(courseData.settings?.title || '');
                    setIsTitleOn(courseData.settings?.haveTitle || false);
                    setLogoFile(courseData.settings?.logoImageFile || null);
                    setIsLogoOn(courseData.settings?.haveLogo || false);
                    setIsPlaybackOn(courseData.settings?.havePlayback || false);
                    setIsNavigationOn(courseData.settings?.haveNavigation || false);
                    setIsTOCon(courseData.settings?.haveTOC || false);
                    setIsPaginationOn(courseData.settings?.havePagination || false);
                    setBackgroundColor(courseData.settings?.backgroundColor || '#ffffff');
                    setPrimaryAccent(courseData.settings?.primaryAccent || '#AB0273');
                    setSecondaryAccent(courseData.settings?.secondaryAccent || '#d1d1d1');
                    setAlignment(courseData.settings?.alignment || 'left');
                }
            } catch (error) {
                console.log(error);
            }
        })();
    }, [courseId, accessToken]);

    useEffect(() => {

    }, [title, logoFile, backgroundColor, primaryAccent, secondaryAccent, topic, alignment]);


    function handleToggle(property: string, isOn: boolean, value: any = null) {
        
        if (property === 'title') {
            setIsTitleOn(isOn);
            setTitle(isOn ? value: '')
        } else if (property === 'logo') {
            setIsLogoOn(isOn);
            setLogoFile(isOn ? value : null)
        } else if (property === 'playback' ) {
            setIsPlaybackOn(isOn);
        } else if (property === 'navigation' ) {
            setIsNavigationOn(isOn);
        } else if (property === 'toc' ) {
            setIsTOCon(isOn);
        } else if (property === 'pagination' ) {
            setIsPaginationOn(isOn);
        }

        axios
            .put(
                `${process.env.REACT_APP_SERVER_BASE_URL}/api/v1/course1/${courseId}`, 
                {
                    haveTitle: property === 'title' ? isOn : isTitleOn,
                    title: property === 'title' ? (isOn ? value : '') : title,
                    haveLogo: property === 'logo' ? isOn : isLogoOn,
                    logoImageFile: property === 'logo' ? (isOn ? value : null) : logoFile,
                    havePlayback: property === 'playback' ? isOn : isPlaybackOn,
                    haveNavigation: property === 'navigation' ? isOn : isNavigationOn,
                    haveTOC: property === 'toc' ? isOn : isTOCon,
                    havePagination: property === 'pagination' ? isOn : isPaginationOn,
                },
                {
                    headers: { Authorization: `Bearer ${accessToken}`},
                }
            )
            .then((res) => {
                console.log('Settings updated:', res.data);
            })
            .catch((error) => {
                console.error('Error updating settings:', error);
            });
    }

    function handleInputOrColorOrAlignment(property: string, value: string) {
        axios
            .put(
                `${process.env.REACT_APP_SERVER_BASE_URL}/api/v1/course1/${courseId}`, 
                {
                    haveTitle: isTitleOn,
                    title: property === 'title' ? value : title,
                    haveLogo: isLogoOn,
                    logoImageFile: property === 'logo' ? value : logoFile,
                    backgroundColor: property === 'bgColor' ? value : backgroundColor,
                    primaryAccent: property === 'priAccent' ? value : primaryAccent,
                    secondaryAccent: property === 'secAccent' ? value : secondaryAccent,
                    alignment: property === 'alignment' ? value : alignment,
                },
                {
                    headers: { Authorization: `Bearer ${accessToken}`},
                }
            )
            .then((res) => {
                console.log('Settings updated:', res.data);
            })
            .catch((error) => {
                console.error('Error updating settings:', error);
            });
    }

    // function handleColorChange(property: string, newColor: string) {
    //     if(property === 'bgColor') setBackgroundColor(newColor);
    //     if(property === 'priAccent') setPrimaryAccent(newColor);
    //     if(property === 'secAccent') setSecondaryAccent(newColor);
    //     axios
    //         .put(
    //             `${process.env.REACT_APP_SERVER_BASE_URL}/api/v1/course1/${courseId}`, 
    //             { 
    //                 backgroundColor: property === 'bgColor' ? newColor : backgroundColor,
    //                 primaryAccent: property === 'priAccent' ? newColor : primaryAccent,
    //                 secondaryAccent: property === 'secAccent' ? newColor : secondaryAccent,
    //             },
    //             {
    //                 headers: { Authorization: `Bearer ${accessToken}` },
    //             }
    //         )
    //         .then((res) => {
    //             console.log('Background color updated:', res.data);
    //         })
    //         .catch((error) => {
    //             console.error('Error updating background color:', error);
    //         });
    // };

    // function handleAllignment(property: string, align: string) {
    //     setAlignment(align);
    //     axios
    //         .put(
    //             `${process.env.REACT_APP_SERVER_BASE_URL}/api/v1/course1/${courseId}`, 
    //             { 
    //                 alignment: property === 'alignment' ? align : alignment,
    //             },
    //             {
    //                 headers: { Authorization: `Bearer ${accessToken}` },
    //             }
    //         )
    //         .then((res) => {
    //             console.log('Background color updated:', res.data);
    //         })
    //         .catch((error) => {
    //             console.error('Error updating background color:', error);
    //         });
    // };

  return (
    <main className='relative w-full max-w-[100vw] h-screen max-h-[100vh] mx-auto overflow-hidden font-poppins'>
        <div className='flex w-full h-[3rem] px-[1.25rem] py-[0.5625] items-center'>Player Settings</div>
        <section className='relative w-full h-[calc(100%-3rem)] flex'>
            <div className='w-full max-w-[calc(100%-18rem)] h-full bg-[#d1d1d1] flex items-center justify-center'>
                <div className=' containerplayer '>
                    <Player title={title} logoFile={logoFile} bgColor={backgroundColor} priAccent={primaryAccent} secAccent={secondaryAccent} topic={topic} isPlaybackOn={isPlaybackOn} isNavigationOn={isNavigationOn} isPaginationOn={isPaginationOn} isTOCon={isTOCon} alignment={alignment}  />
                </div>
            </div>
            {/* settings div */}
            <div className='w-[18rem] h-full bg-[#d1d1d1] py-[1.5rem] pr-[1.5rem] '>
                <div className='mr-[1.5rem] bg-[#f6f6f6] w-full h-full p-4 space-y-5 text-sm overflow-y-scroll '>

                    <div className='flex justify-between items-center '>
                        <span>Title</span>
                        <div>
                            <Switch checked={isTitleOn} onCheckedChange={() => handleToggle('title', !isTitleOn, title)} />
                        </div>
                    </div>

                    {isTitleOn && (
                        <div className='border-2 border-gray-400 hover:border-gray-600 p-2 rounded-md'>
                            <input 
                                type="text" 
                                onChange={(e) => {
                                    setTitle(e.target.value);
                                    handleInputOrColorOrAlignment('title', e.target.value);
                                }} 
                                value={title} placeholder='Enter Title here' 
                                className='border-none outline-none text-gray-600 ' 
                                disabled={!isTitleOn}
                            />
                        </div>
                    )}

                    <div className='flex justify-between items-start '>
                        <div className='flex space-x-3 items-start'>
                            <span>Logo</span>
                            {isLogoOn && (
                                <UploadImage
                                    name="playerSettingsLogo"
                                    type="image"
                                    image={logoFile}
                                    handleUploadFromDevice={(file) => {
                                        setLogoFile(file);
                                        // console.log(file);
                                        handleInputOrColorOrAlignment('logo', file);
                                    }}
                                />
                            )}
                        </div>
                        <div>
                            <Switch checked={isLogoOn} onCheckedChange={() => handleToggle('logo', !isLogoOn, logoFile)} />
                        </div>
                    </div>

                    <div className='flex flex-col space-y-3 '>
                        <span>Align</span>
                        <div className='flex justify-between items-center'>
                            <span onClick={() => {setAlignment('left'); handleInputOrColorOrAlignment('alignment', 'left')}}>
                                <img src={AlignLeft} alt="alignLeft" className='w-[2rem] h-[2rem]' />
                            </span>
                            <span onClick={() => {setAlignment('center'); handleInputOrColorOrAlignment('alignment', 'center')}}>
                                <img src={AlignCenter} alt="alignCenter" className='w-[2rem] h-[2rem]' />
                            </span>
                            <span onClick={() => {setAlignment('right'); handleInputOrColorOrAlignment('alignment', 'right')}}>
                                <img src={AlignRight} alt="alignRight" className='w-[2rem] h-[2rem]' />
                            </span>
                        </div>
                    </div>

                    <div className='flex justify-between '>
                        <span>Background color</span>
                        <input value={backgroundColor} onChange={(e) => {
                        setBackgroundColor(e.target.value);
                        handleInputOrColorOrAlignment('bgColor', e.target.value)
                        }}
                        type="color" name="" id="" className='w-[3rem] h-[2rem] rounded-md' 
                        />
                    </div>

                    <div className='flex justify-between items-center '>
                        <span>Preview template</span>
                        <ComboboxDemo data={course?.data} handleTopicChange={(topic) => setTopic(topic)} />
                    </div>
                    
                    <div className='h-[0.0625rem] bg-gray-600'></div>

                    <div className='flex justify-between items-center '>
                        <span>Playback controls</span>
                        <div>
                            <Switch checked={isPlaybackOn} onCheckedChange={() => handleToggle('playback', !isPlaybackOn) } />
                        </div>
                    </div>

                    <div className='flex justify-between items-center '>
                        <span>Navigation controls</span>
                        <div>
                            <Switch checked={isNavigationOn} onCheckedChange={() => handleToggle('navigation', !isNavigationOn)} />
                        </div>
                    </div>

                    <div className='flex justify-between items-center '>
                        <span>Table of contents</span>
                        <div>
                            <Switch checked={isTOCon} onCheckedChange={() => handleToggle('toc', !isTOCon)} />
                        </div>
                    </div>

                    <div className='flex justify-between items-center '>
                        <span>Pagination</span>
                        <div>
                            <Switch checked={isPaginationOn} onCheckedChange={() => handleToggle('pagination', !isPaginationOn)} />
                        </div>
                    </div>

                    <div className='flex justify-between '>
                        <span>Primary accent</span>
                        <input value={primaryAccent} onChange={(e) => {
                            setPrimaryAccent(e.target.value);
                            handleInputOrColorOrAlignment('priAccent', e.target.value)
                            }}
                            type="color" name="" id="" className='w-[3rem] h-[2rem] rounded-md' 
                        />
                    </div>

                    <div className='flex justify-between '>
                        <span>Secondary accent</span>
                        <input value={secondaryAccent} onChange={(e) => {
                            setSecondaryAccent(e.target.value);
                            handleInputOrColorOrAlignment('secAccent', e.target.value)
                            }}
                            type="color" name="" id="" className='w-[3rem] h-[2rem] rounded-md' 
                        />
                    </div>

                </div>
            </div>
        </section>
    </main>
  )
}

export default PlayerSettings
