import { createSlice } from "@reduxjs/toolkit";
import { BusinessUnitType } from "src/components/Sidebar";
import { json } from "stream/consumers";

const initialState = {
  businessUnits: [] as BusinessUnitType[],
};
export const businessUnitSlice = createSlice({
  name: "businessUnits",
  initialState,
  reducers: {
    setBusinessUnits: (state, action) => {
      state.businessUnits = action.payload;
    },
    setAddBusinessUnit: (state, action) => {
      state.businessUnits.splice(0, 0, action.payload);
    },
    setDelBusinessUnits: (state, action) => {
      state.businessUnits = state.businessUnits.filter(
        (businessUnit) => businessUnit._id !== action.payload
      );
    },
    setEditBusinessUnit: (state, action) => {
      const newBusinessUnit = state.businessUnits.map(
        (businessUnit) => {
          if (businessUnit._id === action.payload._id) {
            // console.log("checking...", JSON.parse(JSON.stringify(businessUnit)), action.payload)
            return action.payload
          }
          // console.log("this is not checking...")
          return businessUnit
        }
      );

      state.businessUnits = newBusinessUnit
    }
  },
});

export const { setBusinessUnits, setAddBusinessUnit, setDelBusinessUnits, setEditBusinessUnit } =
  businessUnitSlice.actions;
export default businessUnitSlice.reducer;
