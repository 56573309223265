import React, { useCallback, useEffect, useState } from "react";
import { SelectUser, SelectAircraft, MenuScreen } from "../Screens";

type Props = {
  data: any;
  AllTemplateData: any;
  handleChangeBackgroundImage: (imageUrl: string) => void;
  onNext: (templateId: string) => void;
  handleUserAircraftMenu: (user: any, aircraft: any, menu: any) => void;
  active: string;
  selectedUser: any;
  selectedAircraft: any;
};

export function UserAircraftMenu({
  data,
  AllTemplateData,
  handleChangeBackgroundImage,
  onNext,
  handleUserAircraftMenu,
  active,
  selectedUser,
  selectedAircraft,
}: Props) {
  const [userData, setUserData] = useState<Array<any>>([]);
  const [aircraftData, setAircraftData] = useState<Array<any>>([]);
  const [menuData, setMenuData] = useState<Array<any>>([]);

  const [selectedUserState, setSelectedUserState] = useState<any | null>(selectedUser);
  const [selectedAircraftState, setSelectedAircraftState] = useState<any | null>(selectedAircraft);
  const [selectedMenu, setSelectedMenu] = useState<any | null>(null);

  const [activeScreen, setActiveScreen] = useState<string>("user");

  useEffect(() => {
    console.log(data);
  }, []);

  useEffect(() => {
    setActiveScreen(active)
  }, [active])

  useEffect(() => {
    // const handleupdate = () =>
    console.log(selectedUserState, selectedAircraftState, selectedMenu);
    handleUserAircraftMenu(selectedUserState, selectedAircraftState, selectedMenu);
    if (selectedMenu) {
      handleNext()
    }
    if (activeScreen === "aircraft") {
      handleChangeBackgroundImage(data.content.aircraftbgFile?.url ?? "")
    }
    if (activeScreen === "menu") {
      console.log(selectedAircraftState)
      handleChangeBackgroundImage(selectedAircraftState.file?.url ?? "")
    }

    // return () => handleupdate();
  }, [selectedUserState, selectedAircraftState, selectedMenu]);

  // useCallback(() => {
  //   handleNext()
  // }, [selectedMenu])

  function handleNext() {
    // const currentId = data._id;
    // const temp = data.templatesId.indexOf(currentId);
    // onNext(data.templatesId[temp + 1]);
    onNext(selectedMenu._id);
  }

  return (
    <div className="w-full xs:h-[calc(100%-3.5rem)] sm:h-[calc(100%-4rem)] md:h-[calc(100%-4.6rem)] lg:h-[calc(100%-5.125rem)] xl:h-[calc(100%-5.625rem)] pt-[1.5rem] md:pt-[1.88rem] pl-[1.7rem] md:pl-[2.5rem] lg:pl-[3.75rem] pr-[1.7rem] md:pr-[2rem]">
      {activeScreen === "user" && <SelectUser
        title={data.content.userTitle}
        data={data.content.users}
        handleSelectUser={(user) => { setSelectedUserState(user); setActiveScreen("aircraft"); }}
      />}
      {activeScreen === "aircraft" && <SelectAircraft
        title={data.content.aircraftTitle}
        data={data.content.aircrafts}
        handleSelectAircraft={(aircraft) => { setSelectedAircraftState(aircraft); setActiveScreen("menu"); }}
      />}
      {activeScreen === "menu" && <MenuScreen
        data={AllTemplateData}
        selectedAircraft={selectedAircraftState}
        selectedUser={selectedUser}
        handleSelectMenu={(menu) => {
          console.log(menu);
          setSelectedMenu(menu);
        }}
      />}
    </div>
  );
}
