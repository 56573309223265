import React, { useState } from "react";
import Toolbar from "./Toolbar";
import Main from "./Main";
import Auth from "../Auth";

type Props = {
  children: React.ReactElement;
};

function Layout() {
  const [items, setItems] = useState<any>([]);
  const [searchResults, setSearchResults] = useState<Array<any>>([]);
  const [searchInput, setSearchInput] = useState("")

  const handleSearchResults = (searchResults: any[], searchInput: string) => {
    setSearchResults(searchResults);
    setSearchInput(searchInput)

    console.log(searchResults);
  };
  return (
    <Auth>
      <div className="flex flex-col w-full max-w-[90rem] mx-auto font-poppins">
        <Toolbar items={items} searchInput={searchInput} onHandleSearchResults={handleSearchResults} />
        <Main searchInput={searchInput} onHandleCheckedItems={(items) => setItems(items)} searchResults={searchResults} onHandleClickedSearchResult={() => {setSearchResults([]); setSearchInput("")}}/>
      </div>
    </Auth>
  );
}

export default Layout;

