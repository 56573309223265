import { CompositeDecorator, ContentBlock, ContentState, Editor, EditorState, Modifier, RichUtils, convertFromHTML, convertToRaw } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import React, { useState, useEffect, useRef, useCallback } from 'react'
import Immutable from "immutable";
// import { Modal } from '@mantine/core';
import { Link } from '@carbon/icons-react';
// import { ErrorResult } from '../types/Results';
// import ErrorIndicator from '../components/Indicators/ErrorIndicator';
// @ts-ignore
import { Controls } from "./RTE/controls"
// @ts-ignore
import FontSize from "./RTE/controls/FontSize";
// @ts-ignore
import defaultToolbar from "./RTE/config/defaultToolbar";
// @ts-ignore
import ModalHandler from "./RTE/event-handler/modals";
//@ts-ignore
import {  changeDepth,  handleNewLine,  blockRenderMap,  getCustomStyleMap,  extractInlineStyle,  getSelectedBlocksType,} from "draftjs-utils"; 

type Props = {
  onChange: (content: string, contentLength: number) => void;
  value?: string;
  placeholder?: string;
}

// Custom overrides for "code" style.


export function StyleButton({ active, label, onToggle, style }: { active: boolean; label: string; onToggle: (style: string) => void; style: string }) {

  return (
    <span className={`cursor-pointer px-1 border rounded-sm border-[#F1F1F1] hover:border-b-black/50 hover:border-r-black/50 ${active && "border-b-black/50 border-r-black/50 bg-white text-[#242424]"}`} onMouseDown={() => onToggle(style)}>
      {label}
    </span>
  );
}

const BLOCK_TYPES = [
  { label: 'H1', style: 'header-one' },
  { label: 'H2', style: 'header-two' },
  { label: 'H3', style: 'header-three' },
  { label: 'H4', style: 'header-four' },
  { label: 'H5', style: 'header-five' },
  { label: 'H6', style: 'header-six' },
  { label: 'Blockquote', style: 'blockquote' },
  { label: 'UL', style: 'unordered-list-item' },
  { label: 'OL', style: 'ordered-list-item' },
  { label: 'Code Block', style: 'code-block' },
];

type BlockStyleProps = {
  editorState: EditorState;
  onToggle: (style: string) => void;
};

const BlockStyleControls = (props: BlockStyleProps) => {
  const { editorState } = props;
  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();

  return (
    <div className="flex space-x-1">
      {BLOCK_TYPES.map((type) =>
        <StyleButton
          key={type.label}
          active={type.style === blockType}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
        />
      )}
    </div>
  );
};

var INLINE_STYLES = [
  { label: 'Bold', style: 'BOLD' },
  { label: 'Italic', style: 'ITALIC' },
  { label: 'Underline', style: 'UNDERLINE' },
  { label: 'Monospace', style: 'CODE' },
];

type InlineStyleProps = {
  editorState: EditorState;
  onToggle: (style: any) => void;
};

const InlineStyleControls = (props: InlineStyleProps) => {
  const currentStyle = props.editorState.getCurrentInlineStyle();

  return (
    <div className="flex space-x-1">
      {INLINE_STYLES.map((type) =>
        <StyleButton
          key={type.label}
          active={currentStyle.has(type.style)}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
        />
      )}
    </div>
  );
};

type ErrorResult = {
  error: Boolean;
  message: String;
  timestamp: Number;
};

export default function RichTextEditor({ onChange, value = "", placeholder }: Props) {

  const [error, setError] = useState<ErrorResult>();
  const [linkToggle, setLinkToggle] = useState(false)
  const [openCreateLinkModal, setOpenCreateLinkModal] = useState(false)
  const [linkUrl, setLinkUrl] = useState("")
  const [linkText, setLinkText] = useState("")
  const [selectedText, setSelectedText] = useState("")
  const [editorState, setEditorState] = useState<EditorState>(() => value ? EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(value).contentBlocks)) : EditorState.createEmpty());
  const editor = useRef<Editor>(null);
  // const [postHtmlContent, setPostHtmlContent] = useState<string>("")
  // const [postContentLength, setPostContentLength] = useState<number>(0)
  const [{ content, wordCount }, setContent] = useState({
    content: '',
    wordCount: 0
  });

  // useEffect(() => {
  //   focusEditor();
  // }, [])

  // useEffect(() => {
  //   console.log(value)
  //   setEditorState(() => value ? EditorState.moveFocusToEnd(EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(value).contentBlocks))) : EditorState.createEmpty())
  // }, [value])

  useEffect(() => {
    const selectionState = editorState.getSelection();
    const anchorKey = selectionState.getAnchorKey();
    const currentContent = editorState.getCurrentContent();
    const currentContentBlock = currentContent.getBlockForKey(anchorKey);
    const start = selectionState.getStartOffset();
    const end = selectionState.getEndOffset();
    const selectedText = currentContentBlock.getText().slice(start, end);
    setSelectedText(selectedText)
  }, [editorState.getSelection()])

  useEffect(() => {
    if (editorState.getCurrentContent().getPlainText().length > 5000) {
      setError({
        error: true,
        message: "Content length exceeded 5000 chars",
        timestamp: Date.now()
      })
      setEditorState(EditorState.undo(editorState))
    }
    const content = editorState.getCurrentContent();
    // setPostContentLength(content.getPlainText().length)
    // content to save to the db
    const contentToSave = JSON.stringify(convertToRaw(content));
    // setPostHtmlContent(`${stateToHTML(editorState.getCurrentContent())}`)
    console.log("state to html: ", `${stateToHTML(editorState.getCurrentContent())}`)
    if (`${stateToHTML(editorState.getCurrentContent(), {
      // @ts-ignore: null is not assigned to defaultBlockTag as its type
      defaultBlockTag: null,
    })}` !== '<br>')
      onChange(`${stateToHTML(editorState.getCurrentContent(), {
        // @ts-ignore: null is not assigned to defaultBlockTag as its type
        defaultBlockTag: "p",
      })}`, content.getPlainText().length);
    console.log("rendering...")
  }, [editorState])

  const setFormattedContent = useCallback(
    (text: string) => {
      let words = text.split('').filter(Boolean);
      if (words.length > 5000) {
        setContent({
          content: words.slice(0, 5000).join(' '),
          wordCount: words.length
        });
      } else {
        setContent({ content: text, wordCount: words.length });
      }
    },
    [setContent]
  );

  function focusEditor() {
    if (editor.current)
      editor.current.focus();
  }
  const styleMap = {
    CODE: {
      backgroundColor: 'rgba(0, 0, 0, 0.05)',
      fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
      fontSize: 16,
      padding: 2,
    },
  };

  // const blockRenderMap = Immutable.Map({
  //   'blockquote': {
  //     element: 'blockquote',
  //   },
  //   'header-one': {
  //     element: 'h1',
  //   },
  //   'header-two': {
  //     element: 'h2',
  //   },
  //   'header-three': {
  //     element: 'h3',
  //   },
  //   'header-four': {
  //     element: 'h4',
  //   },
  //   'header-five': {
  //     element: 'h5',
  //   },
  //   'header-six': {
  //     element: 'h6',
  //   },
  //   'code-block': {
  //     element: 'pre',
  //   },
  //   'bold': {
  //     element: 'strong',
  //   },
  //   'unstyled': {
  //     element: 'p',
  //   },
  // });


  function getBlockStyle(block: ContentBlock) {
    if (block.getType() == 'blockquote') {
      return 'border-l-[5px] border-gray-300 text-[#666] my-[16px] py-[10px] px-[20px]';
    }
    else return ''
  }

  function toggleInlineStyle(inlineStyle: any) {
    setEditorState(RichUtils.toggleInlineStyle(editorState, inlineStyle));
  }

  function toggleBlockType(blockType: any) {
    setEditorState(RichUtils.toggleBlockType(editorState, blockType));
  }

  // adding the link part here

  const linkDecorator = () => new CompositeDecorator([
    {
      strategy: findLinkEntities,
      component: Link,
    }
  ]);

  function findLinkEntities(contentBlock: ContentBlock, callback: any, contentState: ContentState) {
    contentBlock.findEntityRanges((character) => {
      const entityKey = character.getEntity();
      return (
        entityKey !== null &&
        contentState.getEntity(entityKey).getType() === 'LINK'
      );
    }, callback);
  }

  const Link = (props: any) => {
    const { url, linkText } = props.contentState.getEntity(props.entityKey).getData();
    return (
      <a style={{ color: "#006cb7", textDecoration: "underline" }}
        href={url}
      >
        {linkText || props.children}
      </a>
    )
  }

  const generateLink = (hyperlink: string, linkDisplayText: string) => {
    const decorator = linkDecorator();
    let link = hyperlink;

    if (!hyperlink.includes('http://')) {
      if (!hyperlink.includes('https://')) {
        link = `http://${hyperlink}`;
      }
    }

    // get the content that already present in editor to append new content into it
    const currentContent = editorState.getCurrentContent();

    // Creating Link entity
    currentContent.createEntity('LINK', 'MUTABLE', { url: link, target: '_blank' });

    const entityKey = currentContent.getLastCreatedEntityKey();

    // Get the selected text it'll used to override the text
    const selection = editorState.getSelection();

    // Add new text or override the selection text that user selects
    const textWithEntity = Modifier.replaceText(
      currentContent,
      selection,
      linkDisplayText,
      editorState.getCurrentInlineStyle(),
      entityKey,
    )

    const newState = EditorState.createWithContent(textWithEntity, decorator);
    setEditorState(newState);

  }
  return (
    <div className="relative flex flex-col space-y-2 rounded-[0.375rem] border border-[#586A84] hover:border-tertiary pl-[1.25rem] pr-1 pt-2 text-[1rem] text-[#242424] bg-[#F5F5F5] font-[400]">
      <div
      // className={classNames("rdw-editor-toolbar", toolbarClassName)}
      // style={{
      //   visibility: toolbarShow ? "visible" : "hidden",
      //   ...toolbarStyle,
      // }}
      // onMouseDown={this.preventDefault}
      // aria-label="rdw-toolbar"
      // aria-hidden={(!editorFocused && toolbarOnFocus).toString()}
      // onFocus={this.onToolbarFocus}
      >
        {/* {defaultToolbar.options.map((opt: any, index: number) => {
          const Control = Controls[opt];
          const config = defaultToolbar[opt];
          console.log(Control, config)
          // if (opt === "image" && uploadCallback) {
          //   config.uploadCallback = uploadCallback;
          // }
          return <Control key={index} modalHandler={{}} translations={{}} editorState={editorState} onChange={(editorState: EditorState) => setEditorState(editorState)} config={config} />;
          // return <>a</>
        })} */}
        {/* <FontSize config={defaultToolbar["fontSize"]} editorState={editorState} onChange={(editorState: EditorState) => setEditorState(editorState)}  /> */}
        {/* {toolbarCustomButtons &&
          toolbarCustomButtons.map((button, index) =>
            React.cloneElement(button, { key: index, ...controlProps })
          )} */}
      </div>
      {selectedText.length !== 0 && 
      <div className="bg-white p-2 rounded-sm border border-black/50 shadow-lg absolute -top-16 flex flex-col space-y-2 z-10">
        <BlockStyleControls
          editorState={editorState}
          onToggle={style => toggleBlockType(style)}
        />
        <div className='flex flex-wrap space-x-1'>
          <InlineStyleControls
            editorState={editorState}
            onToggle={style => toggleInlineStyle(style)}
          />
          <FontSize config={defaultToolbar["fontSize"]} editorState={editorState} onChange={(editorState: EditorState) => {console.log(editorState); setEditorState(editorState);}} />
          {/* <button className={`${linkToggle && "border-primary bg-primary text-white"} p-2 border rounded-md`} onClick={() => {
                        var selectionState = editorState.getSelection();
                        var anchorKey = selectionState.getAnchorKey();
                        var currentContent = editorState.getCurrentContent();
                        var currentContentBlock = currentContent.getBlockForKey(anchorKey);
                        var start = selectionState.getStartOffset();
                        var end = selectionState.getEndOffset();
                        var selectedText = currentContentBlock.getText().slice(start, end);

                        setLinkText(selectedText);
                        if (!linkToggle && selectedText.length > 0) {
                            setOpenCreateLinkModal(true);
                            setLinkToggle(!linkToggle);
                        }
                    }}><Link size={16} /></button> */}
        </div>
      </div>}
      <div className="ppg-rte h-44 w-full overflow-y-scroll leading-normal" onClick={focusEditor}>
        <Editor
          ref={editor}
          blockStyleFn={getBlockStyle}
          customStyleMap={styleMap}
          blockRenderMap={blockRenderMap}
          placeholder={placeholder}
          editorState={editorState}
          onChange={editorState => {
            // if (`${stateToHTML(editorState.getCurrentContent(), {
            //   // @ts-ignore: null is not assigned to defaultBlockTag as its type
            //   defaultBlockTag: null,
            // })}` !== '<br>') {
            console.log("this is the editor: ", editorState.getCurrentContent().getPlainText());
            setEditorState(editorState);
            // }
            setFormattedContent(editorState.getCurrentContent().getPlainText());
          }}
          spellCheck={true}
        />
      </div>
      <p className={`flex w-full justify-end ${error?.error && "text-red-500"}`}>
        {wordCount}/5000
      </p>

      {/* {error && <ErrorIndicator error={error} callback={() => setError(undefined)} />} */}

      {/* {
                openCreateLinkModal && (
                    <Modal
                        opened={openCreateLinkModal}
                        onClose={() => { setOpenCreateLinkModal(false); setLinkToggle(false); }}
                        title="Create Hyperlink"
                        size="md"
                    >
                        <div className='flex flex-col space-y-2'>
                            <div className='flex flex-col'>
                                <label htmlFor="linkDisplayText">Link Display Text</label>
                                <input className='outline-none border rounded-md px-3 py-1' value={linkText} type="text" name="linkDisplayText" id="linkDisplayText" />
                            </div>
                            <div className='flex flex-col'>
                                <label htmlFor="link">Hyperlink</label>
                                <input className='outline-none border rounded-md px-3 py-1' onChange={e => setLinkUrl(e.target.value)} value={linkUrl} type="text" name="link" id="link" />
                            </div>
                            <button className='bg-primary text-white font-semibold rounded-md px-3 py-1 max-w-max' onClick={() => { generateLink(linkUrl, linkText); setOpenCreateLinkModal(false); setLinkToggle(false); }} type="submit">Create</button>
                        </div>
                    </Modal>
                )
            } */}
    </div>
  )
}