import { useEffect, useRef, useState } from "react";
import L from "leaflet";
import "leaflet/dist/leaflet.css";

type Props = {
  image: string;
  locationPoints?: number[][];
  sectionBounds?: number[];
};

export function PreviewMapscreen({
  image,
  locationPoints = [],
  sectionBounds,
}: Props) {
  const mapRef = useRef<L.Map | null>(null);
  const markersRef = useRef<L.Marker[]>([]);
  const [imageSize, setImageSize] = useState<{ width: number; height: number } | null>(null);

  useEffect(() => {
    console.log(sectionBounds, locationPoints, image);
  }, [sectionBounds, locationPoints]);

  useEffect(() => {
    const img = new Image();
    img.src = image;
    img.onload = () => {
      setImageSize({ width: img.naturalWidth, height: img.naturalHeight });
    };
  }, [image]);

  useEffect(() => {
    if (!imageSize || mapRef.current) return;

    const { width, height } = imageSize;
    const centerH = sectionBounds && sectionBounds.length >= 2
      ? ((sectionBounds[1] - sectionBounds[0]) / 2) + sectionBounds[0]
      : height / 2;
    const centerW = width / 2;

    if (isNaN(centerH) || isNaN(centerW)) {
      console.error('Invalid center coordinates:', { centerH, centerW });
      return;
    }

    const map = L.map("map", {
      center: [centerH, centerW],
      zoom: -1,
      minZoom: -2,
      crs: L.CRS.Simple,
      maxBounds: [
        [0, 0],
        [height, width],
      ],
      maxBoundsViscosity: 1.0,
      zoomControl: true,
    });

    const bounds: L.LatLngBoundsExpression = [
      [0, 0],
      [height, width],
    ];
    L.imageOverlay(image, bounds).addTo(map);
    map.fitBounds(bounds, { padding: [20, 20] });
    mapRef.current = map;
  }, [imageSize]);

  useEffect(() => {
    if (!mapRef.current || locationPoints.length === 0) return;

    // Remove old markers
    if (markersRef.current) {
        markersRef.current.forEach((marker) => marker.remove());
        markersRef.current = [];
    }

    let totalLat = 0, totalLng = 0;

    locationPoints.forEach((points: number[]) => {
        if(points.length >= 3) {
            const marker = L.marker([points[1], points[2]], {
            icon: L.divIcon({
                className: "blue-dot blinking",
            }),
            }).addTo(mapRef.current!);
            markersRef.current.push(marker);
            totalLat += points[1];
            totalLng += points[2];
        } else {
            console.log("Invalid location point:", points);
        }
    })

    // Adjust zoom based on the number of points
    if (locationPoints.length === 1) {
      const [_, x, y] = locationPoints[0];
      mapRef.current.setView([x, y], 0); // Adjust zoom level as needed
    } else if (locationPoints.length > 1) {
      const avgX = totalLat / locationPoints.length;
      const avgY = totalLng / locationPoints.length;
      mapRef.current.setView([avgX, avgY], -1); // Adjust zoom for multiple points
    }

  }, [locationPoints]);

  return <div id="map" className="h-full w-full"></div>;
}
