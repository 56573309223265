import React, { useState, useEffect, useRef } from "react";
import ReactCountryFlag from "react-country-flag";
import { ChevronRight } from "@carbon/icons-react";
import { ChevronLeft } from "@carbon/icons-react";
import PreviewModal from "src/utils/PreviewModal";
import PdfViewer from "src/utils/PdfViewer";
// @ts-ignore
import PAannouncementBG from "../../../assets/PAannouncement.jfif";
// @ts-ignore
import EyeLogo from "../../../assets/preview/Eye.svg";
// @ts-ignore
import MicrophoneLogo from "../../../assets/preview/Microphone.svg";
// @ts-ignore
import DownArrow from "../../../assets/preview/CaretDown.svg";
// @ts-ignore
import PauseLogo from "../../../assets/preview/Pause.svg";
// @ts-ignore
import PlayLogo from "../../../assets/play.png";
// @ts-ignore
import Speaker from "../../../assets/preview/SpeakerLow.svg";
// @ts-ignore
import Repeat from "../../../assets/preview/Repeat.svg";
// @ts-ignore
import EnglishLogo from "../../../assets/preview/EnglishLogo.png";
import Slider from "rc-slider";
import { useSelector } from "react-redux";

type Props = {
  data: any;
  onNext: (nextTemplateId: string) => void;
  //   handleSelectMenu: (menu: string) => void;
  //   selectedAircraft: any;
};

export function PAannouncement({ data, onNext }: Props) {
  const [openModal, setOpenModal] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [announcement, setAnnouncement] = useState<any>();
  const [filteredDoc, setFilteredDoc] = useState<any>();
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isVolumeOpen, setIsVolumeOpen] = useState(false);
  const [currentTime, setCurrentTime] = useState<number | undefined>(0);
  const [audioDuration, setAudioDuration] = useState<number | undefined>(0);

  const { themeColor, headingFont, bodyFont } = useSelector((state: any) => state.programSettings);

  const MAX = 20;

  useEffect(() => {
    console.log(data);
  }, []);

  useEffect(() => {
    handleClickLanguage(data.content.languages[0], announcement);
    console.log(data.content.languages[0], announcement);
  }, [announcement]);
  useEffect(() => {
    console.log(filteredDoc);
  }, [filteredDoc]);

  function handleClickPA(data: any) {
    console.log(data);
    setAnnouncement(data);
    setOpenModal(true);
  }

  function handleClickLanguage(lang: any, announcement: any) {
    if (announcement) {
      console.log(lang, announcement);
      setSelectedLanguage(lang.name);
      const filteredLangDoc = announcement.languageDoc.filter(
        (data: any) => data.id === lang.id
      );

      console.log(filteredLangDoc[0]);
      setFilteredDoc(filteredLangDoc[0]);

      setOpenDropdown(false);
    }
  }

  const audioRef = useRef<HTMLAudioElement>(null);

  const handlePlayPause = () => {
    if (isPlaying) {
      audioRef.current?.pause();
    } else {
      audioRef.current?.play();
    }

    setIsPlaying((currentPlayStatus) => !currentPlayStatus);
  };

  const onTimeUpdate = () => {
    setCurrentTime(audioRef.current?.currentTime ?? 0);
  };

  const formatTime = (duration: number) => {
    const OneSec = 60;
    const minutes = Math.floor(duration / OneSec);
    const seconds = Math.floor(duration - minutes * OneSec);

    const formatedTime = `${tenPad(minutes)}:${tenPad(seconds)}`;

    return formatedTime;
  };

  const tenPad = (time: number) => {
    if (time < 10) return `0${time}`;
    else return time;
  };

  const onVolumeChange = (value: number) => {
    if (audioRef.current) {
      audioRef.current.volume = value / 100;
    }
  };

  function handleVolume(e: React.ChangeEvent<HTMLInputElement>) {
    const { value } = e.target;
    const volume = Number(value) / MAX;
    if (audioRef.current) {
      audioRef.current.volume = volume;
    }
  }

  return (
    <div className={`relative w-full xs:h-[calc(100%-3.5rem)] sm:h-[calc(100%-4rem)] md:h-[calc(100%-4.6rem)] lg:h-[calc(100%-5.125rem)] xl:h-[calc(100%-5.625rem)] pt-[1.5rem] md:pt-[1.88rem] pl-[1.7rem] md:pl-[2.5rem] lg:pl-[3.75rem] pr-[1.7rem] md:pr-[2.44rem] ${bodyFont && `font-${bodyFont}`}`}>
      <div className="w-full h-full flex flex-col space-y-[3rem] md:space-y-[3.4rem] lg:space-y-[5rem]">
        <div className={`text-[#FFFFFF] text-[1.7rem] md:text-[2rem] lg:text-[2.5rem] font-semibold  border-l-4 md:border-l-[7px] pl-[1rem] md:pl-[1.5rem] leading-normal ${headingFont && `font-${headingFont}`}`}>
          {data.title}
        </div>
        <div className="grid grid-cols-2 md:grid-cols-3 gap-x-[2rem] gap-y-[3rem] lg:grid-cols-4 lg:gap-y-[5.06rem] lg:gap-x-[4.94rem] pl-[0rem] lg:pl-[1.5rem]">
          {data.content.documents.map((data: any, idx: number) => (
            <PAannouncementDocs
              key={data._id}
              data={data}
              index={idx + 1}
              themeColor={themeColor}
              handleClick={(data: any) => {
                handleClickPA(data);
              }}
            />
          ))}
        </div>
      </div>

      <div>
        <PreviewModal
          onOpened={openModal}
          onClose={() => setOpenModal(false)}
          showCrossIcon={true}
        >
          <>
            <div
              style={{
                background: `linear-gradient(0deg, rgba(0, 0, 0, 0.70) 0%, rgba(0, 0, 0, 0.70) 100%), url(${data.content.bgfile?.url})`,
                backgroundPosition: "50%",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundColor: "lightgray",
                color: "#146793",
              }}
              className="w-full h-full pt-[1.5rem] md:pt-[1.88rem] px-[1.5rem] md:px-[2.5rem] flex flex-col space-y-[2.5rem] md:space-y-[5rem] lg:space-y-[6.06rem] overflow-y-scroll no-scrollbar"
            >
              <div className="md:px-[1rem]">
                <div className="text-[#FFFFFF] text-[1.2rem] md:text-[1.6rem] lg:text-[2rem] font-semibold  border-l-4 md:border-l-[7px] pl-[0.8rem] md:pl-[1.3rem] leading-normal flex xs:flex-col sm:flex-col md:flex-row xs:items-start sm:items-start md:items-center md:space-x-1">
                  <span className="">{data.title} - </span>
                  <span className=""> {announcement?.title}</span>
                </div>
              </div>
              <div className="md:px-[2rem] lg:px-[14.69rem] flex flex-col space-y-[0.2rem] md:space-y-[0.45rem] items-center">
                <div className="w-full flex max-w-[53.125rem] h-[10.625rem] border-2 border-solid border-[#454544]/90 rounded-[0.625rem] bg-black/80">
                  <div className="w-full flex flex-col space-y-[1rem] md:space-y-[1.31rem] md:max-w-[37rem] h-full mx-[1rem] md:mx-auto">
                    <div className="w-full">
                      <audio
                        src={filteredDoc?.file?.url ?? ""}
                        itemType={filteredDoc?.file?.type ?? ""}
                        ref={audioRef}
                        className="w-full"
                        onWaiting={() => setIsLoading(true)}
                        onCanPlayThrough={() => {
                          // alert(audioRef.current?.duration)
                          setAudioDuration(audioRef.current?.duration);
                          setIsLoading(false);
                        }}
                        onEnded={() => setIsPlaying(false)}
                        onTimeUpdate={onTimeUpdate}
                      >
                        {/* <source
                        src={filteredDoc?.file?.url ?? ""}
                        type={filteredDoc?.file?.type ?? ""}
                      ></source>   */}
                      </audio>

                      <div className="flex w-full space-x-[1rem] justify-center items-center mt-[2.94rem]">
                        {/* current time */}
                        <span className="text-[#FFFFFF] text-[0.625rem] font-[400] leading-normal">
                          {formatTime(currentTime ?? 0)}
                        </span>

                        {/* Progress bar */}
                        <div className="bg-[#FFFFFF]/50 h-[4px] w-full max-w-[31.25rem] mx-auto rounded-full">
                          <div
                            className={`${!themeColor&& "bg-[#AB0273]"} h-full w-[0rem] rounded-full ${
                              currentTime !== audioDuration
                                ? "progress-bar-inner"
                                : ""
                            }`}
                            style={{
                              backgroundColor: themeColor, opacity: 0.8,
                              animationPlayState: isPlaying
                                ? "running"
                                : "paused",
                              animationDuration: isLoading
                                ? "0s"
                                : `${audioRef.current?.duration}s`,
                            }}
                          ></div>
                        </div>

                        {/* Audio Duration */}
                        <span className="text-[#FFFFFF] text-[0.625rem] font-[400] leading-normal">
                          {formatTime(audioDuration ?? 0)}
                        </span>
                      </div>
                    </div>
                    <div className="grid grid-cols-3 items-center">
                      <div className="col-span-1 flex items-center space-x-[0.56rem]">
                        <button>
                          <img src={Repeat} alt="repeat" />
                        </button>
                        <div className="group flex items-center">
                          <button
                            onClick={() => setIsVolumeOpen(!isVolumeOpen)}
                          >
                            <img src={Speaker} alt="sound" />
                          </button>
                          <input
                            type="range"
                            style={{accentColor: themeColor}}
                            className={`${!themeColor && "accent-[#AB0273]"} h-[4px] w-[3.125rem]`}
                            min={0}
                            max={MAX}
                            onChange={(e) => handleVolume(e)}
                          />
                        </div>
                      </div>
                      <div className="col-span-1 flex justify-center">
                        <button
                          onClick={handlePlayPause}
                          style={{borderColor: themeColor, opacity: 0.7}}
                          className={`w-[3.25] h-[3.25] p-[0.625rem] flex items-center justify-center border-[3px] border-solid ${themeColor && "border-[#AB0273]/70"} rounded-full`}
                        >
                          {isPlaying ? (
                            <img src={PauseLogo} alt="pause" />
                          ) : (
                            <img
                              className="w-[2rem] h-[2rem] p-[0.4rem]"
                              src={PlayLogo}
                              alt="play"
                            />
                          )}
                        </button>
                      </div>
                      <div className="col-span-1 relative flex flex-col items-end">
                        <button
                          onClick={() => setOpenDropdown(!openDropdown)}
                          style={{borderColor: themeColor, opacity: 0.7}}
                          className={`flex py-[0.5rem] px-[0.75rem] w-full max-w-[8rem] h-[2.65rem] items-center justify-between space-x-[0.625rem] border-2 border-solid ${!themeColor && "border-[#AB0273]/70"} rounded-[1.875rem]`}
                        >
                          {/* <img src={EnglishLogo} alt="logo" /> */}
                          <span className="text-[0.875rem] md:text-[1rem] text-[#FFFFFF] line-clamp-1">{selectedLanguage}</span>
                          <img
                            className="w-[1.75rem] h-[1.75rem]"
                            src={DownArrow}
                            alt="arrowdown"
                          />
                        </button>
                        {openDropdown && (
                          <div className="flex flex-col w-[9rem] md:w-[10rem] lg:w-[12.4375rem] dropDownLanguage xs:right-0">
                            <ul className="flex flex-col p-[0.44rem]">
                              {data.content.languages.map((lang: any, idx: number) => (
                                <li className={`flex text-[1rem] lg:text-[1.5rem] text-black px-3 py-2 text-left items-center border-t border-[#000]/30 ${idx === 0 && "border-t-0"}`}>
                                  <button
                                    onClick={() =>
                                      handleClickLanguage(lang, announcement)
                                    }
                                    className="flex space-x-[0.625rem] items-center justify-center"
                                  >
                                    {/* <span>
                                      <ReactCountryFlag 
                                        countryCode={lang.name.substring(0,2).toLowerCase()} svg
                                      />
                                    </span> */}
                                    <span className="">
                                      {lang.name}
                                    </span>
                                  </button>
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-full max-w-[53.125rem] h-[19rem] md:h-[21.25rem] p-[1rem] border-2 border-solid border-[#454544]/90 rounded-[0.625rem] bg-black/70">
                  {filteredDoc && (
                    <div dangerouslySetInnerHTML={{__html: filteredDoc.transcriptText}} className="text-[#FFFFFF] text-[0.875rem]"/>
                  )}
                </div>
                <div className="w-full max-w-[53.125rem] h-[6.0625rem] flex items-center justify-center border-2 border-solid border-[#454544]/90 rounded-[0.625rem] bg-black/60">
                  <div style={{borderColor: themeColor, opacity: 0.7}} className={`inline-flex p-[0.625rem] space-x-[0.52rem] items-center justify-center border-[2px] md:border-[3px] border-solid ${!themeColor && "border-[#AB0273]/70"} rounded-[12.5rem]`}>
                    <span className="w-[1.8rem] h-[1.8rem] md:w-[2rem] md:h-[2rem]">
                      <img src={MicrophoneLogo} alt="mike" />
                    </span>
                    <span className="text-[1rem] text-[#FFFFFF] font-[500] leading-normal">
                      Practice Mode
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </>
        </PreviewModal>
      </div>
    </div>
  );
}

const PAannouncementDocs: React.FC<{
  data: any;
  index: number;
  themeColor: any;
  handleClick: (data: any) => void;
}> = ({ data, index, themeColor, handleClick }) => {
  return (
    // {

    <button
      onClick={() => {
        if (handleClick) handleClick(data);
      }}
      className=" flex flex-col relative"
    >
      <div 
      style={{
        background: `linear-gradient(0deg, rgba(0, 0, 0, 0.70) 0%, rgba(0, 0, 0, 0.70) 100%), url(${PAannouncementBG})`,
        backgroundPosition: "50%",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundColor: "lightgray",
      }}
      className="w-full flex flex-col max-w-[16.875rem] h-[9.375rem]  bg-[#000000]/80 text-[#FFFFFF]/[87]">
        <div className="grow w-full max-w-[16.875rem] h-[9.375rem] text-[1.25rem] flex  items-center justify-center font-[500] leading-[1.5rem] tracking-[0.00188rem]">
          {data.title}
        </div>
      </div>
      <div style={{backgroundColor: themeColor, opacity: 0.8}} className={`flex w-full max-w-[16.875rem] h-[1.875rem] justify-center items-center py-[0.31rem] flex-shrink-0 text-[#E1E1E1] font-semibold text-[1.25rem] leading-[1.25rem] tracking-[0.00188rem] ${!themeColor && "bg-[#2F0220]/80"}`}>
        {data.date}
      </div>
      <span className="absolute top-0 right-[5px] text-[rgba(255,255,255,0.3)] text-4xl font-extrabold">
        {index}
      </span>
    </button>
    // }
  );
};

{
  /* <span className="flex justify-end pr-[0.63rem]">
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
  >
    <path
      d="M28.182 17H24.182C23.6515 17 23.1428 17.2107 22.7678 17.5858C22.3927 17.9609 22.182 18.4696 22.182 19V24C22.182 24.5304 22.3927 25.0391 22.7678 25.4142C23.1428 25.7893 23.6515 26 24.182 26H26.182C26.7124 26 27.2211 25.7893 27.5962 25.4142C27.9713 25.0391 28.182 24.5304 28.182 24V17ZM28.182 17C28.182 15.4165 27.8686 13.8486 27.2598 12.3868C26.651 10.925 25.759 9.59807 24.635 8.48261C23.5111 7.36714 22.1775 6.48516 20.7111 5.88751C19.2447 5.28985 17.6745 4.98833 16.091 5.00034C14.5075 4.98833 12.9373 5.28985 11.4709 5.88751C10.0045 6.48516 8.6709 7.36714 7.54695 8.48261C6.423 9.59807 5.53093 10.925 4.92217 12.3868C4.31341 13.8486 4 15.4165 4 17V24C4 24.5304 4.21071 25.0391 4.58579 25.4142C4.96086 25.7893 5.46957 26 6 26H8C8.53043 26 9.03914 25.7893 9.41421 25.4142C9.78929 25.0391 10 24.5304 10 24V19C10 18.4696 9.78929 17.9609 9.41421 17.5858C9.03914 17.2107 8.53043 17 8 17H4"
      stroke="white"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</span> */
}
