import React from 'react'
import InputText from 'src/utils/TextInput/InputText';
import UploadButton from 'src/utils/UploadButton';

type Props = {
    input: string;
    onChangeInput: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onHandleAdd: () => void
}


function AddLanguages({input, onChangeInput, onHandleAdd}: Props) {
  return (
    <div className="flex flex-col h-full  px-[0.45rem]">
          <div className="grow space-y-4 py-[1.25rem]">
            <div>
              <InputText
                value={input}
                onChange={(e) => onChangeInput(e)}
                placeholder="Enter Language"
                labelTitle="Language"
              />
            </div>
          </div>
          <div className=" border-t border-[#2C2C2C] px-[0.49rem] py-5 flex justify-end items-center">
            <button
              onClick={() => onHandleAdd()}
              className="px-7 py-3 text-[1rem] text-[#FFFFFF] bg-[#AB0273] rounded-[1.875rem]"
            >
              Done
            </button>
          </div>
        </div>
  )
}

export default AddLanguages;
