import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ChatGPTIcon from "../../assets/chatgpt.svg.webp";
import "./ChatComponent.css";

const openaiApiKey = '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';

const headers = {
  'Content-Type': 'application/json',
  'Authorization': `Bearer ${openaiApiKey}`,
};

interface ChatMessage {
  role: string;
  content: string;
}

function ChatGPT() {
  const [message, setMessage] = useState<string>("");
  const [chats, setChats] = useState<ChatMessage[]>([]);
  const [isTyping, setIsTyping] = useState<boolean>(false);

  const scrollToBottom = () => {
    // Your scroll to bottom logic here
  };

  const sendMessage = async () => {
    if (!message) return;

    setIsTyping(true);

    let updatedChats: ChatMessage[] = [...chats, { role: "user", content: message }];
    setChats(updatedChats);

    setMessage("");

    try {
      const response = await axios.post(
        'https://api.openai.com/v1/chat/completions',
        {
          model: 'gpt-3.5-turbo',
          messages: [
            { role: 'system', content: 'You are a helpful assistant.' },
            { role: 'user', content: message },
          ],
         
        },
        { headers }
      );
      
      const data = response.data.choices[0].message.content;

      updatedChats = [...updatedChats, { role: "AI", content: data }];
      setChats(updatedChats);

      setIsTyping(false);
      scrollToBottom();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [chats]);

  return (
    <main className=" font-arial overflow-scroll w-[23.75rem] h-[45.25rem] bg-[#F5F5F5] text-[#ffffff] shadow-lg border-2 border-solid border-[#000000]/30 rounded-[1.625rem]  flex flex-col z-10 absolute top-[5.7rem] right-[0.2rem]">
      <section className="ChatUserMessage">
        {chats.map((chat, index) => (
          <p key={index} className={chat.role === "user" ? "user_msg" : ""}>
            <span>
              <b>{chat.role.toUpperCase()}</b>
            </span>
            <span>:</span>
            <span>{chat.content}</span>
          </p>
        ))}
      </section>

      <div className={isTyping ? "chatTypingshow" : "chatTypinghide"}>
        <p>
          <i>{isTyping ? "Typing..." : ""}</i>
        </p>
      </div>

      <form className="formChatUserMessage" onSubmit={(e) => { e.preventDefault(); sendMessage(); }}>
        <input
          type="text"
          name="message"
          value={message}
          placeholder="Type a message here and hit Enter..."
          onChange={(e) => setMessage(e.target.value)}
        />
      </form>
    </main>
  );
}

export default ChatGPT;

