import React from 'react'

type Props = {
    value: string,
    onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void,
    placeholder?: string,
    className?: string,
    labelTitle?: string
}

function TextArea({value, onChange, placeholder, className, labelTitle}: Props) {
  return (
    <div className='flex flex-col space-y-[1.25rem] mx-[0.49rem]'>
      {labelTitle && <label className='font-[600] text-[1rem] text-[#242424] leading-normal' htmlFor='textarea'>{labelTitle}</label>}
      <div className='flex items-center overflow-hidden rounded-[0.375rem] border border-[#586A84]  hover:border-tertiary'>
        <textarea rows={4} cols={20} className='grow outline-none px-[1.25rem] py-2 text-[1rem] text-[#242424] bg-[#F5F5F5] font-[400] leading-normal resize-none' value={value} onChange={(e) => onChange(e)} placeholder={placeholder} name='textarea' />
      </div>
    </div>
  )
}

export default TextArea;