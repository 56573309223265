import { createContext, useContext } from "react";

export type State = {
    aircraftsusers: {
        aircrafts: any[],
        users: any[]
    }
}

export const InitialState: State = {
    aircraftsusers: {
        aircrafts: [],
        users: []
    },
}

export type StateManager = {
    state: State;
    setAircraftsUsers: (aircraftsusers: any) => void;
}

export const AppContext = createContext<StateManager>({
    state: InitialState,
    setAircraftsUsers: (_) => console.error("no function set for 'setAircrafts'"),
})

export const useAppContext = () => useContext(AppContext);