import React, { useEffect, useState } from "react";
import Nav from "./Navbar";
import Sidebar from "./Sidebar";
import RightPanel from "./RightPanel";
import { AuthenticatedTemplate } from "@azure/msal-react";
import { Device } from "src/components/Preview/Layout";
import Navbar from "src/components/Preview/Navbar";
import Preview from "src/components/Preview";
import { cn } from "src/components/lib/utils";
import { cva } from "class-variance-authority"
import { useParams } from "react-router-dom";
import PreviewModal from "src/utils/PreviewModal";
import SidebarWidgets from "./Sidebar/SidebarWigets";


type Props = {
  type: string;
  handleProgram?: (program: any) => void;
  children: (callback?: (program: any) => void) => JSX.Element;
};

const deviceVariants = cva("grow flex flex-col", {
  variants: {
    device: {
      default: "max-h-[64rem] h-full max-w-[90rem] w-full",
      laptop: "max-h-[64rem] h-full max-w-[90rem] w-full",
      tabPortrait:
        "max-h-[62rem] h-full max-w-[50rem] w-screen border-[12px] border-gray-300 rounded-lg",
      tabLandscape:
        "max-h-[50rem] h-full max-w-[62rem] w-screen border-[12px] border-gray-300 rounded-lg",
      mobilePortrait:
        "max-h-[50rem] h-full max-w-[35rem] w-screen border-[12px] border-gray-300 rounded-lg",
      mobileLandscape:
        "max-h-[35rem] h-full max-w-[50rem] w-screen border-[12px] border-gray-300 rounded-lg",
    },
  },
  defaultVariants: {
    device: "default",
  },
})

function Layout(props: Props) {

  const [openPreviewModal, setOpenPreviewModal] = useState(false);
  const [deviceVariantState, setDeviceVariantState] = useState<Device>("default");

  const [program, setProgram] = useState<any>(null);

  const {authorId, businessUnitId } = useParams();

  return (
    <main className="relative w-full h-screen mx-auto overflow-hidden font-poppins">
      <Nav type={props.type} />
      <section className="relative w-full h-[calc(100%-5.625rem)] flex bg-[#EFEFEF]">
        {(props.type !== "homepage" && props.type !== "profile" && props.type !== "courses") && <Sidebar type={props.type} />}
        {props.type === "dragdropwidget" && <div className="w-[400px]"><SidebarWidgets /></div>}
        {props.children((program) => setProgram(program))}
        {/* {props.type === "courses" && <RightPanel />} */}
      </section>

      {program && <PreviewModal
        onOpened={program}
        onClose={() => setProgram(null)}
        showCrossIcon={false}
      >
        <div className="flex flex-col items-center w-full mx-auto h-screen overflow-hidden ">
          <Navbar
            onHandleDevice={(device) => setDeviceVariantState(device)}
            programName={program?.title}
            handleClose={() => setProgram(null)}
          />
          <div className="grow w-full flex justify-center">
            {deviceVariantState !== "default" &&
              deviceVariantState !== "laptop" ? (
              <iframe
                className={cn(deviceVariants({ device: deviceVariantState }))}
                title="preview section"
                src={`${process.env.REACT_APP_CLIENT_BASE_URL}/user/${authorId}/businessunit/${businessUnitId}/sepm/${program._id}/preview`}
              ></iframe>
            ) : (
              <Preview device={deviceVariantState} programID={program._id} />
            )}
          </div>
        </div>
      </PreviewModal>}
    </main>
  );
}

export default Layout;
