import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux'; // Assuming you're using Redux for state management

function LoginDashboardRedirect() {

  const navigate = useNavigate();
  const { accessToken } = useSelector((state:any) => state.accessToken);

  useEffect(() => {
    if (accessToken) {
      navigate('/dashboard', { replace: true });
    } else {
      navigate('/login');
    }
  }, [accessToken, navigate]);

  return null;
}

export default LoginDashboardRedirect;
