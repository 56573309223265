import React, { useEffect } from 'react';
import axios from 'axios';
import { Plus, X } from 'lucide-react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Output from './Output';

type Props = {
  children: React.ReactNode;
}


function Layout() {

  const [data, setData] = React.useState<ErrOutputData>({ blocks: [] });

  const { pageId } = useParams();

  const { accessToken } = useSelector((state: any) => state.accessToken);

  const style: ErrOutputStyle = {
    header: {
      h1: {
        color: 'lightseagreen',
        fontFamily: 'cursive'
      },
    },
    image: {
      img: {},
      figure: {},
      figcaption: {}
    },
    paragraph: {
      textAlign: 'justify',
      margin: '8px 0',
      fontSize: '18px',
      lineHeight: '1.7',
      fontWeight: 200,
    },
    list: {
      container: {
        margin: '5px 0',
      },
      listItem: {
        margin: '5px 0',
      },
    },
    table: {
      table: {},
      tr: {},
      th: {},
      td: {},
    },
    quote: {
      container: {},
      content: {},
      author: {},
      message: {}
    },
    codebox: {
      code: { lineHeight: '22px' },
    },
    warning: {
      icon: {
        width: '28px',
      },
      title: {
        marginRight: '10px'
      },
      message: {
        textAlign: 'left'
      },
    },
    avatar: {
      height: '40px',
      width: '40px',
      borderRadius: '20px',
      margin: '8px',
      boxShadow: '0 0 4px 0 rgba(0,0,0,0.5)',
      backgroundColor: '#1e242a'
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_SERVER_BASE_URL}/api/v1/page/get/${pageId}`,
          {
            headers: { Authorization: `Bearer ${accessToken}` },
          }
        );
        if (res && res.data) {
          console.log(JSON.parse(res.data.data.page?.data[0].content));
          setData(JSON.parse(res.data.data.page?.data[0].content) || { blocks: [] }); // Ensure data is set as an array
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);
  return (
    <div className="flex h-screen">
      {/* Sidebar */}
      <div className="bg-gray-200 w-1/5 ">
        <div className="absolute ml-60 ">
          <X className="h-7 w-7" />
        </div>
        <div className=" relative w-full flex items-center justify-between mt-9 ml-5 text-[#242424] font-medium text-[1rem]">

        </div>
      </div>

      {/* Main content area */}
      {/* <div className="flex-1 flex items-center justify-center p-4 bg-gray-100">
        <p className="text-lg text-center">Select the topic to get started</p>
      </div> */}

      <main className="">
        <Output data={data} style={style} />
      </main>
    </div>
  )
}

export default Layout;
