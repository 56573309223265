import React, { useState, useEffect } from "react";
import { ChevronRight } from "@carbon/icons-react";
import { ChevronLeft } from "@carbon/icons-react";
import PreviewModal from "src/utils/PreviewModal";
import PdfViewer from "src/utils/PdfViewer";
// @ts-ignore
import EyeLogo from "../../../assets/preview/Eye.svg";
// @ts-ignore
import CardBG from "../../../assets/cardBG.jfif";
import { useSelector } from "react-redux";

type Props = {
  data: any;
  onNext: (nextTemplateId: string) => void;
  //   handleSelectMenu: (menu: string) => void;
  //   selectedAircraft: any;
};

export function PreFlightScreen({ data, onNext }: Props) {
  const [openModal, setOpenModal] = useState(false);
  const [pdfFile, setPdfFile] = useState<string>("");
  const [pdfTitle, setPdfTitle] = useState<string>("");

  const { themeColor, headingFont, bodyFont } = useSelector((state: any) => state.programSettings);

  useEffect(() => {
    console.log(data);
  }, []);

  return (
    <div className={`relative w-full xs:h-[calc(100%-3.5rem)] sm:h-[calc(100%-4rem)] md:h-[calc(100%-4.6rem)] lg:h-[calc(100%-5.125rem)] xl:h-[calc(100%-5.625rem)] pt-[1.5rem] md:pt-[1.88rem] pl-[1.7rem] md:pl-[2.5rem] lg:pl-[3.75rem] pr-[1.7rem] md:pr-[2.06rem] ${bodyFont && `font-${bodyFont}`}`}>
      <div className="w-full h-full flex flex-col space-y-[3rem] md:space-y-[3.4rem] lg:space-y-[5rem]">
        <div className={`text-[#FFFFFF] text-[1.7rem] md:text-[2rem] lg:text-[2.5rem] font-semibold border-l-4 md:border-l-[7px] pl-[1rem] md:pl-[1.5rem] leading-normal ${headingFont && `font-${headingFont}`}`}>
          {data.title}
        </div>
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-[2rem] gap-y-[3rem] lg:gap-y-[5.06rem] lg:gap-x-[4.94rem] pl-[0rem] lg:pl-[1.5rem]">
          {data.content.documents.map((data: any, idx: number) => (
            <PreFlightDocs
              key={data._id}
              index={idx + 1}
              themeColor={themeColor}
              data={data}
              handleClick={(file) => {
                setOpenModal(true);
                setPdfFile(file);
                setPdfTitle(data.title);
              }}
            />
          ))}
        </div>
      </div>
      <div>
        <PreviewModal
          onOpened={openModal}
          onClose={() => setOpenModal(false)}
          showCrossIcon={true}
        >
          <div 
            style={{
              background: `linear-gradient(0deg, rgba(0, 0, 0, 0.70) 0%, rgba(0, 0, 0, 0.70) 100%), url(${data.content.bgfile?.url})`,
              backgroundPosition: "50%",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundColor: "lightgray",
              color: "#146793",
            }}
            className="w-full h-full pt-[1.5rem] md:pt-[1.88rem] pl-[1.7rem] md:pl-[2.5rem] lg:pl-[3.75rem] pr-[1.7rem] md:pr-[2.06rem] flex flex-col space-y-[1.5rem]"
            >
            <div className="text-[#FFFFFF] text-[1.2rem] md:text-[1.6rem] lg:text-[2rem] font-semibold  border-l-4 md:border-l-[7px] pl-[0.8rem] md:pl-[1.3rem] leading-normal flex xs:flex-col sm:flex-col md:flex-row xs:items-start sm:items-start md:items-center md:space-x-1">
                <span className="">{data.title} - </span>
                <span className=""> {pdfTitle}</span>
            </div>
            <div className="h-full">
              <PdfViewer file={pdfFile} />
            </div>
          </div>
        </PreviewModal>
      </div>
    </div>
  );
}

const PreFlightDocs: React.FC<{
  data: any;
  themeColor: any;
  handleClick: (file: any) => void;
  index: number;
}> = ({ data, themeColor, handleClick, index }) => {
  return (
    <button
      onClick={() => {
        handleClick(data.file);
      }}
      className=" flex flex-col relative"
    >
      <div 
      style={{
        background: `linear-gradient(0deg, rgba(0, 0, 0, 0.70) 0%, rgba(0, 0, 0, 0.70) 100%), url(${CardBG})`,
        backgroundPosition: "50%",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundColor: "lightgray",
      }}
      className="w-full flex flex-col max-w-[16.875rem] h-[9.375rem]  bg-[#000000]/80 text-[#FFFFFF]/[87]">
        <div className="grow w-full max-w-[16.875rem] h-[9.375rem] text-[1.25rem] flex  items-center justify-center font-[500] leading-[1.5rem] tracking-[0.00188rem]">
          {data.title}
        </div>
      </div>
      <div style={{backgroundColor: themeColor, opacity: 0.8}} className={`flex w-full max-w-[16.875rem] h-[[1.875rem] justify-center items-center py-[0.31rem] flex-shrink-0 text-[#E1E1E1] font-semibold text-[1.25rem] leading-[1.25rem] tracking-[0.00188rem] ${!themeColor && "bg-[#2F0220]/80"}`}>
        {data.date}
      </div>
      <span className="absolute top-0 right-[5px] text-[rgba(255,255,255,0.3)] text-4xl font-extrabold">
        {index}
      </span>
    </button>
  );
};
