import React from "react";
import { useSelector } from "react-redux";
import Video, {
  ReactWebPlayer,
} from "src/components/MediaLibrary/FileType/Video";

type Props = {
  data: any;
  onNext: (nextTemplateId: string) => void;
};

export function Splash({ data, onNext }: Props) {
  const { themeColor, headingFont, bodyFont, headingColor, thumbnailHeadingColor, thumbnailTitleColor } = useSelector(
    (state: any) => state.programSettings
  );

  return (
    // <div className={`w-full grow flex flex-col items-center pt-[1rem] md:pt-[1.5rem] lg:pt-[1.5rem] xl:pt[2.5rem] pb-[0.1rem] space-y-[3rem] px-5 ${bodyFont && `font-${bodyFont}`}`}>
    //   <div className="grow xs:w-[90%] sm:w-[85%] md:w-[85%] lg:w-[50%] xl:w-[60%] flex flex-col space-y-[1.5rem] md:space-y-[1.5rem] lg:space-y-[1.5rem] 2xl:space-y-[1.8rem]">
    //     <div className="flex text-[#FFFFFF] justify-start items-center py-2 sm:py-4">
    //       <div className={`xs:text-[1.5rem] sm:text-[1.7rem] md:text-[2.3rem] lg:text-[2rem] 2xl:text-[2.3rem] font-semibold ${headingFont && `font-${headingFont}`} xs:border-l-[5px] md:border-l-[6px] lg:border-l-[6px] xs:pl-[1rem] md:pl-[1.35rem] lg:pl-[1rem]`}>
    //         {data.content.title}
    //       </div>
    //     </div>

    //     <div className="flex flex-col space-y-[1.5rem] md:space-y-[1.5rem] lg:space-y-[1.5rem] 2xl:space-y-[1.8rem] justify-center h-full">
    //       <div className="relative w-full rounded-[0.625rem] border border-[#B39B8F] overflow-hidden bg-black" style={{ paddingTop: '56%' }}>
    //         {data.content.file ? (data.content.file.hasOwnProperty("name") ? (
    //           <video
    //             className="absolute inset-0 mx-auto max-w-none max-h-full rounded-[0.625rem]"
    //             style={{
    //               width: 'auto',
    //               height: '100%',
    //               maxWidth: '100%',
    //               maxHeight: '100%'
    //             }}
    //             controls
    //           >
    //             <source
    //               src={data.content.file?.url ?? ""}
    //               type={data.content.file?.type ?? ""}
    //             />
    //           </video>
    //         )
    //           :
    //           (
    //             <ReactWebPlayer url={data.content.file.url} />
    //           )) : (
    //           <div className="flex items-center justify-center w-full h-full text-[#FFFFFF] text-[1.3rem] font-normal">
    //             No Video
    //           </div>
    //         )}
    //       </div>

    //       <div className="flex justify-end">
    //         <button
    //           onClick={() => {
    //             const currentId = data._id;
    //             const temp = data.templatesId.indexOf(currentId);
    //             onNext(data.templatesId[temp + 1]);
    //           }}
    //           className="flex justify-center items-center text-[#FFFFFF] xs:text-[1rem] md:text-[1.2rem] 2xl:text-[1.5rem] font-normal px-[1rem] py-[0.2rem] md:px-[1.3rem] md:py-[0.3rem] lg:px-[1.6rem] lg:py-[0.45rem] xl:px-[2rem] xl:py-[0.68rem] border-[1px] border-solid rounded-[60px]"
    //         >
    //           <span className="flex space-x-1">
    //             <span>{data.content.buttonText}</span>
    //             <span>&#62;&#62;</span>
    //           </span>
    //         </button>
    //       </div>
    //     </div>
    //   </div>
    //   <div className="flex grow items-end justify-center text-[#F5F5F5]  text-[14px] lg:text-[18px] font-normal p-2">
    //     {data.content.copyrightText}
    //   </div>
    // </div>

    <div
      className={`grow flex flex-col w-full items-center px-5 md:px-28 xl:px-36 ${
        bodyFont && `font-${bodyFont}`
      }`}
    >
      {/* <div className="flex flex-col items-center h-full w-full"> */}
      <div className="grow flex flex-col justify-between py-3">
        <div className="flex w-full justify-start">
          <div
            className={`text-[1.5rem] md:text-[1.5rem] lg:text-[1.5rem] 2xl:text-[1.5rem] font-semibold ${
              headingFont && `font-${headingFont}`
            } `}
          >
            <div 
              style={{color: headingColor, borderColor: headingColor}}
              className={`${!headingColor && "text-[#FFFFFF] border-[#FFFFFF]"} border-l-[5px] md:border-l-[6px] lg:border-l-[6px] xs:pl-[1rem] md:pl-[1.35rem] lg:pl-[1rem]`}>
              {data.content.title}
            </div>
          </div>
        </div>

        <div className="relative w-full rounded-[0.625rem] border border-[#B39B8F] overflow-hidden bg-black">
          {data.content.file ? (
            data.content.file.hasOwnProperty("name") ? (
              <video className="grow" controls>
                <source
                  src={data.content.file?.url ?? ""}
                  type={data.content.file?.type ?? ""}
                />
              </video>
            ) : (
              <ReactWebPlayer url={data.content.file.url} />
            )
          ) : (
            <div className="flex items-center justify-center w-full h-full text-[#FFFFFF] text-[1.3rem] font-normal">
              No Video
            </div>
          )}
        </div>

        <div className="flex justify-end">
          <button
            onClick={() => {
              const currentId = data._id;
              const temp = data.templatesId.indexOf(currentId);
              onNext(data.templatesId[temp + 1]);
            }}
            className="flex justify-center items-center text-[#FFFFFF] xs:text-[0.7rem] md:text-[0.9rem] xl:text-[1.2rem] font-normal px-[0.8rem] py-[0.2rem] md:px-[1rem] md:py-[0.3rem] lg:px-[1.4rem] lg:py-[0.45rem] xl:px-[1.8rem] xl:py-[0.68rem] border-[1px] border-solid rounded-[60px]"
          >
            <span className="flex space-x-1">
              <span>{data.content.buttonText}</span>
              <span>&#62;&#62;</span>
            </span>
          </button>
        </div>
      </div>

      <div className="flex justify-center text-[#F5F5F5]  text-[12px] xl:text-[14px] font-normal pb-[0.2rem]">
        {data.content.copyrightText}
      </div>
      {/* </div> */}
    </div>
  );
}
