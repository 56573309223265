import { Navigation, ExternalLink, Star, Edit } from 'lucide-react';
import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getFormattedDate } from 'src/helpers';
import { ContextMenu, ContextMenuContent, ContextMenuItem, ContextMenuSeparator, ContextMenuTrigger } from '../ui/context-menu';
import { useDispatch } from 'react-redux';
import { setLog } from 'src/features/errorLog/errorLogSlice';
import axios from 'axios'
import { setEditCatalogueCourse } from 'src/features/catalogueCourses/catalogueCoursesSlice';
import Modal from 'src/utils/Modal';
import Text from 'src/utils/Text/Txt';

type Props = {
    catalogueCourse: any;
    handleCheckedItems: (items: any) => void;
    checkedItems: any;
    onChange?: (data: any) => void;
}

function Tile({ catalogueCourse, handleCheckedItems, checkedItems, onChange }: Props) {
    const user: any = useSelector((state: any) => state.user.authUser);
    const navigate = useNavigate()

    const [isSelected, setIsSelected] = useState(false)
    const [passUnitIdToDelete, setPassUnitIdToDelete] = useState<string | null>(
        null
    );
    const [openDeletePopup, setOpenDeletePopup] = useState(false);
    const [openRenamePopup, setOpenRenamePopup] = useState(false);
    const [renamedTitle, setRenamedTitle] = useState<string>("");
    const [renamedId, setRenamedId] = useState<string>("");

    const [program, setProgram] = useState<any>();
    const [openDetailModalProgram, setOpenDetailModalProgram] = useState(false);


    const dispatch = useDispatch();

    const { accessToken } = useSelector((state: any) => state.accessToken);
    const { catalogueCourses } = useSelector(
        (state: any) => state.catalogueCourses
    );

    function handleSelect() {
        const matchedItems = checkedItems.filter((item: any) => item._id === catalogueCourse._id)
        const selected = matchedItems.length > 0
        let newCheckedItems = []
        if (selected) {
            newCheckedItems = checkedItems.filter((item: any) => item._id !== catalogueCourse._id)
            setIsSelected(false)
        } else {
            newCheckedItems = [...checkedItems, catalogueCourse]
            setIsSelected(true)
        }
        handleCheckedItems(newCheckedItems)
    }

    function handleDeletePopup(id: any) {
        setOpenDeletePopup(true);
        setPassUnitIdToDelete(id);
    }

    function handleRenamePopup(catalogueCourse: any) {
        setOpenRenamePopup(true);
        setRenamedTitle((prevState: string) => {
            if (prevState !== catalogueCourse.title) return catalogueCourse.title;
            return "";
        });
        setRenamedId((prevState: string) => {
            if (prevState !== catalogueCourse._id) return catalogueCourse._id;
            return "";
        });
    }

    async function renameTitle(programId: any, title: any) {
        const newTitle = catalogueCourses.filter((item: any) => {
            const value = item.title.toLowerCase();
            return value === title.toLowerCase();
        });
        console.log(newTitle);
        if (newTitle.length > 0) {
            dispatch(
                setLog({
                    mode: "warn",
                    message: "Program of same name already exist!",
                })
            );
            return;
        }
        await axios
            .post(
                `${process.env.REACT_APP_SERVER_BASE_URL}/api/v1/program/rename/${programId}`,
                {
                    title,
                    type: catalogueCourse.type,
                },
                {
                    headers: { Authorization: `Bearer ${accessToken}` },
                }
            )
            .then((res) => res.data)
            .then((response) => {
                console.log(response.data);
                dispatch(setEditCatalogueCourse(response.data));
                dispatch(setLog({ mode: "success", message: response.message }));
                const newCatalogueCourses = catalogueCourses.map(
                    (catalogueCourse: any) => {
                        if (catalogueCourse._id === response.data._id) {
                            return response.data;
                        }
                        return catalogueCourse;
                    }
                );

                if (onChange) onChange(newCatalogueCourses);
                setOpenRenamePopup(false);
            })
            .catch((err) => {
                console.error(err);
                dispatch(setLog({ mode: "failure", message: err }));
            });
    }

    async function handleFavorite(isFavorite: boolean, programId: any) {
        await axios
            .post(
                `${process.env.REACT_APP_SERVER_BASE_URL}/api/v1/program/favorite/post/${programId}`,
                {
                    isFavorite,
                    type: catalogueCourse.type,
                },
                {
                    headers: { Authorization: `Bearer ${accessToken}` },
                }
            )
            .then((res) => res.data)
            .then((response) => {
                console.log(response.data);
                window.location.reload();
                dispatch(setLog({ mode: "success", message: response.message }));
            })
            .catch((err) => {
                console.error(err);
                dispatch(setLog({ mode: "failure", message: err }));
            });
    }

    const handleDelete = async (id: any) => {
        try {
            const response = await axios.delete(
                `${process.env.REACT_APP_SERVER_BASE_URL}/api/v1/program/${id}?type=${catalogueCourse.type}`,
                {
                    headers: { Authorization: `Bearer ${accessToken}` },
                }
            );
            console.log(response.data.data, response.data);
            const newData = catalogueCourses.filter((item: any) => item._id !== id);
            dispatch(setLog({ mode: "success", message: response.data.message }));
            if (onChange) onChange(newData);

            setOpenDeletePopup(false);
        } catch (error) {
            console.log(error);
            dispatch(setLog({ mode: "failure", message: error }));
        }
    };

    async function handleDuplicate(catalogueCourse: any) {
        let max = -1;
        catalogueCourses.forEach((item: any) => {
            if (item.title.includes(catalogueCourse.title)) {
                const value = parseInt(item.title.split("(")[1] || 0);
                console.log(value);
                if (max < value) max = value;
            }
        });
        console.log(max);
        axios
            .post(
                `${process.env.REACT_APP_SERVER_BASE_URL}/api/v1/program/duplicate/${catalogueCourse._id}`,
                {
                    title: catalogueCourse.title + `${max !== -1 ? `(${max + 1})` : ""}`,
                    type: catalogueCourse.type,
                },
                {
                    headers: { Authorization: `Bearer ${accessToken}` },
                }
            )
            .then((respose) => {
                console.log(respose.data);
                window.location.reload();
            })
            .catch((err) => {
                console.error(err);
            });
    }

    function handleDetailsOfProgram(program: any) {
        setOpenDetailModalProgram(true);
        setProgram(program);
    }

    return (
        <>
            <ContextMenu>
                <ContextMenuTrigger>
                    <button onClick={() => handleSelect()} className={`relative group col-span-1 ${isSelected && "border-2 border-tertiary shadow-tertiary shadow-sm rounded-lg"} `}>
                        <div className={`p-2 space-y-2 shadow-lg hover:bg-[#E0CBD9] border-2 rounded-lg ${isSelected && "bg-[#E0CBD9] border-white"} `}>
                            <div className='bg-white flex items-center justify-center'>
                                <img src={catalogueCourse.imageUrl} alt="Course-img" className="h-[5rem] w-[5rem]" />
                            </div>
                            <div className='flex flex-col text-left space-y-1 text-lg font-semibold'>
                                <span>{catalogueCourse.title}</span>
                                <span className='text-xs font-light'>Created by - {user.name}</span>
                                <span className='text-xs font-extralight'>{getFormattedDate(catalogueCourse.modifiedOn / 1)}</span>
                            </div>
                        </div>
                        <div className='hidden absolute top-3 right-1 group-hover:block bg-white p-1.5 rounded-full border'>
                            <Edit role="button" onClick={() => navigate(`/user/${user.oid}/course/${catalogueCourse._id}`)} size={24} />
                        </div>
                        {catalogueCourse.favorite &&
                            <div className='absolute top-2 left-0 bg-transparent p-1.5 rounded-full'>
                                <Star strokeWidth={"2.5"} className='text-yellow-500' size={24} />
                            </div>}
                    </button>
                </ContextMenuTrigger>
                <ContextMenuContent>
                    <ContextMenuItem onClick={() => {
                        navigate(
                            catalogueCourse.type === "sepm"
                                ? `/user/${catalogueCourse.authorId}/businessunit/${catalogueCourse.businessUnitId}/sepm/${catalogueCourse._id}`
                                : catalogueCourse.type === "page"
                                    ? `/user/${catalogueCourse.authorId}/businessunit/${catalogueCourse.businessUnitId}/page/${catalogueCourse._id}`
                                    : `/user/${catalogueCourse.authorId}/course/${catalogueCourse._id}`
                        )
                    }}>Edit</ContextMenuItem>
                    <ContextMenuItem>Preview</ContextMenuItem>
                    <ContextMenuItem>Collaborate</ContextMenuItem>
                    <ContextMenuItem>Share</ContextMenuItem>
                    <ContextMenuItem>Publish</ContextMenuItem>
                    <ContextMenuItem onClick={() => handleDuplicate(catalogueCourse)}>Duplicate</ContextMenuItem>
                    <ContextMenuItem onClick={() => handleRenamePopup(catalogueCourse)}>Rename</ContextMenuItem>
                    <ContextMenuItem onClick={() => handleFavorite(catalogueCourse.favorite, catalogueCourse._id)}>{!catalogueCourse.favorite ? "Favorite" : "Remove Favorite"}</ContextMenuItem>
                    <ContextMenuItem onClick={() => handleDetailsOfProgram(catalogueCourse)}>Details</ContextMenuItem>
                    <ContextMenuSeparator />
                    <ContextMenuItem onClick={() => handleDeletePopup(catalogueCourse._id)} className="text-red-500 focus:bg-red-500 focus:text-white">Delete</ContextMenuItem>
                </ContextMenuContent>
            </ContextMenu>
            <Modal
                onOpened={openDeletePopup}
                onClose={() => setOpenDeletePopup(false)}
                title="Confirm Delete"
                height="calc(100%-400px)"
                passUnitIDtoDelete={passUnitIdToDelete || ""}
            >
                <div className="flex flex-col h-full items-center pt-4 px-4 space-y-4">
                    <div className="flex items-center justify-center w-full py-5">
                        <span>Are you sure you want to delete this course?</span>
                    </div>

                    <div className=" border-t-[0.0625rem] border-[#2C2C2C] py-5 w-full flex justify-center space-x-[4rem] items-center">
                        <button
                            onClick={() => setOpenDeletePopup(false)}
                            className="text-[#FFFFFF] px-5 py-3 text-[16px] rounded-[1.875rem] bg-[#929292]"
                        >
                            Cancel
                        </button>
                        <button
                            onClick={() => {
                                handleDelete(passUnitIdToDelete);
                            }}
                            className="text-[#FFFFFF] px-5 py-3 text-[16px] rounded-[1.875rem] bg-red-600"
                        >
                            Delete
                        </button>
                    </div>
                </div>
            </Modal>
            <Modal
                onOpened={openRenamePopup}
                onClose={() => setOpenRenamePopup(false)}
                title="Rename"
                height="calc(100%-400px)"
            >
                <div className="flex flex-col h-full">
                    <div className="grow">
                        <Text
                            value={renamedTitle}
                            onChange={(e) => setRenamedTitle(e.target.value)}
                            labelTitle="Project name"
                            autoFocus={true}
                        />
                    </div>
                    <div className="pt-[2.31rem] pb-[0.81rem] space-x-[3.94rem] flex justify-center items-center">
                        <button
                            onClick={() => setOpenRenamePopup(false)}
                            className="p-[0.72rem] border-2 border-tertiary rounded-[1.875rem]"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="33"
                                height="33"
                                viewBox="0 0 33 33"
                                fill="none"
                            >
                                <path
                                    d="M25.5 7.5L7.5 25.5"
                                    stroke="#AB0273"
                                    stroke-width="3"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                                <path
                                    d="M25.5 25.5L7.5 7.5"
                                    stroke="#AB0273"
                                    stroke-width="3"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                        </button>
                        <button
                            onClick={() => {
                                renameTitle(renamedId, renamedTitle);
                            }}
                            className="p-[0.72rem] border-2 border-tertiary rounded-[1.875rem]"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="33"
                                height="33"
                                viewBox="0 0 33 33"
                                fill="none"
                            >
                                <path
                                    d="M27.5 9.50063L13.5 23.5L6.5 16.5006"
                                    stroke="#AB0273"
                                    stroke-width="3"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                        </button>
                    </div>
                </div>
            </Modal>
            <Modal
                onOpened={openDetailModalProgram}
                onClose={() => setOpenDetailModalProgram(false)}
                title="Course Details"
                height="calc(100%-400px)"
            >
                <div className="flex flex-col h-full">
                    <div className="grow flex flex-col px-[1.63rem] pt-[1.25rem] space-y-[1.25rem]">
                        <div className="grid grid-cols-8 items-center space-x-3 ">
                            <span className="col-span-3 font-[500] text-[1.125rem] leading-normal text-[#242424]">
                                Program Name:
                            </span>
                            <span className="col-span-5 text-gray-600 leading-normal border-2 p-1 border-[#c2c2c2]">
                                {program?.title}
                            </span>
                        </div>
                        <div className="grid grid-cols-8 items-center space-x-3 ">
                            <span className="col-span-3 font-[500] text-[1.125rem] leading-normal text-[#242424]">
                                Created By:
                            </span>
                            <span className="col-span-5 text-gray-600 leading-normal border-2 p-1 border-[#c2c2c2]">
                                User
                            </span>
                        </div>
                        <div className="grid grid-cols-8 items-center space-x-3 ">
                            <span className="col-span-3 font-[500] text-[1.125rem] leading-normal text-[#242424]">
                                Created On:
                            </span>
                            <span className="col-span-5 text-gray-600 leading-normal border-2 p-1 border-[#c2c2c2]">
                                {getFormattedDate(program?.createdOn / 1)}
                            </span>
                        </div>
                    </div>
                    <div className="pt-[2.31rem] pb-[0.85rem] px-[1rem] space-x-[3.94rem] flex justify-end items-center">
                        <button
                            onClick={() => setOpenDetailModalProgram(false)}
                            className="text-[#FFFFFF] px-4 py-2 text-[16px] rounded-[1.875rem] bg-[#929292]"
                        >
                            Close
                        </button>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default Tile