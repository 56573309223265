import React from "react";
import { useSearchParams } from "react-router-dom";
import { ROOT_FOLDER } from "src/hooks/useFolder";
import HomeLogo from "../../assets/Home.png";

type Props = {
  currentFolder: any;
  onHandleClickedSearchResult: () => void;
  searchInput: string;
};

function FolderBreadcrums({
  currentFolder,
  onHandleClickedSearchResult,
  searchInput,
}: Props) {
  const [searchParams, setSearchParams] = useSearchParams();

  let path = currentFolder === ROOT_FOLDER ? [] : [ROOT_FOLDER];
  if (currentFolder) path = [...path, ...currentFolder.path];

  console.log("this is the path: ", path, currentFolder, currentFolder?.path);

  return (
    <div className="flex h-6 space-x-2 flex-grow-1 px-1">
      {path.map((folder, index) => (
        <div key={index} className="flex space-x-2">
          <button
            onClick={() => {
              console.log("searchParams: ", searchParams)
              setSearchParams({ ...Object.fromEntries(searchParams.entries()), "folderId": folder._id || "null" })
            }
            }
            className="text-truncate d-inline-block"
            style={{ maxWidth: "150px" }}
          >
            {folder.name === "Root" ? (
              <img className="w-5 h-5" src={HomeLogo} alt="icn" />
            ) : folder.name === "" ? (
              "Unknown"
            ) : (
              folder.name
            )}
          </button>
          <span>/</span>
        </div>
      ))}

      {searchInput && (
        <div
          className="flex space-x-1 text-truncate d-inline-block text-white "
          style={{ maxWidth: "200px" }}
        >
          <span>
            <button
              onClick={() => onHandleClickedSearchResult()}
              className="w-5 h-5 hover:border-b-white hover:border-b"
            >
              <img src={HomeLogo} alt="icn" />
            </button>
          </span>
          <span>/</span>
          <span className="whitespace-nowrap">
            Search results for "{searchInput}"
          </span>
        </div>
      )}

      {currentFolder && !searchInput && (
        <button
          className="text-truncate d-inline-block border-b-white border-b text-white"
          style={{ maxWidth: "200px" }}
        >
          {currentFolder.name === "Root" ? (
            <img className="w-5 h-5" src={HomeLogo} alt="icn" />
          ) : currentFolder.name === "" ? (
            "Unknown"
          ) : (
            currentFolder.name
          )}
        </button>
      )}
    </div>
  );
}

export default FolderBreadcrums;
