import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    canvasActiveComponent: null,
};
export const canvasActiveComponentSlice = createSlice({
    name: "canvasActiveComponent",
    initialState,
    reducers: {
        setCanvasActiveComponent: (state, action) => {
            state.canvasActiveComponent = action.payload;
        },
    },
});

export const { setCanvasActiveComponent } = canvasActiveComponentSlice.actions;
export default canvasActiveComponentSlice.reducer;
