import axios from "axios";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
// import HomeLogo from "../assets/home.png";
import {
  setChildFiles,
  setChildFolders,
  setSelectFolder,
  setUpdateFolder,
} from "src/features/fileFolder/fileFolderSlice";
import { ListObjects, getObectURL } from "src/helpers/AWS_S3";

type Props = {
  folderId: string | null;
  folder: any | null;
};

export const ROOT_FOLDER = { name: "Root", _id: null, path: [] };

function useFolder({ folderId = null, folder = null }: Props) {
  const fileFolder = useSelector((state: any) => state.fileFolder);
  const { accessToken } = useSelector((state: any) => state.accessToken);
  const { authUser } = useSelector((state: any) => state.user);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setSelectFolder({ folderId, folder }));
  }, [folderId, folder]);

  useEffect(() => {
    async function getFolder() {
      console.log("I am inside usefolder ", folderId, typeof folderId)
      if (folderId === null) {
        // console.log("this is root: ", ROOT_FOLDER)
        return dispatch(setUpdateFolder({ folder: ROOT_FOLDER }));
      }

      //   const querySnapshot = await getDoc(
      //     doc(collection(database, "folders"), folderId)
      //   );

      //   const res = { id: querySnapshot.id, ...querySnapshot.data() };
      // console.log("this is root: ", ROOT_FOLDER)
      else{
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_BASE_URL}/api/v1/folder/getfolder/${folderId}`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );

      if (response && response.data) {
        console.log("response ", response.data.data);
        dispatch(setUpdateFolder({ folder: response.data.data }));
      } else {
        dispatch(setUpdateFolder({ folder: ROOT_FOLDER }));
      }
    }
    }

    getFolder();
  }, [folderId]);

  useEffect(() => {
    async function getFolders() {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_BASE_URL}/api/v1/folder?folderId=${folderId}`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );

      if (response && response.data) {
        // console.log("childFolders: ", response.data.data)
        dispatch(setChildFolders({ childFolders: response.data.data }));
      }
    }

    getFolders();
  }, [folderId]);

  useEffect(() => {
    async function getFiles() {
      console.log("the url is ", await ListObjects(`uploads/users/${authUser.oid}/folder`))
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_BASE_URL}/api/v1/file?folderId=${folderId}`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );

      if (response && response.data) {
        // console.log("childFolders: ", response.data.data)
        dispatch(setChildFiles({ childFiles: response.data.data }));
      }
      //   const q = query(
      //     collection(database, "files"),
      //     where("folderId", "==", folderId),
      //     where("userId", "==", currentUser.uid),
      //     orderBy("createdAt")
      //   );
      //   const querySnapshot = await getDocs(q);

      // let res: any = [];
      //   querySnapshot.forEach((doc) => {
      //     res.push({ id: doc.id, ...doc.data() });
      //   });

      // dispatch(setChildFiles({ childFiles: res }));
    }

    getFiles();
  }, [folderId]);

  return fileFolder;
}

export default useFolder;
