import React from 'react';
import ReactDOM from 'react-dom';
import { BlockToolConstructorOptions } from '@editorjs/editorjs';
import AudioWithText from './AudioWithText';

class VideoTool {
    data: any;
    api: any;
    wrapper: HTMLElement | null;

    constructor({ data, api }: BlockToolConstructorOptions) {
        this.data = data || {};
        this.api = api;
        this.wrapper = null;
    }

    render() {
        this.wrapper = document.createElement('div');
        this.wrapper.classList.add('audio-tool-wrapper');

        ReactDOM.render(
            <AudioWithText
                data={{...this.data, id:new Date().getTime()}}
                onChange={this.handleDataChange.bind(this)}
            />,
            this.wrapper
        );
        return this.wrapper;
    }

    handleDataChange(data: any) {
        this.data = data;
        // this.api.blocks.save();
    }

    save() {
        return this.data;
    }

    static get toolbox() {
        return {
            title: 'Audio with Text',
            icon: '<svg>...</svg>', // Add your custom icon SVG here
        };
    }
}

export default VideoTool;
