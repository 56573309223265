import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { setSelectedComponent } from 'src/features/ComponentProperty/componentPeopertySlice';
import UploadImage from '../../UploadImage/UploadImage';
import RichTextEditor from 'src/utils/RichTE';
import { v4 as uuid } from "uuid";
import { useSelector } from 'react-redux';
import { dispatch } from 'd3';
import { CircleSlash } from 'lucide-react';
import { GetTemplate } from './utils/GetTemplate';

type Props = {
    handleDataChange: (data: any) => void;
    data: any;
}

function ClickToReveal({ data, handleDataChange }: Props) {

    useEffect(() => {
        console.log("data", data);
    }, [data]);

    return (
        <div className='flex flex-col p-4 w-full space-y-3'>

        </div>
    )
}

export default ClickToReveal