import React, { useState } from "react";
import { ChevronRight } from "@carbon/icons-react";
import { ChevronLeft } from "@carbon/icons-react";
import { useSelector } from "react-redux";
import { setThemeColor } from "src/features/programSettings/programSettingsSlice";

type Props = {
  data: any;
  title: string;
  handleSelectUser: (user: any) => void;
};

export function SelectUser({ data , title, handleSelectUser}: Props) {
    console.log(data);

    const { headingFont, bodyFont, themeColor } = useSelector((state: any) => state.programSettings);
  // const users = data.content.users;
  // console.log(users);

  // Code for PAGINATION: -->>
  // const usersPerPage = 4;

  // const [currentPage, setCurrentPage] = useState(1);

  // const startIndex = (currentPage - 1) * usersPerPage;
  // const endIndex = startIndex + usersPerPage;

  // // Get the users for the current page
  // const displayedUsers = users.slice(startIndex, endIndex);

  // const goToNextPage = () => {
  //   setCurrentPage((prevPage) => prevPage + 1);
  // };

  // const goToPreviousPage = () => {
  //   setCurrentPage((prevPage) => Math.max(prevPage - 1, 1)); // Ensure not to go below page 1
  // };

  return (
    <div className={`w-full h-full flex flex-col space-y-[3rem] md:space-y-[3.4rem] lg:space-y-[5rem] ${bodyFont && `font-${bodyFont}`}`}>
      <div className={`text-[#FFFFFF] text-[1.7rem] md:text-[2rem] lg:text-[2.5rem] font-semibold border-l-4 md:border-l-[7px] pl-[1rem] md:pl-[1.5rem]  leading-normal ${headingFont && `font-${headingFont}`}`}>
        {title}
      </div>

        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-[2.5rem] gap-y-[3rem] lg:gap-y-[6.25rem] lg:gap-x-[8.13rem] pl-0 lg:pl-[1.5rem]">
          {data.map((user: any, idx: number) => (
              <UserCard key={user._id} themeColor={themeColor} user={user} handleSelect={() => handleSelectUser(user)}/>
          ))}
        </div>
    </div>
  );
}


const UserCard: React.FC<{ user: any, themeColor:any, handleSelect: () => void, key: any }> = ({ user, themeColor, handleSelect, key}) => {
  return (
    <button key={key} onClick={() => handleSelect()} className=" flex flex-col">
      <div className="w-full max-w-[14.375rem] h-[14.375rem] flex-shrink-0 overflow-hidden">
        <img
          src={user.file?.url ?? ""}
          alt={`Profile of ${user.name}`}
          className="w-full h-full object-cover flex"
        />
      </div>
      <div style={{backgroundColor: themeColor, opacity: 0.8}} className={`flex w-full max-w-[14.375rem] h-[3.75rem] p-[0.625rem] justify-center items-center flex-shrink-0 text-[#FFFFFF] text-center font-semibold text-[1.25rem] ${!themeColor && "bg-[#2F0220]/80"}`}>
        {user.name}
      </div>
    </button>
  );
};


// Code For Pagination

{/* <div className="overflow-y-scroll no-scrollbar w-full h-full flex flex-col space-y-[3.69rem] mt-[1rem] md:pt-[2.12rem] mx-[3.75rem] ">
      <div className="text-[#FFFFFF] text-[2rem] md:text-[2.5rem] lg:text-[3rem] font-semibold border-l-[6px] md:border-l-8 pl-[1.37rem]">
        {data.content.title}
      </div>

      <div className=" grid grid-cols-12">
        <div className=" relative col-span-7">
          <button
            className={`absolute top-1/2 left-0 text-[#FFFFFF] ${users.length > 4 ? "block" : "hidden"}`}
            onClick={goToPreviousPage}
            disabled={currentPage === 1}
          >
            <ChevronLeft size={40} className="font-bold" />
          </button>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-x-[6.56rem] gap-y-[3.75rem] mx-[4rem]">
            {displayedUsers.map((user: any, idx: number) => (
              <UserCard key={user._id} user={user} />
            ))}
          </div>

          <button
            className={`absolute top-1/2 right-0 text-[#FFFFFF] ${users.length > 4 ? "block" : "hidden"}`}
            onClick={goToNextPage}
            disabled={endIndex >= users.length}
          >
            <ChevronRight size={40} className="font-bold" />
          </button>
        </div>
      </div>
    </div> */}
