import React, { useEffect, useState } from "react";
import PublishLayout from "../PublishLayout";
import { ChevronLeft, Copy } from "@carbon/icons-react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
import InputText from "src/utils/TextInput/InputText";
import Auth from "../Auth";

type Props = {};

function Publish({ }: Props) {

  const [program, setProgram] = useState<any>();
  const [businessUnit, setBusinessUnit] = useState<any>();
  const { businessUnitId, sepmId, templateId, userId } = useParams();

  const { accessToken } = useSelector((state: any) => state.accessToken);

  const navigate = useNavigate();

  useEffect(() => {
    getProgram();
  }, []);

  async function getProgram() {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_BASE_URL}/api/v1/program/detail/${sepmId}`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );

      if (response && response.data) {
        setProgram(response.data.data.program);
        setBusinessUnit(response.data.data.businessUnit);
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <Auth>
      <PublishLayout type="prgram">
        <div className="col-span-12 rounded-[1.25rem] text-[#242424] bg-[#FFFFFF] h-full max-h-[54rem] w-full max-w-[90rem]">
          <section className="flex flex-col space-y-10 overflow-auto w-full h-full no-scrollbar">
            <div className="flex items-center space-x-2 border-b py-[0.81rem]">
              <a
                href={`/user/${userId}/businessunit/${businessUnitId}/sepm/${sepmId}`}
                className="flex items-center space-x-2 cursor-pointer"
              >
                <ChevronLeft size={32} />
              </a>
              <span className="font-[600] leading-normal text-[1.5rem] text-[#242424] uppercase">
                {businessUnit?.title} / {program?.title}
              </span>
            </div>
            <div className="w-full space-y-4 px-4">
              <div className="text-3xl font-bold">Course URL</div>
              <div>To view this course, copy and paste its URL or select Copy Link button.</div>
              <div className="w-full flex space-x-4 items-center">
                <span className="p-4 border-2 rounded-md text-lg font-medium">{process.env.REACT_APP_CLIENT_BASE_URL}/user/{userId}/businessunit/{businessUnitId}/sepm/{sepmId}/preview</span>
                <span>
                  <button
                    onClick={() => {
                      navigator.clipboard.writeText(`${process.env.REACT_APP_CLIENT_BASE_URL}/user/${userId}/businessunit/${businessUnitId}/sepm/${sepmId}/preview`);
                      alert("Copied");
                    }}
                    className="flex w-36 items-center justify-center p-3 text-[#AB0273] border-2 border-[#AB0273] hover:text-white hover:bg-[#AB0273] rounded-full space-x-2"
                  >
                    <Copy size={20} />
                    <span>Copy Link</span>
                  </button>
                </span>
              </div>
            </div>
            <div className="space-y-4 pl-4">
              <div className="text-3xl font-bold">Apps</div>
              <div>To access your apps, click on the publish portal button.</div>
              <a href="https://bravoapp.peakpacificgroup.com">
                <div className="px-4 py-4 font-xl font-semibold text-white bg-[#AB0273] rounded-full max-w-max">
                  Publish Portal
                </div>
              </a>
            </div>
          </section>
        </div>
      </PublishLayout>
    </Auth>
  );
}

export default Publish;
