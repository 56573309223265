
import ReactDOM from 'react-dom';
import { useToastPortal, useToastAutoClose } from 'src/hooks';
import React, { useState, forwardRef, useImperativeHandle, useEffect } from 'react';
import { Toast } from './Toast';
import { uuid } from 'src/helpers';

/**
 * The parent of this component should not have
 * to worry about maintaining a list of message
 * objects. That would require the parent to
 * also manage the deletion of toasts, etc.
 *
 * To accommodate this, we are using a combination
 * of useImperativeHandle and forwardRef to give
 * the parent access to this component's addMessage
 * functionality.
 */
type Props = {
    autoClose: boolean;
    autoCloseTime: number;
}

export type RefType = {
    addMessage: (toast: any) => void
}

export const ToastPortal = forwardRef<RefType, Props>(
  ({ autoClose = false, autoCloseTime = 5000 }, ref) => {
    const [toasts, setToasts] = useState<Array<any>>([]);
    const { loaded, portalId } = useToastPortal();

    // console.log(loaded, portalId)
    useEffect(() => {
        // console.log(toasts)
    }, [toasts])

    useToastAutoClose({
      toasts,
      setToasts,
      autoClose,
      autoCloseTime,
    });

    const removeToast = (id: any) => {
      setToasts(toasts.filter(t => t.id !== id));
    };

    useImperativeHandle(ref, () => ({
      addMessage(toast: any) {
        console.log(toast)
        setToasts([...toasts, { ...toast, id: uuid() }]);
      },
    }));

    return loaded ? (
      ReactDOM.createPortal(
        <div className="flex flex-col space-y-2">
          {toasts.map((t: any) => (
            <Toast
              key={t.id}
              mode={t.mode}
              message={t.message}
              onClose={() => removeToast(t.id)}
            />
          ))}
        </div>,

        document.getElementById(portalId)!,
      )
    ) : (
      <></>
    );
  },
);