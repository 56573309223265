import { CircleSlash, LayoutList, List, ListTodo, PanelTop } from 'lucide-react'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { setSelectedTemplate } from 'src/features/courseTemplate/courseTemplateSlice';
import { DefaultTemplateData } from './Templates/Authoring/utils/DefaultTemplateData';

type Props = {
    insertDropdown: boolean;
    setInsertDropdown: (insertDropdown: boolean) => void;
    handleOpenTemplate?: () => void;
}

const TemplatesArray = [
    {
        id: 1,
        title: "MCR",
        type: "mcr",
        description: "Multiple Choice Response",
        icon: <ListTodo size={24} />
    },
    {
        id: 2,
        title: "SCR",
        type: "scr",
        description: "Single Choice Response",
        icon: <List size={24} />
    },
    {
        id: 3,
        title: "True/False",
        type: "true/false",
        description: "True or False",
        icon: <CircleSlash size={24} />
    },
    {
        id: 4,
        title: "Text with Image",
        type: "textwithimage",
        description: "Text with Image",
        icon: <LayoutList size={24} />
    },
    {
        id: 5,
        title: "Tabs",
        type: "tabs",
        description: "Tabs",
        icon: <PanelTop size={24} />
    },
    {
        id: 6,
        title: "ClickToReveal",
        type: "clicktoreveal",
        description: "Click to Reveal the content",
        icon: <PanelTop size={24} />
    },
]

export type Template = {
    id: number;
    title: string;
    type: string;
    description: string;
    data?: any;
}

function TemplateRibbon({ insertDropdown, setInsertDropdown, handleOpenTemplate}: Props) {

    const dispatch = useDispatch();

    function handleTemplateSelection(template: Template) {
        dispatch(setSelectedTemplate({
            id: new Date().getTime(),
            title: template.title,
            type: template.type,
            description: template.description,
            data: template.data || DefaultTemplateData.get(template.type)
        }))
    }

    return (
        <div className="absolute top-[9rem] flex flex-col p-3 bg-gray-100 border border-gray-300 space-y-2 shadow-lg rounded-lg z-10">
            {TemplatesArray.map((template, index) => (
                // <div className="relative group" >
                    <button key={template.id} onClick={() => {handleTemplateSelection(template); setInsertDropdown(!insertDropdown); if(handleOpenTemplate) handleOpenTemplate();}} className='w-full px-2 py-1 flex space-x-2 items-center hover:bg-gray-300 rounded-md'>
                        <span>{template.icon}</span>
                        <span className=" px-2 py-1 max-w-max whitespace-nowrap text-sm rounded ">
                            {template.title}
                        </span>
                    </button>
                //     <span className="hidden absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-2 py-1 max-w-max whitespace-nowrap bg-black text-white text-center text-sm rounded opacity-0 group-hover:block group-hover:opacity-100 transition-opacity duration-300">
                //         {template.description}
                //     </span>
                // </div>
            ))}
        </div>
    )
}

export default TemplateRibbon