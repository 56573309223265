import React from "react";
import Logo from "../../assets/logowhite.png";
import AirPlaneLogo from "../../assets/preview/Airplane.svg";
import HomeLogo from "../../assets/House.svg";
import DownloadLogo from "../../assets/preview/DownloadSimple.svg";
import BellLogo from "../../assets/preview/Bell.svg";
import ListLogo from "../../assets/preview/List.svg";
import { useSelector } from "react-redux";

type Props = {
  gotoMenuScreen: () => void;
  gotoAircraftScreen: () => void;
  gotoUserScreen: () => void;
  user: any;
  aircraft: any;
  menu: any;
};

function OutputNav({
  gotoMenuScreen,
  gotoAircraftScreen,
  gotoUserScreen,
  user,
  aircraft,
  menu,
}: Props) {

  const { logoImageFile, themeColor, headingFont, bodyFont } = useSelector((state: any) => state.programSettings);

  return (
    <div style={{backgroundColor: themeColor}} className={` w-full max-w-[90rem] xs:h-[3.5rem] sm:h-[4rem] md:h-[4.6rem] lg:h-[5.125rem] xl:h-[5.625rem] grid grid-cols-2 items-center ${!themeColor && "bg-tertiary"} pl-4`}>
      <div className="col-span-1 h-full flex items-center">
        {logoImageFile ? 
          (<img className="xs:h-[2.5rem] sm:h-[2.7rem] md:h-[2.9rem] lg:h-[3.125rem] w-auto " src={logoImageFile?.url} alt="logo" />)
          :
          (<img className="xs:h-[2.5rem] sm:h-[2.7rem] md:h-[2.9rem] lg:h-[3.125rem] w-auto " src={Logo} alt="logo" />)
      }
      </div>
      <div className="col-span-1 h-full flex items-center justify-end pr-[1.81rem] space-x-[3rem]">
        {user && aircraft && menu && (
          <button onClick={() => gotoMenuScreen()}>
            <img src={HomeLogo} alt="homeLogo" className="xs:h-6 xs:w-6 sm:h-7 sm:w-7 md:h-8 md:w-8" />
            {/* <svg
              xmlns="http://www.w3.org/2000/svg"
              width="26"
              height="27"
              viewBox="0 0 26 27"
              fill="none"
            >
              <path
                d="M15.9993 23.9991V17.9989C15.9993 17.7337 15.8939 17.4794 15.7064 17.2918C15.5188 17.1043 15.2645 16.9989 14.9993 16.9989H10.9993C10.7341 16.9989 10.4797 17.1043 10.2922 17.2918C10.1046 17.4794 9.99927 17.7337 9.99927 17.9989V23.9991C9.99927 24.2643 9.89393 24.5186 9.70642 24.7061C9.51891 24.8937 9.26459 24.999 8.99939 24.9991L3.00012 24.9999C2.86879 24.9999 2.73874 24.974 2.6174 24.9238C2.49606 24.8735 2.38581 24.7999 2.29294 24.707C2.20007 24.6142 2.12639 24.5039 2.07613 24.3826C2.02587 24.2612 2 24.1312 2 23.9999V12.4423C2 12.303 2.02911 12.1652 2.08547 12.0378C2.14183 11.9104 2.22418 11.7962 2.32726 11.7024L12.3266 2.61058C12.5106 2.44322 12.7505 2.35047 12.9993 2.35046C13.2481 2.35046 13.4879 2.44318 13.672 2.61054L23.6727 11.7024C23.7758 11.7962 23.8582 11.9104 23.9145 12.0378C23.9709 12.1652 24 12.303 24 12.4424V23.9999C24 24.1312 23.9741 24.2612 23.9239 24.3826C23.8736 24.5039 23.7999 24.6142 23.7071 24.707C23.6142 24.7999 23.5039 24.8735 23.3826 24.9238C23.2613 24.974 23.1312 24.9999 22.9999 24.9999L16.9991 24.9991C16.7339 24.999 16.4796 24.8937 16.2921 24.7061C16.1046 24.5186 15.9993 24.2643 15.9993 23.9991V23.9991Z"
                stroke="white"
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg> */}
          </button>
        )}
        {user && aircraft && (
          <button onClick={() => gotoAircraftScreen()}>
            <img src={AirPlaneLogo} alt="aeroplaneLogo" className="xs:h-6 xs:w-6 sm:h-7 sm:w-7 md:h-8 md:w-8" />
          </button>
        )}
        {/* {menu &&
          <button onClick={() => gotoUserScreen()}>
            <img src={DownloadLogo} alt="downloadLogo" />
          </button>
        } */}
        <button>
          <img src={BellLogo} alt="bellLogo" className="xs:h-6 xs:w-6 sm:h-7 sm:w-7 md:h-8 md:w-8" />
        </button>
        <button>
          <img src={ListLogo} alt="listLogo" className="xs:h-6 xs:w-6 sm:h-7 sm:w-7 md:h-8 md:w-8" />
        </button>
      </div>
    </div>
  );
}

export default OutputNav;
