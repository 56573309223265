import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  logoImageFile: null,
  themeColor: "",
  headingFont: "",
  bodyFont: "",
  headingColor: "",
  thumbnailHeadingColor: "",
  thumbnailTitleColor: "",
} as {
    logoImageFile: any | null;
    themeColor: string;
    headingFont: string;
    bodyFont: string;
    headingColor: string;
    thumbnailHeadingColor: string;
    thumbnailTitleColor: string;
};
export const programSettingsSlice = createSlice({
  name: "programSettings",
  initialState,
  reducers: {
    setLogoImageFile: (state, action) => {
      state.logoImageFile = action.payload;
    },
    setThemeColor: (state, action) => {
      state.themeColor = action.payload;
    },
    setHeadingFont: (state, action) => {
      state.headingFont = action.payload;
    },
    setBodyFont: (state, action) => {
      state.bodyFont = action.payload;
    },
    setHeadingColor: (state, action) => {
      state.headingColor = action.payload;
    },
    setThumbnailHeadingColor: (state, action) => {
      state.thumbnailHeadingColor = action.payload;
    },
    setThumbnailTitleColor: (state, action) => {
      state.thumbnailTitleColor = action.payload;
    },
  },
});

export const { setLogoImageFile, setThemeColor, setHeadingFont, setBodyFont, setHeadingColor, setThumbnailHeadingColor, setThumbnailTitleColor } = programSettingsSlice.actions;
export default programSettingsSlice.reducer;
