import axios from "axios";
import React, { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { setLog } from "src/features/errorLog/errorLogSlice";
import { setAddToChildFolders } from "src/features/fileFolder/fileFolderSlice";
import useFolder, { ROOT_FOLDER } from "src/hooks/useFolder";
import Modal from "src/utils/Modal";
import Text from "src/utils/Text/Txt";

type Props = {
};

function AddFolderButton({  }: Props) {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");

  const { accessToken } = useSelector((state: any) => state.accessToken);
  const [ searchParams, setSearchParams ]: any = useSearchParams();
  const folderId = searchParams.get("folderId");
  const { folder } = useSelector((state: any) => state.fileFolder);

  const {fileFolder} = useFolder({ folderId: (folderId === "null" ? null : folderId), folder });
  const currentFolder = fileFolder?.folder

  console.log(fileFolder, currentFolder)

  const dispatch = useDispatch()


  function openModal() {
    setOpen(true);
  }

  function closeModal() {
    setOpen(false);
  }

  async function handleSubmit() {
    console.log("clicked", currentFolder, currentFolder.path)
    if (currentFolder == null) return;
    
    const path = [...currentFolder.path];
    console.log("clicked path", path)
    if (currentFolder !== ROOT_FOLDER) {
      path.push({ name: currentFolder.name, _id: currentFolder._id });
    }
    
    console.log("clicked path", path)
    try {
      //   const res = await addDoc(collection(database, "folders"), {
      //     name: name,
      //     parentId: currentFolder._id,
      //     userId: currentUser.uid,
      //     path: path,
      //     createdAt: new Date().getTime(),
      //   })

      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_BASE_URL}/api/v1/folder/`,
        {
          name: name,
          parentId: currentFolder._id,
          path: path,
        },
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );
      console.log("Added folder", response.data.data);
      dispatch(setAddToChildFolders(response.data.data))
      dispatch(setLog({ mode: "success", message: response.data.message }))
    } catch (e) {
      console.log(e);
      dispatch(setLog({ mode: "failure", message: e }))
    }

    setName("");
    closeModal();
  }

  return (
    <div>
      <button
        onClick={openModal}
        className="flex space-x-1 bg-[#AB0273] text-[#FFFFFF] rounded-lg px-2 py-2 hover:bg-[#AB0273]/80"
      >
        <div>📁</div>
        <div className="whitespace-nowrap">New Folder</div>
      </button>
      <Modal
        onOpened={open}
        onClose={() => setOpen(false)}
        title="Create Folder"
        height="calc(100%-400px)"
      >
        <div className="flex flex-col h-full">
          <div className="grow space-y-4">
            <div>
              <Text
                value={name}
                onChange={(e) => setName(e.target.value)}
                autoFocus={true}
                labelTitle=""
              />
            </div>
          </div>
          <div className=" border-t p-5 flex justify-between items-center">
            <button
              onClick={() => handleSubmit()}
              className="px-7 py-3 rounded-md text-white bg-primary"
            >
              Done
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default AddFolderButton;
