import React from 'react'
import Imageicon from "../../assets/Upload/Image.svg";
import ViewIcon from "../../assets/Upload/Eye.svg";
import DeleteIcon from "../../assets/Upload/TrashSimple.svg";
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setPageState } from 'src/features/templatePageState/templatePageStateSlice';
import Modal from 'src/utils/Modal';
import { ReactWebPlayer } from '../MediaLibrary/FileType/Video';

type Props = {
  openDrawerForm?: any;
  pageState?: any;
  type?: string | null;
  name?: string;
  handleClick?: (name: string) => void;
  handleCropFile?: (file: any) => void;
  file?: any;
}

function VideoDropdown({ openDrawerForm, pageState, type, name, file, handleClick, handleCropFile }: Props) {
  const dispatch = useDispatch()

  const navigate = useNavigate();

  const [searchParams, setSearchParams]: any = useSearchParams();

  const { businessUnitId, sepmId, templateId, userId } = useParams();
  const [viewVideo, setViewVideo] = React.useState(false);

  const handleChangeMediaButtonClick = () => {
    if (handleClick && name) handleClick(name);
    navigate(
      `/user/${userId}/businessunit/${businessUnitId}/sepm/${sepmId}/template/${templateId}/media?screen=${searchParams.get(
        "screen"
      )}&folderId=null&type=${type}`
    );
    if (pageState) dispatch(setPageState(pageState));
  };

  async function removeFile() {
    if (handleCropFile) handleCropFile(null);
  }

  return (
    <>
      {openDrawerForm ? (
        <div className="absolute bg-[#F5F5F5] w-[13rem] border-2 border-black/30 rounded-[1.5rem] py-[1.13rem] px-[1.06rem] space-y-[1.2rem]">

          <div className="flex space-x-[1.3rem] w-full text-[1rem] text-[#242424] items-center">
            <span>
              <img src={Imageicon} alt="" />
            </span>
            <button onClick={handleChangeMediaButtonClick}>Change Video</button>
          </div>

          <div className="flex space-x-[1.3rem] w-full text-[1rem] text-[#242424] items-center">
            <span>
              <img src={ViewIcon} alt="" />
            </span>
            <button onClick={() => setViewVideo(true)}>View Video</button>
          </div>
          <div className="bg-[#2C2C2C] h-[0.063rem]"></div>
          <div className="flex space-x-[1.3rem] w-full text-[1rem] text-[#C2262E] items-center">
            <span>
              <img src={DeleteIcon} alt="" />
            </span>
            <button onClick={removeFile}>Remove</button>
          </div>
        </div>
      ) : (
        <div className="absolute inline-block ml-1 w-[13rem] bg-[#F5F5F5] border-2 border-black/30 rounded-[1.5rem] py-[1.13rem] px-[1.06rem] space-y-[1.2rem]">

          <div className="flex space-x-[1.3rem] w-full text-[1rem] text-[#242424] items-center">
            <span>
              <img src={Imageicon} alt="" />
            </span>
            <button onClick={handleChangeMediaButtonClick}>Change Video</button>
          </div>

          <div className="flex space-x-[1.3rem] w-full text-[1rem] text-[#242424] items-center">
            <span>
              <img src={ViewIcon} alt="" />
            </span>
            <button onClick={() => setViewVideo(true)}>View Video</button>
          </div>
          <div className="bg-[#2C2C2C] h-[0.063rem]"></div>
          <div className="flex space-x-[1.3rem] w-full text-[1rem] text-[#C2262E] items-center">
            <span>
              <img src={DeleteIcon} alt="" />
            </span>
            <button onClick={removeFile}>Remove</button>
          </div>
        </div>


      )}


      <Modal className='flex items-center justify-center' title='Video' onOpened={viewVideo} onClose={() => setViewVideo(false)}>
        <div className='max-w-[90vw] max-h-[90vh] flex items-center justify-center'>
          <div className='aspect-h-16 aspect-w-9 max-w-full max-h-full overflow-hidden'>
            {file.hasOwnProperty("name") ? (
              <video
                className="h-56 rounded-[1.5rem] border-2 border-black/30"
                controls
              >
                <source src={file?.url} type={file?.type} />
              </video>) : (
              <ReactWebPlayer url={file?.url} />
            )}
          </div>
        </div>
      </Modal>
    </>
  )
}

export default VideoDropdown;
