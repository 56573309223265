import React, { useEffect, useState } from "react";
import PublishLayout from "../PublishLayout";
import { ChevronLeft, User } from "@carbon/icons-react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
import Modal from "src/utils/Modal";
import InputText from "src/utils/TextInput/InputText";
import TextArea from "src/utils/TextInput/TextArea";
import { useDispatch } from "react-redux";
import { setLog } from "src/features/errorLog/errorLogSlice";
import Auth from "../Auth";

type Props = {};

function Share({ }: Props) {

    const [program, setProgram] = useState<any>();
    const [businessUnit, setBusinessUnit] = useState<any>();
    const { businessUnitId, sepmId, templateId, userId } = useParams();

    const [AddPeopleModal, setAddPeopleModal] = useState(false)
    const [email, setEmail] = useState("")
    const [message, setMessage] = useState("")

    const [accessUsers, setAccessUsers] = useState<Array<any>>([])

    const { accessToken } = useSelector((state: any) => state.accessToken);

    const dispatch = useDispatch();

    useEffect(() => {
        getProgram();
        getAccessList();
    }, []);

    async function getProgram() {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVER_BASE_URL}/api/v1/program/detail/${sepmId}`,
                {
                    headers: { Authorization: `Bearer ${accessToken}` },
                }
            );

            if (response && response.data) {
                setProgram(response.data.data.program);
                setBusinessUnit(response.data.data.businessUnit);
            }
        } catch (err: any) {
            console.log(err);
            dispatch(
                setLog({ mode: "failure", message: err.response.data.message })
            );
        }
    }

    async function getAccessList() {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVER_BASE_URL}/api/v1/userrole/getaccesslist/course/${sepmId}`,
                {
                    headers: { Authorization: `Bearer ${accessToken}` },
                }
            );

            if (response && response.data) {
                console.log(response.data)
                setAccessUsers(response.data.data);
            }
        } catch (err: any) {
            console.log(err);
            dispatch(
                setLog({ mode: "failure", message: err.response.data.message })
            );
        }
    }

    async function handleSend() {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVER_BASE_URL}/api/v1/userrole/add`,
                {
                    type: "course",
                    typeId: sepmId,
                    user: {
                        email,
                        message
                    }
                },
                {
                    headers: { Authorization: `Bearer ${accessToken}` },
                },
            );

            if (response && response.data) {
                console.log(response.data)
                dispatch(setLog({ mode: "success", message: response.data.message }));
                setAddPeopleModal(false);
                window.location.reload();
            }
        } catch (err: any) {
            console.log(err);
            dispatch(
                setLog({ mode: "failure", message: err.response.data.message })
            );
        }
    }

    async function handleRemovePermission(_id: any) {
        await axios
            .delete(
                `${process.env.REACT_APP_SERVER_BASE_URL}/api/v1/userrole/remove/${_id}`,
                {
                    headers: { Authorization: `Bearer ${accessToken}` },
                }
            )
            .then((res) => res.data)
            .then((response) => {

                dispatch(setLog({ mode: "success", message: response.message }));
                window.location.reload();
            })
            .catch((err) => {
                console.error(err);
                dispatch(
                    setLog({ mode: "failure", message: err.response.data.message })
                );
            });
    }

    return (
        <Auth>
            <PublishLayout type="prgram">
                <div className="col-span-12 rounded-[1.25rem] text-[#242424] bg-[#FFFFFF] h-full max-h-[54rem]">
                    <section className="flex flex-col space-y-10 overflow-auto w-full h-full no-scrollbar">
                        <div className="flex items-center space-x-2 border-b py-[0.81rem]">
                            <a
                                href={`/user/${userId}/businessunit/${businessUnitId}/sepm/${sepmId}`}
                                className="flex items-center space-x-2 cursor-pointer"
                            >
                                <ChevronLeft size={32} />
                            </a>
                            <span className="font-[600] leading-normal text-[1.5rem] text-[#242424] uppercase">
                                {businessUnit?.title} / {program?.title}
                            </span>
                        </div>
                        <div className="px-10 space-y-8">
                            <div className="flex space-x-10 items-center">
                                <span>Only people with emails listed below will have access to the course.</span>
                                <button onClick={() => setAddPeopleModal(true)} className="px-3 py-2 rounded-full border-2 hover:bg-[#AB0273] hover:text-white">Add people</button>
                            </div>
                            <div className="max-w-[30rem] space-y-2">
                                <div className="flex space-x-4 border-b-2 ">
                                    <span className="text-xl font-semibold">People</span>
                                    <span className="text-xl font-semibold">{accessUsers.length > 0 ? accessUsers.length : 0}</span>
                                </div>
                                <div className="space-y-2 max-h-[30rem] overflow-auto no-scrollbar">
                                    {
                                        accessUsers.map((item) => {
                                            return (
                                                <div className="flex items-center space-x-4 py-2">
                                                    <span>
                                                        <User size={32} />
                                                    </span>
                                                    <div className="w-full flex items-center justify-between">
                                                        <span className="">{item.user.email}</span>
                                                        <button onClick={() => handleRemovePermission(item._id)} className="px-3 py-2 bg-[#AB0273] rounded-md text-white">stop sharing</button>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </section>

                    <Modal
                        onOpened={AddPeopleModal}
                        onClose={() => setAddPeopleModal(false)}
                        title={`Share "SEPM" Course`}
                    >
                        <>
                            <div className="space-y-4 p-4">
                                <InputText placeholder="Add email" value={email} onChange={(e) => setEmail(e.target.value)} />

                                <TextArea placeholder="Add a message" value={message} onChange={(e) => setMessage(e.target.value)} />
                            </div>
                            <div className="pb-4 px-4 flex justify-end">
                                <button onClick={() => handleSend()} className="px-3 py-2 rounded-md bg-[#AB0273] text-white" >Send</button>
                            </div>
                        </>
                    </Modal>
                </div>
            </PublishLayout>
        </Auth>
    );
}

export default Share;
