import React from 'react'

type Props = {
  id: Number
}

function TwoColText(props: Props) {
  return (
    <div>TwoColText</div>
  )
}

export default TwoColText