import React, { useEffect, useState } from "react";
import InputText from "src/utils/TextInput/InputText";
import UploadButton from "src/utils/UploadButton";
import MultipleCheck from "src/utils/MultipleCheck";
import InputDate from "src/utils/TextInput/Date";
import UploadDropdowns from "../UploadDropdowns";

type QrhProps = {
  state?: any;
  openDrawerForm?: any;
  users: any[];
  onHandleDocument: (selectedOptions: any) => void;
  document: any;
};

function QRHDoc({ state, openDrawerForm, users, document, onHandleDocument }: QrhProps) {
  const [title, setTitle] = useState(document?.title);
  const [date, setDate] = useState(document?.date);
  const [savedSelectedOptions, setSavedSelectedOptions] = useState<Array<any>>(
    document?.selectedOptions || []
  );
  const [File, setFile] = useState<any>(document?.file);

  useEffect(() => {
    console.log("this is document: ", document, state);

    if (state) {
      if (state.title) setTitle(state.title)
      if (state.date) setDate(state.date)
      if (state.savedSelectedOptions) setSavedSelectedOptions(state.savedSelectedOptions)
      setFile(state.file || state.File)
    }

  }, [state]);

  useEffect(() => {
    console.log(savedSelectedOptions)
    handleContentChange();
  }, [title, savedSelectedOptions, date, File]);

  function handleContentChange() {
    if ((state && state._id !== document?._id)) return
    const newDocument = {
      aircraftId: document.aircraftId,
      _id: document._id,
      title,
      date,
      file: File,
      selectedOptions: savedSelectedOptions,
    };
    console.log(newDocument)
    onHandleDocument(newDocument);
  }

  return (
    <div className="">
      <div className="pt-[1.25rem] px-[0.45rem]">
        <InputText
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder="Document Title"
          labelTitle=""
        />
      </div>
      <div className="pt-[1.25rem] px-[0.45rem]">
        <InputDate
          value={date}
          onChange={(e) => setDate(e.target.value)}
          placeholder="Enter date"
          labelTitle="Date"
        />
      </div>
      <div className="flex flex-col space-y-[0.94rem] pt-[1.25rem] px-[0.94rem]">
        <div className="text-[16px] font-[600]">
          Safety Training QRH Document
        </div>
        <div className="flex space-x-3 items-center">
          <UploadButton type="application" pageState={{
            // ...state, 
            File,
            "title": title,
            "date": date,
            "savedSelectedOptions": savedSelectedOptions,
            "openDrawerForm": openDrawerForm,
            "_id": document?._id
          }} 
            handleUploadFromDevice={(file) => {
              setFile(file);
              // console.log(file);
            }}
          />
          <UploadDropdowns openDrawerForm={openDrawerForm} File={File} />
        </div>
      </div>
      <div className="flex flex-col space-y-[0.94rem] pt-[1.25rem] px-[0.94rem]">
        <div className="text-[16px] font-[600]">
          Select Users for this document
        </div>
        <MultipleCheck
          SelectTitle="Select Users"
          options={users}
          savedSelectedOptions={state && state._id === document?._id ? state.savedSelectedOptions : savedSelectedOptions}
          onHandleSelectedOptions={(selectedOptions) =>
            setSavedSelectedOptions(selectedOptions)
          }
        />
      </div>
    </div>
  );
}

export default QRHDoc;