import React, { useEffect } from 'react'
import Layout from './Layout'
import { useSelector } from 'react-redux';
import { PlusCircle } from 'lucide-react';
import Editor from './Editor';

type Props = {}

function Page({ }: Props) {
  const [topic, setTopic] = React.useState<any>(null);
  const user = useSelector((state: any) => state.user.authUser);
  const [isTopicSelected, setIsTopicSelected] = React.useState(false);

  useEffect(() => {
    console.log('topic:', topic, 'isTopicSelected:', isTopicSelected);
  }, [topic, isTopicSelected])


  return (
    <Layout handleTopic={(topic) => {setTopic(topic); setIsTopicSelected(true)}} topicContent={topic?.content} >
      <div className='h-full w-full max-w-[70rem] flex flex-col items-center justify-center space-y-4 overflow-y-scroll no-scrollbar'>
        {topic ? (
          <Editor
            value={topic?.content}
            topic={topic}
            onChange={(value) => setTopic({ ...topic, content: value })}
            handleIsTopicSelected={() => setIsTopicSelected(false)}
            isTopicSelected={isTopicSelected}
          />)
          : (<h2 className='text-lg font-medium'>
            Select a topic to get started
          </h2>)
        }
      </div>
    </Layout>
  )
}

export default Page