import React, { useState, useEffect, ChangeEvent, useRef } from "react";
// @ts-ignore
import VideoSymbol from "../../../assets/videoSymbol.png";
// @ts-ignore
import TextSymbol from "../../../assets/textSymbol.png";
// @ts-ignore
import arrow from "../../../assets/arrow.png";
import { ChevronUp } from "lucide-react";
// import RichTextEditor from "src/utils/RichTE";

type Props = {
  data: {
    videoSrc: string | null;
    text: string;
    id:string;
  };
  onChange: (data: { videoSrc: string | null; text: string,id:string }) => void;
};

const layouts = [
  "videoLeftTextRight",
  "videoTopTextBottom",
  "videoRightTextLeft",
  "videoBottomTextTop",
  "videoOnly",
];

function VideoWithText({ data, onChange}: Props) {
  const [videoSrc, setVideoSrc] = useState<string | null>(
    data?.videoSrc || null
  );
  const [text, setText] = useState<string>(data?.text || "");
  const [File, setFile] = useState<any | null>(null);
  const [layoutIndex, setLayoutIndex] = useState<number>(0);
  const [videoSelected, setVideoSelected] = useState<boolean>(false);
  const [showLayoutButtons, setShowLayoutButtons] = useState<boolean>(false);
  const [showButton, setShowButton] = useState<boolean>(false);
  const [buttonPosition, setButtonPosition] = useState<{
    top: number;
    left: number;
  }>({ top: 0, left: 0 });

  const containerRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const tooltipRef = useRef<HTMLDivElement>(null);


  useEffect(() => {
    onChange({ ...data, videoSrc, text });
  }, [videoSrc, text, onChange]);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node) &&
        !tooltipRef.current?.contains(event.target as Node)
      ) {
        setShowLayoutButtons(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [containerRef]);

  const handleVideoUpload = (event: ChangeEvent<HTMLInputElement>) => {
   
    const file = event.target.files?.[0];
    if (file) {
      const videoURL = URL.createObjectURL(file);
      setVideoSrc(videoURL);
      setVideoSelected(true);
    }
    event.target.value="";
  };

  const handleTextChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setText(event.target.value);
    setShowLayoutButtons(false); // Hides the tooltip when interacting with text area
  };

  const handleLayoutButtonClick = (index: number) => {
    setLayoutIndex(index);
  };

  const handleVideoMouseEnter = () => {
    setShowButton(true);
  };

  const handleVideoMouseLeave = () => {
    setShowButton(false);
  };

  const handleButtonClicked = () => {
    if (buttonRef.current && containerRef.current) {
      const buttonRect = buttonRef.current.getBoundingClientRect();
      const containerRect = containerRef.current.getBoundingClientRect();

      const topPosition = buttonRect.top - containerRect.top - 110;
      const leftPosition = buttonRect.left - containerRect.left - 20;

      const adjustedTopPosition = topPosition;

      setButtonPosition({ top: adjustedTopPosition, left: leftPosition });
      setShowLayoutButtons(true); // Show tooltip only when the pop-up button is clicked
    }
  };

  const handleContainerMouseLeave = () => {
    setShowButton(false);
    setShowLayoutButtons(false); // Hide the tooltip when leaving container
  };

  return (
    <div
      ref={containerRef}
      className="border border-gray-300 bg-[#E0CBD9] p-5 mt-5 mb-10 rounded-lg relative"
      onMouseLeave={handleContainerMouseLeave}
    >
      <div>
        <input id={`input-${data.id}`}
          type="file"
          accept="video/*"
          onChange={(e)=>{handleVideoUpload(e)}}
          className="border-none hidden text-[400] font-[400] font-semibold focus:outline-none"
        />
        <label className="bg-[#AB0273] text-white text-[1rem] font-[400]  px-3 py-2 rounded-md" htmlFor={`input-${data.id}`}><span>Upload</span></label>
      </div>
      
      <div className="relative">
        <div className="relative">
          <div
            onMouseEnter={handleVideoMouseEnter}
            onMouseLeave={handleVideoMouseLeave}
            className="relative"
          >
            {/* {videoSelected && ( */}
            <div className="absolute">
              <button
                ref={buttonRef}
                className={`relative flex items-center bg-white rounded-md w-14 h-7 p-1 ${
                  showButton ? "block" : "hidden"
                }`}
                style={{ zIndex: 999, left: "10px" }}
                onClick={handleButtonClicked} // Moved onClick handler to the button itself
              >
                <img src={VideoSymbol} alt="video" className="w-7 h-7" />
                <ChevronUp />
              </button>
            </div>
            {/* )} */}

            {showLayoutButtons && (
              <div
                ref={tooltipRef}
                className="absolute bg-white p-2 border border-gray-200 rounded-lg z-10"
                style={{
                  top: buttonPosition.top - 15,
                  left: buttonPosition.left - 20,
                  width: "250px",
                  minWidth: "150px",
                }}
              >
                <div className="flex">
                  {layouts.map((layout, idx) => (
                    <button
                      key={idx}
                      onClick={() => handleLayoutButtonClick(idx)}
                      className="px-1 py-2 w-20 rounded-lg flex justify-center items-center hover:shadow-lg bg-[#c1bebe]"
                      style={{ marginRight: "2px" }}
                    >
                      {layout === "videoLeftTextRight" && (
                        <div className="flex items-center">
                          <img
                            src={VideoSymbol}
                            alt="video"
                            className="w-4 h-4"
                          />
                          <img
                            src={TextSymbol}
                            alt="text"
                            className="w-4 h-4"
                          />
                        </div>
                      )}
                      {layout === "videoTopTextBottom" && (
                        <div className="flex flex-col items-center">
                          <img
                            src={VideoSymbol}
                            alt="video"
                            className="w-4 h-4"
                          />
                          <img
                            src={TextSymbol}
                            alt="text"
                            className="w-4 h-4"
                          />
                        </div>
                      )}
                      {layout === "videoRightTextLeft" && (
                        <div className="flex items-center justify-between">
                          <img
                            src={TextSymbol}
                            alt="text"
                            className="w-4 h-4"
                          />
                          <img
                            src={VideoSymbol}
                            alt="video"
                            className="w-4 h-4"
                          />
                        </div>
                      )}
                      {layout === "videoBottomTextTop" && (
                        <div className="flex flex-col items-center">
                          <img
                            src={TextSymbol}
                            alt="text"
                            className="w-4 h-4"
                          />
                          <img
                            src={VideoSymbol}
                            alt="video"
                            className="w-4 h-4"
                          />
                        </div>
                      )}
                      {layout === "videoOnly" && (
                        <img
                          src={VideoSymbol}
                          alt="video"
                          className="w-4 h-4"
                        />
                      )}
                    </button>
                  ))}
                </div>
              </div>
            )}

            <div
              className={`${
                layoutIndex % 2 === 0 && layoutIndex !== 4
                  ? "grid grid-cols-2"
                  : "flex flex-col space-y-3"
              } mt-4 `}
            >
              {layoutIndex !== 4 && (
                <div
                  className={`${
                    layoutIndex === 0
                      ? "col-span-1 h-60"
                      : layoutIndex === 1
                      ? "w-full h-80 mb-1"
                      : layoutIndex === 2
                      ? "col-span-1 h-60 order-2"
                      : "w-full h-80 order-2 mt-1"
                  } group relative overflow-hidden rounded-[0.625rem] bg-black`}
                >
                  {videoSrc ? (
                    <video
                      className="absolute inset-0 mx-auto max-w-none max-h-full rounded-[0.625rem]"
                      style={{
                        width: "auto",
                        height: "100%",
                        maxWidth: "100%",
                        maxHeight: "100%",
                      }}
                      controls
                      src={videoSrc}
                    />
                  ) : (
                    <div className="w-full h-full flex justify-center items-center text-white">
                      Upload Video
                    </div>
                  )}
                </div>
              )}

              {layoutIndex !== 4 && (
                <div
                  className={`${
                    layoutIndex === 0
                      ? "col-span-1 h-60 ml-1"
                      : layoutIndex === 1
                      ? "w-full h-60"
                      : layoutIndex === 2
                      ? "col-span-1 h-60 order-1 mr-1"
                      : "w-full h-60 order-1"
                  }`}
                >
                  <textarea
                    value={text}
                    onChange={handleTextChange}
                    placeholder="Enter your text here"
                    className="w-full h-full border border-gray-300 p-2 rounded-lg focus:outline-none focus:border-blue-500"
                    style={{ resize: "none", boxSizing: "border-box" }}
                    onFocus={() => setShowLayoutButtons(false)}
                    onBlur={() => setShowLayoutButtons(false)}
                  />
                  {/* {text !== undefined && (
                    <RichTextEditor
                      placeholder="Enter Acknowledgement Text"
                      value={text}
                      onChange={(content, contentLength) => {
                        console.log(content);
                        setText(content);
                      }}
                    />
                  )} */}
                </div>
              )}

              {layoutIndex === 4 && (
                <div className="w-full h-80 group relative overflow-hidden rounded-[0.625rem] bg-black">
                  {videoSrc ? (
                    <video
                      className="absolute inset-0 mx-auto max-w-none max-h-full rounded-[0.625rem]"
                      style={{
                        width: "auto",
                        height: "100%",
                        maxWidth: "100%",
                        maxHeight: "100%",
                      }}
                      controls
                      src={videoSrc}
                    />
                  ) : (
                    <div className="w-full h-full flex justify-center items-center text-white">
                      Upload Video
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VideoWithText;
