import { createSlice } from "@reduxjs/toolkit";

export type Module = {
  id: string;
  title: string;
  status: "gray" | "orange" | "green";
  children?: Module[];
  elapsedTime: number;
  type: "module" | "document" | "media"; // To differentiate last depth
};

const initialState = {
  modules: [] as Module[],
  currentPath: [] as string[],
  elapsedTime: 0,
};
export const trackedModulesSlice = createSlice({
  name: "templatePageState",
  initialState,
  reducers: {
    setModules: (state, action) => {
      state.modules = action.payload;
    },
    setCurrentPath: (state, action) => {
      state.currentPath = action.payload;
    },
    setElapsedTime: (state, action) => {
      state.elapsedTime = action.payload;
    }
  },
});

export const { setModules, setCurrentPath, setElapsedTime } = trackedModulesSlice.actions;
export default trackedModulesSlice.reducer;
