import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UserFilled } from "@carbon/icons-react";
import { useAccount, useMsal } from "@azure/msal-react";
import Avatar from "react-avatar";
import CopyLogo from "../../assets/copy.svg";
import MyAccount from "../../assets/User.svg";
import ManageTeams from "../../assets/users-three-bold 1.svg";
import UpgradePlan from "../../assets/upgradePlan.png";
import Help from "../../assets/Wrench.svg";
import Support from "../../assets/Handshake.svg";
import Signout from "../../assets/SignOut.svg";
import { useNavigate } from "react-router-dom";
import { setAccessToken } from "src/features/token/tokenSlice";
import { useParams } from "react-router-dom";

function NavProfile() {
  const [openDropdown, setOpenDropdown] = useState(false);
  const { instance, accounts } = useMsal();
  const [username, setUsername] = useState<string>("");
  // const [user, setUser] = useState<any>();
  const user: any = useSelector((state: any) => state.user.authUser);
  const navigate = useNavigate();

  const { userId } = useParams();

  const account = useAccount(accounts[0] || {});
  // const user = useSelector((state: any) => state.user.authUser);
  // useEffect(() => {
  //   init();
  // }, []);

  // async function init() {
  //   instance
  //     .handleRedirectPromise()
  //     .then((res) => {
  //       console.log(res);
  //       if (res) {
  //         setUsername(res.account.username);
  //         setUser(res.account);
  //       }
  //     })
  //     .catch((err) => console.error(err));
  // }
  const dispatch = useDispatch();

  function logout() {
    
    dispatch(setAccessToken(null))
    // instance.logoutRedirect({
    //   account: instance.getAccountByUsername(username),
    // });
    instance.logoutRedirect({
      account: account,
    });
  }

  // drop functionality
  const menuRef = React.createRef<HTMLDivElement>();
  const buttonRef = React.createRef<HTMLButtonElement>();

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!menuRef?.current?.contains(event.target as Node)) {
        setOpenDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      // Clean up the event listener when the component unmounts
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuRef]);

  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      if (buttonRef?.current?.contains(event.target as Node)) {
        setOpenDropdown(true);
      }
    };

    document.addEventListener("mousedown", handleClick);

    return () => {
      // Clean up the event listener when the component unmounts
      document.removeEventListener("mousedown", handleClick);
    };
  }, [buttonRef]);

  return (
    <div className="">
      <div>
        <button ref={buttonRef}>
          <Avatar name={user?.name} size={"30px"} round={true} />
        </button>
      </div>
      {openDropdown && (
        <div
          ref={menuRef}
          className=" w-[23.75rem] h-[25.25rem] bg-[#F5F5F5] text-[#242424] shadow-lg border-2 border-solid border-[#000000]/30 rounded-[1.625rem]  flex flex-col z-10 absolute top-[4.81rem] right-[0.75rem]"
        >
          <div className="flex pt-[0.31rem] px-[0.38rem] pb-[0.5rem] space-x-[1.25rem] items-center">
            <Avatar name={user?.name} size={"3.125rem"} round={true} />
            <div className="flex flex-col text-[0.875rem] font-[400] leading-normal">
              <div>{user?.name}</div>
              <div>{user?.upn}</div>
            </div>
          </div>
          <div className="h-[0.0625rem] w-full max-w-[22rem] mx-auto bg-[#2C2C2C]"></div>
          <ul className="w-full flex flex-col items-start justify-center space-y-[0.8rem] px-[0.56rem] mt-[0.657rem]">
            <li className="w-full">
              <button className="cursor-pointer flex items-center space-x-[1.94rem] hover:text-[#FFFFFF] w-full rounded-[1rem] px-[0.5rem] py-[0.287rem] hover:bg-tertiary" onClick={() => navigate(`/user/${user?.oid}/profile`)}>
                <span>
                  <img src={MyAccount} alt="logo" />
                </span>
                <span className="text-left">My account</span>
              </button>
            </li>
            <li className="cursor-pointer flex items-center space-x-[1.94rem] hover:text-[#FFFFFF] w-full rounded-[1rem] px-[0.5rem] py-[0.287rem] hover:bg-tertiary">
              <span>
                <img src={ManageTeams} alt="logo" />
              </span>
              <button className="text-left">Manage your team</button>
            </li>
            <li className="cursor-pointer flex items-center space-x-[1.94rem] hover:text-[#FFFFFF] w-full rounded-[1rem] px-[0.5rem] py-[0.287rem] hover:bg-tertiary">
              <span>
                <img src={Help} alt="logo" />
              </span>
              <button className="text-left">Help & FAQs</button>
            </li>
            <li className="cursor-pointer flex items-center space-x-[1.94rem] hover:text-[#FFFFFF] w-full rounded-[1rem] px-[0.5rem] py-[0.287rem] hover:bg-tertiary">
              <span>
                <img src={Support} alt="logo" />
              </span>
              <button className="text-left">Support</button>
            </li>
            <li className="cursor-pointer flex items-center space-x-[1.94rem] hover:text-[#FFFFFF] w-full rounded-[1rem] px-[0.5rem] py-[0.287rem] hover:bg-tertiary">
              <span>
                <img src={UpgradePlan} alt="logo" className="w-8 h-8"  />
              </span>
              <button className="text-left" onClick={() => navigate(`/user/${user?.oid}/subscription`)}>Upgrade Plan</button>
            </li>
            <li className="border-b-[1px] w-[22rem] border-[#667085]"></li>
            <li className="cursor-pointer flex items-center space-x-[1.94rem] hover:text-[#FFFFFF] w-full rounded-[1rem] px-[0.5rem] py-[0.287rem] hover:bg-tertiary">
              <span>
                <img src={Signout} alt="logo" />
              </span>
              <button className="text-left" onClick={() => logout()}>
                Sign out
              </button>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
}

export default NavProfile;
