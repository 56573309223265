import React, { useEffect, useState } from 'react'
import Question from './common/Question'
import { Question as QuestionType } from './utils/DefaultTemplateData'

type Props = {
  handleDataChange: (data: any) => void;
  data: QuestionType;
  optionQuantity: number;
  handleImageOrOptionClicked: (id: string) => void;
}

function Scr({ data, handleDataChange, optionQuantity, handleImageOrOptionClicked }: Props) {

  useEffect(() => {
    console.log("data", data);
  }, [data]);


  return (
    <div className='grow overflow-y-scroll'>
      <Question type='scr' optionQuantity={optionQuantity} data={data} handleDataChange={(data) => {
        handleDataChange(data);
      }} handleImageOrOptionClicked={handleImageOrOptionClicked}/>
    </div>
  )
}

export default Scr