import React, { useEffect, useState } from "react";
import InputText from "src/utils/TextInput/InputText";
import UploadButton from "src/utils/UploadButton";
import MultipleCheck from "src/utils/MultipleCheck";
import TextArea from "src/utils/TextInput/TextArea";
import DownArrow from "../../assets/down.png";
import InputDate from "src/utils/TextInput/Date";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { setPageState } from "src/features/templatePageState/templatePageStateSlice";
import { useDispatch } from "react-redux";
import UploadDropdowns from "../UploadDropdowns";
import RichTextEditor from "src/utils/RichTE";

type PAannouncementProps = {
  state?: any;
  openDrawerForm?: any;
  users: any[];
  onHandleDocument: (selectedOptions: any) => void;
  document: any;
  languages?: any[];
};

function PAannouncementDoc({
  state,
  openDrawerForm,
  users,
  document,
  onHandleDocument,
  languages,
}: PAannouncementProps) {
  const [title, setTitle] = useState(document?.title);
  const [date, setDate] = useState(document?.date);
  const [transcriptText, setTranscriptText] = useState("");
  const [savedSelectedOptions, setSavedSelectedOptions] = useState<Array<any>>(
    document?.selectedOptions || []
  );

  const [openLanguage, setOpenLanguage] = useState(false);
  const [selectLanguage, setSelectLanguage] = useState<any>(null);

  const [filteredOptions, setFilteredOptions] = useState<Array<any>>([]);

  const [File, setFile] = useState<any>(null);

  // const { pageState } = useSelector((state: any) => state.templatePageState);
  // const dispatch = useDispatch();

  useEffect(() => {
    console.log("this is document: ", document, state);
    // setTitle(document?.title);
    // setTranscriptText(document?.transcriptText);
    // setSavedSelectedOptions(document?.selectedOptions);

    if (state) {
      setTitle(state.title);
      setDate(state.date);
      setTranscriptText(state.transcriptText);
      setSavedSelectedOptions(state.savedSelectedOptions);
      setOpenLanguage(state.openLanguage);
      setSelectLanguage(state.selectLanguage);
      setFile(state.file || state.File);
    }
  }, [state]);

  useEffect(() => {
    console.log(selectLanguage);
    if (!state && selectLanguage) {
      const languageDoc = document?.languageDoc.filter(
        (item: any) => item.id === selectLanguage.id
      )[0];
      if (languageDoc) {
        setTranscriptText(languageDoc.transcriptText);
        if (languageDoc.file) setFile(languageDoc.file);
      }
    }
  }, [selectLanguage]);

  useEffect(() => {
    handleContentChange();
  }, [title, date, savedSelectedOptions, transcriptText, File]);

  function handleContentChange() {
    if (state && state._id !== document?._id) return;
    let newLanguageDoc;
    if (languages?.length === document?.languageDoc.length) {
      newLanguageDoc = document?.languageDoc.map((item: any) => {
        if (selectLanguage && item.id === selectLanguage.id) {
          return { ...item, transcriptText: transcriptText, file: File };
        }
        return item;
      });
      console.log("if", newLanguageDoc);
    } else {
      const docs =
        languages
          ?.filter((lang) => {
            return (
              document?.languageDoc.filter((item: any) => item.id === lang.id)
                .length === 0
            );
          })
          .map((lang) => {
            return { id: lang.id, transcriptText: "", file: null };
          }) || [];
      newLanguageDoc = [...document?.languageDoc, ...docs];
      console.log("else", newLanguageDoc);
    }

    console.log(newLanguageDoc);
    const newDocument = {
      _id: document?._id,
      aircraftId: document?.aircraftId,
      languageId: document?.languageId,
      title,
      date,
      selectedOptions: savedSelectedOptions,
      languageDoc: newLanguageDoc,
    };
    onHandleDocument(newDocument);
  }

  function handleSearch(searchTerm: string) {
    const searchResults = languages?.filter((data) => {
      const optionName = data.name.toLowerCase();
      const searchTermChars = searchTerm.toLowerCase().split("");

      let searchIndex = 0;
      for (const char of optionName) {
        if (char === searchTermChars[searchIndex]) {
          searchIndex++;
        }

        if (searchIndex === searchTermChars.length) {
          return true; // All characters in the search term found in order
        }
      }

      return false;
    });

    if (searchResults) setFilteredOptions(searchResults);
  }

  return (
    document && (
      <div className="flex flex-col space-y-[1.25rem] ">
        <div className="pt-[1.25rem] px-[0.45rem]">
          <InputText
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder="PA Announcement Title"
            labelTitle=""
          />
        </div>

        <div className="px-[0.45rem]">
          <InputDate
            value={date}
            onChange={(e) => setDate(e.target.value)}
            placeholder="Enter date"
            labelTitle="Date"
          />
        </div>

        <div className="flex flex-col space-y-[0.94rem] px-[0.94rem] z-[9999]">
          <div className="text-[16px] font-[600]">
            Select Users for this PA Announcement
          </div>
          <MultipleCheck
            SelectTitle="Select Users"
            options={users}
            savedSelectedOptions={
              state && state._id === document?._id
                ? state.savedSelectedOptions
                : savedSelectedOptions
            }
            onHandleSelectedOptions={(selectedOptions) =>
              setSavedSelectedOptions(selectedOptions)
            }
          />
        </div>

        <div className="relative flex flex-col h-full px-[0.94rem]">
          <button
            onClick={() => {
              handleSearch("");
              setOpenLanguage(!openLanguage);
            }}
            className="flex w-[11.875rem] bg-[#AB0273] px-[0.63rem] py-[0.3125rem] rounded-[1.25rem] border border-[#AB0273] items-center justify-between"
          >
            <span className="text-[1rem] font-[600]  text-[#FFFFFF] leading-normal">
              Select Language
            </span>
            <span className="block">
              {/* <img src={DownArrow} alt="logo" /> */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
              >
                <path
                  d="M26 12L16 22L6 12"
                  stroke="white"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
          </button>

          {openLanguage && (
            <div className="absolute top-[2.8rem] z-[999] w-[11.875rem] border-2 border-[#000000]/30 bg-[#F5F5F5] rounded-[1.8rem] px-[1.12rem] pt-[0.7rem]">
              <input
                type="text"
                placeholder="Search..."
                className="flex w-full bg-[#F5F5F5]  text-[#242424] text-[0.875rem] font-[500]  px-[0.58rem] pb-[0.94rem] text-left  items-center outline-none"
                // value={searchTerm}
                onChange={(e) => handleSearch(e.target.value)}
              />

              <div className=" max-h-[158px] overflow-y-scroll no-scrollbar">
                {filteredOptions?.map((language: any) => (
                  <>
                    <button
                      onClick={() => {
                        setSelectLanguage(null);
                        setSelectLanguage({
                          id: language.id,
                          name: language.name,
                        });
                        setOpenLanguage(false);
                      }}
                      className="flex w-full text-[#242424] text-[1rem] font-[500]  px-[0.58rem] py-[0.94rem] text-left border-t-[0.0625rem] border-[#2C2C2C] items-center"
                    >
                      <div className="overflow-hidden w-[10.875rem]">
                        {language.name}
                      </div>
                    </button>
                  </>
                ))}
              </div>
            </div>
          )}

          {selectLanguage && (
            <div className="pt-[1.25rem] space-y-[1.25rem]">
              <div className="flex flex-col space-y-[0.94rem]">
                <div className="text-[16px] font-[600] overflow-hidden">
                  PA Announcement Audio ({selectLanguage.name})
                </div>
                <div className="flex space-x-3 items-center">
                  <UploadButton
                    type="audio"
                    pageState={{
                      // ...state,
                      File,
                      title: title,
                      date: date,
                      transcriptText: transcriptText,
                      savedSelectedOptions: savedSelectedOptions,
                      openLanguage: openLanguage,
                      selectLanguage: selectLanguage,
                      openDrawerForm: openDrawerForm,
                      _id: document?._id,
                    }}
                    handleUploadFromDevice={(file) => {
                      setFile(file);
                      // console.log(file);
                    }}
                  />
                  <UploadDropdowns
                    type="audio"
                    pageState={{
                      // ...state,
                      File,
                      title: title,
                      date: date,
                      transcriptText: transcriptText,
                      savedSelectedOptions: savedSelectedOptions,
                      openLanguage: openLanguage,
                      selectLanguage: selectLanguage,
                      openDrawerForm: openDrawerForm,
                      _id: document?._id,
                    }}
                    openDrawerForm={openDrawerForm}
                    File={File}
                    // handleCropFile={(file) => setFile(file)}
                  />
                </div>
              </div>

              <div className="">
                {/* <TextArea
                  value={transcriptText}
                  onChange={(e) => setTranscriptText(e.target.value)}
                  placeholder="Transcript Text"
                  labelTitle=""
                /> */}
                {transcriptText !== undefined && (
                  <RichTextEditor
                    placeholder="Transcript Text"
                    value={transcriptText}
                    onChange={(content, contentLength) => {
                      console.log(content);
                      setTranscriptText(content);
                    }}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    )
  );
}

export default PAannouncementDoc;
