import React from 'react'
import { useDrag } from 'react-dnd'
import { TextCreation } from "@carbon/icons-react";
import { useSelector } from 'react-redux';

function TextWithHyperlinkIcon() {
  const isLeftPanelCollapsed = useSelector(
    (state: any) => state.sidebar.leftPanelCollapsed
  );
    const [{isDragging}, dragRef] = useDrag({
        type: "text-with-hyperlink",
        item: {
            ID: 5,
        },
        collect: monitor => ({
            isDragging: !!monitor.isDragging()
        })
    })
  return (
    // <div ref={dragRef}>TextWithHyperlinkIcon</div>
    <div className="flex items-center space-x-2" ref={dragRef}>
      <div>
        <TextCreation size={"glyph"} />
      </div>
      {!isLeftPanelCollapsed && (
        <div className="border rounded-md p-2 text-xs flex flex-col space-y-1">
          <div >Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book.</div>
        </div>
      )}
    </div>
  )
}

export default TextWithHyperlinkIcon