import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import Layout from "../Layout";
import Auth from "../Auth";
import { ChevronLeft } from "@carbon/icons-react";


type Props = {};

function Profile({}: Props) {
  const user: any = useSelector((state: any) => state.user.authUser);
  useEffect(() => {
    console.log(user);
  }, [user]);

  return (
    <Auth>
      <Layout type="profile">
        {(callback) => 
        <div className="bg-gray-100 min-h-screen w-full h-full">
          <div className="container mx-auto py-8">
            <div className="flex items-center space-x-2 mb-4">
              <a
                href={"/dashboard"}
                className="cursor-pointer"
              >
                <ChevronLeft size={32} />
              </a>
              <h1 className="text-3xl font-bold text-tertiary">Profile</h1>
            </div>
            <div className="bg-white shadow-md rounded-lg p-6">
              <h2 className="text-xl font-bold mb-2 text-tertiary">
                User Information
              </h2>
              <div className="grid grid-cols-2 gap-4">
                <p className="mb-4">
                  <span className="font-bold text-tertiary">Name:</span>{" "}
                  {user.name}
                </p>
                <p className="mb-4">
                  <span className="font-bold text-tertiary">Email:</span>{" "}
                  {user.unique_name}
                </p>
                {/* Add more user information here */}
              </div>
            </div>
          </div>
        </div>}
      </Layout>
    </Auth>
  );
}

export default Profile;
