import { createSlice } from "@reduxjs/toolkit";

type TemplateType = {
  _id: string;
  programId: string;
  authorId: string;
  title: string;
  type: string;
  modifiedOn: number;
  createdOn: number;
  content: any;
};

const initialState = {
  programTemplates: [] as TemplateType[],
};
export const programTemplateSlice = createSlice({
  name: "programTemplates",
  initialState,
  reducers: {
    setProgramTemplates: (state, action) => {
      state.programTemplates = action.payload;
    },
    setAddProgramTemplate: (state, action) => {
      state.programTemplates.push(action.payload)
    },
    setDelProgramTemplate: (state, action) => {
      state.programTemplates = state.programTemplates.filter(
        (programTemplate) => programTemplate._id !== action.payload
      );
    },
  },
});

export const { setProgramTemplates, setAddProgramTemplate, setDelProgramTemplate } = programTemplateSlice.actions;
export default programTemplateSlice.reducer;