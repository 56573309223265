import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import axios from "axios";
import { setSaveChanges } from "src/features/saveChanges/saveChangesSlice";
import { setPageState } from "src/features/templatePageState/templatePageStateSlice";
import InputText from "src/utils/TextInput/InputText";
import TemplateHeading from "src/utils/TextInput/TemplateHeading";
import TextArea from "src/utils/TextInput/TextArea";
import UploadButton from "src/utils/UploadButton";
import UploadDropdowns from "../UploadDropdowns";

type Props = {};

function ThreeDModalScreen({}: Props) {

  const [title, setTitle] = useState("");
  const [bgFile, setbgFile] = useState("");

  const [state, setState] = useState<any>(null);
  const { pageState } = useSelector((state: any) => state.templatePageState);

  const [content, setContent] = useState<any>();
  const [saving, setSaving] = useState(false);

  const [isLoading, setIsLoading] = useState(false)

  const { templateId } = useParams();
  
  const { accessToken } = useSelector((state: any) => state.accessToken);

  const dispatch = useDispatch();


  useEffect(() => {
    const timeoutId = setTimeout(() => {
      dispatch(setSaveChanges(true));
      setIsLoading(true)
      axios
        .post(
          `${process.env.REACT_APP_SERVER_BASE_URL}/api/v1/template/save/${templateId}`,
          { content },
          {
            headers: { Authorization: `Bearer ${accessToken}` },
          }
        )
        .then(() => {
          dispatch(setSaveChanges(false));
          setIsLoading(false)
        })
        .catch((error) => {
          console.error("Auto-save failed:", error);
          setSaving(false);
          dispatch(setSaveChanges(false));
          setIsLoading(false)
        });
    }, 5000);
    return () => clearTimeout(timeoutId);
  }, [content]);

  useEffect(() => {
    handleContentChange();
  }, [title, bgFile]);

  useEffect(() => {
    console.log(pageState, state);
    if (pageState) {
      if (pageState.uploadButtonFor === "bgImageFile" && pageState.file) { setbgFile(pageState.file); }
      if (!pageState.file) { setbgFile(pageState.bgFile); }
      if (pageState.title) setTitle(pageState.title);

      setState(pageState);
      dispatch(setPageState(null));
    }
    else {
      getContent();
    }
  }, []);

  async function getContent() {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_BASE_URL}/api/v1/template/content/${templateId}`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );

      if (response && response.data) {
        console.log("template details:", response.data.data);
        setTitle(response.data.data.content.title);
        setbgFile(response.data.data.content.bgfile);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handleContentChange = () => {
    const newContent = {
      title,
      bgfile: bgFile,
    };
    setContent(newContent);
  };

  return (
    <div className=" ">
      <TemplateHeading title="3D Model Screen" />
      <div>
        <InputText
          value={title}
          onChange={(e) => {
            setTitle(e.target.value);
          }}
          placeholder="Enter Title"
          labelTitle="Title"
        />
      </div>

      <div className="grid grid-cols-4 mt-[1.5rem] mx-[0.49rem] items-center">
        <div className="col-span-1 font-[600]  text-[1rem] text-[#242424] leading-normal">Background Image</div>
        <div className="col-span-3 flex space-x-[0.75rem] items-center">
          <UploadButton
            name="bgImageFile"
            // handleClick={(name) => {console.log(name); setUploadButtonFor(name)}}
            type="image"
            pageState={{
              ...state,
              uploadButtonFor: "bgImageFile",
              bgFile,
              title,
            }}
          />
          <UploadDropdowns 
          name="bgImageFile"
          type="image"
          pageState={{
            ...state,
            uploadButtonFor: "bgImageFile",
            bgFile,
            title,
          }}
          handleCropFile={(cropFile) => setbgFile(cropFile)}
          File={bgFile} />
        </div>
      </div>
    </div>
  );
}

export default ThreeDModalScreen;
