import React, { useState, useEffect } from "react";
import Modal from "src/utils/Modal";
import InputText from "src/utils/TextInput/InputText";
import UploadButton from "src/utils/UploadButton";
import UploadDropdowns from "../UploadDropdowns";

type Props = {
  state?: any;
  openEditData?: any;
  editTitle?: any;
  editData: any;
  onSetEditData: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onHandleEdit: () => void;
  onHandleDelete: (id: any) => void;
};

function EditAircraft({
  state,
  openEditData,
  editTitle,
  editData,
  onSetEditData,
  onHandleEdit,
  onHandleDelete,
}: Props) {
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [passUnitIdToDelete, setPassUnitIdToDelete] = useState<string | null>(
    null
  );

  const [file, setFile] = useState<any>(editData.file);

  useEffect(() => {
    console.log("this is state: ", state);

    if (state) {
      if (state.file) setFile(state.file);
    }
  }, [state]);

  function handleDeletePopup(id: any) {
    setOpenDeletePopup(true);
    setPassUnitIdToDelete(id);
  }

  function handleDelete(id: any) {
    onHandleDelete(id);

    setOpenDeletePopup(false);
  }

  return (
    <>
      <div className="flex flex-col h-full px-[0.49rem] ">
        <div className="grow space-y-4 mt-2 ">
          <InputText
            value={editData?.name}
            onChange={(e) => onSetEditData(e)}
            placeholder="Enter Aircraft Name"
            labelTitle="Aircraft Name"
          />
          <div className="flex flex-col  space-y-[1.125rem] p-[0.49rem]">
            <div className="text-[1rem] font-bold">Aircraft's Image</div>
            <div className="flex space-x-1 items-center">
              <UploadButton
                type={"image"}
                pageState={{
                  ...state,
                  uploadButtonFor: "",
                  openEditData: openEditData,
                  editTitle: editTitle,
                  editData: editData,
                }}
                handleUploadFromDevice={(file) => {
                  setFile(file);
                  console.log(file);
                }}
              />
              {/* {state ? (state.file ? state.file.name : editData ? editData.file?.name : "") : (editData && editData.file?.name)} */}
              <UploadDropdowns
                type={"image"}
                pageState={{
                  ...state,
                  uploadButtonFor: "",
                  openEditData: openEditData,
                  editTitle: editTitle,
                  editData: editData,
                }}
                handleCropFile={(cropFile) => setFile(cropFile)}
                openEditModal={openEditData}
                File={file}
              />
            </div>
          </div>
        </div>
        <div className=" border-t border-[#2C2C2C] border-[#2C2C2C]/30 px-[0.49rem] py-[1.25rem] flex justify-between items-center">
          <button
            onClick={() => handleDeletePopup(editData.id)}
            className="px-6 py-3 text-[1rem] text-[#FFFFFF] bg-[#929292] rounded-[1.875rem]"
          >
            Delete
          </button>
          <button
            onClick={() => {
              onHandleEdit();
            }}
            className="px-7 py-3 text-[1rem] text-[#FFFFFF] bg-[#AB0273] rounded-[1.875rem]"
          >
            Done
          </button>
        </div>
      </div>

      <Modal
        onOpened={openDeletePopup}
        onClose={() => setOpenDeletePopup(false)}
        title="Confirm Delete"
        height="calc(100%-400px)"
        passUnitIDtoDelete={passUnitIdToDelete || ""}
      >
        <div className="flex flex-col h-full items-center pt-4 px-4 space-y-4">
          <div className="flex items-center justify-center w-full py-5">
            <span>Are you sure you want to delete this Aircraft?</span>
          </div>

          <div className=" border-t-[0.0625rem] border-[#2C2C2C] py-5 w-full flex justify-between items-center">
            <button
              onClick={() => setOpenDeletePopup(false)}
              className="text-[#FFFFFF] px-5 py-2 text-[16px] rounded-[1.875rem] bg-[#929292]"
            >
              Cancel
            </button>
            <button
              onClick={() => {
                handleDelete(passUnitIdToDelete);
              }}
              className="text-[#FFFFFF] px-5 py-2 text-[16px] rounded-[1.875rem] bg-red-600"
            >
              Delete
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default EditAircraft;
