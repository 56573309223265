import React, { memo, useEffect, useRef, useState } from "react";
import Templates from "../Templates";
import { OverflowMenuHorizontal } from "@carbon/icons-react";
import {
  ChevronLeft,
  ChevronRight,
  Menu,
  UserFollow,
  ChevronDown,
} from "@carbon/icons-react";
import { useDispatch, useSelector } from "react-redux";
import { setLeftPanelCollapsed } from "../../features/Sidebar/sidebarSlice";
import Modal from "src/utils/Modal";
import axios from "axios";
import EditLogo from "../../assets/edit.svg";
import PreviewLogo from "../../assets/preview.svg";
import CollaborateLogo from "../../assets/collaborate.svg";
import DeleteLogo from "../../assets/TableMenu/TrashSimple.svg";
import DownArrow from "../../assets/down.png";
import { useUser } from "src/hooks/useUser";
import DashboardIcon from "../../assets/dashboard.svg";
import CourseIcon from "../../assets/course.svg";
import RecentIcon from "../../assets/recent.svg";
import StarredIcon from "../../assets/starred.svg";
import SharedwithmeIcon from "../../assets/sharedwithme.svg";
import AddClientIcon from "../../assets/addclient.svg";
import Text from "src/utils/Text/Txt";
import { useAccount, useMsal } from "@azure/msal-react";
import { setAccessToken } from "src/features/token/tokenSlice";
import {
  setAddBusinessUnit,
  setBusinessUnits,
} from "src/features/businessUnits/businessUnitSlice";
import { setActiveSidebar } from "src/features/activeSidebar/activeSidebarSlice";
import { setAuthUser, setIsAuthenticated } from "src/features/user/userSlice";
import Avatar from "react-avatar";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  setAddProgramTemplate,
  setProgramTemplates,
  setDelProgramTemplate,
} from "src/features/programTemplates/programTemplatesSlice";
import { RefType, ToastPortal } from "../ToastComponent/ToastPortal";
import { setLog } from "src/features/errorLog/errorLogSlice";

type sidebarProps = {};

type TemplateType = {
  _id: string;
  programId: string;
  authorId: string;
  title: string;
  type: string;
  modifiedOn: number;
  createdOn: number;
  content: any;
};

const SidebarProgram = memo((props: sidebarProps) => {
  const [openDropdown, setOpenDropdown] = useState(false);
  const [openDropdownMenu, setOpenDropdownMenu] = useState(false);
  const [templates, setTemplates] = useState<Array<string>>([]);
  const [openModal, setOpenModal] = useState(false);
  const [passUnitIdToDelete, setPassUnitIdToDelete] = useState<string | null>(
    null
  );
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const createdTemplates = useSelector(
    (state: any) => state.programTemplates.programTemplates
  );

  // Use an object to store the state for each template
  const [templateDropdownStates, setTemplateDropdownStates] = useState<{
    [key: string]: boolean;
  }>({});

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { programTemplates }: { programTemplates: TemplateType[] } =
    useSelector((state: any) => state.programTemplates);

    const [searchParams] = useSearchParams();

  const { activeSidebar } = useSelector((state: any) => state.activeSidebar);
  const { accessToken } = useSelector((state: any) => state.accessToken);
  // const { aircrafts, users } = useSelector(
  //   (state: any) => state.aircraftsUsers
  // );

  const { saveChanges } = useSelector((state: any) => state.saveChanges);

  const [users, setUsers] = useState<any>([]);
  const [aircrafts, setAircrafts] = useState<any>([]);

  const { businessUnitId, sepmId, authorId, userId } = useParams();

  useEffect(() => {
    // if(searchParams.get("screen") === "multiple user")
    
    getTemplates();
  }, [saveChanges]);

  async function getTemplates() {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_BASE_URL}/api/v1/template/${sepmId}`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );

      if (response && response.data) {
        // console.log("templates:", response.data.data);
        // const myUnits = response.data.data as BusinessUnitType[]
        // setUnits(response.data.data as BusinessUnitType[]);
        if (response.data.data.templates.length > 0) {
          // navigate(`/businessunit/${businessUnitId}/program/${programId}/template/${response.data.data.templates[0]._id}?screen=${response.data.data.templates[0].type}`)
          // dispatch(setActiveSidebar(response.data.data.templates[0].title))
          setAircrafts(response.data.data.aircrafts);
          setUsers(response.data.data.users);
        }
        dispatch(
          setProgramTemplates(response.data.data.templates as TemplateType[])
        );
      }
    } catch (error) {
      console.log(error);
      dispatch(
        setProgramTemplates([])
      );
    }
  }

  async function AddTemplate(title: string, type: string) {
    try {
      // Check if the button is already disabled
      if (isButtonClicked) {
        return;
      }
      // Check if a template with the same title already exists in Redux state
      if (
        createdTemplates.some(
          (template: TemplateType) => template.title === title
        )
      ) {
        dispatch(
          setLog({
            mode: "warn",
            message: "You have already created this template.",
          })
        );
        return;
      }
      if (
        type !== "splash" &&
        type !== "acknowledgement" &&
        type !== "multiple user" &&
        (aircrafts.length === 0 || users.length === 0)
      ) {
        dispatch(
          setLog({
            mode: "warn",
            message:
              "You must create a User/Aircraft before proceeding this template!",
          })
        );
        return;
      }
      // Disable the button
      setIsButtonClicked(true);

      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_BASE_URL}/api/v1/template/${sepmId}`,
        {
          title,
          type,
          modifiedOn: new Date().getTime(),
        },
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );
      // console.log("Added template", response.data.data);
      dispatch(setAddProgramTemplate(response.data.data as TemplateType));
      navigate(
        `/user/${userId}/businessunit/${businessUnitId}/sepm/${sepmId}/template/${response.data.data._id}?screen=${response.data.data.type}`
      );
      dispatch(setLog({ mode: "success", message: response.data.message }));
      dispatch(setActiveSidebar(response.data.data.title));
      setOpenDropdown(false);
      setIsButtonClicked(false);
      window.location.reload();
    } catch (err) {
      console.error(err);
      dispatch(setLog({ mode: "failure", message: err }));
    }
  }

  // handle delete

  function handleDeletePopup(id: any) {
    setOpenDeletePopup(true);
    setPassUnitIdToDelete(id);
  }

  const handleDelete = async (id: any) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_SERVER_BASE_URL}/api/v1/template/${id}`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );
      if (response && response.data) {
        console.log("Template deleted successfully");
        dispatch(setDelProgramTemplate(id));
        dispatch(setLog({ mode: "success", message: response.data.message }));
        setOpenDeletePopup(false);
        // setTemplateDropdownStates((prevState) => ({
        //   ...prevState,
        //   [id]: false,
        // }));
      }
    } catch (error) {
      console.log(error);
      dispatch(setLog({ mode: "failure", message: error }));
    }
  };

  const menuRef = React.createRef<HTMLDivElement>();
  const buttonRef = React.createRef<HTMLButtonElement>();

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!menuRef?.current?.contains(event.target as Node)) {
        setOpenDropdown(false);
        for (const templateId in templateDropdownStates) {
          if (templateDropdownStates[templateId]) {
            setTemplateDropdownStates((prevState) => ({
              ...prevState,
              [templateId]: false,
            }));
          }
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      // Clean up the event listener when the component unmounts
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuRef]);

  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      if (buttonRef?.current?.contains(event.target as Node)) {
        setOpenDropdown(true);
      }
    };

    document.addEventListener("mousedown", handleClick);

    return () => {
      // Clean up the event listener when the component unmounts
      document.removeEventListener("mousedown", handleClick);
    };
  }, [buttonRef]);

  return (
    <>
      <div
        className={`relative pt-[1.06rem] px-[1.25rem]  bg-[#EFEFEF] text-secondary min-w-[20.625rem] h-full`}
      >
        <div className="flex flex-col space-y-2">
          <button
            ref={buttonRef}
            className="relative w-full max-w-[15.9375rem] flex items-center justify-between bg-[#AB0273] text-[#FFFFFF] text-[1.25rem] font-[500] leading-normal px-[1.28rem] py-[0.625rem] rounded-[3.125rem]"
          >
            <span className="space-x-[0.625rem]">
              <span className="font-bold">+</span>
              <span>New Template</span>
            </span>
            <span>
              {/* <img src={DownArrow} alt="logo" /> */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
              >
                <path
                  d="M26 12L16 22L6 12"
                  stroke="white"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
          </button>
          {openDropdown && (
            <div
              ref={menuRef}
              className="w-[15.9375rem] h-[33.75rem] bg-[#F5F5F5] text-[#242424] text-[1rem] font-[600] leading-normal border-2 border-black/30 rounded-[1.375rem] py-[1rem] px-[0.69rem] flex flex-col items-start space-y-[0.7rem] overflow-hidden z-10 absolute top-16 shadow-lg shadow-black/30"
            >
              <button
                disabled={isButtonClicked}
                className="hover:bg-[#AB0273] hover:rounded-[3.125rem] hover:text-[#FFFFFF] w-full text-left px-2 py-1"
                onClick={() => AddTemplate("Splash", "splash")}
              >
                <span></span>
                <span>Splash</span>
              </button>
              <button
                disabled={isButtonClicked}
                className="hover:bg-[#AB0273] hover:rounded-[3.125rem] hover:text-[#FFFFFF] w-full text-left px-2 py-1"
                onClick={() =>
                  AddTemplate("Acknowledgement", "acknowledgement")
                }
              >
                <span></span>
                <span>Acknowledgement</span>
              </button>
              <button
                disabled={isButtonClicked}
                className="hover:bg-[#AB0273] hover:rounded-[3.125rem] hover:text-[#FFFFFF] w-full text-left px-2 py-1"
                onClick={() => AddTemplate("Multiple User", "multiple user")}
              >
                <span></span>
                <span>Multiple User Menu</span>
              </button>
              <button
                disabled={isButtonClicked}
                className="hover:bg-[#AB0273] hover:rounded-[3.125rem] hover:text-[#FFFFFF] w-full text-left px-2 py-1"
                onClick={() => AddTemplate("Safety Training QRH", "qrh")}
              >
                <span></span>
                <span>Safety Training QRH</span>
              </button>
              <button
                disabled={isButtonClicked}
                className="hover:bg-[#AB0273] hover:rounded-[3.125rem] hover:text-[#FFFFFF] w-full text-left px-2 py-1"
                onClick={() => AddTemplate("Pre-Flight Briefing", "pre-flight")}
              >
                <span></span>
                <span>Pre-Flight Briefing</span>
              </button>
              <button
                disabled={isButtonClicked}
                className="hover:bg-[#AB0273] hover:rounded-[3.125rem] hover:text-[#FFFFFF] w-full text-left px-2 py-1"
                onClick={() =>
                  AddTemplate("Flight-Ops Training Memo", "flight-ops")
                }
              >
                <span></span>
                <span>Flight-Ops Training Memo</span>
              </button>
              <button
                disabled={isButtonClicked}
                className="hover:bg-[#AB0273] hover:rounded-[3.125rem] hover:text-[#FFFFFF] w-full text-left px-2 py-1"
                onClick={() => AddTemplate("PA Announcement", "announcement")}
              >
                <span></span>
                <span>PA Announcement</span>
              </button>
              <button
                disabled={isButtonClicked}
                className="hover:bg-[#AB0273] hover:rounded-[3.125rem] hover:text-[#FFFFFF] w-full text-left px-2 py-1"
                onClick={() => AddTemplate("3D Model", "modal")}
              >
                <span></span>
                <span>3D Model</span>
              </button>
              <button
                disabled={isButtonClicked}
                className="hover:bg-[#AB0273] hover:rounded-[3.125rem] hover:text-[#FFFFFF] w-full text-left px-2 py-1"
                onClick={() => AddTemplate("Emergency Equipment", "emergency")}
              >
                <span></span>
                <span>Emergency Equipment</span>
              </button>
              <button
                disabled={isButtonClicked}
                className="hover:bg-[#AB0273] hover:rounded-[3.125rem] hover:text-[#FFFFFF] w-full text-left px-2 py-1"
                onClick={() => AddTemplate("Videos", "videos")}
              >
                <span></span>
                <span>Videos</span>
              </button>
              <button
                disabled={isButtonClicked}
                className="hover:bg-[#AB0273] hover:rounded-[3.125rem] hover:text-[#FFFFFF] w-full text-left px-2 py-1"
                onClick={() =>
                  AddTemplate("Location Diagram", "location diagram")
                }
              >
                <span></span>
                <span>Location Diagram</span>
              </button>
              <button
                disabled={isButtonClicked}
                className="hover:bg-[#AB0273] hover:rounded-[3.125rem] hover:text-[#FFFFFF] w-full text-left px-2 py-1"
                onClick={() =>
                  AddTemplate("A/C Type System Diff", "system diff")
                }
              >
                <span></span>
                <span>A/C Type System Diff</span>
              </button>
            </div>
          )}
          <div className="flex flex-col items-start space-y-[0.63rem] pt-[1.5rem]">
            <span className="text-[1.25rem] font-[600] capitalize leading-normal text[#242424] whitespace-nowrap">Course Contents</span>
            <div className="w-[15.625rem] h-[0.0625rem] bg-[#667085] "></div>
          </div>

          {programTemplates && (
            <>
              {programTemplates.map((template: any) => (
                <button
                  onClick={() => dispatch(setActiveSidebar(template.title))}
                  className={`group flex items-center space-x-[0.625rem] text-[1rem] text-[#242424] font-[600] ${
                    activeSidebar === template.title && "bg-[#E0CBD9]"
                  } px-[0.625rem] py-[0.3125rem] rounded-[3.125rem] hover:bg-[#E0CBD9]`}
                >
                  <a
                    className="w-full text-left"
                    href={`${process.env.REACT_APP_CLIENT_BASE_URL}/user/${userId}/businessunit/${businessUnitId}/sepm/${sepmId}/template/${template._id}?screen=${template.type}`}
                  >
                    <div className="">{template.title}</div>
                  </a>
                  <button
                    onClick={() => {
                      // Toggle the dropdown state for the current template
                      setTemplateDropdownStates((prevState) => ({
                        ...prevState,
                        [template._id]: !prevState[template._id],
                      }));
                    }}
                    className="invisible group-hover:visible"
                  >
                    <OverflowMenuHorizontal size="24" />
                  </button>

                  {templateDropdownStates[template._id] && (
                    <div
                      ref={menuRef}
                      className="overflow-hidden z-10 absolute right-4 bg-[#F5F5F5] border shadow-lg px-[0.7rem] py-[0.65rem] rounded-[1.25rem] min-w-[8rem] mt-20"
                    >
                      <ul className="w-full flex flex-col items-start justify-center space-y-2">
                        <li className="text-[#C2262E] cursor-pointer flex items-center space-x-[1.2rem]">
                          <span>
                            <img src={DeleteLogo} alt="logo" />
                          </span>
                          <span>
                            <button
                              onClick={() => handleDeletePopup(template._id)}
                            >
                              Delete
                            </button>
                          </span>
                        </li>
                      </ul>
                    </div>
                  )}
                </button>
              ))}
            </>
          )}
        </div>
      </div>

      <Modal
        onOpened={openDeletePopup}
        onClose={() => setOpenDeletePopup(false)}
        title="Confirm Delete"
        height="calc(100%-400px)"
        passUnitIDtoDelete={passUnitIdToDelete || ""}
      >
        <div className="flex flex-col h-full items-center pt-4 px-4 space-y-4">
          <div className="flex items-center justify-center w-full py-5">
            <span>
              Are you sure you want to delete this template?
            </span>
          </div>

          <div className="border-t-[0.0625rem] border-[#2C2C2C] py-5 w-full flex justify-center space-x-[4rem] items-center">
              <button
                onClick={() => setOpenDeletePopup(false)}
                className="text-[#FFFFFF] px-5 py-2 text-[16px] rounded-[1.875rem] bg-[#929292]"
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  handleDelete(passUnitIdToDelete);
                }}
                className="text-[#FFFFFF] px-5 py-2 text-[16px] rounded-[1.875rem] bg-red-600"
              >
                Delete
              </button>
          </div>
        </div>
      </Modal>
    </>
  );
});

export default SidebarProgram;
