import { createSlice } from "@reduxjs/toolkit";

type blocks = {
    blockId: String,
    serialId: String,
    position: Number
}

const initialState = {
    blocks: [] as blocks[],
}
export const blockSlice = createSlice({
    name: "block",
    initialState,
    reducers: {
        setBlocks: (state, action) => {
            state.blocks = action.payload
        },
        delBlocks: (state, action) => {
            state.blocks = state.blocks.filter((block) => block.blockId !== action.payload)
        },
        addBlocks: (state, action) => {
            state.blocks.splice(action.payload.position, 0, action.payload)
        },
    }
})

export const {setBlocks, delBlocks, addBlocks} = blockSlice.actions
export default blockSlice.reducer