import React, { useState, useEffect } from "react";
import Modal from "src/utils/Modal";
import Text from "src/utils/Text/Txt";
import InputText from "src/utils/TextInput/InputText";
import UploadButton from "src/utils/UploadButton";
import UploadDropdowns from "../UploadDropdowns";

type Props = {
  state?: any;
  openEditData?: any;
  editTitle?: any;
  openEditModal: any;
  editData: any;
  onSetEditData: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onHandleEdit: (file: any, iconfile: any) => void;
  onHandleDelete: (id: any) => void;
};

function EditEquipments({
  state,
  openEditData,
  editTitle,
  editData,
  onSetEditData,
  onHandleEdit,
  onHandleDelete,
  openEditModal,
}: Props) {
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [passUnitIdToDelete, setPassUnitIdToDelete] = useState<string | null>(
    null
  );

  // const [file, setFile] = useState<any>(editData.file);
  const [equipmentIconfile, setequipmentIconFile] = useState<any>(editData.iconfile);
  const [equipmentFile, setequipmentFile] = useState<any>(editData.file);

  useEffect(() => {
    console.log("this is state: ", state);

    if (state) {
      if (state.file && state.uploadButtonFor === "equipmentIconImageFile") {
        setequipmentIconFile(state.file);
        setequipmentFile(state.equipmentFile);
      }
      if (state.file && state.uploadButtonFor === "equipmentImageFile") {
        setequipmentIconFile(state.equipmentIconfile);
        setequipmentFile(state.file);
      }
    }
  }, [state]);

  function handleDeletePopup(id: any) {
    setOpenDeletePopup(true);
    setPassUnitIdToDelete(id);
    console.log("delete id", passUnitIdToDelete);
  }

  function handleDelete(id: any) {
    onHandleDelete(id);

    setOpenDeletePopup(false);
  }

  return (
    <>
      <div className="flex flex-col h-full  px-[0.45rem]">
        <div className="grow space-y-[1.5rem] py-[1.25rem] overflow-y-scroll no-scrollbar">
          <div>
            <InputText
              value={editData?.name}
              onChange={(e) => onSetEditData(e)}
              labelTitle="Name"
              autoFocus={true}
            />
          </div>

          <div className="flex flex-col space-y-3 px-[0.49rem]">
            <div className="text-[16px] font-[600]">Equipment Icon</div>
            <div className="flex space-x-1 items-center">
              <UploadButton
                name="equipmentIconImageFile"
                type={"image"}
                pageState={{
                  ...state,
                  uploadButtonFor: "equipmentIconImageFile",
                  openEditModal,
                  openEditData,
                  editTitle,
                  editData,
                  equipmentIconfile,
                  equipmentFile,
                }}
                handleUploadFromDevice={(file) => {
                  setequipmentIconFile(file);
                  console.log(file);
                }}
              />
              <UploadDropdowns 
              name="equipmentIconImageFile"
              type={"image"}
              pageState={{
                ...state,
                uploadButtonFor: "equipmentIconImageFile",
                openEditModal,
                openEditData,
                editTitle,
                editData,
                equipmentIconfile,
                equipmentFile,
              }}
              handleCropFile={(cropFile) => setequipmentIconFile(cropFile)}
              openEditModal={openEditModal} File={equipmentIconfile} />
            </div>
          </div>

          <div className="flex flex-col space-y-3  px-[0.49rem]">
            <div className="text-[16px] font-[600]">Equipment Image</div>
            <div className="flex space-x-1 items-center">
              <UploadButton
                name="equipmentImageFile"
                type={"image"}
                pageState={{
                  ...state,
                  uploadButtonFor: "equipmentImageFile",
                  openEditModal,
                  openEditData,
                  editTitle,
                  editData,
                  equipmentIconfile,
                  equipmentFile,
                }}
                handleUploadFromDevice={(file) => {
                  setequipmentFile(file);
                  console.log(file);
                }}
              />
              <UploadDropdowns 
              name="equipmentImageFile"
              type={"image"}
              pageState={{
                ...state,
                uploadButtonFor: "equipmentImageFile",
                openEditModal,
                openEditData,
                editTitle,
                editData,
                equipmentIconfile,
                equipmentFile,
              }}
              handleCropFile={(cropFile) => setequipmentFile(cropFile)}
              openEditModal={openEditModal} File={equipmentFile} />
            </div>
          </div>
        </div>

        <div className=" border-t px-[0.49rem] py-5 flex justify-between items-center">
          <button
            onClick={() => handleDeletePopup(editData.id)}
            className="px-6 py-3 text-[1rem] text-[#FFFFFF] bg-[#929292] rounded-[1.875rem]"
          >
            Delete
          </button>
          <button
            onClick={() => {
              onHandleEdit(equipmentFile, equipmentIconfile);
            }}
            className="px-7 py-3 text-[1rem] text-[#FFFFFF] bg-[#AB0273] rounded-[1.875rem]"
          >
            Done
          </button>
        </div>
      </div>

      <Modal
        onOpened={openDeletePopup}
        onClose={() => setOpenDeletePopup(false)}
        title="Confirm Delete"
        height="calc(100%-400px)"
        passUnitIDtoDelete={passUnitIdToDelete || ""}
      >
        <div className="flex flex-col h-full items-center pt-4 px-4 space-y-4">
          <div className="flex items-center justify-center w-full py-5">
              <span>Are you sure you want to delete this Language?</span>
          </div>

          <div className=" border-t-[0.0625rem] border-[#2C2C2C] py-5 w-full flex justify-between items-center">
              <button
                onClick={() => setOpenDeletePopup(false)}
                className="text-[#FFFFFF] px-5 py-2 text-[16px] rounded-[1.875rem] bg-[#929292]"
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  handleDelete(passUnitIdToDelete);
                }}
                className="text-[#FFFFFF] px-5 py-2 text-[16px] rounded-[1.875rem] bg-red-600"
              >
                Delete
              </button>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default EditEquipments;
