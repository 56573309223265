import React, { useEffect, useState } from "react";
import InputText from "src/utils/TextInput/InputText";
import UploadButton from "src/utils/UploadButton";
import MultipleCheck from "src/utils/MultipleCheck";
import TextArea from "src/utils/TextInput/TextArea";
import UploadDropdowns from "../UploadDropdowns";
import RichTextEditor from "src/utils/RichTE";

type Props = {
  state?: any;
  openDrawerForm?: any;
  users: any[];
  onHandleDocument: (selectedOptions: any) => void;
  document: any;
  handleStateNull?: () => void;
};

function EquipmentDoc({
  state,
  openDrawerForm,
  users,
  document,
  onHandleDocument,
  handleStateNull,
}: Props) {
  const [title, setTitle] = useState(document?.title);
  const [descriptionText, setDescriptionText] = useState(
    document?.descriptionText
  );
  const [savedSelectedOptions, setSavedSelectedOptions] = useState<Array<any>>(
    document?.selectedOptions || []
  );
  const [smallFile, setsmallFile] = useState(document?.smallfile);
  const [mediumFile, setmediumFile] = useState(document?.mediumfile);
  const [largeFile, setlargeFile] = useState(document?.largefile);

  // const [state, setState] = useState<any>(null)

  useEffect(() => {
    console.log("this is document: ", document, state);
    // setTitle(document?.title);
    // setTranscriptText(document?.transcriptText);
    // setSavedSelectedOptions(document?.selectedOptions);

    if (state) {
      if (state.title) setTitle(state.title);
      if (state.descriptionText) setDescriptionText(state.descriptionText);
      if (state.savedSelectedOptions)
        setSavedSelectedOptions(state.savedSelectedOptions);
      if (state.file && state.uploadButtonFor === "smallImageFile") {
        setsmallFile(state.file);
        setmediumFile(state.mediumFile);
        setlargeFile(state.largeFile);
      }
      if (state.file && state.uploadButtonFor === "mediumImageFile") {
        setsmallFile(state.smallFile);
        setmediumFile(state.file);
        setlargeFile(state.largeFile);
      }
      if (state.file && state.uploadButtonFor === "largeImageFile") {
        setsmallFile(state.smallFile);
        setmediumFile(state.mediumFile);
        setlargeFile(state.file);
      }
      if (
        !state.file &&
        (state.uploadButtonFor === "smallImageFile" ||
          state.uploadButtonFor === "mediumImageFile" ||
          state.uploadButtonFor === "largeImageFile")
      ) {
        setsmallFile(state.smallFile);
        setmediumFile(state.mediumFile);
        setlargeFile(state.largeFile);
      }

      if (handleStateNull) handleStateNull();
    }
  }, [state]);

  useEffect(() => {
    handleContentChange();
  }, [
    title,
    savedSelectedOptions,
    descriptionText,
    smallFile,
    mediumFile,
    largeFile,
  ]);

  function handleContentChange() {
    if (state._id && state._id !== document?._id) return;
    const newDocument = {
      _id: document?._id,
      aircraftId: document?.aircraftId,
      catagoryId: document?.catagoryId,
      title,
      selectedOptions: savedSelectedOptions,
      descriptionText,
      smallfile: smallFile,
      mediumfile: mediumFile,
      largefile: largeFile,
    };
    console.log(newDocument);
    onHandleDocument(newDocument);
  }

  return (
    document && (
      <div className="pt-[1.25rem] pb-[1.7rem] space-y-[1.75rem]">
        <div className="px-[0.45rem]">
          <InputText
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder="Emergency Equipment Name"
            labelTitle=""
          />
        </div>

        <div className="flex flex-col px-[0.94rem] space-y-[0.5rem]">
          <div className="text-[16px] font-[600]">Small Image</div>
          <div className="flex space-x-3 items-center">
            <UploadButton
              name="smallImageFile"
              type="image"
              pageState={{
                ...state,
                uploadButtonFor: "smallImageFile",
                title: title,
                selectedOptions: savedSelectedOptions,
                discriptionText: descriptionText,
                smallFile: smallFile,
                mediumFile: mediumFile,
                largeFile: largeFile,
                openDrawerForm: openDrawerForm,
                _id: document?._id,
              }}
              handleUploadFromDevice={(file) => {
                setsmallFile(file);
                console.log(file);
              }}
            />
            <UploadDropdowns
              name="smallImageFile"
              type="image"
              handleCropFile={(cropFile) => setsmallFile(cropFile)}
              openDrawerForm={openDrawerForm}
              File={smallFile}
            />
          </div>
        </div>

        <div className="flex flex-col px-[0.94rem] space-y-[0.5rem]">
          <div className="text-[16px] font-[600]">Medium Image</div>
          <div className="flex space-x-3 items-center">
            <UploadButton
              name="mediumImageFile"
              type="image"
              pageState={{
                ...state,
                uploadButtonFor: "mediumImageFile",
                title: title,
                selectedOptions: savedSelectedOptions,
                discriptionText: descriptionText,
                smallFile: smallFile,
                mediumFile: mediumFile,
                largeFile: largeFile,
                openDrawerForm: openDrawerForm,
                _id: document?._id,
              }}
              handleUploadFromDevice={(file) => {
                setmediumFile(file);
                console.log(file);
              }}
            />
            <UploadDropdowns
              name="mediumImageFile"
              type="image"
              handleCropFile={(cropFile) => setmediumFile(cropFile)}
              openDrawerForm={openDrawerForm}
              File={mediumFile}
            />
          </div>
        </div>

        <div className="flex flex-col px-[0.94rem] space-y-[0.5rem]">
          <div className="text-[16px] font-[600]">Large Image</div>
          <div className="flex space-x-3 items-center">
            <UploadButton
              name="largeImageFile"
              type="image"
              pageState={{
                ...state,
                uploadButtonFor: "largeImageFile",
                title: title,
                savedSelectedOptions: savedSelectedOptions,
                discriptionText: descriptionText,
                smallFile: smallFile,
                mediumFile: mediumFile,
                largeFile: largeFile,
                openDrawerForm: openDrawerForm,
                _id: document?._id,
              }}
              handleUploadFromDevice={(file) => {
                setlargeFile(file);
                console.log(file);
              }}
            />
            <UploadDropdowns
              name="largeImageFile"
              type="image"
              handleCropFile={(cropFile) => setlargeFile(cropFile)}
              openDrawerForm={openDrawerForm}
              File={largeFile}
            />
          </div>
        </div>

        <div className="flex flex-col px-[0.94rem] space-y-[0.5rem]">
          <div className="text-[16px] font-[600]">
            Select Users for this Item:
          </div>
          <MultipleCheck
            SelectTitle="Select Users"
            options={users}
            savedSelectedOptions={
              state && state._id === document?._id
                ? state.savedSelectedOptions
                : savedSelectedOptions
            }
            onHandleSelectedOptions={(selectedOptions) =>
              setSavedSelectedOptions(selectedOptions)
            }
          />
        </div>

        <div className="px-[0.94rem]">
          {/* <TextArea
            value={descriptionText}
            onChange={(e) => setDescriptionText(e.target.value)}
            placeholder="Description Text"
            labelTitle=""
          /> */}
          {descriptionText !== undefined && (
            <RichTextEditor
              placeholder="Description Text"
              value={descriptionText}
              onChange={(content, contentLength) => {
                console.log(content);
                setDescriptionText(content);
              }}
            />
          )}
        </div>
      </div>
    )
  );
}

export default EquipmentDoc;
