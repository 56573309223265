import React from 'react'

type Props = {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  labelTitle: string;
}

function InputDate({ value, onChange, placeholder, labelTitle }: Props) {
  return (
    <div className="flex flex-col space-y-[0.94rem] mx-[0.49rem]">
      <label className="font-[600]  text-[1rem] text-[#242424] leading-normal" htmlFor="input">{labelTitle}</label>
      
      <div className="flex w-[11.625rem] items-center overflow-hidden rounded-[0.375rem] border border-[#586A84] hover:border-tertiary">
        <input className="grow w-full outline-none px-[1.25rem] py-[0.75rem] text-[1rem] text-[#242424] font-[400] bg-[#F5F5F5]  leading-normal" type="date"  value={value}  onChange={(e) => onChange(e)}  placeholder={placeholder}  name="date"/>
      </div>
    </div>
  )
}

export default InputDate;