import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Test } from "src/components/Test";
import { cn } from "src/components/lib/utils";
import PreviewModal from "src/utils/PreviewModal";

type Props = {
  data: any;
  selectedAircraft: any;
  onNext: (nextTemplateId: string) => void;
};

export function LocationDiagram({ data, selectedAircraft, onNext }: Props) {
  const [selectedSectionLocation, setSelectedSectionLocation] =
    useState<any>(null);
  const [selectedHeight, setSelectedHeight] = useState("");
  const [selectedStart, setSelectedStart] = useState("");
  const [selectedPosition, setSelectedPosition] = useState("");

  const [selectedImage, setSelectedImage] = useState<any>(null);
  const [selectedSection, setSelectedSection] = useState<any>(null);
  const [selectedEuipmentDoc, setSelectedEquipmentDoc] = useState<any>(null);

  const { themeColor, headingFont, bodyFont } = useSelector((state: any) => state.programSettings);

  useEffect(() => {
    console.log(data, selectedAircraft, selectedEuipmentDoc);
  }, [selectedEuipmentDoc]);

  const locationsection = (section: any) => {
    return (<>
      {data.content.aircraftDocuments[0].aircraftDoc.locations.filter((location: any) => location.section.id === section.id).map(
        (location: any) => {
          return <ul className="font-semibold text-white list-disc pl-4" key={location.id}>
            <li>{location.name}</li>
          </ul>;
        }
      )}
    </>
    )
  }

  const equipmentlocation = (location: any) => {
    return (<>
      {data.content.aircraftDocuments[0].aircraftDoc.documents.filter((doc: any) => doc.aircraftId === selectedAircraft.id && doc.sectionId === selectedSection.id && doc.locationId === location.id).map(
        (equipmentdoc: any, idx: number) => {
          return (
            <div className={`flex items-center px-5 py-4 w-full ${idx % 2 === 0 ? "bg-[#FFFFFF]" : "bg-[#FEFEFF]/[90]"}`} key={equipmentdoc.id}>
              <button onClick={() => setSelectedImage(equipmentdoc)} className="pr-4">
                <img className="h-12 w-12" src={equipmentdoc.iconfile?.url ?? ""} alt="" />
              </button>
              <button className="w-full flex items-center" onClick={() => handleEuipmentSelect(selectedAircraft.id, selectedSection.id, location.id, equipmentdoc.equipmentId)}>
                <div className="grow flex justify-start text-black text-left text-xl font-bold">{equipmentdoc.equipmentName}</div>
                <div className={`${selectedEuipmentDoc && selectedEuipmentDoc.id === equipmentdoc.id ? "bg-[#AB0273] text-white" : "bg-[#929292] text-black"} font-bold w-[36px] h-[36px] flex items-center justify-center rounded-full`}><span>{equipmentdoc.quantity <= 9 ? `0${equipmentdoc.quantity}` : equipmentdoc.quantity}</span></div>
              </button>

            </div>
          );
        }
      )}
    </>)
  }

  function handleEuipmentSelect(aircraftId: any, sectionId: any, locationId: any, equipmentId: any) {
    console.log(aircraftId, sectionId, locationId, equipmentId)
    if (selectedEuipmentDoc === null) {
      const doc = data.content.aircraftDocuments[0].aircraftDoc.documents.filter((doc: any) => doc.aircraftId === aircraftId && doc.sectionId === sectionId && doc.locationId === locationId && doc.equipmentId === equipmentId)
      console.log(doc)
      setSelectedEquipmentDoc(doc[0])
    }
    else setSelectedEquipmentDoc(null)
  }

  return (
    <div className={`relative w-full xs:h-[calc(100%-3.5rem)] sm:h-[calc(100%-4rem)] md:h-[calc(100%-4.6rem)] lg:h-[calc(100%-5.125rem)] xl:h-[calc(100%-5.625rem)] pt-[1.5rem] md:pt-[1.88rem] pl-[1.7rem] md:pl-[2.5rem] lg:pl-[3.75rem] pr-[1.7rem] md:pr-[2.44rem] flex flex-col space-y-[3.41rem] ${bodyFont && `font-${bodyFont}`}`}>
      <div className={`text-[#FFFFFF] text-[1.7rem] md:text-[2rem] lg:text-[2.5rem] font-semibold  border-l-4 md:border-l-[7px] pl-[1rem] md:pl-[1.5rem] leading-normal flex items-center space-x-1 ${headingFont && `font-${headingFont}`}`}>
        <span className="">{selectedAircraft.name} - </span>
        <span className=""> {data.title}</span>
      </div>

      <div className="w-full flex justify-center">
        <div className="relative w-[45rem] flex justify-center">
          {data.content.aircraftDocuments && (
            <img
              className="h-[723px] aspect-auto"
              src={data.content.aircraftDocuments[0].aircraftDoc.file.url}
              alt="AircraftLocationImage"
            />
          )}
          {
            data.content.aircraftDocuments[0].aircraftDoc.sections.map((section: any) => {

              return <button
                onClick={() => { setSelectedSection(section); }}
                style={{ height: `${section.endY - section.startY}px` || "0px", top: `${section.startY}px` || "0px" }}
                className={cn(
                  `xs:w-[140px] sm:w-[180px] md:w-[280px] lg:w-[306px] absolute flex flex-col`,
                  `${section.position === "Left"
                    ? "left-0"
                    : "right-0"}`
                )}
              >
                <div className={`w-full flex ${section.position === "Left" ? "justify-end" : "justify-start"} `}>
                  <span style={{backgroundColor: themeColor, opacity: 0.8}} className={`${!themeColor && "bg-[#AB0273]/80"} text-white px-2.5 py-[2.5px] text-xl font-bold uppercase`}>{section.name}</span>
                </div>
                <div className={`bg-[#D9D9D966] w-full h-full flex flex-col space-y-2 ${section.position === "Left" ? "items-start" : "items-end"} px-2.5 py-1.5`}>
                  {locationsection(section)}
                </div>
              </button>
            })
          }
          {/* {data.content.aircraftDocuments[0].aircraftDoc.locations &&
            data.content.aircraftDocuments[0].aircraftDoc.locations.map(
              (data: any) => (
                <div
                  style={{
                    height: selectedHeight || "0px",
                    top: selectedStart || "0px",
                  }}
                  className={cn(
                    `absolute flex flex-col p-4 border-2 rounded-lg hover:border-primary`,
                    `bg-black/50 ${selectedPosition}`
                  )}
                >
                  <div className="text-lg font-bold mb-1">
                    {data.section.name}
                  </div>
                  <>
                  <div key={data.id}>{data.name}</div>
                  </>
                </div>
              )
            )} */}
        </div>
      </div>

      {selectedSection &&
        <div>
          <PreviewModal
            onOpened={selectedSection !== null}
            onClose={() => setSelectedSection(null)}
            showCrossIcon={true}
          >
            <>
              <div
                style={{
                  background: `linear-gradient(0deg, rgba(0, 0, 0, 0.70) 0%, rgba(0, 0, 0, 0.70) 100%), url(${data.content.bgfile?.url ?? ""})`,
                  backgroundPosition: "50%",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundColor: "lightgray",
                  color: "#146793",
                }}
                className="w-full h-full pt-[1.5rem] md:pt-[1.88rem] pl-[1.7rem] md:pl-[2.5rem] lg:pl-[3.75rem] pr-[1.7rem] md:pr-[2.44rem] flex flex-col space-y-[3rem] overflow-y-scroll no-scrollbar"
              >
                <div className="text-[#FFFFFF] text-[1.2rem] md:text-[1.6rem] lg:text-[2rem] font-semibold  border-l-4 md:border-l-[7px] pl-[0.8rem] md:pl-[1.3rem] leading-normal flex xs:flex-col sm:flex-col md:flex-row xs:items-start sm:items-start md:items-center md:space-x-1">
                  <span className="flex">
                    <span className="">{selectedAircraft.name} - </span>
                    <span>Location Diagram: </span>
                  </span>
                  <span className=""> {selectedSection && selectedSection.name}</span>
                </div>
                <div className="w-full flex md:space-x-10 xs:flex-col sm:flex-col md:flex-row xs:space-y-3 sm:space-y-3 xs:justify-center">
                  <div className=" w-full md:w-[32%]">
                    <div style={{backgroundColor: themeColor}} className={`flex items-center space-x-6 ${!themeColor && "bg-[#AB0273]"} text-white text-xl font-bold px-5 py-4`}>
                      <div>ITEM</div>
                      <div className="grow">Description</div>
                      <div>QTY</div>
                    </div>
                    {data.content.aircraftDocuments[0].aircraftDoc.locations.filter((location: any) => location.section.id === selectedSection.id).map(
                      (location: any) => {
                        return <div>
                          <div style={{backgroundColor: themeColor, opacity: 0.7}} className={`flex items-center space-x-6 ${!themeColor && "bg-[#AB0273]/70"} text-white text-xl font-bold pl-5 py-4`} key={location.id}>{location.name}</div>
                          {
                            equipmentlocation(location)
                          }
                        </div>;
                      }
                    )}
                  </div>
                  <div className="w-full md:w-[65%] flex justify-center">
                    <Test
                      image={selectedSection?.file ?? ""}
                      // x={x}
                      // y={y}
                      // locatePoints={0}
                      locationPoints={selectedEuipmentDoc?.locationPoints}
                    // onChangeInputs={({ id, x, y }) => {
                    //   handleChange(id, x, y);
                    // }}
                    />
                  </div>
                </div>
              </div>
            </>
          </PreviewModal>
        </div>}

      {selectedImage &&
        <div>
          <PreviewModal
            onOpened={selectedImage !== null}
            onClose={() => setSelectedImage(null)}
            showCrossIcon={true}
          >
            <>
              <div
                style={{
                  background: `linear-gradient(0deg, rgba(0, 0, 0, 0.70) 0%, rgba(0, 0, 0, 0.70) 100%), url(${data.content.bgfile?.url ?? ""})`,
                  backgroundPosition: "50%",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundColor: "lightgray",
                  color: "#146793",
                }}
                className="w-full h-full py-[1.5rem] md:py-[1.88rem] pl-[1.7rem] md:pl-[2.5rem] lg:pl-[3.75rem] pr-[1.7rem] md:pr-[2.44rem] flex flex-col space-y-[1.38rem] overflow-y-scroll no-scrollbar"
              >
                <div className="text-[#FFFFFF] text-[1.2rem] md:text-[1.6rem] lg:text-[2rem] font-semibold  border-l-4 md:border-l-[7px] pl-[0.8rem] md:pl-[1.3rem] leading-normal flex xs:flex-col sm:flex-col md:flex-row xs:items-start sm:items-start md:items-center md:space-x-1">
                  <span className="flex">
                    <span className="">{selectedAircraft.name} - </span>
                    <span>Location Diagram: </span>
                  </span>
                  <span className=""> {selectedSection && selectedSection.name}</span>
                </div>
                <div className="text-[#FFFFFF] text-[1rem] md:text-[1.25rem] lg:text-[1.5rem] font-semibold pl-2 md:pl-[2rem] leading-normal flex items-center space-x-1">
                  <span className="">{selectedImage.equipmentName}</span>
                </div>
                <div className="relative md:ml-[1rem] flex flex-col w-full max-w-[81.6875rem] max-h-[49.0625rem] rounded-[1.25rem] bg-black/80">
                  <img className="h-full w-full object-contain" src={selectedImage.file?.url ?? ""} alt="" />
                </div>
              </div>
            </>
          </PreviewModal>
        </div>}
    </div>
  );
}
