import React from 'react'

type Props = {
    title: string
}

function TemplateHeading({title}: Props) {
  return (
    <div className="flex flex-col">
      <h1 className="font-[600] text-[1.5rem] text-[#AB0273] leading-normal px-[0.49rem]">{title}</h1>
      <div className='bg-[#242424] h-[0.0625rem] my-[0.88rem]'></div>
    </div>
  )
}

export default TemplateHeading;