import { useEffect, useRef, useState } from "react";
import L from "leaflet";
import "leaflet/dist/leaflet.css";

type Props = {
  image: string;
  x?: number;
  y?: number;
  locatePoints?: number;
  locationPoints?: number[][];
  onChangeInputs?: ({ id, x, y }: { id: number; x: number; y: number }) => void;
  sectionBounds?: number[];
};

export function MapScreen({
  image,
  x,
  y,
  locatePoints,
  locationPoints = [],
  onChangeInputs,
  sectionBounds,
}: Props) {
  const mapRef = useRef<L.Map | null>(null);
  const markerRef = useRef<L.Marker | null>(null);
  const [imageSize, setImageSize] = useState<{ width: number; height: number } | null>(null);

  useEffect(() => {
    console.log(locatePoints, x, y);
  }, [locatePoints, x, y]);

  useEffect(() => {
    const img = new Image();
    img.src = image;
    img.onload = () => {
      setImageSize({ width: img.naturalWidth, height: img.naturalHeight });
    };
  }, [image]);

  useEffect(() => {
    if (!imageSize || mapRef.current) return;

    const { width, height } = imageSize;
    const centerH = sectionBounds && sectionBounds.length >= 2
      ? ((sectionBounds[1] - sectionBounds[0]) / 2) + sectionBounds[0]
      : height / 2;
    const centerW = width / 2;

    if (isNaN(centerH) || isNaN(centerW)) {
      console.error('Invalid center coordinates:', { centerH, centerW });
      return;
    }

    const map = L.map("map", {
      center: [centerH, centerW],
      zoom: -1,
      minZoom: -2,
      crs: L.CRS.Simple,
      maxBounds: [
        [0, 0],
        [height, width],
      ],
      maxBoundsViscosity: 1.0,
      zoomControl: true,
    });

    const bounds: L.LatLngBoundsExpression = [
      [0, 0],
      [height, width],
    ];
    L.imageOverlay(image, bounds).addTo(map);
    map.fitBounds(bounds, { padding: [20, 20] });
    mapRef.current = map;
  }, [imageSize]);

  useEffect(() => {
    if (!mapRef.current || locatePoints === undefined) return;

    if (markerRef.current) {
      markerRef.current.remove();
      markerRef.current = null;
    }

    const adjustedX = x === 0 && sectionBounds ? sectionBounds[1] : x;
    const adjustedY = y === 0 && imageSize ? 0 : y;

    if (adjustedX === undefined || adjustedY === undefined || isNaN(adjustedX) || isNaN(adjustedY)) {
      console.warn("Invalid marker coordinates, placing at default");
      return;
    }

    if(locatePoints > 0) {
        const marker = L.marker([adjustedX, adjustedY], {
          draggable: true,
          icon: L.divIcon({
            className: "blue-dot blinking",
          }),
        }).addTo(mapRef.current);
    
        marker.on("dragend", (event) => {
          const { lat, lng } = (event.target as L.Marker).getLatLng();
          if (onChangeInputs) {
            onChangeInputs({ id: locatePoints, x: lat, y: lng });
          }
        });

        markerRef.current = marker;
    }

  }, [locatePoints, x, y, imageSize, sectionBounds]);

  useEffect(() => {
    if (!mapRef.current || !sectionBounds || sectionBounds.length !== 2) return;

    const [startHeight, endHeight] = sectionBounds;
    if (!imageSize) return;
    const { width: imageWidth } = imageSize;

    if ([startHeight, endHeight].some(isNaN)) {
      console.warn("Invalid sectionBounds values:", sectionBounds);
      return;
    }

    const rectangleBounds: L.LatLngBoundsExpression = [
      [startHeight, 0],
      [endHeight, imageWidth],
    ];

    mapRef.current.eachLayer((layer) => {
      if (layer instanceof L.Rectangle) {
        mapRef.current?.removeLayer(layer);
      }
    });

    L.rectangle(rectangleBounds, {
      color: "lightcoral",
      weight: 2,
      fillOpacity: 0.2,
    }).addTo(mapRef.current);

    mapRef.current.fitBounds(rectangleBounds, {
      animate: true,
      padding: [20, 20],
    });
  }, [imageSize, sectionBounds]);

  return <div id="map" className="h-full w-full"></div>;
}


// import { useEffect, useRef, useState } from 'react';
// import L from 'leaflet';
// import 'leaflet/dist/leaflet.css';

// type LocationPoint = [number, number, number]; // [id, x, y]

// type Equipment = {
//     equipmentName: string;
//     locationPoints: LocationPoint[];
//     file: { url: string };
// };

// type Props = {
//     image: any;
//     selectedEquipment?: Equipment;
// };

// export function MapScreen({ image, selectedEquipment }: Props) {
//     const mapRef = useRef<L.Map | null>(null); // Reference to track the map instance
//     const [mapLoaded, setMapLoaded] = useState(false);
//     const [aspectRatio, setAspectRatio] = useState(1);

//     function getAspectRatio(image: HTMLImageElement): number {
//         return image.naturalWidth / image.naturalHeight;
//     }

//     useEffect(() => {
//         const img = new Image();
//         img.src = image;
//         img.onload = () => {
//             const calculatedAspectRatio = getAspectRatio(img);
//             setAspectRatio(calculatedAspectRatio); // Update aspect ratio in state
//             console.log('Aspect Ratio:', calculatedAspectRatio);
//         };
//     }, [image]);

//     console.log(aspectRatio);

//     const mapBounds: L.LatLngBoundsLiteral = [[0, 0], [4050, 800]];
//     // const mapBounds: L.LatLngBoundsLiteral = [[0, 0], [4050, (4050 * aspectRatio)]];

//     // Scaling function for location points
//     const scaleCoordinates = (x: number, y: number) => {
//         const scaledX = 4050 - ((y / 600) * 4050);
//         const scaledY = (x / 400) * 800;
//         // const scaledY = (x / 400) * (4050 * aspectRatio);
//         console.log(`Original: (${x}, ${y}) -> Scaled: (${scaledX}, ${scaledY})`);
//         return [scaledX, scaledY];
//     };

//     useEffect(() => {
//         if (mapRef.current) return;

//         const leafletCSS = document.createElement('link');
//         leafletCSS.rel = 'stylesheet';
//         leafletCSS.href = 'https://unpkg.com/leaflet/dist/leaflet.css';
//         document.head.appendChild(leafletCSS);

//         const leafletJS = document.createElement('script');
//         leafletJS.src = 'https://unpkg.com/leaflet/dist/leaflet.js';
//         document.body.appendChild(leafletJS);

//         leafletJS.onload = () => {
//             const newMap = L.map('map', {
//                 center: [2025, 400],
//                 zoom: 0,
//                 crs: L.CRS.Simple,
//                 maxBounds: mapBounds,
//                 maxBoundsViscosity: 1.0,
//                 dragging: true,
//                 zoomControl: false,
//                 scrollWheelZoom: false,
//             });

//             L.imageOverlay(image || '', mapBounds).addTo(newMap);
//             newMap.fitBounds(mapBounds);

//             mapRef.current = newMap;
//             setMapLoaded(true);
//         };

//         return () => {
//             if (mapRef.current) {
//                 mapRef.current.remove();
//                 mapRef.current = null;
//             }
//         };
//     }, []);

//     // Scroll to equipment location
//     useEffect(() => {
//         if (!mapRef.current || !mapLoaded || !selectedEquipment?.locationPoints?.length) return;

//         selectedEquipment.locationPoints.forEach(([_, x, y]) => {
//             const [scaledX, scaledY] = scaleCoordinates(x, y);

//             mapRef.current?.panTo([scaledX, scaledY], { animate: true, duration: 1.5 });

//             L.marker([scaledX, scaledY], {
//                 icon: L.divIcon({
//                     className: 'bg-blue rounded-full w-4 h-4 border-2 border-white animate-pulse',
//                 }),
//             }).addTo(mapRef.current as L.Map); // Explicitly assert mapRef as non-null
//         });
//     }, [selectedEquipment, mapLoaded]);

//     return <div id="map" className="h-[100vh] w-full"></div>;
// }
