import React, { useEffect, useState } from "react";
import AddFolderButton from "./Button/AddFolderButton";
import AddFileButton from "./Button/AddFileButton";
import { useSelector } from "react-redux";
import RenameLogo from "../../assets/rename.png";
import CopyLogo from "../../assets/copy.svg";
import MoveLogo from "../../assets/move.svg";
import StarredLogo from "../../assets/star.svg";
import DeleteLogo from "../../assets/delete.svg";
import ShareLogo from "../../assets/share.png";
import Upload from "../../assets/export2.png";
import useFolder, { ROOT_FOLDER } from "src/hooks/useFolder";
import {
  CopyObjects,
  DeleteFilesAndFolders,
  DeleteManyObject,
  DeleteObject,
  Rename,
  copyFolder,
  moveFolder,
  renameFolder,
} from "src/helpers/AWS_S3";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import axios from "axios";
import { useDispatch } from "react-redux";
import Modal from "src/utils/Modal";
import Text from "src/utils/Text/Txt";
import { ChevronLeft } from "@carbon/icons-react";
import { setAddPageState } from "src/features/templatePageState/templatePageStateSlice";
import { setLog } from "src/features/errorLog/errorLogSlice";
import {
  setSelectFile,
  setUpdateFolder,
} from "src/features/fileFolder/fileFolderSlice";
import { resolve } from "path";

type ToolbarProps = {
  items: any[];
  onHandleSearchResults: (searchResults: any[], searchInput: string) => void;
  searchInput: string;
};

function Toolbar({ items, onHandleSearchResults, searchInput }: ToolbarProps) {
  const navigate = useNavigate();
  const { businessUnitId, sepmId, templateId, userId } = useParams();

  const [openRenamePopup, setOpenRenamePopup] = useState(false);
  const [renamedTitle, setRenamedTitle] = useState<string>("");
  const [renamedId, setRenamedId] = useState<string>("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const { folder } = useSelector((state: any) => state.fileFolder);
  const { authUser } = useSelector((state: any) => state.user);
  const folderId = searchParams.get("folderId");
  const { fileFolder } = useFolder({
    folderId: folderId === "null" ? null : folderId,
    folder,
  });
  const currentFolder = fileFolder?.folder;

  const [copySourceKeys, setCopySourceKeys] = useState([]);
  const [cutSourceKeys, setCutSourceKeys] = useState([]);

  const [searchTerm, setSearchTerm] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const [searchResults, setSearchResults] = useState<Array<any>>([]);

  const { accessToken } = useSelector((state: any) => state.accessToken);

  const { pageState } = useSelector((state: any) => state.templatePageState);

  const dispatch = useDispatch();

  const itemsWithKey = items.reduce((acc, curr) => {
    const filePath =
      currentFolder === ROOT_FOLDER
        ? `${currentFolder?.path.join("/")}/${curr.name}`
        : `${currentFolder?.path.join("/")}/${currentFolder?.name}/${curr.name
        }`;


    acc.push({
      ...curr,
      Key: `uploads/users/${authUser.oid}${filePath}${curr.type ? "" : "/"}`,
    });

    return acc;
  }, []);

  useEffect(() => setSearchTerm(searchInput), [searchInput])

  useEffect(() => {
    console.log("items: ", items, itemsWithKey)
  }, [items])

  useEffect(() => {
    if (searchTerm !== "") {
      const timeoutId = setTimeout(() => {
        setIsLoading(true);
        axios
          .get(
            `${process.env.REACT_APP_SERVER_BASE_URL}/api/v1/folder/search?searchTerm=${searchTerm}`,
            {
              headers: { Authorization: `Bearer ${accessToken}` },
            }
          )
          .then((res) => {
            console.log(res);
            setSearchResults(res.data.data);
            // console.log(searchResults);
            onHandleSearchResults(res.data.data, searchTerm);
            setIsLoading(false);
          })
          .catch((error) => {
            console.error("Search failed:", error);
            setIsLoading(false)
          });
      }, 2000);
      return () => clearTimeout(timeoutId);
    } else {
      setSearchResults([]);
      onHandleSearchResults([], searchTerm);
    }
  }, [searchTerm]);

  // console.log(keys);

  function handleDeletePopup() {
    setOpenDeletePopup(true);
    // setPassUnitIdToDelete(id);
  }

  async function handleDelete() {
    // const toDelete = items.map((item) => {
    //   return { _id: item._id };
    // });

    await axios
      .post(
        `${process.env.REACT_APP_SERVER_BASE_URL}/api/v1/folder/delete`,
        {
          items,
        },
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      )
      .then((res) => res.data)
      .then((response) => {
        console.log("deleted result: ", response.data);
        setOpenDeletePopup(false);
      })
      .catch((err) => {
        console.error(err);
      });

    DeleteFilesAndFolders(itemsWithKey).then((res) => {
      console.log("deleted result: ", res);
      window.location.reload();
    }).catch((err) => console.error(err))

    // if (itemsWithKey.length > 1) {
    //   await DeleteManyObject(itemsWithKey);
    // } else {
    //   console.log("this is the deleted key: ", itemsWithKey[0].Key)
    //   await DeleteObject(itemsWithKey[0].Key);
    // }
    // window.location.reload();
  }

  async function handleCopy() {
    const filePath =
      currentFolder === ROOT_FOLDER
        ? `${currentFolder.path.map((item: any) => item.name).join("/")}/`
        : `${currentFolder.path.map((item: any) => item.name).join("/") && `/${currentFolder.path.map((item: any) => item.name).join("/")}`}/${currentFolder.name}/`;


    const destinationKey = `uploads/users/${authUser.oid}${filePath}` + itemsWithKey[0].name + (itemsWithKey[0].type ? "" : "/");

    console.log("destinationKey: ", destinationKey, currentFolder)

    await axios
      .post(
        `${process.env.REACT_APP_SERVER_BASE_URL}/api/v1/folder/copyto`,
        {
          items: copySourceKeys,
          folderId,
          destinationKey,
          path: [...currentFolder.path, { name: currentFolder.name, _id: currentFolder._id }],
        },
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      )
      .then((res) => res.data)
      .then((response) => {
        console.log("deleted result: ", response.data);
      })
      .catch((err) => {
        console.error(err);
      });


    const promises: any = []
    copySourceKeys.forEach(async (key: any) => {
      const response = copyFolder(
        key.Key,
        destinationKey
      );
      promises.push(response)
    });

    Promise.all(promises).then((values) => {
      console.log(values);
      window.location.reload();
    }).catch((err) => console.error(err))

  }

  async function handleCut() {
    const filePath =
      currentFolder === ROOT_FOLDER
        ? `${currentFolder.path.map((item: any) => item.name).join("/")}/`
        : `${currentFolder.path.map((item: any) => item.name).join("/") && `/${currentFolder.path.map((item: any) => item.name).join("/")}`}/${currentFolder.name}/`;


    const destinationKey = `uploads/users/${authUser.oid}${filePath}` + itemsWithKey[0].name + (itemsWithKey[0].type ? "" : "/");

    await axios
      .post(
        `${process.env.REACT_APP_SERVER_BASE_URL}/api/v1/folder/moveto`,
        {
          items: cutSourceKeys,
          folderId,
          destinationKey,
          path: [...currentFolder.path, { name: currentFolder.name, _id: currentFolder._id }],
        },
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      )
      .then((res) => res.data)
      .then((response) => {
        console.log("deleted result: ", response.data);
      })
      .catch((err) => {
        console.error(err);
      });

    const promises: any = []
    cutSourceKeys.forEach(async (key: any) => {
      const response = moveFolder(
        key.Key,
        destinationKey
      );
      console.log(response);
    });

    Promise.all(promises).then((values) => {
      console.log(values);
      window.location.reload();
    }).catch((err) => console.error(err))

    // cutSourceKeys.forEach(async (key: any) => {
    //   const response = await CopyObjects(
    //     key,
    //     destinationKey + key.Key.split("/").at(-1) + (key.type ? "" : "/")
    //   );
    //   console.log(response);
    //   console.log("moveto: ", key);
    //   await DeleteObject(key.Key);
    // });


    // if (itemsWithKey.length > 1) {
    //   await DeleteManyObject(itemsWithKey);
    // } else {
    //   await DeleteObject(itemsWithKey[0].Key);
    // }
  }

  function handleRenamePopup() {
    setOpenRenamePopup(true);
    console.log(itemsWithKey);
    setRenamedTitle(itemsWithKey[0].name);
    setRenamedId(itemsWithKey[0]._id);
  }

  async function renameTitle(id: any, name: string) {
    await axios
      .post(
        `${process.env.REACT_APP_SERVER_BASE_URL}/api/v1/folder/rename/${id}`,
        {
          items: itemsWithKey[0],
          name,
        },
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      )
      .then((res) => res.data)
      .then((response) => {
        console.log(response.data);
        setOpenRenamePopup(false);
      })
      .catch((err) => {
        console.error(err);
      });

    const filePath =
      currentFolder === ROOT_FOLDER
        ? `${currentFolder.path.map((item: any) => item.name).join("/")}/`
        : `${currentFolder.path.map((item: any) => item.name).join("/") && `/${currentFolder.path.map((item: any) => item.name).join("/")}`}/${currentFolder.name}/`;


    const destinationKey = `uploads/users/${authUser.oid}${filePath}`;

    // const result = await Rename(
    //   itemsWithKey[0],
    //   destinationKey + renamedTitle + (itemsWithKey[0].type ? "" : "/")
    // );

    {
      itemsWithKey[0].type ? await Rename(
        itemsWithKey[0],
        destinationKey + renamedTitle + (itemsWithKey[0].type ? "" : "/")
      ) : await renameFolder(itemsWithKey[0].Key, destinationKey + renamedTitle + "/")
    }

    window.location.reload();
  }

  function handleClick(item: any) {
    if (item.type && !pageState) {
      if (searchParams.get("type") !== item.type.split("/")[0]) {
        dispatch(
          setLog({
            mode: "warn",
            message: "Selected file is not matched with required file type",
          })
        );
        return;
      }
      dispatch(setSelectFile({ file: item }));
      navigate(
        `/user/${userId}/businessunit/${businessUnitId}/sepm/${sepmId}/template/${templateId}?screen=${searchParams.get(
          "screen"
        )}`
      );
      setSearchResults([]);
      setSearchTerm("");
      return;
    }
    if (item.type && pageState) {
      if (searchParams.get("type") !== item.type.split("/")[0]) {
        dispatch(
          setLog({
            mode: "warn",
            message: "Selected file is not matched with required file type",
          })
        );
        return;
      }
      dispatch(setAddPageState({ file: item }));
      navigate(
        `/user/${userId}/businessunit/${businessUnitId}/sepm/${sepmId}/template/${templateId}?screen=${searchParams.get(
          "screen"
        )}`
      );
      setSearchResults([]);
      setSearchTerm("");
      return;
    }

    console.log(item._id, searchParams);
    searchParams.set("folderId", item._id);
    setSearchParams(searchParams);
    dispatch(setUpdateFolder({ item }));
    setSearchResults([]);
    setSearchTerm("");
  }

  return (
    <div className="py-1 w-full bg-white-300 ">
      <div className="grow relative flex items-center space-x-3 my-1">
        <button
          onClick={() =>
            navigate(
              `/user/${userId}/businessunit/${businessUnitId}/sepm/${sepmId}/template/${templateId}?screen=${searchParams.get(
                "screen"
              )}`
            )
          }
        >
          <ChevronLeft size={32} />
        </button>
        <AddFolderButton />
        <div className="grow">
          {items.length > 0 && (
            <div className="flex space-x-2 bg-white rounded-sm">
              <div className="flex space-x-2 items-center px-3 py-1">
                {items.length === 1 && (
                  <button
                    onClick={() => handleRenamePopup()}
                    className="flex space-x-1 items-center px-3 py-1 rounded-md hover:bg-[#E0CBD9] "
                  >
                    <span className="w-7 h-7">
                      <img src={RenameLogo} alt="logo" />
                    </span>
                    <button>Rename</button>
                  </button>
                )}
                <button
                  onClick={() => handleDeletePopup()}
                  // onClick={() => handleDelete()}
                  className="flex space-x-1 items-center px-3 py-1 rounded-md hover:bg-[#E0CBD9] text-red-500"
                >
                  <span>
                    <img src={DeleteLogo} alt="logo" />
                  </span>
                  <span>Delete</span>
                </button>
                <button
                  onClick={() => setCutSourceKeys(itemsWithKey)}
                  className={`flex items-center space-x-1 px-3 py-1 rounded-md hover:bg-[#E0CBD9] ${cutSourceKeys.length !== 0 && "bg-[#E0CBD9] text-black"
                    }`}
                >
                  <span>
                    <img src={StarredLogo} alt="logo" />
                  </span>
                  <button>Cut</button>
                </button>
                <button
                  onClick={() => setCopySourceKeys(itemsWithKey)}
                  className={`flex items-center space-x-1 px-3 py-1 rounded-md hover:bg-[#E0CBD9] ${copySourceKeys.length !== 0 && "bg-[#E0CBD9] text-black"
                    }`}
                >
                  <span>
                    <img src={CopyLogo} alt="logo" />
                  </span>
                  <button>Copy</button>
                </button>
                <button
                  disabled={
                    copySourceKeys.length === 0 && cutSourceKeys.length === 0
                  }
                  onClick={() => {
                    if (copySourceKeys.length !== 0) return handleCopy();
                    else if (cutSourceKeys.length !== 0) return handleCut();
                  }}
                  className={`flex items-center space-x-1 disabled:text-[#afafaf]`}
                >
                  <span>
                    <img src={MoveLogo} alt="logo" />
                  </span>
                  <button>Paste</button>
                </button>
              </div>
              <div className="flex items-center">
                <span className="flex items-center border border-gray-300 rounded-full space-x-1 px-3 pt-1 pb-1">
                  <span
                    className="font-bold"
                    onChange={(e) => {
                      // setCheckedAllItems([]);
                    }}
                  >
                    X
                  </span>
                  <span className="whitespace-nowrap">
                    {items.length} selected
                  </span>
                </span>
              </div>
            </div>
          )}
        </div>
        <form className="group relative">
          <svg
            width="20"
            height="20"
            fill="currentColor"
            className="absolute left-3 top-1/2 -mt-2.5 text-slate-400 pointer-events-none group-focus-within:text-blue-500"
            aria-hidden="true"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
            />
          </svg>
          <input
            className="focus:ring-2 focus:ring-blue-500 focus:outline-none appearance-none w-full text-sm leading-6 text-slate-900 placeholder-slate-400 rounded-md py-2 pl-10 ring-1 ring-slate-300 shadow-sm"
            type="text"
            aria-label="Search"
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </form>

        {/* <div>
          {searchResults.length > 0 && (
            <div className="overflow-hidden z-10 absolute top-[3.2rem] left-0 bg-white border shadow-lg p-2 h-screen w-full max-w-[90rem]">
              <ul className="w-full flex flex-col items-start justify-center">
                {searchResults.map((item, idx) => (
                  <li
                    key={idx}
                    className="cursor-pointer flex items-center hover:text-black rounded-md p-1 hover:bg-gray-200 space-x-3"
                  >
                    <button
                      onClick={() => handleClick(item)}
                      className="flex space-x-1"
                    >
                      {item?.type?.split("/")[0] === "image" && <div>🖼️</div>}
                      {item?.type?.split("/")[0] === "audio" && <div>🎙️</div>}
                      {item?.type?.split("/")[0] === "video" && <div>📷</div>}
                      {item?.type?.split("/")[0] === "application" && (
                        <div>📄</div>
                      )}
                      {item?.type ? "" : "📁"}

                      <div className="w-full hover:underline">{item.name}</div>
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div> */}

        <AddFileButton />
        <div className="flex items-center space-x-1 bg-[#AB0273] text-[#FFFFFF] rounded-lg px-2 py-2 hover:bg-[#AB0273]/80 justify-end">
          <div>📄</div>
          <div className="whitespace-nowrap">AI Generative Image</div>
        </div>
      </div>

      <Modal
        onOpened={openDeletePopup}
        onClose={() => setOpenDeletePopup(false)}
        title="Confirm Delete"
        height="calc(100%-400px)"
      // passUnitIDtoDelete={passUnitIdToDelete || ""}
      >
        <div className="flex flex-col h-full items-center pt-4 px-4 space-y-4">
          <div className="flex items-center justify-center w-full py-5">
            <span>
              Are you sure you want to delete item?
            </span>
          </div>

          <div className="border-t-[0.0625rem] border-[#2C2C2C] py-5 w-full flex justify-center space-x-[4rem] items-center">
            <button
              onClick={() => setOpenDeletePopup(false)}
              className="text-[#FFFFFF] px-5 py-2 text-[16px] rounded-[1.875rem] bg-[#929292]"
            >
              Cancel
            </button>
            <button
              onClick={() => {
                // handleDelete(passUnitIdToDelete);
                handleDelete();
              }}
              className="text-[#FFFFFF] px-5 py-2 text-[16px] rounded-[1.875rem] bg-red-600"
            >
              Delete
            </button>
          </div>
        </div>
      </Modal>

      <Modal
        onOpened={openRenamePopup}
        onClose={() => setOpenRenamePopup(false)}
        title="Rename"
        height="calc(100%-400px)"
      >
        <div className="flex flex-col h-full">
          <div className="grow space-y-4">
            <div>
              <Text
                value={renamedTitle}
                onChange={(e) => setRenamedTitle(e.target.value)}
                labelTitle="Name"
                autoFocus={true}
              />
            </div>
          </div>
          <div className=" border-t p-5 flex justify-between items-center">
            <button
              onClick={() => {
                renameTitle(renamedId, renamedTitle);
              }}
              className="px-7 py-3 rounded-md text-white bg-primary"
            >
              Done
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default Toolbar;
