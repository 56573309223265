import React, { Component } from "react";
import ReactDOM from "react-dom";
import { BlockToolConstructorOptions } from "@editorjs/editorjs";
import Accordions from "./Accordions";

type AccordionType = {
    id: number;
    title: string;
    content: string;
}

class AccordionsTool {
    private data: { accordions: AccordionType[]};
    private api: any;
    private wrapper: HTMLElement | null;
  
    constructor({ data, api }: BlockToolConstructorOptions) {
      this.data = data || { accordions: [] };
      this.api = api;
      this.wrapper = null;
    }
  
    render() {
      if (!this.wrapper) {
        this.wrapper = document.createElement("div");
        this.wrapper.classList.add("accordions-tool-wrapper");
      } else {
        this.wrapper.innerHTML = ''; // Clear the wrapper's content before re-rendering
      }
    
      this.renderComponent();
    
      return this.wrapper;
    }
  
    renderComponent() {
      ReactDOM.render(
        <AccordionsToolComponent
          data={this.data}
          onChange={this.handleTabsChange.bind(this)}
        />,
        this.wrapper
      );
    }
  
    handleTabsChange(accordions: AccordionType[],) {
      this.data = { ...this.data, accordions };
      console.log(this.data);
      this.renderComponent(); // Re-render the component with the updated data
      // this.api.blocks.save(); // Save the block data when tabs change
    }
  
    save() {
      return this.data;
    }
  
    static get toolbox() {
      return {
        title: "Accordion",
        icon: "<svg>...</svg>", // Add your custom icon SVG here
      };
    }
  }
  
  export default AccordionsTool;

  type AccordionsToolProps = {
    data: {
        accordions: AccordionType[];
    }
    onChange: (accordions: AccordionType[]) => void;
  }

  class AccordionsToolComponent extends Component<AccordionsToolProps> {
    render() {
        return (
            <Accordions
                accordions={this.props.data.accordions}
                onChange={this.props.onChange}
            />
        )
    }
  }
