import React, { useEffect, useState } from "react";
import Click from "./Click/Click";
import Reveal from "./Reveal/Reveal";
import RichTextEditor from 'src/utils/RichTE';
import { OverflowMenuHorizontal } from "@carbon/icons-react";
import Text from "src/utils/Text/Txt";
import Modal from "src/utils/Modal";

type ClickToReveal = {
  id: number;
  content: string;
  name: string;
};

type Props = {
  clickToReveal: ClickToReveal[];
  onChange: (clickToReveal: ClickToReveal[]) => void;
};

const initialClickToReveal: ClickToReveal[] = [{
  id: Date.now(),
  content: "",
  name: "Click 1",
},
];

function ClickToReveal({ clickToReveal, onChange }: Props) {
  const [clickToRevealData, setClickToRevealData] = useState<ClickToReveal[]>(clickToReveal || initialClickToReveal);
  const [type, setType] = useState<string>("click");
  const [buttonText, setButtonText] = useState<string>(clickToReveal ? clickToReveal[0].name : "");
  const [description, setDescription] = useState<string>(clickToReveal ? clickToReveal[0].content : "");
  const [updatedclickToReveals, setUpdatedclickToReveals] = useState<ClickToReveal[]>(clickToReveal || initialClickToReveal);
  const [openClickId, setOpenClickId] = useState<number | null>(null);
  const [menuOpenId, setMenuOpenId] = useState<number | null>(null);
  const [openRenamePopup, setOpenRenamePopup] = useState(false);
  const [renamedClickToRevealId, setRenamedClickToRevealId] = useState<number | null>(null);
  const [renamedClickToRevealName, setRenamedClickToRevealName] = useState("");

  const currentItem = clickToRevealData.find(item => item.id === openClickId);
  const placeholderText = currentItem ? `This is the reveal text for ${currentItem.name}` : 'Select a click to reveal';

  useEffect(() => {
    console.log("click to reveal data", clickToRevealData);
    onChange(clickToRevealData); // Notify parent about the initial click to reveal state
    if (clickToRevealData.length > 0 && openClickId === null) {
      setOpenClickId(clickToRevealData[0].id);
    }
  }, [clickToRevealData]);

  const handleClickToRevealTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newType = e.target.value;
    setType(newType);
  };

  const handleClickToRevealChange = (updatedClickToReveal: ClickToReveal[]) => {
    setClickToRevealData(updatedClickToReveal);
  };

  const addClick = () => {
    const newClick: ClickToReveal = {
      id: Date.now(),
      content: "",
      name: `Click ${clickToRevealData.length + 1}`,
    };
    setClickToRevealData(prevClickToReveal => [...prevClickToReveal, newClick]);
    setOpenClickId(newClick.id);
  };
   
  const toggleMenu = (id: number) => {
    setMenuOpenId(prev => (prev === id ? null : id));
  };

  const handleInputChange = (id: number, value: string) => {
    setClickToRevealData(prev => 
      prev.map(item => 
        item.id === id ? { ...item, name: value } : item
      )
    );
  };

  const handleEditorChange = (id: number, content: string) => {
    setClickToRevealData(prev => 
      prev.map(item => 
        item.id === id ? { ...item, content } : item
      )
    );
  };

  const toggleClick = (id: number) => {
      setOpenClickId(id);
  };

  const handleRemoveClickToReveal = (id: number) => {
    const updatedClickToReveals = clickToRevealData.filter(item => item.id !== id);
    setClickToRevealData(updatedClickToReveals);
    setMenuOpenId(null); // Close the menu after removing an item
    if (openClickId === id) {
      setOpenClickId(null); // Close the editor if it's open for the removed item
    }
  };

  const openRenameModal = (item: ClickToReveal) => {
    setRenamedClickToRevealId(item.id);
    setRenamedClickToRevealName(item.name);
    setOpenRenamePopup(true);
    setMenuOpenId(null);
  };

  const handleRenameClickToReveal = (id: number, newName: string) => {
    setClickToRevealData(prev => 
      prev.map(item => 
        item.id === id ? { ...item, name: newName } : item
      )
    );
  };

  const handleRename = () => {
    if (renamedClickToRevealId !== null) {
      handleRenameClickToReveal(renamedClickToRevealId, renamedClickToRevealName);
      setOpenRenamePopup(false);
    }
  };
  // useEffect(() => {
  //   console.log("click to reveal", updatedclickToReveals);
  //   onChange(updatedclickToReveals);
  // }, [updatedclickToReveals]);

  return (
    <div className="flex flex-col mt-5">
      <span className="col-span-1 flex items-center space-x-2">
        <span className="font-semibold text-[1rem] text-[#242424] flex-shrink-0">
          Add more click to reveal
        </span>
        <button
          onClick={addClick}
          className="border rounded-full px-4 py-2 text-[1rem] bg-[#AB0273] text-[#ffffff] flex-shrink-0"
        >
          Add Click +
        </button>
      </span>

      {/* <Click clickToReveal={clickToRevealData} onChange={handleClickToRevealChange}/> */}
      <div className="flex  mt-5">
         <div className="relative">
            <div className="max-h-[260px]  no-scrollbar">
                {clickToRevealData.map(item => (
                  <div className="group relative flex justify-between" key={item.id}>
                    <button
                      onClick={() => toggleClick(item.id)}
                      className={`rounded-md w-32 px-3 py-2 bg-[#AB0273] text-white text-center mb-2 hover:bg-[#E0CBD9]`}
                      title={item.name}
                    >
                      <span className="line-clamp-1">{item.name}</span>
                    </button>
                    <button
                      onClick={() => toggleMenu(item.id)}
                      className={`absolute group-hover:visible right-1 top-2 ${
                      menuOpenId !== item.id ? "invisible" : "visible"
                       }`}
                     >
                      <OverflowMenuHorizontal size="24" />
                    </button>
                    {menuOpenId === item.id && (
                      <div className="absolute top-10 left-7 w-36 bg-gray-100 border rounded shadow-lg z-[9999]">
                          <button
                            onClick={() => openRenameModal(item)}
                            className="block px-4 py-2 text-left w-full hover:bg-[#E0CBD9] hover:bg-purple/20"
                           >
                             Rename
                          </button>
                          <button
                            onClick={() => {
                            handleRemoveClickToReveal(item.id);
                            setMenuOpenId(null);
                             }}
                            className="block px-4 py-2 text-left w-full hover:bg-[#E0CBD9] hover:bg-purple/20"
                           >
                            Remove
                          </button>
                      </div>
                    )}
                  </div>
                ))}
              </div>
        
            </div>
            <div className="w-full ml-2 bg-gray-200 p-4">
              {openClickId!== null && (
                <RichTextEditor
                  key={openClickId}
                  placeholder={placeholderText}
                   value={clickToRevealData.find(item => item.id === openClickId)?.content || ""}
                    onChange={(content) => handleEditorChange(openClickId, content)}
               />
              )} 
            </div>
            
          </div>
          <Modal
        onOpened={openRenamePopup}
        onClose={() => setOpenRenamePopup(false)}
        title="Rename"
        height="calc(100%-400px)"
      >
        <div className="flex flex-col h-full">
          <div className="grow">
            <Text
              value={renamedClickToRevealName}
              onChange={(e) => setRenamedClickToRevealName(e.target.value)}
              labelTitle="Rename Title"
              autoFocus={true}
            />
          </div>
          <div className="pt-[2.31rem] pb-[0.81rem] space-x-[3.94rem] flex justify-center items-center">
            <button
              onClick={() => setOpenRenamePopup(false)}
              className="p-[0.72rem] border-2 border-tertiary rounded-[1.875rem]"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="33"
                height="33"
                viewBox="0 0 33 33"
                fill="none"
              >
                <path
                  d="M25.5 7.5L7.5 25.5"
                  stroke="#AB0273"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M25.5 25.5L7.5 7.5"
                  stroke="#AB0273"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
            <button
              onClick={handleRename}
              className="p-[0.72rem] border-2 border-tertiary rounded-[1.875rem]"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="33"
                height="33"
                viewBox="0 0 33 33"
                fill="none"
              >
                <path
                  d="M27.5 9.50063L13.5 23.5L6.5 16.5006"
                  stroke="#AB0273"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
          </div>
        </div>
      </Modal>
    </div> 
  );
}

export default ClickToReveal;
