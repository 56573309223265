import React from "react";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";

type Props = {
  file: any;
};

export const FileType = new Map([
  ["image/bmp", "bmp"],
  ["application/msword", "doc"],
  [
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "docx",
  ],
  ["text/htm", "htm"],
  ["text/html", "html"],
  ["image/jpg", "jpg"],
  ["image/jpeg", "jpeg"],
  ["application/pdf", "pdf"],
  ["image/png", "png"],
  ["application/vnd.ms-powerpoint", "ppt"],
  [
    "applicatiapplication/vnd.openxmlformats-officedocument.presentationml.presentation",
    "pptx",
  ],
  ["image/tiff", "tiff"],
  ["text/plain", "txt"],
  ["application/vnd.ms-excel", "xls"],
  ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "xlsx"],
]);

function PdfViewer({ file }: Props) {
  console.log(file);

  return (
    <DocViewer
      documents={[{ uri: file?.url ?? "", fileType: file ? FileType.get(file.type) : "" }]}
      pluginRenderers={DocViewerRenderers}
    />
  );
}

export default PdfViewer;
