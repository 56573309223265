import React, { useEffect, useState } from "react";
import SingleDrag from "./SingleDrag/SingleDrag";
import MultipleDrags from "./MultipleDrags/MultipleDrags";

type Item = {
  id: string;
  content: string;
  droppableId: string;
};

type CompleteDroppableArray = {
  id: string;
  item: Item | null;
  matchId: string | null; // itemId
  text: string;
}[];

type CompleteDroppableObject = {
  items: Item[];
  text: string;
  droppableBoxId: string;
};

type Data = {
  activeDroppable: {
    items: Item[];
  };
  completeDroppable: CompleteDroppableArray | CompleteDroppableObject;
  type: string;
};

type Props = {
  data: Data;
  onChange: (data: Data) => void;
};

function DragAndDrop({ data, onChange }: Props) {
  const [dragType, setDragType] = useState<string>(data.type || "single");

  useEffect(() => {
    console.log("data", data);
  }, [data]);

  function handleDragType(e: React.ChangeEvent<HTMLSelectElement>) {
    const newDragType = e.target.value;
    let resetData: Data = {
      ...data,
      type: newDragType,
      activeDroppable: { items: [] },
      completeDroppable:
        newDragType === "single" ? [] : { items: [], text: "", droppableBoxId: "" },
    };

    // Reset the completeDroppable structure based on the selected drag type
    if (newDragType === "single") {
      resetData.completeDroppable = []; // Reset for single drag
    } else if (newDragType === "multiple") {
      resetData.completeDroppable = { items: [], text: "", droppableBoxId: "" }; // Reset for multiple drag
    }

    setDragType(newDragType);
    onChange(resetData);
  }

  const handleDataChange = (data: Data) => {
    const updatedData: Data = { ...data };
    onChange(updatedData);
  };

  return (
    <div className="flex flex-col mt-5">
      <label className="grid grid-cols-2">
        <span className="col-span-1">Select Tab Type</span>
        <select
          className="col-span-1 w-48 border border-[#d7d7d7] px-2 py-1 hover:border-black rounded-md"
          value={dragType}
          onChange={(e) => handleDragType(e)}
        >
          <option value="single">Single</option>
          <option value="multiple">Multiple</option>
        </select>
      </label>
        {dragType === "single" ? (
          <SingleDrag
            data={data as Data & { completeDroppable: CompleteDroppableArray }}
            onDataChange={handleDataChange}
          />
        ) : (
          <MultipleDrags
            data={data as Data & { completeDroppable: CompleteDroppableObject }}
            onDataChange={handleDataChange}
          />
        )}
    </div>
  );
}

export default DragAndDrop;
