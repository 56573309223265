import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    authTokenExpired: false,
};
export const tokenSliceExpire = createSlice({
  name: "tokenExpired",
  initialState,
  reducers: {
    setTokenExpired: (state, action) => {
      state.authTokenExpired = action.payload;
    },
  },
});

export const { setTokenExpired } = tokenSliceExpire.actions;
export default tokenSliceExpire.reducer;
