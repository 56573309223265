import React, { ReactElement, useEffect } from "react";
import { Close } from "@carbon/icons-react"

type Props = {
  onOpened: boolean;
  onClose: () => void;
  showCrossIcon: boolean;
  children: ReactElement;
  className?: string;
};

export default function PreviewModal({
  onOpened,
  onClose,
  showCrossIcon,
  children,
  className,
}: Props) {
  const keydownHandler = ({ key }: { key: string }) => {
    switch (key) {
      case "Escape":
        onClose();
        break;
      default:
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", keydownHandler);
    return () => document.removeEventListener("keydown", keydownHandler);
  });

  return !onOpened ? null : (
    <div className="absolute top-0 left-0 z-[99999] h-full w-full overflow-hidden bg-black/20">
      <div className={`relative h-full w-full bg-white rounded-lg`}>
        {showCrossIcon && (
          <button className="absolute top-7 right-10 text-black bg-white rounded-full text-lg z-10" onClick={onClose}>
            <Close size={32} />
          </button>
        )}
        <div className="h-full">{children}</div>
      </div>
    </div>
  );
}
