import React, { useEffect, useState } from "react";
import Select, { MultiValue } from "react-select";
import countryList from "react-select-country-list";
import locales from "locale-codes";
import Modal from "src/utils/Modal";
import Drawer from "src/utils/Drawer";
import InputText from "src/utils/TextInput/InputText";
import axios from "axios";
import { useSelector } from "react-redux";
import EditLogo from "../assets/PencilSimple.svg";
import DownArrow from "../assets/down.png";
import UploadButton from "./UploadButton";
import AddUsers from "src/components/DraweData/AddUsers";
import AddAircrafts from "src/components/DraweData/AddAircraft";
import EditUser from "src/components/DraweData/EditUser";
import EditAircraft from "src/components/DraweData/EditAircraft";
import AddLanguages from "src/components/DraweData/AddLanguages";
import EditLanguages from "src/components/DraweData/EditLanguages";
import EditCatagory from "src/components/DraweData/EditCatagory";
import AddCatagory from "src/components/DraweData/AddCatagory";
import { useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setPageState } from "src/features/templatePageState/templatePageStateSlice";

interface LanguageOption {
  id?: string; // Unique ID for each
  value: string; // Language code (ISO 639-1)
  name: string; // Language name
  countryCode: string; // Country code (ISO 3166-1 alpha-2)
}

type Props = {
  className?: string;
  pageState?: any;
  handleSetStateNull?: () => void;
  AddMenu: string;
  CreateNewMenu: string;
  onDataChange?: (data: any) => void;
  allData?: any[];
};

type Data = {
  name: string;
  id: number;
  file: any;
  isVariant?: boolean;
  selectedAircraft?: string | undefined;
};

function DropDown({
  className,
  pageState,
  handleSetStateNull,
  AddMenu,
  CreateNewMenu,
  allData,
  onDataChange,
}: Props) {
  const [openDropdown, setOpenDropdown] = useState(false);
  const [openLangDropdown, setOpenLangDropdown] = useState(false);
  const [openCreateData, setOpenCreateData] = useState("");
  const [openEditData, setOpenEditData] = useState("");
  const [editTitle, setEditTitle] = useState("");
  const [dataName, setDataName] = useState("");
  const [openCreatemodal, setOpenCreateModal] = useState(""); // for language and catagory creation
  const [openEditmodal, setOpenEditModal] = useState(""); // for language and catagory edit

  const [editedData, setEditedData] = useState<any>();

  const [state, setState] = useState<any>(null);

  const [searchUrlParams, setSearchUrlParams] = useSearchParams();

  const [filteredOptions, setFilteredOptions] = useState<Array<any>>([]);

  const [languageOptions, setLanguageOptions] = useState<LanguageOption[]>([]);
  const [selectedLanguages, setSelectedLanguages] = useState<LanguageOption[]>([]);

  useEffect(() => {
    // Fetch language-country pairs dynamically
    const languagesWithCountries = locales.all
      .filter(locale => locale.tag.includes("-")) // Filter out regional languages (e.g., "en-US", "fr-FR")
      .map(locale => {
        const [langCode, countryCode] = locale.tag.split("-"); // Extract language and country
        return {
          value: langCode,
          name: locale.name, // Language name
          countryCode: countryCode || "UN", // Default to UN if no country found
        };
      });

    setLanguageOptions(languagesWithCountries);
  }, []);

  // const options = countryList().getData();
  // console.log(options);

  useEffect(() => {
    console.log(selectedLanguages, allData);
  }, [selectedLanguages, allData]);

  // const handleChange = (selectedOptions: MultiValue<LanguageOption>) => {
  //   console.log(selectedOptions);
  //   setSelectedLanguages(selectedOptions as LanguageOption[]);
  //   if (onDataChange && allData) onDataChange(selectedOptions);
  // };

  const handleChange = (selectedOptions: MultiValue<LanguageOption>) => {
    console.log(selectedOptions);
    const optionsWithId = (selectedOptions as LanguageOption[]).map(option => ({
      ...option,
      id: option.id || new Date().getTime() + Math.random().toString(36).substr(2, 9) // Generate a unique ID if not already present
    }));
    console.log(optionsWithId);
    setSelectedLanguages(optionsWithId);
    if (onDataChange && allData) onDataChange(optionsWithId);
  };

  const dispatch = useDispatch();

  const { accessToken } = useSelector((state: any) => state.accessToken);
  // const { pageState } = useSelector((state: any) => state.templatePageState);

  useEffect(() => {
    // console.log("this is pagrState: ", pageState, state);
    if (pageState) {
      if (pageState.openCreateData) setOpenCreateData(pageState.openCreateData);
      if (pageState.openCreatemodal)
        setOpenCreateModal(pageState.openCreatemodal);
      if (pageState.input) setDataName(pageState.input);
      if (pageState.openEditData) setOpenEditData(pageState.openEditData);
      if (pageState.openEditmodal) setOpenEditModal(pageState.openEditmodal);
      if (pageState.editTitle) setEditTitle(pageState.editTitle);
      if (pageState.editData) setEditedData(pageState.editData);
      setState(pageState);
      // if (handleSetStateNull) handleSetStateNull();
      // dispatch(setPageState(null));
    }
  }, [pageState, openCreateData, openCreatemodal, openEditData, editTitle]);

  // Manish
  const menuRef = React.createRef<HTMLDivElement>();
  const buttonRef = React.createRef<HTMLButtonElement>();

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!menuRef?.current?.contains(event.target as Node)) {
        setOpenDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      // Clean up the event listener when the component unmounts
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuRef]);
  //End

  useEffect(() => {}, [setEditedData]);

  function handleAdd(name: string, isVariant?: boolean, selectedAircraft?: string) {
    const newData: Data = {
      name,
      id: new Date().getTime(),
      file: state ? state.file : null,
      isVariant,
      selectedAircraft,
    };
    // console.log("this is the newData: ", newData);

    if (onDataChange && allData) onDataChange([...allData, newData]);
    setState(null);
    if (handleSetStateNull) handleSetStateNull();
    setDataName("");
    setOpenCreateData("");
    setOpenCreateModal("");
  }

  function handleEditPopup(data: Data) {
    // console.log(data)
    setOpenEditData(data.name);
    setEditedData(data);
  }

  function handleEditModalPopup(data: any) {
    setOpenEditModal(data.name);
    setEditedData(data);
  }

  function handleEdit(editedData: any) {
    console.log(editedData, state)
    if (onDataChange && allData) {
      const newData = allData.map((data) => {
        if (data.id === editedData?.id) {
          return { ...editedData, file: state?.file || editedData?.file };
        }
        return data;
      });

      onDataChange(newData);
      if (handleSetStateNull) handleSetStateNull();
      setOpenEditData("");
      setOpenEditModal("");
    }
  }

  function handleSearch(searchTerm: string) {
    const searchResults = allData?.filter((data) => {
      const optionName = data.name.toLowerCase();
      const searchTermChars = searchTerm.toLowerCase().split("");

      let searchIndex = 0;
      for (const char of optionName) {
        if (char === searchTermChars[searchIndex]) {
          searchIndex++;
        }

        if (searchIndex === searchTermChars.length) {
          return true; // All characters in the search term found in order
        }
      }

      return false;
    });

    // console.log(searchResults)

    if (searchResults) setFilteredOptions(searchResults);
  }

  return (
    <div>
      <div>
        <div ref={menuRef} className="relative flex flex-col">
          <button
            onClick={() => {
              if(AddMenu !== "Add Language") handleSearch("");
              setOpenDropdown(!openDropdown);
            }}
            className="flex w-[11.875rem] bg-[#AB0273] px-[0.63rem] py-[0.3125rem] rounded-[1.25rem] border border-[#AB0273] items-center justify-between"
          >
            <span className="text-[1rem] font-[600]  text-[#FFFFFF] leading-normal">
              {AddMenu}
            </span>
              {/* <img src={DownArrow} alt="logo" /> */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
              >
                <path
                  d="M26 12L16 22L6 12"
                  stroke="white"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
          </button>
          {openDropdown && (
            AddMenu !== "Add Language" ? (
              <div className="absolute top-[2.87rem] -left-[2.06rem] z-10 w-[16rem] border-2 border-[#000000]/30 bg-[#F5F5F5]  rounded-[1.8rem] px-[1.12rem] py-[0.7rem]">
                <button
                  className="w-full flex col-span-3 text-[1rem] text-[#242424] font-[50 font-poppins]  leading-normal space-x-[0.63rem] pt-[0.5rem] pb-[0.94rem] items-center justify-center"
                  onClick={() => {
                    if (AddMenu === "Add User" || AddMenu === "Add Aircraft") {
                      setOpenCreateData(CreateNewMenu);
                      setState(null);
                      setDataName("");
                    }
                    if (AddMenu === "Add Catagory")
                      setOpenCreateModal(CreateNewMenu);
                  }}
                >
                  <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M2.5 8H13.5"
                      stroke="#242424"
                      stroke-width="3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M8 2.5V13.5"
                      stroke="#242424"
                      stroke-width="3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  </span>
                  <span>{CreateNewMenu}</span>
                </button>

                <input
                  type="text"
                  placeholder="Search..."
                  className="flex w-full bg-[#F5F5F5] text-[#242424] text-[0.875rem] font-[500]  px-[0.58rem] py-[0.94rem] text-left  items-center border-t-[0.0625rem] border-[#2C2C2C] outline-none"
                  // value={searchTerm}
                  onChange={(e) => handleSearch(e.target.value)}
        font-poppins       />

                {filteredOptions && (
                  <div className=" max-h-[145px] overflow-y-scroll no-scrollbar">
                    {filteredOptions.map((data) => (
                      <>
                        <div
                          className={`${
                            data.name !== "English" && ""
                          } w-full flex text-[#242424] text-[1rem] font-[500]  px-[0.58rem] py-[0.94rem] text-left border-t-[0.0625rem] border-[#2C2C2C]  items-center`}
                        >
                          <div className="overflow-hidden w-[185px]">
                            {data.name}
                          </div>
                          {data.name !== "English" && (
                            <div>
                              <button
                                onClick={() => {
                                  if (
                                    AddMenu === "Add User" ||
                                    AddMenu === "Add Aircraft"
                                  ) {
                                    handleEditPopup(data);
                                    setEditedData(data);
                                    setState(null);
                                  }
                                  if (
                                    AddMenu === "Add Language" ||
                                    AddMenu === "Add Catagory"
                                  )
                                    handleEditModalPopup(data);
                                  setEditTitle(`Edit ${AddMenu.split(" ")[1]}`);
                                }}
                                className="w-5"
                              >
                                <img src={EditLogo} alt="logo" />
                              </button>
                            </div>
                          )}
                        </div>
                      </>
                    ))}
                  </div>
                )}
              </div>
            ) : (
              <div className="absolute top-[2.87rem] -left-[2.06rem] z-10 w-[16rem] border-2 border-[#000000]/30 bg-[#F5F5F5]  rounded-[1.8rem] px-[1.12rem] py-[0.7rem]">
                <button
                  className="w-full flex col-span-3 text-[1rem] text-[#242424] font-[50 font-poppins]  leading-normal space-x-[0.63rem] pt-[0.5rem] pb-[0.94rem] items-center justify-center"
                  onClick={() => {
                    if (AddMenu === "Add Language")
                      setOpenLangDropdown(true);
                  }}
                >
                  <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M2.5 8H13.5"
                      stroke="#242424"
                      stroke-width="3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M8 2.5V13.5"
                      stroke="#242424"
                      stroke-width="3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  </span>
                  <span>{CreateNewMenu}</span>
                </button>

                {openLangDropdown && (
                    <Select
                    isMulti
                    options={languageOptions}
                    onChange={handleChange}
                    value={allData}
                    placeholder="Select languages..."
                    menuPortalTarget={document.body}  // Ensures it renders above the modal
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    }}
                    formatOptionLabel={(e: LanguageOption) => (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <img
                          src={`https://flagcdn.com/w40/${e.countryCode?.toLowerCase()}.png`}
                          alt={e.name}
                          style={{ width: 20, height: 15, marginRight: 10 }}
                          onError={(e) => (e.currentTarget.style.display = "none")}
                        />
                        {e.name}
                      </div>
                    )}
                  />
                )}

                {allData && (
                  <div className=" max-h-[145px] overflow-y-scroll no-scrollbar">
                    {allData.map((data) => (
                      <>
                        <div
                          className={`${
                            data.name !== "English" && ""
                          } w-full flex text-[#242424] text-[1rem] font-[500]  px-[0.58rem] py-[0.94rem] text-left border-t-[0.0625rem] border-[#2C2C2C]  items-center`}
                        >
                          <div className="overflow-hidden w-[185px]">
                            {data.name}
                          </div>
                          {data.name !== "English" && (
                            <div>
                              <button
                                onClick={() => {
                                  if (
                                    AddMenu === "Add Language"
                                  )
                                    handleEditModalPopup(data);
                                  setEditTitle(`Edit ${AddMenu.split(" ")[1]}`);
                                }}
                                className="w-5"
                              >
                                <img src={EditLogo} alt="logo" />
                              </button>
                            </div>
                          )}
                        </div>
                      </>
                    ))}
                  </div>
                )}
              </div>
            )
          )}
        </div>
      </div>

      {/* to create*/}
      {openCreateData === "Create new user" &&
        CreateNewMenu === "Create new user" && (
          <Drawer
            onOpened={openCreateData.length > 0}
            onClose={() => setOpenCreateData("")}
            title={AddMenu}
          >
            <>
              <AddUsers
                state={state ? state : null}
                openCreateData={openCreateData}
                input={dataName}
                onChangeInput={(e) => setDataName(e.target.value)}
                onHandleAdd={() => {
                  handleAdd(dataName);
                }}
              />
            </>
          </Drawer>
        )}
      {openCreateData === "Create new aircraft" &&
        CreateNewMenu === "Create new aircraft" && (
          <Drawer
            onOpened={openCreateData.length > 0}
            onClose={() => setOpenCreateData("")}
            title={AddMenu}
          >
            <>
              <AddAircrafts
                state={state ? state : null}
                openCreateData={openCreateData}
                input={dataName}
                onChangeInput={(e) => setDataName(e.target.value)}
                onHandleAdd={(isVariant, selectedAircraft) => {
                  handleAdd(dataName, isVariant, selectedAircraft);
                  // console.log("this is the dataName: ", dataName);
                }}
                aircrafts={allData}
              />
            </>
          </Drawer>
        )}

      {/* to create language and catagory */}
      <Modal
        onOpened={openCreatemodal.length > 0}
        onClose={() => setOpenCreateModal("")}
        title={AddMenu}
        height="calc(100%-400px)"
        width="calc(100%-600px)"
      >
        <>
          {openCreatemodal === "Create new language" && (
            <></>
            // <AddLanguages
            //   input={dataName}
            //   onChangeInput={(e) => setDataName(e.target.value)}
            //   onHandleAdd={() => {
            //     handleAdd(dataName);
            //     // console.log("this is the dataName: ", dataName);
            //   }}
            // />
            // <Select
            //   isMulti
            //   options={languageOptions}
            //   onChange={handleChange}
            //   value={selectedLanguages}
            //   placeholder="Select languages..."
            //   menuPortalTarget={document.body}  // Ensures it renders above the modal
            //   styles={{
            //     menuPortal: (base) => ({ ...base, zIndex: 9999 }),
            //   }}
            //   formatOptionLabel={(e: LanguageOption) => (
            //     <div style={{ display: "flex", alignItems: "center" }}>
            //       <img
            //         src={`https://flagcdn.com/w40/${e.countryCode.toLowerCase()}.png`}
            //         alt={e.name}
            //         style={{ width: 20, height: 15, marginRight: 10 }}
            //         onError={(e) => (e.currentTarget.style.display = "none")}
            //       />
            //       {e.name}
            //     </div>
            //   )}
            // />

          )}

          {openCreatemodal === "Create new catagory" && (
            <AddCatagory
              state={state ? state : null}
              openCreatemodal={openCreatemodal}
              input={dataName}
              onChangeInput={(e) => setDataName(e.target.value)}
              onHandleAdd={() => {
                handleAdd(dataName);
                // console.log("this is the dataName: ", dataName);
              }}
            />
          )}
        </>
      </Modal>

      {/* to edit*/}

      {editTitle === "Edit User" && CreateNewMenu === "Create new user" && (
        <Drawer
          onOpened={openEditData.length > 0}
          onClose={() => setOpenEditData("")}
          title={editTitle}
        >
          <>
            <EditUser
              state={state ? state : null}
              openEditData={openEditData}
              editTitle={editTitle}
              editData={editedData}
              onSetEditData={(e) =>
                setEditedData((prevState: any) => {
                  const newName = e.target.value;
                  return { ...prevState, name: newName };
                })
              }
              onHandleEdit={() => handleEdit(editedData)}
              onHandleDelete={(id: any) => {
                if (onDataChange && allData) {
                  const newUser = allData.filter((data) => data.id !== id);

                  onDataChange(newUser);

                  setOpenEditData("");
                }
              }}
            />
          </>
        </Drawer>
      )}
      {editTitle === "Edit Aircraft" &&
        CreateNewMenu === "Create new aircraft" && (
          <Drawer
            onOpened={openEditData.length > 0}
            onClose={() => {setOpenEditData(""); if(handleSetStateNull) handleSetStateNull();}}
            title={editTitle}
          >
            <>
              <EditAircraft
                state={state ? state : null}
                openEditData={openEditData}
                editTitle={editTitle}
                editData={editedData}
                onSetEditData={(e) =>
                  setEditedData((prevState: any) => {
                    const newName = e.target.value;
                    return { ...prevState, name: newName };
                  })
                }
                onHandleEdit={() => handleEdit(editedData)}
                onHandleDelete={(id: any) => {
                  if (onDataChange && allData) {
                    const newUser = allData.filter((data) => data.id !== id);

                    onDataChange(newUser);

                    setOpenEditData("");
                  }
                }}
              />
            </>
          </Drawer>
        )}

      {/* to edit language and catagory */}
      <Modal
        onOpened={openEditmodal.length > 0}
        onClose={() => setOpenEditModal("")}
        title={editTitle}
        height="calc(100%-400px)"
        width="calc(100%)"
      >
        <>
          {editTitle === "Edit Language" && (
            <EditLanguages
              editData={editedData}
              onSetEditData={(e) =>
                setEditedData((prevState: any) => {
                  const newName = e.target.value;
                  return { ...prevState, name: newName };
                })
              }
              onHandleEdit={() => handleEdit(editedData)}
              onHandleDelete={(id: any) => {
                if (onDataChange && allData) {
                  const newUser = allData.filter((data) => data.id !== id);

                  onDataChange(newUser);

                  setOpenEditModal("");
                }
              }}
            />
          )}
          {editTitle === "Edit Catagory" && (
            <EditCatagory
              state={state ? state : null}
              openEditmodal={openEditmodal}
              editTitle={editTitle}
              editData={editedData}
              onSetEditData={(e) =>
                setEditedData((prevState: any) => {
                  const newName = e.target.value;
                  return { ...prevState, name: newName };
                })
              }
              onHandleEdit={() => handleEdit(editedData)}
              onHandleDelete={(id: any) => {
                if (onDataChange && allData) {
                  const newUser = allData.filter((data) => data.id !== id);

                  onDataChange(newUser);

                  setOpenEditModal("");
                }
              }}
            />
          )}
        </>
      </Modal>
    </div>
  );
}

export default DropDown;
