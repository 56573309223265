import React, { Children, cloneElement, ReactElement, useEffect } from "react";

type Props = {
  onOpened: boolean;
  onClose: () => void;
  title: string;
  children: ReactElement[];
  height?: string;
  // width?: string
  passUnitIDtoDelete?: string;
  // className?: string
};

export default function LocateSectionDrawer({
  onOpened,
  onClose,
  title,
  children,
  height,
}: Props) {
  const arrayChildren = Children.toArray(children);

  const keydownHandler = ({ key }: { key: string }) => {
    switch (key) {
      case "Escape":
        onClose();
        break;
      default:
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", keydownHandler);
    return () => document.removeEventListener("keydown", keydownHandler);
  });

  return !onOpened ? null : (
    <div className="flex bg-black/[50] absolute top-0 left-0 w-full h-full">
      <div className="grow">{arrayChildren[0]}</div>
      <div className={`drawer-dialogue`}>
        <div className="modal-Dtitle">
          <h1>{title}</h1>
          <button onClick={onClose}>
          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
              <path d="M25 7L7 25" stroke="black" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M25 25L7 7" stroke="black" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          </button>
        </div>
        <div className="modal-body">{arrayChildren[1]}</div>
      </div>
    </div>
  );
}
