import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import SplashScreen from "./SplashScreen";
import ProgramLayout from "../ProgramLayout";
import { useSelector } from "react-redux";
import AcknowledgementScreen from "./AcknowledgementScreen";
import MultipleUserScreen from "./MultipleUserScreen";
import QRHScreen from "./QRHScreen";
import PreFlightScreen from "./PreFlightScreen";
import FlightOpsTrainingScreen from "./FlightOpsTrainingScreen";
import ACtypeSystemDiff from "./ACtypeSystemDiff";
import PAannouncementScreen from "./PAannouncementScreen";
import VideosScreen from "./VideosScreen";
import EmergencyEquipmentScreen from "./EmergencyEquipmentScreen";
import { AppContext, InitialState, State } from "../../context/State";
import LocationDiagram from "./LocationDiagram";
import ThreeDModalScreen from "./3DModalScreen";
import Auth from "../Auth";

const Screens = new Map([
  ["splash", <SplashScreen />],
  ["acknowledgement", <AcknowledgementScreen />],
  ["multiple user", <MultipleUserScreen />],
  ["qrh", <QRHScreen />],
  ["pre-flight", <PreFlightScreen />],
  ["flight-ops", <FlightOpsTrainingScreen />],
  ["announcement", <PAannouncementScreen />],
  ["modal", <ThreeDModalScreen />],
  ["emergency", <EmergencyEquipmentScreen />],
  ["videos", <VideosScreen />],
  ["location diagram", <LocationDiagram />],
  ["system diff", <ACtypeSystemDiff />],
]);

type TemplateType = {
  _id: string;
  programId: string;
  authorId: string;
  title: string;
  type: string;
  modifiedOn: number;
  createdOn: number;
  content: any;
};

function Templates() {
  const [state, setState] = useState<State>(InitialState); // creating context on the template page

  const [searchParams] = useSearchParams();

  const { programTemplates }: { programTemplates: TemplateType[] } =
    useSelector((state: any) => state.programTemplates);

  // console.log("template type: ", searchParams.get("screen"));
  const [screenType, setScreenType] = useState(searchParams.get("screen"));

  // creating function for setting the context state

  async function setAircraftsUsers(aircraftsusers: any){
    setState({
      aircraftsusers: aircraftsusers,
    })
  }
  return (
    <Auth>
    <AppContext.Provider value={{state, setAircraftsUsers}}>
      <ProgramLayout type="">
        <div className="col-span-12 py-[0.81rem] px-[0.63rem] rounded-[1.25rem] text-[#242424] bg-[#FFFFFF] h-full overflow-auto">
          <section className="flex flex-col space-y-10 overflow-auto w-full h-full no-scrollbar">
            {screenType &&
            programTemplates.find(
              (template) => template.type === screenType
            ) ? (
              Screens.get(screenType)
            ) : (
              <div className="px-4 py-2">
                <div>No Templates Found.</div>
              </div>
            )}
          </section>
        </div>
      </ProgramLayout>
    </AppContext.Provider>
    </Auth>
  );
}

export default Templates;
