import React, {useState} from "react";
import ProgramLayout from "../ProgramLayout";
import Auth from "../Auth";

type Props = {};

function Program({}: Props) {

  return (
    <Auth>
      <ProgramLayout type="Sepm">
        <div className="col-span-12 py-[0.81rem] px-[0.63rem rounded-[1.25rem] text-[#242424] bg-[#FFFFFF] h-full max-h-[54rem]">
          <section className="flex flex-col space-y-10 overflow-auto w-full h-full no-scrollbar">
            {/* <div>
              <TemplateHeading
                title="Splash Screen"
              />
              <div>
                <InputText 
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  placeholder="Enter Title"
                  labelTitle="Title"
                />
              </div>
              <div>
                <InputText 
                  value={buttonText}
                  onChange={(e) => setButtonText(e.target.value)}
                  placeholder="Enter Button Text"
                  labelTitle="Button"
                />
              </div>
              <div>
                <InputText 
                  value={copyrightText}
                  onChange={(e) => setCopyrightText(e.target.value)}
                  placeholder="Enter Copyright Text"
                  labelTitle="Copyright Text"
                />
              </div>
            </div> */}

            <div className="h-[calc(100%-100px)] w-full flex flex-col space-y-2 items-center justify-center">
              <span className="text-2xl">You did not add any template here.</span>
              <div className="flex items-end space-x-2"><span className="text-4xl">+</span><span className="text-2xl">Add Templates</span></div>
            </div>
          </section>
        </div>
      </ProgramLayout>
    </Auth>
  );
}

export default Program;
