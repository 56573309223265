import { createSlice } from "@reduxjs/toolkit";

type InitialState = {
    selectedTemplate: {
        id: number,
        title: string,
        type: string,
        description: string,
        data: any[],
        openTemplate: boolean,
    } | null,
    selectedTool: {
        id: number,
        title: string,
        type: string,
        description: string,
        data: any[]
    } | null,
}

const initialState: InitialState = {
    selectedTemplate: null,
    selectedTool: null,
};

export const courseTemplateSlice = createSlice({
    name: "courseTemplate",
    initialState,
    reducers: {
        setSelectedTemplate: (state, action) => {
            state.selectedTemplate = action.payload;
        },
        removeSelectedTemplate: (state) => {
            state.selectedTemplate = null;
        },
        setSelectedTool: (state, action) => {
            state.selectedTool = action.payload;
        },
        removeSelectedTool: (state) => {
            state.selectedTool = null;
        },
    },
})

export const { setSelectedTemplate, removeSelectedTemplate, setSelectedTool, removeSelectedTool } = courseTemplateSlice.actions;
export default courseTemplateSlice.reducer;