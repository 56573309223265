import React, { ReactElement, useEffect } from "react";

type Props = {
  onOpened: boolean;
  onClose: () => void;
  title: string;
  children: ReactElement;
  height?: string;
  width?: string;
  passUnitIDtoDelete?: string;
  className?: string;
};

export default function Modal({
  onOpened,
  onClose,
  title,
  children,
  height,
  width,
  passUnitIDtoDelete,
  className,
}: Props) {
  const keydownHandler = ({ key }: { key: string }) => {
    switch (key) {
      case "Escape":
        onClose();
        break;
      default:
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", keydownHandler);
    return () => document.removeEventListener("keydown", keydownHandler);
  });

  return !onOpened ? null : (
    <div className="modal">
      <div className={`modal-dialogue ${height} ${width}`}>
        <div className="modal-title">
          <h1 className="text-[#FFFFFF] text-[1.25rem] font-[600]">{title}</h1>
          <button className='pr-[0.31rem]' onClick={onClose}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
              >
                <path
                  d="M21.875 6.125L6.125 21.875"
                  stroke="white"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M21.875 21.875L6.125 6.125"
                  stroke="white"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
          </button>
        </div>
        <div className="modal-body">{children}</div>
      </div>
    </div>
  );
}
