import {
  CopyObjectCommand,
  DeleteObjectCommand,
  DeleteObjectsCommand,
  GetObjectCommand,
  ListObjectsV2Command,
  PutObjectCommand,
  DeleteObjectCommandInput,
  S3Client,
} from "@aws-sdk/client-s3";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";

const s3Client = new S3Client({
  region: "ap-southeast-1",
  credentials: {
    accessKeyId: "AKIAQ7EEYMTV3NHXXZAP",
    secretAccessKey: "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",
  },
});

//Created By Manish Agarwal
export async function deleteFolder(key: string) {
  async function recursiveDelete(token?: any) {
    // List objects in the folder
    const listObjectsCommand = new ListObjectsV2Command({
      Bucket: "sepmupload",
      Prefix: key,
      ContinuationToken: token
    });

    const objects = await s3Client.send(listObjectsCommand);
    // console.log("its working...", objects.Contents)

    if (objects?.Contents && objects.Contents.length > 0) {
      // Create an array of object keys
      const objectKeys = objects.Contents.map((obj) => ({ Key: obj.Key }));

      // Delete objects in the folder
      const deleteObjectsCommand = new DeleteObjectsCommand({
        Bucket: "sepmupload",
        Delete: { Objects: objectKeys },
      });

      await s3Client.send(deleteObjectsCommand);
    }

    // repeat if more files to delete
    if (objects.NextContinuationToken) {
      recursiveDelete(objects.NextContinuationToken);
    }
    // return total deleted count when finished
    // return `${count} files deleted.`;
  }

  return recursiveDelete();
  // Optionally, you can delete the folder itself
  // Note: The folder must be empty before deleting it
  // const deleteFolderCommand = new DeleteObjectCommand({
  //   Bucket: "sepmupload",
  //   Key: key,
  // });

  // await s3Client.send(deleteFolderCommand);
};

async function deleteFolderV2(key: string) {
  console.log("inside the deleteFolderV2", key)
  const listObjectsCommand = new ListObjectsV2Command({
    Bucket: "sepmupload",
    Prefix: key,
  });

  const { Contents: objects } = await s3Client.send(listObjectsCommand);

  if (objects) {
    for (const object of objects) {
      const deleteObjectCommand = new DeleteObjectCommand({
        Bucket: "sepmupload",
        Key: object.Key,
      });

      await s3Client.send(deleteObjectCommand);
    }
  }
}

export async function renameFolder(oldFolderPath: string, newFolderPath: string) {
  const objects = await listObjects(oldFolderPath);

  console.log("inside the renameFolder", oldFolderPath, newFolderPath, objects)

  if (objects) {
    for (const object of objects) {
      if (object.Key) {
        const newKey = object.Key.replace(oldFolderPath, newFolderPath);
        await copyObject(object.Key, newKey);
        await deleteObject(object.Key);
      }
      else {
        console.log("No objects found in the old folder.");
      }
    }
  }
}

export async function moveFolder(oldFolderPath: string, newFolderPath: string) {
  const objects = await listObjects(oldFolderPath);

  console.log("inside the renameFolder", oldFolderPath, newFolderPath, objects)

  if (objects) {
    const promises: any = []
    for (const object of objects) {
      if (object.Key) {
        // const oldFolderName = oldFolderPath.split("/").slice(-2, -1)[0]; // get the old folder name
        const newKey = object.Key.replace(oldFolderPath, `${newFolderPath}`);
        const copyres = copyObject(object.Key, newKey);
        const delres =  deleteObject(object.Key);

        promises.push(copyres, delres)
      }
      else {
        console.log("No objects found in the old folder.");
      }
    }
    await Promise.all(promises)
  }
}

export async function copyFolder(oldFolderPath: string, newFolderPath: string) {

  console.log("inside the copyFolder", oldFolderPath, newFolderPath)
  const objects = await listObjects(oldFolderPath);

  if (objects) {
    for (const object of objects) {
      if (object.Key) {
        const newKey = object.Key.replace(oldFolderPath, newFolderPath);
        await copyObject(object.Key, newKey);
      }
      else {
        console.log("No objects found in the old folder.");
      }
    }
  }
}

async function listObjects(folderPath: string) {
  const listObjectsCommand = new ListObjectsV2Command({
    Bucket: "sepmupload",
    Prefix: folderPath,
  });

  const { Contents: objects } = await s3Client.send(listObjectsCommand);
  return objects;
}

async function copyObject(oldKey: string, newKey: string) {
  const copyObjectCommand = new CopyObjectCommand({
    Bucket: "sepmupload",
    CopySource: `sepmupload/${oldKey}`,
    Key: newKey,
  });

  await s3Client.send(copyObjectCommand);
}

async function deleteObject(key: string) {
  const deleteObjectCommand = new DeleteObjectCommand({
    Bucket: "sepmupload",
    Key: key,
  });

  await s3Client.send(deleteObjectCommand);
}

//Created By Manish Agarwal
// export async function renameFolder(bucketName: string, oldFolderPath: string, newFolderPath: string) {
//   // Ensure that the folder paths end with a trailing slash

//   console.log(oldFolderPath, newFolderPath)
//   oldFolderPath = oldFolderPath.endsWith('/') ? oldFolderPath : oldFolderPath + '/';
//   newFolderPath = newFolderPath.endsWith('/') ? newFolderPath : newFolderPath + '/';

//   // List all objects in the old folder
//   const listObjectsParams = {
//     Bucket: bucketName,
//     Prefix: oldFolderPath,
//   };

//   try {
//     const listObjectsOutput = await s3Client.send(new ListObjectsV2Command(listObjectsParams));

//     if (!listObjectsOutput?.Contents) {
//       console.log('No objects found in the old folder.');
//       return;
//     }

//     // Copy each object to the new folder
//     for (const object of listObjectsOutput.Contents) {

//       console.log("this is the object: ", object)
//       const copyObjectParams = {
//         Bucket: bucketName,
//         CopySource: `${bucketName}/${object.Key ?? ''}`,
//         Key: object.Key?.replace(oldFolderPath, newFolderPath) ?? '',
//       };

//       // console.log(`${bucketName}/${object.Key ?? ''}`+"PATH"+object.Key?.replace(oldFolderPath, newFolderPath) ?? '')
//       await s3Client.send(new CopyObjectCommand(copyObjectParams));
//     }

//     // Delete all objects in the old folder
//     const deleteObjectsParams = {
//       Bucket: bucketName,
//       Delete: {
//         Objects: listObjectsOutput.Contents.map((object) => ({ Key: object.Key ?? '' })),
//       },
//     };

//     await s3Client.send(new DeleteObjectsCommand(deleteObjectsParams));

//     console.log('Folder renamed successfully.');
//   } catch (error) {
//     console.error('Error:', error);
//   }
// }


export async function getObectURL(key: string) {
  const command = new GetObjectCommand({
    Bucket: "sepmupload",
    Key: key,
  });

  const url = getSignedUrl(s3Client, command);
  return url;
}
export async function putObectURL(key: string, contentType: string) {
  console.log("inside the putObjectURL ", contentType);

  const command = new PutObjectCommand({
    Bucket: "sepmupload",
    Key: key,
    ContentType: contentType,
    // ContentDisposition: "inline"
    // ACL: 'public-read'
  });

  const url = getSignedUrl(s3Client, command);
  return url;
}
export async function DeleteObject(key: string) {
  const command = new DeleteObjectCommand({
    Bucket: "sepmupload",
    Key: key,
  });

  const result = await s3Client.send(command);
  return result;
}

export async function DeleteManyObject(itemsWithKey: any[]) {
  const keys: { Key: string }[] = itemsWithKey.map((item) => { return { Key: item.Key } })
  console.log("this is the keys array: ", keys, itemsWithKey)
  const command = new DeleteObjectsCommand({
    Bucket: "sepmupload",
    Delete: {
      Objects: keys,
    },
  });

  const result = await s3Client.send(command);
  return result;
}

export async function ListObjects(key: string) {
  const command = new ListObjectsV2Command({
    Bucket: "sepmupload",
    Prefix: key,
    Delimiter: "/",
  });

  const result = await s3Client.send(command);
  return result;
}

export async function CopyObjects(
  sourceKey: { Key: string },
  destinationKey: string
) {
  const command = new CopyObjectCommand({
    Bucket: "sepmupload",
    CopySource: encodeURI(`/sepmupload/${sourceKey.Key}`),
    Key: destinationKey,
  });

  console.log(command, sourceKey, destinationKey);

  const result = await s3Client.send(command);
  return result;
}

export async function Rename(
  sourceKey: { Key: string },
  destinationKey: string
) {

  console.log("aws", sourceKey, destinationKey)

  const response = await CopyObjects(sourceKey, destinationKey)

  if (response) {
    const result = await DeleteObject(sourceKey.Key)

    return result
  }
}

export async function DeleteFilesAndFolders(itemsWithKey: any) {
  console.log("itemsWithKey: ", itemsWithKey)
  const promises: any = []
  itemsWithKey.map(async (item: any) => {
    if (item.type) {
      const res = DeleteObject(item.Key)
      promises.push(res)
    }
    else {
      const res = deleteFolderV2(item.Key)
      promises.push(res)
    }
  })
  await Promise.all(promises)
}