import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  saveChanges: false,
};
export const saveChangesSlice = createSlice({
  name: "saveChanges",
  initialState,
  reducers: {
    setSaveChanges: (state, action) => {
      state.saveChanges = action.payload;
    },
  },
});

export const { setSaveChanges } = saveChangesSlice.actions;
export default saveChangesSlice.reducer;
