import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import RichTextEditor from "src/utils/RichTextEditor";
import { Editor } from "draft-js";

type Props = {};

export function Tester({}: Props) {
  
  const [innerHTML, setInnerHTML] = useState<string>();
//   const [value] = useState();

  const value = "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."

  return (
    <>
      <div>For testing purposes only</div>
      <div>
        {/* <RichTextEditor value={value} onChange={(html, length) => console.log(html, length)} /> */}
      </div>
    </>
  );
}
