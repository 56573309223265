import React, { useEffect, useState } from "react";
import InputText from "src/utils/TextInput/InputText";
import TemplateHeading from "src/utils/TextInput/TemplateHeading";
import DropDown from "src/utils/DropDown";
import debounce from "lodash/debounce";
import axios from "axios";
import { useDispatch } from "react-redux";
import { setSaveChanges } from "src/features/saveChanges/saveChangesSlice";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import UploadButton from "src/utils/UploadButton";
import { setSelectFile } from "src/features/fileFolder/fileFolderSlice";
import UploadDropdowns from "../UploadDropdowns";
import { setPageState } from "src/features/templatePageState/templatePageStateSlice";

type Props = {};

function MultipleUserScreen({}: Props) {
  // const [title, setTitle] = useState<string>("");
  const [userTitle, setUserTitle] = useState<string>("");
  const [aircraftTitle, setAircraftTitle] = useState<string>("");
  const [users, setUsers] = useState<any>([]);
  const [aircrafts, setAircrafts] = useState<any>([]);

  const [content, setContent] = useState<any>();
  const [saving, setSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // const [File, setFile] = useState<any | null>(null);
  const [userbgFile, setuserbgFile] = useState<any | null>(null);
  const [aircraftbgFile, setaircraftbgFile] = useState<any | null>(null);

  const { templateId } = useParams();

  const { accessToken } = useSelector((state: any) => state.accessToken);
  const [state, setState] = useState<any>(null);
  const { pageState } = useSelector((state: any) => state.templatePageState);

  const dispatch = useDispatch();

  // const debouncedAutoSave = debounce((newContent: any) => {
  //   setSaving(true);
  //   dispatch(setSaveChanges(true));
  //   // Send a POST request to your server to save the content
  //   axios
  //     .post(
  //       `http://localhost:8080/api/v1/template/save/${templateId}`,
  //       { content: newContent },
  //       {
  //         headers: { Authorization: `Bearer ${accessToken}` },
  //       }
  //     )
  //     .then(() => {
  //       setSaving(false);
  //       dispatch(setSaveChanges(false));
  //     })
  //     .catch((error) => {
  //       console.error("Auto-save failed:", error);
  //       setSaving(false);
  //       dispatch(setSaveChanges(false));
  //     });
  // }, 1000);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      dispatch(setSaveChanges(true));
      setIsLoading(true);
      axios
        .post(
          `${process.env.REACT_APP_SERVER_BASE_URL}/api/v1/template/save/${templateId}`,
          { content },
          {
            headers: { Authorization: `Bearer ${accessToken}` },
          }
        )
        .then((response) => {
          dispatch(setSelectFile({ file: null }));
          dispatch(setSaveChanges(false));
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Auto-save failed:", error);
          setSaving(false);
          dispatch(setSaveChanges(false));
          setIsLoading(false);
        });
    }, 5000);
    return () => clearTimeout(timeoutId);
  }, [content]);

  useEffect(() => {
    handleContentChange();
  }, [userTitle, aircraftTitle, users, aircrafts, userbgFile, aircraftbgFile]);

  useEffect(() => {
    console.log(pageState, state);
    if (pageState) {
      if (pageState.uploadButtonFor === "userbgImageFile" && pageState.file) {
        setuserbgFile(pageState.file);
        setaircraftbgFile(pageState.aircraftbgFile);
      }
      if (
        pageState.uploadButtonFor === "aircraftbgImageFile" &&
        pageState.file
      ) {
        setaircraftbgFile(pageState.file);
        setuserbgFile(pageState.userbgFile);
      }
      if (!(pageState.file && pageState.uploadButtonFor)) {
        setuserbgFile(pageState.userbgFile);
        setaircraftbgFile(pageState.aircraftbgFile);
      }
      if (pageState.userTitle) setUserTitle(pageState.userTitle);
      if (pageState.aircraftTitle) setAircraftTitle(pageState.aircraftTitle);
      if (pageState.users) setUsers(pageState.users);
      if (pageState.aircrafts) setAircrafts(pageState.aircrafts);

      setState(pageState);
      dispatch(setPageState(null));
    } else {
      getContent();
    }
  }, []);

  async function getContent() {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_BASE_URL}/api/v1/template/content/${templateId}`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );

      if (response && response.data) {
        console.log("template details:", response.data.data);
        setUserTitle(response.data.data.content.userTitle);
        setAircraftTitle(response.data.data.content.aircraftTitle);
        setUsers(response.data.data.content.users);
        setAircrafts(response.data.data.content.aircrafts);
        // setFile(response.data.data.content.file);
        setuserbgFile(response.data.data.content.bgfile);
        setaircraftbgFile(response.data.data.content.aircraftbgFile);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handleContentChange = () => {
    const newContent = {
      userTitle,
      aircraftTitle,
      users: users || [],
      aircrafts: aircrafts || [],
      bgfile: userbgFile,
      aircraftbgFile,
    };
    setContent(newContent);
    // Call the debounced auto-save function with the new content
    // debouncedAutoSave(newContent);
  };

  return (
    <div>
      <TemplateHeading title="Multiple User Menu" />
      <div>
        <InputText
          value={userTitle}
          onChange={(e) => {
            setUserTitle((prevState: string) => {
              if (prevState !== e.target.value) return e.target.value;
              return prevState;
            });
          }}
          placeholder="Enter Title"
          labelTitle="User Title"
        />
      </div>

      <div className="grid grid-cols-4 mt-[1.25rem] mx-[0.49rem] items-center">
        <div className="col-span-1 font-[600]  text-[1rem] text-[#242424] leading-normal">
          User Background Image
        </div>
        <div className="col-span-3 flex space-x-[0.75rem] items-center">
          <UploadButton
            name="userbgImageFile"
            // handleClick={(name) => {console.log(name); setUploadButtonFor(name)}}
            type="image"
            pageState={{
              // ...state,
              uploadButtonFor: "userbgImageFile",
              userTitle,
              aircraftTitle,
              users,
              aircrafts,
              userbgFile,
              aircraftbgFile,
            }}
            handleUploadFromDevice={(file) => {
              setuserbgFile(file);
              // console.log(file);
            }}
          />
          <UploadDropdowns 
          name="userbgImageFile"
          type="image"
          pageState={{
            // ...state,
            uploadButtonFor: "userbgImageFile",
              userTitle,
              aircraftTitle,
              users,
              aircrafts,
              userbgFile,
              aircraftbgFile,
          }}
          handleCropFile={(cropFile) => setuserbgFile(cropFile)}
          File={userbgFile} />
        </div>
      </div>

      <div className="grid grid-cols-4 mt-[1.25rem] mx-[0.49rem] items-center">
        <div className="col-span-1 font-[600]  text-[1rem] text-[#242424] leading-normal">User Menu</div>
        <div className="col-span-3">
          <DropDown
            pageState={
              state
                ? state.uploadButtonFor
                  ? {
                      ...state,
                      userbgFile,
                      aircraftbgFile,
                      outerFile: state.file,
                      file: null,
                    }
                  : state
                : {
                    userTitle,
                    aircraftTitle,
                    users,
                    aircrafts,
                    userbgFile,
                    aircraftbgFile,
                  }
            }
            handleSetStateNull={() => setState(null)}
            AddMenu="Add User"
            CreateNewMenu="Create new user"
            onDataChange={(data: any) => {
              setUsers(data);
              console.log("users", data);
            }}
            allData={users}
          />
        </div>
      </div>

      <div className="mt-[1.25rem]">
        <InputText
          value={aircraftTitle}
          onChange={(e) => {
            setAircraftTitle((prevState: string) => {
              if (prevState !== e.target.value) return e.target.value;
              return prevState;
            });
          }}
          placeholder="Enter Title"
          labelTitle="Aircraft Title"
        />
      </div>

      {/* <div className="flex mt-4 items-center space-x-10 p-4">
        <div className="flex space-x-8 items-center">
          <div className="flex space-x-10 items-center">
            <span className="text-[14px] font-bold">Aircraft Background Image</span>
            <UploadButton type="image" />
          </div>
          <UploadDropdowns File={File} />
        </div>
      </div> */}

      <div className="grid grid-cols-4 mt-[1.25rem] mx-[0.49rem] items-center">
        <div className="col-span-1 font-[600] text-[1rem] text-[#242424] leading-normal">
          Aircraft Background Image
        </div>
        <div className="col-span-3 flex space-x-[0.75rem] items-center">
          <UploadButton
            name="aircraftbgImageFile"
            // handleClick={(name) => {console.log(name); setUploadButtonFor(name)}}
            type="image"
            pageState={{
              // ...state,
              uploadButtonFor: "aircraftbgImageFile",
              userTitle,
              aircraftTitle,
              users,
              aircrafts,
              userbgFile,
              aircraftbgFile,
            }}
            handleUploadFromDevice={(file) => {
              setaircraftbgFile(file);
              // console.log(file);
            }}
          />
          <UploadDropdowns
          name="aircraftbgImageFile"
          type="image"
          pageState={{
            // ...state,
            uploadButtonFor: "aircraftbgImageFile",
              userTitle,
              aircraftTitle,
              users,
              aircrafts,
              userbgFile,
              aircraftbgFile,
          }}
          handleCropFile={(cropFile) => setaircraftbgFile(cropFile)}
          File={aircraftbgFile} />
        </div>
      </div>

      <div className="grid grid-cols-4 mt-[1.25rem] mx-[0.49rem] items-center">
        <div className="col-span-1 font-[600]  text-[1rem] text-[#242424] leading-normal">Aircraft Menu</div>
        <div className="col-span-3">
          <DropDown
            pageState={
              state
                ? state.uploadButtonFor
                  ? {
                      ...state,
                      userbgFile,
                      aircraftbgFile,
                      outerFile: state.file,
                      file: null,
                    }
                  : state
                : {
                    userTitle,
                    aircraftTitle,
                    users,
                    aircrafts,
                    userbgFile,
                    aircraftbgFile,
                  }
            }
            handleSetStateNull={() => setState(null)}
            AddMenu="Add Aircraft"
            CreateNewMenu="Create new aircraft"
            onDataChange={(data: any) => {
              setAircrafts(data);
              console.log("aircrafts", data);
            }}
            allData={aircrafts}
          />
        </div>
      </div>
    </div>
  );
}

export default MultipleUserScreen;
