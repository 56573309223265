import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { useDispatch } from 'react-redux';
import axios from "axios";

type Props = {}

function Invite({ }: Props) {
    const { instance } = useMsal();
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const navigate = useNavigate();

    const inviteUser = async (e: React.FormEvent<HTMLFormElement>) => { // Specify the type of 'e'
        e.preventDefault();

        try {
            // Request an access token
            const authResult = await instance.loginPopup();

            // Make a call to Azure AD B2B invite endpoint
            const response = await axios.post(
                'https://graph.microsoft.com/v1.0/invitations',
                {
                    invitedUserEmailAddress: email,
                    inviteRedirectUrl: 'https://bravo-x.peakpacificgroup.com/', // Specify your invitation redirect URL
                },
                {
                    headers: {
                        Authorization: `Bearer ${authResult.accessToken}`,
                        'Content-Type': 'application/json',
                    },
                }
            );

            setMessage('User invited successfully.');
            navigate("/login")
            console.log(response.data); // Handle the response accordingly
        } catch (error) {
            setMessage('Error inviting user. Please try again.');
            console.error('Error inviting user:', error);
        }


    };
    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 py-2">
            <h1 className="text-2xl font-bold mb-5 text-[#AB0273]">Invite User</h1>
            <form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4" onSubmit={inviteUser}>
                <div className="mb-4">
                    <label className="block text-[#AB0273] text-sm font-bold mb-2" htmlFor="email">
                        Email Address:
                    </label>
                    <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="email"
                        type="email"
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </div>
                <div className="flex items-center justify-between">
                    <button className="bg-[#AB0273] hover:bg-[#9B0273] text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit">
                        Invite User
                    </button>
                </div>
            </form>
            <p className="text-center text-gray-500 text-xs">{message}</p>
        </div>
    );
}

export default Invite