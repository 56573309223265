import React, { useState, useEffect } from "react";
import { ChevronRight } from "@carbon/icons-react";
import { ChevronLeft } from "@carbon/icons-react";
import { useSelector } from "react-redux";
import { head } from "lodash";
import Loader from "src/utils/Loader";
import { Module } from "src/features/trackedModules/trackedModulesSlice";

type Props = {
  data: any;
  handleSelectMenu: (menu: any) => void;
  selectedAircraft: any;
  selectedUser: any;
};

export function MenuScreen({
  data,
  selectedAircraft,
  selectedUser,
  handleSelectMenu,
}: Props) {
  const [filteredData, setFilteredData] = useState<Array<any>>([]);

  const { themeColor, headingFont, bodyFont, headingColor, thumbnailHeadingColor, thumbnailTitleColor } = useSelector((state: any) => state.programSettings);
  const {modules, currentPath} = useSelector((state: any) => state.trackedModules);

  useEffect(() => {
    console.log(modules, currentPath)
  }, [modules, currentPath])

  useEffect(() => {
    console.log(data, selectedAircraft, selectedUser);

    const filteredTemplates = data.filter(
      (template: any) =>
        template.content?.documents || template.content?.aircraftDocuments
    );

    console.log(filteredTemplates);

    const filterTemplatesByUserAircraft = filteredTemplates.filter(
      (template: any) => {
        if (template.type !== "location diagram") {
          const temp = template.content.documents.filter((data: any) => {
            if (data.aircraftId === selectedAircraft.id) {
              const template = data.selectedOptions.filter((data: any) => {
                return data.id === selectedUser.id;
              });
              return template.length > 0;
            } else return false;
          });
          return temp.length > 0;
        } else {
          const temp = template.content.aircraftDocuments.filter(
            (data: any) => {
              if (data.aircraftId === selectedAircraft.id) {
                return true;
              } else return false;
            }
          );
          return temp.length > 0;
        }
      }
    );

    console.log(filterTemplatesByUserAircraft);
    //now we are going to find whether there is modal template or not if yes then we will append it with the filterTemplatesByUserAircraft array

    const result = data.find((template: any) => template.type === "modal");
    console.log(result);

    if (result) setFilteredData([...filterTemplatesByUserAircraft, result]);
    else setFilteredData(filterTemplatesByUserAircraft);
  }, []);

  const getModuleByPath = (modulesList: Module[], path: string[]): Module | null => {
    let currentModules = modulesList;
    let targetModule: Module | null = null;

    for (let id of path) {
      targetModule = currentModules.find(module => module.id === id) || null;

      if (!targetModule) return null; // Return null if any module in the path is missing

      currentModules = targetModule.children || []; // Move to the next level
    }

    return targetModule; // Return the final module
  };

  return (
    <div className={`grow w-full h-full flex flex-col space-y-[3rem] md:space-y-[3.4rem] lg:space-y-[5rem] ${bodyFont && `font-${bodyFont}`}`}>
      <div className={`text-[1.5rem] md:text-[1.7rem] lg:text-[2rem] font-semibold ${headingFont && `font-${headingFont}`}`}>
        <div 
        style={{color: headingColor, borderColor: headingColor}}
        className={`border-l-4 md:border-l-[7px] pl-[1rem] md:pl-[1.5rem] leading-normal ${!headingColor && "text-[#FFFFFF] border-[#FFFFFF]"}`}>
          {selectedAircraft.name}
        </div>
      </div>

      <div className="flex-1 min-h-0 overflow-y-auto no-scrollbar">
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-[2rem] lg:gap-x-[4.69rem] gap-y-[3rem] lg:gap-y-[5rem] pl-0 lg:pl-[1.5rem]">
          {filteredData.map((data: any) => {
            const currentModules = getModuleByPath(modules, currentPath)?.children;
            const moduleStatus = currentModules && currentModules.find((module: Module) => module.id === data._id)?.status;
            return (
              <MenuCard
                key={data._id}
                status={moduleStatus}
                themeColor={themeColor} 
                thumbnailHeadingColor={thumbnailHeadingColor} 
                thumbnailTitleColor={thumbnailTitleColor}
                data={data}
                handleSelect={() => {
                  handleSelectMenu(data);
                  console.log("this is running");
                }}
              />
            )
          })}
        </div>
      </div>
    </div>
  );
}

const MenuCard: React.FC<{ data: any; status: string | undefined; themeColor: any; thumbnailHeadingColor: any, thumbnailTitleColor: any, handleSelect: () => void }> = ({
  data,
  status,
  themeColor,
  thumbnailHeadingColor, 
  thumbnailTitleColor,
  handleSelect,
}) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const img = new Image();
    img.src = data.content.bgfile?.url ?? "";
    img.onload = () => setIsLoading(false);
  }, [data.content.bgfile?.url]);

  return (
    <button onClick={() => handleSelect()} className="relative flex flex-col">
      <div 
      style={{ borderColor: `${themeColor}CC` }}
      className={`w-full max-w-[16.875rem] h-[9.375rem] flex-shrink-0 overflow-hidden border-2 border-b-0 ${!themeColor && "border-[#2F0220]/80"}`}>
        {isLoading ? (
          <div className="w-full h-full flex items-center justify-center bg-gray-200">
            <Loader loading={isLoading} />
          </div>
        ) : (
          <img
            src={data.content.bgfile?.url ?? ""}
            alt={`Profile of ${data.title}`}
            className="w-full h-full object-cover flex"
            loading="lazy"
          />
        )}
      </div>
      <div style={{ backgroundColor: `${themeColor}CC`, borderColor: `${themeColor}CC`, color: thumbnailTitleColor }} 
      className={`flex w-full max-w-[16.875rem] py-[0.25rem] px-[0.31rem] text-start justify-start items-center flex-shrink-0 ${!thumbnailTitleColor && "text-[#FFFFFF]"} font-semibold text-[1.25rem] leading-[1.5rem] tracking-[0.00188rem] border-2 border-t-0 ${!themeColor && "bg-[#2F0220]/80 border-[#2F0220]/80"}`}>
        {data.title}
      </div>
      <span className={`absolute top-2 left-2 h-4 w-4 rounded-full ${status && status === "gray" ? "bg-gray-500" : status === "orange" ? "bg-orange-500" : "bg-green-500"}`}></span>
    </button>
  );
};
