import React, { useEffect, useState } from "react";
import Modal from "src/utils/Modal";
import Drawer from "src/utils/Drawer";
import InputText from "src/utils/TextInput/InputText";
import axios from "axios";
import { useSelector } from "react-redux";
import EditLogo from "../assets/PencilSimple.svg";
import ViewLogo from "../assets/view.png";
import DownArrow from "../assets/down.png";
import UploadButton from "./UploadButton";
import AddUsers from "src/components/DraweData/AddUsers";
import AddAircrafts from "src/components/DraweData/AddAircraft";
import EditUser from "src/components/DraweData/EditUser";
import EditAircraft from "src/components/DraweData/EditAircraft";
import AddLanguages from "src/components/DraweData/AddLanguages";
import EditLanguages from "src/components/DraweData/EditLanguages";
import EditCatagory from "src/components/DraweData/EditCatagory";
import AddCatagory from "src/components/DraweData/AddCatagory";
import { useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setPageState } from "src/features/templatePageState/templatePageStateSlice";
import AddSection from "src/components/DraweData/AddSection";
import AddEquipments from "src/components/DraweData/AddEquipments";
import AddLocations from "src/components/DraweData/AddLocations";
import EditSection from "src/components/DraweData/EditSection";
import EditEquipments from "src/components/DraweData/EditEquipments";
import EditLocations from "src/components/DraweData/EditLocations";
import LocationDrawer from "./LocationDrawer";
import UploadDropdowns from "src/components/UploadDropdowns";
import { forEach } from "lodash";
import { DefineSectionLopa, MapScreen, Test } from "src/components/Test";
import { setLog } from "src/features/errorLog/errorLogSlice";
import DrawSquare from "../assets/drawsquare.svg";

type Props = {
  className?: string;
  AircraftId: string;
  AddMenu: string;
  CreateNewMenu: string;
  onEditedData?: (data: any) => void;
  onDataChange?: (data: any[]) => void;
  allData?: any[];
  sections: any[];
  equipments: any[];
  locations: any[];
  documents?: any[];
  onHandleDocuments?: (documents: any[]) => void;
  selectAircraft: any;
  pageState?: any;
  onHandleState: () => void;
  outerFile?: any;
};

type Data = {
  name: string;
  id: number;
  section?: any;
  equipments?: any[];
  bounds?: any[];
  // file?: any;
  position?: string | number;
  startY?: number | null;
  endY?: number | null;
};

function CreatableSelectDropdown({
  className,
  AircraftId,
  AddMenu,
  CreateNewMenu,
  allData,
  onEditedData,
  onDataChange,
  sections,
  equipments,
  locations,
  documents = [],
  onHandleDocuments,
  selectAircraft,
  pageState,
  onHandleState,
  outerFile,
}: Props) {
  const [openDropdown, setOpenDropdown] = useState(false);
  const [openEditData, setOpenEditData] = useState("");
  const [editTitle, setEditTitle] = useState("");
  const [dataName, setDataName] = useState("");
  const [openLocationDrawer, setOpenLocationDrawer] = useState<any>(null);
  const [openDefineSectionDrawer, setOpenDefineSectionDrawer] = useState<any>(null);
  const [openEditDefinedSectionDrawer, setOpenEditDefinedSectionDrawer] = useState<any>(null);
  const [selectEquipment, setSelectEquipment] = useState<any>(null);
  const [openLocationEquipments, setOpenLocationEquipments] = useState(false);
  const [openCreateModal, setOpenCreateModal] = useState(""); // for language and catagory creation
  const [openEditModal, setOpenEditModal] = useState(""); // for language and catagory edit
  const [editedData, setEditedData] = useState<any>();
  const [state, setState] = useState<any>(pageState || null);
  const [equipmentName, setEquipmentName] = useState("");
  const [quantity, setQuantity] = useState<number>(0);
  const [documentId, setDocumentId] = useState<any>(null);
  const [locationPoints, setLocationPoints] = useState<Array<Array<number>>>([]);
  const [sectionBounds, setSectionBounds] = useState<Array<number>>([]);
  const [editSectionBounds, setEditSectionBounds] = useState<Array<number>>([]);
  const [location, setLocation] = useState<any>(null);
  const [file, setFile] = useState<any>(null);
  const [iconfile, seticonFile] = useState<any>(null);

  const [searchUrlParams, setSearchUrlParams] = useSearchParams();

  const [x, setX] = useState<number>(0);
  const [y, setY] = useState<number>(0);
  const [locatePoints, setLocatePoints] = useState<number>(0);

  const [filteredOptions, setFilteredOptions] = useState<Array<any>>([]);

  const dispatch = useDispatch();

  const { accessToken } = useSelector((state: any) => state.accessToken);
  // const { pageState } = useSelector((state: any) => state.templatePageState);

  useEffect(() => {
    console.log("this is pageState: ", pageState, state);
    if (state) {
      // if(pageState.openCreateData) setOpenCreateData(pageState.openCreateData);
      if (state.input) setDataName(state.input);
      if (state.openEditData) setOpenEditData(state.openEditData);
      if (state.editTitle) setEditTitle(state.editTitle);
      if (state.editData) setEditedData(state.editData);
      if (state.openCreateModal) setOpenCreateModal(state.openCreateModal);
      if (state.openEditModal) setOpenEditModal(state.openEditModal);
      setState(null);
    }
  }, [pageState]);

  // Manish
  const menuRef = React.createRef<HTMLDivElement>();
  const buttonRef = React.createRef<HTMLButtonElement>();

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!menuRef?.current?.contains(event.target as Node)) {
        setOpenDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      // Clean up the event listener when the component unmounts
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuRef]);
  //End

  useEffect(() => {
    console.log("sectionBounds: ", sectionBounds, "editSectionBounds: ", editSectionBounds);
  }, [sectionBounds, editSectionBounds]);

  useEffect(() => {
    console.log("editedData: ", editedData);  
  }, [editedData]);
  useEffect(() => {
    console.log("outerFile: ", outerFile);  
  }, [outerFile]);

  useEffect(() => {
    console.log(quantity, x, y, locatePoints, locationPoints);
    handleDone();
  }, [quantity, x, y, locatePoints, locationPoints]);

  function handleAdd(data: any, type: string) {
    let newData: Data | null = null;
    if (type === "section")
      newData = {
        name: data.name,
        id: new Date().getTime(),
        // file: data.file,
        bounds: data.bounds,
        position: -1,
        startY: 0,
        endY: 0,
      };
    if (type === "equipment") newData = { ...data, id: new Date().getTime() };
    if (type === "location")
      newData = {
        name: data.name,
        id: new Date().getTime(),
        section: data.section,
        equipments: data.equipments,
      };
    console.log("this is the newData: ", newData);

    if (onDataChange && allData) onDataChange([...allData, newData]);

    onHandleState();
    setDataName("");
    // setOpenCreateData("");
    setOpenCreateModal("");
  }

  // function handleEditPopup(data: Data) {
  //   // setOpenEditData(data.name);
  //   setOpenEditModal(data.name);
  //   setEditedData(data);
  // }

  function handleEditModalPopup(data: any) {
    // console.log(data)
    setOpenEditModal(data.name);
    setEditedData(data);
    if(AddMenu === "Add Section") {
      setEditSectionBounds(data.bounds);
    }
  }

  function handleEdit(editedData: any) {
    console.log(editedData);
    if (onDataChange && allData) {
      const newData = allData.map((data) => {
        if (data.id === editedData?.id) {
          return editedData;
        }
        return data;
      });

      onHandleState();
      onDataChange(newData);
      // setOpenEditData("");
      setOpenEditModal("");
    }
  }

  useEffect(() => { }, [selectEquipment, locationPoints]);

  function handleEquipmentSelection(equipment: any) {
    console.log(equipment);
    setSelectEquipment(null);
    setSelectEquipment(equipment);

    setOpenLocationEquipments(false);

    const filteredEquipmentDoc = documents?.filter((item: any) => {
      if (
        item.aircraftId === AircraftId &&
        item.sectionId === openLocationDrawer.section.id &&
        item.locationId === openLocationDrawer.id &&
        item.equipmentId === equipment.id
      ) {
        return item;
      }
    })[0];

    console.log(filteredEquipmentDoc);
    if (filteredEquipmentDoc) {
      setDocumentId(filteredEquipmentDoc.id);
      setEquipmentName(filteredEquipmentDoc.equipmentName);
      setQuantity(filteredEquipmentDoc.quantity);
      setLocationPoints(filteredEquipmentDoc.locationPoints);
      setFile(filteredEquipmentDoc.file);
      seticonFile(filteredEquipmentDoc.iconfile);
      return;
    }

    const newLocationDoc = {
      id: new Date().getTime(),
      aircraftId: AircraftId,
      sectionId: openLocationDrawer.section.id,
      locationId: openLocationDrawer.id,
      equipmentId: equipment.id,
      equipmentName: equipment.name,
      quantity: 0,
      locationPoints: [],
      file: equipment.file,
      iconfile: equipment.iconfile,
    };

    setDocumentId(newLocationDoc.id);
    setEquipmentName(equipment.name);
    setQuantity(0);
    setLocationPoints([]);
    setFile(equipment.file);
    seticonFile(equipment.iconfile);
    console.log(newLocationDoc);

    if (onHandleDocuments)
      return onHandleDocuments([...documents, newLocationDoc]);
  }

  function handleChange(id: number, x: number, y: number) {
    console.log(id, x, y, locationPoints);
    const newLocationPointss = locationPoints.map((item: number[]) => {
      console.log(item, item[0], item[0] === id);
      if (item[0] === id) {
        // item[1] = x;
        // item[2] = y;

        return [id, x, y];
      }

      return item;
    });
    console.log(newLocationPointss);
    setLocationPoints(newLocationPointss);
  }

  function handleDone() {
    const newDocuments = documents.map((doc) => {
      if (doc.id === documentId) {
        return { ...doc, quantity, locationPoints };
      }
      return doc;
    });
    console.log(newDocuments);
    if (onHandleDocuments) onHandleDocuments(newDocuments);
  }

  function handleSetLocation() {
    console.log(quantity);
    setLocationPoints([]);
    for (let i = 0; i < quantity; i++) {
      setLocationPoints((prevState: number[][]) => [
        ...prevState,
        [i + 1, 0, 0],
      ]);
    }
  }

  function handleSearch(searchTerm: string) {
    const searchResults = allData?.filter((data: any) => {
      const optionName = data.name.toLowerCase();
      const searchTermChars = searchTerm.toLowerCase().split("");

      let searchIndex = 0;
      for (const char of optionName) {
        if (char === searchTermChars[searchIndex]) {
          searchIndex++;
        }

        if (searchIndex === searchTermChars.length) {
          return true; // All characters in the search term found in order
        }
      }

      return false;
    });

    if (searchResults) setFilteredOptions(searchResults);
  }

  return (
    <div>
      <div ref={menuRef} className="relative flex flex-col">
        <button
          onClick={() => {
            handleSearch("");
            setOpenDropdown(!openDropdown);
          }}
          className="flex w-[11.875rem] bg-[#AB0273] px-[0.63rem] py-[0.3125rem] rounded-[1.25rem] border border-[#AB0273] items-center justify-between"
        >
          <span className="text-[1rem] font-[600]  text-[#FFFFFF] leading-normal">
            {AddMenu}
          </span>
          <span className="">
            {/* <img src={DownArrow} alt="logo" /> */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
            >
              <path
                d="M26 12L16 22L6 12"
                stroke="white"
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
        </button>
        {openDropdown && (
          <div className="absolute top-[2.87rem] -left-[2.06rem] z-10 w-[16rem] border-2 border-[#000000]/30 bg-[#F5F5F5]  rounded-[1.8rem] px-[0.69rem] py-[0.5rem]">
            <button
              className="w-full inline-flex flex-shrink-0 col-span-3 text-[1rem] text-[#242424] font-[500]  leading-normal space-x-[0.63rem] pt-[0.5rem] pb-[0.94rem] items-center justify-center"
              onClick={() => {
                // if (AddMenu === "Add Secton" || AddMenu === "Add Location" || AddMenu === "Add Equipments"){
                setSectionBounds([]);
                setOpenCreateModal(CreateNewMenu);
                setDataName("");
                // }
              }}
            >
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M2.5 8H13.5"
                    stroke="#242424"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M8 2.5V13.5"
                    stroke="#242424"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
              <span>{CreateNewMenu}</span>
            </button>

            <input
              type="text"
              placeholder="Search..."
              className="flex w-full bg-[#F5F5F5] text-[#242424] text-[0.875rem] font-[500]  px-[0.58rem] py-[0.94rem] text-left  items-center border-t-[0.0625rem] border-[#2C2C2C] outline-none"
              // value={searchTerm}
              onChange={(e) => handleSearch(e.target.value)}
            />

            {filteredOptions && (
              <div className=" max-h-[151.5px] overflow-y-scroll no-scrollbar">
                {filteredOptions.map((data) => (
                  <>
                    {data.name && (
                      <div>
                        <div className="w-full flex text-[#242424] text-[1rem] font-[500]  px-[0.58rem] py-[0.94rem] text-left border-t-[0.0625rem] border-[#2C2C2C]  items-center">
                          <div className="overflow-hidden w-[185px]">
                            {data.name}
                          </div>
                          <div className="flex space-x-2">
                            {AddMenu === "Add Location" && (
                              <button
                                onClick={() => {
                                  if (AddMenu === "Add Location") {
                                    setOpenDropdown(false);
                                    setOpenLocationDrawer(data);
                                    setSelectEquipment(null);
                                  }
                                }}
                                className="w-5"
                              >
                                <img src={ViewLogo} alt="logo" />
                              </button>
                            )}
                            <button
                              onClick={() => {
                                if (
                                  AddMenu === "Add Section" ||
                                  AddMenu === "Add Location" ||
                                  AddMenu === "Add Equipments"
                                )
                                  handleEditModalPopup(data);
                                setEditTitle(`Edit ${AddMenu.split(" ")[1]}`);
                                setState(null);
                              }}
                              className="w-5"
                            >
                              <img src={EditLogo} alt="logo" />
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                ))}
              </div>
            )}
          </div>
        )}
      </div>

      {/* to create*/}
      {openCreateModal === "Create new section" &&
        openCreateModal === CreateNewMenu && (
          <Modal
            onOpened={openCreateModal.length > 0}
            onClose={() => setOpenCreateModal("")}
            title={AddMenu}
            height="calc(100%-400px)"
            width="calc(100%-600px)"
          >
            <AddSection
              state={
                pageState
                  ? { ...pageState, openCreateModal, selectAircraft }
                  : {
                    openCreateModal,
                    selectAircraft,
                    sections,
                    equipments,
                    locations,
                    outerFile,
                  }
              }
              openCreateModal={openCreateModal}
              input={dataName}
              onChangeInput={(e) => setDataName(e.target.value)}
              sectionBounds={sectionBounds}
              onHandleAdd={() => {
                let contains = false;
                sections.forEach((item: any) => {
                  if (item.name.toLowerCase() === dataName.toLowerCase()) {
                    contains = true;
                    dispatch(
                      setLog({
                        mode: "warn",
                        message: "section with this name is already present!",
                      })
                    );
                    return;
                  }
                });
                if (!contains)
                  handleAdd(
                    { name: dataName, bounds: sectionBounds },
                    "section"
                  );
                  // handleAdd(
                  //   { name: dataName, file: pageState ? pageState.file : null },
                  //   "section"
                  // );
              }}
              handleDefineSection={() => {
                setOpenDefineSectionDrawer(true);
              }}
            />
          </Modal>
        )}

      {openCreateModal === "Create new equipments" &&
        openCreateModal === CreateNewMenu && (
          <Modal
            onOpened={openCreateModal.length > 0}
            onClose={() => setOpenCreateModal("")}
            title={AddMenu}
            height="calc(100%-400px)"
            width="calc(100%-600px)"
          >
            <AddEquipments
              state={
                pageState
                  ? { ...pageState, openCreateModal, selectAircraft }
                  : {
                    openCreateModal,
                    selectAircraft,
                    sections,
                    equipments,
                    locations,
                    outerFile,
                  }
              }
              openCreateModal={openCreateModal}
              input={dataName}
              onChangeInput={(e) => setDataName(e.target.value)}
              onHandleAdd={(file, iconfile) => {
                let contains = false;
                equipments.forEach((item: any) => {
                  if (item.name.toLowerCase() === dataName.toLowerCase()) {
                    contains = true;
                    dispatch(
                      setLog({
                        mode: "warn",
                        message: "equipment with this name is already present!",
                      })
                    );
                    return;
                  }
                });
                if (!contains)
                  handleAdd({ name: dataName, file, iconfile }, "equipment");
              }}
            />
          </Modal>
        )}

      {openCreateModal === "Create new location" &&
        openCreateModal === CreateNewMenu && (
          <Modal
            onOpened={openCreateModal.length > 0}
            onClose={() => setOpenCreateModal("")}
            title={AddMenu}
            height="calc(100%-400px)"
            width="calc(100%-600px)"
          >
            <AddLocations
              // openCreateModal={openCreateModal}
              input={dataName}
              onChangeInput={(e) => setDataName(e.target.value)}
              onHandleAdd={(data) => {
                // let contains = false;
                // locations.forEach((item: any) => {
                //   if (item.name.toLowerCase() === dataName.toLowerCase()) {
                //     contains = true;
                //     dispatch(
                //       setLog({
                //         mode: "warn",
                //         message: "location with this name is already present!",
                //       })
                //     );
                //     return;
                //   }
                // });
                // if (!contains) handleAdd(data, "location");
                handleAdd(data, "location");
              }}
              sections={sections}
              equipments={equipments}
            />
          </Modal>
        )}

      {/* to edit*/}
      {editTitle === "Edit Section" &&
        CreateNewMenu === "Create new section" && (
          <Modal
            onOpened={openEditModal.length > 0}
            onClose={() => setOpenEditModal("")}
            title={editTitle}
            height="calc(100%-400px)"
            width="calc(100%)"
          >
            <EditSection
              state={
                pageState
                  ? { ...pageState, openCreateModal, selectAircraft }
                  : {
                    openCreateModal,
                    selectAircraft,
                    sections,
                    equipments,
                    locations,
                    openEditModal,
                    outerFile,
                  }
              }
              openEditData={openEditData}
              openEditModal={openEditModal}
              editTitle={editTitle}
              editData={editedData}
              editedBounds={editSectionBounds}
              handleEditDefineSection={() => {
                setOpenEditDefinedSectionDrawer(true);
                setEditSectionBounds(editSectionBounds);
              }}
              onSetEditData={(e) =>
                setEditedData((prevState: any) => {
                  const newName = e.target.value;
                  return { ...prevState, name: newName };
                })
              }
              onHandleEdit={(bounds) => {
                console.log(bounds);
                handleEdit({ ...editedData, bounds });
                if (onEditedData) onEditedData({ ...editedData, bounds });
              }}
              // onHandleEdit={(file) => {
              //   console.log(file);
              //   handleEdit({ ...editedData, file });
              //   if (onEditedData) onEditedData({ ...editedData, file });
              // }}
              onHandleDelete={(id: any) => {
                if (onDataChange && allData) {
                  const newUser = allData.filter((data) => data.id !== id);

                  onDataChange(newUser);

                  setOpenEditModal("");
                }
              }}
            />
          </Modal>
        )}
      {editTitle === "Edit Equipments" &&
        CreateNewMenu === "Create new equipments" && (
          <Modal
            onOpened={openEditModal.length > 0}
            onClose={() => setOpenEditModal("")}
            title={editTitle}
            height="calc(100%-400px)"
            width="calc(100%)"
          >
            <EditEquipments
              state={
                pageState
                  ? { ...pageState, openCreateModal, selectAircraft }
                  : {
                    openCreateModal,
                    selectAircraft,
                    sections,
                    equipments,
                    locations,
                    openEditModal,
                    outerFile,
                  }
              }
              openEditData={openEditData}
              openEditModal={openEditModal}
              editTitle={editTitle}
              editData={editedData}
              onSetEditData={(e) =>
                setEditedData((prevState: any) => {
                  const newName = e.target.value;
                  return { ...prevState, name: newName };
                })
              }
              onHandleEdit={(file, iconfile) => {
                console.log(file);
                handleEdit({ ...editedData, file, iconfile });
              }}
              onHandleDelete={(id: any) => {
                if (onDataChange && allData) {
                  const newUser = allData.filter((data) => data.id !== id);

                  onDataChange(newUser);

                  setOpenEditModal("");
                }
              }}
            />
          </Modal>
        )}
      {editTitle === "Edit Location" &&
        CreateNewMenu === "Create new location" && (
          <Modal
            onOpened={openEditModal.length > 0}
            onClose={() => setOpenEditModal("")}
            title={editTitle}
            height="calc(100%-400px)"
            width="calc(100%)"
          >
            <EditLocations
              editData={editedData}
              onSetEditData={(data) => {
                setEditedData(data);
                // console.log(data)
              }}
              onHandleEdit={() => handleEdit(editedData)}
              onHandleDelete={(id: any) => {
                if (onDataChange && allData) {
                  const newUser = allData.filter((data) => data.id !== id);

                  onDataChange(newUser);

                  setOpenEditModal("");
                }
              }}
              sections={sections}
              equipments={equipments}
            />
          </Modal>
        )}

      <LocationDrawer
        onOpened={openLocationDrawer}
        onClose={() => setOpenLocationDrawer(null)}
        title={`${openLocationDrawer?.section.name} (${openLocationDrawer?.name})`}
        height="calc(100%-400px)"
      >
        {openLocationDrawer ? (
          <MapScreen
            image={outerFile?.url ?? ""}
            x={x}
            y={y}
            locatePoints={locatePoints}
            onChangeInputs={({ id, x, y }) => {
              handleChange(id, x, y);
            }}
            sectionBounds={openLocationDrawer?.section.bounds}
          />
        ) : (
          <></>
        )}
        <div className="flex flex-col h-full">
          <div className="grow space-y-4 mt-[0.88rem] overflow-y-scroll">
            <div
              className="relative flex flex-col items-center
            text-[16px]"
            >
              <div className="pb-[2.56rem]">
                <button
                  onClick={() => {
                    // handleSearch("", openLocationDrawer?.equipments);
                    setOpenLocationEquipments(!openLocationEquipments);
                  }}
                  className="flex w-[11.875rem] bg-[#AB0273] px-[0.63rem] py-[0.3125rem] rounded-[1.25rem] border border-[#AB0273] items-center justify-between"
                >
                  <span className="text-[1rem] font-[600]  text-[#FFFFFF] leading-normal">
                    Select Equipment
                  </span>
                  <span className="block">
                    <img src={DownArrow} alt="logo" />
                  </span>
                </button>
              </div>
              {openLocationEquipments && (
                <div className="absolute top-[2.3rem] z-10 w-[11.875rem] border-2 border-[#000000]/30 bg-[#F5F5F5]  rounded-[1.8rem] px-[1.12rem]">
                  {/* <input
                    type="text"
                    placeholder="Search..."
                    className="flex w-full bg-[#F5F5F5]  text-[#242424] text-[0.875rem] font-[500]  px-[0.58rem] pb-[0.94rem] text-left  items-center outline-none"
                    // value={searchTerm}
                    onChange={(e) =>
                      handleSearch(
                        e.target.value,
                        openLocationDrawer?.equipments
                      )
                    }
                  /> */}

                  <div className="max-h-[160px] overflow-y-scroll no-scrollbar ">
                    {openLocationDrawer?.equipments.map((equipment: any, idx: number) => (
                      <>
                        <button
                          onClick={() => {
                            handleEquipmentSelection(equipment);
                          }}
                          className={`flex w-full text-[1rem] text-[#242424] font-[500] px-[0.58rem] py-[0.94rem] text-left items-center border-t border-[#2C2C2C] ${idx === 0 && "border-t-0"}`}
                        >
                          <div className="overflow-hidden">
                            {equipment.name}
                          </div>
                        </button>
                      </>
                    ))}
                  </div>
                </div>
              )}
              {selectEquipment && (
                <div className="pb-[1.25rem] space-y-[1.5rem]  w-full">
                  <div className="px-[0.45rem]">
                    <InputText
                      disabled
                      value={equipmentName}
                      // onChange={(e) => setEquipmentName(e.target.value)}
                      placeholder="Equipment Name"
                      labelTitle=""
                    />
                  </div>

                  <div className="flex flex-col space-y-[0.75rem] px-[0.94rem]">
                    <div className="text-[16px] font-[600]">
                      Equipment Image
                    </div>
                    <div className="text-[14px] text-[#586A84]">
                      {selectEquipment.file?.name}
                    </div>
                  </div>

                  <div className="flex flex-col space-y-[0.75rem] px-[0.94rem]">
                    <div className="text-[16px] font-[600]">
                      Equipment Icon Image
                    </div>
                    <div className="text-[14px] text-[#586A84]">
                      {selectEquipment.iconfile?.name}
                    </div>
                  </div>

                  <div className="flex flex-col space-y-[0.94rem] px-[0.94rem]">
                    <label className="font-bold text-[16px]" htmlFor="input">
                      Quantity
                    </label>
                    <div className="flex items-center space-x-2 pr-4 overflow-hidden rounded-md border border-[#929292]">
                      <input
                        type="number"
                        className="grow outline-none px-4 py-1 text-[14px]"
                        value={quantity}
                        onChange={(e) => setQuantity(parseInt(e.target.value))}
                        name="input"
                      />
                    </div>
                  </div>

                  <div className="px-[0.94rem]">
                    <button
                      onClick={() => {
                        handleSetLocation();
                        // handleDone();
                      }}
                      className="border px-3 py-2 bg-tertiary text-[#FFFFFF] text-[16px] font-[600] rounded-[1.25rem]"
                    >
                      set location points
                    </button>
                  </div>

                  {locationPoints &&
                    locationPoints.map((point, idx) => {
                      return (
                        <div className="flex flex-col space-y-[1.37rem] p-4">
                          <div className="flex space-x-[2.75rem] items-center text-[14px]">
                            <div className="text-[16px] font-[600]">
                              location point {idx + 1}
                            </div>
                            <button
                              onClick={() => {
                                setX(point[1]);
                                setY(point[2]);
                                console.log(point, point[0]);
                                setLocatePoints(point[0]);
                              }}
                              className="px-3 py-2 bg-[#89AF7B] hover:bg-[#89AF7B]/80 text-[#FFFFFF] rounded-[1.25rem] text-[14px] font-[600]"
                            >
                              view and set the point
                            </button>
                          </div>
                          <div className="flex space-x-2">
                            <div className="items-center overflow-hidden rounded-md hover:border-primary border-2">
                              <input
                                type="number"
                                className="grow outline-none w-52 px-4 py-1 text-[14px]"
                                value={point[1]}
                                onChange={(e) => {
                                  handleChange(
                                    point[0],
                                    parseInt(e.target.value),
                                    point[2]
                                  );
                                  setX(point[1]);
                                  setY(point[2]);
                                  setLocatePoints(0);
                                  // handleDone();
                                }}
                                name="input"
                              />
                            </div>
                            <div className="items-center overflow-hidden rounded-md hover:border-primary border-2">
                              <input
                                type="number"
                                className="grow outline-none w-52 px-4 py-1 text-[14px]"
                                value={point[2]}
                                onChange={(e) => {
                                  handleChange(
                                    point[0],
                                    point[1],
                                    parseInt(e.target.value)
                                  );
                                  handleDone();
                                  setX(point[1]);
                                  setY(point[2]);
                                  setLocatePoints(0);
                                }}
                                name="input"
                              />
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              )}
            </div>
          </div>
          <div className="border-t border-[#2C2C2C] px-[0.49rem] py-5 flex justify-end items-center">
            <button
              onClick={() => {
                setOpenLocationDrawer(null);
                handleDone();
                setX(0);
                setY(0);
                setLocatePoints(0);
              }}
              className="px-7 py-3 text-[16px] rounded-[1.875rem] text-[#FFFFFF] bg-[#AB0273] y"
            >
              Done
            </button>
          </div>
        </div>
      </LocationDrawer>

      <LocationDrawer
        onOpened={openDefineSectionDrawer}
        onClose={() => setOpenDefineSectionDrawer(null)}
        title={dataName}
        height="calc(100%-400px)"
      >
        {openDefineSectionDrawer ? (
          <DefineSectionLopa
            image={outerFile?.url ?? ""}
            sectionBounds={sectionBounds}
            onSectionBoundsChange={(bounds: number[]) => {
              console.log('Updated Section Bounds:', bounds);
              setSectionBounds(bounds);
            }}
          />
        ) : (
          <></>
        )}
        <div className="flex flex-col h-full">
          <div className="grow space-y-4 mt-[0.88rem] p-4">
            <div
              className="flex flex-col items-start
            text-[16px]"
            >
              Define section bounds here:
            </div>
            <div
              className="flex flex-wrap items-center
            text-[16px]"
            >
              <span className="pr-1">Click on this</span>
              <img className="w-5 h-5" src={DrawSquare} alt="drawsquare" />
              <span className="pl-1">to enable drawing mode:</span>
            </div>
            <div className="grid grid-cols-4 items-center">
              <div className="col-span-1 text-[16px]">Start Bound:</div>
              <div className="col-span-3 w-32 items-center overflow-hidden rounded-md hover:border-primary border-2">
                <input
                  type="number"
                  className="grow outline-none w-32 px-4 py-1 text-[14px]"
                  placeholder="Start Height"
                  value={sectionBounds[0] || 0}
                  onChange={(e) => {
                    const roundedValue = Math.round(parseFloat(e.target.value)) || 0;
                    setSectionBounds([roundedValue, sectionBounds[1]]);
                  }}
                />
              </div>
            </div>
            <div className="grid grid-cols-4 items-center">
              <div className="col-span-1 text-[16px]">End Bound:</div>
              <div className="col-span-3 w-32 items-center overflow-hidden rounded-md hover:border-primary border-2">
                <input
                  type="number"
                  className="grow outline-none w-32 px-4 py-1 text-[14px]"
                  placeholder="End Height"
                  value={sectionBounds[1] || 0}
                  onChange={(e) => {
                    const roundedValue = Math.round(parseFloat(e.target.value)) || 0;
                    setSectionBounds([sectionBounds[0], roundedValue]);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="border-t border-[#2C2C2C] px-[0.49rem] py-5 flex justify-end items-center">
            <button
              onClick={() => {
                setOpenDefineSectionDrawer(null);
              }}
              className="px-7 py-3 text-[16px] rounded-[1.875rem] text-[#FFFFFF] bg-[#AB0273] y"
            >
              Done
            </button>
          </div>
        </div>
      </LocationDrawer>
      
      <LocationDrawer
        onOpened={openEditDefinedSectionDrawer}
        onClose={() => setOpenEditDefinedSectionDrawer(null)}
        title={editedData?.name}
        height="calc(100%-400px)"
      >
        {openEditDefinedSectionDrawer ? (
          // <Test
          //   image={openLocationDrawer.section.file}
          //   x={x}
          //   y={y}
          //   locatePoints={locatePoints}
          //   onChangeInputs={({ id, x, y }) => {
          //     handleChange(id, x, y);
          //   }}
          // />
          <DefineSectionLopa
            image={outerFile?.url ?? ""}
            sectionBounds={editSectionBounds}
            onSectionBoundsChange={(bounds: number[]) => {
              console.log('Updated Section Bounds:', bounds);
              setEditSectionBounds(bounds);
            }}
          />
        ) : (
          <></>
        )}
        <div className="flex flex-col h-full">
          <div className="grow space-y-4 mt-[0.88rem] p-4">
            <div
              className="relative flex flex-col items-start
            text-[16px]"
            >
              Define section bounds here:
            </div>
            <div
              className="flex flex-wrap items-center
            text-[16px]"
            >
              <span className="pr-1">Click on this</span>
              <img className="w-5 h-5" src={DrawSquare} alt="drawsquare" />
              <span className="pl-1">to enable drawing mode:</span>
            </div>
            <div className="grid grid-cols-4 items-center">
              <div className="col-span-1 text-[16px]">Start Bound:</div>
              <div className="col-span-3 w-32 items-center overflow-hidden rounded-md hover:border-primary border-2">
                <input
                  type="number"
                  className="grow outline-none w-32 px-4 py-1 text-[14px]"
                  placeholder="Start Height"
                  value={editSectionBounds[0] || 0}
                  onChange={(e) => {
                    const roundedValue = Math.round(parseFloat(e.target.value)) || 0;
                    setEditSectionBounds([roundedValue, editSectionBounds[1]]);
                  }}
                />
              </div>
            </div>
            <div className="grid grid-cols-4 items-center">
              <div className="col-span-1 text-[16px]">End Bound:</div>
              <div className="col-span-3 w-32 items-center overflow-hidden rounded-md hover:border-primary border-2">
                <input
                  type="number"
                  className="grow outline-none w-32 px-4 py-1 text-[14px]"
                  placeholder="End Height"
                  value={editSectionBounds[1] || 0}
                  onChange={(e) => {
                    const roundedValue = Math.round(parseFloat(e.target.value)) || 0;
                    setEditSectionBounds([editSectionBounds[0], roundedValue]);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="border-t border-[#2C2C2C] px-[0.49rem] py-5 flex justify-end items-center">
            <button
              onClick={() => {
                setOpenEditDefinedSectionDrawer(null);
              }}
              className="px-7 py-3 text-[16px] rounded-[1.875rem] text-[#FFFFFF] bg-[#AB0273] y"
            >
              Done
            </button>
          </div>
        </div>
      </LocationDrawer>
    </div>
  );
}

export default CreatableSelectDropdown;
