import { useEffect, useRef, useState } from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import 'leaflet-draw';
import DrawSquare from "../../assets/drawsquare.svg";
// import GrabHand from "../../assets/grabhand.svg";
import GrabHand from "../../assets/hold.png";

declare global {
    interface Window {
        zoomToLocation: (location: string) => void;
    }
}

type Props = {
    image: any;
    sectionBounds?: number[];
    onSectionBoundsChange?: (bounds: number[]) => void;
};

export function DefineSectionLopa({ image, sectionBounds, onSectionBoundsChange }: Props) {
    const mapRef = useRef<L.Map | null>(null);
    const [imageSize, setImageSize] = useState<{ width: number; height: number } | null>(null);
    const [isDrawingMode, setIsDrawingMode] = useState(false);
    const drawHandlerRef = useRef<L.Draw.Rectangle | null>(null);
    const imageOverlayRef = useRef<L.ImageOverlay | null>(null);
    const drawnItemsRef = useRef<L.FeatureGroup | null>(null);

    useEffect(() => {
        console.log("sectionBounds", sectionBounds);
    }, [sectionBounds]);
    
    useEffect(() => {
        const img = new Image();
        img.src = image;
        img.onload = () => {
            setImageSize({ width: img.naturalWidth, height: img.naturalHeight });
        };
    }, [image]);

    useEffect(() => {
        if (!imageSize) return;

        const { width, height } = imageSize;
        const centerH = sectionBounds && sectionBounds.length === 2
            ? ((sectionBounds[1] - sectionBounds[0]) / 2) + sectionBounds[0]
            : height / 2;
        const centerW = width / 2;

        if (!mapRef.current) {
            const map = L.map('map', {
                crs: L.CRS.Simple,
                maxBounds: [[0, 0], [height, width]],
                maxBoundsViscosity: 1.0,
                center: [centerH, centerW],
                zoom: -1,
                minZoom: -2,
                dragging: true,
            });

            mapRef.current = map;
            const drawnItems = new L.FeatureGroup();
            map.addLayer(drawnItems);
            drawnItemsRef.current = drawnItems;

            drawHandlerRef.current = new L.Draw.Rectangle(map, {
                shapeOptions: {
                    color: 'blue',
                    weight: 2,
                },
            });

            map.on(L.Draw.Event.CREATED, (event: any) => {
                const layer = event.layer;
                drawnItems.clearLayers();
                drawnItems.addLayer(layer);
                const bounds = layer.getBounds();
                const startHeight = bounds.getSouth();
                const endHeight = bounds.getNorth();

                if (!isNaN(startHeight) && !isNaN(endHeight)) {
                    onSectionBoundsChange?.([startHeight, endHeight]);
                }
            });
        }

        if (mapRef.current && !imageOverlayRef.current) {
            const bounds: L.LatLngBoundsExpression = [[0, 0], [height, width]];
            const imageOverlay = L.imageOverlay(image, bounds).addTo(mapRef.current);
            imageOverlayRef.current = imageOverlay;
            mapRef.current.fitBounds(bounds, { padding: [20, 20] });
        }
    }, [imageSize, onSectionBoundsChange]);

    useEffect(() => {
        if (!imageSize || !sectionBounds || sectionBounds.length !== 2 || !drawnItemsRef.current) return;

        const [startHeight, endHeight] = sectionBounds;

        // Validate that startHeight, endHeight, and imageSize.width are valid numbers
        if (
            isNaN(startHeight) || 
            isNaN(endHeight) || 
            isNaN(imageSize.width)
        ) {
            console.error("Invalid rectangle bounds:", { startHeight, endHeight, imageSize });
            return;
        }

        console.log("Redrawing rectangle with bounds:", sectionBounds);
        // Clear previous layers
        drawnItemsRef.current.clearLayers();

        // Define rectangle bounds
        const rectangleBounds: L.LatLngBoundsExpression = [
            [startHeight, 0],
            [endHeight, imageSize.width],
        ];

        // Create and add the rectangle
        const rectangle = L.rectangle(rectangleBounds, {
            color: 'blue',
            weight: 2,
        });
        drawnItemsRef.current.addLayer(rectangle);
    }, [sectionBounds, imageSize]);

    const toggleMode = () => {
        if (!mapRef.current || !drawHandlerRef.current) return;

        if (isDrawingMode) {
            mapRef.current.dragging.enable();
            mapRef.current.boxZoom.enable();
            mapRef.current.keyboard.enable();
            mapRef.current.scrollWheelZoom.enable();
            drawHandlerRef.current.disable();
        } else {
            mapRef.current.dragging.disable();
            mapRef.current.boxZoom.disable();
            mapRef.current.keyboard.disable();
            mapRef.current.scrollWheelZoom.disable();
            drawHandlerRef.current.enable();
        }

        setIsDrawingMode(!isDrawingMode);
    };

    return (
        <div id="map" className="h-full w-full">
            <div className="relative flex justify-center mb-4">
            <button
                onClick={toggleMode}
                className="absolute top-2 right-2 px-4 py-2 bg-blue text-white rounded z-999999 group"
            >
                <img
                    className="w-5 h-5"
                    src={isDrawingMode ? GrabHand : DrawSquare}
                    alt={isDrawingMode ? "grabhand" : "drawsquare"}
                />
                <span className="absolute w-20 top-full right-1/2 translate-x-1/2 mt-1 px-2 py-1 text-xs text-white bg-black rounded opacity-0 group-hover:opacity-100 transition-opacity">
                    {isDrawingMode ? "Panning Mode" : "Drawing Mode"}
                </span>
            </button>
            </div>
        </div>
    );
}
