import React, { useEffect, useState } from 'react'
import { Question as QuestionType } from './utils/DefaultTemplateData'
import Question from './common/Question'

type Props = {
    handleDataChange: (data: any) => void;
    data: QuestionType;
    optionQuantity: number;
    handleImageOrOptionClicked: (id: string) => void;
}

function TrueFalse({ data, handleDataChange, optionQuantity, handleImageOrOptionClicked }: Props) {

    useEffect(() => {
        console.log("data", data);
      }, [data]);
    

    return (
        <div className="grow overflow-y-scroll">
            <Question type='true/false' optionQuantity={optionQuantity} data={data} handleDataChange={handleDataChange} handleImageOrOptionClicked={handleImageOrOptionClicked} />
        </div>
    )
}

export default TrueFalse