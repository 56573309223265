import React, { Component } from "react";
import ReactDOM from "react-dom";
import { BlockToolConstructorOptions } from "@editorjs/editorjs";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";
import DragAndDrop from "./DragAndDrop";

type Item = {
  id: string;
  content: string;
  droppableId: string;  // droppableId
};

type CompleteDroppableArray = {
  id: string;
  item: Item | null;
  matchId: string | null; // itemId
  text: string;
}[];

type CompleteDroppableObject = {
    items: Item[];
    text: string;
    droppableBoxId: string;
  };

type Data = {
  activeDroppable: {
    items: Item[];
  };
  completeDroppable: CompleteDroppableArray | CompleteDroppableObject
  type: string;
};

type DragAndDropToolProps = {
  data: Data;
  onChange: (data: Data) => void;
};

class DragAndDropToolComponent extends Component<DragAndDropToolProps> {
  render() {
    return (
      <DragAndDrop data={this.props.data} onChange={this.props.onChange} />
    );
  }
}

const isEmptyObject = (obj: Object) =>
  Object.keys(obj).length === 0 && obj.constructor === Object;

class DragAndDropTool {
  private data: Data;
  private api: any;
  private wrapper: HTMLElement | null;

  constructor({ data, api }: BlockToolConstructorOptions) {
    this.data = this.initializeData(data);
    this.api = api;
    this.wrapper = null;
  }

  initializeData(data: Partial<Data>): Data {
    const defaultData: Data = {
      activeDroppable: { items: [] },
      completeDroppable: [],
      type: 'single'
    };

    if (isEmptyObject(data)) {
      return defaultData;
    }

    if (data?.type === 'multiple') {
      return {
        ...defaultData,
        ...data,
        completeDroppable: data.completeDroppable || {items: [], text: '', droppableBoxId: "" },
      };
    }

    return {
      ...defaultData,
      ...data,
      completeDroppable: data.completeDroppable || [],
    };
  }

  render() {
    if (!this.wrapper) {
      this.wrapper = document.createElement("div");
      this.wrapper.classList.add("drag-and-drop-tool-wrapper");
    } else {
      this.wrapper.innerHTML = "";
    }

    this.renderComponent();

    return this.wrapper;
  }

  renderComponent() {
    ReactDOM.render(
      <DragAndDropToolComponent
        data={this.data}
        onChange={this.handleItemsChange.bind(this)}
      />,
      this.wrapper
    );
  }

  handleItemsChange(data: Data) {
    this.data = this.initializeData(data);
    this.renderComponent();
  }

  save() {
    return this.data;
  }

  static get toolbox() {
    return {
      title: "Drag & Drop",
      icon: "<svg>...</svg>", // Add your custom icon SVG here
    };
  }
}

export default DragAndDropTool;
