import React, { useCallback, useEffect, useState } from "react";
import { SelectUser, SelectAircraft, MenuScreen } from "../Screens";
import { Module, setCurrentPath, setModules } from "src/features/trackedModules/trackedModulesSlice";
import { useSelector } from "react-redux";
import { stat } from "fs";
import { useDispatch } from "react-redux";
import { storeTrackingData } from "src/helpers/Tracking";
import { useParams } from "react-router-dom";

type Props = {
  data: any;
  AllTemplateData: any;
  handleChangeBackgroundImage: (imageUrl: string) => void;
  onNext: (templateId: string) => void;
  handleUserAircraftMenu: (user: any, aircraft: any, menu: any) => void;
  active: string;
  selectedUser: any;
  selectedAircraft: any;
};

export function UserAircraftMenu({
  data,
  AllTemplateData,
  handleChangeBackgroundImage,
  onNext,
  handleUserAircraftMenu,
  active,
  selectedUser,
  selectedAircraft,
}: Props) {
  const [userData, setUserData] = useState<Array<any>>([]);
  const [aircraftData, setAircraftData] = useState<Array<any>>([]);
  const [menuData, setMenuData] = useState<Array<any>>([]);

  const [selectedUserState, setSelectedUserState] = useState<any | null>(selectedUser);
  const [selectedAircraftState, setSelectedAircraftState] = useState<any | null>(selectedAircraft);
  const [selectedMenu, setSelectedMenu] = useState<any | null>(null);

  const [activeScreen, setActiveScreen] = useState<string>();

  const { businessUnitId, sepmId } = useParams();
  const { accessToken } = useSelector((state: any) => state.accessToken);
  const { modules, currentPath } = useSelector((state: any) => state.trackedModules);

  const dispatch = useDispatch();

  useEffect(() => {
    console.log(data);
  }, []);

  useEffect(() => {
    setActiveScreen(active)
  }, [active])

  useEffect(() => {
    // const handleupdate = () =>
    handleUserAircraftMenu(selectedUserState, selectedAircraftState, selectedMenu);
    if (selectedMenu) {
      handleNext()
    }
    if (activeScreen === "aircraft") {
      handleChangeBackgroundImage(data.content.aircraftbgFile?.url ?? "")
    }
    if (activeScreen === "menu") {
      console.log(selectedAircraftState)
      handleChangeBackgroundImage(selectedAircraftState.file?.url ?? "")
    }

    // return () => handleupdate();
  }, [selectedUserState, selectedAircraftState, selectedMenu]);

  // useCallback(() => {
  //   handleNext()
  // }, [selectedMenu])

  function handleNext() {
    // const currentId = data._id;
    // const temp = data.templatesId.indexOf(currentId);
    // onNext(data.templatesId[temp + 1]);
    onNext(selectedMenu._id);
  }

  // Function to update session storage
  const updateSessionStorage = (updatedModules: Module[]) => {
    businessUnitId && sepmId && storeTrackingData(updatedModules, businessUnitId, sepmId, accessToken)
    sessionStorage.setItem("modules", JSON.stringify(updatedModules));
  };

  const getModuleByPath = (modulesList: Module[], path: string[]): Module | null => {
    let currentModules = modulesList;
    let targetModule: Module | null = null;

    for (let id of path) {
      targetModule = currentModules.find(module => module.id === id) || null;

      if (!targetModule) return null; // Return null if any module in the path is missing

      currentModules = targetModule.children || []; // Move to the next level
    }

    return targetModule; // Return the final module
  };

  // Function to add children dynamically when clicking a module
  const handleModuleClick = (moduleId: string, childrenArray: Module[]) => {
    console.log("Clicked on module", moduleId, childrenArray);

    const findAndUpdateModule = (modulesList: Module[]): Module[] => {
      return modulesList.map((module: Module) => {
        if (module.id === moduleId) {
          dispatch(setCurrentPath([...currentPath, module.id]))
          // Update the module if found
          return {
            ...module,
            // children: module.children ? [...module.children, ...childrenArray] : childrenArray,
            children: childrenArray,
          };
        } else if (module.children && module.children.length > 0) {
          // Recursively search in children
          return {
            ...module,
            children: findAndUpdateModule(module.children),
          };
        }
        return module;
      });
    };

    const updatedModules = findAndUpdateModule(modules);

    dispatch(setModules(updatedModules));
    updateSessionStorage(updatedModules);
  };

  // Function to handle document opening
  const handleOpenDocument = (docId: string) => {
    // Find moduleId associated with the document
    const module = findModuleByDocumentId(modules, docId);

    if (module) {
      handleLastDepthClick(module.id);
    }
  };

  // Function to find module by document ID (assuming each module has a documentId field)
  const findModuleByDocumentId = (modules: Module[], docId: string): Module | null => {
    for (let module of modules) {
      if (module.id === docId) return module;
      if (module.children) {
        const found = findModuleByDocumentId(module.children, docId);
        if (found) return found;
      }
    }
    return null;
  };

  // Function to mark last depth items as completed and update parents
  const handleLastDepthClick = (moduleId: string) => {
    const updateModulesRecursive = (modules: Module[]): Module[] => {
      return modules.map((module) => {
        if (module.id === moduleId) {
          return { ...module, status: "green" };
        }
        if (module.children) {
          const updatedChildren = updateModulesRecursive(module.children);
          const allGreen = updatedChildren.every((child) => child.status === "green");
          const anyGreen = updatedChildren.some((child) => child.status === "green" || child.status === "orange");

          return {
            ...module,
            children: updatedChildren,
            status: allGreen ? "green" : anyGreen ? "orange" : "gray",
          };
        }
        return module;
      });
    };

    const updatedModules = updateModulesRecursive(modules);

    dispatch(setModules(updatedModules));
    updateSessionStorage(updatedModules);
  };

  return (
    <div className="w-full h-[calc(100%-2.625rem)] md:h-[calc(100%-3.625rem)] lg:h-[calc(100%-4.625rem)] xl:h-[calc(100%-5.25rem)] pt-[1.5rem] md:pt-[1.88rem] pl-[1.7rem] md:pl-[2.5rem] lg:pl-[3.75rem] pr-[1.7rem] md:pr-[2rem]">
      {activeScreen === "user" && <SelectUser
        title={data.content.userTitle}
        data={data.content.users}
        handleSelectUser={(user) => {
          setSelectedUserState(user);
          const storedChildrenData = getModuleByPath(modules, [...currentPath, user.id])?.children
          const childrenArray = storedChildrenData ? storedChildrenData : data.content.aircrafts.map((aircraft: any) => {
            return {
              id: aircraft.id,
              title: aircraft.name,
              status: "gray",
              type: "aircraft",
              elapsedTime: 0
            }
          });
          handleModuleClick(user.id, childrenArray);
          setActiveScreen("aircraft");
        }}
      />}
      {activeScreen === "aircraft" && <SelectAircraft
        title={data.content.aircraftTitle}
        data={data.content.aircrafts}
        handleSelectAircraft={(aircraft) => {
          setSelectedAircraftState(aircraft);
          const storedChildrenData = getModuleByPath(modules, [...currentPath, aircraft.id])?.children
          const childrenArray = storedChildrenData ? storedChildrenData : AllTemplateData.filter((template: any) => template.type !== "splash" && template.type !== "acknowledgement" && template.type !== "multiple user").map((template: any) => {
            return {
              id: template._id,
              title: template.title,
              status: "gray",
              type: "module",
              elapsedTime: 0
            }
          });
          handleModuleClick(aircraft.id, childrenArray);
          setActiveScreen("menu");
        }}
      />}
      {activeScreen === "menu" && <MenuScreen
        data={AllTemplateData}
        selectedAircraft={selectedAircraftState}
        selectedUser={selectedUser}
        handleSelectMenu={(menu) => {
          
          if (menu.type === "location diagram" || menu.type === "modal") {
            handleOpenDocument(menu._id);
          }
          else {
            const storedChildrenData = getModuleByPath(modules, [...currentPath, menu._id])?.children
            const childrenArray = storedChildrenData ? storedChildrenData : menu.type === "emergency" ? menu.content.catagories.map((category: any) => {
              return {
                id: category.id,
                title: category.name,
                status: "gray",
                type: "module",
                elapsedTime: 0
              }
            }) : menu.content.documents.filter((doc: any) => {
              if (doc.selectedOptions && doc.selectedOptions.length === 0) return true;
              const isUserDoc = doc.selectedOptions && doc.selectedOptions.some((option: any) => option.id === selectedUserState.id);
              const isAircraftDoc = doc.aircraftId === selectedAircraftState.id;
              return (isUserDoc && isAircraftDoc);
            }).map((doc: any) => {
              return {
                id: doc._id,
                title: doc.title,
                status: "gray",
                type: "module",
                elapsedTime: 0
              }
            });
            handleModuleClick(menu._id, childrenArray);
          }
          setSelectedMenu(menu);
        }}
      />}
    </div>
  );
}
