import React, { useState, useEffect } from "react";
import InputText from "src/utils/TextInput/InputText";
import UploadButton from "src/utils/UploadButton";
import UploadDropdowns from "../UploadDropdowns";
import { Switch } from "../ui/switch";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../ui/select";
import { set } from "lodash";

type Props = {
  state?: any;
  openCreateData?: any;
  input: string;
  onChangeInput: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onHandleAdd: (isVariant: boolean, selectedAircraft: string | undefined) => void;
  aircrafts: any;
};

function AddAircrafts({
  state,
  openCreateData,
  input,
  onChangeInput,
  onHandleAdd,
  aircrafts
}: Props) {
  const [file, setFile] = useState<any>(null);
  const [isVariant, setIsVariant] = useState(false);
  const [selectedAircraft, setSelectedAircraft] = useState<string | undefined>(undefined);

  useEffect(() => {

    if (state) {
      if (state.file) setFile(state.file);
    }
  }, [input, state]);

  useEffect(() => {
    console.log("aircraft: ", aircrafts)
  }, [aircrafts])

  return (
    <div className="flex flex-col h-full px-[0.49rem] ">
      <div className="grow space-y-4 mt-2">
        <div>
          <InputText
            value={input}
            onChange={(e) => onChangeInput(e)}
            placeholder="Enter Aircraft Name"
            labelTitle="Aircraft Name"
          />
        </div>
        <div className="flex flex-col space-y-[1.125rem] p-[0.49rem]">
          <div className="text-[1rem] font-[600]">Aircraft's Image</div>
          <div className="flex space-x-1 items-center">
            <UploadButton
              type={"image"}
              pageState={{
                ...state,
                uploadButtonFor: "",
                input: input,
                openCreateData: openCreateData,
              }}
              handleUploadFromDevice={(file) => {
                setFile(file);
              }}
            />
            <UploadDropdowns
              type={"image"}
              pageState={{
                ...state,
                uploadButtonFor: "",
                input: input,
                openCreateData: openCreateData,
              }}
              handleCropFile={(cropFile) => setFile(cropFile)}
              openCreateModal={openCreateData}
              File={file}
            />
          </div>
        </div>
        <div>
          <div className="flex space-x-1 items-center">
            <div>Aircraft Variant: </div>
            <div>
              <Switch
                checked={isVariant}
                onCheckedChange={() =>
                  setIsVariant((prev) => {
                    return !prev;
                  })
                }
              />
            </div>
          </div>
          {isVariant && <div className="flex justify-between items-center">
            <div className="text-left w-full text-sm">
              Variant
            </div>
            <Select
              value={selectedAircraft}
              onValueChange={(value: string) => setSelectedAircraft(value)}
            >
              <SelectTrigger className="w-[8rem] h-[2rem] px-1 text-sm">
                <SelectValue
                  placeholder="Select Aircraft"
                >
                  {aircrafts?.find((item: any) => item.id === selectedAircraft)?.name}
                </SelectValue>
              </SelectTrigger>
              <SelectContent className="z-[99999]">
                {aircrafts?.map((item: any, index: number) => (
                  <SelectItem key={index} value={item.id}>
                    {item.name}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>}
        </div>
      </div>
      <div className="border-t border-[#2C2C2C] px-[0.49rem] py-5 flex justify-end items-center">
        <button
          onClick={() => onHandleAdd(isVariant, selectedAircraft)}
          className="px-7 py-3 text-[1rem] text-[#FFFFFF] bg-[#AB0273] rounded-[1.875rem]"
        >
          Done
        </button>
      </div>
    </div>
  );
}

export default AddAircrafts;
