// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
  
  .ChatUserMessage p{
    background-color: rgb(171 2 115);
    max-width: 70%;
    padding: 15px;
    border-radius: 50px;
    margin-top: 2%;
  }
  
  .ChatUserMessage p span{
    margin: 5px;
  }
  
  .ChatUserMessage p span:first-child{
    margin-right: 0;
  }
  
  .user_msg{
    text-align: right;
    margin-left: 30%;
    display: flex;
    flex-direction: row-reverse;
  }
  
  .chatTypinghide {
    visibility: hidden;
    display: none;
  }
  .chatTypingshow {
  
    color: rgb(171 2 115);
  }
  
  .formChatUserMessage{
    text-align: center;
    position: sticky;
    bottom: 0;
  }
  
  .formChatUserMessage input{
    width: 100%;
    height: 40px;
    border: none;
    padding: 10px;
    font-size: 1.2rem;
    background-color: rgb(28, 23, 23);
    border-radius: 40px;
    margin-top: 2px;
  }
  
  .formChatUserMessage input:focus{
    outline: none;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/ChatGPT/ChatComponent.css"],"names":[],"mappings":";;EAEE;IACE,gCAAgC;IAChC,cAAc;IACd,aAAa;IACb,mBAAmB;IACnB,cAAc;EAChB;;EAEA;IACE,WAAW;EACb;;EAEA;IACE,eAAe;EACjB;;EAEA;IACE,iBAAiB;IACjB,gBAAgB;IAChB,aAAa;IACb,2BAA2B;EAC7B;;EAEA;IACE,kBAAkB;IAClB,aAAa;EACf;EACA;;IAEE,qBAAqB;EACvB;;EAEA;IACE,kBAAkB;IAClB,gBAAgB;IAChB,SAAS;EACX;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,YAAY;IACZ,aAAa;IACb,iBAAiB;IACjB,iCAAiC;IACjC,mBAAmB;IACnB,eAAe;EACjB;;EAEA;IACE,aAAa;EACf","sourcesContent":["\n  \n  .ChatUserMessage p{\n    background-color: rgb(171 2 115);\n    max-width: 70%;\n    padding: 15px;\n    border-radius: 50px;\n    margin-top: 2%;\n  }\n  \n  .ChatUserMessage p span{\n    margin: 5px;\n  }\n  \n  .ChatUserMessage p span:first-child{\n    margin-right: 0;\n  }\n  \n  .user_msg{\n    text-align: right;\n    margin-left: 30%;\n    display: flex;\n    flex-direction: row-reverse;\n  }\n  \n  .chatTypinghide {\n    visibility: hidden;\n    display: none;\n  }\n  .chatTypingshow {\n  \n    color: rgb(171 2 115);\n  }\n  \n  .formChatUserMessage{\n    text-align: center;\n    position: sticky;\n    bottom: 0;\n  }\n  \n  .formChatUserMessage input{\n    width: 100%;\n    height: 40px;\n    border: none;\n    padding: 10px;\n    font-size: 1.2rem;\n    background-color: rgb(28, 23, 23);\n    border-radius: 40px;\n    margin-top: 2px;\n  }\n  \n  .formChatUserMessage input:focus{\n    outline: none;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
