import React, { useState } from "react";
import Nav from "../Navbar";
import Navigation from "./Navigation";
import ProgramNav from "src/utils/ProgramNav";
import PreviewLogo from "../../assets/TemplateNavbar/Play.svg";
import MediaLogo from "../../assets/TemplateNavbar/Image.svg";
import SettingLogo from "../../assets/TemplateNavbar/GearSix.svg";
import ShareLogo from "../../assets/TemplateNavbar/Share.svg";
import ExportLogo from "../../assets/TemplateNavbar/UploadSimple.svg";
import { useParams, useSearchParams } from "react-router-dom";
import PreviewModal from "src/utils/PreviewModal";
import { useSelector } from "react-redux";
import { Device } from "../Preview/Layout";
import Navbar from "../Preview/Navbar";
import { cn } from "./../lib/utils";
import { cva } from "class-variance-authority";
import Preview from "../Preview";
import SettingsModal from "src/utils/SettingsModal";
import UploadButton from "src/utils/UploadButton";
import UploadDropdowns from "../UploadDropdowns";
import { SketchPicker } from "react-color";
import axios from "axios";

type Props = {
  children: JSX.Element;
  topicContent: string;
  handleTopic: (topic: any) => void;
};

type ButtonType = {
  name: string;
  buttonIcon: React.ReactNode;
  navigate: boolean;
  navigateUrl?: string;
};

const deviceVariants = cva("grow flex flex-col", {
  variants: {
    device: {
      default: "max-h-[64rem] h-full max-w-[90rem] w-full",
      laptop: "max-h-[64rem] h-full max-w-[90rem] w-full",
      tabPortrait:
        "max-h-[62rem] h-full max-w-[50rem] w-screen border-[12px] border-gray-300 rounded-lg",
      tabLandscape:
        "max-h-[50rem] h-full max-w-[62rem] w-screen border-[12px] border-gray-300 rounded-lg",
      mobilePortrait:
        "max-h-[50rem] h-full max-w-[35rem] w-screen border-[12px] border-gray-300 rounded-lg",
      mobileLandscape:
        "max-h-[35rem] h-full max-w-[50rem] w-screen border-[12px] border-gray-300 rounded-lg",
    },
  },
  defaultVariants: {
    device: "default",
  },
});

function Layout({ children, topicContent, handleTopic }: Props) {
  const [page, setPage] = useState<any>();
  const [openPreviewModal, setOpenPreviewModal] = useState(false);
  const [openSettingsModal, setOpenSettingsModal] = useState(false);
  const [currentColor, setCurrentColor] = useState<any>("#AB0273");
  const [headingFont, setHeadingFont] = useState<any>("");
  const [bodyFont, setBodyFont] = useState<any>("");
  const [logoFile, setLogoFile] = useState<any>(null);
  const [openColorPalette, setOpenColorPalette] = useState(false);
  const [uploadingFiles, setUploadingFiles] = useState<any>([]);
  const [url, setUrl] = useState<any>("");
  const { accessToken } = useSelector((state: any) => state.accessToken);
  const [deviceVariantState, setDeviceVariantState] =
    useState<Device>("default");
  const { businessUnitId, pageId, sepmId, templateId, userId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const programButtonsArr: ButtonType[] = [
    {
      name: "Media Library",
      buttonIcon: <img src={MediaLogo} alt="Midea Library" />,
      navigate: true,
      navigateUrl: `/user/${userId}/businessunit/${businessUnitId}/page/${pageId}/media`,
    },
    {
      name: "Share",
      buttonIcon: <img src={ShareLogo} alt="Share" />,
      navigate: true,
      navigateUrl: ``,
    },
    {
      name: "Preview",
      buttonIcon: <img src={PreviewLogo} alt="Publish" />,
      navigate: false,
    },
    {
      name: "Publish",
      buttonIcon: <img src={ExportLogo} alt="Publish" />,
      navigate: true,
      navigateUrl: ``,
    },
    {
      name: "Settings",
      buttonIcon: <img src={SettingLogo} alt="Settings" />,
      navigate: false,
    },
  ];

  // async function handleProgramSetting() {
  //   await axios
  //     .post(
  //       `${process.env.REACT_APP_SERVER_BASE_URL}/api/v1/program/programsettings/${sepmId}`,
  //       {
  //         file: logoFile,
  //         color: currentColor,
  //         headingFont,
  //         bodyFont,
  //       },
  //       {
  //         headers: { Authorization: `Bearer ${accessToken}` },
  //       }
  //     )
  //     .then((res) => res.data)
  //     .then((response) => {
  //       console.log(response.data);
  //       setProgram(response.data);
  //     })
  //     .catch((err) => {
  //       console.error(err);
  //     });

  //   setOpenSettingsModal(false);
  // }

  function handleOnColorChange(color: any) {
    console.log(color.hex);
    setCurrentColor(color.hex);
  }

  return (
    <main className="relative w-full max-w-[90rem] h-screen max-h-[64rem] mx-auto overflow-hidden font-poppins">
      <Nav type="Page" />
      <ProgramNav type="Page" buttonsArray={programButtonsArr} handlePage={(page) => setPage(page)} />
      <section className="relative w-full h-[calc(100%-5.625rem)] max-w-[90rem] flex bg-[#f6f6f6]">
        <Navigation
          topicContent={topicContent}
          handleTopic={(topic) => handleTopic(topic)}
        />
        {children}
        {/* {props.type === "courses" && <RightPanel />} */}
      </section>

      <PreviewModal
        onOpened={openPreviewModal}
        onClose={() => setOpenPreviewModal(false)}
        showCrossIcon={false}
      >
        <div className="flex flex-col items-center w-full max-w-[90rem] mx-auto h-full overflow-hidden">
          <Navbar
            onHandleDevice={(device) => setDeviceVariantState(device)}
            programName={page?.title}
            handleClose={() => setOpenPreviewModal(false)}
          />
          <div className="grow w-full flex justify-center max-h-[60.5rem] h-[calc(100vh-3.5rem)] ">
            {deviceVariantState !== "default" &&
            deviceVariantState !== "laptop" ? (
              <iframe
                className={cn(deviceVariants({ device: deviceVariantState }))}
                title="preview section"
                src={`${process.env.REACT_APP_CLIENT_BASE_URL}/user/${userId}/businessunit/${businessUnitId}/sepm/${sepmId}/preview`}
              ></iframe>
            ) : (
              <Preview device={deviceVariantState} />
            )}
          </div>
        </div>
      </PreviewModal>

      <SettingsModal
        onOpened={openSettingsModal}
        onClose={() => setOpenSettingsModal(false)}
        showCrossIcon={true}
      >
        <div className="flex flex-col w-full max-w-[90rem] h-screen overflow-hidden ">
          <span className="m-4 font-semibold text-[#4e4e4e]">
            Course Settings
          </span>
          <div className="h-[0.0625rem] w-full bg-gray-300"></div>
          <div className="w-[70%] h-full flex flex-col mx-auto items-center ">
            <span className="w-full flex justify-start font-semibold mt-8 mb-4 px-2 text-[1.5rem]">
              {page?.title}
            </span>
            <div className="grid grid-cols-2 w-full justify-start font-normal my-4 px-2">
              <span className="col-span-1">Logo</span>
              <span className="col-span-1 flex items-start space-x-5">
                <UploadButton
                  name="LogoImageFile"
                  type="image"
                  pageState={{
                    // ...state,
                    openSettingsModal,
                    uploadButtonFor: "LogoImageFile",
                    logoFile,
                    currentColor,
                    headingFont,
                    bodyFont,
                  }}
                  handleUploadFromDevice={(file) => {
                    setLogoFile(file);
                    console.log(file);
                  }}
                />
                <UploadDropdowns
                  name="LogoImageFile"
                  type="image"
                  pageState={{
                    // ...state,
                    openSettingsModal,
                    uploadButtonFor: "LogoImageFile",
                    logoFile,
                    currentColor,
                    headingFont,
                    bodyFont,
                  }}
                  handleCropFile={(cropFile) => setLogoFile(cropFile)}
                  File={logoFile}
                />
              </span>
            </div>
            <span className="w-full flex justify-start my-2 px-2 pb-2 text-[1.2rem] border-b border-gray-300">
              Theme
            </span>
            <div className="w-full flex flex-col space-y-5 px-2 my-4">
              <div className="grid grid-cols-2 w-full justify-start font-normal ">
                <div className="col-span-1">Theme color</div>
                <div className="col-span-1 relative flex items-center justify-start space-x-5">
                  <div
                    style={{ backgroundColor: currentColor }}
                    className={`flex w-10 h-10 ${
                      !currentColor && "bg-tertiary"
                    }`}
                  ></div>
                  <button
                    onClick={() => setOpenColorPalette(!openColorPalette)}
                  >
                    +
                  </button>
                  {openColorPalette && (
                    <SketchPicker
                      color={currentColor}
                      onChangeComplete={handleOnColorChange}
                      className="absolute top-0 left-[80px]"
                    />
                  )}
                  <button
                    onClick={() => setCurrentColor("")}
                    className=" bg-tertiary p-2 rounded-lg text-[#FFFFFF] "
                  >
                    default theme color
                  </button>
                </div>
              </div>
              <div className="grid grid-cols-2 w-full justify-start font-normal ">
                <div className="col-span-1 font-">
                  <label htmlFor="heading-font">Heading Font</label>
                </div>
                <div className="col-span-1 flex items-start space-x-5">
                  <select
                    id="heading-font"
                    name="heading-font"
                    value={headingFont}
                    onChange={(e) => setHeadingFont(e.target.value)}
                  >
                    <option value="">Select Font</option>
                    <option value="inter">Inter</option>
                    <option value="sans-serif">sans-serif</option>
                    <option value="poppins">Poppins</option>
                    <option value="mono">Mono</option>
                  </select>
                  <div className="flex items-center space-x-2">
                    <span>default font:</span>
                    <button
                      onClick={() => setHeadingFont("poppins")}
                      className="p-2 border rounded-lg"
                    >
                      poppins
                    </button>
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-2 w-full justify-start font-normal font">
                <div className="col-span-1">
                  <label htmlFor="body-font">Body Text Font</label>
                </div>
                <div className="col-span-1 flex items-start space-x-5">
                  <select
                    id="body-font"
                    name="body-font"
                    value={bodyFont}
                    onChange={(e) => setBodyFont(e.target.value)}
                  >
                    <option value="">Select Font</option>
                    <option value="inter">Inter</option>
                    <option value="sans-serif">sans-serif</option>
                    <option value="poppins">Poppins</option>
                    <option value="mono">Mono</option>
                  </select>
                  <div className="flex items-center space-x-2">
                    <span>default font:</span>
                    <button
                      onClick={() => setBodyFont("poppins")}
                      className="p-2 border rounded-lg"
                    >
                      poppins
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <button
              // onClick={() => handleProgramSetting()}
              className="w-full flex justify-end font-semibold mt-8 mb-4 px-2 "
            >
              <span className="text-[1.2rem] text-[#FFFFFF] bg-[#AB0273] hover:bg-[#AB0273]/80 px-4 py-2 rounded-md">
                Save
              </span>
            </button>
          </div>
        </div>
      </SettingsModal>
    </main>
  );
}

export default Layout;
