import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import thunk from "redux-thunk";

import userReducer from "../features/user/userSlice";
import wigetReducer from "../features/widget/widgetSlice";
import blockReducer from "../features/blocks/bockSlice";
import sidebarReducer from "../features/Sidebar/sidebarSlice";
import editorStateReducer from "../features/editorState/editorStateSlice";
import tokenReducer from "../features/token/tokenSlice"
import tokenExpireReducer from "../features/token/tokenSliceExpire"
import businessUnitReducer from "../features/businessUnits/businessUnitSlice"
import programTemplateReducer from "../features/programTemplates/programTemplatesSlice"
import activeSidebarReducer from "../features/activeSidebar/activeSidebarSlice"
import saveChangesReducer from "../features/saveChanges/saveChangesSlice"
import catalogueCoursesReducer from "../features/catalogueCourses/catalogueCoursesSlice"
import fileFolderReducer from "../features/fileFolder/fileFolderSlice"
import errorLogReducer from "../features/errorLog/errorLogSlice"
import templatePageStateReducer from "../features/templatePageState/templatePageStateSlice"
import loadingStateReducer from "../features/loadingState/loadingStateSlice"
import programSettingsReducer from "../features/programSettings/programSettingsSlice"
import courseTemplateReducer from "../features/courseTemplate/courseTemplateSlice"
import componentPropertiesReducer from "../features/ComponentProperty/componentPeopertySlice"
import canvasActiveComponentReducer from "../features/canvasActiveComponent/canvasActiveComponent"
import selectedTabReducer from "../features/selectedTab/selectedTabSlice";
import selectedCardReducer from "../features/selectedCard/selectedCardSlice";
import selectedAccordionReducer from "../features/selectedAccordion/selectedAccordionSlice";
import selectedFlipcardReducer from "../features/selectedFlipcard/selectedFlipcardSlice";
import isAddingHotspotReducer from "../features/isAddingHotspot/isAddingHotspotSlice";
import selectedHotspotReducer from "../features/selectedHotspot/selectedHotspotSlice";
import templateAudioReducer from "src/features/templateAudio/templateAudioSlice";
import lmsLearnerDataReducer from "src/features/lmsLearnerData/lmsLearnerDataSlice";
import trackedModulesReducer from "src/features/trackedModules/trackedModulesSlice";

const reducers = combineReducers({
  user: userReducer,
  widget: wigetReducer,
  block: blockReducer,
  sidebar: sidebarReducer,
  editorState: editorStateReducer,
  accessToken: tokenReducer,
  tokenExpired: tokenExpireReducer,
  businessUnits: businessUnitReducer,
  programTemplates: programTemplateReducer,
  activeSidebar: activeSidebarReducer,
  saveChanges: saveChangesReducer,
  catalogueCourses: catalogueCoursesReducer,
  fileFolder: fileFolderReducer,
  errorLog: errorLogReducer,
  templatePageState: templatePageStateReducer,
  loadingState: loadingStateReducer,
  programSettings: programSettingsReducer,
  courseTemplate: courseTemplateReducer,
  componentProperties: componentPropertiesReducer,
  canvasActiveComponent: canvasActiveComponentReducer,
  selectedTab: selectedTabReducer,
  selectedCard: selectedCardReducer,
  selectedAccordion: selectedAccordionReducer,
  selectedFlipcard: selectedFlipcardReducer,
  isAddingHotspot: isAddingHotspotReducer,
  selectedHotspot: selectedHotspotReducer,
  templateAudio: templateAudioReducer,
  lmsLearnerData: lmsLearnerDataReducer,
  trackedModules: trackedModulesReducer,
});

export type StateType = ReturnType<typeof reducers>;

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['lmsLearnerData'],
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: [thunk],
});

export default store;
