import React, { Suspense, useEffect, useState, useRef } from "react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";
import { useSelector } from "react-redux";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../ui/tabs";
import Layout1 from "../../assets/Course/textwithimage/Layout1.png";
import Layout2 from "../../assets/Course/textwithimage/Layout2.png";
import Layout3 from "../../assets/Course/textwithimage/Layout3.png";
import { Input } from "../ui/input";
import Layout4 from "../../assets/Course/textwithimage/Layout4.png";
import { Separator } from "../ui/separator";
import { Slider } from "../ui/slider";
import { cn } from "../lib/utils";
import RichTextEditor from "src/utils/RichTE";
import UploadImage from "./UploadImage/UploadImage";
import { setSelectedComponent } from "src/features/ComponentProperty/componentPeopertySlice";
import { useDispatch } from "react-redux";
import { setSelectedCard } from "src/features/selectedCard/selectedCardSlice";
import { DefaultTemplateData } from "./Templates/Authoring/utils/DefaultTemplateData";
import { v4 as uuid } from "uuid";
import { CircleSlash } from "lucide-react";
import Text from "src/utils/Text/Txt";
import { Editor } from "@tinymce/tinymce-react";
import { Editor as TinyMCEEditor } from "tinymce";
import ImageLeft from "../../assets/Course/clicktoreveal/leftImage.png";
import ImageRight from "../../assets/Course/clicktoreveal/rightImage.png";
import SimpleText from "../../assets/Course/clicktoreveal/simpleText.png";
import BackimageLeft from "../../assets/Course/clicktoreveal/backLeftImage.png";
import BackimageRight from "../../assets/Course/clicktoreveal/backRightImage.png";
import BackSimpleText from "../../assets/Course/clicktoreveal/backSimpleText.png";
import FrontSmallSimple from "../../assets/Course/clicktoreveal/frontSmallSimple.png";
import BackSmallSimple from "../../assets/Course/clicktoreveal/backSmallSimple.png";
import FrontSmallWithImage from "../../assets/Course/clicktoreveal/frontSmallWithImage.png";
import BackSmallWithImage from "../../assets/Course/clicktoreveal/backSmallWithImage.png";
import { setCanvasActiveComponent } from "src/features/canvasActiveComponent/canvasActiveComponent";
import { LayoutStyles } from "./LayoutSettings";

const Scr = React.lazy(() => import("./StyleSettings/Templates/Scr"));
const Mcr = React.lazy(() => import("./StyleSettings/Templates/Mcr"));
const TrueFalse = React.lazy(
  () => import("./StyleSettings/Templates/TrueFalse")
);
const TextWImage = React.lazy(
  () => import("./StyleSettings/Templates/TextWImage")
);
const Tab = React.lazy(() => import("./StyleSettings/Templates/Tabs"));

type SettingProps = {
  templateData: any;
  handleChange: (templateData: any) => void;
};

const Settings = ({ templateData, handleChange }: SettingProps) => {
  const { canvasActiveComponent } = useSelector(
    (state: any) => state.canvasActiveComponent
  );
  const [transitionState, setTransitionState] = useState<string>("front");
  const { selectedCard } = useSelector((state: any) => state.selectedCard);
  const { selectedTab } = useSelector((state: any) => state.selectedTab);

  const dispatch = useDispatch();

  const editorRef = useRef<TinyMCEEditor | null>(null);
  const log = () => {
    if (editorRef.current) {
      console.log(editorRef.current.getContent());
    }
  };

  useEffect(() => {
    console.log(canvasActiveComponent, selectedCard);
  }, [canvasActiveComponent, selectedCard]);
  console.log(templateData);

  useEffect(() => {
    console.log("templateData", templateData);
  }, [templateData]);

  useEffect(() => {
    console.log(selectedCard);
    dispatch(setSelectedCard("front"));
  }, []);

  useEffect(() => {
    console.log(selectedCard);
  }, [selectedCard]);

  async function handleUpdateTemplateData(key: string, value: any) {
    const keys = key.split(".");
    let updatedData = { ...templateData.data }; // Shallow copy of the top-level object

    let current = updatedData;

    for (let i = 0; i < keys.length - 1; i++) {
      const keyPart = keys[i];
      const arrayIndexMatch = keyPart.match(/^(\w+)\[(\d+)\]$/);

      if (arrayIndexMatch) {
        const arrayKey = arrayIndexMatch[1];
        const arrayIndex = parseInt(arrayIndexMatch[2], 10);

        if (!Array.isArray(current[arrayKey])) {
          console.error(
            `Key path ${keys
              .slice(0, i + 1)
              .join(".")} does not exist as an array in templateData`
          );
          return;
        }

        current[arrayKey] = [...current[arrayKey]]; // Shallow copy of the array
        current = current[arrayKey][arrayIndex] = {
          ...current[arrayKey][arrayIndex],
        }; // Shallow copy of the array element
      } else {
        if (current[keyPart] === undefined) {
          console.error(
            `Key path ${keys
              .slice(0, i + 1)
              .join(".")} does not exist in templateData`
          );
          return;
        }

        current[keyPart] = { ...current[keyPart] }; // Shallow copy of the nested object
        console.log(keyPart);
        if (keys[i + 1] === "layoutOption") {
          console.log(LayoutStyles.get(value));
          current[keyPart].styles = LayoutStyles.get(value);
        }
        current = current[keyPart];
      }
    }

    current[keys[keys.length - 1]] = value;

    console.log("Updated Key:", key);
    console.log("Updated Value:", value);
    console.log("Updated Data:", updatedData);

    handleChange({
      ...templateData,
      data: updatedData,
    });
  }

  return (
    <div className="sidebar bg-[#EFEFEF] border-l border-gray-300 relative flex w-[20rem] flex-col overflow-y-scroll">
      <Tabs defaultValue="content" className="">
        <TabsList>
          {(templateData.type === "textwithimage" ||
            templateData.type === "clicktoreveal" || selectedTab?.content?.templateType === "textwithimage") && (
              <TabsTrigger value="content">Content</TabsTrigger>
            )}
          <TabsTrigger value="style">Style</TabsTrigger>
          <TabsTrigger value="layout">Layout</TabsTrigger>
        </TabsList>

        {templateData.type === "scr" && (
          <>
            <TabsContent value="style">
              <Suspense fallback={<div>Loading...</div>}>
                <Scr
                  templateData={templateData}
                  handleUpdateTemplateData={handleUpdateTemplateData}
                  canvasActiveComponent={canvasActiveComponent}
                />
              </Suspense>
            </TabsContent>
            <TabsContent value="layout" className="space-y-3"></TabsContent>
          </>
        )}

        {templateData.type === "mcr" && (
          <>
            <TabsContent value="style">
              <Suspense fallback={<div>Loading...</div>}>
                <Mcr
                  templateData={templateData}
                  handleUpdateTemplateData={handleUpdateTemplateData}
                  canvasActiveComponent={canvasActiveComponent}
                />
              </Suspense>
            </TabsContent>
            <TabsContent value="layout" className="space-y-3"></TabsContent>
          </>
        )}

        {templateData.type === "true/false" && (
          <>
            <TabsContent value="style">
              <Suspense fallback={<div>Loading...</div>}>
                <TrueFalse
                  templateData={templateData}
                  handleUpdateTemplateData={handleUpdateTemplateData}
                  canvasActiveComponent={canvasActiveComponent}
                />
              </Suspense>
            </TabsContent>
            <TabsContent value="layout" className="space-y-3"></TabsContent>
          </>
        )}
        {templateData.type === "textwithimage" && (
          <>
            <TabsContent value="content">
              <div className="flex flex-grow">
                <div className="space-y-6 w-full">
                  <div className="flex flex-col">
                    <div className="pt-[1.25rem] px-[1.63rem] flex flex-col items-start space-y-4">
                      <div className="flex items-start space-x-2 font-[500] text-[1.125rem] leading-normal text-[#242424]">
                        <span>Logo</span>
                        <span className="text-[1rem] text-gray-400">
                          (optional)
                        </span>
                        <span>
                          <button
                            onClick={() => {
                              const newValue = !templateData.data?.haveImage;

                              let updatedData = { ...templateData.data };
                              if (newValue) {
                                updatedData = {
                                  ...templateData.data,
                                  haveImage: newValue,
                                };
                              } else {
                                const { logoImageFile, ...rest } =
                                  templateData.data;
                                updatedData = { ...rest, haveImage: newValue };
                              }

                              console.log(updatedData);
                              handleChange({
                                ...templateData,
                                data: updatedData,
                              });
                            }}
                            className={`relative col-span-1 w-[42px] h-[24px] rounded-full cursor-pointer transition-colors ${templateData.data?.haveImage
                              ? "bg-[#5934ff]"
                              : "bg-gray-400"
                              }`}
                          >
                            <div
                              className={`absolute top-1 left-1 w-[16px] h-[16px] bg-white rounded-full transition-transform ${templateData.data?.haveImage
                                ? "transform translate-x-[18px]"
                                : ""
                                }`}
                            ></div>
                          </button>
                        </span>
                      </div>
                      <div
                        onClick={() => {
                          const existingComponent =
                            templateData.data?.componentProperties?.find(
                              (comp: any) => comp.type === "Image"
                            );
                          if (existingComponent) {
                            dispatch(
                              setSelectedComponent({
                                type: existingComponent.type,
                                maxLength: existingComponent.maxLength,
                                zone: existingComponent.zone,
                              })
                            );
                          }
                        }}
                        className="rounded-md border-2 border-gray-300"
                      >
                        {templateData.data?.haveImage && (
                          <div className="flex space-x-5 items-start">
                            <UploadImage
                              name="logoImageFile"
                              type="image"
                              image={templateData.data?.logoImageFile}
                              handleUploadFromDevice={(file) => {
                                console.log(file);
                                handleChange({
                                  ...templateData,
                                  data: {
                                    ...templateData.data,
                                    logoImageFile: file,
                                  },
                                });
                              }}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="pt-[1.25rem] px-[1.63rem] flex flex-col items-start space-y-4 w-full">
                      <div className="flex items-start space-x-2 font-[500] text-[1.125rem] leading-normal text-[#242424]">
                        <span>Main Image</span>
                      </div>
                      <div
                        onClick={() => {
                          const existingComponent =
                            templateData.data?.componentProperties?.find(
                              (comp: any) => comp.type === "Image"
                            );
                          if (existingComponent) {
                            dispatch(
                              setSelectedComponent({
                                type: existingComponent.type,
                                maxLength: existingComponent.maxLength,
                                zone: existingComponent.zone,
                              })
                            );
                          }
                        }}
                        className="rounded-md border-2 border-gray-300"
                      >
                        <div className="flex space-x-5 items-start">
                          <UploadImage
                            name="imageFile"
                            type="image"
                            image={templateData.data?.imageFile}
                            handleUploadFromDevice={(file) => {
                              console.log(file);
                              handleChange({
                                ...templateData,
                                data: {
                                  ...templateData.data,
                                  imageFile: file,
                                },
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="w-full">
                    {/* <RichTextEditor
                        placeholder="Enter Acknowledgement Text"
                        value={templateData.data.text}
                        onChange={(content, contentLength) => {
                          handleChange({
                            ...templateData,
                            data: {
                              ...templateData.data,
                              text: content,
                            }
                          });
                        }}
                      /> */}

                    <Editor
                      apiKey="0ddx1tmh6sfvjtjv7n8cwkxut9errgx0zbq4yfsqo7750vb5"
                      onInit={(_evt, editor) => (editorRef.current = editor)}
                      initialValue={templateData.data.text}
                      init={{
                        height: 500,
                        menubar: false,
                        plugins: [
                          "advlist",
                          "autolink",
                          "lists",
                          "link",
                          "image",
                          "charmap",
                          "preview",
                          "anchor",
                          "searchreplace",
                          "visualblocks",
                          "code",
                          "fullscreen",
                          "insertdatetime",
                          "media",
                          "table",
                          "code",
                          "help",
                          "wordcount",
                        ],
                        toolbar:
                          "undo redo | blocks | " +
                          "bold italic forecolor | alignleft aligncenter " +
                          "alignright alignjustify | bullist numlist outdent indent | " +
                          "removeformat | help",
                        content_style:
                          "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                      }}
                      onChange={(e) => {
                        handleChange({
                          ...templateData,
                          data: {
                            ...templateData.data,
                            text: e.target.getContent(),
                          },
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
            </TabsContent>
            <TabsContent value="style">
              <Suspense fallback={<div>Loading...</div>}>
                <TextWImage
                  templateData={templateData}
                  handleUpdateTemplateData={handleUpdateTemplateData}
                  canvasActiveComponent={canvasActiveComponent}
                />
              </Suspense>
            </TabsContent>
            <TabsContent value="layout" className="space-y-3">
              <div
                onClick={() =>
                  handleUpdateTemplateData(
                    "layoutOption",
                    "text-left-image-right"
                  )
                }
                className="h-[6rem] w-full flex justify-center items-center"
              >
                <img
                  className={`h-full aspect-auto ${templateData.data.layoutOption ===
                    "text-left-image-right" &&
                    "border-blue border-[3px] rounded-md"
                    }`}
                  src={Layout1}
                  alt="image"
                />
              </div>
              <div
                onClick={() =>
                  handleUpdateTemplateData(
                    "layoutOption",
                    "text-right-image-left"
                  )
                }
                className="h-[6rem] w-full flex justify-center items-center"
              >
                <img
                  className={`h-full aspect-auto ${templateData.data.layoutOption ===
                    "text-right-image-left" &&
                    "border-blue border-[3px] rounded-md"
                    }`}
                  src={Layout4}
                  alt="image"
                />
              </div>
              <div
                onClick={() =>
                  handleUpdateTemplateData("layoutOption", "only-text")
                }
                className="h-[6rem] w-full flex justify-center items-center"
              >
                <img
                  className={`h-full aspect-auto ${templateData.data.layoutOption === "only-text" &&
                    "border-blue border-[3px] rounded-md"
                    }`}
                  src={Layout2}
                  alt="image"
                />
              </div>
              <div
                onClick={() =>
                  handleUpdateTemplateData(
                    "layoutOption",
                    "text-image-centered"
                  )
                }
                className="h-[6rem] w-full flex justify-center items-center"
              >
                <img
                  className={`h-full aspect-auto ${templateData.data.layoutOption === "text-image-centered" &&
                    "border-blue border-[3px] rounded-md"
                    }`}
                  src={Layout3}
                  alt="image"
                />
              </div>
            </TabsContent>
          </>
        )}

        {templateData.type === "tabs" && (
          <>
            {
              selectedTab?.content?.templateType === "textwithimage" && (
                <TabsContent value="content">
                  <div className="flex flex-grow">
                    <div className="space-y-6 w-full">
                      <div className="flex flex-col">
                        <div className="pt-[1.25rem] px-[1.63rem] flex flex-col items-start space-y-4">
                          <div className="flex items-start space-x-2 font-[500] text-[1.125rem] leading-normal text-[#242424]">
                            <span>Logo</span>
                            <span className="text-[1rem] text-gray-400">
                              (optional)
                            </span>
                            <span>
                              <button
                                onClick={() => {
                                  const newValue = !templateData.data.tabs[selectedTab?.index]?.content.templateData.data?.haveImage;

                                  let updatedData = { ...templateData.data.tabs[selectedTab.index]?.content.templateData.data };
                                  if (newValue) {
                                    updatedData = {
                                      ...templateData.data.tabs[selectedTab.index]?.content.templateData.data,
                                      haveImage: newValue,
                                    };
                                  } else {
                                    const { logoImageFile, ...rest } =
                                      templateData.data.tabs[selectedTab.index]?.content.templateData.data;
                                    updatedData = { ...rest, haveImage: newValue };
                                  }

                                  console.log(updatedData);
                                  handleChange({
                                    ...templateData,
                                    data: {
                                      ...templateData.data,
                                      tabs: templateData.data.tabs.map((tab: any, index: number) =>
                                        index === selectedTab.index
                                          ? {
                                            ...tab,
                                            content: {
                                              ...tab.content,
                                              templateData: {
                                                ...tab.content.templateData,
                                                data: updatedData
                                              }
                                            }
                                          }
                                          : tab
                                      )
                                    }
                                  });
                                }}
                                className={`relative col-span-1 w-[42px] h-[24px] rounded-full cursor-pointer transition-colors ${templateData.data.tabs[selectedTab.index]?.content.templateData.data?.haveImage
                                  ? "bg-[#5934ff]"
                                  : "bg-gray-400"
                                  }`}
                              >
                                <div
                                  className={`absolute top-1 left-1 w-[16px] h-[16px] bg-white rounded-full transition-transform ${templateData.data.tabs[selectedTab.index]?.content.templateData.data?.haveImage
                                    ? "transform translate-x-[18px]"
                                    : ""
                                    }`}
                                ></div>
                              </button>
                            </span>
                          </div>
                          <div
                            onClick={() => {
                              const existingComponent =
                                templateData.data.tabs[selectedTab.index]?.content.templateData.data?.componentProperties?.find(
                                  (comp: any) => comp.type === "Image"
                                );
                              if (existingComponent) {
                                dispatch(
                                  setSelectedComponent({
                                    type: existingComponent.type,
                                    maxLength: existingComponent.maxLength,
                                    zone: existingComponent.zone,
                                  })
                                );
                              }
                            }}
                            className="rounded-md border-2 border-gray-300"
                          >
                            {templateData.data.tabs[selectedTab.index]?.content.templateData.data?.haveImage && (
                              <div className="flex space-x-5 items-start">
                                <UploadImage
                                  name="logoImageFile"
                                  type="image"
                                  image={templateData.data.tabs[selectedTab.index]?.content.templateData.data?.logoImageFile}
                                  handleUploadFromDevice={(file) => {
                                    console.log(file);
                                    handleChange({
                                      ...templateData,
                                      data: {
                                        ...templateData.data,
                                        tabs: templateData.data.tabs.map((tab: any, index: number) =>
                                          index === selectedTab.index
                                            ? {
                                              ...tab,
                                              content: {
                                                ...tab.content,
                                                templateData: {
                                                  ...tab.content.templateData,
                                                  data: {
                                                    ...tab.content.templateData.data,
                                                    logoImageFile: file,
                                                  }
                                                }
                                              }
                                            }
                                            : tab
                                        )
                                      }
                                    });
                                  }}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="pt-[1.25rem] px-[1.63rem] flex flex-col items-start space-y-4 w-full">
                          <div className="flex items-start space-x-2 font-[500] text-[1.125rem] leading-normal text-[#242424]">
                            <span>Main Image</span>
                          </div>
                          <div
                            onClick={() => {
                              const existingComponent =
                                templateData.data.tabs[selectedTab.index]?.content.templateData.data?.componentProperties?.find(
                                  (comp: any) => comp.type === "Image"
                                );
                              if (existingComponent) {
                                dispatch(
                                  setSelectedComponent({
                                    type: existingComponent.type,
                                    maxLength: existingComponent.maxLength,
                                    zone: existingComponent.zone,
                                  })
                                );
                              }
                            }}
                            className="rounded-md border-2 border-gray-300"
                          >
                            <div className="flex space-x-5 items-start">
                              <UploadImage
                                name="imageFile"
                                type="image"
                                image={templateData.data.tabs[selectedTab.index]?.content.templateData.data?.imageFile}
                                handleUploadFromDevice={(file) => {
                                  console.log(file);
                                  handleChange({
                                    ...templateData,
                                    data: {
                                      ...templateData.data,
                                      tabs: templateData.data.tabs.map((tab: any, index: number) =>
                                        index === selectedTab.index
                                          ? {
                                            ...tab,
                                            content: {
                                              ...tab.content,
                                              templateData: {
                                                ...tab.content.templateData,
                                                data: {
                                                  ...tab.content.templateData.data,
                                                  imageFile: file,
                                                }
                                              }
                                            }
                                          }
                                          : tab
                                      )
                                    }
                                  });
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="w-full">
                        {/* <RichTextEditor
                        placeholder="Enter Acknowledgement Text"
                        value={templateData.data.text}
                        onChange={(content, contentLength) => {
                          handleChange({
                            ...templateData,
                            data: {
                              ...templateData.data,
                              text: content,
                            }
                          });
                        }}
                      /> */}

                        <Editor
                          apiKey="0ddx1tmh6sfvjtjv7n8cwkxut9errgx0zbq4yfsqo7750vb5"
                          onInit={(_evt, editor) => (editorRef.current = editor)}
                          initialValue={templateData.data.tabs[selectedTab.index]?.content.templateData.data.text}
                          init={{
                            height: 500,
                            menubar: false,
                            plugins: [
                              "advlist",
                              "autolink",
                              "lists",
                              "link",
                              "image",
                              "charmap",
                              "preview",
                              "anchor",
                              "searchreplace",
                              "visualblocks",
                              "code",
                              "fullscreen",
                              "insertdatetime",
                              "media",
                              "table",
                              "code",
                              "help",
                              "wordcount",
                            ],
                            toolbar:
                              "undo redo | blocks | " +
                              "bold italic forecolor | alignleft aligncenter " +
                              "alignright alignjustify | bullist numlist outdent indent | " +
                              "removeformat | help",
                            content_style:
                              "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                          }}
                          onChange={(e) => {
                            handleChange({
                              ...templateData,
                              data: {
                                ...templateData.data,
                                tabs: templateData.data.tabs.map((tab: any, index: number) =>
                                  index === selectedTab.index
                                    ? {
                                      ...tab,
                                      content: {
                                        ...tab.content,
                                        templateData: {
                                          ...tab.content.templateData,
                                          data: {
                                            ...tab.content.templateData.data,
                                            text: e.target.getContent(),
                                          }
                                        }
                                      }
                                    }
                                    : tab
                                )
                              }
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </TabsContent>
              )
            }
            <TabsContent value="style">
              <Suspense fallback={<div>Loading...</div>}>
                <Tab
                  templateData={templateData}
                  handleUpdateTemplateData={handleUpdateTemplateData}
                  canvasActiveComponent={canvasActiveComponent}
                />
              </Suspense>
            </TabsContent>

            <TabsContent value="layout" className="space-y-3 p-3">
              <div className="flex justify-between items-center">
                <div className="text-left w-full text-sm">Tabs direction</div>
                <Select
                  value={
                    templateData.data.layoutOption === "horizontal-tab-top"
                      ? "Horizontal"
                      : templateData.data.layoutOption === "vertical-tab-left"
                        ? "Vertical"
                        : undefined
                  }
                  onValueChange={(value: string) => {
                    const newValue =
                      value === "Horizontal"
                        ? "horizontal-tab-top"
                        : "vertical-tab-left";
                    handleUpdateTemplateData("layoutOption", newValue);
                  }}
                >
                  <SelectTrigger className="w-[8rem]  h-[2rem] px-1 text-sm">
                    <SelectValue placeholder="Position" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="Horizontal">Horizontal</SelectItem>
                    <SelectItem value="Vertical">Vertical</SelectItem>
                  </SelectContent>
                </Select>
              </div>

              <div className="flex justify-between items-center">
                <div className="text-left w-full text-sm">Tabs position</div>

                <Select
                  value={
                    templateData.data.styles.tabButton?.justifyContent ===
                      "center"
                      ? "Center"
                      : templateData.data.styles.tabButton?.justifyContent ===
                        "flex-start"
                        ? "Left"
                        : templateData.data.styles.tabButton?.justifyContent ===
                          "flex-end"
                          ? "Right"
                          : undefined
                  }
                  onValueChange={(value: string) => {
                    const newValue =
                      value === "Center"
                        ? "center"
                        : value === "Left"
                          ? "flex-start"
                          : "flex-end";
                    handleUpdateTemplateData(
                      "styles.tabButton.justifyContent",
                      newValue
                    );
                  }}
                >
                  <SelectTrigger className="w-[8rem]  h-[2rem] px-1 text-sm">
                    <SelectValue placeholder="Position" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="Left">Left</SelectItem>
                    <SelectItem value="Right">Right</SelectItem>
                    <SelectItem value="Center">Center</SelectItem>
                  </SelectContent>
                </Select>
              </div>

              <div className="flex justify-between items-center">
                <div className="text-left w-full text-sm">Tabs width</div>
                <div className="flex items-center space-x-2">
                  <Input
                    type="number"
                    value={parseInt(
                      templateData?.data?.styles?.tabButton?.width
                    )}
                    onChange={(e) => {
                      handleUpdateTemplateData(
                        "styles.tabButton.width",
                        `${e.target.value}${templateData?.data?.styles?.tabButton?.width?.includes(
                          "%"
                        )
                          ? "%"
                          : "px"
                        }`
                      );
                    }}
                    className="w-[5rem] px-2 py-1 rounded-md"
                  />
                  <select
                    value={
                      templateData?.data?.styles?.tabButton?.width?.includes(
                        "%"
                      )
                        ? "%"
                        : "px"
                    }
                    onChange={(e) => {
                      const newValue = `${parseInt(
                        templateData?.data?.styles?.tabButton?.width
                      )}${e.target.value}`;
                      handleUpdateTemplateData(
                        "styles.tabButton.width",
                        newValue
                      );
                    }}
                    className="w-[4rem] px-2 py-1 rounded-md text-xs"
                  >
                    <option value="px">px</option>
                    <option value="%">%</option>
                  </select>
                </div>
              </div>

              <div className="flex justify-between items-center">
                <div className="text-left w-full text-sm">Tabs height</div>
                <div className="flex items-center space-x-2">
                  <Input
                    type="number"
                    value={parseInt(
                      templateData?.data?.styles?.tabButton?.height
                    )}
                    onChange={(e) => {
                      handleUpdateTemplateData(
                        "styles.tabButton.height",
                        `${e.target.value}${templateData?.data?.styles?.tabButton?.height?.includes(
                          "%"
                        )
                          ? "%"
                          : "px"
                        }`
                      );
                    }}
                    className="w-[5rem] px-2 py-1 rounded-md"
                  />
                  <select
                    value={
                      templateData?.data?.styles?.tabButton?.height?.includes(
                        "%"
                      )
                        ? "%"
                        : "px"
                    }
                    onChange={(e) => {
                      const newValue = `${parseInt(
                        templateData?.data?.styles?.tabButton?.height
                      )}${e.target.value}`;
                      handleUpdateTemplateData(
                        "styles.tabButton.height",
                        newValue
                      );
                    }}
                    className="w-[4rem] px-2 py-1 rounded-md text-xs"
                  >
                    <option value="px">px</option>
                    <option value="%">%</option>
                  </select>
                </div>
              </div>
            </TabsContent>
          </>
        )}

        {templateData.type === "clicktoreveal" && (
          <>
            <TabsContent value="content">
              <div className="flex flex-grow">
                <div className="flex flex-col space-y-[1.25rem] p-3">
                  <div className="flex w-full justify-between items-center">
                    <div className="text-left w-full text-sm">
                      Select front/back
                    </div>
                    <Select
                      value={
                        selectedCard === "front"
                          ? "Front"
                          : "Back"
                      }
                      onValueChange={(value: string) => {
                        const newValue = value === "Front" ? "front" : "back";
                        dispatch(setCanvasActiveComponent(null));
                        dispatch(setSelectedCard(newValue));
                      }}
                    >
                      <SelectTrigger className="w-[8rem] h-[2rem] px-1 text-sm">
                        <SelectValue placeholder="Position" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="Front">Front</SelectItem>
                        <SelectItem value="Back">Back</SelectItem>
                      </SelectContent>
                    </Select>
                  </div>

                  <div className="flex w-full justify-between items-center">
                    <div className="text-left w-full text-sm">Transition</div>
                    <Select
                      value={
                        templateData.data.transition === "horizontal"
                          ? "Horizontal"
                          : "Vertical"
                      }
                      onValueChange={(value: string) => {
                        const newValue =
                          value === "Horizontal"
                            ? "horizontal"
                            : "vertical";
                        handleUpdateTemplateData("transition", newValue);
                      }}
                    >
                      <SelectTrigger className="w-[8rem] h-[2rem] px-1 text-sm">
                        <SelectValue placeholder="Select Transition" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="Horizontal">
                          Horizontal flip
                        </SelectItem>
                        <SelectItem value="Vertical">Vertical flip</SelectItem>
                      </SelectContent>
                    </Select>
                  </div>

                  {selectedCard === "back" ? (
                    <>
                      <div className="flex flex-col items-start space-y-4 w-full">
                        <div className="flex items-start space-x-2 font-[500] text-[1.125rem] leading-normal">
                          <span>Image</span>
                        </div>
                        <div className="rounded-md border-2 border-gray-300">
                          <div className="flex space-x-5 items-start">
                            <UploadImage
                              name="imageFile"
                              type="image"
                              image={templateData.data?.back?.imageFile}
                              handleUploadFromDevice={(file) => {
                                console.log(file);
                                handleChange({
                                  ...templateData,
                                  data: {
                                    ...templateData.data,
                                    back: {
                                      ...templateData.data.back,
                                      imageFile: file,
                                    },
                                  },
                                });
                              }}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="w-full flex flex-col space-y-4">
                        <div className="font-[500] text-[1.125rem] leading-normal">
                          Answer
                        </div>
                        <div className="flex w-full items-center overflow-hidden rounded-md hover:border-gray-500 border-2 border-[#b2b8c1] text-gray-600">
                          <input
                            type="text"
                            placeholder="Enter prompt"
                            value={templateData?.data?.back?.answer}
                            onChange={(e) => {
                              handleChange({
                                ...templateData,
                                data: {
                                  ...templateData.data,
                                  back: {
                                    ...templateData.data.back,
                                    answer: e.target.value,
                                  },
                                },
                              });
                            }}
                            className="grow outline-none pl-[0.75rem] pr-[1.25rem] py-[0.75rem] text-[14px]"
                          />
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="flex flex-col items-start space-y-4 w-full">
                        <div className="flex items-start space-x-2 font-[500] text-[1.125rem] leading-normal">
                          <span>Image</span>
                        </div>
                        <div className="rounded-md border-2 border-gray-300">
                          <div className="flex space-x-5 items-start">
                            <UploadImage
                              name="imageFile"
                              type="image"
                              image={templateData.data?.front?.imageFile}
                              handleUploadFromDevice={(file) => {
                                console.log(file);
                                handleChange({
                                  ...templateData,
                                  data: {
                                    ...templateData.data,
                                    front: {
                                      ...templateData.data.front,
                                      imageFile: file,
                                    },
                                  },
                                });
                              }}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="w-full flex flex-col space-y-4">
                        <div className="font-[500] text-[1.125rem] leading-normal">
                          Prompt
                        </div>
                        <div className="flex w-full items-center overflow-hidden rounded-md hover:border-gray-500 border-2 border-[#b2b8c1] text-gray-600">
                          <input
                            type="text"
                            placeholder="Enter prompt"
                            value={templateData.data.front.prompt}
                            onChange={(e) => {
                              handleChange({
                                ...templateData,
                                data: {
                                  ...templateData.data,
                                  front: {
                                    ...templateData.data.front,
                                    prompt: e.target.value,
                                  },
                                },
                              });
                            }}
                            className="grow outline-none pl-[0.75rem] pr-[1.25rem] py-[0.75rem] text-[14px]"
                          />
                        </div>
                      </div>

                      <div className="w-full flex flex-col space-y-4">
                        <div className="font-[500] text-[1.125rem] leading-normal">
                          Question
                        </div>
                        <div className="flex w-full items-center overflow-hidden rounded-md hover:border-gray-500 border-2 border-[#b2b8c1] text-gray-600">
                          <input
                            type="text"
                            placeholder="Enter prompt"
                            value={templateData.data.front.question}
                            onChange={(e) => {
                              handleChange({
                                ...templateData,
                                data: {
                                  ...templateData.data,
                                  front: {
                                    ...templateData.data.front,
                                    question: e.target.value,
                                  },
                                },
                              });
                            }}
                            className="grow outline-none pl-[0.75rem] pr-[1.25rem] py-[0.75rem] text-[14px]"
                          />
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </TabsContent>

            <TabsContent value="style">
              {/* <Suspense fallback={<div>Loading...</div>}>
                    <ClickToReveal
                      templateData={templateData}
                      handleUpdateTemplateData={handleUpdateTemplateData}
                      canvasActiveComponent={canvasActiveComponent}
                    />
                </Suspense> */}
              <div className="p-3 space-y-4">
                <div>
                  <h3 className="text-lg font-semibold">
                    {templateData.title}
                  </h3>
                </div>

                {selectedCard === "back" ? (
                  <>
                    {canvasActiveComponent?.componentType === "wholeBack" && (
                      <div className="flex flex-col space-y-3 justify-between items-center">
                        <div className="text-left w-full text-sm">
                          Container
                        </div>
                        <div className="flex w-full items-center justify-between">
                          <div className="text-left w-full text-sm">Width</div>
                          <div className="flex items-center space-x-2">
                            <Input
                              type="number"
                              value={parseInt(
                                templateData.data.back.styles.parentContainer
                                  .width
                              )}
                              onChange={(e) => {
                                handleUpdateTemplateData(
                                  "back.styles.parentContainer.width",
                                  `${e.target.value}${templateData.data.back.styles.parentContainer.width.includes(
                                    "%"
                                  )
                                    ? "%"
                                    : "px"
                                  }`
                                );
                              }}
                              className="w-[5rem] px-2 py-1 rounded-md"
                            />
                            <select
                              value={
                                templateData.data.back.styles.parentContainer.width.includes(
                                  "%"
                                )
                                  ? "%"
                                  : "px"
                              }
                              onChange={(e) => {
                                const newValue = `${parseInt(
                                  templateData.data.back.styles.parentContainer
                                    .width
                                )}${e.target.value}`;
                                handleUpdateTemplateData(
                                  "back.styles.parentContainer.width",
                                  newValue
                                );
                              }}
                              className="w-[4rem] px-2 py-1 rounded-md text-xs"
                            >
                              <option value="px">px</option>
                              <option value="%">%</option>
                            </select>
                          </div>
                        </div>
                        <div className="flex w-full justify-between items-center">
                          <div className="text-left w-full text-sm">Height</div>
                          <div className="flex items-center space-x-2">
                            <Input
                              type="number"
                              value={parseInt(
                                templateData.data.back.styles.parentContainer
                                  .height
                              )}
                              onChange={(e) => {
                                handleUpdateTemplateData(
                                  "back.styles.parentContainer.height",
                                  `${e.target.value}${templateData.data.back.styles.parentContainer.height.includes(
                                    "%"
                                  )
                                    ? "%"
                                    : "px"
                                  }`
                                );
                              }}
                              className="w-[5rem] px-2 py-1 rounded-md"
                            />
                            <select
                              value={
                                templateData.data.back.styles.parentContainer.height.includes(
                                  "%"
                                )
                                  ? "%"
                                  : "px"
                              }
                              onChange={(e) => {
                                const newValue = `${parseInt(
                                  templateData.data.back.styles.parentContainer
                                    .height
                                )}${e.target.value}`;
                                handleUpdateTemplateData(
                                  "back.styles.parentContainer.height",
                                  newValue
                                );
                              }}
                              className="w-[4rem] px-2 py-1 rounded-md text-xs"
                            >
                              <option value="px">px</option>
                              <option value="%">%</option>
                            </select>
                          </div>
                        </div>

                        <div className="space-y-3">
                          <div className="text-left w-full text-sm">
                            Padding
                          </div>
                          <div className="flex flex-row flex-wrap text-xs">
                            <div className="flex py-1 w-full justify-between items-center">
                              <label htmlFor="paddingTop">Top</label>
                              <div className="flex items-center space-x-2">
                                <Input
                                  type="number"
                                  value={parseInt(
                                    templateData.data.back.styles.container
                                      .paddingTop
                                  )}
                                  onChange={(e) => {
                                    handleUpdateTemplateData(
                                      "back.styles.container.paddingTop",
                                      `${e.target.value}${templateData.data.back.styles.container.paddingTop.includes(
                                        "%"
                                      )
                                        ? "%"
                                        : "px"
                                      }`
                                    );
                                  }}
                                  className="w-[5rem] px-2 py-1 rounded-md"
                                />
                                <select
                                  value={
                                    templateData.data.back.styles.container.paddingTop.includes(
                                      "%"
                                    )
                                      ? "%"
                                      : "px"
                                  }
                                  onChange={(e) => {
                                    const newValue = `${parseInt(
                                      templateData.data.back.styles.container
                                        .paddingTop
                                    )}${e.target.value}`;
                                    handleUpdateTemplateData(
                                      "back.styles.container.paddingTop",
                                      newValue
                                    );
                                  }}
                                  className="w-[4rem] px-2 py-1 rounded-md"
                                >
                                  <option value="px">px</option>
                                  <option value="%">%</option>
                                </select>
                              </div>
                            </div>
                            <div className="flex py-1 w-full justify-between items-center">
                              <label htmlFor="paddingRight">Right</label>
                              <div className="flex items-center space-x-2">
                                <Input
                                  type="number"
                                  value={parseInt(
                                    templateData.data.back.styles.container
                                      .paddingRight
                                  )}
                                  onChange={(e) => {
                                    handleUpdateTemplateData(
                                      "back.styles.container.paddingRight",
                                      `${e.target.value}${templateData.data.back.styles.container.paddingRight.includes(
                                        "%"
                                      )
                                        ? "%"
                                        : "px"
                                      }`
                                    );
                                  }}
                                  className="w-[5rem] px-2 py-1 rounded-md"
                                />
                                <select
                                  value={
                                    templateData.data.back.styles.container.paddingRight.includes(
                                      "%"
                                    )
                                      ? "%"
                                      : "px"
                                  }
                                  onChange={(e) => {
                                    const newValue = `${parseInt(
                                      templateData.data.back.styles.container
                                        .paddingRight
                                    )}${e.target.value}`;
                                    handleUpdateTemplateData(
                                      "back.styles.container.paddingRight",
                                      newValue
                                    );
                                  }}
                                  className="w-[4rem] px-2 py-1 rounded-md"
                                >
                                  <option value="px">px</option>
                                  <option value="%">%</option>
                                </select>
                              </div>
                            </div>
                            <div className="flex py-1 w-full justify-between items-center">
                              <label htmlFor="paddingBottom">Bottom</label>
                              <div className="flex items-center space-x-2">
                                <Input
                                  type="number"
                                  value={parseInt(
                                    templateData.data.back.styles.container
                                      .paddingBottom
                                  )}
                                  onChange={(e) => {
                                    handleUpdateTemplateData(
                                      "back.styles.container.paddingBottom",
                                      `${e.target.value}${templateData.data.back.styles.container.paddingBottom.includes(
                                        "%"
                                      )
                                        ? "%"
                                        : "px"
                                      }`
                                    );
                                  }}
                                  className="w-[5rem] px-2 py-1 rounded-md"
                                />
                                <select
                                  value={
                                    templateData.data.back.styles.container.paddingBottom.includes(
                                      "%"
                                    )
                                      ? "%"
                                      : "px"
                                  }
                                  onChange={(e) => {
                                    const newValue = `${parseInt(
                                      templateData.data.back.styles.container
                                        .paddingBottom
                                    )}${e.target.value}`;
                                    handleUpdateTemplateData(
                                      "back.styles.container.paddingBottom",
                                      newValue
                                    );
                                  }}
                                  className="w-[4rem] px-2 py-1 rounded-md"
                                >
                                  <option value="px">px</option>
                                  <option value="%">%</option>
                                </select>
                              </div>
                            </div>
                            <div className="flex py-1 w-full justify-between items-center">
                              <label htmlFor="paddingLeft">Left</label>
                              <div className="flex items-center space-x-2">
                                <Input
                                  type="number"
                                  value={parseInt(
                                    templateData.data.back.styles.container
                                      .paddingLeft
                                  )}
                                  onChange={(e) => {
                                    handleUpdateTemplateData(
                                      "back.styles.container.paddingLeft",
                                      `${e.target.value}${templateData.data.back.styles.container.paddingLeft.includes(
                                        "%"
                                      )
                                        ? "%"
                                        : "px"
                                      }`
                                    );
                                  }}
                                  className="w-[5rem] px-2 py-1 rounded-md"
                                />
                                <select
                                  value={
                                    templateData.data.back.styles.container.paddingLeft.includes(
                                      "%"
                                    )
                                      ? "%"
                                      : "px"
                                  }
                                  onChange={(e) => {
                                    const newValue = `${parseInt(
                                      templateData.data.back.styles.container
                                        .paddingLeft
                                    )}${e.target.value}`;
                                    handleUpdateTemplateData(
                                      "back.styles.container.paddingLeft",
                                      newValue
                                    );
                                  }}
                                  className="w-[4rem] px-2 py-1 rounded-md"
                                >
                                  <option value="px">px</option>
                                  <option value="%">%</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="flex w-full items-center justify-between">
                          <div className="text-left w-full text-sm">Gap</div>
                          <div className="flex items-center space-x-2">
                            <Input
                              type="number"
                              value={parseInt(
                                templateData.data.back.styles.container.gap
                              )}
                              onChange={(e) => {
                                handleUpdateTemplateData(
                                  "back.styles.container.gap",
                                  `${e.target.value}${templateData.data.back.styles.container.gap.includes(
                                    "%"
                                  )
                                    ? "%"
                                    : "px"
                                  }`
                                );
                              }}
                              className="w-[5rem] px-2 py-1 rounded-md"
                            />
                            <select
                              value={
                                templateData.data.back.styles.container.gap.includes(
                                  "%"
                                )
                                  ? "%"
                                  : "px"
                              }
                              onChange={(e) => {
                                const newValue = `${parseInt(
                                  templateData.data.back.styles.container.gap
                                )}${e.target.value}`;
                                handleUpdateTemplateData(
                                  "back.styles.container.gap",
                                  newValue
                                );
                              }}
                              className="w-[4rem] px-2 py-1 rounded-md text-xs"
                            >
                              <option value="px">px</option>
                              <option value="%">%</option>
                            </select>
                          </div>
                        </div>

                        <div className="flex py-1 w-full justify-between items-center">
                          <label htmlFor="paddingLeft">Rounded Corners</label>
                          <div className="flex items-center space-x-2">
                            <Input
                              type="number"
                              value={parseInt(
                                templateData.data.back.styles.container
                                  .borderRadius
                              )}
                              onChange={(e) => {
                                handleUpdateTemplateData(
                                  "back.styles.container.borderRadius",
                                  `${e.target.value}${templateData.data.back.styles.container.borderRadius.includes(
                                    "%"
                                  )
                                    ? "%"
                                    : "px"
                                  }`
                                );
                              }}
                              className="w-[5rem] px-2 py-1 rounded-md"
                            />
                            <select
                              value={
                                templateData.data.back.styles.container.borderRadius.includes(
                                  "%"
                                )
                                  ? "%"
                                  : "px"
                              }
                              onChange={(e) => {
                                const newValue = `${parseInt(
                                  templateData.data.back.styles.container
                                    .borderRadius
                                )}${e.target.value}`;
                                handleUpdateTemplateData(
                                  "back.styles.container.borderRadius",
                                  newValue
                                );
                              }}
                              className="w-[4rem] px-2 py-1 rounded-md"
                            >
                              <option value="px">px</option>
                              <option value="%">%</option>
                            </select>
                          </div>
                        </div>

                        <div className="flex px-3 py-1 w-full justify-between items-center mr-6">
                          <div className="text-left w-full text-sm ">
                            Background Color
                          </div>
                          <input
                            value={
                              templateData.data.back.styles.container
                                .backgroundColor
                            }
                            onChange={(e) => {
                              console.log(e.target.value);
                              handleUpdateTemplateData(
                                "back.styles.container.backgroundColor",
                                e.target.value
                              );
                            }}
                            type="color"
                            name=""
                            id=""
                          />
                        </div>
                      </div>
                    )}

                    {canvasActiveComponent?.componentType ===
                      "answerImageWrap" && (
                        <div className="flex flex-col space-y-3 justify-between items-center">
                          <div className="text-left w-full text-sm">
                            Answer Image Wrapper
                          </div>
                          <div className="space-y-3">
                            <div className="flex w-full items-center justify-between">
                              <div className="text-left w-full text-sm">Gap</div>
                              <div className="flex items-center space-x-2">
                                <Input
                                  type="number"
                                  value={parseInt(
                                    templateData.data.back.styles.answerImageWrap
                                      .gap
                                  )}
                                  onChange={(e) => {
                                    handleUpdateTemplateData(
                                      "back.styles.answerImageWrap.gap",
                                      `${e.target.value}${templateData.data.back.styles.answerImageWrap.gap.includes(
                                        "%"
                                      )
                                        ? "%"
                                        : "px"
                                      }`
                                    );
                                  }}
                                  className="w-[5rem] px-2 py-1 rounded-md"
                                />
                                <select
                                  value={
                                    templateData.data.back.styles.answerImageWrap.gap.includes(
                                      "%"
                                    )
                                      ? "%"
                                      : "px"
                                  }
                                  onChange={(e) => {
                                    const newValue = `${parseInt(
                                      templateData.data.back.styles
                                        .answerImageWrap.gap
                                    )}${e.target.value}`;
                                    handleUpdateTemplateData(
                                      "back.styles.answerImageWrap.gap",
                                      newValue
                                    );
                                  }}
                                  className="w-[4rem] px-2 py-1 rounded-md text-xs"
                                >
                                  <option value="px">px</option>
                                  <option value="%">%</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                    {canvasActiveComponent?.componentType === "answerLabel" && (
                      <div className="flex flex-col space-y-3 justify-between items-center">
                        <div className="text-left w-full text-sm">
                          Answer Label
                        </div>
                        <div className="space-y-3">
                          <div className="flex w-full items-center justify-between">
                            <div className="text-left w-full text-sm">
                              Width
                            </div>
                            <div className="flex items-center space-x-2">
                              <Input
                                type="number"
                                value={parseInt(
                                  templateData.data.back.styles.answerLabel
                                    .width
                                )}
                                onChange={(e) => {
                                  handleUpdateTemplateData(
                                    "back.styles.answerLabel.width",
                                    `${e.target.value}${templateData.data.back.styles.answerLabel.width.includes(
                                      "%"
                                    )
                                      ? "%"
                                      : "px"
                                    }`
                                  );
                                }}
                                className="w-[5rem] px-2 py-1 rounded-md"
                              />
                              <select
                                value={
                                  templateData.data.back.styles.answerLabel.width.includes(
                                    "%"
                                  )
                                    ? "%"
                                    : "px"
                                }
                                onChange={(e) => {
                                  const newValue = `${parseInt(
                                    templateData.data.back.styles.answerLabel
                                      .width
                                  )}${e.target.value}`;
                                  handleUpdateTemplateData(
                                    "back.styles.answerLabel.width",
                                    newValue
                                  );
                                }}
                                className="w-[4rem] px-2 py-1 rounded-md text-xs"
                              >
                                <option value="px">px</option>
                                <option value="%">%</option>
                              </select>
                            </div>
                          </div>
                          <div className="flex w-full justify-between items-center">
                            <div className="text-left w-full text-sm">
                              Height
                            </div>
                            <div className="flex items-center space-x-2">
                              <Input
                                type="number"
                                value={parseInt(
                                  templateData.data.back.styles.answerLabel
                                    .height
                                )}
                                onChange={(e) => {
                                  handleUpdateTemplateData(
                                    "back.styles.answerLabel.height",
                                    `${e.target.value}${templateData.data.back.styles.answerLabel.height.includes(
                                      "%"
                                    )
                                      ? "%"
                                      : "px"
                                    }`
                                  );
                                }}
                                className="w-[5rem] px-2 py-1 rounded-md"
                              />
                              <select
                                value={
                                  templateData.data.back.styles.answerLabel.height.includes(
                                    "%"
                                  )
                                    ? "%"
                                    : "px"
                                }
                                onChange={(e) => {
                                  const newValue = `${parseInt(
                                    templateData.data.back.styles.answerLabel
                                      .height
                                  )}${e.target.value}`;
                                  handleUpdateTemplateData(
                                    "back.styles.answerLabel.height",
                                    newValue
                                  );
                                }}
                                className="w-[4rem] px-2 py-1 rounded-md text-xs"
                              >
                                <option value="px">px</option>
                                <option value="%">%</option>
                              </select>
                            </div>
                          </div>

                          <div className="flex justify-between items-center">
                            <div className="text-left w-full text-sm">
                              Horizontal Alignment
                            </div>
                            <Select
                              value={
                                templateData.data.back.styles.answerLabel
                                  .justifyContent === "center"
                                  ? "Center"
                                  : templateData.data.back.styles.answerLabel
                                    .justifyContent === "flex-start"
                                    ? "Left"
                                    : templateData.data.back.styles.answerLabel
                                      .justifyContent === "flex-end"
                                      ? "Right"
                                      : undefined
                              }
                              onValueChange={(value: string) => {
                                const newValue =
                                  value === "Center"
                                    ? "center"
                                    : value === "Left"
                                      ? "flex-start"
                                      : "flex-end";
                                handleUpdateTemplateData(
                                  "back.styles.answerLabel.justifyContent",
                                  newValue
                                );
                              }}
                            >
                              <SelectTrigger className="w-[8rem] h-[2rem] px-1 text-sm">
                                <SelectValue placeholder="Position" />
                              </SelectTrigger>
                              <SelectContent>
                                <SelectItem value="Left">Left</SelectItem>
                                <SelectItem value="Right">Right</SelectItem>
                                <SelectItem value="Center">Center</SelectItem>
                              </SelectContent>
                            </Select>
                          </div>

                          <div className="flex justify-between items-center">
                            <div className="text-left w-full text-sm">
                              Vertical Alignment
                            </div>
                            <Select
                              value={
                                templateData.data.back.styles.answerLabel
                                  .alignItems === "center"
                                  ? "Middle"
                                  : templateData.data.back.styles.answerLabel
                                    .alignItems === "flex-start"
                                    ? "Top"
                                    : templateData.data.back.styles.answerLabel
                                      .alignItems === "flex-end"
                                      ? "Bottom"
                                      : undefined
                              }
                              onValueChange={(value: string) => {
                                const newValue =
                                  value === "Middle"
                                    ? "center"
                                    : value === "Top"
                                      ? "flex-start"
                                      : "flex-end";
                                handleUpdateTemplateData(
                                  "back.styles.answerLabel.alignItems",
                                  newValue
                                );
                              }}
                            >
                              <SelectTrigger className="w-[8rem] h-[2rem] px-1 text-sm">
                                <SelectValue placeholder="Position" />
                              </SelectTrigger>
                              <SelectContent>
                                <SelectItem value="Top">Top</SelectItem>
                                <SelectItem value="Bottom">Bottom</SelectItem>
                                <SelectItem value="Middle">Middle</SelectItem>
                              </SelectContent>
                            </Select>
                          </div>

                          <div>
                            <div className="text-left w-full text-sm">
                              Padding
                            </div>
                            <div className="flex flex-row flex-wrap text-xs">
                              <div className="flex py-1 w-full justify-between items-center">
                                <label htmlFor="paddingTop">Top</label>
                                <div className="flex items-center space-x-2">
                                  <Input
                                    type="number"
                                    value={parseInt(
                                      templateData.data.back.styles.answerLabel
                                        .paddingTop
                                    )}
                                    onChange={(e) => {
                                      handleUpdateTemplateData(
                                        "back.styles.answerLabel.paddingTop",
                                        `${e.target.value}${templateData.data.back.styles.answerLabel.paddingTop.includes(
                                          "%"
                                        )
                                          ? "%"
                                          : "px"
                                        }`
                                      );
                                    }}
                                    className="w-[5rem] px-2 py-1 rounded-md"
                                  />
                                  <select
                                    value={
                                      templateData.data.back.styles.answerLabel.paddingTop.includes(
                                        "%"
                                      )
                                        ? "%"
                                        : "px"
                                    }
                                    onChange={(e) => {
                                      const newValue = `${parseInt(
                                        templateData.data.back.styles
                                          .answerLabel.paddingTop
                                      )}${e.target.value}`;
                                      handleUpdateTemplateData(
                                        "back.styles.answerLabel.paddingTop",
                                        newValue
                                      );
                                    }}
                                    className="w-[4rem] px-2 py-1 rounded-md"
                                  >
                                    <option value="px">px</option>
                                    <option value="%">%</option>
                                  </select>
                                </div>
                              </div>
                              <div className="flex py-1 w-full justify-between items-center">
                                <label htmlFor="paddingRight">Right</label>
                                <div className="flex items-center space-x-2">
                                  <Input
                                    type="number"
                                    value={parseInt(
                                      templateData.data.back.styles.answerLabel
                                        .paddingRight
                                    )}
                                    onChange={(e) => {
                                      handleUpdateTemplateData(
                                        "back.styles.answerLabel.paddingRight",
                                        `${e.target.value}${templateData.data.back.styles.answerLabel.paddingRight.includes(
                                          "%"
                                        )
                                          ? "%"
                                          : "px"
                                        }`
                                      );
                                    }}
                                    className="w-[5rem] px-2 py-1 rounded-md"
                                  />
                                  <select
                                    value={
                                      templateData.data.back.styles.answerLabel.paddingRight.includes(
                                        "%"
                                      )
                                        ? "%"
                                        : "px"
                                    }
                                    onChange={(e) => {
                                      const newValue = `${parseInt(
                                        templateData.data.back.styles
                                          .answerLabel.paddingRight
                                      )}${e.target.value}`;
                                      handleUpdateTemplateData(
                                        "back.styles.answerLabel.paddingRight",
                                        newValue
                                      );
                                    }}
                                    className="w-[4rem] px-2 py-1 rounded-md"
                                  >
                                    <option value="px">px</option>
                                    <option value="%">%</option>
                                  </select>
                                </div>
                              </div>
                              <div className="flex py-1 w-full justify-between items-center">
                                <label htmlFor="paddingBottom">Bottom</label>
                                <div className="flex items-center space-x-2">
                                  <Input
                                    type="number"
                                    value={parseInt(
                                      templateData.data.back.styles.answerLabel
                                        .paddingBottom
                                    )}
                                    onChange={(e) => {
                                      handleUpdateTemplateData(
                                        "back.styles.answerLabel.paddingBottom",
                                        `${e.target.value}${templateData.data.back.styles.answerLabel.paddingBottom.includes(
                                          "%"
                                        )
                                          ? "%"
                                          : "px"
                                        }`
                                      );
                                    }}
                                    className="w-[5rem] px-2 py-1 rounded-md"
                                  />
                                  <select
                                    value={
                                      templateData.data.back.styles.answerLabel.paddingBottom.includes(
                                        "%"
                                      )
                                        ? "%"
                                        : "px"
                                    }
                                    onChange={(e) => {
                                      const newValue = `${parseInt(
                                        templateData.data.back.styles
                                          .answerLabel.paddingBottom
                                      )}${e.target.value}`;
                                      handleUpdateTemplateData(
                                        "back.styles.answerLabel.paddingBottom",
                                        newValue
                                      );
                                    }}
                                    className="w-[4rem] px-2 py-1 rounded-md"
                                  >
                                    <option value="px">px</option>
                                    <option value="%">%</option>
                                  </select>
                                </div>
                              </div>
                              <div className="flex py-1 w-full justify-between items-center">
                                <label htmlFor="paddingLeft">Left</label>
                                <div className="flex items-center space-x-2">
                                  <Input
                                    type="number"
                                    value={parseInt(
                                      templateData.data.back.styles.answerLabel
                                        .paddingLeft
                                    )}
                                    onChange={(e) => {
                                      handleUpdateTemplateData(
                                        "back.styles.answerLabel.paddingLeft",
                                        `${e.target.value}${templateData.data.back.styles.answerLabel.paddingLeft.includes(
                                          "%"
                                        )
                                          ? "%"
                                          : "px"
                                        }`
                                      );
                                    }}
                                    className="w-[5rem] px-2 py-1 rounded-md"
                                  />
                                  <select
                                    value={
                                      templateData.data.back.styles.answerLabel.paddingLeft.includes(
                                        "%"
                                      )
                                        ? "%"
                                        : "px"
                                    }
                                    onChange={(e) => {
                                      const newValue = `${parseInt(
                                        templateData.data.back.styles
                                          .answerLabel.paddingLeft
                                      )}${e.target.value}`;
                                      handleUpdateTemplateData(
                                        "back.styles.answerLabel.paddingLeft",
                                        newValue
                                      );
                                    }}
                                    className="w-[4rem] px-2 py-1 rounded-md"
                                  >
                                    <option value="px">px</option>
                                    <option value="%">%</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {canvasActiveComponent?.componentType === "answer" && (
                      <div className="flex flex-col space-y-3 justify-between items-center">
                        <div className="text-left w-full text-sm">Text</div>
                        <div className="space-y-3">
                          <div className="flex w-full items-center justify-between">
                            <div className="text-left w-full text-sm">
                              Width
                            </div>
                            <div className="flex items-center space-x-2">
                              <Input
                                type="number"
                                value={parseInt(
                                  templateData.data.back.styles.text.width
                                )}
                                onChange={(e) => {
                                  handleUpdateTemplateData(
                                    "back.styles.text.width",
                                    `${e.target.value}${templateData.data.back.styles.text.width.includes(
                                      "%"
                                    )
                                      ? "%"
                                      : "px"
                                    }`
                                  );
                                }}
                                className="w-[5rem] px-2 py-1 rounded-md"
                              />
                              <select
                                value={
                                  templateData.data.back.styles.text.width.includes(
                                    "%"
                                  )
                                    ? "%"
                                    : "px"
                                }
                                onChange={(e) => {
                                  const newValue = `${parseInt(
                                    templateData.data.back.styles.text.width
                                  )}${e.target.value}`;
                                  handleUpdateTemplateData(
                                    "back.styles.text.width",
                                    newValue
                                  );
                                }}
                                className="w-[4rem] px-2 py-1 rounded-md text-xs"
                              >
                                <option value="px">px</option>
                                <option value="%">%</option>
                              </select>
                            </div>
                          </div>
                          <div className="flex w-full justify-between items-center">
                            <div className="text-left w-full text-sm">
                              Height
                            </div>
                            <div className="flex items-center space-x-2">
                              <Input
                                type="number"
                                value={parseInt(
                                  templateData.data.back.styles.text.height
                                )}
                                onChange={(e) => {
                                  handleUpdateTemplateData(
                                    "back.styles.text.height",
                                    `${e.target.value}${templateData.data.back.styles.text.height.includes(
                                      "%"
                                    )
                                      ? "%"
                                      : "px"
                                    }`
                                  );
                                }}
                                className="w-[5rem] px-2 py-1 rounded-md"
                              />
                              <select
                                value={
                                  templateData.data.back.styles.text.height.includes(
                                    "%"
                                  )
                                    ? "%"
                                    : "px"
                                }
                                onChange={(e) => {
                                  const newValue = `${parseInt(
                                    templateData.data.back.styles.text.height
                                  )}${e.target.value}`;
                                  handleUpdateTemplateData(
                                    "back.styles.text.height",
                                    newValue
                                  );
                                }}
                                className="w-[4rem] px-2 py-1 rounded-md text-xs"
                              >
                                <option value="px">px</option>
                                <option value="%">%</option>
                              </select>
                            </div>
                          </div>

                          <div className="flex justify-between items-center">
                            <div className="text-left w-full text-sm">
                              Horizontal Alignment
                            </div>
                            <Select
                              value={
                                templateData.data.back.styles.text
                                  .justifyContent === "center"
                                  ? "Center"
                                  : templateData.data.back.styles.text
                                    .justifyContent === "flex-start"
                                    ? "Left"
                                    : templateData.data.back.styles.text
                                      .justifyContent === "flex-end"
                                      ? "Right"
                                      : undefined
                              }
                              onValueChange={(value: string) => {
                                const newValue =
                                  value === "Center"
                                    ? "center"
                                    : value === "Left"
                                      ? "flex-start"
                                      : "flex-end";
                                handleUpdateTemplateData(
                                  "back.styles.text.justifyContent",
                                  newValue
                                );
                              }}
                            >
                              <SelectTrigger className="w-[8rem] h-[2rem] px-1 text-sm">
                                <SelectValue placeholder="Position" />
                              </SelectTrigger>
                              <SelectContent>
                                <SelectItem value="Left">Left</SelectItem>
                                <SelectItem value="Right">Right</SelectItem>
                                <SelectItem value="Center">Center</SelectItem>
                              </SelectContent>
                            </Select>
                          </div>

                          <div className="flex justify-between items-center">
                            <div className="text-left w-full text-sm">
                              Vertical Alignment
                            </div>
                            <Select
                              value={
                                templateData.data.back.styles.text
                                  .alignItems === "center"
                                  ? "Middle"
                                  : templateData.data.back.styles.text
                                    .alignItems === "flex-start"
                                    ? "Top"
                                    : templateData.data.back.styles.text
                                      .alignItems === "flex-end"
                                      ? "Bottom"
                                      : undefined
                              }
                              onValueChange={(value: string) => {
                                const newValue =
                                  value === "Middle"
                                    ? "center"
                                    : value === "Top"
                                      ? "flex-start"
                                      : "flex-end";
                                handleUpdateTemplateData(
                                  "back.styles.text.alignItems",
                                  newValue
                                );
                              }}
                            >
                              <SelectTrigger className="w-[8rem] h-[2rem] px-1 text-sm">
                                <SelectValue placeholder="Position" />
                              </SelectTrigger>
                              <SelectContent>
                                <SelectItem value="Top">Top</SelectItem>
                                <SelectItem value="Bottom">Bottom</SelectItem>
                                <SelectItem value="Middle">Middle</SelectItem>
                              </SelectContent>
                            </Select>
                          </div>

                          <div>
                            <div className="text-left w-full text-sm">
                              Padding
                            </div>
                            <div className="flex flex-row flex-wrap text-xs">
                              <div className="flex py-1 w-full justify-between items-center">
                                <label htmlFor="paddingTop">Top</label>
                                <div className="flex items-center space-x-2">
                                  <Input
                                    type="number"
                                    value={parseInt(
                                      templateData.data.back.styles.text
                                        .paddingTop
                                    )}
                                    onChange={(e) => {
                                      handleUpdateTemplateData(
                                        "back.styles.text.paddingTop",
                                        `${e.target.value}${templateData.data.back.styles.text.paddingTop.includes(
                                          "%"
                                        )
                                          ? "%"
                                          : "px"
                                        }`
                                      );
                                    }}
                                    className="w-[5rem] px-2 py-1 rounded-md"
                                  />
                                  <select
                                    value={
                                      templateData.data.back.styles.text.paddingTop.includes(
                                        "%"
                                      )
                                        ? "%"
                                        : "px"
                                    }
                                    onChange={(e) => {
                                      const newValue = `${parseInt(
                                        templateData.data.back.styles.text
                                          .paddingTop
                                      )}${e.target.value}`;
                                      handleUpdateTemplateData(
                                        "back.styles.text.paddingTop",
                                        newValue
                                      );
                                    }}
                                    className="w-[4rem] px-2 py-1 rounded-md"
                                  >
                                    <option value="px">px</option>
                                    <option value="%">%</option>
                                  </select>
                                </div>
                              </div>
                              <div className="flex py-1 w-full justify-between items-center">
                                <label htmlFor="paddingRight">Right</label>
                                <div className="flex items-center space-x-2">
                                  <Input
                                    type="number"
                                    value={parseInt(
                                      templateData.data.back.styles.text
                                        .paddingRight
                                    )}
                                    onChange={(e) => {
                                      handleUpdateTemplateData(
                                        "back.styles.text.paddingRight",
                                        `${e.target.value}${templateData.data.back.styles.text.paddingRight.includes(
                                          "%"
                                        )
                                          ? "%"
                                          : "px"
                                        }`
                                      );
                                    }}
                                    className="w-[5rem] px-2 py-1 rounded-md"
                                  />
                                  <select
                                    value={
                                      templateData.data.back.styles.text.paddingRight.includes(
                                        "%"
                                      )
                                        ? "%"
                                        : "px"
                                    }
                                    onChange={(e) => {
                                      const newValue = `${parseInt(
                                        templateData.data.back.styles.text
                                          .paddingRight
                                      )}${e.target.value}`;
                                      handleUpdateTemplateData(
                                        "back.styles.text.paddingRight",
                                        newValue
                                      );
                                    }}
                                    className="w-[4rem] px-2 py-1 rounded-md"
                                  >
                                    <option value="px">px</option>
                                    <option value="%">%</option>
                                  </select>
                                </div>
                              </div>
                              <div className="flex py-1 w-full justify-between items-center">
                                <label htmlFor="paddingBottom">Bottom</label>
                                <div className="flex items-center space-x-2">
                                  <Input
                                    type="number"
                                    value={parseInt(
                                      templateData.data.back.styles.text
                                        .paddingBottom
                                    )}
                                    onChange={(e) => {
                                      handleUpdateTemplateData(
                                        "back.styles.text.paddingBottom",
                                        `${e.target.value}${templateData.data.back.styles.text.paddingBottom.includes(
                                          "%"
                                        )
                                          ? "%"
                                          : "px"
                                        }`
                                      );
                                    }}
                                    className="w-[5rem] px-2 py-1 rounded-md"
                                  />
                                  <select
                                    value={
                                      templateData.data.back.styles.text.paddingBottom.includes(
                                        "%"
                                      )
                                        ? "%"
                                        : "px"
                                    }
                                    onChange={(e) => {
                                      const newValue = `${parseInt(
                                        templateData.data.back.styles.text
                                          .paddingBottom
                                      )}${e.target.value}`;
                                      handleUpdateTemplateData(
                                        "back.styles.text.paddingBottom",
                                        newValue
                                      );
                                    }}
                                    className="w-[4rem] px-2 py-1 rounded-md"
                                  >
                                    <option value="px">px</option>
                                    <option value="%">%</option>
                                  </select>
                                </div>
                              </div>
                              <div className="flex py-1 w-full justify-between items-center">
                                <label htmlFor="paddingLeft">Left</label>
                                <div className="flex items-center space-x-2">
                                  <Input
                                    type="number"
                                    value={parseInt(
                                      templateData.data.back.styles.text
                                        .paddingLeft
                                    )}
                                    onChange={(e) => {
                                      handleUpdateTemplateData(
                                        "back.styles.text.paddingLeft",
                                        `${e.target.value}${templateData.data.back.styles.text.paddingLeft.includes(
                                          "%"
                                        )
                                          ? "%"
                                          : "px"
                                        }`
                                      );
                                    }}
                                    className="w-[5rem] px-2 py-1 rounded-md"
                                  />
                                  <select
                                    value={
                                      templateData.data.back.styles.text.paddingLeft.includes(
                                        "%"
                                      )
                                        ? "%"
                                        : "px"
                                    }
                                    onChange={(e) => {
                                      const newValue = `${parseInt(
                                        templateData.data.back.styles.text
                                          .paddingLeft
                                      )}${e.target.value}`;
                                      handleUpdateTemplateData(
                                        "back.styles.text.paddingLeft",
                                        newValue
                                      );
                                    }}
                                    className="w-[4rem] px-2 py-1 rounded-md"
                                  >
                                    <option value="px">px</option>
                                    <option value="%">%</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div>
                            <button
                              onClick={() => {
                                const newValue =
                                  templateData.data.back.styles.text
                                    .overflowY === "auto"
                                    ? "hidden"
                                    : "auto";
                                handleUpdateTemplateData(
                                  "back.styles.text.overflowY",
                                  newValue
                                );
                              }}
                              className="text-left w-full text-sm"
                            >
                              {templateData.data.back.styles.text.overflowY ===
                                "auto"
                                ? "Enable"
                                : "Disable"}{" "}
                              Scroll
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                    {canvasActiveComponent?.componentType === "answerimage" && (
                      <div className="flex flex-col space-y-3 justify-between items-center">
                        <div className="text-left w-full text-sm">Image</div>
                        <div className="space-y-3 w-full">
                          <div className="flex w-full items-center justify-between">
                            <div className="text-left w-full text-sm">
                              Width
                            </div>
                            <div className="flex items-center space-x-2">
                              <Input
                                type="number"
                                value={parseInt(
                                  templateData.data.back.styles.image.width
                                )}
                                onChange={(e) => {
                                  handleUpdateTemplateData(
                                    "back.styles.image.width",
                                    `${e.target.value}${templateData.data.back.styles.image.width.includes(
                                      "%"
                                    )
                                      ? "%"
                                      : "px"
                                    }`
                                  );
                                }}
                                className="w-[5rem] px-2 py-1 rounded-md"
                              />
                              <select
                                value={
                                  templateData.data.back.styles.image.width.includes(
                                    "%"
                                  )
                                    ? "%"
                                    : "px"
                                }
                                onChange={(e) => {
                                  const newValue = `${parseInt(
                                    templateData.data.back.styles.image.width
                                  )}${e.target.value}`;
                                  handleUpdateTemplateData(
                                    "back.styles.image.width",
                                    newValue
                                  );
                                }}
                                className="w-[4rem] px-2 py-1 rounded-md text-xs"
                              >
                                <option value="px">px</option>
                                <option value="%">%</option>
                              </select>
                            </div>
                          </div>
                          <div className="flex w-full justify-between items-center">
                            <div className="text-left w-full text-sm">
                              Height
                            </div>
                            <div className="flex items-center space-x-2">
                              <Input
                                type="number"
                                value={parseInt(
                                  templateData.data.back.styles.image.height
                                )}
                                onChange={(e) => {
                                  handleUpdateTemplateData(
                                    "back.styles.image.height",
                                    `${e.target.value}${templateData.data.back.styles.image.height.includes(
                                      "%"
                                    )
                                      ? "%"
                                      : "px"
                                    }`
                                  );
                                }}
                                className="w-[5rem] px-2 py-1 rounded-md"
                              />
                              <select
                                value={
                                  templateData.data.back.styles.image.height.includes(
                                    "%"
                                  )
                                    ? "%"
                                    : "px"
                                }
                                onChange={(e) => {
                                  const newValue = `${parseInt(
                                    templateData.data.back.styles.image.height
                                  )}${e.target.value}`;
                                  handleUpdateTemplateData(
                                    "back.styles.image.height",
                                    newValue
                                  );
                                }}
                                className="w-[4rem] px-2 py-1 rounded-md text-xs"
                              >
                                <option value="px">px</option>
                                <option value="%">%</option>
                              </select>
                            </div>
                          </div>

                          <div className="flex w-full justify-between items-center">
                            <div className="text-left w-full text-sm">
                              Horizontal Alignment
                            </div>
                            <Select
                              value={
                                templateData.data.back.styles.imageContainer
                                  .justifyContent === "center"
                                  ? "Center"
                                  : templateData.data.back.styles.imageContainer
                                    .justifyContent === "flex-start"
                                    ? "Left"
                                    : templateData.data.back.styles.imageContainer
                                      .justifyContent === "flex-end"
                                      ? "Right"
                                      : undefined
                              }
                              onValueChange={(value: string) => {
                                const newValue =
                                  value === "Center"
                                    ? "center"
                                    : value === "Left"
                                      ? "flex-start"
                                      : "flex-end";
                                handleUpdateTemplateData(
                                  "back.styles.imageContainer.justifyContent",
                                  newValue
                                );
                              }}
                            >
                              <SelectTrigger className="w-[8rem] h-[2rem] px-1 text-sm">
                                <SelectValue placeholder="Position" />
                              </SelectTrigger>
                              <SelectContent>
                                <SelectItem value="Left">Left</SelectItem>
                                <SelectItem value="Right">Right</SelectItem>
                                <SelectItem value="Center">Center</SelectItem>
                              </SelectContent>
                            </Select>
                          </div>

                          <div className="flex w-full justify-between items-center">
                            <div className="text-left w-full text-sm">
                              Vertical Alignment
                            </div>
                            <Select
                              value={
                                templateData.data.back.styles.imageContainer
                                  .alignItems === "center"
                                  ? "Middle"
                                  : templateData.data.back.styles.imageContainer
                                    .alignItems === "flex-start"
                                    ? "Top"
                                    : templateData.data.back.styles.imageContainer
                                      .alignItems === "flex-end"
                                      ? "Bottom"
                                      : undefined
                              }
                              onValueChange={(value: string) => {
                                const newValue =
                                  value === "Middle"
                                    ? "center"
                                    : value === "Top"
                                      ? "flex-start"
                                      : "flex-end";
                                handleUpdateTemplateData(
                                  "back.styles.imageContainer.alignItems",
                                  newValue
                                );
                              }}
                            >
                              <SelectTrigger className="w-[8rem] h-[2rem] px-1 text-sm">
                                <SelectValue placeholder="Position" />
                              </SelectTrigger>
                              <SelectContent>
                                <SelectItem value="Top">Top</SelectItem>
                                <SelectItem value="Bottom">Bottom</SelectItem>
                                <SelectItem value="Middle">Middle</SelectItem>
                              </SelectContent>
                            </Select>
                          </div>
                          {/* write a code to change the image object fit to contain or cover */}

                          <div className="flex w-full justify-between items-center">
                            <div className="text-left w-full text-sm">
                              Object Fit
                            </div>
                            <Select
                              value={
                                templateData.data.back.styles.image
                                  .objectFit === "contain"
                                  ? "Contain"
                                  : templateData.data.back.styles.image
                                    .objectFit === "cover"
                                    ? "Cover"
                                    : undefined
                              }
                              onValueChange={(value: string) => {
                                const newValue =
                                  value === "Contain" ? "contain" : "cover";
                                handleUpdateTemplateData(
                                  "back.styles.image.objectFit",
                                  newValue
                                );
                              }}
                            >
                              <SelectTrigger className="w-[8rem] h-[2rem] px-1 text-sm">
                                <SelectValue placeholder="Position" />
                              </SelectTrigger>
                              <SelectContent>
                                <SelectItem value="Contain">Contain</SelectItem>
                                <SelectItem value="Cover">Cover</SelectItem>
                              </SelectContent>
                            </Select>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    {canvasActiveComponent?.componentType === "wholeFront" && (
                      <div className="flex flex-col space-y-3 justify-between items-center">
                        <div className="text-left w-full text-sm">
                          Container
                        </div>

                        <div className="flex w-full items-center justify-between">
                          <div className="text-left w-full text-sm">Width</div>
                          <div className="flex items-center space-x-2">
                            <Input
                              type="number"
                              value={parseInt(
                                templateData.data.front.styles.parentContainer
                                  .width
                              )}
                              onChange={(e) => {
                                handleUpdateTemplateData(
                                  "front.styles.parentContainer.width",
                                  `${e.target.value}${templateData.data.front.styles.parentContainer.width.includes(
                                    "%"
                                  )
                                    ? "%"
                                    : "px"
                                  }`
                                );
                              }}
                              className="w-[5rem] px-2 py-1 rounded-md"
                            />
                            <select
                              value={
                                templateData.data.front.styles.parentContainer.width.includes(
                                  "%"
                                )
                                  ? "%"
                                  : "px"
                              }
                              onChange={(e) => {
                                const newValue = `${parseInt(
                                  templateData.data.front.styles.parentContainer
                                    .width
                                )}${e.target.value}`;
                                handleUpdateTemplateData(
                                  "front.styles.parentContainer.width",
                                  newValue
                                );
                              }}
                              className="w-[4rem] px-2 py-1 rounded-md text-xs"
                            >
                              <option value="px">px</option>
                              <option value="%">%</option>
                            </select>
                          </div>
                        </div>
                        <div className="flex w-full justify-between items-center">
                          <div className="text-left w-full text-sm">Height</div>
                          <div className="flex items-center space-x-2">
                            <Input
                              type="number"
                              value={parseInt(
                                templateData.data.front.styles.parentContainer
                                  .height
                              )}
                              onChange={(e) => {
                                handleUpdateTemplateData(
                                  "front.styles.parentContainer.height",
                                  `${e.target.value}${templateData.data.front.styles.parentContainer.height.includes(
                                    "%"
                                  )
                                    ? "%"
                                    : "px"
                                  }`
                                );
                              }}
                              className="w-[5rem] px-2 py-1 rounded-md"
                            />
                            <select
                              value={
                                templateData.data.front.styles.parentContainer.height.includes(
                                  "%"
                                )
                                  ? "%"
                                  : "px"
                              }
                              onChange={(e) => {
                                const newValue = `${parseInt(
                                  templateData.data.front.styles.parentContainer
                                    .height
                                )}${e.target.value}`;
                                handleUpdateTemplateData(
                                  "front.styles.parentContainer.height",
                                  newValue
                                );
                              }}
                              className="w-[4rem] px-2 py-1 rounded-md text-xs"
                            >
                              <option value="px">px</option>
                              <option value="%">%</option>
                            </select>
                          </div>
                        </div>

                        <div className="space-y-3">
                          <div className="text-left w-full text-sm">
                            Padding
                          </div>
                          <div className="flex flex-row flex-wrap text-xs">
                            <div className="flex py-1 w-full justify-between items-center">
                              <label htmlFor="paddingTop">Top</label>
                              <div className="flex items-center space-x-2">
                                <Input
                                  type="number"
                                  value={parseInt(
                                    templateData.data.front.styles.container
                                      .paddingTop
                                  )}
                                  onChange={(e) => {
                                    handleUpdateTemplateData(
                                      "front.styles.container.paddingTop",
                                      `${e.target.value}${templateData.data.front.styles.container.paddingTop.includes(
                                        "%"
                                      )
                                        ? "%"
                                        : "px"
                                      }`
                                    );
                                  }}
                                  className="w-[5rem] px-2 py-1 rounded-md"
                                />
                                <select
                                  value={
                                    templateData.data.front.styles.container.paddingTop.includes(
                                      "%"
                                    )
                                      ? "%"
                                      : "px"
                                  }
                                  onChange={(e) => {
                                    const newValue = `${parseInt(
                                      templateData.data.front.styles.container
                                        .paddingTop
                                    )}${e.target.value}`;
                                    handleUpdateTemplateData(
                                      "front.styles.container.paddingTop",
                                      newValue
                                    );
                                  }}
                                  className="w-[4rem] px-2 py-1 rounded-md"
                                >
                                  <option value="px">px</option>
                                  <option value="%">%</option>
                                </select>
                              </div>
                            </div>
                            <div className="flex py-1 w-full justify-between items-center">
                              <label htmlFor="paddingRight">Right</label>
                              <div className="flex items-center space-x-2">
                                <Input
                                  type="number"
                                  value={parseInt(
                                    templateData.data.front.styles.container
                                      .paddingRight
                                  )}
                                  onChange={(e) => {
                                    handleUpdateTemplateData(
                                      "front.styles.container.paddingRight",
                                      `${e.target.value}${templateData.data.front.styles.container.paddingRight.includes(
                                        "%"
                                      )
                                        ? "%"
                                        : "px"
                                      }`
                                    );
                                  }}
                                  className="w-[5rem] px-2 py-1 rounded-md"
                                />
                                <select
                                  value={
                                    templateData.data.front.styles.container.paddingRight.includes(
                                      "%"
                                    )
                                      ? "%"
                                      : "px"
                                  }
                                  onChange={(e) => {
                                    const newValue = `${parseInt(
                                      templateData.data.front.styles.container
                                        .paddingRight
                                    )}${e.target.value}`;
                                    handleUpdateTemplateData(
                                      "front.styles.container.paddingRight",
                                      newValue
                                    );
                                  }}
                                  className="w-[4rem] px-2 py-1 rounded-md"
                                >
                                  <option value="px">px</option>
                                  <option value="%">%</option>
                                </select>
                              </div>
                            </div>
                            <div className="flex py-1 w-full justify-between items-center">
                              <label htmlFor="paddingBottom">Bottom</label>
                              <div className="flex items-center space-x-2">
                                <Input
                                  type="number"
                                  value={parseInt(
                                    templateData.data.front.styles.container
                                      .paddingBottom
                                  )}
                                  onChange={(e) => {
                                    handleUpdateTemplateData(
                                      "front.styles.container.paddingBottom",
                                      `${e.target.value}${templateData.data.front.styles.container.paddingBottom.includes(
                                        "%"
                                      )
                                        ? "%"
                                        : "px"
                                      }`
                                    );
                                  }}
                                  className="w-[5rem] px-2 py-1 rounded-md"
                                />
                                <select
                                  value={
                                    templateData.data.front.styles.container.paddingBottom.includes(
                                      "%"
                                    )
                                      ? "%"
                                      : "px"
                                  }
                                  onChange={(e) => {
                                    const newValue = `${parseInt(
                                      templateData.data.front.styles.container
                                        .paddingBottom
                                    )}${e.target.value}`;
                                    handleUpdateTemplateData(
                                      "front.styles.container.paddingBottom",
                                      newValue
                                    );
                                  }}
                                  className="w-[4rem] px-2 py-1 rounded-md"
                                >
                                  <option value="px">px</option>
                                  <option value="%">%</option>
                                </select>
                              </div>
                            </div>
                            <div className="flex py-1 w-full justify-between items-center">
                              <label htmlFor="paddingLeft">Left</label>
                              <div className="flex items-center space-x-2">
                                <Input
                                  type="number"
                                  value={parseInt(
                                    templateData.data.front.styles.container
                                      .paddingLeft
                                  )}
                                  onChange={(e) => {
                                    handleUpdateTemplateData(
                                      "front.styles.container.paddingLeft",
                                      `${e.target.value}${templateData.data.front.styles.container.paddingLeft.includes(
                                        "%"
                                      )
                                        ? "%"
                                        : "px"
                                      }`
                                    );
                                  }}
                                  className="w-[5rem] px-2 py-1 rounded-md"
                                />
                                <select
                                  value={
                                    templateData.data.front.styles.container.paddingLeft.includes(
                                      "%"
                                    )
                                      ? "%"
                                      : "px"
                                  }
                                  onChange={(e) => {
                                    const newValue = `${parseInt(
                                      templateData.data.front.styles.container
                                        .paddingLeft
                                    )}${e.target.value}`;
                                    handleUpdateTemplateData(
                                      "front.styles.container.paddingLeft",
                                      newValue
                                    );
                                  }}
                                  className="w-[4rem] px-2 py-1 rounded-md"
                                >
                                  <option value="px">px</option>
                                  <option value="%">%</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="flex w-full items-center justify-between">
                          <div className="text-left w-full text-sm">Gap</div>
                          <div className="flex items-center space-x-2">
                            <Input
                              type="number"
                              value={parseInt(
                                templateData.data.front.styles.container.gap
                              )}
                              onChange={(e) => {
                                handleUpdateTemplateData(
                                  "front.styles.container.gap",
                                  `${e.target.value}${templateData.data.front.styles.container.gap.includes(
                                    "%"
                                  )
                                    ? "%"
                                    : "px"
                                  }`
                                );
                              }}
                              className="w-[5rem] px-2 py-1 rounded-md"
                            />
                            <select
                              value={
                                templateData.data.front.styles.container.gap.includes(
                                  "%"
                                )
                                  ? "%"
                                  : "px"
                              }
                              onChange={(e) => {
                                const newValue = `${parseInt(
                                  templateData.data.front.styles.container.gap
                                )}${e.target.value}`;
                                handleUpdateTemplateData(
                                  "front.styles.container.gap",
                                  newValue
                                );
                              }}
                              className="w-[4rem] px-2 py-1 rounded-md text-xs"
                            >
                              <option value="px">px</option>
                              <option value="%">%</option>
                            </select>
                          </div>
                        </div>

                        <div className="flex py-1 w-full justify-between items-center">
                          <label htmlFor="paddingLeft">Rounded Corners</label>
                          <div className="flex items-center space-x-2">
                            <Input
                              type="number"
                              value={parseInt(
                                templateData.data.front.styles.container
                                  .borderRadius
                              )}
                              onChange={(e) => {
                                handleUpdateTemplateData(
                                  "front.styles.container.borderRadius",
                                  `${e.target.value}${templateData.data.front.styles.container.borderRadius.includes(
                                    "%"
                                  )
                                    ? "%"
                                    : "px"
                                  }`
                                );
                              }}
                              className="w-[5rem] px-2 py-1 rounded-md"
                            />
                            <select
                              value={
                                templateData.data.front.styles.container.borderRadius.includes(
                                  "%"
                                )
                                  ? "%"
                                  : "px"
                              }
                              onChange={(e) => {
                                const newValue = `${parseInt(
                                  templateData.data.front.styles.container
                                    .borderRadius
                                )}${e.target.value}`;
                                handleUpdateTemplateData(
                                  "front.styles.container.borderRadius",
                                  newValue
                                );
                              }}
                              className="w-[4rem] px-2 py-1 rounded-md"
                            >
                              <option value="px">px</option>
                              <option value="%">%</option>
                            </select>
                          </div>
                        </div>
                        <div className="flex px-3 py-1 w-full justify-between items-center mr-6">
                          <div className="text-left w-full text-sm ">
                            Background Color
                          </div>
                          <input
                            value={
                              templateData.data.front.styles.container
                                .backgroundColor
                            }
                            onChange={(e) => {
                              console.log(e.target.value);
                              handleUpdateTemplateData(
                                "front.styles.container.backgroundColor",
                                e.target.value
                              );
                            }}
                            type="color"
                            name=""
                            id=""
                          />
                        </div>
                      </div>
                    )}

                    {canvasActiveComponent?.componentType ===
                      "questionImageWrap" && (
                        <div className="flex flex-col space-y-3 justify-between items-center">
                          <div className="text-left w-full text-sm">
                            Question Image Wrapper
                          </div>
                          <div className="space-y-3">
                            <div className="flex w-full items-center justify-between">
                              <div className="text-left w-full text-sm">Gap</div>
                              <div className="flex items-center space-x-2">
                                <Input
                                  type="number"
                                  value={parseInt(
                                    templateData.data.front.styles
                                      .questionImageWrap.gap
                                  )}
                                  onChange={(e) => {
                                    handleUpdateTemplateData(
                                      "front.styles.questionImageWrap.gap",
                                      `${e.target.value}${templateData.data.front.styles.questionImageWrap.gap.includes(
                                        "%"
                                      )
                                        ? "%"
                                        : "px"
                                      }`
                                    );
                                  }}
                                  className="w-[5rem] px-2 py-1 rounded-md"
                                />
                                <select
                                  value={
                                    templateData.data.front.styles.questionImageWrap.gap.includes(
                                      "%"
                                    )
                                      ? "%"
                                      : "px"
                                  }
                                  onChange={(e) => {
                                    const newValue = `${parseInt(
                                      templateData.data.front.styles
                                        .questionImageWrap.gap
                                    )}${e.target.value}`;
                                    handleUpdateTemplateData(
                                      "front.styles.questionImageWrap.gap",
                                      newValue
                                    );
                                  }}
                                  className="w-[4rem] px-2 py-1 rounded-md text-xs"
                                >
                                  <option value="px">px</option>
                                  <option value="%">%</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    {canvasActiveComponent?.componentType ===
                      "promptLabelWrap" && (
                        <div className="flex flex-col space-y-3 justify-between items-center">
                          <div className="text-left w-full text-sm">
                            Prompt Label Wrapper
                          </div>
                          <div className="space-y-3">
                            <div className="flex w-full items-center justify-between">
                              <div className="text-left w-full text-sm">Gap</div>
                              <div className="flex items-center space-x-2">
                                <Input
                                  type="number"
                                  value={parseInt(
                                    templateData.data.front.styles.promptLabelWrap
                                      .gap
                                  )}
                                  onChange={(e) => {
                                    handleUpdateTemplateData(
                                      "front.styles.promptLabelWrap.gap",
                                      `${e.target.value}${templateData.data.front.styles.promptLabelWrap.gap.includes(
                                        "%"
                                      )
                                        ? "%"
                                        : "px"
                                      }`
                                    );
                                  }}
                                  className="w-[5rem] px-2 py-1 rounded-md"
                                />
                                <select
                                  value={
                                    templateData.data.front.styles.promptLabelWrap.gap.includes(
                                      "%"
                                    )
                                      ? "%"
                                      : "px"
                                  }
                                  onChange={(e) => {
                                    const newValue = `${parseInt(
                                      templateData.data.front.styles
                                        .promptLabelWrap.gap
                                    )}${e.target.value}`;
                                    handleUpdateTemplateData(
                                      "front.styles.promptLabelWrap.gap",
                                      newValue
                                    );
                                  }}
                                  className="w-[4rem] px-2 py-1 rounded-md text-xs"
                                >
                                  <option value="px">px</option>
                                  <option value="%">%</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                    {canvasActiveComponent?.componentType === "prompt" && (
                      <div className="flex flex-col space-y-3 justify-between items-center">
                        <div className="text-left w-full text-sm">Prompt</div>
                        <div className="space-y-3">
                          <div className="flex w-full items-center justify-between">
                            <div className="text-left w-full text-sm">
                              Width
                            </div>
                            <div className="flex items-center space-x-2">
                              <Input
                                type="number"
                                value={parseInt(
                                  templateData.data.front.styles.prompt.width
                                )}
                                onChange={(e) => {
                                  handleUpdateTemplateData(
                                    "front.styles.prompt.width",
                                    `${e.target.value}${templateData.data.front.styles.prompt.width.includes(
                                      "%"
                                    )
                                      ? "%"
                                      : "px"
                                    }`
                                  );
                                }}
                                className="w-[5rem] px-2 py-1 rounded-md"
                              />
                              <select
                                value={
                                  templateData.data.front.styles.prompt.width.includes(
                                    "%"
                                  )
                                    ? "%"
                                    : "px"
                                }
                                onChange={(e) => {
                                  const newValue = `${parseInt(
                                    templateData.data.front.styles.prompt.width
                                  )}${e.target.value}`;
                                  handleUpdateTemplateData(
                                    "front.styles.prompt.width",
                                    newValue
                                  );
                                }}
                                className="w-[4rem] px-2 py-1 rounded-md text-xs"
                              >
                                <option value="px">px</option>
                                <option value="%">%</option>
                              </select>
                            </div>
                          </div>
                          <div className="flex w-full justify-between items-center">
                            <div className="text-left w-full text-sm">
                              Height
                            </div>
                            <div className="flex items-center space-x-2">
                              <Input
                                type="number"
                                value={parseInt(
                                  templateData.data.front.styles.prompt.height
                                )}
                                onChange={(e) => {
                                  handleUpdateTemplateData(
                                    "front.styles.prompt.height",
                                    `${e.target.value}${templateData.data.front.styles.prompt.height.includes(
                                      "%"
                                    )
                                      ? "%"
                                      : "px"
                                    }`
                                  );
                                }}
                                className="w-[5rem] px-2 py-1 rounded-md"
                              />
                              <select
                                value={
                                  templateData.data.front.styles.prompt.height.includes(
                                    "%"
                                  )
                                    ? "%"
                                    : "px"
                                }
                                onChange={(e) => {
                                  const newValue = `${parseInt(
                                    templateData.data.front.styles.prompt.height
                                  )}${e.target.value}`;
                                  handleUpdateTemplateData(
                                    "front.styles.prompt.height",
                                    newValue
                                  );
                                }}
                                className="w-[4rem] px-2 py-1 rounded-md text-xs"
                              >
                                <option value="px">px</option>
                                <option value="%">%</option>
                              </select>
                            </div>
                          </div>

                          <div className="flex justify-between items-center">
                            <div className="text-left w-full text-sm">
                              Horizontal Alignment
                            </div>
                            <Select
                              value={
                                templateData.data.front.styles.prompt
                                  .justifyContent === "center"
                                  ? "Center"
                                  : templateData.data.front.styles.prompt
                                    .justifyContent === "flex-start"
                                    ? "Left"
                                    : templateData.data.front.styles.prompt
                                      .justifyContent === "flex-end"
                                      ? "Right"
                                      : undefined
                              }
                              onValueChange={(value: string) => {
                                const newValue =
                                  value === "Center"
                                    ? "center"
                                    : value === "Left"
                                      ? "flex-start"
                                      : "flex-end";
                                handleUpdateTemplateData(
                                  "front.styles.prompt.justifyContent",
                                  newValue
                                );
                              }}
                            >
                              <SelectTrigger className="w-[8rem] h-[2rem] px-1 text-sm">
                                <SelectValue placeholder="Position" />
                              </SelectTrigger>
                              <SelectContent>
                                <SelectItem value="Left">Left</SelectItem>
                                <SelectItem value="Right">Right</SelectItem>
                                <SelectItem value="Center">Center</SelectItem>
                              </SelectContent>
                            </Select>
                          </div>

                          <div className="flex justify-between items-center">
                            <div className="text-left w-full text-sm">
                              Vertical Alignment
                            </div>
                            <Select
                              value={
                                templateData.data.front.styles.prompt
                                  .alignItems === "center"
                                  ? "Middle"
                                  : templateData.data.front.styles.prompt
                                    .alignItems === "flex-start"
                                    ? "Top"
                                    : templateData.data.front.styles.prompt
                                      .alignItems === "flex-end"
                                      ? "Bottom"
                                      : undefined
                              }
                              onValueChange={(value: string) => {
                                const newValue =
                                  value === "Middle"
                                    ? "center"
                                    : value === "Top"
                                      ? "flex-start"
                                      : "flex-end";
                                handleUpdateTemplateData(
                                  "front.styles.prompt.alignItems",
                                  newValue
                                );
                              }}
                            >
                              <SelectTrigger className="w-[8rem] h-[2rem] px-1 text-sm">
                                <SelectValue placeholder="Position" />
                              </SelectTrigger>
                              <SelectContent>
                                <SelectItem value="Top">Top</SelectItem>
                                <SelectItem value="Bottom">Bottom</SelectItem>
                                <SelectItem value="Middle">Middle</SelectItem>
                              </SelectContent>
                            </Select>
                          </div>

                          <div>
                            <div className="text-left w-full text-sm">
                              Padding
                            </div>
                            <div className="flex flex-row flex-wrap text-xs">
                              <div className="flex py-1 w-full justify-between items-center">
                                <label htmlFor="paddingTop">Top</label>
                                <div className="flex items-center space-x-2">
                                  <Input
                                    type="number"
                                    value={parseInt(
                                      templateData.data.front.styles.prompt
                                        .paddingTop
                                    )}
                                    onChange={(e) => {
                                      handleUpdateTemplateData(
                                        "front.styles.prompt.paddingTop",
                                        `${e.target.value}${templateData.data.front.styles.prompt.paddingTop.includes(
                                          "%"
                                        )
                                          ? "%"
                                          : "px"
                                        }`
                                      );
                                    }}
                                    className="w-[5rem] px-2 py-1 rounded-md"
                                  />
                                  <select
                                    value={
                                      templateData.data.front.styles.prompt.paddingTop.includes(
                                        "%"
                                      )
                                        ? "%"
                                        : "px"
                                    }
                                    onChange={(e) => {
                                      const newValue = `${parseInt(
                                        templateData.data.front.styles.prompt
                                          .paddingTop
                                      )}${e.target.value}`;
                                      handleUpdateTemplateData(
                                        "front.styles.prompt.paddingTop",
                                        newValue
                                      );
                                    }}
                                    className="w-[4rem] px-2 py-1 rounded-md"
                                  >
                                    <option value="px">px</option>
                                    <option value="%">%</option>
                                  </select>
                                </div>
                              </div>
                              <div className="flex py-1 w-full justify-between items-center">
                                <label htmlFor="paddingRight">Right</label>
                                <div className="flex items-center space-x-2">
                                  <Input
                                    type="number"
                                    value={parseInt(
                                      templateData.data.front.styles.prompt
                                        .paddingRight
                                    )}
                                    onChange={(e) => {
                                      handleUpdateTemplateData(
                                        "front.styles.prompt.paddingRight",
                                        `${e.target.value}${templateData.data.front.styles.prompt.paddingRight.includes(
                                          "%"
                                        )
                                          ? "%"
                                          : "px"
                                        }`
                                      );
                                    }}
                                    className="w-[5rem] px-2 py-1 rounded-md"
                                  />
                                  <select
                                    value={
                                      templateData.data.front.styles.prompt.paddingRight.includes(
                                        "%"
                                      )
                                        ? "%"
                                        : "px"
                                    }
                                    onChange={(e) => {
                                      const newValue = `${parseInt(
                                        templateData.data.front.styles.prompt
                                          .paddingRight
                                      )}${e.target.value}`;
                                      handleUpdateTemplateData(
                                        "front.styles.prompt.paddingRight",
                                        newValue
                                      );
                                    }}
                                    className="w-[4rem] px-2 py-1 rounded-md"
                                  >
                                    <option value="px">px</option>
                                    <option value="%">%</option>
                                  </select>
                                </div>
                              </div>
                              <div className="flex py-1 w-full justify-between items-center">
                                <label htmlFor="paddingBottom">Bottom</label>
                                <div className="flex items-center space-x-2">
                                  <Input
                                    type="number"
                                    value={parseInt(
                                      templateData.data.front.styles.prompt
                                        .paddingBottom
                                    )}
                                    onChange={(e) => {
                                      handleUpdateTemplateData(
                                        "front.styles.prompt.paddingBottom",
                                        `${e.target.value}${templateData.data.front.styles.prompt.paddingBottom.includes(
                                          "%"
                                        )
                                          ? "%"
                                          : "px"
                                        }`
                                      );
                                    }}
                                    className="w-[5rem] px-2 py-1 rounded-md"
                                  />
                                  <select
                                    value={
                                      templateData.data.front.styles.prompt.paddingBottom.includes(
                                        "%"
                                      )
                                        ? "%"
                                        : "px"
                                    }
                                    onChange={(e) => {
                                      const newValue = `${parseInt(
                                        templateData.data.front.styles.prompt
                                          .paddingBottom
                                      )}${e.target.value}`;
                                      handleUpdateTemplateData(
                                        "front.styles.prompt.paddingBottom",
                                        newValue
                                      );
                                    }}
                                    className="w-[4rem] px-2 py-1 rounded-md"
                                  >
                                    <option value="px">px</option>
                                    <option value="%">%</option>
                                  </select>
                                </div>
                              </div>
                              <div className="flex py-1 w-full justify-between items-center">
                                <label htmlFor="paddingLeft">Left</label>
                                <div className="flex items-center space-x-2">
                                  <Input
                                    type="number"
                                    value={parseInt(
                                      templateData.data.front.styles.prompt
                                        .paddingLeft
                                    )}
                                    onChange={(e) => {
                                      handleUpdateTemplateData(
                                        "front.styles.prompt.paddingLeft",
                                        `${e.target.value}${templateData.data.front.styles.prompt.paddingLeft.includes(
                                          "%"
                                        )
                                          ? "%"
                                          : "px"
                                        }`
                                      );
                                    }}
                                    className="w-[5rem] px-2 py-1 rounded-md"
                                  />
                                  <select
                                    value={
                                      templateData.data.front.styles.prompt.paddingLeft.includes(
                                        "%"
                                      )
                                        ? "%"
                                        : "px"
                                    }
                                    onChange={(e) => {
                                      const newValue = `${parseInt(
                                        templateData.data.front.styles.prompt
                                          .paddingLeft
                                      )}${e.target.value}`;
                                      handleUpdateTemplateData(
                                        "front.styles.prompt.paddingLeft",
                                        newValue
                                      );
                                    }}
                                    className="w-[4rem] px-2 py-1 rounded-md"
                                  >
                                    <option value="px">px</option>
                                    <option value="%">%</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {canvasActiveComponent?.componentType ===
                      "questionLabel" && (
                        <div className="flex flex-col space-y-3 justify-between items-center">
                          <div className="text-left w-full text-sm">
                            Question Label
                          </div>
                          <div className="space-y-3">
                            <div className="flex w-full items-center justify-between">
                              <div className="text-left w-full text-sm">
                                Width
                              </div>
                              <div className="flex items-center space-x-2">
                                <Input
                                  type="number"
                                  value={parseInt(
                                    templateData.data.front.styles.questionLabel
                                      .width
                                  )}
                                  onChange={(e) => {
                                    handleUpdateTemplateData(
                                      "front.styles.questionLabel.width",
                                      `${e.target.value}${templateData.data.front.styles.questionLabel.width.includes(
                                        "%"
                                      )
                                        ? "%"
                                        : "px"
                                      }`
                                    );
                                  }}
                                  className="w-[5rem] px-2 py-1 rounded-md"
                                />
                                <select
                                  value={
                                    templateData.data.front.styles.questionLabel.width.includes(
                                      "%"
                                    )
                                      ? "%"
                                      : "px"
                                  }
                                  onChange={(e) => {
                                    const newValue = `${parseInt(
                                      templateData.data.front.styles.questionLabel
                                        .width
                                    )}${e.target.value}`;
                                    handleUpdateTemplateData(
                                      "front.styles.questionLabel.width",
                                      newValue
                                    );
                                  }}
                                  className="w-[4rem] px-2 py-1 rounded-md text-xs"
                                >
                                  <option value="px">px</option>
                                  <option value="%">%</option>
                                </select>
                              </div>
                            </div>
                            <div className="flex w-full justify-between items-center">
                              <div className="text-left w-full text-sm">
                                Height
                              </div>
                              <div className="flex items-center space-x-2">
                                <Input
                                  type="number"
                                  value={parseInt(
                                    templateData.data.front.styles.questionLabel
                                      .height
                                  )}
                                  onChange={(e) => {
                                    handleUpdateTemplateData(
                                      "front.styles.questionLabel.height",
                                      `${e.target.value}${templateData.data.front.styles.questionLabel.height.includes(
                                        "%"
                                      )
                                        ? "%"
                                        : "px"
                                      }`
                                    );
                                  }}
                                  className="w-[5rem] px-2 py-1 rounded-md"
                                />
                                <select
                                  value={
                                    templateData.data.front.styles.questionLabel.height.includes(
                                      "%"
                                    )
                                      ? "%"
                                      : "px"
                                  }
                                  onChange={(e) => {
                                    const newValue = `${parseInt(
                                      templateData.data.front.styles.questionLabel
                                        .height
                                    )}${e.target.value}`;
                                    handleUpdateTemplateData(
                                      "front.styles.questionLabel.height",
                                      newValue
                                    );
                                  }}
                                  className="w-[4rem] px-2 py-1 rounded-md text-xs"
                                >
                                  <option value="px">px</option>
                                  <option value="%">%</option>
                                </select>
                              </div>
                            </div>
                            <div className="flex justify-between items-center">
                              <div className="text-left w-full text-sm">
                                Horizontal Alignment
                              </div>
                              <Select
                                value={
                                  templateData.data.front.styles.questionLabel
                                    .justifyContent === "center"
                                    ? "Center"
                                    : templateData.data.front.styles.questionLabel
                                      .justifyContent === "flex-start"
                                      ? "Left"
                                      : templateData.data.front.styles.questionLabel
                                        .justifyContent === "flex-end"
                                        ? "Right"
                                        : undefined
                                }
                                onValueChange={(value: string) => {
                                  const newValue =
                                    value === "Center"
                                      ? "center"
                                      : value === "Left"
                                        ? "flex-start"
                                        : "flex-end";
                                  handleUpdateTemplateData(
                                    "front.styles.questionLabel.justifyContent",
                                    newValue
                                  );
                                }}
                              >
                                <SelectTrigger className="w-[8rem] h-[2rem] px-1 text-sm">
                                  <SelectValue placeholder="Position" />
                                </SelectTrigger>
                                <SelectContent>
                                  <SelectItem value="Left">Left</SelectItem>
                                  <SelectItem value="Right">Right</SelectItem>
                                  <SelectItem value="Center">Center</SelectItem>
                                </SelectContent>
                              </Select>
                            </div>

                            <div className="flex justify-between items-center">
                              <div className="text-left w-full text-sm">
                                Vertical Alignment
                              </div>
                              <Select
                                value={
                                  templateData.data.front.styles.questionLabel
                                    .alignItems === "center"
                                    ? "Middle"
                                    : templateData.data.front.styles.questionLabel
                                      .alignItems === "flex-start"
                                      ? "Top"
                                      : templateData.data.front.styles.questionLabel
                                        .alignItems === "flex-end"
                                        ? "Bottom"
                                        : undefined
                                }
                                onValueChange={(value: string) => {
                                  const newValue =
                                    value === "Middle"
                                      ? "center"
                                      : value === "Top"
                                        ? "flex-start"
                                        : "flex-end";
                                  handleUpdateTemplateData(
                                    "front.styles.questionLabel.alignItems",
                                    newValue
                                  );
                                }}
                              >
                                <SelectTrigger className="w-[8rem] h-[2rem] px-1 text-sm">
                                  <SelectValue placeholder="Position" />
                                </SelectTrigger>
                                <SelectContent>
                                  <SelectItem value="Top">Top</SelectItem>
                                  <SelectItem value="Bottom">Bottom</SelectItem>
                                  <SelectItem value="Middle">Middle</SelectItem>
                                </SelectContent>
                              </Select>
                            </div>

                            <div>
                              <div className="text-left w-full text-sm">
                                Padding
                              </div>
                              <div className="flex flex-row flex-wrap text-xs">
                                <div className="flex py-1 w-full justify-between items-center">
                                  <label htmlFor="paddingTop">Top</label>
                                  <div className="flex items-center space-x-2">
                                    <Input
                                      type="number"
                                      value={parseInt(
                                        templateData.data.front.styles
                                          .questionLabel.paddingTop
                                      )}
                                      onChange={(e) => {
                                        handleUpdateTemplateData(
                                          "front.styles.questionLabel.paddingTop",
                                          `${e.target.value}${templateData.data.front.styles.questionLabel.paddingTop.includes(
                                            "%"
                                          )
                                            ? "%"
                                            : "px"
                                          }`
                                        );
                                      }}
                                      className="w-[5rem] px-2 py-1 rounded-md"
                                    />
                                    <select
                                      value={
                                        templateData.data.front.styles.questionLabel.paddingTop.includes(
                                          "%"
                                        )
                                          ? "%"
                                          : "px"
                                      }
                                      onChange={(e) => {
                                        const newValue = `${parseInt(
                                          templateData.data.front.styles
                                            .questionLabel.paddingTop
                                        )}${e.target.value}`;
                                        handleUpdateTemplateData(
                                          "front.styles.questionLabel.paddingTop",
                                          newValue
                                        );
                                      }}
                                      className="w-[4rem] px-2 py-1 rounded-md"
                                    >
                                      <option value="px">px</option>
                                      <option value="%">%</option>
                                    </select>
                                  </div>
                                </div>
                                <div className="flex py-1 w-full justify-between items-center">
                                  <label htmlFor="paddingRight">Right</label>
                                  <div className="flex items-center space-x-2">
                                    <Input
                                      type="number"
                                      value={parseInt(
                                        templateData.data.front.styles
                                          .questionLabel.paddingRight
                                      )}
                                      onChange={(e) => {
                                        handleUpdateTemplateData(
                                          "front.styles.questionLabel.paddingRight",
                                          `${e.target.value}${templateData.data.front.styles.questionLabel.paddingRight.includes(
                                            "%"
                                          )
                                            ? "%"
                                            : "px"
                                          }`
                                        );
                                      }}
                                      className="w-[5rem] px-2 py-1 rounded-md"
                                    />
                                    <select
                                      value={
                                        templateData.data.front.styles.questionLabel.paddingRight.includes(
                                          "%"
                                        )
                                          ? "%"
                                          : "px"
                                      }
                                      onChange={(e) => {
                                        const newValue = `${parseInt(
                                          templateData.data.front.styles
                                            .questionLabel.paddingRight
                                        )}${e.target.value}`;
                                        handleUpdateTemplateData(
                                          "front.styles.questionLabel.paddingRight",
                                          newValue
                                        );
                                      }}
                                      className="w-[4rem] px-2 py-1 rounded-md"
                                    >
                                      <option value="px">px</option>
                                      <option value="%">%</option>
                                    </select>
                                  </div>
                                </div>
                                <div className="flex py-1 w-full justify-between items-center">
                                  <label htmlFor="paddingBottom">Bottom</label>
                                  <div className="flex items-center space-x-2">
                                    <Input
                                      type="number"
                                      value={parseInt(
                                        templateData.data.front.styles
                                          .questionLabel.paddingBottom
                                      )}
                                      onChange={(e) => {
                                        handleUpdateTemplateData(
                                          "front.styles.questionLabel.paddingBottom",
                                          `${e.target.value}${templateData.data.front.styles.questionLabel.paddingBottom.includes(
                                            "%"
                                          )
                                            ? "%"
                                            : "px"
                                          }`
                                        );
                                      }}
                                      className="w-[5rem] px-2 py-1 rounded-md"
                                    />
                                    <select
                                      value={
                                        templateData.data.front.styles.questionLabel.paddingBottom.includes(
                                          "%"
                                        )
                                          ? "%"
                                          : "px"
                                      }
                                      onChange={(e) => {
                                        const newValue = `${parseInt(
                                          templateData.data.front.styles
                                            .questionLabel.paddingBottom
                                        )}${e.target.value}`;
                                        handleUpdateTemplateData(
                                          "front.styles.questionLabel.paddingBottom",
                                          newValue
                                        );
                                      }}
                                      className="w-[4rem] px-2 py-1 rounded-md"
                                    >
                                      <option value="px">px</option>
                                      <option value="%">%</option>
                                    </select>
                                  </div>
                                </div>
                                <div className="flex py-1 w-full justify-between items-center">
                                  <label htmlFor="paddingLeft">Left</label>
                                  <div className="flex items-center space-x-2">
                                    <Input
                                      type="number"
                                      value={parseInt(
                                        templateData.data.front.styles
                                          .questionLabel.paddingLeft
                                      )}
                                      onChange={(e) => {
                                        handleUpdateTemplateData(
                                          "front.styles.questionLabel.paddingLeft",
                                          `${e.target.value}${templateData.data.front.styles.questionLabel.paddingLeft.includes(
                                            "%"
                                          )
                                            ? "%"
                                            : "px"
                                          }`
                                        );
                                      }}
                                      className="w-[5rem] px-2 py-1 rounded-md"
                                    />
                                    <select
                                      value={
                                        templateData.data.front.styles.questionLabel.paddingLeft.includes(
                                          "%"
                                        )
                                          ? "%"
                                          : "px"
                                      }
                                      onChange={(e) => {
                                        const newValue = `${parseInt(
                                          templateData.data.front.styles
                                            .questionLabel.paddingLeft
                                        )}${e.target.value}`;
                                        handleUpdateTemplateData(
                                          "front.styles.questionLabel.paddingLeft",
                                          newValue
                                        );
                                      }}
                                      className="w-[4rem] px-2 py-1 rounded-md"
                                    >
                                      <option value="px">px</option>
                                      <option value="%">%</option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    {canvasActiveComponent?.componentType === "question" && (
                      <div className="flex flex-col space-y-3 justify-between items-center">
                        <div className="text-left w-full text-sm">Text</div>
                        <div className="space-y-3">
                          <div className="flex w-full items-center justify-between">
                            <div className="text-left w-full text-sm">
                              Width
                            </div>
                            <div className="flex items-center space-x-2">
                              <Input
                                type="number"
                                value={parseInt(
                                  templateData.data.front.styles.text.width
                                )}
                                onChange={(e) => {
                                  handleUpdateTemplateData(
                                    "front.styles.text.width",
                                    `${e.target.value}${templateData.data.front.styles.text.width.includes(
                                      "%"
                                    )
                                      ? "%"
                                      : "px"
                                    }`
                                  );
                                }}
                                className="w-[5rem] px-2 py-1 rounded-md"
                              />
                              <select
                                value={
                                  templateData.data.front.styles.text.width.includes(
                                    "%"
                                  )
                                    ? "%"
                                    : "px"
                                }
                                onChange={(e) => {
                                  const newValue = `${parseInt(
                                    templateData.data.front.styles.text.width
                                  )}${e.target.value}`;
                                  handleUpdateTemplateData(
                                    "front.styles.text.width",
                                    newValue
                                  );
                                }}
                                className="w-[4rem] px-2 py-1 rounded-md text-xs"
                              >
                                <option value="px">px</option>
                                <option value="%">%</option>
                              </select>
                            </div>
                          </div>
                          <div className="flex w-full justify-between items-center">
                            <div className="text-left w-full text-sm">
                              Height
                            </div>
                            <div className="flex items-center space-x-2">
                              <Input
                                type="number"
                                value={parseInt(
                                  templateData.data.front.styles.text.height
                                )}
                                onChange={(e) => {
                                  handleUpdateTemplateData(
                                    "front.styles.text.height",
                                    `${e.target.value}${templateData.data.front.styles.text.height.includes(
                                      "%"
                                    )
                                      ? "%"
                                      : "px"
                                    }`
                                  );
                                }}
                                className="w-[5rem] px-2 py-1 rounded-md"
                              />
                              <select
                                value={
                                  templateData.data.front.styles.text.height.includes(
                                    "%"
                                  )
                                    ? "%"
                                    : "px"
                                }
                                onChange={(e) => {
                                  const newValue = `${parseInt(
                                    templateData.data.front.styles.text.height
                                  )}${e.target.value}`;
                                  handleUpdateTemplateData(
                                    "front.styles.text.height",
                                    newValue
                                  );
                                }}
                                className="w-[4rem] px-2 py-1 rounded-md text-xs"
                              >
                                <option value="px">px</option>
                                <option value="%">%</option>
                              </select>
                            </div>
                          </div>

                          <div className="flex justify-between items-center">
                            <div className="text-left w-full text-sm">
                              Horizontal Alignment
                            </div>
                            <Select
                              value={
                                templateData.data.front.styles.text
                                  .justifyContent === "center"
                                  ? "Center"
                                  : templateData.data.front.styles.text
                                    .justifyContent === "flex-start"
                                    ? "Left"
                                    : templateData.data.front.styles.text
                                      .justifyContent === "flex-end"
                                      ? "Right"
                                      : undefined
                              }
                              onValueChange={(value: string) => {
                                const newValue =
                                  value === "Center"
                                    ? "center"
                                    : value === "Left"
                                      ? "flex-start"
                                      : "flex-end";
                                handleUpdateTemplateData(
                                  "front.styles.text.justifyContent",
                                  newValue
                                );
                              }}
                            >
                              <SelectTrigger className="w-[8rem] h-[2rem] px-1 text-sm">
                                <SelectValue placeholder="Position" />
                              </SelectTrigger>
                              <SelectContent>
                                <SelectItem value="Left">Left</SelectItem>
                                <SelectItem value="Right">Right</SelectItem>
                                <SelectItem value="Center">Center</SelectItem>
                              </SelectContent>
                            </Select>
                          </div>

                          <div className="flex justify-between items-center">
                            <div className="text-left w-full text-sm">
                              Vertical Alignment
                            </div>
                            <Select
                              value={
                                templateData.data.front.styles.text
                                  .alignItems === "center"
                                  ? "Middle"
                                  : templateData.data.front.styles.text
                                    .alignItems === "flex-start"
                                    ? "Top"
                                    : templateData.data.front.styles.text
                                      .alignItems === "flex-end"
                                      ? "Bottom"
                                      : undefined
                              }
                              onValueChange={(value: string) => {
                                const newValue =
                                  value === "Middle"
                                    ? "center"
                                    : value === "Top"
                                      ? "flex-start"
                                      : "flex-end";
                                handleUpdateTemplateData(
                                  "front.styles.text.alignItems",
                                  newValue
                                );
                              }}
                            >
                              <SelectTrigger className="w-[8rem] h-[2rem] px-1 text-sm">
                                <SelectValue placeholder="Position" />
                              </SelectTrigger>
                              <SelectContent>
                                <SelectItem value="Top">Top</SelectItem>
                                <SelectItem value="Bottom">Bottom</SelectItem>
                                <SelectItem value="Middle">Middle</SelectItem>
                              </SelectContent>
                            </Select>
                          </div>

                          <div>
                            <div className="text-left w-full text-sm">
                              Padding
                            </div>
                            <div className="flex flex-row flex-wrap text-xs">
                              <div className="flex py-1 w-full justify-between items-center">
                                <label htmlFor="paddingTop">Top</label>
                                <div className="flex items-center space-x-2">
                                  <Input
                                    type="number"
                                    value={parseInt(
                                      templateData.data.front.styles.text
                                        .paddingTop
                                    )}
                                    onChange={(e) => {
                                      handleUpdateTemplateData(
                                        "front.styles.text.paddingTop",
                                        `${e.target.value}${templateData.data.front.styles.text.paddingTop.includes(
                                          "%"
                                        )
                                          ? "%"
                                          : "px"
                                        }`
                                      );
                                    }}
                                    className="w-[5rem] px-2 py-1 rounded-md"
                                  />
                                  <select
                                    value={
                                      templateData.data.front.styles.text.paddingTop.includes(
                                        "%"
                                      )
                                        ? "%"
                                        : "px"
                                    }
                                    onChange={(e) => {
                                      const newValue = `${parseInt(
                                        templateData.data.front.styles.text
                                          .paddingTop
                                      )}${e.target.value}`;
                                      handleUpdateTemplateData(
                                        "front.styles.text.paddingTop",
                                        newValue
                                      );
                                    }}
                                    className="w-[4rem] px-2 py-1 rounded-md"
                                  >
                                    <option value="px">px</option>
                                    <option value="%">%</option>
                                  </select>
                                </div>
                              </div>
                              <div className="flex py-1 w-full justify-between items-center">
                                <label htmlFor="paddingRight">Right</label>
                                <div className="flex items-center space-x-2">
                                  <Input
                                    type="number"
                                    value={parseInt(
                                      templateData.data.front.styles.text
                                        .paddingRight
                                    )}
                                    onChange={(e) => {
                                      handleUpdateTemplateData(
                                        "front.styles.text.paddingRight",
                                        `${e.target.value}${templateData.data.front.styles.text.paddingRight.includes(
                                          "%"
                                        )
                                          ? "%"
                                          : "px"
                                        }`
                                      );
                                    }}
                                    className="w-[5rem] px-2 py-1 rounded-md"
                                  />
                                  <select
                                    value={
                                      templateData.data.front.styles.text.paddingRight.includes(
                                        "%"
                                      )
                                        ? "%"
                                        : "px"
                                    }
                                    onChange={(e) => {
                                      const newValue = `${parseInt(
                                        templateData.data.front.styles.text
                                          .paddingRight
                                      )}${e.target.value}`;
                                      handleUpdateTemplateData(
                                        "front.styles.text.paddingRight",
                                        newValue
                                      );
                                    }}
                                    className="w-[4rem] px-2 py-1 rounded-md"
                                  >
                                    <option value="px">px</option>
                                    <option value="%">%</option>
                                  </select>
                                </div>
                              </div>
                              <div className="flex py-1 w-full justify-between items-center">
                                <label htmlFor="paddingBottom">Bottom</label>
                                <div className="flex items-center space-x-2">
                                  <Input
                                    type="number"
                                    value={parseInt(
                                      templateData.data.front.styles.text
                                        .paddingBottom
                                    )}
                                    onChange={(e) => {
                                      handleUpdateTemplateData(
                                        "front.styles.text.paddingBottom",
                                        `${e.target.value}${templateData.data.front.styles.text.paddingBottom.includes(
                                          "%"
                                        )
                                          ? "%"
                                          : "px"
                                        }`
                                      );
                                    }}
                                    className="w-[5rem] px-2 py-1 rounded-md"
                                  />
                                  <select
                                    value={
                                      templateData.data.front.styles.text.paddingBottom.includes(
                                        "%"
                                      )
                                        ? "%"
                                        : "px"
                                    }
                                    onChange={(e) => {
                                      const newValue = `${parseInt(
                                        templateData.data.front.styles.text
                                          .paddingBottom
                                      )}${e.target.value}`;
                                      handleUpdateTemplateData(
                                        "front.styles.text.paddingBottom",
                                        newValue
                                      );
                                    }}
                                    className="w-[4rem] px-2 py-1 rounded-md"
                                  >
                                    <option value="px">px</option>
                                    <option value="%">%</option>
                                  </select>
                                </div>
                              </div>
                              <div className="flex py-1 w-full justify-between items-center">
                                <label htmlFor="paddingLeft">Left</label>
                                <div className="flex items-center space-x-2">
                                  <Input
                                    type="number"
                                    value={parseInt(
                                      templateData.data.front.styles.text
                                        .paddingLeft
                                    )}
                                    onChange={(e) => {
                                      handleUpdateTemplateData(
                                        "front.styles.text.paddingLeft",
                                        `${e.target.value}${templateData.data.front.styles.text.paddingLeft.includes(
                                          "%"
                                        )
                                          ? "%"
                                          : "px"
                                        }`
                                      );
                                    }}
                                    className="w-[5rem] px-2 py-1 rounded-md"
                                  />
                                  <select
                                    value={
                                      templateData.data.front.styles.text.paddingLeft.includes(
                                        "%"
                                      )
                                        ? "%"
                                        : "px"
                                    }
                                    onChange={(e) => {
                                      const newValue = `${parseInt(
                                        templateData.data.front.styles.text
                                          .paddingLeft
                                      )}${e.target.value}`;
                                      handleUpdateTemplateData(
                                        "front.styles.text.paddingLeft",
                                        newValue
                                      );
                                    }}
                                    className="w-[4rem] px-2 py-1 rounded-md"
                                  >
                                    <option value="px">px</option>
                                    <option value="%">%</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div>
                            <button
                              onClick={() => {
                                const newValue =
                                  templateData.data.front.styles.text
                                    .overflowY === "auto"
                                    ? "hidden"
                                    : "auto";
                                handleUpdateTemplateData(
                                  "front.styles.text.overflowY",
                                  newValue
                                );
                              }}
                              className="text-left w-full text-sm"
                            >
                              {templateData.data.front.styles.text.overflowY ===
                                "auto"
                                ? "Enable"
                                : "Disable"}{" "}
                              Scroll
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                    {canvasActiveComponent?.componentType === "image" && (
                      <div className="flex flex-col space-y-3 justify-between items-center">
                        <div className="text-left w-full text-sm">Image</div>
                        <div className="space-y-3 w-full">
                          <div className="flex w-full items-center justify-between">
                            <div className="text-left w-full text-sm">
                              Width
                            </div>
                            <div className="flex items-center space-x-2">
                              <Input
                                type="number"
                                value={parseInt(
                                  templateData.data.front.styles.image.width
                                )}
                                onChange={(e) => {
                                  handleUpdateTemplateData(
                                    "front.styles.image.width",
                                    `${e.target.value}${templateData.data.front.styles.image.width.includes(
                                      "%"
                                    )
                                      ? "%"
                                      : "px"
                                    }`
                                  );
                                }}
                                className="w-[5rem] px-2 py-1 rounded-md"
                              />
                              <select
                                value={
                                  templateData.data.front.styles.image.width.includes(
                                    "%"
                                  )
                                    ? "%"
                                    : "px"
                                }
                                onChange={(e) => {
                                  const newValue = `${parseInt(
                                    templateData.data.front.styles.image.width
                                  )}${e.target.value}`;
                                  handleUpdateTemplateData(
                                    "front.styles.image.width",
                                    newValue
                                  );
                                }}
                                className="w-[4rem] px-2 py-1 rounded-md text-xs"
                              >
                                <option value="px">px</option>
                                <option value="%">%</option>
                              </select>
                            </div>
                          </div>
                          <div className="flex w-full justify-between items-center">
                            <div className="text-left w-full text-sm">
                              Height
                            </div>
                            <div className="flex items-center space-x-2">
                              <Input
                                type="number"
                                value={parseInt(
                                  templateData.data.front.styles.image.height
                                )}
                                onChange={(e) => {
                                  handleUpdateTemplateData(
                                    "front.styles.image.height",
                                    `${e.target.value}${templateData.data.front.styles.image.height.includes(
                                      "%"
                                    )
                                      ? "%"
                                      : "px"
                                    }`
                                  );
                                }}
                                className="w-[5rem] px-2 py-1 rounded-md"
                              />
                              <select
                                value={
                                  templateData.data.front.styles.image.height.includes(
                                    "%"
                                  )
                                    ? "%"
                                    : "px"
                                }
                                onChange={(e) => {
                                  const newValue = `${parseInt(
                                    templateData.data.front.styles.image.height
                                  )}${e.target.value}`;
                                  handleUpdateTemplateData(
                                    "front.styles.image.height",
                                    newValue
                                  );
                                }}
                                className="w-[4rem] px-2 py-1 rounded-md text-xs"
                              >
                                <option value="px">px</option>
                                <option value="%">%</option>
                              </select>
                            </div>
                          </div>

                          <div className="flex w-full justify-between items-center">
                            <div className="text-left w-full text-sm">
                              Horizontal Alignment
                            </div>
                            <Select
                              value={
                                templateData.data.front.styles.imageContainer
                                  .justifyContent === "center"
                                  ? "Center"
                                  : templateData.data.front.styles
                                    .imageContainer.justifyContent ===
                                    "flex-start"
                                    ? "Left"
                                    : templateData.data.front.styles
                                      .imageContainer.justifyContent ===
                                      "flex-end"
                                      ? "Right"
                                      : undefined
                              }
                              onValueChange={(value: string) => {
                                const newValue =
                                  value === "Center"
                                    ? "center"
                                    : value === "Left"
                                      ? "flex-start"
                                      : "flex-end";
                                handleUpdateTemplateData(
                                  "front.styles.imageContainer.justifyContent",
                                  newValue
                                );
                              }}
                            >
                              <SelectTrigger className="w-[8rem] h-[2rem] px-1 text-sm">
                                <SelectValue placeholder="Position" />
                              </SelectTrigger>
                              <SelectContent>
                                <SelectItem value="Left">Left</SelectItem>
                                <SelectItem value="Right">Right</SelectItem>
                                <SelectItem value="Center">Center</SelectItem>
                              </SelectContent>
                            </Select>
                          </div>

                          <div className="flex w-full justify-between items-center">
                            <div className="text-left w-full text-sm">
                              Vertical Alignment
                            </div>
                            <Select
                              value={
                                templateData.data.front.styles.imageContainer
                                  .alignItems === "center"
                                  ? "Middle"
                                  : templateData.data.front.styles
                                    .imageContainer.alignItems ===
                                    "flex-start"
                                    ? "Top"
                                    : templateData.data.front.styles
                                      .imageContainer.alignItems === "flex-end"
                                      ? "Bottom"
                                      : undefined
                              }
                              onValueChange={(value: string) => {
                                const newValue =
                                  value === "Middle"
                                    ? "center"
                                    : value === "Top"
                                      ? "flex-start"
                                      : "flex-end";
                                handleUpdateTemplateData(
                                  "front.styles.imageContainer.alignItems",
                                  newValue
                                );
                              }}
                            >
                              <SelectTrigger className="w-[8rem] h-[2rem] px-1 text-sm">
                                <SelectValue placeholder="Position" />
                              </SelectTrigger>
                              <SelectContent>
                                <SelectItem value="Top">Top</SelectItem>
                                <SelectItem value="Bottom">Bottom</SelectItem>
                                <SelectItem value="Middle">Middle</SelectItem>
                              </SelectContent>
                            </Select>
                          </div>

                          <div className="flex w-full justify-between items-center">
                            <div className="text-left w-full text-sm">
                              Object Fit
                            </div>
                            <Select
                              value={
                                templateData.data.front.styles.image
                                  .objectFit === "contain"
                                  ? "Contain"
                                  : templateData.data.front.styles.image
                                    .objectFit === "cover"
                                    ? "Cover"
                                    : undefined
                              }
                              onValueChange={(value: string) => {
                                const newValue =
                                  value === "Contain" ? "contain" : "cover";
                                handleUpdateTemplateData(
                                  "front.styles.image.objectFit",
                                  newValue
                                );
                              }}
                            >
                              <SelectTrigger className="w-[8rem] h-[2rem] px-1 text-sm">
                                <SelectValue placeholder="Position" />
                              </SelectTrigger>
                              <SelectContent>
                                <SelectItem value="Contain">Contain</SelectItem>
                                <SelectItem value="Cover">Cover</SelectItem>
                              </SelectContent>
                            </Select>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                )}

                {canvasActiveComponent?.componentType === "globalBG" && (
                  <div className="flex flex-col space-y-3 justify-between items-center">
                    <div className="text-left w-full text-sm">
                      Outer Container
                    </div>

                    <div className="flex px-3 py-1 w-full justify-between items-center mr-6">
                      <div className="text-left w-full text-sm ">
                        Background Color
                      </div>
                      <input
                        value={templateData.data.styles.global.backgroundColor}
                        onChange={(e) => {
                          console.log(e.target.value);
                          handleUpdateTemplateData(
                            "styles.global.backgroundColor",
                            e.target.value
                          );
                        }}
                        type="color"
                        name=""
                        id=""
                      />
                    </div>
                  </div>
                )}
              </div>
            </TabsContent>

            <TabsContent value="layout" className="space-y-3">
              {selectedCard === "back" ? (
                <>
                  <div
                    onClick={() =>
                      handleUpdateTemplateData(
                        "back.layoutOption",
                        "back-text-left-image-right"
                      )
                    }
                    className="h-[6rem] w-full flex justify-center items-center"
                  >
                    <img
                      className={`h-full aspect-auto ${templateData.data.back.layoutOption ===
                        "back-text-left-image-right" &&
                        "border-blue border-[3px] rounded-md"
                        }`}
                      src={BackimageRight}
                      alt="image"
                    />
                  </div>
                  <div
                    onClick={() =>
                      handleUpdateTemplateData(
                        "back.layoutOption",
                        "back-text-right-image-left"
                      )
                    }
                    className="h-[6rem] w-full flex justify-center items-center"
                  >
                    <img
                      className={`h-full aspect-auto ${templateData.data.back.layoutOption ===
                        "back-text-right-image-left" &&
                        "border-blue border-[3px] rounded-md"
                        }`}
                      src={BackimageLeft}
                      alt="image"
                    />
                  </div>
                  <div
                    onClick={() =>
                      handleUpdateTemplateData(
                        "back.layoutOption",
                        "back-only-text"
                      )
                    }
                    className="h-[6rem] w-full flex justify-center items-center"
                  >
                    <img
                      className={`h-full aspect-auto ${templateData.data.back.layoutOption ===
                        "back-only-text" &&
                        "border-blue border-[3px] rounded-md"
                        }`}
                      src={BackSimpleText}
                      alt="image"
                    />
                  </div>
                  <div
                    onClick={() =>
                      handleUpdateTemplateData(
                        "back.layoutOption",
                        "back-only-text-square"
                      )
                    }
                    className="h-[6rem] w-full flex justify-center items-center"
                  >
                    <img
                      className={`h-full aspect-auto ${templateData.data.back.layoutOption ===
                        "back-only-text-square" &&
                        "border-blue border-[3px] rounded-md"
                        }`}
                      src={BackSmallSimple}
                      alt="image"
                    />
                  </div>
                  <div
                    onClick={() =>
                      handleUpdateTemplateData(
                        "back.layoutOption",
                        "back-text-image-square"
                      )
                    }
                    className="h-[6rem] w-full flex justify-center items-center"
                  >
                    <img
                      className={`h-full aspect-auto ${templateData.data.back.layoutOption ===
                        "back-text-image-square" &&
                        "border-blue border-[3px] rounded-md"
                        }`}
                      src={BackSmallWithImage}
                      alt="image"
                    />
                  </div>
                </>
              ) : (
                <>
                  <div
                    onClick={() =>
                      handleUpdateTemplateData(
                        "front.layoutOption",
                        "front-text-left-image-right"
                      )
                    }
                    className="h-[6rem] w-full flex justify-center items-center"
                  >
                    <img
                      className={`h-full aspect-auto ${templateData.data.front.layoutOption ===
                        "front-text-left-image-right" &&
                        "border-blue border-[3px] rounded-md"
                        }`}
                      src={ImageRight}
                      alt="image"
                    />
                  </div>
                  <div
                    onClick={() =>
                      handleUpdateTemplateData(
                        "front.layoutOption",
                        "front-text-right-image-left"
                      )
                    }
                    className="h-[6rem] w-full flex justify-center items-center"
                  >
                    <img
                      className={`h-full aspect-auto ${templateData.data.front.layoutOption ===
                        "front-text-right-image-left" &&
                        "border-blue border-[3px] rounded-md"
                        }`}
                      src={ImageLeft}
                      alt="image"
                    />
                  </div>
                  <div
                    onClick={() =>
                      handleUpdateTemplateData(
                        "front.layoutOption",
                        "front-only-text"
                      )
                    }
                    className="h-[6rem] w-full flex justify-center items-center"
                  >
                    <img
                      className={`h-full aspect-auto ${templateData.data.front.layoutOption ===
                        "front-only-text" &&
                        "border-blue border-[3px] rounded-md"
                        }`}
                      src={SimpleText}
                      alt="image"
                    />
                  </div>
                  <div
                    onClick={() =>
                      handleUpdateTemplateData(
                        "front.layoutOption",
                        "front-only-text-square"
                      )
                    }
                    className="h-[6rem] w-full flex justify-center items-center"
                  >
                    <img
                      className={`h-full aspect-auto ${templateData.data.front.layoutOption ===
                        "front-only-text-square" &&
                        "border-blue border-[3px] rounded-md"
                        }`}
                      src={FrontSmallSimple}
                      alt="image"
                    />
                  </div>
                  <div
                    onClick={() =>
                      handleUpdateTemplateData(
                        "front.layoutOption",
                        "front-text-image-square"
                      )
                    }
                    className="h-[6rem] w-full flex justify-center items-center"
                  >
                    <img
                      className={`h-full aspect-auto ${templateData.data.front.layoutOption ===
                        "front-text-image-square" &&
                        "border-blue border-[3px] rounded-md"
                        }`}
                      src={FrontSmallWithImage}
                      alt="image"
                    />
                  </div>
                </>
              )}
            </TabsContent>
          </>
        )}
      </Tabs>
    </div>
  );
};

export default Settings;
