import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { BlockToolConstructorOptions } from '@editorjs/editorjs';
import ImageWithText from './ImageWithText';

type ImageToolProps = {
    data: {
        imageSrc: string | null;
        text: string;
        id: string;
    };
    onChange: ({ imageSrc, text , id}: {
        imageSrc: string | null;
        text: string;
        id: string;
    }) => void;
    params: any;
    topic: any;
};

class ImageToolComponent extends Component<ImageToolProps> {
    render() {
        const { userId, businessUnitId, pageId } = this.props.params;
        return (
            <ImageWithText
                data={this.props.data}
                onChange={this.props.onChange}
                params={{ userId, businessUnitId, pageId }}
                topic={this.props.topic}
            />
        );
    }
}

class ImageTool {
    data: any;
    api: any;
    wrapper: HTMLElement | null;
    config: any;

    constructor({ data, api, config }: BlockToolConstructorOptions) {
        this.data = data || {};
        this.api = api;
        this.wrapper = null;
        this.config = config;

    }

    render() {
        if (!this.wrapper) {
            this.wrapper = document.createElement("div");
            this.wrapper.classList.add("audio-tool-wrapper");
        } else {
            this.wrapper.innerHTML = ''; // Clear the wrapper's content before re-rendering
        }

        this.renderComponent();

        return this.wrapper;
    }

    renderComponent() {
        ReactDOM.render(
            <ImageToolComponent
                data={{...this.data, id:new Date().getTime()}}
                onChange={this.handleDataChange.bind(this)}
                params={this.config?.params || {}}
                topic={this.config?.topic || {}}
            />,
            this.wrapper
        );
    }

    handleDataChange(data: any) {
        this.data = data;
        console.log("Data changed:", this.data);
        this.renderComponent();
        // this.api.blocks.save();
    }

    currentBlock() {
        return this.api.blocks.getCurrentBlock();
    }

    save() {
        return this.data;
    }

    static get toolbox() {
        return {
            title: 'Image',
            icon: '<svg>...</svg>', // Add your custom icon SVG here
        };
    }
}

export default ImageTool;
