import React, { useState, useEffect } from "react";
import Navbar from "./Navbar";
import { cva, type VariantProps } from "class-variance-authority";
import { cn } from "src/components/lib/utils";
import OutputNav from "./OutputNav";

type Props = {
  children: JSX.Element;
  programName: string;
  BackgroundImage: any;
  device?: string;
  gotoMenuScreen: () => void;
  gotoAircraftScreen: () => void;
  gotoUserScreen: () => void;
  user: any;
  aircraft: any;
  menu: any;
};

export type Device =
  | "laptop"
  | "tabPortrait"
  | "tabLandscape"
  | "mobilePortrait"
  | "mobileLandscape"
  | "default"
  | null
  | undefined;

function Layout({ children, programName, BackgroundImage, device, gotoMenuScreen, gotoAircraftScreen, gotoUserScreen, user, aircraft, menu }: Props) {
  const [deviceVariantState, setDeviceVariantState] =
    useState<Device>("default");

  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  useEffect(() => {
    console.log(BackgroundImage);
  }, [BackgroundImage])

  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    // Todo: just commented background in testing, remove it afterwards
    <main
      style={{
        backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.70) 0%, rgba(0, 0, 0, 0.70) 100%), url(${BackgroundImage})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundColor: "lightgray",
        color: "#146793",
      }}
      className={`font-poppins flex flex-col ${device !== "laptop" && device !== "default" ? "h-screen" : "h-[calc(100vh-2.75rem)] md:h-[calc(100vh-3rem)] xl:h-[calc(100vh-3.5rem)]"} w-full`}
    >
      <OutputNav gotoMenuScreen={() => gotoMenuScreen()} gotoAircraftScreen={() => gotoAircraftScreen()} gotoUserScreen={() => gotoUserScreen()} user={user} aircraft={aircraft} menu={menu} />
      {children}
    </main>
  );
}

export default Layout;
