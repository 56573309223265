import React, { useState } from 'react';
import ChatGPT from '.';
import ChatGPTIcon from "../../assets/chatgpt.svg.webp";

const ChatComponent = () => {
  const [isComponentVisible, setComponentVisibility] = useState(false);

  const handleButtonClick = () => {
    // Toggle the visibility state
    setComponentVisibility(!isComponentVisible);
  };

  return (
    <div>
      <button onClick={handleButtonClick}>
        <img className="w-[40px]" src={ChatGPTIcon} alt="chatgpt-icon" />
      </button>

      {isComponentVisible && <OpenedComponent />}
    </div>
  );
};

const OpenedComponent = () => {
  return (
    <div>
      <ChatGPT />
      {/* Add the content of the opened component here */}
    </div>
  );
};

export default ChatComponent;

