import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { setSelectedComponent } from 'src/features/ComponentProperty/componentPeopertySlice';
import UploadImage from '../../UploadImage/UploadImage';
import RichTextEditor from 'src/utils/RichTE';

type Props = {
    handleDataChange: (data: any) => void;
    data: any;
}

function TextWImage({ data, handleDataChange }: Props) {
    const [haveLogoImage, setHaveLogoImage] = useState<boolean>(
        data?.haveImage ?? false);
    const [logoImage, setLogoImage] = useState<object | null>(data.logoImageFile ?? null);
    const [image, setImage] = useState<object | null>(data.imageFile ?? null);
    const [text, setText] = useState<string>(data?.text ?? "");

    useEffect(() => {
        console.log("data", data);
    }, [data]);

    const dispatch = useDispatch();
    return (
        <div className='overflow-y-scroll flex flex-grow'>
            <div className='space-y-6 w-full'>
                <div className='flex'>
                    <div className="pt-[1.25rem] px-[1.63rem] flex flex-col items-start space-y-4">
                        <div className="flex items-start space-x-2 font-[500] text-[1.125rem] leading-normal text-[#242424]">
                            <span>Logo</span>
                            <span className="text-[1rem] text-gray-400">(optional)</span>
                            <span>
                                <button
                                    onClick={() => {
                                        const newValue = !haveLogoImage;
                                        setHaveLogoImage(newValue);

                                        let updatedData = { ...data };
                                        if (newValue) {
                                            updatedData = { ...data, haveImage: newValue };
                                        } else {
                                            const { logoImageFile, ...rest } = data;
                                            updatedData = { ...rest, haveImage: newValue };
                                        }

                                        console.log(updatedData);
                                        handleDataChange(updatedData);
                                    }}
                                    className={`relative col-span-1 w-[42px] h-[24px] rounded-full cursor-pointer transition-colors ${haveLogoImage ? "bg-[#5934ff]" : "bg-gray-400"
                                        }`}
                                >
                                    <div
                                        className={`absolute top-1 left-1 w-[16px] h-[16px] bg-white rounded-full transition-transform ${haveLogoImage ? "transform translate-x-[18px]" : ""
                                            }`}
                                    ></div>
                                </button>
                            </span>
                        </div>
                        <div
                            onClick={() => {
                                const existingComponent = data?.componentProperties?.find(
                                    (comp: any) => comp.type === "Image"
                                );
                                if (existingComponent) {
                                    dispatch(
                                        setSelectedComponent({
                                            type: existingComponent.type,
                                            maxLength: existingComponent.maxLength,
                                            zone: existingComponent.zone,
                                        })
                                    );
                                }
                            }}
                            className="rounded-md border-2 border-gray-300"
                        >
                            {data?.haveImage && (
                                <div className="flex space-x-5 items-start">
                                    <UploadImage
                                        name="logoImageFile"
                                        type="image"
                                        image={data?.logoImageFile}
                                        handleUploadFromDevice={(file) => {
                                            setLogoImage(file);
                                            console.log(file);
                                            handleDataChange({ ...data, logoImageFile: file });
                                        }}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="pt-[1.25rem] px-[1.63rem] flex flex-col items-start space-y-4 w-full">
                        <div className="flex items-start space-x-2 font-[500] text-[1.125rem] leading-normal text-[#242424]">
                            <span>Main Image</span>
                        </div>
                        <div
                            onClick={() => {
                                const existingComponent = data?.componentProperties?.find(
                                    (comp: any) => comp.type === "Image"
                                );
                                if (existingComponent) {
                                    dispatch(
                                        setSelectedComponent({
                                            type: existingComponent.type,
                                            maxLength: existingComponent.maxLength,
                                            zone: existingComponent.zone,
                                        })
                                    );
                                }
                            }}
                            className="rounded-md border-2 border-gray-300"
                        >
                            <div className="flex space-x-5 items-start">
                                <UploadImage
                                    name="imageFile"
                                    type="image"
                                    image={data?.imageFile}
                                    handleUploadFromDevice={(file) => {
                                        setImage(file);
                                        console.log(file);
                                        handleDataChange({ ...data, imageFile: file });
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className='px-[1.63rem] w-[50rem]'>
                    <RichTextEditor
                        placeholder="Enter Acknowledgement Text"
                        value={text}
                        onChange={(content, contentLength) => {
                            console.log(content);
                            setText(content);
                            handleDataChange({ ...data, text: content });
                        }}
                    />
                </div>
            </div>


        </div>
    )
}

export default TextWImage