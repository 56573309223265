import React, { useEffect, useRef, useState } from "react";
import Imageicon from "../../assets/Upload/Image.svg";
import ViewIcon from "../../assets/Upload/Eye.svg";
import CropIcon from "../../assets/Upload/Crop.svg";
import DeleteIcon from "../../assets/Upload/TrashSimple.svg";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Modal from "src/utils/Modal";
import "react-image-crop/dist/ReactCrop.css";
//@ts-ignore
import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  Crop,
  PixelCrop,
  convertToPixelCrop,
} from "react-image-crop";
import { canvasPreview } from "./ImageCropComponents/canvasPreview";
import { useDebounceEffect } from "./ImageCropComponents/useDebounceEffect";
import "./ImageCropComponents/index.css";
import { ListObjects, getObectURL, putObectURL } from "src/helpers/AWS_S3";
import { useSearchParams } from "react-router-dom";
import { setAddToChildFiles } from "src/features/fileFolder/fileFolderSlice";
import { useDispatch } from "react-redux";
import useFolder, { ROOT_FOLDER } from "src/hooks/useFolder";
import { setPageState } from "src/features/templatePageState/templatePageStateSlice";

type Props = {
  openDrawerForm?: any;
  openCreateModal?: any;
  openEditModal?: any;
  pageState?: any;
  type?: string | null;
  name?: string;
  handleClick?: (name: string) => void;
  file?: any;
  handleCropFile?: (file: any) => void;
};

function centerAspectCrop(
  mediaWidth: number,
  mediaHeight: number,
  aspect: number
) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

const defaultAspect = 16 / 9;

function ImageDropdown({
  openDrawerForm,
  openCreateModal,
  openEditModal,
  file,
  handleCropFile,
  pageState,
  type,
  name,
  handleClick,
}: Props) {
  const [openImagePopup, setOpenImagePopup] = useState(false);
  const [openCropImagePopup, setOpenCropImagePopup] = useState(false);
  const [imageUrl, setimageUrl] = useState("");

  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  const imgRef = useRef<HTMLImageElement>(null);
  const [crop, setCrop] = useState<Crop>();
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [aspect, setAspect] = useState<number | undefined>(defaultAspect);
  const blobUrlRef = useRef("");
  const hiddenAnchorRef = useRef<HTMLAnchorElement>(null);
  const { authUser } = useSelector((state: any) => state.user);

  const [url, setUrl] = useState<any>("");
  const { folder } = useSelector((state: any) => state.fileFolder);
  const [searchParams, setSearchParams]: any = useSearchParams();
  const folderId = searchParams.get("folderId");
  const { accessToken } = useSelector((state: any) => state.accessToken);
  //const [showChangeMediaComponent, setChangeMediaComponent] = useState(false);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { businessUnitId, sepmId, templateId, userId } = useParams();

  const handleChangeMediaButtonClick = () => {
    if (handleClick && name) handleClick(name);
    navigate(
      `/user/${userId}/businessunit/${businessUnitId}/sepm/${sepmId}/template/${templateId}/media?screen=${searchParams.get(
        "screen"
      )}&folderId=null&type=${type}`
    );
    if (pageState) dispatch(setPageState(pageState));
  };

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate
        );
      }
    },
    100,
    [completedCrop, scale, rotate]
  );

  function onImageHandler() {
    getImageURL();
    setOpenImagePopup(true);
  }

  function onCropImageHandler() {
    getImageURL();
    setOpenCropImagePopup(true);
  }

  function handleToggleAspectClick() {
    if (aspect) {
      setAspect(undefined);
    } else {
      setAspect(defaultAspect);
      if (imgRef.current) {
        const { width, height } = imgRef.current;
        const newCrop = centerAspectCrop(width, height, 16 / 9);
        setCrop(centerAspectCrop(width, height, defaultAspect));
        setCompletedCrop(convertToPixelCrop(newCrop, width, height));
      }
    }
  }

  async function getImageURL() {
    console.log("Image URL:", file, file?.url);
    setimageUrl(file?.url);
  }

  async function onDownloadCropClick() {
    const image = imgRef.current;
    const previewCanvas = previewCanvasRef.current;
    if (!image || !previewCanvas || !completedCrop) {
      throw new Error("Crop canvas does not exist");
    }

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    const offscreen = new OffscreenCanvas(
      completedCrop.width * scaleX,
      completedCrop.height * scaleY
    );
    const ctx = offscreen.getContext("2d");
    if (!ctx) {
      throw new Error("No 2d context");
    }

    ctx.drawImage(
      previewCanvas,
      0,
      0,
      previewCanvas.width,
      previewCanvas.height,
      0,
      0,
      offscreen.width,
      offscreen.height
    );
    // You might want { type: "image/jpeg", quality: <0 to 1> } to
    // reduce image size
    const blob = await offscreen.convertToBlob({
      type: "image/jpeg",
    });

    if (blobUrlRef.current) {
      URL.revokeObjectURL(blobUrlRef.current);
    }

    const customImageName = file.name;

    blobUrlRef.current = URL.createObjectURL(blob);

    const url = new URL(blobUrlRef.current);
    const path = url.pathname;
    const parts = path.split("/");
    const imageFileName = parts[parts.length - 1];

    const finalImageFileName = customImageName;

    // Now, 'finalFileName' contains the chosen file name
    console.log(
      file.url + "Final File Name:",
      finalImageFileName + " FOLDER ID" + file.folderId
    );

    const UploadUrl = await putObectURL(
      `uploads/users/${authUser.oid}/${file.folderId}/CROP_${file.name}`,
      file.type
    );
    console.log(
      "upload url: ",
      UploadUrl,
      authUser.oid,
      finalImageFileName,
      file
    );
    axios
      .put(`${UploadUrl}`, blob, {
        onUploadProgress: (ProgressEvent) => console.log(ProgressEvent.loaded),
      })
      .then((response) => console.log(response.data))
      .catch((err) => console.log(err));

    const SignedUrl = await getObectURL(
      `uploads/users/${authUser.oid}/${file.folderId}/CROP_${file.name}`
    );

    console.log("this is the signed url: ", SignedUrl);

    setUrl(SignedUrl.split("?")[0]);

    const response = await axios.post(
      `${process.env.REACT_APP_SERVER_BASE_URL}/api/v1/file/`,
      {
        name: `CROP_${file.name}`,
        type: file.type,
        size: file.size,
        folderId: file.folderId,
        url: SignedUrl.split("?")[0],
      },
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    );
    console.log("Added files", response.data.data);
    if (handleCropFile) handleCropFile(response.data.data);
    setOpenCropImagePopup(false);
    //setbgFile(pageState.file); ;

    // if (hiddenAnchorRef.current) {
    //   hiddenAnchorRef.current.href = blobUrlRef.current
    //   hiddenAnchorRef.current.download = finalImageFileName
    //   hiddenAnchorRef.current.click()
    // }
  }

  function removeFile() {
    if (handleCropFile) handleCropFile(null);
  }

  return (
    <>
      {openDrawerForm || openCreateModal || openEditModal ? (
        <div className="z-[9999] absolute top-[2.7rem] bg-[#F5F5F5] w-[13rem] border-2 border-black/30 rounded-[1.5rem] py-[1.31rem] px-[1.06rem] space-y-[1.2rem] font-[500] text-[1rem]">
          <div className="flex space-x-[1.3rem] w-full text-[#242424] items-center">
            <span>
              <img src={Imageicon} alt="" />
            </span>
            <button onClick={() => handleChangeMediaButtonClick()}>
              Change Image
            </button>
          </div>

          <div className="flex space-x-[1.3rem] w-full text-[#242424] items-center">
            <span>
              <img src={ViewIcon} alt="" />
            </span>
            <button onClick={() => onImageHandler()}>View Image</button>
          </div>

          <div className="flex space-x-[1.3rem] w-full text-[#242424] items-center">
            <span>
              <img src={CropIcon} alt="" />
            </span>
            <button onClick={() => onCropImageHandler()}>Crop Image</button>
          </div>
          <div className="bg-[#2C2C2C] h-[0.063rem]"></div>
          <div className="flex space-x-[1.3rem] w-full text-[#C2262E] items-center">
            <span>
              <img src={DeleteIcon} alt="" />
            </span>
            <button onClick={removeFile}>Remove</button>
          </div>
        </div>
      ) : (
        <div className="absolute inline-block z-[99] ml-1 w-[13rem] bg-[#F5F5F5] border-2 border-black/30 rounded-[1.5rem] py-[1.31rem] px-[1.06rem] text-[1rem] font-[500] space-y-[1.25rem]">
          <div className="flex space-x-[1.3rem] w-full text-[#242424] items-center">
            <span>
              <img src={Imageicon} alt="" />
            </span>
            <button onClick={() => handleChangeMediaButtonClick()}>
              Change Image
            </button>
          </div>

          <div className="flex space-x-[1.3rem] w-full text-[#242424] items-center">
            <span>
              <img src={ViewIcon} alt="" />
            </span>
            <button onClick={() => onImageHandler()}>View Image</button>
          </div>

          <div className="flex space-x-[1.3rem] w-full text-[#242424] items-center">
            <span>
              <img src={CropIcon} alt="" />
            </span>
            <button onClick={() => onCropImageHandler()}>Crop Image</button>
          </div>
          <div className="bg-[#2C2C2C] h-[0.063rem]"></div>
          <div className="flex space-x-[1.3rem] w-full text-[#C2262E] items-center">
            <span>
              <img src={DeleteIcon} alt="" />
            </span>
            <button onClick={removeFile}>Remove</button>
          </div>
        </div>
      )}
      <Modal
        onOpened={openImagePopup}
        onClose={() => setOpenImagePopup(false)}
        title="Image Viewer"
        height="calc(100%-400px)"
      >
        <div className="max-w-[80vw] max-h-[80vh] overflow-auto flex flex-col h-full">
          <div className="grow w-full h-full">
            <img src={imageUrl} alt="Modal Image" className="w-full h-auto object-contain" />
          </div>
          <div className="pt-[2.31rem] pb-[0.81rem] space-x-[3.94rem] flex justify-center items-center"></div>
        </div>
      </Modal>
      <Modal
        onOpened={openCropImagePopup}
        onClose={() => setOpenCropImagePopup(false)}
        title="Image Viewer"
        height="calc(100%-400px)"
      >
        <div className="max-w-[90vw] max-h-[90vh] overflow-auto flex flex-col h-full">
          <div>
            <label htmlFor="scale-input">Scale: </label>
            <input
              id="scale-input"
              type="number"
              step="0.1"
              value={scale}
              disabled={!imageUrl}
              onChange={(e) => setScale(Number(e.target.value))}
            />
          </div>
          <div>
            <button onClick={handleToggleAspectClick}>
              Toggle aspect {aspect ? "off" : "on"}
            </button>
          </div>
          <div className="grow">
            {!!imageUrl && (
              <ReactCrop
                // className="ReactCrop--no-animate"
                crop={crop}
                onChange={(_: any, percentCrop: any) => setCrop(percentCrop)}
                onComplete={(c: any) => setCompletedCrop(c)}
                aspect={aspect}
                minWidth={20}
                minHeight={20}
              >
                <img
                  ref={imgRef}
                  alt="Crop me"
                  src={imageUrl}
                  style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
                  onLoad={onImageLoad}
                  crossOrigin="anonymous"
                />
              </ReactCrop>
            )}
            <div>
              {!!completedCrop && (
                <canvas
                  ref={previewCanvasRef}
                  style={{
                    border: "1px solid black",
                    objectFit: "contain",
                    width: completedCrop.width,
                    height: completedCrop.height,
                  }}
                />
              )}
            </div>
          </div>
          <div className="pt-[2.31rem] pb-[0.81rem] space-x-[3.94rem] flex justify-center items-center">
            <button
              onClick={onDownloadCropClick}
              className="w-full max-w-[8.9375rem] p-2  bg-[#AB0273] text-[#FFFFFF] rounded-[3.125rem] "
            >
              Done
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default ImageDropdown;
