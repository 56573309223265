import React, { Component } from "react";
import ReactDOM from "react-dom";
import { BlockToolConstructorOptions } from "@editorjs/editorjs";
import FlipCards from "./FlipCards";

type FlipCardsToolProps = {
  data: {
    buttonText: string;
    frontData: string;
    backData: string;
  };
  onChange: (buttonText: string, frontData: string, backData: string) => void;
};

class FlipCardsToolComponent extends Component<FlipCardsToolProps> {
  render() {
    return (
      <FlipCards
        buttonText={this.props.data.buttonText}
        frontData={this.props.data.frontData}
        backData={this.props.data.backData}
        onChange={this.props.onChange}
      />
    );
  }
}

class FlipCardsTool {
  private data: { buttonText: string; frontData: string; backData: string};
  private api: any;
  private wrapper: HTMLElement | null;

  constructor({ data, api }: BlockToolConstructorOptions) {
    this.data = data || { buttonText: "", frontData: "", backData: ""};
    this.api = api;
    this.wrapper = null;
  }

  render() {
    if (!this.wrapper) {
      this.wrapper = document.createElement("div");
      this.wrapper.classList.add("flipcards-tool-wrapper");
    } else {
      this.wrapper.innerHTML = ''; // Clear the wrapper's content before re-rendering
    }
  
    this.renderComponent();
  
    return this.wrapper;
  }

  renderComponent() {
    ReactDOM.render(
      <FlipCardsToolComponent
        data={this.data}
        onChange={this.handleTabsChange.bind(this)}
      />,
      this.wrapper
    );
  }

  handleTabsChange(buttonText: string, frontData: string, backData: string) {
    this.data = { ...this.data, buttonText: buttonText || "", frontData: frontData || "", backData: backData || ""};
    console.log(this.data);
    this.renderComponent(); // Re-render the component with the updated data
    // this.api.blocks.save(); // Save the block data when tabs change
  }

  save() {
    return this.data;
  }

  static get toolbox() {
    return {
      title: "Flip Cards",
      icon: "<svg>...</svg>", // Add your custom icon SVG here
    };
  }
}

export default FlipCardsTool;
