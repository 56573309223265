import React from "react"
import { ClickToReveal, Mcr, Scr, Tabs, TextWImage, TrueFalse } from "../index"

export const GetPreviewTemplate = new Map([
    ['mcr', (props: any) => <Mcr {...props} />],
    ['scr', (props: any) => <Scr {...props} />],
    ['true/false', (props: any) => <TrueFalse {...props} />],
    ['textwithimage', (props: any) => <TextWImage {...props} />],
    ['tabs', (props: any) => <Tabs {...props} />],
    ['clicktoreveal', (props: any) => <ClickToReveal {...props} />],
])
