import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    selectedCard: 'front',
};
export const selectedCardSlice = createSlice({
    name: "selectedCard",
    initialState,
    reducers: {
        setSelectedCard: (state, action) => {
            state.selectedCard = action.payload;
        },
    },
});

export const { setSelectedCard } = selectedCardSlice.actions;
export default selectedCardSlice.reducer;