import React, { useEffect, useState } from "react";
import Modal from "src/utils/Modal";
import Text from "src/utils/Text/Txt";
import InputText from "src/utils/TextInput/InputText";
import UploadButton from "src/utils/UploadButton";
import UploadDropdowns from "../UploadDropdowns";

type Props = {
  state?: any;
  openEditData?: any;
  editTitle?: any;
  openEditModal: any;
  editData: any;
  onSetEditData: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onHandleEdit: (file: any) => void;
  onHandleDelete: (id: any) => void;
  editedBounds: any[];
  handleEditDefineSection: () => void;
};

function EditSection({
  state,
  openEditData,
  openEditModal,
  editTitle,
  editData,
  onSetEditData,
  onHandleEdit,
  onHandleDelete,
  editedBounds,
  handleEditDefineSection,
}: Props) {
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [passUnitIdToDelete, setPassUnitIdToDelete] = useState<string | null>(
    null
  );

  const [file, setFile] = useState<any>(editData.file);

  useEffect(() => {
    console.log("this is state: ", state);

    if (state) {
      if (state.file) setFile(state.file);
    }
  }, [state]);

  useEffect(() => {
    console.log("this is editData: ", editData);
  }, [editData]);

  function handleDeletePopup(id: any) {
    setOpenDeletePopup(true);
    setPassUnitIdToDelete(id);
    console.log("delete id", passUnitIdToDelete);
  }

  function handleDelete(id: any) {
    onHandleDelete(id);

    setOpenDeletePopup(false);
  }

  return (
    <>
      <div className="flex flex-col h-full  px-[0.45rem]">
        <div className="grow space-y-[1.5rem] py-[1.25rem] overflow-y-scroll no-scrollbar ">
          <div className="">
            <InputText
              value={editData?.name}
              onChange={(e) => onSetEditData(e)}
              labelTitle="Name"
              autoFocus={true}
            />
          </div>

          <div className="flex flex-col space-y-3 px-[0.49rem]">
          <button onClick={() => handleEditDefineSection()} className="flex w-[8.875rem] bg-[#AB0273] px-[0.63rem] py-[0.3125rem] rounded-[1.25rem] border border-[#AB0273] items-center text-[1rem] font-[600] text-[#FFFFFF]">Define Section</button>
            {/* <div className="text-[16px] font-[600]">Section Image</div> */}
            <div className="flex space-x-1 items-center">
              {editedBounds.length === 0 ? (
                <>
                  <div className="relative flex flex-col items-centertext-[16px]">No bounds defined:</div>
                </>
              ) : (
                <>
                  <div className="relative flex flex-col items-centertext-[16px]">Defined bound:</div>
                  <div className="items-center overflow-hidden rounded-md border-2 px-2 py-1">
                    {editedBounds[0]}
                  </div>
                  <div className="items-center overflow-hidden rounded-md border-2 px-2 py-1">
                    {editedBounds[1]}
                  </div>
                </>
              )}
              {/* <UploadButton
                type={"image"}
                pageState={{
                  ...state,
                  openEditModal: openEditModal,
                  openEditData: openEditData,
                  editTitle: editTitle,
                  editData: editData,
                }}
                handleUploadFromDevice={(file) => {
                  setFile(file);
                  console.log(file);
                }}
              />
              <UploadDropdowns
                type={"image"}
                pageState={{
                  ...state,
                  openEditModal: openEditModal,
                  openEditData: openEditData,
                  editTitle: editTitle,
                  editData: editData,
                }}
                handleCropFile={(cropFile) => setFile(cropFile)}
                openEditModal={openEditModal}
                File={file}
              /> */}
            </div>
          </div>
        </div>

        <div className=" border-t px-[0.49rem] py-5 flex justify-between items-center">
          <button
            onClick={() => handleDeletePopup(editData.id)}
            className="px-6 py-3 text-[1rem] text-[#FFFFFF] bg-[#929292] rounded-[1.875rem]"
          >
            Delete
          </button>
          <button
            onClick={() => {
              onHandleEdit(editedBounds);
              // onHandleEdit(file);
            }}
            className="px-7 py-3 text-[1rem] text-[#FFFFFF] bg-[#AB0273] rounded-[1.875rem]"
          >
            Done
          </button>
        </div>
      </div>

      <Modal
        onOpened={openDeletePopup}
        onClose={() => setOpenDeletePopup(false)}
        title="Confirm Delete"
        height="calc(100%-400px)"
        passUnitIDtoDelete={passUnitIdToDelete || ""}
      >
        <div className="flex flex-col h-full items-center  pt-4 px-4 space-y-4">
          <div className="flex items-center justify-center w-full py-5">
            <span>Are you sure you want to delete this Language?</span>
          </div>

          <div className=" border-t-[0.0625rem] border-[#2C2C2C] py-5 w-full flex justify-center space-x-[4rem] items-center">
            <button
              onClick={() => setOpenDeletePopup(false)}
              className="text-[#FFFFFF] px-5 py-2 text-[16px] rounded-[1.875rem] bg-[#929292]"
            >
              Cancel
            </button>
            <button
              onClick={() => {
                handleDelete(passUnitIdToDelete);
              }}
              className="text-[#FFFFFF] px-5 py-2 text-[16px] rounded-[1.875rem] bg-red-600"
            >
              Delete
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default EditSection;
