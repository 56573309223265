import React, { useEffect, useState } from "react";
import InputText from "src/utils/TextInput/InputText";
import TemplateHeading from "src/utils/TextInput/TemplateHeading";
import debounce from "lodash/debounce";
import axios from "axios";
import { useDispatch } from "react-redux";
import { setSaveChanges } from "src/features/saveChanges/saveChangesSlice";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import UploadButton from "src/utils/UploadButton";
import SelectDropdown from "src/utils/SelectDropdown";
import { setSelectFile } from "src/features/fileFolder/fileFolderSlice";
import UploadDropdowns from "../UploadDropdowns";
import Loader from "src/utils/Loader";
import { setLog } from "src/features/errorLog/errorLogSlice";

type Props = {};

function FlightOpsTrainingScreen({}: Props) {
  const [title, setTitle] = useState<string>("");
  const [File, setFile] = useState<any | null>(null);

  const [documents, setDocuments] = useState<Array<any>>([]);

  const [content, setContent] = useState<any>();
  const [saving, setSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { accessToken } = useSelector((state: any) => state.accessToken);

  const {
    fileFolder: { file },
  } = useSelector((state: any) => state.fileFolder);

  // const { aircrafts, users } = useSelector((state: any) => state.aircraftsUsers);

  const [users, setUsers] = useState<any>([]);
  const [aircrafts, setAircrafts] = useState<any>([]);

  const dispatch = useDispatch();

  const { templateId } = useParams();

  // const debouncedAutoSave = debounce((newContent: any) => {
  //   setSaving(true);
  //   dispatch(setSaveChanges(true));
  //   // Send a POST request to your server to save the content
  //   axios
  //     .post(
  //       `http://localhost:8080/api/v1/template/save/${templateId}`,
  //       { content: newContent },
  //       {
  //         headers: { Authorization: `Bearer ${accessToken}` },
  //       }
  //     )
  //     .then(() => {
  //       setSaving(false);
  //       dispatch(setSaveChanges(false));
  //     })
  //     .catch((error) => {
  //       console.error("Auto-save failed:", error);
  //       setSaving(false);
  //       dispatch(setSaveChanges(false));
  //     });
  // }, 1000);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      dispatch(setSaveChanges(true));
      axios
        .post(
          `${process.env.REACT_APP_SERVER_BASE_URL}/api/v1/template/save/${templateId}`,
          { content },
          {
            headers: { Authorization: `Bearer ${accessToken}` },
          }
        )
        .then(() => {
          dispatch(setSelectFile({ file: null }));
          dispatch(setSaveChanges(false));
        })
        .catch((error) => {
          console.error("Auto-save failed:", error);
          dispatch(setLog({ mode: "failure", message: error }));
          setSaving(false);
          dispatch(setSaveChanges(false));
        })
        .finally(() => setIsLoading(false));
    }, 5000);
    return () => clearTimeout(timeoutId);
  }, [content]);

  useEffect(() => {
    handleContentChange();
  }, [title, documents, File]);

  useEffect(() => {
    getContent();
  }, []);

  async function getContent() {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_BASE_URL}/api/v1/template/content/${templateId}`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );

      if (response && response.data) {
        console.log("template details:", response.data.data);
        setDocuments(response.data.data.content.documents);
        setFile(response.data.data.content.bgfile);
        setAircrafts(response.data.data.content.aircrafts);
        setUsers(response.data.data.content.users);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handleContentChange = () => {
    if (file) setFile(file);
    const newContent = {
      documents,
      bgfile: File,
    };
    setContent(newContent);
    // Call the debounced auto-save function with the new content
    // debouncedAutoSave(newContent);
  };

  return (
    <div className="">
      <TemplateHeading title="Flight-Ops Training Memo" />

      <div className="grid grid-cols-4 mx-[0.49rem] items-center">
        <div className="col-span-1 font-[600]  text-[1rem] text-[#242424] leading-normal">Background Image</div>
        <div className="col-span-3 flex space-x-[0.75rem] items-center">
          <UploadButton 
          name="bgImageFile"
          // handleClick={(name) => {console.log(name); setUploadButtonFor(name)}}
          type="image"
          handleUploadFromDevice={(file) => {
            setFile(file);
            // console.log(file);
          }}
          />
          <UploadDropdowns 
          name="bgImageFile"
          type="image"
          handleCropFile={(cropFile) => setFile(cropFile)}
          File={File}  />
        </div>
      </div>

      <div className="grid grid-cols-4 mt-[1.25rem] mx-[0.49rem] items-center">
        <div className="col-span-1 font-[600]  text-[1rem] text-[#242424] leading-normal">Select Aircraft</div>
        <div className="col-span-3">
        <SelectDropdown
          isLoading={false}
          SelectAircraftButton="Select Aircraft"
          aircrafts={aircrafts}
          users={users}
          documents={documents || []}
          onHandleDocuments={(documents, isLoading) => {
            // console.log( isLoading);
            if (isLoading) setIsLoading(true);
            setDocuments(documents);
          }}
          DocumentType="Flight-Ops Training Document"
        />
        </div>
      </div>

      {isLoading && (
        <div className="bg-black/50 z-[99999] backdrop-blur-sm fixed top-0 bottom-0 left-0 right-0 flex items-center justify-center">
          {/* <span className="text-white text-xl font-bold">Loading...</span> */}
          <Loader loading={isLoading} color="#ffffff" />
        </div>
      )}
    </div>
  );
}

export default FlightOpsTrainingScreen;
