import React from "react";
import SimpleTextIcon from "./SimpleTextIcon";
import TextWithHeadingIcon from "./TextWithHeadingIcon";
import TwoColTextIcon from "./TwoColTextIcon";
import ThreeColTextIcon from "./ThreeColTextIcon";
import TextWithHyperlinkIcon from "./TextWithHyperlinkIcon";

function SidebarWidgets() {
  return (
    <div className="flex flex-col space-y-2 items-center justify-center  max-h-[calc(100vh-112px)] pt-10 pb-2 px-4">
      {/* <SimpleTextIcon /> */}
      <TextWithHeadingIcon />
      {/* <TwoColTextIcon /> */}
      {/* <ThreeColTextIcon /> */}
      <TextWithHyperlinkIcon />
    </div>
  );
}

export default SidebarWidgets;
