import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setSelectedComponent } from "src/features/ComponentProperty/componentPeopertySlice";
import UploadImage from "../../UploadImage/UploadImage";
import RichTextEditor from "src/utils/RichTE";
import { DefaultTemplateData, Tab } from "./utils/DefaultTemplateData";
import { OverflowMenuHorizontal } from "@carbon/icons-react";
import { v4 as uuid } from "uuid";
import { GetTemplate } from "./utils/GetTemplate";
import { CircleSlash, List, ListTodo } from "lucide-react";
import { useSelector } from "react-redux";
import { setSelectedTab } from "src/features/selectedTab/selectedTabSlice";

type Props = {
  handleDataChange: (data: any) => void;
  data: any;
  handleSelectedTemplate?: (tabTemplateType: string) => void;
};

function Tabs({ data, handleDataChange, handleSelectedTemplate }: Props) {
  const [tabsData, setTabsData] = useState<Tab[]>(data?.tabs);
  // const [selectedTab, setSelectedTab] = useState<Tab>(data?.tabs[0]);

  const { selectedTab } = useSelector((state: any) => state.selectedTab)

  const dispatch = useDispatch();

  useEffect(() => {
    console.log(tabsData);
    const updatedData = { ...data, tabs: tabsData };
    handleDataChange(updatedData);
  }, [tabsData]);

  useEffect(() => {
    dispatch(setSelectedTab(data?.tabs[0]));
  }, []);

  useEffect(() => {
    console.log("data", data);
  }, [data]);

  useEffect(() => {
    if (handleSelectedTemplate) {
      handleSelectedTemplate(selectedTab?.content?.templateType);
    }
  }, [selectedTab]);

  const handleTabsChange = (updatedTabs: Tab[]) => {
    setTabsData(updatedTabs);
    const updatedData = { ...data, tabs: updatedTabs };
    console.log(updatedTabs, updatedData)
    handleDataChange(updatedData);
  };

  return (
    <div className="grow overflow-y-scroll">
      {data?.tabsType === "vertical" ? (
        <></>
      ) : (
        // <VerticalTabs tabs={tabsData} selectedTab={selectedTab} setSelectedTab={setSelectedTab} onChange={handleTabsChange}/>
        <HorizontalTabs
          tabs={tabsData}
          selectedTab={selectedTab}
          setSelectedTab={(tab) => dispatch(setSelectedTab(tab))}
          onChange={handleTabsChange}
        />
      )}
    </div>
  );
}

export default Tabs;

type HProps = {
  tabs: Tab[];
  selectedTab: Tab;
  setSelectedTab: (tab: Tab) => void;
  onChange: (updatedTabs: Tab[]) => void;
};

const TemplatesArray = [
  {
    id: 1,
    title: "MCR",
    type: "mcr",
    description: "Multiple Choice Response",
    icon: <ListTodo size={24} />,
  },
  {
    id: 2,
    title: "SCR",
    type: "scr",
    description: "Single Choice Response",
    icon: <List size={24} />,
  },
  {
    id: 3,
    title: "True/False",
    type: "true/false",
    description: "True or False",
    icon: <CircleSlash size={24} />,
  },
  {
    id: 4,
    title: "Text with Image",
    type: "textwithimage",
    description: "Text with Image",
    icon: <CircleSlash size={24} />,
  },
];

export type Template = {
  id: number;
  title: string;
  type: string;
  description: string;
  data?: any;
};

function HorizontalTabs({
  tabs,
  selectedTab,
  setSelectedTab,
  onChange,
}: HProps) {
  const [isMenuOpen, setIsMenuOpen] = useState<string>("");
  const [opendropdown, setopendropdown] = useState(false);
  const [templateData, setTemplateData] = useState();

  useEffect(() => {
    console.log(tabs);
    console.log(selectedTab);
  }, [tabs, selectedTab]);

  const addTab = () => {
    const newTab: Tab = {
      _id: uuid(),
      content: {
        templateType: "",
        templateData: {},
      },
      title: `Tab ${tabs.length + 1}`,
    };
    onChange([...tabs, newTab]);
    setSelectedTab(newTab);
  };

  function handleTemplateSelection(template: any, selectedTab: string) {
    const updatedTabs = tabs?.map((tab: Tab) => {
      if (tab._id === selectedTab) {
        setSelectedTab({
          ...tab,
          content: {
            ...tab.content,
            templateType: template.type,
            templateData: {
              id: new Date().getTime(),
              title: template.title,
              type: template.type,
              description: template.description,
              data: template.data || DefaultTemplateData.get(template.type),
            },
          },
        });
        return {
          ...tab,
          content: {
            ...tab.content,
            templateType: template.type,
            templateData: {
              id: new Date().getTime(),
              title: template.title,
              type: template.type,
              description: template.description,
              data: template.data || DefaultTemplateData.get(template.type),
            },
          },
        };
      }
      return tab;
    });
    setopendropdown(false);
    onChange(updatedTabs);
  }

  function handleRename(selectedTab: Tab, newTitle: string) {
    setSelectedTab({ ...selectedTab, title: newTitle });
    const updatedTabs = tabs?.map((tab: Tab) => {
      if (tab._id === selectedTab._id) {
        return {
          ...tab, title: newTitle
        }
      }
      return tab;
    });
    onChange(updatedTabs);
  }

  function handleTemplateDataChange(data: any) {
    setSelectedTab({
      ...selectedTab,
      content: {
        ...selectedTab.content,
        templateData: {
          ...selectedTab.content.templateData,
          data: data,
        },
      },
    })
    const updatedTabs = tabs?.map((tab: Tab) => {
      if (tab._id === selectedTab._id) {
        return {
          ...tab,
          content: {
            ...tab.content,
            templateData: {
              ...tab.content.templateData,
              data: data,
            },
          },
        }
      }
      return tab;
    });
    onChange(updatedTabs);
  }

  return (
    <div className="flex flex-col p-4 w-full">
      <div className="flex flex-wrap">
        {tabs?.map((tab, idx: number) => (
          <div key={tab._id} className="group relative flex ">
            <button
              key={tab._id}
              onClick={() => setSelectedTab(tab)}
              className={`block line-clamp-1 w-32 px-3 py-2 text-left mr-2 mb-2 hover:bg-[#c4c4c4] rounded-md ${tab._id === selectedTab?._id ? "bg-[#c4c4c4]" : "bg-gray-200"
                }`}
              title={tab?.title}
            >
              <span className="line-clamp-1">{tab?.title}</span>
            </button>
            <button
              //   onClick={() => toggleMenu(tab.id)}
              className={`absolute group-hover:visible right-3 top-2 `}
            //   className={`absolute group-hover:visible right-3 top-2 ${
            //     isMenuOpen !== tab.id ? "invisible" : "visible"
            //   }`}
            >
              <OverflowMenuHorizontal size="24" />
            </button>
            {/* {isMenuOpen === tab._id && (
              <div className="absolute top-10 left-7 w-36 bg-white border rounded shadow-lg z-10">
                <button
                  //   onClick={() => openRenameModal(tab)}
                  className="block px-4 py-2 text-left w-full hover:bg-[#E0CBD9] hover:bg-purple/20"
                >
                  Rename
                </button>
                <button
                  //   onClick={() => {
                  //     handleRemoveTab(tab.id);
                  //     setIsMenuOpen(null);
                  //   }}
                  className="block px-4 py-2 text-left w-full hover:bg-[#E0CBD9] hover:bg-purple/20"
                >
                  Remove
                </button>
              </div>
            )} */}
          </div>
        ))}
        <button
          onClick={() => addTab()}
          className="block w-32 px-4 py-2 mb-2 text-[1rem] bg-[#AB0273] text-[#ffffff] rounded-md hover:bg-tertiary/50"
        >
          Add Tab +
        </button>
      </div>

      <div className="w-full h-full flex flex-col items-center mt-4">
        {selectedTab !== null && (
          <>
            {selectedTab ? (
              <div key={selectedTab?._id} className="w-full h-full space-y-5">
                <div className="flex flex-col space-y-3 mt-[1.25rem] mx-[1.63rem]">
                  <span>Tab Label: </span>
                  <input type="text" value={selectedTab?.title} onChange={(e) => handleRename(selectedTab, e.target.value)} className="outline-none w-72 border-2 border-gray-400 rounded-md flex px-5 py-3 hover:border-black" />
                </div>
                <div className="flex space-x-6 items-start pt-[1.25rem] px-[1.63rem]">
                  <span>Choose Template: </span>
                  <div className=" space-y-1">
                    <button onClick={() => setopendropdown(!opendropdown)}
                      className="bg-gray-200 rounded-md px-3 py-1"
                    >
                      {selectedTab?.content?.templateData?.title ? (
                        `${selectedTab?.content?.templateData?.title}`
                      ) : (
                        "Templates"
                      )}
                    </button>
                    {opendropdown && (
                      <div className="flex flex-col p-3 items-start border-2 border-gray-300 shadow-md rounded-lg bg-gray-100">
                        <button
                          onClick={() => {
                            const template = TemplatesArray.find(
                              (template: any) => template.type === "scr"
                            );
                            handleTemplateSelection(template, selectedTab._id);
                          }}
                          className="p-[0.72rem] rounded-[1.875rem] hover:bg-tertiary/25"
                        >
                          SCR
                        </button>
                        <button
                          onClick={() => {
                            const template = TemplatesArray.find(
                              (template: any) => template.type === "mcr"
                            );
                            handleTemplateSelection(template, selectedTab._id);
                          }}
                          className="p-[0.72rem] rounded-[1.875rem] hover:bg-tertiary/25"
                        >
                          MCR
                        </button>
                        <button
                          onClick={() => {
                            const template = TemplatesArray.find(
                              (template: any) => template.type === "true/false"
                            );
                            handleTemplateSelection(template, selectedTab._id);
                          }}
                          className="p-[0.72rem] rounded-[1.875rem] hover:bg-tertiary/25"
                        >
                          True/False
                        </button>
                        <button
                          onClick={() => {
                            const template = TemplatesArray.find(
                              (template: any) =>
                                template.type === "textwithimage"
                            );
                            handleTemplateSelection(template, selectedTab._id);
                          }}
                          className="p-[0.72rem] rounded-[1.875rem] hover:bg-tertiary/25"
                        >
                          Text with Image
                        </button>
                      </div>
                    )}
                  </div>
                </div>

                {/* {JSON.stringify(selectedTab)} */}
                {selectedTab.content.templateType ? (
                  <div className="">
                    {(() => {
                      console.log(selectedTab);
                      const TemplateComponent = GetTemplate.get(
                        selectedTab.content.templateType
                      );
                      return TemplateComponent ? (
                        <TemplateComponent
                          optionQuantity={
                            selectedTab.content.templateData.data.optionquantity
                          }
                          data={selectedTab.content.templateData.data}
                          handleDataChange={(data: any) => {
                            console.log(data);
                            handleTemplateDataChange(data);
                          }}
                          handleImageOrOptionClicked={(id: string) => { console.log(id);  }}
                        />
                      ) : null;
                    })()}
                  </div>
                ) : (
                  <div className="flex flex-col justify-center items-center pt-[5rem] text-sm text-gray-500">
                    <span>No template selected</span>
                    <span>Choose template from above</span>
                  </div>
                )}
              </div>
            ) : null}
          </>
        )}
      </div>
    </div>
  );
}
