import React from 'react';
import Layout from './Layout';

function PreviewPage() {
  return (
    <div>
      {/* <h1>PreviewPage</h1> */}
      <Layout/>
    
    </div>
  )
}

export default PreviewPage;
