import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    isAuthenticated: false,
    authUser: null
}
export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setIsAuthenticated: (state, action) => {
            state.isAuthenticated = action.payload
        },
        setAuthUser: (state, action) => {
            state.authUser = action.payload
        }
    }
})

export const {setIsAuthenticated, setAuthUser} = userSlice.actions
export default userSlice.reducer