import { color } from "d3";
import { max } from "lodash";
import { Type } from "lucide-react";
import React from "react";
import { v4 as uuid } from "uuid";

export type Option = {
    _id: string;
    value: string;
    correct: boolean;
    response: boolean;
    feedback?: string;
    optionImage?: {
        _id: string;
        url: string;
        name: string;
        type: string;
        size: number;
        createdOn: number;
        folderId: string | null;
        userId: string;
        __v: number;
    };
};

export type ComponentProperty = {
    type: string;
    maxLength?: number;
    zone: number[][];
};

export type Question = {
    question: string;
    prompt: string;
    optionquantity?: number;
    timer?: number;
    options: Option[];
    haveImage: boolean;
    imageFile: object | null;
    globalFeedback?: string;
    isSubmitType?: boolean;
    isNextType?: boolean;
    isGlobalPopup?: boolean;
    isTimer?: boolean;
    haveOptionImage?: boolean;
    componentProperties?: ComponentProperty[];
};

const defaultMcrData: any = {
    question: "Choose the airbuses that are available in CATHAY PACIFIC",
    prompt: "Select multiple options",
    options: [
        { _id: uuid(), value: "A320", correct: true, response: false, feedback: "" },
        { _id: uuid(), value: "A330", correct: true, response: false, feedback: "" },
        { _id: uuid(), value: "A340", correct: true, response: false, feedback: "" },
        { _id: uuid(), value: "A350", correct: true, response: false, feedback: "" },
    ],
    haveImage: false,
    imageFile: null,
    globalFeedback: "",
    isGlobalPopup: false,
    isTimer: false,
    haveOptionImage: false,
    optionquantity: 4,
    timer: 60,
    componentProperties: [
        { type: 'Question', maxLength: 150, zone: [[1, 1], [0, 0], [0, 0], [0, 0]] },
        { type: 'Image', zone: [[0, 0], [0, 1], [0, 1], [0, 0]] },
        { type: 'Prompt', maxLength: 80, zone: [[0, 0], [1, 0], [0, 0], [0, 0]] },
        { type: 'Choices', maxLength: 55, zone: [[0, 0], [0, 0], [1, 0], [0, 0]] },
        {
            type: 'Choices Feedback',
            maxLength: 30,
            zone: [[0, 0], [0, 0], [0, 1], [0, 0]]
        },
        {
            type: 'Global Feedback',
            maxLength: 150,
            zone: [[0, 0], [0, 0], [0, 0], [1, 1]]
        },
    ],
    styles: {
        container: {
            width: "100%",
            height: "100%",
            paddingTop: "0px",
            paddingRight: "0px",
            paddingBottom: "0px",
            paddingLeft: "0px",
            borderRadius: "0px",
            backgroundColor: "#ffffff"
        },
        questionContainer: {
            width: "100%",
            height: "100%",
            x: 0,
            y: 0,
            paddingTop: "20px",
            paddingRight: "0px",
            paddingBottom: "0px",
            paddingLeft: "30px",
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            // backgroundColor: "#ffffff",
        },
        question: {
            width: "100%",
            height: "40px",
            x: 0,
            y: 0,
            // paddingTop: "0px",
            // paddingRight: "0px",
            // paddingBottom: "0px",
            // paddingLeft: "0px",
            // justifyContent: 'center',
            // alignItems: 'center',
            // backgroundColor: "#ffffff",
            color: "#000000",
        },

        promptContainer: {
            width: "100%",
            height: "100%",
            x: 0,
            y: 0,
            paddingTop: "0px",
            paddingRight: "0px",
            paddingBottom: "0px",
            paddingLeft: "30px",
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            // backgroundColor: "#ffffff",
        },

        prompt: {
            width: "50%",
            height: "20px",
            x: 0,
            y: 0,
            // paddingTop: "0px",
            // paddingRight: "0px",
            // paddingBottom: "0px",
            // paddingLeft: "0px",
            // justifyContent: 'center',
            // alignItems: 'center',
            // overflowY: "auto",
            // backgroundColor: "#ffffff",
            color: "#000000",
        },

        optionsContainer: {
            width: "100%",
            height: "100%",
            x: 0,
            y: 0,
            paddingTop: "40px",
            paddingRight: "0px",
            paddingBottom: "0px",
            paddingLeft: "30px",
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
        },

        option: {
            width: "75%",
            height: "250px",
            x: 0,
            y: 0,
            // paddingTop: "0px",
            // paddingRight: "0px",
            // paddingBottom: "0px",
            // paddingLeft: "0px",
            // justifyContent: 'center',
            // alignItems: 'center',
            // overflowY: "auto",
            // backgroundColor: "#ffffff",
            color: "#000000",
        },
        feedbackOption: {
            width: "75%",
            height: "100%",
            x: 0,
            y: 0,
            // paddingTop: "0px",
            // paddingRight: "0px",
            // paddingBottom: "0px",
            // paddingLeft: "0px",
            // justifyContent: 'center',
            // alignItems: 'center',
            // overflowY: "auto",
            backgroundColor: "#000000",
            opacity: 0.5,
            color: "#ffffff",
        },
        imageContainer: {
            width: "100%",
            height: "100%",
            x: 0,
            y: 0,
            paddingTop: "0px",
            paddingRight: "0px",
            paddingBottom: "0px",
            paddingLeft: "0px",
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            // backgroundColor: "#ffffff",
        },
        image: {
            width: "100%",
            height: "100%",
            x: 0,
            y: 0,
            // paddingTop: "0px",
            // paddingRight: "0px",
            // paddingBottom: "0px",
            // paddingLeft: "0px",
            objectFit: 'contain',
            // justifyContent: 'center',
            // alignItems: 'center',
        },
        globalFeedbackContainer: {
            width: "100%",
            height: "100%",
            x: 0,
            y: 0,
            paddingTop: "0px",
            paddingRight: "0px",
            paddingBottom: "0px",
            paddingLeft: "30px",
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            // backgroundColor: "#ffffff",
        },
        globalFeedback: {
            width: "100%",
            height: "20px",
            x: 0,
            y: 0,
            // paddingTop: "0px",
            // paddingRight: "0px",
            // paddingBottom: "0px",
            // paddingLeft: "0px",
            // justifyContent: 'center',
            // alignItems: 'center',
            // overflowY: "auto",
            // backgroundColor: "#ffffff",
            // color: "#000000",
        },
    }
};

const defaultScrData: any = {
    question: "During the safety demonstration, passengers are shown how to use which of the following?",
    prompt: "Select an option",
    options: [
        { _id: uuid(), value: "The overhead reading light", correct: false, response: false, feedback: "" },
        { _id: uuid(), value: "The in-flight Wi-Fi system", correct: false, response: false, feedback: "" },
        { _id: uuid(), value: "The oxygen mask and life vest", correct: true, response: false, feedback: "" },
        { _id: uuid(), value: "The food and beverage menu", correct: false, response: false, feedback: "" },
    ],
    haveImage: false,
    imageFile: null,
    globalFeedback: "",
    isSubmitType: true,
    isNextType: false,
    isGlobalPopup: false,
    isTimer: false,
    haveOptionImage: false,
    optionquantity: 4,
    timer: 60,
    componentProperties: [
        { type: 'Question', maxLength: 150, zone: [[1, 1], [0, 0], [0, 0], [0, 0]] },
        { type: 'Image', zone: [[0, 0], [0, 1], [0, 1], [0, 0]] },
        { type: 'Prompt', maxLength: 80, zone: [[0, 0], [1, 0], [0, 0], [0, 0]] },
        { type: 'Choices', maxLength: 55, zone: [[0, 0], [0, 0], [1, 0], [0, 0]] },
        {
            type: 'Choices Feedback',
            maxLength: 30,
            zone: [[0, 0], [0, 0], [0, 1], [0, 0]]
        },
        {
            type: 'Global Feedback',
            maxLength: 150,
            zone: [[0, 0], [0, 0], [0, 0], [1, 1]]
        }
    ],
    styles: {
        container: {
            width: "100%",
            height: "100%",
            paddingTop: "0px",
            paddingRight: "0px",
            paddingBottom: "0px",
            paddingLeft: "0px",
            borderRadius: "0px",
            backgroundColor: "#ffffff"
        },
        questionContainer: {
            width: "100%",
            height: "100%",
            x: 0,
            y: 0,
            paddingTop: "20px",
            paddingRight: "0px",
            paddingBottom: "0px",
            paddingLeft: "30px",
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            // backgroundColor: "#ffffff",
        },
        question: {
            width: "100%",
            height: "40px",
            x: 0,
            y: 0,
            // paddingTop: "0px",
            // paddingRight: "0px",
            // paddingBottom: "0px",
            // paddingLeft: "0px",
            // justifyContent: 'center',
            // alignItems: 'center',
            // backgroundColor: "#ffffff",
            color: "#000000",
        },

        promptContainer: {
            width: "100%",
            height: "100%",
            x: 0,
            y: 0,
            paddingTop: "0px",
            paddingRight: "0px",
            paddingBottom: "0px",
            paddingLeft: "30px",
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            // backgroundColor: "#ffffff",
        },

        prompt: {
            width: "50%",
            height: "20px",
            x: 0,
            y: 0,
            // paddingTop: "0px",
            // paddingRight: "0px",
            // paddingBottom: "0px",
            // paddingLeft: "0px",
            // justifyContent: 'center',
            // alignItems: 'center',
            // overflowY: "auto",
            // backgroundColor: "#ffffff",
            color: "#000000",
        },

        optionsContainer: {
            width: "100%",
            height: "100%",
            x: 0,
            y: 0,
            paddingTop: "40px",
            paddingRight: "0px",
            paddingBottom: "0px",
            paddingLeft: "30px",
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
        },

        option: {
            width: "75%",
            height: "250px",
            x: 0,
            y: 0,
            // paddingTop: "0px",
            // paddingRight: "0px",
            // paddingBottom: "0px",
            // paddingLeft: "0px",
            // justifyContent: 'center',
            // alignItems: 'center',
            // overflowY: "auto",
            // backgroundColor: "#ffffff",
            color: "#000000",
        },
        feedbackOption: {
            width: "75%",
            height: "100%",
            x: 0,
            y: 0,
            // paddingTop: "0px",
            // paddingRight: "0px",
            // paddingBottom: "0px",
            // paddingLeft: "0px",
            // justifyContent: 'center',
            // alignItems: 'center',
            // overflowY: "auto",
            backgroundColor: "#000000",
            opacity: 0.5,
            color: "#ffffff",
        },
        imageContainer: {
            width: "100%",
            height: "100%",
            x: 0,
            y: 0,
            paddingTop: "0px",
            paddingRight: "0px",
            paddingBottom: "0px",
            paddingLeft: "0px",
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            // backgroundColor: "#ffffff",
        },
        image: {
            width: "100%",
            height: "100%",
            x: 0,
            y: 0,
            // paddingTop: "0px",
            // paddingRight: "0px",
            // paddingBottom: "0px",
            // paddingLeft: "0px",
            objectFit: 'contain',
            // justifyContent: 'center',
            // alignItems: 'center',
        },
        globalFeedbackContainer: {
            width: "100%",
            height: "100%",
            x: 0,
            y: 0,
            paddingTop: "0px",
            paddingRight: "0px",
            paddingBottom: "0px",
            paddingLeft: "30px",
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            // backgroundColor: "#ffffff",
        },
        globalFeedback: {
            width: "100%",
            height: "20px",
            x: 0,
            y: 0,
            // paddingTop: "0px",
            // paddingRight: "0px",
            // paddingBottom: "0px",
            // paddingLeft: "0px",
            // justifyContent: 'center',
            // alignItems: 'center',
            // overflowY: "auto",
            // backgroundColor: "#ffffff",
            // color: "#000000",
        },
    }
}

const defaultTrueFalseData: any = {
    question: "Passengers are allowed to carry more than one piece of hand luggage onboard if the total weight is under 7 kg.",
    prompt: "Select True or False",
    options: [
        { _id: uuid(), value: "True", correct: false, response: false },
        { _id: uuid(), value: "False", correct: true, response: false },
    ],
    haveImage: false,
    imageFile: null,
    globalFeedback: "",
    isSubmitType: true,
    isNextType: false,
    isGlobalPopup: false,
    isTimer: false,
    haveOptionImage: false,
    optionquantity: 4,
    timer: 60,
    componentProperties: [
        { type: 'Question', maxLength: 150, zone: [[1, 1], [0, 0], [0, 0], [0, 0]] },
        { type: 'Image', zone: [[0, 0], [0, 1], [0, 1], [0, 0]] },
        { type: 'Prompt', maxLength: 80, zone: [[0, 0], [1, 0], [0, 0], [0, 0]] },
        { type: 'Choices', maxLength: 55, zone: [[0, 0], [0, 0], [1, 0], [0, 0]] },
        {
            type: 'Choices Feedback',
            maxLength: 30,
            zone: [[0, 0], [0, 0], [0, 1], [0, 0]]
        },
        {
            type: 'Global Feedback',
            maxLength: 150,
            zone: [[0, 0], [0, 0], [0, 0], [1, 1]]
        }
    ],
    styles: {
        container: {
            width: "100%",
            height: "100%",
            paddingTop: "0px",
            paddingRight: "0px",
            paddingBottom: "0px",
            paddingLeft: "0px",
            borderRadius: "0px",
            backgroundColor: "#ffffff"
        },
        questionContainer: {
            width: "100%",
            height: "100%",
            x: 0,
            y: 0,
            paddingTop: "20px",
            paddingRight: "0px",
            paddingBottom: "0px",
            paddingLeft: "30px",
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            // backgroundColor: "#ffffff",
        },
        question: {
            width: "100%",
            height: "40px",
            x: 0,
            y: 0,
            // paddingTop: "0px",
            // paddingRight: "0px",
            // paddingBottom: "0px",
            // paddingLeft: "0px",
            // justifyContent: 'center',
            // alignItems: 'center',
            // backgroundColor: "#ffffff",
            color: "#000000",
        },

        promptContainer: {
            width: "100%",
            height: "100%",
            x: 0,
            y: 0,
            paddingTop: "0px",
            paddingRight: "0px",
            paddingBottom: "0px",
            paddingLeft: "30px",
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            // backgroundColor: "#ffffff",
        },

        prompt: {
            width: "50%",
            height: "20px",
            x: 0,
            y: 0,
            // paddingTop: "0px",
            // paddingRight: "0px",
            // paddingBottom: "0px",
            // paddingLeft: "0px",
            // justifyContent: 'center',
            // alignItems: 'center',
            // overflowY: "auto",
            // backgroundColor: "#ffffff",
            color: "#000000",
        },

        optionsContainer: {
            width: "100%",
            height: "100%",
            x: 0,
            y: 0,
            paddingTop: "40px",
            paddingRight: "0px",
            paddingBottom: "0px",
            paddingLeft: "30px",
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
        },

        option: {
            width: "75%",
            height: "250px",
            x: 0,
            y: 0,
            // paddingTop: "0px",
            // paddingRight: "0px",
            // paddingBottom: "0px",
            // paddingLeft: "0px",
            // justifyContent: 'center',
            // alignItems: 'center',
            // overflowY: "auto",
            // backgroundColor: "#ffffff",
            color: "#000000",
        },
        feedbackOption: {
            width: "75%",
            height: "100%",
            x: 0,
            y: 0,
            // paddingTop: "0px",
            // paddingRight: "0px",
            // paddingBottom: "0px",
            // paddingLeft: "0px",
            // justifyContent: 'center',
            // alignItems: 'center',
            // overflowY: "auto",
            backgroundColor: "#000000",
            opacity: 0.5,
            color: "#ffffff",
        },
        imageContainer: {
            width: "100%",
            height: "100%",
            x: 0,
            y: 0,
            paddingTop: "0px",
            paddingRight: "0px",
            paddingBottom: "0px",
            paddingLeft: "0px",
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            // backgroundColor: "#ffffff",
        },
        image: {
            width: "100%",
            height: "100%",
            x: 0,
            y: 0,
            // paddingTop: "0px",
            // paddingRight: "0px",
            // paddingBottom: "0px",
            // paddingLeft: "0px",
            objectFit: 'contain',
            // justifyContent: 'center',
            // alignItems: 'center',
        },
        globalFeedbackContainer: {
            width: "100%",
            height: "100%",
            x: 0,
            y: 0,
            paddingTop: "0px",
            paddingRight: "0px",
            paddingBottom: "0px",
            paddingLeft: "30px",
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            // backgroundColor: "#ffffff",
        },
        globalFeedback: {
            width: "100%",
            height: "20px",
            x: 0,
            y: 0,
            // paddingTop: "0px",
            // paddingRight: "0px",
            // paddingBottom: "0px",
            // paddingLeft: "0px",
            // justifyContent: 'center',
            // alignItems: 'center',
            // overflowY: "auto",
            // backgroundColor: "#ffffff",
            // color: "#000000",
        },
    }
};

export type TextWImageData = {
    logoImageFile: object | null,
    text: string,
    imageFile: object | null,
}

const defaultTextWImageData: any = {
    logoImageFile: null,
    text: `<p style="text-align: left;"><strong><span style="background-color: #ffffff;"><span style="color: #000000;"><span style="font-family: Arial;"><span style="font-size: 24px;">Add The Heading</span></span></span></span></strong></p>
<p><span style="background-color: #ffffff;"><span style="color: #000000;"><span style="font-family: Arial;"><span style="font-size: 14px;"><strong>Lorem Ipsum</strong></span></span></span></span><span style="font-family: Arial;"> </span><span style="background-color: #ffffff;"><span style="color: #000000;"><span style="font-family: Arial;"><span style="font-size: 14px;">is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</span></span></span></span></p>`,
    imageFile: null,
    styles: {
        globalBG: {
            backgroundColor: "ffffff"
        },
        textContainer: {
            width: "50%",
            height: "100%",
            x: 0,
            y: 0,
            paddingTop: "0px",
            paddingRight: "0px",
            paddingBottom: "0px",
            paddingLeft: "0px",
            objectFit: 'contain',
            justifyContent: 'center',
            alignItems: 'center',
            // backgroundColor: "#ffffff",
            color: "#000000",
        },
        logoImageContainer: {
            width: "100%",
            height: "100%",
            maxHeight: "max-content",
            x: 0,
            y: 0,
            paddingTop: "0px",
            paddingRight: "0px",
            paddingBottom: "0px",
            paddingLeft: "0px",
            objectFit: 'contain',
            justifyContent: 'center',
            alignItems: 'center',
            overflow: 'hidden',
            // backgroundColor: "#ffffff",
        },
        logoImage: {
            width: "100px",
            height: "100px",
            x: 0,
            y: 0,
            paddingTop: "0px",
            paddingRight: "0px",
            paddingBottom: "0px",
            paddingLeft: "0px",
            objectFit: 'contain',
            justifyContent: 'center',
            alignItems: 'center',
        },
        text: {
            width: "100%",
            height: "100%",
            x: 0,
            y: 0,
            paddingTop: "30px",
            paddingRight: "30px",
            paddingBottom: "30px",
            paddingLeft: "30px",
            justifyContent: 'center',
            alignItems: 'center',
            overflowY: "auto"
        },
        imageContainer: {
            width: "50%",
            height: "100%",
            x: 0,
            y: 0,
            paddingTop: "0px",
            paddingRight: "0px",
            paddingBottom: "0px",
            paddingLeft: "0px",
            objectFit: 'contain',
            justifyContent: 'center',
            alignItems: 'center',
            // backgroundColor: "#ffffff",
        },
        image: {
            width: "100%",
            height: "100%",
            x: 0,
            y: 0,
            paddingTop: "0px",
            paddingRight: "0px",
            paddingBottom: "0px",
            paddingLeft: "0px",
            objectFit: 'cover',
            justifyContent: 'center',
            alignItems: 'center',
        }
    },
    layoutOption: "text-left-image-right"
}

export type Tab = {
    _id: string;
    content: {
        templateType: string;
        templateData: any;
    };
    title: string;
};

export type Tabs = {
    tabs: Tab[];
    layoutOption: string;
};

const defaultTabsData: any = {
    tabs: [
        { _id: uuid(), content: { templateType: "", templateData: {} }, title: "Tab 1" }
    ],
    layoutOption: "horizontal-tab-top",
    styles: {
        container: {
            width: "100%",
            height: "100%",
            paddingTop: "0px",
            paddingRight: "0px",
            paddingBottom: "0px",
            paddingLeft: "0px",
            borderRadius: "0px",
            backgroundColor: "#ffffff"
        },
        tabContainer: {
            width: "100%",
            gap: "8px",
        },
        tabButton: {
            width: "200px",
            height: "35px",
            paddingTop: "0px",
            paddingRight: "0px",
            paddingBottom: "0px",
            paddingLeft: "0px",
            borderRadius: "5px",
            color: "#333333",
            backgroundColor: "#BABABA",
            selected: {
                color: "#BABABA",
                backgroundColor: "#333333",
            },
            // textAlign: "left",
            // display: "flex",
            justifyContent: 'center',
            alignItems: 'center',
        },
        content: {
            width: "100%",
            paddingTop: "0px",
            paddingRight: "0px",
            paddingBottom: "0px",
            paddingLeft: "0px",
            borderRadius: "0px",
            marginTop: "5px",
            marginLeft: "0px",
            backgroundColor: "#ffffff",
        },
    }
}

export type Front = {
    question: string;
    prompt: string;
    imageFile: object | null
}
export type Back = {
    question: string;
    imageFile: object | null
}

export type ClickAndReveal = {
    content: {
        front: Front;
        back: Back;
    };
    transition: string;
};

const defaultClickAndRevealData: any = {
    front: {
        question: "What should you do if the oxygen masks drop down during the flight?",
        prompt: "Do you know what is the answer?",
        imageFile: null,
        layoutOption: "front-text-left-image-right",
        styles: {
            parentContainer: {
                width: "100%",
                height: "100%",
                paddingTop: "32px",
                paddingRight: "32px",
                paddingBottom: "32px",
                paddingLeft: "32px",
                // backgroundColor: "#d1d1d1",
            },
            container: {
                width: "100%",
                height: "100%",
                paddingTop: "60px",
                paddingRight: "70px",
                paddingBottom: "60px",
                paddingLeft: "70px",
                borderRadius: "15px",
                backgroundColor: "#DFFFD6",
                gap: "15px"
            },
            promptLabelWrap: {
                width: '100%',
                // height: '100%',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                gap: '20px'
            },
            promptContainer: {
                width: "100%",
                height: "100%",
                x: 0,
                y: 0,
                paddingTop: "0px",
                paddingRight: "0px",
                paddingBottom: "0px",
                paddingLeft: "0px",
                objectFit: 'contain',
                justifyContent: 'center',
                alignItems: 'center',
                // backgroundColor: "#ffffff",
                color: "#000000",
            },
            prompt: {
                width: "100%",
                height: "100%",
                x: 0,
                y: 0,
                paddingTop: "0px",
                paddingRight: "0px",
                paddingBottom: "0px",
                paddingLeft: "0px",
                justifyContent: 'flex-start',
                alignItems: 'center',
                overflowY: "auto"
            },
            questionLabelContainer: {
                width: "100%",
                height: "100%",
                x: 0,
                y: 0,
                paddingTop: "0px",
                paddingRight: "0px",
                paddingBottom: "0px",
                paddingLeft: "0px",
                objectFit: 'contain',
                justifyContent: 'center',
                alignItems: 'center',
                // backgroundColor: "#ffffff",
                color: "#000000",
            },
            questionLabel: {
                width: "100%",
                height: "100%",
                x: 0,
                y: 0,
                paddingTop: "0px",
                paddingRight: "0px",
                paddingBottom: "0px",
                paddingLeft: "0px",
                justifyContent: 'flex-start',
                alignItems: 'center',
                overflowY: "auto"
            },
            questionImageWrap: {
                width: '100%',
                height: '100%',
                justifyContent: 'center',
                alignItems: 'flex-start',
                gap: '15px'
            },
            textContainer: {
                width: "50%",
                height: "100%",
                x: 0,
                y: 0,
                paddingTop: "0px",
                paddingRight: "0px",
                paddingBottom: "0px",
                paddingLeft: "0px",
                objectFit: 'contain',
                justifyContent: 'center',
                alignItems: 'center',
                // backgroundColor: "#ffffff",
                color: "#000000",
            },
            text: {
                width: "100%",
                height: "100%",
                x: 0,
                y: 0,
                paddingTop: "0px",
                paddingRight: "0px",
                paddingBottom: "0px",
                paddingLeft: "0px",
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                overflowY: "auto"
            },
            imageContainer: {
                width: "50%",
                height: "100%",
                x: 0,
                y: 0,
                paddingTop: "0px",
                paddingRight: "0px",
                paddingBottom: "0px",
                paddingLeft: "0px",
                objectFit: 'contain',
                justifyContent: 'center',
                alignItems: 'center',
                // backgroundColor: "#ffffff",
                color: "#000000",
                borderRadius: "10px",
            },
            image: {
                width: "100%",
                height: "100%",
                x: 0,
                y: 0,
                paddingTop: "0px",
                paddingRight: "0px",
                paddingBottom: "0px",
                paddingLeft: "0px",
                objectFit: 'cover',
                justifyContent: 'center',
                alignItems: 'flex-start',
                borderRadius: "10px",
            }
        },

    },
    back: {
        answer: "Secure your own mask first before assisting others",
        imageFile: null,
        layoutOption: "back-text-left-image-right",
        styles: {
            parentContainer: {
                width: "100%",
                height: "100%",
                paddingTop: "32px",
                paddingRight: "32px",
                paddingBottom: "32px",
                paddingLeft: "32px",
                // backgroundColor: "#d1d1d1",
            },
            container: {
                width: "100%",
                height: "100%",
                paddingTop: "60px",
                paddingRight: "70px",
                paddingBottom: "60px",
                paddingLeft: "70px",
                borderRadius: "15px",
                backgroundColor: "#DFFFD6",
                gap: "15px"
            },
            answerLabelContainer: {
                width: "100%",
                height: "100%",
                x: 0,
                y: 0,
                paddingTop: "0px",
                paddingRight: "0px",
                paddingBottom: "0px",
                paddingLeft: "0px",
                objectFit: 'contain',
                justifyContent: 'center',
                alignItems: 'center',
                // backgroundColor: "#ffffff",
                color: "#000000",
            },
            answerLabel: {
                width: "100%",
                height: "100%",
                x: 0,
                y: 0,
                paddingTop: "0px",
                paddingRight: "0px",
                paddingBottom: "0px",
                paddingLeft: "0px",
                justifyContent: 'flex-start',
                alignItems: 'center',
                overflowY: "auto"
            },
            answerImageWrap: {
                width: '100%',
                height: '100%',
                justifyContent: 'center',
                alignItems: 'flex-start',
                gap: '15px'
            },
            textContainer: {
                width: "50%",
                height: "100%",
                x: 0,
                y: 0,
                paddingTop: "0px",
                paddingRight: "0px",
                paddingBottom: "0px",
                paddingLeft: "0px",
                objectFit: 'contain',
                justifyContent: 'center',
                alignItems: 'center',
                // backgroundColor: "#ffffff",
                color: "#000000",
            },
            text: {
                width: "100%",
                height: "100%",
                x: 0,
                y: 0,
                paddingTop: "0px",
                paddingRight: "0px",
                paddingBottom: "0px",
                paddingLeft: "0px",
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                overflowY: "auto"
            },
            imageContainer: {
                width: "50%",
                height: "100%",
                x: 0,
                y: 0,
                paddingTop: "0px",
                paddingRight: "0px",
                paddingBottom: "0px",
                paddingLeft: "0px",
                objectFit: 'contain',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: "10px",
                // backgroundColor: "#ffffff",
            },
            image: {
                width: "100%",
                height: "100%",
                x: 0,
                y: 0,
                paddingTop: "0px",
                paddingRight: "0px",
                paddingBottom: "0px",
                paddingLeft: "0px",
                objectFit: 'cover',
                justifyContent: 'center',
                alignItems: 'flex-start',
                borderRadius: "10px",
            }
        },
    },
    transition: 'horizontal',
    styles: {
        global: {
            backgroundColor: "#4F7982",
        }
    }
}



export const DefaultTemplateData = new Map([
    ["mcr", defaultMcrData],
    ["scr", defaultScrData],
    ["true/false", defaultTrueFalseData],
    ["textwithimage", defaultTextWImageData],
    ["tabs", defaultTabsData],
    ["clicktoreveal", defaultClickAndRevealData],
]);
