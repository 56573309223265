import React, { useState } from "react";
import { useSelector } from "react-redux";
// @ts-ignore
import BackgroundImage from "../../../assets/preview/Acknowledge_bg.jfif";

type Props = {
  data: any;
  onNext: (nextTemplateId: string) => void;
};

export function Acknowledgement({ data, onNext }: Props) {
  const { themeColor, headingFont, bodyFont } = useSelector(
    (state: any) => state.programSettings
  );
  const [hovered, setHovered] = useState(false);

  const handleNouseEnter = () => {
    setHovered(true);
  }

  const handleNouseLeave = () => {
    setHovered(false);
  }

  const buttonStyle = {
    backgroundColor: hovered && themeColor,
  }

  return (
    <div
      className={`w-full xs:h-[calc(100%-3.5rem)] sm:h-[calc(100%-4rem)] md:h-[calc(100%-4.6rem)] lg:h-[calc(100%-5.125rem)] xl:h-[calc(100%-5.625rem)] flex flex-col space-y-[2rem] md:space-y-[3rem] lg:space-y-[4rem] pb-[0.5rem] ${
        bodyFont && `font-${bodyFont}`
      }`}
    >
      <div className="grow w-full flex flex-col space-y-[2.87rem] pt-[1.5rem] md:pt-[2.12rem] pl-[1.7rem] md:pl-[2.5rem] lg:pl-[3.75rem]">
        <div
          className={`text-[#FFFFFF] text-[1.7rem] md:text-[2rem] lg:text-[2.5rem] font-semibold border-l-4 md:border-l-[7px] pl-[1rem] md:pl-[1.5rem] leading-normal ${
            headingFont && `font-${headingFont}`
          }`}
        >
          {data.content.title}
        </div>

        {/* <div className="mx-2 sm:mx-4 md:mx-6 lg:mx-8 xl:mx-10 w-full sm:max-w-[35rem] flex flex-col p-2"> */}
        <div className="flex flex-col pl-[1.5rem] sm:pl-[2.5rem] pr-[1.5rem] w-full sm:max-w-[32.5625rem] pt-[1rem]">
          <div
            dangerouslySetInnerHTML={{
              __html: data.content.acknowledgementText,
            }}
            className="rte w-full sm:max-w-[32.5625rem] min-h-[10rem] max-h-[25rem] lg:min-h-[10rem] lg:max-h-[35rem] flex-shrink-0 text-[1.25rem]  font-[500] leading-[1.5rem] text-[#FFFFFF] overflow-y-scroll no-scrollbar"
          />

          <div className="w-full sm:w-[32.5625rem] text-[#FFFFFF] text-[14px] font-[600]  uppercase flex flex-row justify-between items-center pl-[4rem] sm:pl-[2rem] pr-[4rem] sm:pr-[8rem] pt-[1.5rem] gap-5">
            <button
              onClick={() => {
                const currentId = data._id;
                const temp = data.templatesId.indexOf(currentId);
                onNext(data.templatesId[temp - 1]);
              }}
              className="flex justify-center items-center w-full sm:w-[152px] py-2 px-2 rounded-[30px] hover:bg-red-600 bg-[#9C9595]"
            >
              {data.content.button1Text}
            </button>
            <button
              onClick={() => {
                const currentId = data._id;
                const temp = data.templatesId.indexOf(currentId);
                onNext(data.templatesId[temp + 1]);
              }}
              onMouseEnter={handleNouseEnter}
              onMouseLeave={handleNouseLeave}
              style={buttonStyle}
              className={`flex justify-center items-center w-full sm:w-[152px] py-2 px-2 rounded-[30px] ${
                !themeColor && `hover:bg-tertiary`
              } bg-[#9C9595]`}
            >
              {data.content.button2Text}
            </button>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center text-[#F5F5F5]  text-[14px] lg:text-[18px] font-normal px-2 ">
        {data.content.copyrightText}
      </div>
    </div>

    // <div className="w-full h-[calc(100%-5.625rem)] flex flex-col space-y-[2rem] md:space-y-[3rem] lg:space-y-[4rem] pb-[0.5rem] overflow-y-scroll no-scrollbar">
    //   <div className="grow w-full flex flex-col space-y-4 pt-[2.12rem] pl-[2rem] md:pl-[2.5rem] lg:pl-[3.75rem]">
    //     <div className="flex-grow overflow-y-scroll no-scrollbar flex flex-col">
    //       <div className="text-[#FFFFFF] text-[2rem] md:text-[2.5rem] lg:text-[3rem] font-semibold border-l-6 md:border-l-8 pl-[0.38rem] md:pl-[1.5rem]">
    //         {data.content.title}
    //       </div>

    //       <div className="flex flex-col pl-[1.5rem] sm:pl-[2.5rem] pr-[1.5rem] w-full sm:max-w-[32.5625rem] pt-[1rem]">
    //         <div className="flex w-full sm:max-w-[32.5625rem] h-[35rem] flex-shrink-0 text-[1.25rem]  font-[500] leading-[1.5rem] text-[#FFFFFF]  overflow-y-scroll no-scrollbar">
    //           {data.content.acknowledgementText}
    //         </div>
    //       </div>
    //     </div>
    //     <div className="w-full sm:w-[32.5625rem] text-[#FFFFFF] text-[14px] font-[600]  uppercase flex flex-row justify-between items-center pl-[4rem] sm:pl-[6.25rem] pr-[4rem] sm:pr-[8rem] pt-[2rem] gap-5">
    //       <button
    //         onClick={() => {}}
    //         className="flex justify-center items-center w-full sm:w-[152px] py-2 px-2 border-2 border-solid rounded-[1.25rem] bg-[#B7B6B6] "
    //       >
    //         {data.content.button1Text}
    //       </button>
    //       <button
    //         onClick={() => {
    //           const currentId = data._id;
    //           const temp = data.templatesId.indexOf(currentId);
    //           onNext(data.templatesId[temp + 1]);
    //         }}
    //         className="flex justify-center items-center w-full sm:w-[152px] py-2 px-2 border-2 border-solid rounded-[1.25rem] bg-[#12B76A] "
    //       >
    //         {data.content.button2Text}
    //       </button>
    //     </div>
    //   </div>

    //   <div className="flex items-center justify-center text-[#F5F5F5]  text-[14px] lg:text-[18px] font-normal px-2 ">
    //     {data.content.copyrightText}
    //   </div>
    // </div>
  );
}
