import { createSlice } from "@reduxjs/toolkit";

type InitialState = {
    selectedComponent: {
        type: String,
        maxLength: null,
        zone: number[][],
    } | null,
}

const initialState: InitialState = {
    selectedComponent: null,
};

export const componentPropertySlice = createSlice({
    name: "componentProperties",
    initialState,
    reducers: {
        setSelectedComponent: (state, action) => {
            state.selectedComponent = action.payload;
        },
        // removeSelectedTemplate: (state) => {
        //     state.selectedComponent = null;
        // },
    },
})

export const { setSelectedComponent } = componentPropertySlice.actions;
export default componentPropertySlice.reducer;