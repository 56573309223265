import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
// @ts-ignore
import BackgroundImage from "../../../assets/preview/Acknowledge_bg.jfif";

type Props = {
  data: any;
  onNext: (nextTemplateId: string) => void;
};

export function Acknowledgement({ data, onNext }: Props) {
  const { themeColor, headingFont, bodyFont, headingColor, thumbnailHeadingColor, thumbnailTitleColor } = useSelector(
    (state: any) => state.programSettings
  );
  const [hovered, setHovered] = useState(false);

  const handleNouseEnter = () => {
    setHovered(true);
  }

  const handleNouseLeave = () => {
    setHovered(false);
  }

  const buttonStyle = {
    backgroundColor: hovered && themeColor,
    color: hovered && thumbnailTitleColor,
  }

  useEffect(() => {
    console.log("headingColor", headingColor, "thumbnailTitleColor", thumbnailTitleColor);
  }, [headingColor ,thumbnailTitleColor]);

  return (
    <div
      className={`grow flex flex-col w-full item-start ${
        bodyFont && `font-${bodyFont}`
      }`}
    >
      <div className="grow w-full flex flex-col pt-[1.5rem] md:pt-[2.12rem] pl-[1.7rem] md:pl-[2.5rem] lg:pl-[3.75rem]">
        <div 
          className={`text-[1.5rem] md:text-[1.7rem] lg:text-[2rem] font-semibold  ${
            headingFont && `font-${headingFont}`
          }`}
        >
          <div 
            style={{color: headingColor, borderColor: headingColor}}
            className={`border-l-4 md:border-l-[7px] pl-[1rem] md:pl-[1.5rem] leading-normal ${!headingColor && "text-[#FFFFFF] border-[#FFFFFF]"}`}>
            {data.content.title}
          </div>
        </div>

        {/* <div className="mx-2 sm:mx-4 md:mx-6 lg:mx-8 xl:mx-10 w-full sm:max-w-[35rem] flex flex-col p-2"> */}
        <div className="flex flex-col pl-[1.5rem] sm:pl-[2.5rem] pr-[1.5rem] w-full max-w-[80%] md:max-w-[70%] lg:max-w-[50%] pt-[1rem]">
          <div
            dangerouslySetInnerHTML={{
              __html: data.content.acknowledgementText,
            }}
            className="rte w-full min-h-[10rem] max-h-[18rem] xl:max-h-[22rem] 2xl:max-h-[26rem] flex-shrink-0 text-[0.88rem]  font-[500] leading-[1.5rem] text-[#FFFFFF] overflow-y-scroll no-scrollbar"
          />

          <div className="w-full text-[#FFFFFF] text-[12px] md:text-[16px] font-[400] uppercase flex flex-row justify-between items-center md:pl-[3rem] md:pr-[3rem] lg:pl-[7rem] lg:pr-[7rem] pt-[1.5rem] gap-5">
            <button
              onClick={() => {
                const currentId = data._id;
                const temp = data.templatesId.indexOf(currentId);
                onNext(data.templatesId[temp - 1]);
              }}
              className="flex justify-center items-center w-full py-2 md:py-3 px-2 rounded-[30px] hover:bg-red-600 bg-[#9C9595]"
            >
              {data.content.button1Text}
            </button>
            <button
              onClick={() => {
                const currentId = data._id;
                const temp = data.templatesId.indexOf(currentId);
                onNext(data.templatesId[temp + 1]);
              }}
              onMouseEnter={handleNouseEnter}
              onMouseLeave={handleNouseLeave}
              style={buttonStyle}
              className={`flex justify-center items-center w-full py-2 md:py-3 px-2 rounded-[30px] ${
                !themeColor && `hover:bg-tertiary`} ${!thumbnailTitleColor && "text-white"} bg-[#9C9595]`}
            >
              {data.content.button2Text}
            </button>
          </div>
        </div>
      </div>

      <div className="flex justify-center text-[#F5F5F5] text-[12px] xl:text-[14px] font-normal pb-[0.2rem]">
        {data.content.copyrightText}
      </div>
    </div>
  );
}
