import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    selectedTab: null,
};
export const selectedTabSlice = createSlice({
    name: "selectedTab",
    initialState,
    reducers: {
        setSelectedTab: (state, action) => {
            state.selectedTab = action.payload;
        },
    },
});

export const { setSelectedTab } = selectedTabSlice.actions;
export default selectedTabSlice.reducer;
