import { ChevronDown, ChevronUp } from "lucide-react";
import React, { useEffect, useState, useRef } from "react";
import RichTextEditor from "src/utils/RichTE";

type AccordionType = {
  id: number;
  title: string;
  content: string;
};

type Props = {
  accordions: AccordionType[];
  onChange: (accordions: AccordionType[]) => void;
};

const initialAccordion: AccordionType[] = [
  {
    id: Date.now(),
    title: "",
    content: "",
  },
];

function Accordions({ accordions, onChange }: Props) {
  const [updatedAccordions, setUpdatedAccordions] = useState<AccordionType[]>(
    accordions?.length > 0 ? accordions : initialAccordion
  );
  const [openAccordionId, setOpenAccordionId] = useState<number | null>(null);

  useEffect(() => {
    console.log("accordions", updatedAccordions);
    onChange(updatedAccordions);
  }, [updatedAccordions]);

  function toggleAccordion(id: number) {
    setOpenAccordionId((prevId) => (prevId === id ? null : id));
  }

  const addAccordion = () => {
    const newAccordion: AccordionType = {
        id: Date.now(),
        title: "",
        content: "",
    }
    setUpdatedAccordions([...updatedAccordions, newAccordion]);
  }

  return (
    <div className="flex flex-col mt-10">
      {updatedAccordions?.map((accordion, idx: number) => (
        <div className="flex flex-col">
          <div
            key={accordion.id}
            className="flex w-full justify-between items-center bg-gray-300 rounded-md mb-[1px] p-2"
          >
            <input
              type="text"
              placeholder="Enter accordion title text here..."
              value={accordion?.title || ""}
              onChange={(e) =>
                setUpdatedAccordions((prev) =>
                  prev.map((acc) =>
                    acc.id === accordion.id
                      ? { ...acc, title: e.target.value }
                      : acc
                  )
                )
              }
              className="w-full border-none outline-1 outline-gray-200 text-[1rem] font-[400] text-[#242424] bg-gray-300 px-2 py-1 mr-2 placeholder-gray-500"
            />
            <button 
            onClick={() => toggleAccordion(accordion.id)}>
                {openAccordionId === accordion.id ? <ChevronUp /> : <ChevronDown />}
            </button>
          </div>
          {openAccordionId === accordion.id && (
            <>
              {openAccordionId !== null && (
                <RichTextEditor
                  key={openAccordionId}
                  value={accordion?.content || ""}
                  onChange={(content) =>
                    setUpdatedAccordions((prev) =>
                      prev.map((acc) =>
                        acc.id === accordion.id ? { ...acc, content } : acc
                      )
                    )
                  }
                />
              )}
            </>
          )}
        </div>
      ))}
      <button onClick={() => addAccordion()} className="w-48 px-4 py-2 bg-[#AB0273] text-[#ffffff] rounded-md mt-2">Add Accordion +</button>
    </div>
  );
}

export default Accordions;
