import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setPageState } from "src/features/templatePageState/templatePageStateSlice";
import useFolder, { ROOT_FOLDER } from "src/hooks/useFolder";
import { useSelector } from "react-redux";
import { v4 as uuidV4 } from "uuid";
import { ListObjects, getObectURL, putObectURL } from "src/helpers/AWS_S3";
import axios from "axios";
import { setAddToChildFiles } from "src/features/fileFolder/fileFolderSlice";
// @ts-ignore
import DefaultImage from "src/assets/Course/defaultImage.jpg";
import { Option } from "../Templates/Authoring/utils/DefaultTemplateData";

type Props = {
  type?: string | null;
  name?: string;
  image?: any;
  handleUploadFromDevice?: (file: any) => void;
  haveOptions?: boolean;
};

function UploadImage({ type = null, name, handleUploadFromDevice, image, haveOptions }: Props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { userId, businessUnitId, sepmId, templateId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  // console.log(pageState, type)

  const [showButtons, setShowButtons] = React.useState(false);

  const folderId = searchParams.get("folderId");
  const { folder } = useSelector((state: any) => state.fileFolder);
  const { authUser } = useSelector((state: any) => state.user);

  const [uploadingFiles, setUploadingFiles] = useState<any>([]);
  const [url, setUrl] = useState<any>("");
  const [uploadedLogoFile, setUploadedLogoFile] = useState<any>("");

  const { accessToken } = useSelector((state: any) => state.accessToken);

  const { fileFolder } = useFolder({
    folderId: folderId === "null" ? null : folderId,
    folder,
  });
  const currentFolder = fileFolder?.folder;

  useEffect(() => {
    if (uploadedLogoFile && handleUploadFromDevice) {
      handleUploadFromDevice(uploadedLogoFile);
    }
  }, [uploadedLogoFile]);

  const menuRef = React.createRef<HTMLDivElement>();

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!menuRef?.current?.contains(event.target as Node)) {
        setShowButtons(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      // Clean up the event listener when the component unmounts
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuRef]);

  async function handleUpload(e: React.ChangeEvent<HTMLInputElement>) {
    const file = (e.target as HTMLInputElement).files![0];

    if (currentFolder == null || file == null) return;

    const id = uuidV4();
    setUploadingFiles((prevUploadingFiles: any) => [
      ...prevUploadingFiles,
      { id: id, name: file.name, progress: 0, error: false },
    ]);
    const filePath =
      currentFolder === ROOT_FOLDER
        ? `${currentFolder.path.map((item: any) => item.name).join("/")}/${
            file.name
          }`
        : `${
            currentFolder.path.map((item: any) => item.name).join("/") &&
            `/${currentFolder.path.map((item: any) => item.name).join("/")}`
          }/${currentFolder.name}/${file.name}`;

    const UploadUrl = await putObectURL(
      `uploads/users/${authUser.oid}${filePath}`,
      file.type
    );
    console.log("upload url: ", UploadUrl, authUser.oid, filePath, file);
    axios
      .put(`${UploadUrl}`, file, {
        onUploadProgress: (ProgressEvent) => console.log(ProgressEvent.loaded),
      })
      .then((response) => {
        console.log(response.data);
        //   // Set logoFile state with uploaded file data
        // setLogoFile({
        //   name: file.name,
        //   type: file.type,
        //   size: file.size,
        //   url: UploadUrl.split("?")[0], // or SignedUrl.split("?")[0] depending on your requirement
        // });
      })
      .catch((err) => console.log(err));

    const SignedUrl = await getObectURL(
      `uploads/users/${authUser.oid}${filePath}`
    );

    console.log("this is the signed url: ", SignedUrl);

    setUrl(SignedUrl.split("?")[0]);

    const response = await axios.post(
      `${process.env.REACT_APP_SERVER_BASE_URL}/api/v1/file/`,
      {
        name: file.name,
        type: file.type,
        size: file.size,
        folderId,
        url: SignedUrl.split("?")[0],
      },
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    );
    console.log("Added files", response.data.data);
    dispatch(setAddToChildFiles(response.data.data));
    setUploadedLogoFile(response.data.data);
    setShowButtons(false);
  }

  return (
    <div className="relative">
      <button
        onClick={() => {
          setShowButtons((prevState) => !prevState);
        }}
        className={`w-[10rem] h-[7rem] flex items-center justify-center ${haveOptions && "!w-[6rem] !h-[4rem] border-2 border-gray-300 rounded-md" } ${name === "playerSettingsLogo" && "!w-[5rem] !h-[3rem] "}`}
      >
        {image ? (
          <span className="w-full h-full flex items-center justify-center rounded-md overflow-hidden">
            <img className=" max-w-max h-full object-contain rounded-md" src={image?.url} alt="" />
          </span>
        ) : (
          <span className="w-full h-full flex items-center justify-center rounded-md overflow-hidden" > 
            <img className=" max-w-max h-full object-cover rounded-md" src={DefaultImage} alt="" />
          </span>
        )}

      </button>
      {showButtons && (
        <div
          ref={menuRef}
          className="absolute border rounded-sm bg-white flex flex-col items-start  w-[12.875rem] z-50"
        >
          <button
            onClick={() => {}}
            className=" text-tertiary font-semibold px-4 py-2 rounded mr-2"
          >
            Media Library
          </button>
          <div className=" px-4 py-2">
            <input
              className="hidden"
              onChange={(e) => handleUpload(e)}
              type="file"
              name=""
              id="upload-logo"
            />
            <label
              htmlFor="upload-logo"
              className="text-tertiary font-semibold rounded"
            >
              Upload from Device
            </label>
          </div>
        </div>
      )}
    </div>
  );
}

export default UploadImage;
