import React, { useState, useEffect } from "react";
import { ChevronRight } from "@carbon/icons-react";
import { ChevronLeft } from "@carbon/icons-react";
import { useSelector } from "react-redux";
import { head } from "lodash";

type Props = {
  data: any;
  handleSelectMenu: (menu: any) => void;
  selectedAircraft: any;
  selectedUser: any;
};

export function MenuScreen({
  data,
  selectedAircraft,
  selectedUser,
  handleSelectMenu,
}: Props) {
  const [filteredData, setFilteredData] = useState<Array<any>>([]);

  const { themeColor, headingFont, bodyFont } = useSelector((state: any) => state.programSettings);

  useEffect(() => {
    console.log(data, selectedAircraft, selectedUser);

    const filteredTemplates = data.filter(
      (template: any) =>
        template.content?.documents || template.content?.aircraftDocuments
    );

    console.log(filteredTemplates);

    const filterTemplatesByUserAircraft = filteredTemplates.filter(
      (template: any) => {
        if (template.type !== "location diagram") {
          const temp = template.content.documents.filter((data: any) => {
            if (data.aircraftId === selectedAircraft.id) {
              const template = data.selectedOptions.filter((data: any) => {
                return data.id === selectedUser.id;
              });
              return template.length > 0;
            } else return false;
          });
          return temp.length > 0;
        } else {
          const temp = template.content.aircraftDocuments.filter(
            (data: any) => {
              if (data.aircraftId === selectedAircraft.id) {
                return true;
              } else return false;
            }
          );
          return temp.length > 0;
        }
      }
    );

    console.log(filterTemplatesByUserAircraft);
    //now we are going to find whether there is modal template or not if yes then we will append it with the filterTemplatesByUserAircraft array

    const result = data.find((template: any) => template.type === "modal");
    console.log(result);

    if (result) setFilteredData([...filterTemplatesByUserAircraft, result]);
    else setFilteredData(filterTemplatesByUserAircraft);
  }, []);

  return (
    <div className={`w-full h-full flex flex-col space-y-[3rem] md:space-y-[3.4rem] lg:space-y-[5rem] ${bodyFont && `font-${bodyFont}`}`}>
      <div className={`text-[#FFFFFF] text-[1.7rem] md:text-[2rem] lg:text-[2.5rem] font-semibold border-l-4 md:border-l-[7px] pl-[1rem] md:pl-[1.5rem] leading-normal ${headingFont && `font-${headingFont}`}`}>
        {selectedAircraft.name}
      </div>
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-[2rem] lg:gap-x-[4.69rem] gap-y-[3rem] lg:gap-y-[5rem] pl-0 lg:pl-[1.5rem]">
        {filteredData.map((data: any) => (
          <MenuCard
            key={data._id}
            themeColor={themeColor}
            data={data}
            handleSelect={() => {
              handleSelectMenu(data);
              console.log("this is running");
            }}
          />
        ))}
      </div>
    </div>
  );
}

const MenuCard: React.FC<{ data: any; themeColor: any; handleSelect: () => void }> = ({
  data,
  themeColor,
  handleSelect,
}) => {
  return (
    <button onClick={() => handleSelect()} className=" flex flex-col ">
      <div className="w-full max-w-[16.875rem] h-[9.375rem] flex-shrink-0 overflow-hidden">
        <img
          src={data.content.bgfile?.url ?? ""}
          alt={`Profile of ${data.title}`}
          className="w-full h-full object-cover flex"
        />
      </div>
      <div style={{backgroundColor: themeColor, opacity: 0.8}} className={`flex w-full max-w-[16.875rem] py-[0.25rem] px-[0.31rem] text-start justify-start items-center flex-shrink-0 text-[#FFFFFF] font-semibold text-[1.25rem] leading-[1.5rem] tracking-[0.00188rem] ${!themeColor && "bg-[#2F0220]/80"}`}>
        {data.title}
      </div>
    </button>
  );
};
