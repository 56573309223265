import { Box } from "@chakra-ui/react";
import { ChevronLeft } from "lucide-react";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { MapScreen, PreviewMapscreen, Test } from "src/components/Test";
import { useParams } from "react-router-dom";
import { cn } from "src/components/lib/utils";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "src/components/ui/select";
import { useTemplateTracking } from "src/hooks/useTemplateTracking";
import PreviewModal from "src/utils/PreviewModal";

type Props = {
  data: any;
  aircrafts: any;
  selectedAircraft: any;
  onNext: (nextTemplateId: string) => void;
};

export function LocationDiagram({ data, aircrafts, selectedAircraft, onNext }: Props) {
  const [selectedSectionLocation, setSelectedSectionLocation] =
    useState<any>(null);
  const [selectedHeight, setSelectedHeight] = useState("");
  const [selectedStart, setSelectedStart] = useState("");
  const [selectedPosition, setSelectedPosition] = useState("");

  const [selectedImage, setSelectedImage] = useState<any>(null);
  const [selectedSection, setSelectedSection] = useState<any>(null);
  const [selectedEuipmentDoc, setSelectedEquipmentDoc] = useState<any>(null);

  const [variant, setVariant] = useState(selectedAircraft);
  const [matchedAircraftDocument, setMatchedAircraftDocument] = useState<any>(null);

  const { themeColor, headingFont, bodyFont, headingColor, thumbnailHeadingColor, thumbnailTitleColor } = useSelector(
    (state: any) => state.programSettings
  );
  const { accessToken } = useSelector((state: any) => state.accessToken);
  const { businessUnitId, sepmId } = useParams();

  useTemplateTracking({ moduleId: data._id, businessUnitId, sepmId, accessToken });


  useEffect(() => {
    console.log(data, variant, selectedEuipmentDoc);
  }, [selectedEuipmentDoc]);

  useEffect(() => {
    console.log("aircrafts: ", aircrafts, matchedAircraftDocument)
  }, [aircrafts, matchedAircraftDocument])

  useEffect(() => {
    console.log("data and variant: ", data.content.aircraftDocuments, variant)
    const doc = data.content.aircraftDocuments.find((doc: any) => doc.aircraftId === variant.id);
    console.log("doc: ", doc);
    setMatchedAircraftDocument(doc);
  }, [variant])


  const locationsection = (section: any) => {
    return (<>
      {matchedAircraftDocument?.aircraftDoc.locations?.filter((location: any) => location?.section?.id === section?.id).map(
        (location: any) => {
          return <ul className="text-[0.5rem] text-white list-disc pl-4" key={location?.id}>
            <li>{location.name}</li>
          </ul>;
        }
      )}
    </>
    )
  }

  const equipmentlocation = (location: any) => {
    return (<>
      {matchedAircraftDocument?.aircraftDoc.documents.filter((doc: any) => doc.aircraftId === variant.id && doc.sectionId === selectedSection.id && doc.locationId === location.id).map(
        (equipmentdoc: any, idx: number) => {
          return (
            <div className={`flex items-center px-5 py-4 w-full ${idx % 2 === 0 ? "bg-[#FFFFFF]" : "bg-[#FEFEFF]/[90]"}`} key={equipmentdoc.id}>
              <button onClick={() => setSelectedImage(equipmentdoc)} className="pr-4">
                <img className="h-12 w-12 object-cover" src={equipmentdoc.iconfile?.url ?? ""} alt="" />
              </button>
              <button className="w-full flex items-center" onClick={() => handleEuipmentSelect(variant.id, selectedSection.id, location.id, equipmentdoc.equipmentId)}>
                <div className="grow flex justify-start text-black text-left text-xl font-bold">{equipmentdoc.equipmentName}</div>
                <div className="flex items-center justify-center w-[2rem] 2xl:w-[2.5rem]">
                  <div
                    style={{
                      backgroundColor: selectedEuipmentDoc && selectedEuipmentDoc.id === equipmentdoc.id ? (themeColor || "#AB0273") : "#929292",
                      color: selectedEuipmentDoc && selectedEuipmentDoc.id === equipmentdoc.id ? (thumbnailTitleColor || "#FFFFFF") : "#000000"
                    }}
                    className={`font-bold text-xs 2xl:text-base w-[24px] h-[24px] 2xl:w-[36px] 2xl:h-[36px] flex items-center justify-center rounded-full`}><span>{equipmentdoc.quantity <= 9 ? `0${equipmentdoc.quantity}` : equipmentdoc.quantity}</span></div>
                </div>
              </button>

            </div>
          );
        }
      )}
    </>)
  }

  function handleEuipmentSelect(aircraftId: any, sectionId: any, locationId: any, equipmentId: any) {
    console.log(aircraftId, sectionId, locationId, equipmentId)
    if (selectedEuipmentDoc === null) {
      const doc = matchedAircraftDocument?.aircraftDoc.documents.filter((doc: any) => doc.aircraftId === aircraftId && doc.sectionId === sectionId && doc.locationId === locationId && doc.equipmentId === equipmentId)
      console.log(doc)
      setSelectedEquipmentDoc(doc[0])
    }
    else setSelectedEquipmentDoc(null)
  }

  return (
    <div className={`relative w-full grow flex flex-col pt-[1.5rem] md:pt-[1.88rem] pl-[1.7rem] md:pl-[2.5rem] lg:pl-[3.75rem] pr-[1.7rem] md:pr-[2.44rem] space-y-[2rem] 2xl:space-y-[3.41rem] ${bodyFont && `font-${bodyFont}`}`}>
      <div
        style={{ color: headingColor, borderColor: headingColor }}
        className={`${!headingColor && "text-[#FFFFFF] border-[#FFFFFF]"} text-[1.25rem] xl:text-[1.7rem] 2xl:text-[2.5rem] font-semibold  border-l-4 md:border-l-[7px] pl-[1rem] md:pl-[1.5rem] leading-normal flex items-center space-x-1 ${headingFont && `font-${headingFont}`}`}>
        <span className="">{variant.name} - </span>
        <span className=""> {data.title}</span>
      </div>

      {aircrafts?.some((item: any) => item.isVariant && item.selectedAircraft?.toString() === selectedAircraft.id?.toString()) && (
        <div className="absolute right-4 top-4">
          <Select
            value={variant}
            onValueChange={(value: string) => {
              const aircraft = aircrafts.find((item: any) => item.id === value);
              setVariant(aircraft)
            }}
          >
            <SelectTrigger className="w-[12rem] h-[2rem] px-1 text-sm">
              <SelectValue
                placeholder="Select Aircraft"
              >
                {aircrafts?.find((item: any) => item.id === variant.id)?.name}
              </SelectValue>
            </SelectTrigger>
            <SelectContent className="z-[99999]">
              {aircrafts?.filter((item: any) => {
                console.log(item, selectedAircraft)
                return item.isVariant && item.selectedAircraft?.toString() === selectedAircraft.id?.toString()
              }).map((item: any, index: number) => (
                <SelectItem key={index} value={item.id}>
                  {item.name}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
      )}

      {aircrafts?.some((item: any) => item.isDoubleDeck && item.selectedAircraft?.toString() === selectedAircraft.id?.toString()) && (
        <div className="absolute right-4 top-4">
          <Select
            value={variant}
            onValueChange={(value: string) => {
              const aircraft = aircrafts.find((item: any) => item.id === value);
              setVariant(aircraft)
            }}
          >
            <SelectTrigger className="w-[12rem] h-[2rem] px-1 text-sm">
              <SelectValue
                placeholder="Select Aircraft"
              >
                {aircrafts?.find((item: any) => item.id === variant.id)?.name}
              </SelectValue>
            </SelectTrigger>
            <SelectContent className="z-[99999]">
              {aircrafts?.filter((item: any) => {
                console.log(item, selectedAircraft)
                return item.isDoubleDeck && item.selectedAircraft?.toString() === selectedAircraft.id?.toString()
              }).map((item: any, index: number) => (
                <SelectItem key={index} value={item.id}>
                  {item.name}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
      )}

      <div className="w-full flex-grow flex justify-center items-center overflow-hidden p-4">
        <div className="relative w-[20rem] h-[20rem] flex justify-center">
          {matchedAircraftDocument && matchedAircraftDocument?.aircraftDoc && (
            <img
              className="w-full h-full object-contain"
              src={matchedAircraftDocument?.aircraftDoc?.file?.url ?? ""}
              alt="AircraftLocationImage"
            />
          )}
          {
            matchedAircraftDocument?.aircraftDoc.sections.map((section: any) => {
              const containerHeight = 320; // Adjust this based on the container height
              const scaleFactor = containerHeight / 723; // Original image height is 723px

              return <button
                onClick={() => { setSelectedSection(section); }}
                style={{ height: `${(section.endY - section.startY) * scaleFactor}px` || "0px", top: `${section.startY * scaleFactor}px` || "0px" }}
                className={cn(
                  `xs:w-[${180 * scaleFactor}px] sm:w-[${180 * scaleFactor}px] md:w-[${280 * scaleFactor}px] lg:w-[${306 * scaleFactor}px] absolute flex flex-col`,
                  `${section.position === "Left"
                    ? "left-0"
                    : "right-0"}`
                )}
              >
                <div className={`w-full flex ${section.position === "Left" ? "justify-end" : "justify-start"} `}>
                  <span style={{ backgroundColor: themeColor, opacity: 0.8, color: thumbnailTitleColor }} className={`${!themeColor && "bg-[#AB0273]/80"} ${!thumbnailTitleColor && "text-[#FFFFFF]"} px-1 py-[1px] 2xl:px-2.5 2xl:py-[2.5px] text-sm font-bold uppercase`}>{section.name}</span>
                </div>
                <div className={`bg-[#D9D9D966] w-full h-full flex flex-col ${section.position === "Left" ? "items-start" : "items-start"} px-2.5 py-1.5`}>
                  {locationsection(section)}
                </div>
              </button>
            })
          }
          {/* {data.content.aircraftDocuments[0].aircraftDoc.locations &&
            data.content.aircraftDocuments[0].aircraftDoc.locations.map(
              (data: any) => (
                <div
                  style={{
                    height: selectedHeight || "0px",
                    top: selectedStart || "0px",
                  }}
                  className={cn(
                    `absolute flex flex-col p-4 border-2 rounded-lg hover:border-primary`,
                    `bg-black/50 ${selectedPosition}`
                  )}
                >
                  <div className="text-lg font-bold mb-1">
                    {data.section.name}
                  </div>
                  <>
                  <div key={data.id}>{data.name}</div>
                  </>
                </div>
              )
            )} */}
        </div>
      </div>

      {selectedSection &&
        <div>
          <PreviewModal
            onOpened={selectedSection !== null}
            onClose={() => setSelectedSection(null)}
            showCrossIcon={false}
          >
            <>
              <div
                style={{
                  background: `linear-gradient(0deg, rgba(0, 0, 0, 0.70) 0%, rgba(0, 0, 0, 0.70) 100%), url(${data.content.bgfile?.url ?? ""})`,
                  backgroundPosition: "50%",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundColor: "lightgray",
                  color: "#146793",
                }}
                className="w-full h-full flex flex-col"
              >
                {/* <div className="text-[#FFFFFF] text-[1.2rem] md:text-[1.6rem] lg:text-[2rem] font-semibold  border-l-4 md:border-l-[7px] pl-[0.8rem] md:pl-[1.3rem] leading-normal flex xs:flex-col sm:flex-col md:flex-row xs:items-start sm:items-start md:items-center md:space-x-1">
                  <span className="flex">
                    <span className="">{selectedAircraft.name} - </span>
                    <span>Location Diagram: </span>
                  </span>
                  <span className=""> {selectedSection && selectedSection.name}</span>
                </div> */}

                <Box className="bg-white w-full h-[2.75rem] md:h-[3rem] xl:h-[3.5rem] px-4 py-[2px] md:py-1 lg:py-1.5 ">
                  <div className="h-full flex space-x-4 justify-start items-center">
                    <button
                      onClick={() => {
                        // navigate(`/user/${userId}/businessunit/${businessUnitId}/sepm/${sepmId}`);
                        setSelectedSection(null)
                      }
                      }
                      className="text-black"
                    >
                      <ChevronLeft size={32} />
                    </button>
                    <div className="text-black text-lg md:text-xl lg:text-2xl font-semibold">
                      {variant.name} - Location Diagram: {selectedSection && selectedSection.name}
                    </div>
                  </div>
                </Box>

                <div className="flex-grow flex justify-center items-center overflow-hidden p-4">
                  <div className="w-full h-full flex md:space-x-10 xs:flex-col sm:flex-col md:flex-row xs:space-y-3 sm:space-y-3 xs:justify-center">
                    <div className="flex flex-col w-full md:w-[32%]">
                      <div style={{ backgroundColor: themeColor, color: thumbnailTitleColor }} className={`flex items-center space-x-6 ${!themeColor && "bg-[#AB0273]"} ${!thumbnailTitleColor && "text-[#FFFFFF]"} text-xl font-bold px-5 py-4`}>
                        <div>ITEM</div>
                        <div className="grow">Description</div>
                        <div>QTY</div>
                      </div>
                      <div className="grow overflow-y-auto">
                        {matchedAircraftDocument?.aircraftDoc.locations.filter((location: any) => location.section?.id === selectedSection?.id).map(
                          (location: any) => {
                            return <div>
                              <div style={{ backgroundColor: themeColor, opacity: 0.7, color: thumbnailTitleColor }} className={`flex items-center space-x-6 ${!themeColor && "bg-[#AB0273]/70"} ${!thumbnailTitleColor && "text-[#FFFFFF]"} text-xl font-bold pl-5 py-4`} key={location.id}>{location.name}</div>
                              {
                                equipmentlocation(location)
                              }
                            </div>;
                          }
                        )}
                      </div>
                    </div>
                    <div className="w-full md:w-[65%] flex justify-center">
                      {/* <Test
                        image={selectedSection?.file ?? ""}
                        // x={x}
                        // y={y}
                        // locatePoints={0}
                        locationPoints={selectedEuipmentDoc?.locationPoints}
                      // onChangeInputs={({ id, x, y }) => {
                      //   handleChange(id, x, y);
                      // }}
                      /> */}
                      <PreviewMapscreen
                        image={matchedAircraftDocument?.aircraftDoc?.file?.url ?? ""}
                        locationPoints={selectedEuipmentDoc?.locationPoints}
                        sectionBounds={selectedSection?.bounds}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </>
          </PreviewModal>
        </div>}

      {selectedImage &&
        <div>
          <PreviewModal
            onOpened={selectedImage !== null}
            onClose={() => setSelectedImage(null)}
            showCrossIcon={false}
          >
            <>
              <div
                style={{
                  background: `linear-gradient(0deg, rgba(0, 0, 0, 0.70) 0%, rgba(0, 0, 0, 0.70) 100%), url(${data.content.bgfile?.url ?? ""})`,
                  backgroundPosition: "50%",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundColor: "lightgray",
                  color: "#146793",
                }}
                className="w-full h-full flex flex-col"
              >
                {/* <div className="text-[#FFFFFF] text-[1.2rem] md:text-[1.6rem] lg:text-[2rem] font-semibold  border-l-4 md:border-l-[7px] pl-[0.8rem] md:pl-[1.3rem] leading-normal flex xs:flex-col sm:flex-col md:flex-row xs:items-start sm:items-start md:items-center md:space-x-1">
                  <span className="flex">
                    <span className="">{selectedAircraft.name} - </span>
                    <span>Location Diagram: </span>
                  </span>
                  <span className=""> {selectedSection && selectedSection.name}</span>
                </div> */}

                <Box className="bg-white w-full h-[2.75rem] md:h-[3rem] xl:h-[3.5rem] px-4 py-[2px] md:py-1 lg:py-1.5 ">
                  <div className="h-full flex space-x-4 justify-start items-center">
                    <button
                      onClick={() => {
                        // navigate(`/user/${userId}/businessunit/${businessUnitId}/sepm/${sepmId}`);
                        setSelectedImage(null)
                      }
                      }
                      className="text-black"
                    >
                      <ChevronLeft size={32} />
                    </button>
                    <div className="text-black text-lg md:text-xl lg:text-2xl font-semibold">
                      {variant.name} - {"Location Diagram: "}{selectedSection && selectedSection.name} - {selectedImage.equipmentName}
                    </div>
                  </div>
                </Box>
                {/* <div className="text-[#FFFFFF] text-[1rem] md:text-[1.25rem] lg:text-[1.5rem] font-semibold pl-2 md:pl-[2rem] leading-normal flex items-center space-x-1">
                  <span className="">{selectedImage.equipmentName}</span>
                </div> */}
                <div className="flex-grow flex justify-center items-center overflow-hidden p-4">
                  <div className="w-full h-full rounded-[1.25rem] bg-black/80">
                    <img className="h-full w-full object-contain" src={selectedImage.file?.url ?? ""} alt="" />
                  </div>
                </div>
              </div>
            </>
          </PreviewModal>
        </div>}
    </div>
  );
}
