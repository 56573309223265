import React, { useEffect, useState } from "react";
import Modal from "src/utils/Modal";
import Drawer from "src/utils/Drawer";
import axios from "axios";
import { useSelector } from "react-redux";
import DownArrow from "../assets/down.png";
import DeleteLogo from "../assets/TrashSimpleWhite.svg";
import QRHDoc from "src/components/TemplateScreensData/QRHDoc";
import PreFlightDoc from "src/components/TemplateScreensData/PreFlightDoc";
import FlightOpsTrainingDoc from "src/components/TemplateScreensData/FlightOpsTrainingDoc";
import SystemDiffDoc from "src/components/TemplateScreensData/SystemDiffDoc";
import PAannouncementDoc from "src/components/TemplateScreensData/PAannouncementDoc";
import { useLocation, useSearchParams } from "react-router-dom";
import VideosDoc from "src/components/TemplateScreensData/VideosDoc";
import EquipmentDoc from "src/components/TemplateScreensData/EquipmentDoc";
import { useDispatch } from "react-redux";
import { setPageState } from "src/features/templatePageState/templatePageStateSlice";
import { setIsLoading } from "src/features/loadingState/loadingStateSlice";
import Loader from "./Loader";
import { Select } from "src/components/ui/select";
import { DropdownMenu } from "src/components/ui/dropdown-menu";
import { v4 as uuid } from "uuid";

type Props = {
  className?: string;
  isLoading: boolean;
  bgFile?: any;
  SelectAircraftButton: string;
  aircrafts: any[];
  users: any[];
  onHandleDocuments: (documents: any[], isLoading?: boolean) => void;
  documents: any[];
  DocumentType: any;
  languages?: any[];
  catagories?: any[];
};

// type User = {
//   name: string;
//   id: number;
// };

function SelectDropdown({
  className,
  isLoading,
  bgFile,
  SelectAircraftButton,
  aircrafts,
  users,
  onHandleDocuments,
  documents,
  DocumentType,
  languages,
  catagories,
}: Props) {
  const [openDropdown, setOpenDropdown] = useState(false);
  const [openCatagory, setOpenCatagory] = useState(false);
  const [openDrawerForm, setOpenDrawerForm] = useState<any>(null);
  const [selectCatagory, setSelectCatagory] = useState<any>(null);
  const [selectedDocument, setSelectedDocument] = useState<any>(null);
  const [language, setLanguage] = useState<any>(null);
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [passUnitIdToDelete, setPassUnitIdToDelete] = useState("");
  const [state, setState] = useState<any | null>(null);

  const [isLoadingState, setIsLoadingState] = useState(false);

  const [filteredOptions, setFilteredOptions] = useState<Array<any>>([]);

  const [searchUrlParams, setSearchUrlParams] = useSearchParams();

  const dispatch = useDispatch();

  const { accessToken } = useSelector((state: any) => state.accessToken);
  // const { isLoading } = useSelector((state: any) => state.loadingState);
  const { pageState } = useSelector((state: any) => state.templatePageState);
  // Manish
  const menuRef = React.createRef<HTMLDivElement>();

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!menuRef?.current?.contains(event.target as Node)) {
        setOpenDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      // Clean up the event listener when the component unmounts
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuRef]);

  useEffect(() => {
    console.log("this is docs: ", documents, pageState, state);
    if (pageState) {
      if (pageState.openDrawerForm) setOpenDrawerForm(pageState.openDrawerForm);
      if (pageState.selectCatagory) setSelectCatagory(pageState.selectCatagory);
      setState(pageState);
      dispatch(setPageState(null));
    }
  }, [pageState]);

  useEffect(() => {
    console.log(selectCatagory, selectedDocument, state);
  }, [selectCatagory, selectedDocument, state]);

  function handleDoc(document: any) {
    console.log(document);
    const newDocuments = documents.map((doc: any) => {
      if (doc._id === document._id) {
        return document;
      }
      return doc;
    });
    console.log(newDocuments);
    onHandleDocuments(newDocuments);
  }
  //End
  useEffect(() => {
    console.log(isLoading);
  }, [passUnitIdToDelete, isLoading]);

  //Delete Functionality
  function handleDeletePopup(id: any) {
    setOpenDeletePopup(true);
    setPassUnitIdToDelete(id);
  }

  // console.log("doc_id", passUnitIdToDelete)

  function handleDelete(id: any) {
    if (onHandleDocuments) {
      // console.log(documents)
      const newDocuments = documents.filter((doc: any) => doc._id !== id);

      onHandleDocuments(newDocuments, true);
      // console.log("new doc", newDocuments)
      setOpenDeletePopup(false);
    }
  }

  function handleCreateDocument() {
    // const filteredDocument = documents.filter(
    //   (doc) =>
    //     doc.aircraftId === openDrawerForm.id && doc.catagoryId === catagory.id
    // );

    // console.log(filteredDocument, documents);

    // setSelectedDocument(filteredDocument[0]);
    // setIsLoadingState(true);
    const newDocs = [
      ...documents,
      {
        _id: new Date().getTime(),
        aircraftId: openDrawerForm.id,
        catagoryId: selectCatagory.id,
        title: "",
        date: "",
        smallfile: null,
        mediumfile: null,
        largefile: null,
        selectedOptions: [],
        descriptionText: "",
      },
    ];
    console.log("new docs: ", newDocs);
    onHandleDocuments(newDocs, true);
  }

  function handleSearch(searchTerm: string) {
    const searchResults = aircrafts?.filter((data) => {
      const optionName = data.name.toLowerCase();
      const searchTermChars = searchTerm.toLowerCase().split("");

      let searchIndex = 0;
      for (const char of optionName) {
        if (char === searchTermChars[searchIndex]) {
          searchIndex++;
        }

        if (searchIndex === searchTermChars.length) {
          return true; // All characters in the search term found in order
        }
      }

      return false;
    });

    if (searchResults) setFilteredOptions(searchResults);
  }

  function handleSearchCatagory(searchTerm: string) {
    const searchResults = catagories?.filter((data) => {
      const optionName = data.name.toLowerCase();
      const searchTermChars = searchTerm.toLowerCase().split("");

      let searchIndex = 0;
      for (const char of optionName) {
        if (char === searchTermChars[searchIndex]) {
          searchIndex++;
        }

        if (searchIndex === searchTermChars.length) {
          return true; // All characters in the search term found in order
        }
      }

      return false;
    });

    if (searchResults) setFilteredOptions(searchResults);
  }

  function handleCopyDocuments(aircraftId: any, type?: string) {
    const aircraft = aircrafts.find((item) => item.id === parseInt(aircraftId));
    if (aircraft) {
      const newDocuments = documents.filter(
        (doc) => {
          if (type !== "equipment")
            return (doc.aircraftId === aircraft.id)
          return (doc.aircraftId === aircraft.id && doc.catagoryId === selectCatagory.id)
        }
      ).map((doc) => {
        return {
          ...doc,
          _id: uuid(),
          aircraftId: openDrawerForm.id,
        };
      });
      onHandleDocuments([...documents, ...newDocuments], true);
    }
  }

  return (
    <div className="">
      <div className="">
        <div ref={menuRef} className="relative flex flex-col h-full">
          <button
            onClick={() => {
              handleSearch("");
              setOpenDropdown(!openDropdown);
            }}
            className="flex w-[11.875rem] bg-[#AB0273] px-[0.63rem] py-[0.3125rem] rounded-[1.25rem] border border-[#AB0273] items-center justify-between"
          >
            <span className="text-[1rem] font-[600]  text-[#FFFFFF] leading-normal">
              {SelectAircraftButton}
            </span>
            <span>
              {/* <img src={DownArrow} alt="logo" /> */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
              >
                <path
                  d="M26 12L16 22L6 12"
                  stroke="white"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
          </button>
          {openDropdown && (
            <div className="absolute top-[2.87rem] z-10 w-[11.875rem] border-2 border-[#000000]/30 bg-[#F5F5F5] rounded-[1.8rem] px-[1.12rem] pt-[0.7rem]">
              <input
                type="text"
                placeholder="Search..."
                className="flex w-full bg-[#F5F5F5]  text-[#242424] text-[0.875rem] font-[500]  px-[0.58rem] pb-[0.94rem] text-left  items-center outline-none"
                // value={searchTerm}
                onChange={(e) => handleSearch(e.target.value)}
              />

              <div className="max-h-[160px] overflow-y-scroll no-scrollbar ">
                {filteredOptions?.map((data: any) => (
                  <>
                    <button
                      onClick={() =>
                        setOpenDrawerForm({ id: data.id, name: data.name })
                      }
                      className="flex w-full text-[#242424] text-[1rem] font-[500]  px-[0.58rem] py-[0.94rem] text-left border-t-[0.0625rem] border-[#2C2C2C]  items-center"
                    >
                      <div className="overflow-hidden w-[10.875rem]">
                        {data.name}
                      </div>
                    </button>
                  </>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>

      {/* open drawer form */}
      <Drawer
        onOpened={openDrawerForm}
        onClose={() => {
          setOpenDrawerForm(null);
          setSelectCatagory(null);
        }}
        title={openDrawerForm?.name}
      >
        <>
          {openDrawerForm && searchUrlParams.get("screen") !== "emergency" ? (
            <div className="flex flex-col h-full w-full">
              <div className="grow space-y-[1.56rem] overflow-y-scroll">
                {(documents.length > 0 && documents
                  .filter((docs) => docs.aircraftId === openDrawerForm.id).length != 0) ? documents
                    .filter((docs) => docs.aircraftId === openDrawerForm.id)
                    .map((doc: any, idx: number) => {
                      return (
                        <div key={doc._id} className="pb-[1.25rem]">
                          <div className="w-full flex items-center justify-between whitespace-nowrap bg-[#AB0273] px-[0.49rem] py-2">
                            <span className="overflow-hidden w-full font-bold text-[#FFFFFF]">
                              {DocumentType} {idx + 1}
                            </span>
                            <button onClick={() => handleDeletePopup(doc._id)}>
                              <img src={DeleteLogo} alt="logo" />
                            </button>
                          </div>

                          {DocumentType === "QRH Document" && (
                            <QRHDoc
                              state={doc._id === state?._id ? state : null}
                              openDrawerForm={openDrawerForm}
                              users={users}
                              document={doc}
                              onHandleDocument={(document: any) =>
                                handleDoc(document)
                              }
                            />
                          )}

                          {DocumentType === "Pre-Flight Document" && (
                            <PreFlightDoc
                              state={doc._id === state?._id ? state : null}
                              openDrawerForm={openDrawerForm}
                              users={users}
                              document={doc}
                              onHandleDocument={(document: any) =>
                                handleDoc(document)
                              }
                            />
                          )}

                          {DocumentType === "Flight-Ops Training Document" && (
                            <FlightOpsTrainingDoc
                              state={doc._id === state?._id ? state : null}
                              openDrawerForm={openDrawerForm}
                              users={users}
                              document={doc}
                              onHandleDocument={(document: any) =>
                                handleDoc(document)
                              }
                            />
                          )}

                          {DocumentType === "System Diff Document" && (
                            <SystemDiffDoc
                              state={doc._id === state?._id ? state : null}
                              openDrawerForm={openDrawerForm}
                              users={users}
                              document={doc}
                              onHandleDocument={(document: any) =>
                                handleDoc(document)
                              }
                            />
                          )}

                          {DocumentType === "PA Announcement" && (
                            <PAannouncementDoc
                              state={doc._id === state?._id ? state : null}
                              // state={state}
                              // handleStateNull={() => setState(null)}
                              openDrawerForm={openDrawerForm}
                              users={users}
                              languages={languages}
                              document={doc}
                              onHandleDocument={(document: any) =>
                                handleDoc(document)
                              }
                            />
                          )}
                          {DocumentType === "Video" && (
                            <VideosDoc
                              state={doc._id === state?._id ? state : null}
                              openDrawerForm={openDrawerForm}
                              users={users}
                              languages={languages}
                              document={doc}
                              onHandleDocument={(document: any) =>
                                handleDoc(document)
                              }
                            />
                          )}
                        </div>
                      );
                    }) : <>
                  <div>
                    <div className="flex items-center justify-center w-full py-5">
                      <span>No {DocumentType} found</span>
                    </div>

                    <div className="flex flex-col items-center justify-center w-full py-5">
                      <span>Click on the button below to add new {DocumentType}</span>

                      <span>or</span>

                      <span>Copy from the previously created aircraft</span>
                      <select
                        className="border border-[#2C2C2C] rounded-[1.875rem] px-3 py-2"
                        onChange={(e) => {
                          console.log(e.target.value);
                          handleCopyDocuments(e.target.value);
                        }}
                      >
                        <option value="">Select Aircraft</option>
                        {aircrafts.filter((item) => item.id !== openDrawerForm.id).map((aircraft) => (
                          <option key={aircraft.id} value={aircraft.id}>
                            {aircraft.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </>
                }
              </div>
              <div className=" border-t border-[#2C2C2C] px-[0.94rem] py-5 flex justify-between items-center">
                {DocumentType === "Video" ||
                  DocumentType === "PA Announcement" ? (
                  <div className="">
                    <button
                      onClick={() => {
                        if (documents) {
                          console.log("languages: ", languages);
                          const newLanguageDoc = languages?.map((language) => {
                            return {
                              id: language.id,
                              transcriptText: "",
                            };
                          });
                          console.log(newLanguageDoc);
                          return onHandleDocuments(
                            [
                              ...documents,
                              {
                                aircraftId: openDrawerForm.id,
                                _id: new Date().getTime(),
                                title: "",
                                date: "",
                                languageDoc: newLanguageDoc,
                                selectedOptions: [],
                              },
                            ],
                            true
                          );
                        }
                      }}
                      className="flex space-x-1 bg-[#C2262E] rounded-[1.875rem] text-[1rem]  leading-normal w-full text-[#FFFFFF] font-bold px-5 py-3 border border-[#66666654] justify-center items-center"
                    >
                      <span className="flex space-x-2">
                        {" "}
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M3.75 12H20.25"
                              stroke="white"
                              stroke-width="3"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M12 3.75V20.25"
                              stroke="white"
                              stroke-width="3"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </span>
                        <span>Add New {DocumentType}</span>
                      </span>
                    </button>
                  </div>
                ) : (
                  <div className="">
                    <button
                      onClick={() => {
                        if (documents) {
                          return onHandleDocuments(
                            [
                              ...documents,
                              {
                                aircraftId: openDrawerForm.id,
                                _id: new Date().getTime(),
                                title: "",
                                date: "",
                                file: null,
                                selectedOptions: [],
                              },
                            ],
                            true
                          );
                        }
                      }}
                      className="flex space-x-1 bg-[#C2262E] rounded-[1.875rem] w-full text-[#FFFFFF] text-[1rem]  leading-normal font-bold px-5 py-3 border border-[#66666654] justify-center items-center"
                    >
                      <span className="flex space-x-2">
                        {" "}
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M3.75 12H20.25"
                              stroke="white"
                              stroke-width="3"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M12 3.75V20.25"
                              stroke="white"
                              stroke-width="3"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </span>
                        <span>Add New {DocumentType}</span>
                      </span>
                    </button>
                  </div>
                )}

                <button
                  onClick={() => {
                    setOpenDrawerForm(null);
                    setOpenDropdown(false);
                  }}
                  className="px-6 py-3 text-[1rem] text-[#FFFFFF] bg-[#AB0273] rounded-[1.875rem]  "
                >
                  Done
                </button>
              </div>
            </div>
          ) : (
            <div className="flex flex-col h-full">
              <div className="grow space-y-4 mt-[0.88rem] overflow-y-scroll">
                <div className="relative flex flex-col items-center text-[16px]">
                  <div className="pb-[2.56rem]">
                    <button
                      onClick={() => {
                        handleSearchCatagory("");
                        setOpenCatagory(!openCatagory);
                      }}
                      className="flex w-[23.75rem] bg-[#AB0273] px-[0.63rem] py-[0.3125rem] rounded-[1.25rem] border border-[#AB0273] items-center justify-between"
                    >
                      <span className="text-[1rem] font-[600] text-[#FFFFFF] leading-normal">
                        Select Emergency Equipment Catagory
                      </span>
                      <span className="block">
                        {/* <img src={DownArrow} alt="logo" /> */}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="32"
                          height="32"
                          viewBox="0 0 32 32"
                          fill="none"
                        >
                          <path
                            d="M26 12L16 22L6 12"
                            stroke="white"
                            stroke-width="3"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </span>
                    </button>
                  </div>
                  {openCatagory && (
                    <div className="absolute top-[2.85rem]  z-10 w-[23.75rem] border-2 border-[#000000]/30 bg-[#F5F5F5]  rounded-[1.8rem] px-[1.12rem] pt-[0.7rem]">

                      <input
                        type="text"
                        placeholder="Search..."
                        className="flex w-full bg-[#F5F5F5]  text-[#242424] text-[0.875rem] font-[500]  px-[0.58rem] pb-[0.94rem] text-left  items-center outline-none"
                        // value={searchTerm}
                        onChange={(e) => handleSearchCatagory(e.target.value)}
                      />

                      <div className="max-h-[160px] overflow-y-scroll no-scrollbar">
                        {filteredOptions?.map((catagory: any) => (
                          <>
                            <button
                              onClick={() => {
                                setSelectCatagory(null);
                                setSelectCatagory({
                                  id: catagory.id,
                                  name: catagory.name,
                                });
                                // handleCreateDocument(catagory);

                                setOpenCatagory(false);
                              }}
                              className="flex w-full text-[#242424] text-[1rem] font-[500]  px-[0.58rem] py-[0.94rem] text-left border-t-[0.0625rem] border-[#2C2C2C]  items-center"
                            >
                              <div className="overflow-hidden w-[10.875rem]">
                                {catagory.name}
                              </div>
                            </button>
                          </>
                        ))}
                      </div>
                    </div>
                  )}
                  {selectCatagory && (
                    <>
                      {(documents.length > 0 && documents
                        .filter((docs) => docs.aircraftId === openDrawerForm.id && docs.catagoryId === selectCatagory.id).length != 0) ? documents
                          .filter(
                            (docs: any) =>
                              docs.aircraftId === openDrawerForm.id &&
                              docs.catagoryId === selectCatagory.id
                          )
                          .map((doc: any, idx: number) => {
                            return (
                              <div key={doc._id} className="w-full">
                                <div className="w-full flex items-center justify-between px-4 whitespace-nowrap bg-[#AB0273] py-2">
                                  <div className="overflow-hidden w-full font-bold text-white">
                                    {selectCatagory.name} (Emergency Equipment{" "}
                                    {idx + 1})
                                  </div>
                                  <button
                                    className="w-5 h-5"
                                    onClick={() => handleDeletePopup(doc._id)}
                                  >
                                    <img src={DeleteLogo} alt="logo" />
                                  </button>
                                </div>
                                <>
                                  {DocumentType === "Emergency Equipment" && (
                                    <EquipmentDoc
                                      state={
                                        doc._id === state?._id
                                          ? {
                                            ...state,
                                            bgFile: bgFile,
                                            selectCatagory: selectCatagory,
                                            catagories: catagories,
                                            documents: documents,
                                            aircrafts: aircrafts,
                                            users: users,
                                            DocumentType: DocumentType,
                                          }
                                          : {
                                            bgFile: bgFile,
                                            selectCatagory: selectCatagory,
                                            catagories: catagories,
                                            documents: documents,
                                            aircrafts: aircrafts,
                                            users: users,
                                            DocumentType: DocumentType,
                                          }
                                      }
                                      handleStateNull={() => setState(null)}
                                      openDrawerForm={openDrawerForm}
                                      users={users}
                                      document={doc}
                                      onHandleDocument={(document: any) => {
                                        console.log(document);
                                        handleDoc(document);
                                      }}
                                    />
                                  )}
                                </>
                              </div>
                            );
                          })
                        :
                        <>
                          <div>
                            <div className="flex items-center justify-center w-full py-5">
                              <span>No {DocumentType} found</span>
                            </div>

                            <div className="flex flex-col items-center justify-center w-full py-5">
                              <span>Click on the button below to add new {DocumentType}</span>

                              <span>or</span>

                              <span>Copy from the previously created aircraft</span>
                              <select
                                className="border border-[#2C2C2C] rounded-[1.875rem] px-3 py-2"
                                onChange={(e) => {
                                  console.log(e.target.value);
                                  handleCopyDocuments(e.target.value, "equipment");
                                }}
                              >
                                <option value="">Select Aircraft</option>
                                {aircrafts.filter((item) => item.id !== openDrawerForm.id).map((aircraft) => (
                                  <option key={aircraft.id} value={aircraft.id}>
                                    {aircraft.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </>
                      }
                    </>
                  )}
                </div>
              </div>
              <div className=" border-t border-[#2C2C2C] px-[0.94rem] py-5 flex w-full justify-between items-center">
                {selectCatagory && (
                  <div className=" w-full">
                    <button
                      onClick={() => {
                        handleCreateDocument();
                      }}
                      className="flex space-x-1 bg-[#C2262E] rounded-[1.875rem] text-[#FFFFFF] text-[1rem] leading-normal font-bold px-5 py-3 border border-[#66666654] justify-center items-center"
                    >
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M3.75 12H20.25"
                            stroke="white"
                            stroke-width="3"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M12 3.75V20.25"
                            stroke="white"
                            stroke-width="3"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </span>
                      <span>Add New {DocumentType}</span>
                    </button>
                  </div>
                )}
                <div
                  className={`flex  justify-end ${!selectCatagory && "w-full"}`}
                >
                  <button
                    onClick={() => {
                      setOpenDrawerForm(null);
                      setSelectCatagory(null);
                      setOpenDropdown(false);
                    }}
                    className="px-6 py-3 text-[1rem] text-[#FFFFFF] bg-[#AB0273] rounded-[1.875rem] "
                  >
                    Done
                  </button>
                </div>
              </div>
            </div>
          )}
        </>
      </Drawer>

      <Modal
        onOpened={openDeletePopup}
        onClose={() => setOpenDeletePopup(false)}
        title="Confirm Delete"
        height="calc(100%-400px)"
        passUnitIDtoDelete={
          passUnitIdToDelete ? String(passUnitIdToDelete) : ""
        }
      >
        <div className="flex flex-col h-full items-center pt-4 px-4 space-y-4">
          <div className="flex items-center justify-center w-full py-5">
            <span>Are you sure you want to delete this Document?</span>
          </div>

          <div className=" border-t-[0.0625rem] border-[#2C2C2C] py-5 px-[6rem] w-full flex justify-center space-x-[4rem] items-center">
            <button
              onClick={() => setOpenDeletePopup(false)}
              className=" text-[#FFFFFF] px-5 py-2 text-[16px] rounded-[1.875rem] bg-[#929292]"
            >
              Cancel
            </button>
            <button
              onClick={() => {
                handleDelete(passUnitIdToDelete);
              }}
              className="text-[#FFFFFF] px-5 py-2 text-[16px] rounded-[1.875rem] bg-red-600"
            >
              Delete
            </button>
          </div>
        </div>
      </Modal>

      {/* {isLoading &&
        <div className="bg-black/50 z-[99999] backdrop-blur-sm fixed top-0 bottom-0 left-0 right-0 flex items-center justify-center"> */}
      {/* <span className="text-white text-xl font-bold">Loading...</span> */}
      {/* <Loader loading={isLoading} color="#ffffff" />
        </div>} */}
    </div>
  );
}

export default SelectDropdown;
