import React, { useMemo } from 'react';

type Props = {
    mode: "success" | "warn" | "failure";
    onClose: () => void;
    message: string;
}

const style = {
    success: "bg-green-500",
    warn: "bg-orange-500",
    failure: "bg-red-500"
}

export const Toast = ({ mode, onClose, message }: Props) => {
  const classes = useMemo(
    () => ["px-4 py-2 text-white text-lg font-semibold rounded-md", style[mode]].join(' '),
    [mode],
  );

  return (
    <div onClick={onClose} className={classes}>
      <div className="">{message}</div>
    </div>
  );
};
