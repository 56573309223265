import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useTemplateTracking } from "src/hooks/useTemplateTracking";
import Modal from "src/utils/Modal";

type Props = {
  data: any;
  onNext: (nextTemplateId: string) => void;
};

export function ThreeDModelScreen({ data, onNext }: Props) {
  // const [openPopup, setOpenPopup] = useState(false);

  const { headingFont, bodyFont, headingColor } = useSelector((state: any) => state.programSettings);
  const { accessToken } = useSelector((state: any) => state.accessToken);
  const { businessUnitId, sepmId } = useParams();

  useTemplateTracking({ moduleId: data._id, businessUnitId, sepmId, accessToken });

  return (
    <div className={`overflow-y-scroll no-scrollbar w-full h-[calc(100%-2.625rem)] md:h-[calc(100%-3.625rem)] lg:h-[calc(100%-4.625rem)] xl:h-[calc(100%-5.25rem)] flex flex-col space-y-[2rem] md:space-y-[3rem] lg:space-y-[4rem] pb-[0.5rem]`}>
      <div className="grow w-full flex flex-col space-y-[2.87rem] pt-[1rem] md:pt-[2.12rem] pl-[3.75rem] md:pl-[2.5rem] lg:pl-[3.75rem]">
        <div
          style={{ color: headingColor, borderColor: headingColor }}
          className={`${!headingColor && "text-[#FFFFFF] border-[#FFFFFF]"} text-[1.7rem] md:text-[2rem] lg:text-[2.5rem] font-semibold border-l-4 md:border-l-[7px] pl-[1.5rem] leading-normal ${headingFont && `font-${headingFont}`}`}>
          {data.content.title}
        </div>

        <div className="w-full flex items-center justify-center pt-24">
          <div className={`w-full sm:w-[32.5625rem] flex flex-col justify-between items-center py-7 space-y-2 bg-[#FFFFFF] font-inter ${bodyFont && `font-${bodyFont}`}`}>
            <div className="text-[#008771] text-[30px] font-medium">
              3D MODEL
            </div>
            <div className="text-[#757575] text-[14px] font-medium">
              The 3D Model is only available for mobile devices. Contact your Admin.
            </div>
          </div>
        </div>
      </div>
      {/* 
      <Modal
      onOpened={openPopup}
      onClose= {() => setOpenPopup(false)}
      title= ""
      >
        <div>View on mobile</div>
      </Modal> */}
    </div>
  );
}
