import React, { useState, useEffect } from "react";
import InputText from "src/utils/TextInput/InputText";
import UploadButton from "src/utils/UploadButton";
import UploadDropdowns from "../UploadDropdowns";

type Props = {
  state?: any;
  openCreateModal?: any;
  input: string;
  onChangeInput: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onHandleAdd: (file: any, iconfile: any) => void;
};

function AddEquipments({ state, openCreateModal, input, onChangeInput, onHandleAdd }: Props) {
  const [equipmentIconfile, setequipmentIconFile] = useState<any>(null);
  const [equipmentFile, setequipmentFile] = useState<any>(null);

  useEffect(() => {

    if (state) {
      if (state.file && state.uploadButtonFor === "equipmentIconImageFile") {
        setequipmentIconFile(state.file);
        setequipmentFile(state.equipmentFile);
      }
      if (state.file && state.uploadButtonFor === "equipmentImageFile") {
        setequipmentIconFile(state.equipmentIconfile);
        setequipmentFile(state.file);
      }
    }
  }, [input, state]);

  return (
    <div className="flex flex-col h-full  px-[0.45rem]">
      <div className="grow space-y-[1.5rem] py-[1.25rem] overflow-y-scroll no-scrollbar">
        <div>
          <InputText
            value={input}
            onChange={(e) => onChangeInput(e)}
            placeholder="Enter Name"
            labelTitle="Name"
          />
        </div>

        <div className="flex flex-col space-y-3 px-[0.49rem]">
          <div className="text-[16px] font-[600]">Equipment Icon</div>
          <div className="flex space-x-1 items-center">
            <UploadButton
              name="equipmentIconImageFile"
              type="image"
              pageState={{
                ...state,
                uploadButtonFor: "equipmentIconImageFile",
                input,
                openCreateModal,
                equipmentIconfile,
                equipmentFile,
              }}
              handleUploadFromDevice={(file) => {
                setequipmentIconFile(file);
              }}
            />
            <UploadDropdowns 
            name="equipmentIconImageFile"
            type="image"
            pageState={{
              ...state,
              uploadButtonFor: "equipmentIconImageFile",
              input,
              openCreateModal,
              equipmentIconfile,
              equipmentFile,
            }}
            handleCropFile={(cropFile) => setequipmentIconFile(cropFile)}
            openCreateModal={openCreateModal} File={equipmentIconfile} />
          </div>
        </div>

        <div className="flex flex-col space-y-3 px-[0.49rem]">
          <div className="text-[16px] font-[600]">Equipment Image</div>
          <div className="flex space-x-1 items-center">
            <UploadButton
              name="equipmentImageFile"
              type="image"
              pageState={{
                ...state,
                uploadButtonFor: "equipmentImageFile",
                input,
                openCreateModal,
                equipmentIconfile,
                equipmentFile,
              }}
              handleUploadFromDevice={(file) => {
                setequipmentFile(file);
              }}
            />
            <UploadDropdowns 
            name="equipmentImageFile"
            type="image"
            pageState={{
              ...state,
              uploadButtonFor: "equipmentImageFile",
              input,
              openCreateModal,
              equipmentIconfile,
              equipmentFile,
            }}
            handleCropFile={(cropFile) => setequipmentFile(cropFile)}
            openCreateModal={openCreateModal} File={equipmentFile} />
          </div>
        </div>
      </div>

      <div className="border-t px-[0.49rem] py-5 flex justify-end items-center">
        <button
          onClick={() => onHandleAdd(equipmentFile, equipmentIconfile)}
          className="px-7 py-3 text-[1rem] text-[#FFFFFF] bg-[#AB0273] rounded-[1.875rem]"
        >
          Done
        </button>
      </div>
    </div>
  );
}

export default AddEquipments;
