import React, { useEffect, useState } from "react";
import Modal from "src/utils/Modal";
import Text from "src/utils/Text/Txt";
import InputText from "src/utils/TextInput/InputText";
import UploadButton from "src/utils/UploadButton";
import MultipleCheck from "src/utils/MultipleCheck";
import DownArrow from "../../assets/down.png"

type Props = {
  editData: any;
  onSetEditData: (data: any) => void;
  onHandleEdit: () => void;
  onHandleDelete: (id: any) => void;
  equipments?: any[];
  sections?: any[];
};

function EditLocations({ editData, onSetEditData, onHandleEdit, onHandleDelete, equipments, sections }: Props) {
    
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [openSection, setOpenSection] = useState(false);
  const [selectSection, setSelectSection] = useState<any>(editData?.section);
  const [savedSelectedOptions, setSavedSelectedOptions] = useState<any>(editData?.equipments);
  const [passUnitIdToDelete, setPassUnitIdToDelete] = useState<string | null>(
    null
  );
  const [name, setName] = useState<string>(editData?.name)

  const [filteredOptions, setFilteredOptions] = useState<Array<any>>([]);

  useEffect(() => {
    onSetEditData({
        id: editData?.id,
        name,
        section: selectSection,
        equipments: savedSelectedOptions
    })
  }, [name, selectSection, savedSelectedOptions])

  function handleDeletePopup(id: any) {
    setOpenDeletePopup(true);
    setPassUnitIdToDelete(id);
    console.log("delete id", passUnitIdToDelete);
  }

  function handleDelete(id: any) {

    onHandleDelete(id);
    
    setOpenDeletePopup(false);
  }

  function handleSearch(searchTerm: string) {
    const searchResults = sections?.filter((data) => {
      const optionName = data.name.toLowerCase();
      const searchTermChars = searchTerm.toLowerCase().split("");

      let searchIndex = 0;
      for (const char of optionName) {
        if (char === searchTermChars[searchIndex]) {
          searchIndex++;
        }

        if (searchIndex === searchTermChars.length) {
          return true; // All characters in the search term found in order
        }
      }

      return false;
    });

    if (searchResults) setFilteredOptions(searchResults);
    
  }

  return (
    <>
      <div className="flex flex-col h-full  px-[0.45rem]">

      <div className="relative pt-[0.62rem]">
          <div className="py-[0.63rem] px-[0.49rem] z-10">
            <button
              onClick={() => {
                handleSearch("");
                setOpenSection(!openSection);
              }}
              className="relative flex w-[11.875rem] bg-[#AB0273] px-[0.63rem] py-[0.3125rem] rounded-[1.25rem] border border-[#AB0273] items-center justify-between"
            >
              <span className="text-[1rem] font-[600] text-[#FFFFFF] leading-normal">Select Section</span>
              <span className="block">
                {/* <img src={DownArrow} alt="logo" /> */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                >
                  <path
                    d="M26 12L16 22L6 12"
                    stroke="white"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
            </button>
          </div>

          {openSection && (
            <div className="absolute top-[4.1rem] left-[0.49rem] w-[11.875rem] border-2 border-[#000000]/30 bg-[#F5F5F5]  rounded-[1.8rem] px-[1.12rem] py-[0.7rem] z-[999] pl-4">

              <input
                type="text"
                placeholder="Search..."
                className="flex w-full bg-[#F5F5F5]  text-[#242424] text-[0.875rem] font-[500]  px-[0.58rem] pb-[0.94rem] text-left  items-center outline-none"
                // value={searchTerm}
                onChange={(e) => handleSearch(e.target.value)}
              />

              <div className="max-h-[163.5px] overflow-y-scroll no-scrollbar">
              {filteredOptions?.map((section: any) => (
                <>
                  <button
                    onClick={() => {
                      setSelectSection(null);
                      setSelectSection({
                        id: section.id,
                        name: section.name,
                      });
                      setOpenSection(false);
                    }}
                    className="flex w-full text-[#242424] text-[1rem] font-[500]  px-[0.58rem] py-[0.94rem] text-left border-t-[0.0625rem] border-[#2C2C2C] items-center"
                  >
                    <div className="overflow-hidden w-[10.875rem]">
                      {section.name}
                    </div>
                  </button>
                </>
              ))}
              </div>
            </div>
          )}
        </div>

        {selectSection && <div className="flex items-center space-x-2 px-[0.49rem] my-[0.63rem]"> <span className="font-[600] text-[1rem] text-[#AB0273]">{selectSection.name}
            </span>
            <div className="w-full h-[0.0625rem] bg-[#242424]">

            </div>
            </div>}

        <div className="w-full px-[0.49rem] pt-[0.81rem]">
          <div className="text-[1rem] font-[600] leading-normal text-[#242424]">
            Select Equipments:
          </div>
          <MultipleCheck
            SelectTitle="Select Equipments"
            options={equipments || []}
            savedSelectedOptions={savedSelectedOptions}
            onHandleSelectedOptions={(selectedOptions) =>
              setSavedSelectedOptions(selectedOptions)
            }
          />
        </div>

        <div className="py-[1.25rem]">
          <InputText
            value={name}
            onChange={(e) => setName(e.target.value)}
            labelTitle="Name"
            autoFocus={true}
          />
        </div>

        <div className="border-t px-[0.49rem] py-5 flex justify-between items-center">
          <button
            onClick={() => handleDeletePopup(editData.id)}
            className="px-6 py-3 text-[16px] rounded-[1.875rem] text-[#FFFFFF] bg-[#929292] "
          >
            Delete
          </button>
          <button
            onClick={() => {
              onHandleEdit();
            }}
            className="px-7 py-3 text-[16px] rounded-[1.875rem] text-[#FFFFFF] bg-[#AB0273]"
          >
            Done
          </button>
        </div>
      </div>

      <Modal
        onOpened={openDeletePopup}
        onClose={() => setOpenDeletePopup(false)}
        title="Confirm Delete"
        height="calc(100%-400px)"
        passUnitIDtoDelete={passUnitIdToDelete || ""}
      >
        <div className="flex flex-col h-full items-center pt-4 px-4 space-y-4">
          <div className="flex items-center justify-center w-full py-5">
              <span>Are you sure you want to delete this Language?</span>
          </div>

          <div className="border-t-[0.0625rem] border-[#2C2C2C] py-5 w-full flex justify-center space-x-[4rem] items-center">
              <button
                onClick={() => setOpenDeletePopup(false)}
                className="text-[#FFFFFF] px-5 py-2 text-[16px] rounded-[1.875rem] bg-[#929292]"
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  handleDelete(passUnitIdToDelete);
                }}
                className="text-[#FFFFFF] px-5 py-2 text-[16px] rounded-[1.875rem] bg-red-600"
              >
                Delete
              </button>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default EditLocations;
