import React, { useEffect, useState } from "react";
import { ChevronRight } from "@carbon/icons-react";
import { ChevronLeft } from "@carbon/icons-react";
import { useSelector } from "react-redux";
import { setThemeColor } from "src/features/programSettings/programSettingsSlice";
import Loader from "src/utils/Loader";
import { useDispatch } from "react-redux";
import { Module, setCurrentPath, setModules } from "src/features/trackedModules/trackedModulesSlice";
import { storeTrackingData } from "src/helpers/Tracking";
import { useParams } from "react-router-dom";

type Props = {
  data: any;
  title: string;
  handleSelectUser: (user: any) => void;
};

export function SelectUser({ data, title, handleSelectUser }: Props) {
  const { modules, currentPath } = useSelector((state: any) => state.trackedModules);

  const { businessUnitId, sepmId } = useParams();

  const dispatch = useDispatch();
  const { accessToken } = useSelector((state: any) => state.accessToken);
  const { themeColor, headingFont, bodyFont, headingColor, thumbnailHeadingColor, thumbnailTitleColor } = useSelector((state: any) => state.programSettings);

  // Load from session storage on mount
  useEffect(() => {
    const storedModules = sessionStorage.getItem("modules");
    if (storedModules) {
      dispatch(setModules(JSON.parse(storedModules)));
      dispatch(setCurrentPath([]));
    } else {
      console.log("here is the user data: ", data);
      const initialModules: Module[] = data.map((module: any) => {
        return {
          id: module.id,
          title: module.name,
          status: "gray",
          type: "user",
          elapsedTime: 0
        }
      })
      sessionStorage.setItem("modules", JSON.stringify(initialModules));
      businessUnitId && sepmId && storeTrackingData(initialModules, businessUnitId, sepmId, accessToken)
      dispatch(setModules(initialModules))
      dispatch(setCurrentPath([]));
    }
  }, []);

  // const users = data.content.users;
  // console.log(users);

  // Code for PAGINATION: -->>
  // const usersPerPage = 4;

  // const [currentPage, setCurrentPage] = useState(1);

  // const startIndex = (currentPage - 1) * usersPerPage;
  // const endIndex = startIndex + usersPerPage;

  // // Get the users for the current page
  // const displayedUsers = users.slice(startIndex, endIndex);

  // const goToNextPage = () => {
  //   setCurrentPage((prevPage) => prevPage + 1);
  // };

  // const goToPreviousPage = () => {
  //   setCurrentPage((prevPage) => Math.max(prevPage - 1, 1)); // Ensure not to go below page 1
  // };

  return (
    <div className={`flex flex-col h-full grow space-y-[3rem] md:space-y-[3.4rem] lg:space-y-[5rem] ${bodyFont && `font-${bodyFont}`}`}>
      <div className={`text-[1.5rem] md:text-[1.7rem] lg:text-[2rem] font-semibold ${headingFont && `font-${headingFont}`}`}>
        <div
          style={{ color: headingColor, borderColor: headingColor }}
          className={`border-l-4 md:border-l-[7px] pl-[1rem] md:pl-[1.5rem] leading-normal ${!headingColor && "text-[#FFFFFF] border-[#FFFFFF]"}`}>
          {title}
        </div>
      </div>

      {/* ✅ Fix applied here */}
      <div className="flex-1 min-h-0 overflow-y-auto no-scrollbar">
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-[2.5rem] gap-y-[3rem] lg:gap-y-[6.25rem] lg:gap-x-[8.13rem] pl-0 lg:pl-[1.5rem]">
          {data.map((user: any) => {
            const moduleStatus = modules.find((module: Module) => module.id === user.id)?.status;
            return (
              <UserCard key={user._id} status={moduleStatus} themeColor={themeColor} thumbnailHeadingColor={thumbnailHeadingColor} thumbnailTitleColor={thumbnailTitleColor} user={user} handleSelect={() => handleSelectUser(user)} />
            )
          })}
        </div>
      </div>
    </div>
  );
}


const UserCard: React.FC<{ user: any, status: string, themeColor: any, thumbnailHeadingColor: any, thumbnailTitleColor: any, handleSelect: () => void }> = ({ user, status, themeColor, thumbnailHeadingColor, thumbnailTitleColor, handleSelect }) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const img = new Image();
    img.src = user.file?.url ?? "";
    img.onload = () => setIsLoading(false);
  }, [user.file?.url]);

  return (
    <button onClick={() => handleSelect()} className="relative flex flex-col">
      <div
        style={{ borderColor: `${themeColor}CC` }}
        className={`w-full max-w-[14.375rem] h-[14.375rem] flex-shrink-0 overflow-hidden border-2 border-b-0 ${!themeColor && "border-[#2F0220]/80"}`}>
        {isLoading ? (
          <div className="w-full h-full flex items-center justify-center bg-gray-200">
            <Loader loading={isLoading} />
          </div>
        ) : (
          <img
            src={user.file?.url ?? ""}
            alt={`Profile of ${user.name}`}
            className="w-full h-full object-cover flex"
            loading="lazy"
          />
        )}
      </div>
      <div style={{ backgroundColor: `${themeColor}CC`, borderColor: `${themeColor}CC`, color: thumbnailTitleColor }} className={`flex w-full max-w-[14.375rem] h-[3.75rem] p-[0.625rem] justify-center items-center flex-shrink-0 ${!thumbnailTitleColor && "text-[#FFFFFF]"} text-center font-semibold text-[1.25rem] border-2  border-t-0 ${!themeColor && "bg-[#2F0220]/80 border-[#2F0220]/80"}`}>
        {user.name}
      </div>
      <span className={`absolute top-2 left-2 h-4 w-4 rounded-full ${status === "gray" ? "bg-gray-500" : status === "orange" ? "bg-orange-500" : "bg-green-500"}`}></span>
    </button>
  );
};


// Code For Pagination

{/* <div className="overflow-y-scroll no-scrollbar w-full h-full flex flex-col space-y-[3.69rem] mt-[1rem] md:pt-[2.12rem] mx-[3.75rem] ">
      <div className="text-[#FFFFFF] text-[2rem] md:text-[2.5rem] lg:text-[3rem] font-semibold border-l-[6px] md:border-l-8 pl-[1.37rem]">
        {data.content.title}
      </div>

      <div className=" grid grid-cols-12">
        <div className=" relative col-span-7">
          <button
            className={`absolute top-1/2 left-0 text-[#FFFFFF] ${users.length > 4 ? "block" : "hidden"}`}
            onClick={goToPreviousPage}
            disabled={currentPage === 1}
          >
            <ChevronLeft size={40} className="font-bold" />
          </button>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-x-[6.56rem] gap-y-[3.75rem] mx-[4rem]">
            {displayedUsers.map((user: any, idx: number) => (
              <UserCard key={user._id} user={user} />
            ))}
          </div>

          <button
            className={`absolute top-1/2 right-0 text-[#FFFFFF] ${users.length > 4 ? "block" : "hidden"}`}
            onClick={goToNextPage}
            disabled={endIndex >= users.length}
          >
            <ChevronRight size={40} className="font-bold" />
          </button>
        </div>
      </div>
    </div> */}
