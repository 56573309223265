"use client"

import * as React from "react"
import { Check, ChevronsUpDown } from "lucide-react"

import { cn } from "src/components/lib/utils"
import { Button } from "./button"
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "./Combobox/command"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "./Combobox/popover"

type Props = {
    data: any;
    handleTopicChange: (topic: any) => void;
}

// const frameworks = [
//   {
//     value: "next.js",
//     label: "Next.js",
//   },
//   {
//     value: "sveltekit",
//     label: "SvelteKit",
//   },
//   {
//     value: "nuxt.js",
//     label: "Nuxt.js",
//   },
//   {
//     value: "remix",
//     label: "Remix",
//   },
//   {
//     value: "astro",
//     label: "Astro",
//   },
// ]

export function ComboboxDemo({data, handleTopicChange} : Props ) {
  const [open, setOpen] = React.useState(false)
  const [id, setId] = React.useState('')
  const [filesData, setFilesData] = React.useState<any>();

  React.useEffect(() => {
    console.log(id);
  }, [id])

  React.useEffect(() => {
    console.log(data);
    const topics = getTopics(data);
    setFilesData(topics);
  },[data]);

  function getTopics(data: any) {
    let topics: any[] = [];
    data?.forEach((item: any) => {
        if (item?.type === 'file') {
            topics.push(item);
        } else if (item?.children) {
            topics = [...topics, ...getTopics(item?.children)];
        }
    });
    return topics
}

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className="w-[235px] justify-between"
          size="md"
        >
          <span className="text-sm fond-normal line-clamp-1">
          {id
            ? filesData?.find((topic: any) => topic?.id === id)?.name
            : "Select template"}
          </span>
          <span className="">
            <ChevronsUpDown className="h-4 w-4 opacity-50" />
          </span>
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[200px] p-0">
        <Command>
          <CommandInput placeholder="Search framework..." />
          <CommandList>
            <CommandEmpty>No template found.</CommandEmpty>
            <CommandGroup>
              {filesData?.map((topic: any) => (
                <CommandItem
                  key={topic?.id}
                  onSelect={() => {
                    console.log(topic?.id, id);
                    setId(topic?.id === id ? "" : topic?.id)
                    setOpen(false)
                    if (topic?.type === "file") {
                        handleTopicChange(topic);
                      }
                  }}
                >
                  <Check
                    className={cn(
                      "mr-2 h-4 w-4",
                      id === topic?.id ? "opacity-100" : "opacity-0"
                    )}
                  />
                  {topic?.name}
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  )
}
