import React, { useState, useEffect } from "react";
import InputText from "src/utils/TextInput/InputText";
import UploadButton from "src/utils/UploadButton";
import UploadDropdowns from "../UploadDropdowns";

type Props = {
  state?: any;
  input: string;
  openCreateModal?: any;
  onChangeInput: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onHandleAdd: () => void;
  handleDefineSection: () => void;
  sectionBounds: any[];
};

function AddSection({
  state,
  input,
  openCreateModal,
  onChangeInput,
  onHandleAdd,
  handleDefineSection,
  sectionBounds
}: Props) {
  const [file, setFile] = useState<any>(null);

  useEffect(() => {
    console.log(sectionBounds);
  }, [sectionBounds]);

  useEffect(() => {

    if (state) {
      if (state.file) setFile(state.file);
    }
  }, [input, state]);

  return (
    <div className="flex flex-col h-full  px-[0.45rem]">
      <div className="grow space-y-[1.5rem] py-[1.25rem] overflow-y-scroll no-scrollbar ">
        <div>
          <InputText
            value={input}
            onChange={(e) => onChangeInput(e)}
            placeholder="Enter Name"
            labelTitle="Name"
          />
        </div>

        <div className="flex flex-col space-y-3 px-[0.49rem]">
          <button onClick={() => handleDefineSection()} className="flex w-[8.875rem] bg-[#AB0273] px-[0.63rem] py-[0.3125rem] rounded-[1.25rem] border border-[#AB0273] items-center text-[1rem] font-[600] text-[#FFFFFF]">Define Section</button>
          {/* <div className="text-[16px] font-[600]">Section Image</div> */}
          <div className="flex space-x-1 items-center">
            {sectionBounds.length === 0 ? (
              <>
                <div className="relative flex flex-col items-centertext-[16px]">No bounds defined:</div>
              </>
            ) : (
              <>
                <div className="relative flex flex-col items-centertext-[16px]">Defined bound:</div>
                <div className="items-center overflow-hidden rounded-md border-2 px-2 py-1">
                  {sectionBounds[0]}
                </div>
                <div className="items-center overflow-hidden rounded-md border-2 px-2 py-1">
                  {sectionBounds[1]}
                </div>
              </>
            )}
            {/* <UploadButton
              type="image"
              pageState={{
                ...state,
                input: input,
                openCreateModal: openCreateModal,
              }}
              handleUploadFromDevice={(file) => {
                setFile(file);
              }}
            />
            <UploadDropdowns
              type="image"
              pageState={{
                ...state,
                input: input,
                openCreateModal: openCreateModal,
              }}
              handleCropFile={(cropFile) => setFile(cropFile)}
              openCreateModal={openCreateModal}
              File={file}
            /> */}
          </div>
        </div>
      </div>
      <div className=" border-t px-[0.49rem] py-5 flex justify-end items-center">
        <button
          onClick={() => onHandleAdd()}
          className="px-7 py-3 text-[1rem] text-[#FFFFFF] bg-[#AB0273] rounded-[1.875rem]"
        >
          Done
        </button>
      </div>
    </div>
  );
}

export default AddSection;
