import React, { useState, useEffect } from "react";
import VideoDropdown from "./Video";
import ImageDropdown from "./Image";
import AudioDropdown from "./Audio";
import DocumentDropdown from "./Document";

type Props = {
  openDrawerForm?: any;
  openCreateModal?: any;
  openEditModal?: any;
  pageState?: any;
  type?: string | null;
  name?: string;
  handleClick?: (name: string) => void;
  File: any;
  handleCropFile?: (file: any) => void;
};
function UploadDropdowns({ File, openDrawerForm, openCreateModal, openEditModal, pageState, type, name, handleClick, handleCropFile }: Props) {

  const [openDropdown, setOpenDropdown] = useState(false);
  const [showHover, setShowHover] = useState(false);

  const menuRef = React.createRef<HTMLDivElement>();

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!menuRef?.current?.contains(event.target as Node)) {
        setOpenDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      // Clean up the event listener when the component unmounts
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuRef]);

  return (
    <div>
      {File && (
        <div ref={menuRef} className="relative pr-4">
          <button
            onClick={() => setOpenDropdown(!openDropdown)}
            onMouseOver={() => setShowHover(File.hasOwnProperty('name') ? File.name : File.url)}
            onMouseOut={() => setShowHover(false)}
            className="text-[0.875rem] font-[600] leading-normal text-[#586A84]  text-left max-w-max p-[0.625rem] bg-[#EDEDED] rounded-[1.875rem]"
          >

            {/* {
              File?.name ? File.name.length > 19 ?
                (File.name).substring(0, 12) + "...  ." + (File.name).substr((File.name).lastIndexOf('.') + 1) :
                File.name
                : (File.url).substring(0, 12) + "...  ." + (File.url).substr((File.url).lastIndexOf('.') + 1)
            } */}

            {
              File.hasOwnProperty('name') ? File.name.length > 19 ? (File.name).substring(0, 12) + "...  ." + (File.name).substr((File.name).lastIndexOf('.') + 1) :
              File.name : <span className="line-clamp-1">{File.url}</span>
            }

          </button>
          {showHover && (
            <div className="absolute max-w-max left-[0.5rem] text-[#586A84] text-[0.7rem]">
              {File.hasOwnProperty('name') ? File.name : File.url}
            </div>
          )}
          {openDropdown && File.type?.split("/")[0] === "image" &&
            (<ImageDropdown name={name} type={type} pageState={pageState} handleCropFile={handleCropFile} file={File} openDrawerForm={openDrawerForm} openCreateModal={openCreateModal} openEditModal={openEditModal} />)
          }
          {openDropdown && File.type?.split("/")[0] === "audio" &&
            (<AudioDropdown type={type} pageState={pageState} handleCropFile={handleCropFile} file={File} openDrawerForm={openDrawerForm} />)
          }
          {openDropdown && File.type?.split("/")[0] === "video" &&
            (<VideoDropdown name={name} type={type} pageState={pageState} handleCropFile={handleCropFile} file={File} openDrawerForm={openDrawerForm} />)
          }
          {openDropdown && !File.hasOwnProperty('name') &&
            (<VideoDropdown name={name} type={type} pageState={pageState} handleCropFile={handleCropFile} file={File} openDrawerForm={openDrawerForm} />)
          }
          {openDropdown && File.type?.split("/")[0] === "application" &&
            (<DocumentDropdown openDrawerForm={openDrawerForm} />)
          }
        </div>
      )}
    </div>
  );
}

export default UploadDropdowns;
