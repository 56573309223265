import { Image, Shapes, Table, Type } from 'lucide-react';
import React from 'react';
import { useDispatch } from 'react-redux';
import { setSelectedTool } from 'src/features/courseTemplate/courseTemplateSlice';
import { DefaultToolData } from './Templates/Authoring/utils/DefaultToolData';

const ToolArray = [
  {
    id: 1,
    type: 'text',
    description: "Text",
    icon: <Type size={24} />,
  },
  {
    id: 2,
    type: 'image',
    description: "Image",
    icon: <Image size={24} />,
  },
  {
    id: 3,
    type: 'shape',
    description: "Shape",
    icon: <Shapes size={24} />,
  },
  {
    id: 4,
    type: 'table',
    description: "Table",
    icon: <Table size={24} />,
  },
]

const Tools = () => {
  const dispatch = useDispatch();

  function handleToolSelection(tool: any) {
    dispatch(setSelectedTool({
      id: new Date().getTime(),
      type: tool.type,
      x: 0,
      y: 0,
      width: 100,
      height: 50,
      content: tool.description,
      description: tool.description,
      data: DefaultToolData.get(tool.type)
    }))
  }

  return (
    <div className="sidebar relative flex w-10 max-w-[7rem] flex-col z-[99999]">
      {ToolArray.map(tool => (
        <div key={tool.id} className="relative group flex items-center justify-center p-2 cursor-pointer hover:bg-gray-200">
          <button onClick={() => handleToolSelection(tool)}>
            {tool.icon}
          </button>
          <span className="absolute left-full top-1/2 transform -translate-y-1/2 mb-2 px-2 py-1 max-w-max whitespace-nowrap bg-black text-white text-center text-sm rounded opacity-0 group-hover:block group-hover:opacity-100 transition-opacity duration-300">
            {tool.description}
          </span>
        </div>
      ))}
    </div>

  )
}

export default Tools;
