import React, { useEffect, useRef, useState } from 'react'
import Imageicon from "../../assets/Upload/Image.svg";
import ViewIcon from "../../assets/Upload/Eye.svg";
import CropIcon from "../../assets/Upload/Crop.svg";
import DeleteIcon from "../../assets/Upload/TrashSimple.svg";
import { useDispatch } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { setPageState } from 'src/features/templatePageState/templatePageStateSlice';
import Modal from 'src/utils/Modal';
import { getObectURL, putObectURL } from 'src/helpers/AWS_S3';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { set } from 'lodash';
import { start } from 'repl';

type Props = {
  openDrawerForm?: any;
  type?: string | null;
  pageState?: any;
  name?: string;
  handleClick?: (name: string) => void;
  file?: any;
  handleCropFile?: (file: any) => void;
}

function AudioDropdown({ openDrawerForm, type, pageState, name, handleClick, file, handleCropFile }: Props) {

  const [listenAudio, setListenAudio] = useState(false);
  const [recording, setRecording] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(null);
  const [audioUrl, setAudioUrl] = useState("");
  const [audio, setAudio] = useState<HTMLAudioElement | null>(null);
  const [audioChunks, setAudioChunks] = useState<BlobPart[]>([]);
  const [recordModal, setRecordModal] = useState(false);
  const [audioReady, setAudioReady] = useState(false);
  const [startTime, setStartTime] = useState<number | null>(null);
  const [timer, setTimer] = useState(0);

  const { authUser } = useSelector((state: any) => state.user);
  const { accessToken } = useSelector((state: any) => state.accessToken);

  const [searchParams, setSearchParams]: any = useSearchParams();

  const dispatch = useDispatch()

  const navigate = useNavigate();

  const { businessUnitId, sepmId, templateId, userId } = useParams();

  const audioChunksRef = useRef<BlobPart[]>([]);
  const startTimeRef = useRef<number | null>(null);
  const intervalIdRef = useRef<NodeJS.Timer | null>(null);


  useEffect(() => {
    if (recording) {
      navigator.mediaDevices.getUserMedia({ audio: true })
        .then(stream => {
          const newMediaRecorder = new MediaRecorder(stream);
          setMediaRecorder(newMediaRecorder);

          newMediaRecorder.start();

          newMediaRecorder.ondataavailable = event => {
            setAudioChunks((prev) => [...prev, event.data]);
            audioChunksRef.current = [...audioChunksRef.current, event.data];
          };

          newMediaRecorder.onstop = () => {
            console.log("audio chunks: ", audioChunksRef.current);
            const audioBlob = new Blob(audioChunksRef.current);
            // const audioBlob = new Blob(audioChunks);
            const newAudioUrl = URL.createObjectURL(audioBlob);
            setAudioUrl(newAudioUrl);
          };
        });
    }
  }, [recording]);

  useEffect(() => {
    audioChunksRef.current = audioChunks;
  }, [audioChunks]);

  useEffect(() => {
    if (audioUrl) {
      const newAudio = new Audio(audioUrl);
      setAudio(newAudio);
    }
  }, [audioUrl]);

  // Format the timer as mm:ss
  const formatTime = (time: number) => {
    const totalSeconds = Math.floor(time / 1000);
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  const handleChangeMediaButtonClick = () => {
    if (handleClick && name) handleClick(name);
    navigate(
      `/user/${userId}/businessunit/${businessUnitId}/sepm/${sepmId}/template/${templateId}/media?screen=${searchParams.get(
        "screen"
      )}&folderId=null&type=${type}`
    );
    if (pageState) dispatch(setPageState(pageState));
  };

  function removeFile() {
    if (handleCropFile) handleCropFile(null);
  }

  const startRecording = () => {
    setRecording(true);
    const start = Date.now();
    setStartTime(start);
    startTimeRef.current = start;
    intervalIdRef.current = setInterval(() => {
      if (startTimeRef.current) {
        setTimer(Date.now() - startTimeRef.current);
      }
    }, 1000);
  };

  const stopRecording = () => {
    setRecording(false);
    mediaRecorder?.stop();
    setStartTime(null);
    if (intervalIdRef.current) {
      clearInterval(intervalIdRef.current);
      intervalIdRef.current = null;
    }
  };

  const saveAudio = async () => {
    // const s3 = new AWS.S3();
    // const params = {
    //   Bucket: 'your-bucket-name',
    //   Key: 'your-object-key',
    //   Body: new Blob(audioChunks),
    //   ContentType: 'audio/wav'
    // };

    // s3.upload(params, function (err, data) {
    //   if (err) {
    //     console.log('Error uploading audio: ', err);
    //   } else {
    //     console.log('Successfully uploaded audio: ', data);
    //   }
    // });
    const filename = `recording-${new Date().toISOString()}.wav`
    const UploadUrl = await putObectURL(
      `uploads/users/${authUser.oid}/recordings/${filename}`,
      file.type
    );
    console.log("upload url: ", UploadUrl);
    axios
      .put(`${UploadUrl}`, new Blob(audioChunks), {
        onUploadProgress: (ProgressEvent) => console.log(ProgressEvent.loaded),
      })
      .then((response) => console.log(response.data))
      .catch((err) => console.log(err));

    const SignedUrl = await getObectURL(`uploads/users/${authUser.oid}/recordings/${filename}`)

    console.log("this is the signed url: ", SignedUrl)

    const response = await axios.post(
      `${process.env.REACT_APP_SERVER_BASE_URL}/api/v1/file/`,
      {
        name: filename,
        type: 'audio/wav',
        size: audioChunks.length,
        folderId: null,
        url: SignedUrl.split("?")[0],
      },
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    );

    console.log("Added files", response.data.data);

    setRecordModal(false);
    if (handleCropFile) handleCropFile(response.data.data);
  };



  return (
    <>
      {openDrawerForm ? (
        <div className="z-[9999] absolute bg-[#F5F5F5] w-[13rem] border-2 border-black/30 rounded-[1.5rem] py-[1.13rem] px-[1.06rem] space-y-[1.2rem]">

          <div className="flex space-x-[1.3rem] w-full text-[1rem] text-[#242424] items-center">
            <span>
              <img src={Imageicon} alt="" />
            </span>
            <button onClick={handleChangeMediaButtonClick}>Change Audio</button>
          </div>

          <div className="flex space-x-[1.3rem] w-full text-[1rem] text-[#242424] items-center">
            <span>
              <img src={ViewIcon} alt="" />
            </span>
            <button onClick={() => setListenAudio(true)}>Listen Audio</button>
          </div>

          <div className="flex space-x-[1.3rem] w-full text-[1rem] text-[#242424] items-center">
            <span>
              <img src={CropIcon} alt="" />
            </span>
            <button onClick={() => setRecordModal(true)}>Record Audio</button>
          </div>
          <div className="bg-[#2C2C2C] h-[0.063rem]"></div>
          <div className="flex space-x-[1.3rem] w-full text-[1rem] text-[#C2262E] items-center">
            <span>
              <img src={DeleteIcon} alt="" />
            </span>
            <button onClick={removeFile}>Remove</button>
          </div>
        </div>
      ) : (
        <div className="absolute inline-block ml-1 w-[13rem] bg-[#F5F5F5] border-2 border-black/30 rounded-[1.5rem] py-[1.13rem] px-[1.06rem] space-y-[1.2rem]">

          <div className="flex space-x-[1.3rem] w-full text-[1rem] text-[#242424] items-center">
            <span>
              <img src={Imageicon} alt="" />
            </span>
            <button onClick={handleChangeMediaButtonClick}>Change Audio</button>
          </div>

          <div className="flex space-x-[1.3rem] w-full text-[1rem] text-[#242424] items-center">
            <span>
              <img src={ViewIcon} alt="" />
            </span>
            <button onClick={() => setListenAudio(true)}>Listen Audio</button>
          </div>

          <div className="flex space-x-[1.3rem] w-full text-[1rem] text-[#242424] items-center">
            <span>
              <img src={CropIcon} alt="" />
            </span>
            <span>Record Audio</span>
          </div>
          <div className="bg-[#2C2C2C] h-[0.063rem]"></div>
          <div className="flex space-x-[1.3rem] w-full text-[1rem] text-[#C2262E] items-center">
            <span>
              <img src={DeleteIcon} alt="" />
            </span>
            <button onClick={removeFile}>Remove</button>
          </div>
        </div>
      )}

      <Modal className='flex items-center justify-center' title='Audio' onOpened={listenAudio} onClose={() => setListenAudio(false)}>
        <>
          <audio
            className="rounded-[1.5rem] border-2 border-black/30"
            controls
          >
            <source src={file?.url} type={file?.type} />
          </audio>
        </>

      </Modal>

      <Modal title='Record Audio' onOpened={recordModal} onClose={() => setRecordModal(false)} >
        <div className="flex flex-col space-y-2">
          <audio src={audioUrl} controls className="w-full bg-gray-200 rounded-md"></audio>
          <div className='flex items-center justify-around'>
            <button className="bg-cyan-400 hover:bg-cyan-700 text-white font-bold py-2 px-4 rounded" onClick={startRecording} disabled={recording}>Start</button>
            <div className="text-lg font-bold">{formatTime(timer)}</div>
            <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded" onClick={stopRecording} disabled={!recording}>Stop</button>
          </div>
          <button className="bg-purple-500 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded" onClick={saveAudio} disabled={recording || !audio}>Save</button>
        </div>
      </Modal>
    </>
  )
}

export default AudioDropdown;
