import React, { ReactElement, useEffect, useState } from "react";
import { Close } from "@carbon/icons-react";
import { Button } from "src/components/ui/button1";

type Option = {
  _id: string;
  value: string;
  correct: boolean;
  response: boolean;
  feedback?: string;
};

export type QuestionType = {
  question: string;
  prompt: string;
  options: Option[];
  globalFeedback?: string;
};

export type TextWithImageType = {
  logoImageFile: object | null,
  text: string,
  imageFile: object | null,
}

type Props = {
  data: QuestionType;
  onOpened: boolean;
  onClose: () => void;
  children: ReactElement;
  className?: string;
  handleSubmit: () => void;
  handleOptionQuantity: (optionQuantity: number) => void;
  handleDataChange: (data: QuestionType) => void;
};

export default function AuthoringModal({
  data,
  onOpened,
  onClose,
  children,
  className,
  handleSubmit,
  handleOptionQuantity,
  handleDataChange,
}: Props) {
  const [currentData, setCurrentData] = useState<QuestionType | TextWithImageType>(data);
  const [optionquantity, setOptionquantity] = useState<number>(4);
  const [insertOptionFeedback, setInsertOptionFeedback] = useState(false);
  const [insertGlobalFeedback, setInsertGlobalFeedback] = useState(false);

  useEffect(() => {
    setCurrentData(data);
  }, [data]);

  useEffect(() => {
    handleOptionQuantity(optionquantity);
  }, [optionquantity]);

  // Type guard to check if currentData is of type QuestionType
  function isQuestionType(data: any): data is QuestionType {
    return (data as QuestionType).options !== undefined;
  }

  useEffect(() => {
    if (isQuestionType(currentData)) {
      const updatedOptions = currentData.options.map((option: Option) => {
        if (insertOptionFeedback) {
          return {
            ...option,
            feedback: option.feedback || "Write feedback text here..",
          };
        } else {
          const { feedback, ...rest } = option;
          return rest;
        }
      });
      const updatedData = { ...currentData, options: updatedOptions };
      console.log(updatedData);
      setCurrentData(updatedData);
      handleDataChange(updatedData);
    }
  }, [insertOptionFeedback]);

  useEffect(() => {
    handleOptionQuantity(optionquantity);
  }, [optionquantity]);

  useEffect(() => {
    if (isQuestionType(currentData)) {
      const updatedData = insertGlobalFeedback
        ? {
          ...currentData,
          globalFeedback: currentData.globalFeedback || "Write global feedback here...",
        }
        : {
          ...currentData,
          globalFeedback: undefined,
        };

      console.log(updatedData);
      setCurrentData(updatedData);
      handleDataChange(updatedData);
    }
  }, [insertGlobalFeedback]);

  //   const keydownHandler = ({ key }: { key: string }) => {
  //     switch (key) {
  //       case "Escape":
  //         onClose();
  //         break;
  //       default:
  //     }
  //   };

  //   useEffect(() => {
  //     document.addEventListener("keydown", keydownHandler);
  //     return () => document.removeEventListener("keydown", keydownHandler);
  //   });

  return !onOpened ? null : (
    <div className="absolute top-0 left-0 right-0 z-[99999] h-full w-full  bg-black/20">
      <div className={`h-full w-full bg-white rounded-lg`}>
        <div className="w-full flex">
          <div className="relative h-[calc(100vh-10rem)] w-full flex flex-col">
            {/* <button
              className="absolute top-2 right-2 text-black bg-white rounded-full text-lg z-10"
              onClick={onClose}
            >
              <Close size={32} />
            </button> */}
            <div className="grow h-[calc(100%-4rem)]">{children}</div>
            <div className="h-16 flex items-center pl-[1.63rem] border shadow-xl space-x-4">
              <Button
                className={`bg-blue hover:bg-blue/50 text-white py-2 px-4 rounded`}
                onClick={() => handleSubmit()}
              >
                Save
              </Button>
              <Button
                className={`border border-red-500 text-red-500 bg-white hover:bg-red-500 hover:text-white py-2 px-4 rounded`}
                onClick={onClose}
              >
                Cancel
              </Button>
            </div>
          </div>

          {/* <div className="bg-[#EFEFEF] border-l border-gray-300 w-[15rem] flex-col">
            <span className="text-lg font-medium w-full flex justify-center mt-2 ">Settings</span>
            <div className="w-full py-4 px-2 flex flex-col space-y-3">
              <div className="w-full flex items-center space-x-2">
                <span>Option Feedback</span>
                <button
                  onClick={() => setInsertOptionFeedback(!insertOptionFeedback)}
                  className=""
                >
                  {currentData?.options[0]?.feedback ? "-" : "+"}
                </button>
              </div>
              <div className="w-full flex items-center space-x-2">
                <span>Global Feedback</span> */}
          {/* <button onClick={() => setGlobalfeedback(!globalfeedback)} className="">+</button> */}
          {/* </div>
              <div className="w-full flex items-center space-x-2">
                <span>Option Quantity</span>
                <input
                  type="number"
                  value={optionquantity}
                  onChange={(e) => setOptionquantity(Number(e.target.value))}
                />
              </div>
            </div> */}
          {/* </div> */}
        </div>
      </div>
    </div>
  );
}
