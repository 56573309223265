import React, { useState, useEffect } from "react";
import { ChevronRight } from "@carbon/icons-react";
import { ChevronLeft } from "@carbon/icons-react";
import PreviewModal from "src/utils/PreviewModal";
import PdfViewer from "src/utils/PdfViewer";
// @ts-ignore
import EyeLogo from "../../../assets/preview/Eye.svg";
// @ts-ignore
import ZoomOut from "../../../assets/preview/ArrowsOutSimple.svg";
// @ts-ignore
import ZoomIn from "../../../assets/preview/ArrowsInSimple.svg";
import { useSelector } from "react-redux";

type Props = {
  data: any;
  onNext: (nextTemplateId: string) => void;
  //   handleSelectMenu: (menu: string) => void;
  //   selectedAircraft: any;
};

export function EmergencyEquipScreen({ data, onNext }: Props) {
  const [openModal, setOpenModal] = useState(false);
  const [openImageDescModal, setOpenImageDescModal] = useState(false);
  const [openImageModal, setOpenImageModal] = useState(false);
  const [filteredData, setFilteredData] = useState<any>();
  const [catagoryName, setCatagoryName] = useState<string>("");
  // const [equipmentName, setEquipmentName] = useState<string>("");
  // const [equipmentDescription, setEquipmentDescription] = useState<string>("");
  const [selectedEquipment, setSelectedEquipment] = useState<any>(null);

  const { themeColor, headingFont, bodyFont } = useSelector((state: any) => state.programSettings);

  useEffect(() => {
    console.log(data);
  }, []);

  function handleSelectCatagory(catagory: any) {
    setCatagoryName(catagory.name);

    const filteredDocsByCatagory = data.content.documents?.filter(
      (data: any) => data.catagoryId === catagory.id
    );
    console.log(filteredDocsByCatagory);

    const filteredData = {
      ...data,
      content: { ...data.content, documents: filteredDocsByCatagory },
    };
    console.log(filteredData);

    setFilteredData(filteredData);

    setOpenModal(true);
  }

  function handleSelectEquipment(equipment: any) {
    setOpenImageDescModal(true);
    setSelectedEquipment(equipment);
    // setEquipmentName(equipment.title);
    // setEquipmentDescription(equipment.descriptionText);
  }

  return (
    <div className={`relative w-full xs:h-[calc(100%-3.5rem)] sm:h-[calc(100%-4rem)] md:h-[calc(100%-4.6rem)] lg:h-[calc(100%-5.125rem)] xl:h-[calc(100%-5.625rem)] pt-[1.5rem] md:pt-[1.88rem] pl-[1.7rem] md:pl-[2.5rem] lg:pl-[3.75rem] pr-[1.7rem] md:pr-[2.44rem] ${bodyFont && `font-${bodyFont}`}`}>
      <div className="w-full h-full flex flex-col space-y-[3rem] md:space-y-[3.4rem] lg:space-y-[5rem]">
        <div className={`text-[#FFFFFF] text-[1.7rem] md:text-[2rem] lg:text-[2.5rem] font-semibold  border-l-4 md:border-l-[7px] pl-[1rem] md:pl-[1.5rem] leading-normal ${headingFont && `font-${headingFont}`}`}>
          {data.title}
        </div>
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-[2rem] gap-y-[3rem] lg:gap-y-[5rem] lg:gap-x-[4.81rem] pl-[0rem] lg:pl-[1.5rem]">
          {data.content.catagories.length > 0 &&
            data.content.catagories.map((data: any, idx: number) => (
              <EquipmentDocs
                key={data._id}
                index={idx + 1}
                themeColor={themeColor}
                data={data}
                handleClick={(data: any) => {
                  handleSelectCatagory(data);
                }}
              />
            ))}
        </div>
      </div>

      <div>
        <PreviewModal
          onOpened={openModal}
          onClose={() => setOpenModal(false)}
          showCrossIcon={true}
        >
          <>
            <div
              style={{
                background: `linear-gradient(0deg, rgba(0, 0, 0, 0.70) 0%, rgba(0, 0, 0, 0.70) 100%), url("")`,
                backgroundPosition: "50%",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundColor: "lightgray",
                color: "#146793",
              }}
              className="w-full h-full overflow-y-scroll no-scrollbar pt-[1.5rem] md:pt-[1.88rem] pl-[1.7rem] md:pl-[2.5rem] lg:pl-[3.75rem] pr-[1.7rem] md:pr-[2.5rem] lg:pr-[2.58rem] flex flex-col space-y-[3rem] md:space-y-[5.75rem]"
            >
              <div className="text-[#FFFFFF] text-[1.2rem] md:text-[1.6rem] lg:text-[2rem] font-semibold  border-l-4 md:border-l-[7px] pl-[0.8rem] md:pl-[1.3rem] leading-normal flex xs:flex-col sm:flex-col md:flex-row xs:items-start sm:items-start md:items-center md:space-x-1">
                <span className="">{data.title} - </span>
                <span className=""> {catagoryName}</span>
              </div>

              <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 pl-0 lg:pl-[1.80rem] gap-y-[4rem] lg:gap-y-[4.88rem] gap-x-[2rem] md:gap-x-[3rem] lg:gap-x-[6.44rem]">
                {filteredData &&
                  filteredData.content.documents.map((data: any) => (
                    <button
                      onClick={() => {
                        handleSelectEquipment(data);
                      }}
                      className="flex flex-col w-full max-w-[15.625rem] space-y-[0.5rem]"
                    >
                      <div className="w-full max-w-[15.625rem] h-[15.625rem] border-2 border-solid border-white/50 bg-black/20">
                        <img
                          src={data.smallfile?.url ?? ""}
                          alt={`Profile of ${data.title}`}
                          className="w-full h-full object-contain flex"
                        />
                      </div>
                      <div className="flex w-full max-w-[15.625rem] justify-start flex-shrink-0 text-[#FFFFFF] font-semibold text-[1.25rem] leading-normal">
                        {data.title}
                      </div>
                    </button>
                  ))}
              </div>
              {selectedEquipment && openImageDescModal && (
                <div>
                  <PreviewModal
                    onOpened={openImageDescModal}
                    onClose={() => setOpenImageDescModal(false)}
                    showCrossIcon={true}
                  >
                    <>
                      <div
                        style={{
                          background: `linear-gradient(0deg, rgba(0, 0, 0, 0.70) 0%, rgba(0, 0, 0, 0.70) 100%), url("")`,
                          backgroundPosition: "50%",
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                          backgroundColor: "lightgray",
                          color: "#146793",
                        }}
                        className="w-full h-full overflow-y-scroll no-scrollbar pt-[1.5rem] md:pt-[1.88rem] pl-[1.7rem] md:pl-[2.5rem] lg:pl-[3.75rem] pr-[1.7rem] md:pr-[2.5rem] lg:pr-[2.58rem] flex flex-col space-y-[2rem]"
                      >
                        <div className="text-[#FFFFFF] text-[1.2rem] md:text-[1.6rem] lg:text-[2rem] font-semibold  border-l-4 md:border-l-[7px] pl-[0.8rem] md:pl-[1.3rem] leading-normal flex xs:flex-col sm:flex-col md:flex-row xs:items-start sm:items-start md:items-center md:space-x-1">
                          <span className="">{catagoryName} - </span>
                          <span className=""> {selectedEquipment.title}</span>
                        </div>

                        <div className="flex xs:flex-col sm:flex-col md:flex-row lg:pl-[1rem] md:space-x-[2rem] lg:space-x-[7.29rem]">
                          <div className="relative flex flex-col w-full max-w-[29.89525rem] md:max-w-[29.89525rem] lg:max-w-[35.89525rem] h-[20rem] md:h-[38.0625rem] lg:h-[42.0625rem] 2xl:h-[46.0625rem] rounded-[1.25rem] bg-black/80">
                            <span className="w-full h-full">
                              <img
                                className="w-full h-full object-contain"
                                src={selectedEquipment.mediumfile?.url ?? ""}
                                alt="equipment image"
                              />
                            </span>
                            <button
                              onClick={() => setOpenImageModal(true)}
                              className="absolute right-3 bottom-3 md:right-3 md:bottom-3 w-[3rem] h-[3rem] md:w-[3.5rem] md:h-[3.5rem] p-[0.63rem] flex border rounded-full"
                            >
                              <img src={ZoomIn} alt="largePic" />
                            </button>
                          </div>

                          <div
                            dangerouslySetInnerHTML={{
                              __html: selectedEquipment.descriptionText,
                            }}
                            className="w-full text-[1rem] text-[#FFFFFF] max-w-[20.1875rem] lg:max-w-[35.1875rem] h-[49.0625rem] flex-shrink-0 overflow-y-scroll no-scrollbar"
                          />
                        </div>
                      </div>
                    </>
                  </PreviewModal>
                </div>
              )}
              {selectedEquipment && openImageModal && (
                <div>
                  <PreviewModal
                    onOpened={openImageModal}
                    onClose={() => setOpenImageModal(false)}
                    showCrossIcon={true}
                  >
                    <>
                      <div
                        style={{
                          background: `linear-gradient(0deg, rgba(0, 0, 0, 0.70) 0%, rgba(0, 0, 0, 0.70) 100%), url("")`,
                          backgroundPosition: "50%",
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                          backgroundColor: "lightgray",
                          color: "#146793",
                        }}
                        className="w-full h-full overflow-y-scroll no-scrollbar pt-[1.5rem] md:pt-[1.88rem] pl-[1.7rem] md:pl-[2.5rem] lg:pl-[3.75rem] pr-[1.7rem] md:pr-[2.5rem] lg:pr-[2.58rem] flex flex-col space-y-[2rem]"
                      >
                        <div className="text-[#FFFFFF] text-[1.2rem] md:text-[1.6rem] lg:text-[2rem] font-semibold  border-l-4 md:border-l-[7px] pl-[0.8rem] md:pl-[1.3rem] leading-normal flex xs:flex-col sm:flex-col md:flex-row xs:items-start sm:items-start md:items-center md:space-x-1">
                          <span className="">{catagoryName} - </span>
                          <span className=""> {selectedEquipment.title}</span>
                        </div>

                        <div className="flex lg:pl-[1rem]">
                          <div className="relative md:ml-[1rem] flex flex-col w-full max-w-[81.6875rem] h-[30rem] md:h-[46.0625rem] rounded-[1.25rem] bg-black/80">
                            <span className="w-full h-full">
                              <img
                                className="w-full h-full object-contain"
                                src={selectedEquipment.largefile?.url ?? ""}
                                alt="equipment large image"
                              />
                            </span>
                            <button
                              onClick={() => setOpenImageModal(false)}
                              className="absolute right-3 bottom-3 md:right-3 md:bottom-3 w-[3rem] h-[3rem] md:w-[3.5rem] md:h-[3.5rem] p-[0.63rem] flex border rounded-full"
                            >
                              <img src={ZoomOut} alt="largePic" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </>
                  </PreviewModal>
                </div>
              )}
            </div>
          </>
        </PreviewModal>
      </div>
    </div>
  );
}

const EquipmentDocs: React.FC<{
  data: any;
  index: number;
  themeColor: any;
  handleClick?: (data: any) => void;
}> = ({ data, themeColor, handleClick, index }) => {
  return (
    <button
      onClick={() => {
        if (handleClick) handleClick(data);
      }}
      className="flex flex-col max-w-[16.875rem] h-[11.25rem]"
    >
      <div className="w-full max-w-[16.875rem] h-[9.375rem]">
        <img
          src={data.file?.url ?? ""}
          alt={`Profile of ${data.name}`}
          className="w-full h-full object-cover flex"
        />
      </div>
      <div style={{backgroundColor: themeColor, opacity: 0.8}} className={`flex w-full max-w-[16.875rem] px-[0.3125rem] justify-start flex-shrink-0 text-[#FFFFFF] font-semibold text-[1.25rem] leading-normal bg-[#2F0220]/80 ${!themeColor && "bg-[#2F0220]/80"}`}>
        {data.name}
      </div>
      {/* <span className="absolute top-0 right-0 text-[rgba(255,255,255,0.3)] text-4xl font-extrabold">{index}</span> */}
    </button>
  );
};
