import React, { Component } from "react";
import ReactDOM from "react-dom";
import { BlockToolConstructorOptions } from "@editorjs/editorjs";
import ClickToReveal from "./ClickToReveal";

type clickToRevealType = {
  id: number;
  content: string;
  name: string;
};

type ClickToRevealToolProps = {
  data: {
    clickToReveal: clickToRevealType[];
  };
  onChange: (clickToReveal: clickToRevealType[]) => void;
};

class ClickToRevealToolComponent extends Component<ClickToRevealToolProps> {
    render() {
      return (
        <ClickToReveal
          clickToReveal={this.props.data.clickToReveal}
          onChange={this.props.onChange}
        />
      );
    }
  }

class ClickToRevealTool {
  private data: { clickToReveal: clickToRevealType[]};
  private api: any;
  private wrapper: HTMLElement | null;

  constructor({ data, api }: BlockToolConstructorOptions) {
    this.data = data || { clickToReveal: [] };
    this.api = api;
    this.wrapper = null;
  }

  render() {
    if (!this.wrapper) {
      this.wrapper = document.createElement("div");
      this.wrapper.classList.add("click-to-reveal-tool-wrapper");
    } else {
      this.wrapper.innerHTML = ''; // Clear the wrapper's content before re-rendering
    }
  
    this.renderComponent();
  
    return this.wrapper;
  }

  renderComponent() {
    ReactDOM.render(
      <ClickToRevealToolComponent
        data={this.data}
        onChange={this.handleClickToRevealChange.bind(this)}
      />,
      this.wrapper
    );
  }

  handleClickToRevealChange(clickToReveal: clickToRevealType[]) {
    this.data = { ...this.data , clickToReveal };
    console.log(this.data);
    this.renderComponent(); // Re-render the component with the updated data
    // this.api.blocks.save(); // Save the block data when click to reveals change
  }

  save() {
    return this.data;
  }

  static get toolbox() {
    return {
      title: "Click to Reveal",
      icon: "<svg>...</svg>", // Add your custom icon SVG here
    };
  }
}

export default ClickToRevealTool;
