import React, { useState } from "react";
import Modal from "src/utils/Modal";
import Text from "src/utils/Text/Txt";
import InputText from "src/utils/TextInput/InputText";
import UploadButton from "src/utils/UploadButton";

type Props = {
  editData: any;
  onSetEditData: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onHandleEdit: () => void;
  onHandleDelete: (id: any) => void;
};

function EditLanguages({ editData, onSetEditData, onHandleEdit, onHandleDelete }: Props) {
    
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [passUnitIdToDelete, setPassUnitIdToDelete] = useState<string | null>(
    null
  );

  function handleDeletePopup(id: any) {
    setOpenDeletePopup(true);
    setPassUnitIdToDelete(id);
    console.log("delete id", passUnitIdToDelete);
  }

  function handleDelete(id: any) {

    onHandleDelete(id);
    
    setOpenDeletePopup(false);
  }

  return (
    <>
      <div className="flex flex-col h-full  px-[0.45rem]">
        <div className="grow space-y-4 py-[1.25rem]">
          <InputText
            value={editData?.name}
            onChange={(e) => onSetEditData(e)}
            labelTitle="Language"
            autoFocus={true}
          />
        </div>
        <div className=" border-t border-[#2C2C2C] px-[0.49rem] py-5 flex justify-between items-center">
          <button
            onClick={() => handleDeletePopup(editData.id)}
            className="px-6 py-3 text-[1rem] text-[#FFFFFF] bg-[#929292] rounded-[1.875rem]"
          >
            Delete
          </button>
          <button
            onClick={() => {
              onHandleEdit();
            }}
            className="px-7 py-3 text-[1rem] text-[#FFFFFF] bg-[#AB0273] rounded-[1.875rem]"
          >
            Done
          </button>
        </div>
      </div>

      <Modal
        onOpened={openDeletePopup}
        onClose={() => setOpenDeletePopup(false)}
        title="Confirm Delete"
        height="calc(100%-400px)"
        passUnitIDtoDelete={passUnitIdToDelete || ""}
      >
        <div className="flex flex-col h-full items-center pt-4 px-4 space-y-4">
          <div className="flex items-center justify-center w-full py-5">
              <span>Are you sure you want to delete this Language?</span>
          </div>

          <div className=" border-t-[0.0625rem] border-[#2C2C2C] py-5  w-full flex justify-center space-x-[4rem] items-center">
              <button
                onClick={() => setOpenDeletePopup(false)}
                className="text-[#FFFFFF] px-5 py-2 text-[16px] rounded-[1.875rem] bg-[#929292]"
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  handleDelete(passUnitIdToDelete);
                }}
                className="text-[#FFFFFF] px-5 py-2 text-[16px] rounded-[1.875rem] bg-red-600"
              >
                Delete
              </button>
            </div>
        </div>
      </Modal>
    </>
  );
}

export default EditLanguages;
