import React from 'react'
import Layout from './common/Layout';
import TextWithImageLayout from './layouts/textwithimage';
import ClickToRevealLayout from './layouts/ClickToReveal';

type Props = {
    type?: string;
    handleDataChange: (data: any) => void;
    data: any;
    openTemplate: any;
    handleOpenAuthoringModal?: (state: boolean) => void;
    handleCancelTemplateForm?: () => void;
    nestedTemplate?: boolean;
}

function ClickToReveal({ type, handleDataChange, data, openTemplate, handleOpenAuthoringModal, handleCancelTemplateForm, nestedTemplate }: Props) {

    const templateType = 'clicktoreveal';

    return (
        <>
            {!type ? (
                <>
                    {
                        !nestedTemplate ? (
                            // <Layout
                            //     type={templateType}
                            //     data={data}
                            //     handleDataChange={(data) => handleDataChange(data)}
                            //     openTemplate={openTemplate}
                            //     handleOpenAuthoringModal={(state) => { handleOpenAuthoringModal && handleOpenAuthoringModal(state) }}
                            //     handleCancelTemplateForm={() => { handleCancelTemplateForm && handleCancelTemplateForm() }}
                            // >
                                <>
                                    <ClickToRevealLayout data={data} handleDataChange={(data) => handleDataChange(data)} />
                                </>

                            // </Layout>
                        ) : (
                            <>
                                <ClickToRevealLayout data={data} handleDataChange={(data) => handleDataChange(data)} />

                            </>
                        )
                    }
                </>
            ) : (
                <>
                    <ClickToRevealLayout data={data} handleDataChange={(data) => handleDataChange(data)} preview={true} />
                </>
            )
            }
        </>
    )
}

export default ClickToReveal