import React, { memo, useEffect, useState } from "react";
// import NavProfile from "../NavProfile";
import Logo from "../../assets/logo.png";
import NotificationIcon from "../../assets/notification.svg";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import { ChevronLeft } from "@carbon/icons-react";
//@ts-ignore
import MicrosoftLogo from "../../src/assets/microsoft.svg";
//@ts-ignore
import CheckLogo from "../../src/assets/check.png";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import Modal from "src/utils/Modal";
// import Layout from "../MediaLibrary/Layout";
// import Toolbar from "../MediaLibrary/Toolbar";
// import Sidebar from "../MediaLibrary/Sidebar";
// import Main from "../MediaLibrary/Main";
import PreviewModal from "src/utils/PreviewModal";
import { cva } from "class-variance-authority";
import { Device } from "src/components/Preview/Layout";
import TemplateRibbon from "src/components/Course/TemplateRibbon";
// import Navbar from "../Preview/Navbar";
// import { cn } from "../lib/utils";
// import Preview from "../Preview";

type ButtonType = {
  name: string;
  buttonIcon: React.ReactNode;
  navigate: boolean;
  navigateUrl?: string;
};

type NavProps = {
  type: string;
  buttonsArray?: ButtonType[];
  handleAudioModal?: (open: boolean) => void;
  handleVideoModal?: (open: boolean) => void;
  handlePreviewModal?: (open: boolean) => void;
  handleSettingsModal?: (open: boolean) => void;
  handleProgram?: (program: any) => void;
  handleCourse?: (course: any) => void;
  handlePage?: (page: any) => void;
  handleOpenTemplate?: () => void;
};

const deviceVariants = cva("grow flex flex-col", {
  variants: {
    device: {
      default: "max-h-[64rem] h-full max-w-[90rem] w-full",
      laptop: "max-h-[64rem] h-full max-w-[90rem] w-full",
      tabPortrait:
        "max-h-[62rem] h-full max-w-[50rem] w-screen border-[12px] border-gray-300 rounded-lg",
      tabLandscape:
        "max-h-[50rem] h-full max-w-[62rem] w-screen border-[12px] border-gray-300 rounded-lg",
      mobilePortrait:
        "max-h-[50rem] h-full max-w-[35rem] w-screen border-[12px] border-gray-300 rounded-lg",
      mobileLandscape:
        "max-h-[35rem] h-full max-w-[50rem] w-screen border-[12px] border-gray-300 rounded-lg",
    },
  },
  defaultVariants: {
    device: "default",
  },
});

const ProgramNav = memo(
  ({
    type,
    buttonsArray,
    handleAudioModal,
    handleVideoModal,
    handlePreviewModal,
    handleSettingsModal,
    handleProgram,
    handleCourse,
    handlePage,
    handleOpenTemplate,
  }: NavProps) => {
    const { instance } = useMsal();
    const [activeList, setActiveList] = useState("");
    const [program, setProgram] = useState<any>();
    const [course, setCourse] = useState<any>();
    const [page, setPage] = useState<any>();
    const [businessUnit, setBusinessUnit] = useState<any>();
    const [openMediaLibrary, setOpenMediaLibrary] = useState(false);

    const [insertDropDown, setInsertDropdown] = useState(false);
    const [isButtonRelative, setIsButtonRelative] = useState(false);

    const [openPreviewModal, setOpenPreviewModal] = useState(false);
    const [openPublishDropdown, setOpenPublishDropdown] = useState(false);
    const [openSettingDropdown, setOpenSettingDropdown] = useState(false);
    const [deviceVariantState, setDeviceVariantState] =
      useState<Device>("default");

    const [items, setItems] = useState<any>([]);

    const { businessUnitId, sepmId, pageId, courseId, templateId, authorId, userId } =
      useParams();
    const { accessToken } = useSelector((state: any) => state.accessToken);

    const { saveChanges } = useSelector((state: any) => state.saveChanges);

    const [searchParams, setSearchParams] = useSearchParams();

    const navigate = useNavigate();

    useEffect(() => {
      if (type === "Sepm") {
        getProgram();
      } else if (type === "Course") {
        getCourse();
      } else getPage();
      console.log(buttonsArray);
    }, []);

    async function getProgram() {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_BASE_URL}/api/v1/program/detail/${sepmId}`,
          {
            headers: { Authorization: `Bearer ${accessToken}` },
          }
        );

        if (response && response.data) {
          console.log(response.data);
          setProgram(response.data.data.program);
          setBusinessUnit(response.data.data.businessUnit);
          if (handleProgram) handleProgram(response.data.data.program);
        }
      } catch (error) {
        console.log(error);
      }
    }

    async function getCourse() {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_BASE_URL}/api/v1/course1/get/${courseId}`,
          {
            headers: { Authorization: `Bearer ${accessToken}` },
          }
        );

        if (response && response.data) {
          console.log(response.data);
          setCourse(response.data.data.course);
          setBusinessUnit(response.data.data.businessUnit);
          if (handleCourse) handleCourse(response.data.data.course);
        }
      } catch (error) {
        console.log(error);
      }
    }

    async function getPage() {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_BASE_URL}/api/v1/page/get/${pageId}`,
          {
            headers: { Authorization: `Bearer ${accessToken}` },
          }
        );

        if (response && response.data) {
          console.log(response.data);
          setPage(response.data.data.page);
          setBusinessUnit(response.data.data.businessUnit);
          if (handlePage) handlePage(response.data.data.page);
        }
      } catch (error) {
        console.log(error);
      }
    }

    const handleSignIn = () => {
      instance.loginRedirect({
        scopes: ["User.Read"],
      });
    };


    async function CreatePackage(model: string) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_SERVER_BASE_URL}/api/v1/publish/${courseId}/${model}`,
          {
            sepmId: sepmId,
            courseId: courseId,
            pageId: pageId,
            templateId: templateId,
            authorId: authorId,
            userId: userId,
          },
          {
            headers: { Authorization: `Bearer ${accessToken}` },
            responseType: "blob",
          }
        );

        if (response && response.data) {
          // Create a URL for the blob
          console.log(response)
          const blob = new Blob([response.data]);
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${course.title}_build.zip`); // Set the file name
          document.body.appendChild(link);
          link.click();
          link.remove(); // Clean up the link element

          console.log(`Blob size: ${blob.size} bytes`)
        }
      } catch (error) {
        console.log(error);
      }
    }

    async function downloadPackage() {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_BASE_URL}/api/v1/publish/${courseId}`,
          {
            headers: { Authorization: `Bearer ${accessToken}` },
            responseType: "blob",
          }
        );

        if (response && response.data) {
          // Create a URL for the blob
          const blob = new Blob([response.data]);
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'build.zip'); // Set the file name
          document.body.appendChild(link);
          link.click();
          link.remove(); // Clean up the link element

          console.log(`Blob size: ${blob.size} bytes`)
        }
      } catch (error) {
        console.log(error);
      }
    }

    const menuRef = React.createRef<HTMLDivElement>();

    useEffect(() => {
      const handleClickOutside = (event: MouseEvent) => {
        if (!menuRef?.current?.contains(event.target as Node)) {
          setOpenPublishDropdown(false);
          setOpenSettingDropdown(false);
          setInsertDropdown(false);
        }
      };

      document.addEventListener("mousedown", handleClickOutside);

      return () => {
        // Clean up the event listener when the component unmounts
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [menuRef]);

    return (
      <div className="flex justify-between px-[1.44rem]  items-center border-b bg-[#E1E1E1] h-[4.375rem]">
        <div className="flex space-x-4">
          <div className="flex items-center justify-between space-x-2">
            <button
              onClick={() => navigate(`/dashboard`)}
              className="flex items-center space-x-2"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
              >
                <path
                  d="M20 26L10 16L20 6"
                  stroke="black"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
            {type === "Sepm" && (
              <span className="font-[600] leading-normal text-[1.5rem] text-[#242424] uppercase">
                {businessUnit?.title}/{program?.title}
              </span>
            )}
            {type === "Page" && (
              <span className="font-[600] leading-normal text-[1.5rem] text-[#242424] uppercase">
                {businessUnit?.title}/{page?.title}
              </span>
            )}
            {type === "Course" && (
              <span className="font-[600] leading-normal text-[1.5rem] text-[#242424] uppercase">
                {businessUnit && (businessUnit.title + " - ")}{course?.title}
              </span>
            )}
          </div>
        </div>
        {type === "Sepm" && (
          <div className="text-xs flex items-end border-l-4 border-green-500 p-3 bg-[#ededed] rounded-md">
            {saveChanges ? (
              <span>Saving...</span>
            ) : (
              <span className="flex space-x-3">
                <span className="w-4 h-4">
                  <img src={CheckLogo} alt="logo" />
                </span>
                <span>Changes saved!</span>
              </span>
            )}
          </div>
        )}
        <AuthenticatedTemplate>
          <div ref={menuRef} className={`flex items-center space-x-[2rem]`}>
            {buttonsArray?.map((button, idx: number) => (
              <div className={`${(button.name === "Insert" || button.name === "Publish" || button.name === "Settings") && "relative"}`}>
                <div>
                  <button
                    key={idx}
                    onClick={() => {
                      if (button.navigate && button.navigateUrl) {
                        navigate(button.navigateUrl)
                      }
                      else if (button.name === "Audio") {
                        if (handleAudioModal) handleAudioModal(true);
                      }
                      else if (button.name === "Video") {
                        if (handleVideoModal) handleVideoModal(true);
                      }
                      else if (button.name === "Preview") {
                        if (handlePreviewModal) handlePreviewModal(true);
                      }
                      else if (button.name === "Settings") {
                        type === "Sepm" ? handleSettingsModal && handleSettingsModal(true) : setOpenSettingDropdown(!openSettingDropdown);
                      }
                      else if (button.name === "Insert") {
                        setInsertDropdown(!insertDropDown)
                      }
                      else if (button.name === "Publish") {
                        setOpenPublishDropdown(!openPublishDropdown);
                      }
                    }}
                    className={`${(button.name === "Insert" || button.name === "Publish" || button.name === "Settings") && "relative"} hover:bg-[#E0CBD9] px-2 py-1 rounded-lg flex space-x-[0.63rem] items-center`}
                  >
                    {button.buttonIcon}
                    <span className={` text-[1rem] text-[#AB0273] font-[600] leading-normal`}>
                      {button.name}
                    </span>
                  </button>
                </div>

                {insertDropDown && button.name === "Insert" && (
                  <TemplateRibbon insertDropdown={insertDropDown} setInsertDropdown={setInsertDropdown} handleOpenTemplate={handleOpenTemplate} />
                )}
                {openPublishDropdown && button.name === "Publish" && (
                  <div className="absolute top-[3rem] right-[-3rem] w-[14rem] flex flex-col p-3 bg-gray-100 border border-gray-300 space-y-2 shadow-lg rounded-lg z-20">
                    <button onClick={() => CreatePackage("scorm2004")} className="flex justify-start items-center text-start rounded-md p-1 hover:bg-[#E0CBD9] hover:rounded[1.25rem]">Scorm 2004 2nd Edition</button>
                    <button onClick={() => CreatePackage("scorm12")} className="flex justify-start items-center rounded-md p-1 hover:bg-[#E0CBD9] hover:rounded[1.25rem]">Scorm 1.2</button>
                    <button onClick={() => CreatePackage("xapi")} className="flex justify-start items-center rounded-md p-1 hover:bg-[#E0CBD9] hover:rounded[1.25rem]">xApi</button>
                    <button disabled className="disabled:bg-transparent disabled:text-gray-400 flex justify-start items-center rounded-md p-1 hover:bg-[#E0CBD9] hover:rounded[1.25rem]">AICC</button>
                    <button disabled className="disabled:bg-transparent disabled:text-gray-400 flex justify-start items-center rounded-md p-1 hover:bg-[#E0CBD9] hover:rounded[1.25rem]">Cmi5</button>
                  </div>
                )}
                {openSettingDropdown && button.name === "Settings" && (
                  <div className="absolute top-[3rem] right-0 w-[11rem] flex flex-col p-3 bg-gray-100 border border-gray-300 space-y-2 shadow-lg rounded-lg z-20">
                    <button onClick={() => navigate(`/user/${userId}/course/${courseId}/menuSettings`)} className="flex justify-start items-center rounded-md p-1 hover:bg-[#E0CBD9] hover:rounded[1.25rem]">Menu Settings</button>
                    <button onClick={() => navigate(`/user/${userId}/course/${courseId}/settings`)} className="flex justify-start items-center rounded-md p-1 hover:bg-[#E0CBD9] hover:rounded[1.25rem]">Player Settings</button>
                    <button onClick={() => navigate(`/user/${userId}/course/${courseId}/lmsSettings`)} className="flex justify-start items-center rounded-md p-1 hover:bg-[#E0CBD9] hover:rounded[1.25rem]">LMS Settings</button>
                    <button onClick={() => navigate(`/user/${userId}/course/${courseId}/quizSettings`)} className="flex justify-start items-center rounded-md p-1 hover:bg-[#E0CBD9] hover:rounded[1.25rem]">Quiz Settings</button>
                    <button disabled className="disabled:bg-transparent disabled:text-gray-400 flex justify-start items-center rounded-md p-1 hover:bg-[#E0CBD9] hover:rounded[1.25rem]">Course Settings</button>
                  </div>
                )}
              </div>
            ))}
            {/* <button
              onClick={() =>
                navigate(
                  `/user/${userId}/businessunit/${businessUnitId}/sepm/${sepmId}/template/${templateId}/media?screen=${searchParams.get(
                    "screen"
                  )}&folderId=null`
                )
              }
              className="hover:bg-[#E0CBD9] px-2 py-1 rounded-lg flex space-x-[0.63rem] items-center"
            >
              <img src={MediaLogo} alt="logo" />
              <span className="text-[1rem] text-[#AB0273] font-[600] leading-normal">
                Media Library
              </span>
            </button>
            <button
              onClick={() =>
                navigate(
                  `/user/${userId}/businessunit/${businessUnitId}/sepm/${sepmId}/share`
                )
              }
              className="hover:bg-[#E0CBD9] px-2 py-1 rounded-lg flex space-x-[0.63rem] items-center"
            >
              <img src={ShareLogo} alt="logo" />
              <span className="text-[1rem] text-[#AB0273] font-[600] leading-normal">
                Share
              </span>
            </button>
            <button
              onClick={() => {
                if (handlePreviewModal) handlePreviewModal(true);
              }}
              className="hover:bg-[#E0CBD9] px-2 py-1 rounded-lg flex space-x-[0.63rem] items-center "
            >
              <img src={PreviewLogo} alt="logo" />
              <span className="text-[1rem] text-[#AB0273] font-[600] leading-normal">
                Preview
              </span>
            </button>
            <button
              onClick={() =>
                navigate(
                  `/user/${userId}/businessunit/${businessUnitId}/sepm/${sepmId}/publish`
                )
              }
              className="hover:bg-[#E0CBD9] px-2 py-1 rounded-lg flex space-x-[0.63rem] items-center"
            >
              <img src={ExportLogo} alt="logo" />
              <span className="text-[1rem] text-[#AB0273] font-[600] leading-normal">
                Publish
              </span>
            </button>
            <button
              onClick={() => {
                if (handleSettingsModal) handleSettingsModal(true);
              }}
              className="hover:bg-[#E0CBD9] px-2 py-1 rounded-lg flex space-x-[0.63rem] items-center "
            >
              <img src={SettingLogo} alt="logo" />
              <span className="text-[1rem] text-[#AB0273] font-[600] leading-normal">
                Settings
              </span>
            </button> */}
          </div>
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <button
            className="flex items-center px-2 py-1 space-x-2 bg-white text-black font-semibold rounded-sm"
            onClick={() => handleSignIn()}
          >
            <span className="h-8 w-8">
              <img src={MicrosoftLogo} alt="logo" />
            </span>
            <span>Sign in</span>
          </button>
        </UnauthenticatedTemplate>
      </div>
    );
  }
);

export default ProgramNav;
