import React, { useState, useEffect, useRef } from "react";
import PreviewModal from "src/utils/PreviewModal";
// @ts-ignore
import PlayVideo from "../../../assets/preview/PlayCircle.svg";
import Video from "src/components/MediaLibrary/FileType/Video";
// @ts-ignore
import VideoBG from "../../../assets/videoBG.png";
import { useSelector } from "react-redux";
import { Box } from "@chakra-ui/react";
import { ChevronLeft } from "lucide-react";
import Loader from "src/utils/Loader";
import { useDispatch } from "react-redux";
import { Module, setCurrentPath, setModules } from "src/features/trackedModules/trackedModulesSlice";
import { useTemplateTracking } from "src/hooks/useTemplateTracking";
import { useParams } from "react-router-dom";

type Props = {
  data: any;
  onNext: (nextTemplateId: string) => void;
  //   handleSelectMenu: (menu: string) => void;
  //   selectedAircraft: any;
};

export function VideoScreen({ data, onNext }: Props) {
  const [openModal, setOpenModal] = useState(false);
  const [videoDoc, setVideoDoc] = useState<any>();
  const [filteredDoc, setFilteredDoc] = useState<any>();

  const { themeColor, headingFont, bodyFont, headingColor, thumbnailHeadingColor, thumbnailTitleColor } = useSelector(
    (state: any) => state.programSettings
  );

  const { modules, currentPath } = useSelector((state: any) => state.trackedModules);

  const dispatch = useDispatch();

  const { accessToken } = useSelector((state: any) => state.accessToken);
  const { businessUnitId, sepmId } = useParams();

  useTemplateTracking({ moduleId: data._id, businessUnitId, sepmId, accessToken });

  useEffect(() => {
    console.log(data);
  }, []);

  useEffect(() => {
    handleClickLanguage(data.content.languages[0], videoDoc);
    console.log(data.content.languages[0], videoDoc);
  }, [videoDoc]);

  useEffect(() => {
    console.log(filteredDoc);
  }, [filteredDoc]);

  function handleClickPA(data: any) {
    console.log(data);
    setVideoDoc(data);
    setOpenModal(true);
  }

  function handleClickLanguage(lang: any, videoDocument: any) {
    if (videoDocument) {
      console.log(videoDocument);
      const filteredLangDoc = videoDocument.languageDoc.filter(
        (data: any) => data.id === lang.id
      );

      console.log(filteredLangDoc[0]);
      setFilteredDoc(filteredLangDoc[0]);
    }
  }

  // Function to update session storage
  const updateSessionStorage = (updatedModules: Module[]) => {
    sessionStorage.setItem("modules", JSON.stringify(updatedModules));
  };

  const getModuleByPath = (modulesList: Module[], path: string[]): Module | null => {
    let currentModules = modulesList;
    let targetModule: Module | null = null;

    for (let id of path) {
      targetModule = currentModules.find(module => module.id === id) || null;

      if (!targetModule) return null; // Return null if any module in the path is missing

      currentModules = targetModule.children || []; // Move to the next level
    }

    return targetModule; // Return the final module
  };

  // Function to add children dynamically when clicking a module
  const handleModuleClick = (moduleId: string, childrenArray: Module[]) => {
    console.log("Clicked on module", moduleId, childrenArray);

    const findAndUpdateModule = (modulesList: Module[]): Module[] => {
      return modulesList.map((module: Module) => {
        if (module.id === moduleId) {
          dispatch(setCurrentPath([...currentPath, module.id]))
          // Update the module if found
          return {
            ...module,
            // children: module.children ? [...module.children, ...childrenArray] : childrenArray,
            children: childrenArray,
          };
        } else if (module.children && module.children.length > 0) {
          // Recursively search in children
          return {
            ...module,
            children: findAndUpdateModule(module.children),
          };
        }
        return module;
      });
    };

    const updatedModules = findAndUpdateModule(modules);

    dispatch(setModules(updatedModules));
    updateSessionStorage(updatedModules);
  };

  return (
    <div
      className={`relative w-full h-[calc(100%-2.625rem)] md:h-[calc(100%-3.625rem)] lg:h-[calc(100%-4.625rem)] xl:h-[calc(100%-5.25rem)] pt-[1.5rem] md:pt-[1.88rem] pl-[1.7rem] md:pl-[2.5rem] lg:pl-[3.75rem] pr-[1.7rem] md:pr-[2.44rem] ${bodyFont && `font-${bodyFont}`
        }`}
    >
      <div className="w-full h-full flex flex-col space-y-[3rem] md:space-y-[3.4rem] lg:space-y-[5rem]">
        <div
          className={`text-[1.5rem] md:text-[1.7rem] lg:text-[2rem] font-semibold ${headingFont && `font-${headingFont}`
            }`}
        >
          <div
            style={{ color: headingColor, borderColor: headingColor }}
            className={`border-l-4 md:border-l-[7px] pl-[1rem] md:pl-[1.5rem] leading-normal ${!headingColor && "text-[#FFFFFF] border-[#FFFFFF]"}`}>
            {data.title}
          </div>
        </div>

        <div className="grow min-h-0 overflow-y-auto no-scrollbar">
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-[2rem] gap-y-[3rem] lg:gap-y-[5rem] lg:gap-x-[4.81rem] pl-[0rem] lg:pl-[1.5rem] overflow-y-scroll no-scrollbar">
            {data.content.documents.map((data: any, idx: number) => {
              const currentModules = getModuleByPath(modules, currentPath)?.children;
              const moduleStatus = currentModules && currentModules.find((module: Module) => module.id === data._id)?.status;
              return (
                <VideoDocs
                  key={data._id}
                  status={moduleStatus}
                  data={data}
                  index={idx + 1}
                  themeColor={themeColor}
                  thumbnailHeadingColor={thumbnailHeadingColor}
                  thumbnailTitleColor={thumbnailTitleColor}
                  handleClick={(data: any) => {
                    handleClickPA(data);
                  }}
                />
              )
            })}
          </div>
        </div>
      </div>

      <div>
        <PreviewModal
          onOpened={openModal}
          onClose={() => setOpenModal(false)}
          showCrossIcon={false}
        >
          <>
            <div
              style={{
                background: `linear-gradient(0deg, rgba(0, 0, 0, 0.70) 0%, rgba(0, 0, 0, 0.70) 100%), url(${data.content.bgfile?.url ?? ""
                  })`,
                backgroundPosition: "50%",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundColor: "lightgray",
                color: "#146793",
              }}
              className="w-full h-full flex flex-col"
            >
              {/* <div className="md:px-[1rem]">
                <div className="text-[#FFFFFF] text-[1.2rem] md:text-[1.6rem] lg:text-[2rem] font-semibold  border-l-4 md:border-l-[7px] pl-[0.8rem] md:pl-[1.3rem] leading-normal flex xs:flex-col sm:flex-col md:flex-row xs:items-start sm:items-start md:items-center md:space-x-1">
                  <span className="">{data.title} - </span>
                  <span className=""> {videoDoc?.title}</span>
                </div>
              </div> */}

              <Box className="bg-white w-full h-[2.75rem] md:h-[3rem] xl:h-[3.5rem] px-4 py-[2px] md:py-1 lg:py-1.5 ">
                <div className="h-full flex space-x-4 justify-start items-center">
                  <button
                    onClick={() => {
                      // navigate(`/user/${userId}/businessunit/${businessUnitId}/sepm/${sepmId}`);
                      setOpenModal(false);
                    }}
                    className="text-black"
                  >
                    <ChevronLeft size={32} />
                  </button>
                  <div className="text-black text-lg md:text-xl lg:text-2xl font-semibold ">
                    {data.title} - {videoDoc?.title}
                  </div>
                </div>
              </Box>
              <div className="grow flex flex-col w-full justify-center items-center overflow-hidden p-4">
                <Video themeColor={themeColor} file={filteredDoc?.file} />
              </div>
            </div>
          </>
        </PreviewModal>
      </div>
    </div>
  );
}

const VideoDocs: React.FC<{
  data: any;
  status?: string;
  index: number;
  themeColor: any;
  thumbnailHeadingColor: any,
  thumbnailTitleColor: any,
  handleClick: (data: any) => void;
}> = ({ data, index, status, themeColor, thumbnailHeadingColor, thumbnailTitleColor, handleClick }) => {
  const [isLoading, setIsLoading] = useState(true);
  const { modules } = useSelector((state: any) => state.trackedModules);
  const dispatch = useDispatch();

  useEffect(() => {
    const img = new Image();
    img.src = VideoBG ?? "";
    img.onload = () => setIsLoading(false);
  }, [VideoBG]);

  // Function to update session storage
  const updateSessionStorage = (updatedModules: Module[]) => {
    sessionStorage.setItem("modules", JSON.stringify(updatedModules));
  };

  // Function to handle document opening
  const handleOpenDocument = (docId: string) => {
    // Find moduleId associated with the document
    const module = findModuleByDocumentId(modules, docId);

    if (module) {
      handleLastDepthClick(module.id);
    }
  };

  // Function to find module by document ID (assuming each module has a documentId field)
  const findModuleByDocumentId = (modules: Module[], docId: string): Module | null => {
    for (let module of modules) {
      if (module.id === docId) return module;
      if (module.children) {
        const found = findModuleByDocumentId(module.children, docId);
        if (found) return found;
      }
    }
    return null;
  };

  // Function to mark last depth items as completed and update parents
  const handleLastDepthClick = (moduleId: string) => {
    const updateModulesRecursive = (modules: Module[]): Module[] => {
      return modules.map((module) => {
        if (module.id === moduleId) {
          return { ...module, status: "green" };
        }
        if (module.children) {
          const updatedChildren = updateModulesRecursive(module.children);
          const allGreen = updatedChildren.every((child) => child.status === "green");
          const anyGreen = updatedChildren.some((child) => child.status === "green" || child.status === "orange");

          return {
            ...module,
            children: updatedChildren,
            status: allGreen ? "green" : anyGreen ? "orange" : "gray",
          };
        }
        return module;
      });
    };

    const updatedModules = updateModulesRecursive(modules);

    dispatch(setModules(updatedModules));
    updateSessionStorage(updatedModules);
  };

  return (
    // {

    <div
      style={{ borderColor: `${themeColor}CC` }}
      className={`flex flex-col relative border-2 ${!themeColor && "border-[#2F0220]/80"}`}
    >
      <div
        style={{
          color: thumbnailHeadingColor,
          backgroundColor: "lightgray", // Always keep a default background
          backgroundImage: isLoading
            ? "none" // No image when loading
            : `linear-gradient(0deg, rgba(0, 0, 0, 0.70) 0%, rgba(0, 0, 0, 0.70) 100%), url(${VideoBG})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          transition: "background-image 0.3s ease-in-out", // Smooth transitionyy
        }}
        className={`w-full flex flex-col max-w-[16.875rem] h-[9.375rem]  bg-[#000000]/80 ${!thumbnailHeadingColor && "text-[#FFFFFF]/[80]"}`}
      >
        {/* <div className="w-[16.875rem] text-[2rem] pr-1 pt-1 text-[#FFFFFF]/30 font-extrabold leading-[1.5rem] flex-shrink-0 flex justify-end items-start">
          {count + 1}
        </div> */}
        {isLoading ? (
          <div className="w-full h-full flex items-center justify-center">
            <Loader loading={isLoading} />
          </div>
        ) : (
          <div className="grow w-full max-w-[16.875rem] h-[9.375rem] text-[1.25rem] flex  items-center justify-center font-[500] leading-[1.5rem] tracking-[0.00188rem]">
            {data.title}
          </div>
        )}
        <button
          onClick={() => {
            handleOpenDocument(data._id);
            if (handleClick) handleClick(data);
          }}
          className="flex justify-center w-full"
        >
          <img src={PlayVideo} alt="playvideo" />
        </button>
      </div>
      <div
        style={{ backgroundColor: `${themeColor}CC`, color: thumbnailTitleColor }}
        className={`flex w-full max-w-[16.875rem] h-[1.875rem] justify-center items-center flex-shrink-0 ${!thumbnailTitleColor && "text-[#FFFFFF]"} font-semibold text-[1.25rem] leading-[1.25rem] tracking-[0.00188rem] ${!themeColor && "bg-[#2F0220]/80"
          }`}
      >
        {data.date}
      </div>
      <span className="absolute top-0 right-[5px] text-[rgba(255,255,255,0.3)] text-4xl font-extrabold">
        {index}
      </span>
      <span className={`absolute top-2 left-2 h-4 w-4 rounded-full ${status && status === "gray" ? "bg-gray-500" : status === "orange" ? "bg-orange-500" : "bg-green-500"}`}></span>
    </div>
    // }
  );
};
