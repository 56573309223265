import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";

type Props = {
  image: any;
  x?: number;
  y?: number;
  locatePoints?: number;
  onChangeInputs?: ({ id, x, y }: { id: number; x: number; y: number }) => void;
  locationPoints?: number[][];
};

export function Test({ image, x, y, locatePoints, onChangeInputs, locationPoints }: Props) {
  //   const svgRef = useRef(null);
  const [File, setFile] = useState<string>(image?.url || "");

  useEffect(() => {
    let canvas = document.getElementById("canvas") as HTMLCanvasElement;
    canvas.width = 400;
    canvas.height = 600;
    let res = canvas.getContext("2d");
    if (!res || !(res instanceof CanvasRenderingContext2D)) {
      throw new Error("Failed to get 2D context");
    }
    const ctx: CanvasRenderingContext2D = res;
    console.log(ctx);

    if (File) {
      let shapes: any[] = [];
      let currentShapeIndex: number = -1;
      let isDragging = false;
      let startX: number;
      let startY: number;
      console.log(locatePoints);

      if(locationPoints){
        console.log(locatePoints)
        locationPoints.map((points: number[]) => {
        return shapes.push({ x: points[1], y: points[2], width: 10, height: 10, color: "blue" });
      })
    }
      if (locatePoints)
        shapes.push({ x: x, y: y, width: 10, height: 10, color: "blue" });
      // shapes.push({ x: 0, y: 0, width: 50, height: 50, color: "red" });
      // shapes.push({ x: 0, y: 0, width: 50, height: 50, color: "green" });
      // shapes.push({ x: 0, y: 0, width: 50, height: 50, color: "yellow" });
      // shapes.push({x:0, y:0, width: 5, height: 5, color: "blue"})

      const drawShapes = function () {
        let imgObj = new Image();
        imgObj.src = File;

        imgObj.onload = function () {
          let h = canvas.height;
          let nw = imgObj.naturalWidth;
          let nh = imgObj.naturalHeight;
          let aspect = nh / nw;
          let w = h / aspect;
          console.log("height", w);
          canvas.width = w;
          if (ctx) {
            console.log(ctx, imgObj);
            ctx.drawImage(imgObj, 0, 0, w, h);
          }

          if (!imgObj.src) return;

          //   ctx.clearRect(0, 0, w, h);
          for (let shape of shapes) {
            ctx.fillStyle = shape.color;
            ctx.beginPath();
            ctx.arc(shape.x + shape.width / 2, shape.y + shape.width / 2, shape.width / 2, 0, 2 * Math.PI);
            ctx.fill();
            // ctx.fillRect(shape.x, shape.y, shape.width, shape.height);
          }
        };
      };

      drawShapes();

      const is_mouse_in_shape = function (x: number, y: number, shape: any) {
        let shapeLeft = shape.x;
        let shapeRight = shape.x + shape.width;
        let shapeTop = shape.y;
        let shapeBottom = shape.y + shape.height;

        console.log(x, y, shape);

        if (
          x > shapeLeft &&
          x < shapeRight &&
          y > shapeTop &&
          y < shapeBottom
        ) {
          return true;
        }
        return false;
      };

      const handleMousedown = function (e: MouseEvent) {
        console.log(e);
        // ctx.beginPath();
        // ctx.fillStyle = "#000";
        // var size = 5;
        // var radius = 0.5 * size;
        // var pointX = e.offsetX - radius;
        // var pointY = e.offsetY - radius;
        // ctx.fillRect(pointX, pointY, size, size);
        // ctx.fill();
        e.preventDefault();
        startX = e.offsetX;
        startY = e.offsetY;

        let index = 0;
        for (let shape of shapes) {
          if (is_mouse_in_shape(startX, startY, shape)) {
            currentShapeIndex = index;
            isDragging = true;
            return;
          }
          index++;
        }
      };

      const handleMouseup = function (e: MouseEvent) {
        if (!isDragging) {
          return;
        }
        e.preventDefault();
        isDragging = false;
      };

      const handleMouseout = function (e: MouseEvent) {
        if (!isDragging) {
          return;
        }
        e.preventDefault();
        isDragging = false;
      };

      const handleMousemove = function (e: MouseEvent) {
        console.log(shapes, currentShapeIndex, shapes[currentShapeIndex]);
        if (!isDragging) {
          return;
        }
        e.preventDefault();
        let mouseX = e.offsetX;
        let mouseY = e.offsetY;

        let dx = mouseX - startX;
        let dy = mouseY - startY;

        let currentShape = shapes[currentShapeIndex];
        currentShape.x += dx;
        currentShape.y += dy;

        drawShapes();

        startX = mouseX;
        startY = mouseY;
        if (onChangeInputs && locatePoints)
          onChangeInputs({ id: locatePoints, x: startX, y: startY });
      };

      canvas.addEventListener("mousedown", (e) => handleMousedown(e));
      canvas.addEventListener("mouseup", (e) => handleMouseup(e));
      canvas.addEventListener("mouseout", (e) => handleMouseout(e));
      canvas.addEventListener("mousemove", (e) => handleMousemove(e));
    }
  }, [File, locatePoints, locationPoints]);

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    const files = (e.target as HTMLInputElement).files;

    if (files && files[0]) {
      setFile(URL.createObjectURL(files[0]));
    }
  }

  return (
    <>
      <div>
        {/* <svg ref={svgRef}></svg> */}
        <canvas id="canvas"></canvas>
        {/* <div>
          <label htmlFor="img">
            <div className="p-4 border rounded-lg max-w-max">
              Upload Local Image
            </div>
            <input
              className="hidden"
              onChange={(e) => handleChange(e)}
              type="file"
              id="img"
              name="img"
            />
          </label>
          {File && (
            <div className="h-[500px]">
              <img className="h-full aspect-auto" src={File} alt="img" />
            </div>
          )}
        </div> */}
      </div>
    </>
  );
}
