import React, { useState } from "react";
import { useSelector } from "react-redux";
import Modal from "src/utils/Modal";

type Props = {
  data: any;
  onNext: (nextTemplateId: string) => void;
};

export function ThreeDModelScreen({ data, onNext }: Props) {
  // const [openPopup, setOpenPopup] = useState(false);

  const { headingFont, bodyFont } = useSelector((state: any) => state.programSettings);

  return (
    <div className={`overflow-y-scroll no-scrollbar w-full h-[calc(100%-5.625rem)] flex flex-col space-y-[2rem] md:space-y-[3rem] lg:space-y-[4rem] pb-[0.5rem]`}>
      <div className="grow w-full flex flex-col space-y-[2.87rem] pt-[1rem] md:pt-[2.12rem] pl-[3.75rem] md:pl-[2.5rem] lg:pl-[3.75rem]">
        <div className={`text-[#FFFFFF] text-[1.7rem] md:text-[2rem] lg:text-[2.5rem] font-semibold border-l-4 md:border-l-[7px] pl-[1.5rem] leading-normal ${headingFont && `font-${headingFont}`}`}>
          {data.content.title}
        </div>

        <div className="w-full flex items-center justify-center pt-24">
          <div className={`w-full sm:w-[32.5625rem] flex flex-col justify-between items-center py-7 space-y-2 bg-[#FFFFFF] font-inter ${bodyFont && `font-${bodyFont}`}`}>
            <div className="text-[#008771] text-[30px] font-medium">
              3D MODEL
            </div>
            <div className="text-[#757575] text-[14px] font-medium">
              The 3D Model is available in APP version.
            </div>
          </div>
        </div>
      </div>
      {/* 
      <Modal
      onOpened={openPopup}
      onClose= {() => setOpenPopup(false)}
      title= ""
      >
        <div>View on mobile</div>
      </Modal> */}
    </div>
  );
}
