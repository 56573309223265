import React, { useEffect } from 'react'
import RichTextEditor from 'src/utils/RichTE';

type Props = {
    currentFrontData: string;
    onChange: (currentFrontData: string) => void;
};

function FrontCard({currentFrontData, onChange} : Props) {

    useEffect(() => {
        console.log(currentFrontData);
      }, [currentFrontData]);

    function handleEditorChange(content: string) {
        const newFrontContent = content;
        onChange(newFrontContent);
    }

  return (
    <div className='flex mt-5'>
        <div className='w-full'>
            <RichTextEditor
                // key={selectedTab}
                value={currentFrontData || ""}
                onChange={(content) => handleEditorChange(content)}
            />
        </div>
    </div>
  )
}

export default FrontCard
