import React, { useEffect, useState } from "react";
import VerticalTabs from "./VerticalTabs/VerticalTabs";
import HorizontalTabs from "./HorizontalTabs/HorizontalTabs";

type Tab = {
  id: number;
  content: string;
  name: string;
};

type Props = {
  tabs: Tab[];
  tabsType: string;
  onChange: (tabs: Tab[], tabsType: string) => void;
};

const initialTab: Tab[] = [
    {
      id: Date.now(),
      content: "",
      name: "Tab 1",
    },
  ];

function Tabs({ tabs, tabsType, onChange }: Props) {
    const [tabsData, setTabsData] = useState<Tab[]>(tabs || initialTab);
    const [selectedTab, setSelectedTab] = useState<number | null>(tabs? tabs[0].id : initialTab[0].id);

  useEffect(() => {
    console.log("tabs", tabsData);
    onChange(tabsData, tabsType); // Notify parent about the initial tabs state
  }, [tabsData]);

  const handleTabsTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newTabType = e.target.value;
    onChange(tabsData, newTabType);
  };

  const handleTabsChange = (updatedTabs: Tab[]) => {
    setTabsData(updatedTabs);
  };

  return (
    <div className="flex flex-col mt-5">
      <label className="grid grid-cols-2">
        <span className="col-span-1 font-semibold font-800 text-[1rem] text-[#242424]">Select Tab Type</span>
        <select
          className="col-span-1 w-48 border border-[#d7d7d7] font-semibold text-[#AB0273] text-[1rem] px-2 py-1 hover:border-purple-600  rounded-md"
          value={tabsType}
          onChange={handleTabsTypeChange}
        >
          <option className="text-[#AB0273] text-[1rem] font-semibold" value="vertical">Vertical</option>
          <option className="text-[#AB0273] text-[1rem] font-semibold" value="horizontal">Horizontal</option>
        </select>
      </label>

      {tabsType === "vertical" ? (
        <VerticalTabs tabs={tabsData} selectedTab={selectedTab} setSelectedTab={setSelectedTab} onChange={handleTabsChange}/>
      ) : (
        <HorizontalTabs tabs={tabsData} selectedTab={selectedTab} setSelectedTab={setSelectedTab} onChange={handleTabsChange} />
      )}
    </div>
  );
}

export default Tabs;
