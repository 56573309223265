import React, { useEffect, useRef, useState } from "react";
// @ts-ignore
import PauseLogo from "../../../assets/preview/Pause.svg";
// @ts-ignore
import PlayLogo from "../../../assets/play.png";
// @ts-ignore
import Speaker from "../../../assets/preview/Speaker.svg";
// @ts-ignore
import Repeat from "../../../assets/preview/Repeat_.svg";
import ReactPlayer from 'react-player'

type Props = {
  file: any;
  themeColor?: any
};

function Video({ file, themeColor }: Props) {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isVolumeOpen, setIsVolumeOpen] = useState(false);
  const [currentTime, setCurrentTime] = useState<number | undefined>(0);
  const [audioDuration, setAudioDuration] = useState<number | undefined>(0);
  const MAX = 20;

  useEffect(() => {
    console.log(file);
  }, []);

  const videoRef = useRef<HTMLVideoElement>(null);

  const handlePlayPause = () => {
    if (isPlaying) {
      videoRef.current?.pause();
    } else {
      videoRef.current?.play();
    }

    setIsPlaying((currentPlayStatus) => !currentPlayStatus);
  };

  const onTimeUpdate = () => {
    setCurrentTime(videoRef.current?.currentTime ?? 0);
  };

  const formatTime = (duration: number) => {
    const OneSec = 60;
    const minutes = Math.floor(duration / OneSec);
    const seconds = Math.floor(duration - minutes * OneSec);

    const formatedTime = `${tenPad(minutes)}:${tenPad(seconds)}`;

    return formatedTime;
  };

  const tenPad = (time: number) => {
    if (time < 10) return `0${time}`;
    else return time;
  };

  const onVolumeChange = (value: number) => {
    if (videoRef.current) {
      videoRef.current.volume = value / 100;
    }
  };

  function handleVolume(e: React.ChangeEvent<HTMLInputElement>) {
    const { value } = e.target;
    const volume = Number(value) / MAX;
    if (videoRef.current) {
      videoRef.current.volume = volume;
    }
  }

  return (
    <div className="w-full grow flex flex-col items-center justify-center">
      <div className="xs:w-[94%] sm:w-[80%] md:w-[85%] lg:w-[50%] 2xl:w-[70%] grow items-center justify-center flex flex-col space-y-[0.44rem]">
        <div className="relative w-full grow border rounded-[0.625rem] overflow-hidden bg-black">
          {file && (
            <video
              ref={videoRef}
              className="absolute inset-0 mx-auto max-w-none max-h-full rounded-[0.625rem]"
              style={{
                width: 'auto',
                height: '100%',
                maxWidth: '100%',
                maxHeight: '100%'
              }}
              onWaiting={() => setIsLoading(true)}
              onCanPlayThrough={() => {
                setAudioDuration(videoRef.current?.duration);
                setIsLoading(false);
              }}
              onEnded={() => setIsPlaying(false)}
              onTimeUpdate={onTimeUpdate}
            >
              <source src={file?.url || ""} type={file?.type || ""}></source>
            </video>
          )}
        </div>
        <div className="flex flex-col w-full items-center">
          <div className="flex  w-full items-center justify-center space-x-1">
            <div className="group flex items-center">
              <button onClick={() => setIsVolumeOpen(!isVolumeOpen)}>
                <img src={Speaker} alt="sound" />
              </button>
              <input
                type="range"
                className={`accent-[#FFFFFF] h-[4px] w-[3.125rem] group-hover: block ${isVolumeOpen ? "block" : "hidden"
                  }`}
                min={0}
                max={MAX}
                onChange={(e) => handleVolume(e)}
              />
            </div>

            {/* Progress bar */}
            <div className="bg-[#FFFFFF]/50 h-[4px] w-[25.625rem] mx-auto rounded-full">
              <div
                className={`bg-[#FFFFFF] h-full w-[0rem] rounded-full ${currentTime !== audioDuration ? "progress-bar-inner" : ""
                  }`}
                style={{
                  animationPlayState: isPlaying ? "running" : "paused",
                  animationDuration: isLoading
                    ? "0s"
                    : `${videoRef.current?.duration}s`,
                }}
              ></div>
            </div>
            <button>
              <img src={Repeat} alt="repeat" />
            </button>
          </div>

          <div className="flex w-full max-w-[25.625rem] justify-between">
            {/* current time */}
            <span className="text-[#FFFFFF] text-[0.625rem] font-[400] leading-normal">
              {formatTime(currentTime ?? 0)}
            </span>

            <div className="flex justify-center">
              <button
                onClick={handlePlayPause}
                style={{borderColor: themeColor, opacity: 0.7}}
                className={`w-[3.25] h-[3.25] p-[0.625rem] flex items-center justify-center border-[3px] border-solid ${!themeColor && "border-[#AB0273]/70 "} rounded-full`}
              >
                {isPlaying ? (
                  <img src={PauseLogo} alt="pause" />
                ) : (
                  <img
                    className="w-[2rem] h-[2rem] p-[0.4rem]"
                    src={PlayLogo}
                    alt="play"
                  />
                )}
              </button>
            </div>

            {/* Audio Duration */}
            <span className="text-[#FFFFFF] text-[0.625rem] font-[400] leading-normal">
              {formatTime(audioDuration ?? 0)}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Video;

// create a component which will get a youtube link as a prop and display the video in the component itself
// use the react-youtube package to display the video


type YtLinkProps = {
  url: string;
};

export function ReactWebPlayer({ url }: YtLinkProps) {
  const opts = {
    height: "390",
    width: "640",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
    },
  };

  return (
    <ReactPlayer height={"100%"} width={"100%"} url={url} controls={true} />
  );
}

