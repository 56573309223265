import React from "react";
import Imageicon from "../../assets/Upload/Image.svg";
import ViewIcon from "../../assets/Upload/Eye.svg";
import DeleteIcon from "../../assets/Upload/TrashSimple.svg";

type Props = {
  openDrawerForm?: any;
};

function DocumentDropdown({ openDrawerForm }: Props) {
  return (
    <>
      {openDrawerForm ? (
        <div className="absolute bg-[#F5F5F5] w-[15rem] border-2 border-black/30 rounded-[1.5rem] py-[1.13rem] px-[1.06rem] space-y-[1.2rem] z-20">

        <div className="flex space-x-[1.3rem] w-full text-[1rem] text-[#242424] items-center">
          <span>
            <img src={Imageicon} alt="" />
          </span>
          <span>Change Document</span>
        </div>

        <div className="flex space-x-[1.3rem] w-full text-[1rem] text-[#242424] items-center">
          <span>
            <img src={ViewIcon} alt="" />
          </span>
          <span>View Document</span>
        </div>
        <div className="bg-[#2C2C2C] h-[0.063rem]"></div>
        <div className="flex space-x-[1.3rem] w-full text-[1rem] text-[#C2262E] items-center">
          <span>
            <img src={DeleteIcon} alt="" />
          </span>
          <span>Delete</span>
        </div>
      </div>
      ) : (
        <div className="absolute inline-block ml-1 w-[12.8125rem] bg-[#F5F5F5] border-2 border-black/30 rounded-[1.5rem] py-[1.13rem] px-[1.06rem] space-y-[1.2rem]">

        <div className="flex space-x-[1.3rem] w-full text-[1rem] text-[#242424] items-center">
          <span>
            <img src={Imageicon} alt="" />
          </span>
          <span>Change Document</span>
        </div>

        <div className="flex space-x-[1.3rem] w-full text-[1rem] text-[#242424] items-center">
          <span>
            <img src={ViewIcon} alt="" />
          </span>
          <span>View Document</span>
        </div>
        <div className="bg-[#2C2C2C] h-[0.063rem]"></div>
        <div className="flex space-x-[1.3rem] w-full text-[1rem] text-[#C2262E] items-center">
          <span>
            <img src={DeleteIcon} alt="" />
          </span>
          <span>Delete</span>
        </div>
      </div>
      )}
    </>
  );
}

export default DocumentDropdown;
