import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  pageState: null as any,
};
export const templatePageStateSlice = createSlice({
  name: "templatePageState",
  initialState,
  reducers: {
    setPageState: (state, action) => {
      state.pageState = action.payload;
    },
    setAddPageState: (state, action) => {
      return { pageState: {...state.pageState, ...action.payload}} ;
    },
  },
});

export const { setPageState, setAddPageState } = templatePageStateSlice.actions;
export default templatePageStateSlice.reducer;
