import React, { useState, useEffect, ChangeEvent, useRef } from "react";
// @ts-ignore
import ImageSymbol from "../../../assets/picture.png";
// @ts-ignore
import TextSymbol from "../../../assets/textSymbol.png";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import UploadButton from "src/utils/UploadButton";
import UploadDropdowns from "src/components/UploadDropdowns";
import { useParams } from "react-router-dom";
// @ts-ignore
import arrow from "../../../assets/arrow.png";
// import RichTextEditor from "src/utils/RichTE";
import { useSelector } from "react-redux";
import { ChevronUp } from "lucide-react";

type Props = {
  data: {
    imageSrc: string | null;
    text: string;
    id: string;
  };
  onChange: (data: { imageSrc: string | null; text: string, id: string }) => void;
  params: any;
  topic: any;
};

const layouts = [
  "imageLeftTextRight",
  "imageTopTextBottom",
  "imageRightTextLeft",
  "imageBottomTextTop",
  "imageOnly",
];

function ImageWithText({ data, onChange, params, topic }: Props) {
  const [imageSrc, setImageSrc] = useState<string | null>(
    data?.imageSrc || ""
     
  );

  const [text, setText] = useState<string>(data?.text || "");

  const [File, setFile] = useState<any | null>(null);

  const { userId, businessUnitId, pageId } = useParams();
  const [layoutIndex, setLayoutIndex] = useState<number>(0);
  const [imageSelected, setImageSelected] = useState<boolean>(false);
  const [showLayoutButtons, setShowLayoutButtons] = useState<boolean>(false);
  const [showButton, setShowButton] = useState<boolean>(false);
  const [buttonPosition, setButtonPosition] = useState<{
    top: number;
    left: number;
  }>({ top: 0, left: 0 });

  const containerRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const tooltipRef = useRef<HTMLDivElement>(null);

  const pageState = localStorage.getItem("pageState")
    ? JSON.parse(localStorage.getItem("pageState")!)
    : null;

  useEffect(() => {
    if (pageState) {
      setImageSrc(pageState.file?.url || "");
      setText(pageState.text);
      localStorage.removeItem("pageState")
    } 
  }, []);

  useEffect(()=>{
    onChange({...data, imageSrc, text });
  }, [imageSrc, text]);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node) &&
        !tooltipRef.current?.contains(event.target as Node)
      ) {
        setShowLayoutButtons(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [containerRef]);

  const handleImageUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const imageURL = URL.createObjectURL(file);
      setImageSrc(imageURL);
      setImageSelected(true);
    }
  };

  const handleTextChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setText(event.target.value);
    setShowLayoutButtons(false); // Hide the tooltip when interacting with text area
  };

  const handleLayoutButtonClick = (index: number) => {
    setLayoutIndex(index);
    // Keeps the tooltop layout buttons visible after button click if needed
  };

  const handleImageMouseEnter = () => {
    setShowButton(true);
  };

  const handleImageMouseLeave = () => {
    setShowButton(false);
  };

  const handleButtonClicked = () => {
    if (buttonRef.current && containerRef.current) {
      const buttonRect = buttonRef.current.getBoundingClientRect();
      const containerRect = containerRef.current.getBoundingClientRect();

      const topPosition = buttonRect.top - containerRect.top - 190;
      const leftPosition = buttonRect.left - containerRect.left - 20;

      const adjustedTopPosition = topPosition;

      setButtonPosition({ top: adjustedTopPosition, left: leftPosition });
      setShowLayoutButtons(true); // Show tooltip only when the pop-up button is clicked
    }
  };

  const handleContainerMouseLeave = () => {
    setShowButton(false);
    setShowLayoutButtons(false); // Hide tooltip when leaving container
  };

  return (
    <div
      ref={containerRef}
      className="border border-gray-300 bg-[#e4bcbc] p-5 mt-5 mb-10 rounded-lg relative"
      onMouseLeave={handleContainerMouseLeave}
    >
      <div>
        <input id={`input-${data.id}`}
          type="file"
          accept="image/*"
          onChange={(e)=>{handleImageUpload(e)}}
          className="border-none hidden focus:outline-none"
        />
         <label className="bg-[#AB0273] text-white text-[1rem] font-[400]  px-3 py-2 rounded-md" htmlFor={`input-${data.id}`}><span>Upload</span></label>
      </div>
      <div className="col-span-3 flex space-x-[0.75rem] items-center">
        {/* <UploadButton
          name="imageFile"
          type="image"
          handleUploadFromDevice={(file) => {
            setFile(file);
            console.log(file);
          }}
        /> */}
        <button
          onClick={() => {
            localStorage.setItem("pageState", JSON.stringify({ topic, text }));
            window.open(
              `/user/${params.userId}/businessunit/${params.businessUnitId}/page/${params.pageId}/media?type=image`,
              "_self"
            );
          }}
          className="my-4 bg-[#AB0273] font-[400] text-[1rem] text-white border border-purple-300 rounded-md p-1"
        >
          Select Image from Library
        </button>
        <UploadDropdowns
          name="imageFile"
          type="image"
          handleCropFile={(cropFile) => setFile(cropFile)}
          File={File}
        />
      </div>
      {/* <div className="mb-8 flex justify-between ">
        <Tippy content="dfsss">
      
        <button
          onClick={() => changeLayout(0)}
          className="px-4 py-2 w-24 bg-blue text-white rounded-lg flex justify-between items-center hover:bg-teal-300 hover:shadow-lg" 
        >
          <span><img src={ImageSymbol} alt="image" className="w-6 h-6" /></span>
          <span><img src={TextSymbol} alt="text" className="w-7 h-7" /></span>
        </button>
       </Tippy>
       <Tippy content="dfsss">
        <button
          onClick={() => changeLayout(1)}
          className="px-4 py-2 w-24 bg-blue text-white rounded-lg flex flex-col justify-center items-center hover:bg-teal-300 hover:shadow-lg"
        >
          <span><img src={ImageSymbol} alt="image" className="w-6 h-6" /></span>
          <span><img src={TextSymbol} alt="text" className="w-7 h-7" /></span>
        </button>
        </Tippy>
        <Tippy content="dfsss">
          <button
            onClick={() => changeLayout(1)}
            className="px-4 py-2 w-24 bg-blue text-white rounded-lg flex flex-col justify-center items-center hover:bg-teal-300 hover:shadow-lg"
          >
            <span><img src={ImageSymbol} alt="image" className="w-6 h-6" /></span>
            <span><img src={TextSymbol} alt="text" className="w-7 h-7" /></span>
          </button>
        </Tippy>
        <Tippy content="dfsss">
          <button
            onClick={() => changeLayout(2)}
            className="px-4 py-2 w-24 bg-blue text-white rounded-lg flex justify-between items-center hover:bg-teal-300 hover:shadow-lg"
          >
            <span><img src={TextSymbol} alt="text" className="w-7 h-7" /></span>
            <span><img src={ImageSymbol} alt="image" className="w-6 h-6" /></span>
          </button>
        </Tippy>
        <Tippy content="dfsss">
          <button
            onClick={() => changeLayout(3)}
            className="px-4 py-2 w-24 bg-blue text-white rounded-lg flex flex-col justify-center items-center hover:bg-teal-300 hover:shadow-lg"
          >
            <span><img src={TextSymbol} alt="text" className="w-7 h-7" /></span>
            <span><img src={ImageSymbol} alt="image" className="w-6 h-6" /></span>
          </button>
        </Tippy>
        <Tippy content="dfsss">
          <button
            onClick={() => changeLayout(4)}
            className="px-4 py-2 w-24 bg-blue text-white rounded-lg flex justify-center items-center hover:bg-teal-300 hover:shadow-lg"
          >
            <span><img src={ImageSymbol} alt="image" className="w-6 h-6" /></span>
          </button>
        </Tippy>
      </div> */}
      {/* <div className={`${layoutIndex % 2 === 0 ? 'grid grid-cols-2' : 'flex flex-col space-y-3'} border border-gray-300 p-2 rounded-lg `}>
        {layoutIndex !== 4 && (
          <div className={`relative overflow-hidden rounded-[0.625rem] bg-black ${layoutIndex === 0 ? 'col-span-1 h-60' : layoutIndex === 1 ? 'w-full h-80 mb-1' : layoutIndex === 2 ? 'col-span-1 h-60 order-2' : 'w-full h-80 order-2 mt-1'}`}>
            {imageSrc && (
              <img src={imageSrc} alt="image" className="absolute inset-0 mx-auto max-w-none max-h-full rounded-[0.625rem] object-contain"
                style={{
                  width: 'auto',
                  height: '100%',
                  maxWidth: '100%',
                  maxHeight: '100%',
                }} /> */}

      <div className="relative">
        <div className="relative">
          <div
            onMouseEnter={handleImageMouseEnter}
            onMouseLeave={handleImageMouseLeave}
            className="relative"
          >
            {/* {imageSelected && ( */}
              <div className="absolute">
                <button
                  ref={buttonRef}
                  className={`relative flex items-center bg-white rounded-md w-14 h-7 p-1 ${
                    showButton ? "block" : "hidden"
                  }`}
                  style={{ zIndex: 999, left: "10px" }}
                  onClick={handleButtonClicked} // Moved onClick handler to the button itself
                >
                  <img src={ImageSymbol} alt="image" className="w-5 h-5" />
                  <ChevronUp />
                </button>
              </div>
            

            {showLayoutButtons && (
              <div
                ref={tooltipRef}
                className="absolute bg-white p-2 border border-gray-200 rounded-lg z-10"
                style={{
                  top: buttonPosition.top,
                  left: buttonPosition.left - 20,
                  width: "250px",
                  minWidth: "150px",
                }}
              >
                <div className="flex">
                  {layouts.map((layout, idx) => (
                    <button
                      key={idx}
                      onClick={() => handleLayoutButtonClick(idx)}
                      className="px-1 py-2 w-20 rounded-lg flex justify-center items-center hover:shadow-lg bg-[#c1bebe]"
                      style={{ marginRight: "2px" }}
                    >
                      {layout === "imageLeftTextRight" && (
                        <div className="flex items-center">
                          <img
                            src={ImageSymbol}
                            alt="image"
                            className="w-3 h-3"
                          />
                          <img
                            src={TextSymbol}
                            alt="text"
                            className="w-4 h-4"
                          />
                        </div>
                      )}
                      {layout === "imageTopTextBottom" && (
                        <div className="flex flex-col items-center">
                          <img
                            src={ImageSymbol}
                            alt="image"
                            className="w-3 h-3"
                          />
                          <img
                            src={TextSymbol}
                            alt="text"
                            className="w-4 h-4"
                          />
                        </div>
                      )}
                      {layout === "imageRightTextLeft" && (
                        <div className="flex items-center justify-between">
                          <img
                            src={TextSymbol}
                            alt="text"
                            className="w-4 h-4"
                          />
                          <img
                            src={ImageSymbol}
                            alt="image"
                            className="w-3 h-3"
                          />
                        </div>
                      )}
                      {layout === "imageBottomTextTop" && (
                        <div className="flex flex-col items-center">
                          <img
                            src={TextSymbol}
                            alt="text"
                            className="w-4 h-4"
                          />
                          <img
                            src={ImageSymbol}
                            alt="image"
                            className="w-3 h-3"
                          />
                        </div>
                      )}
                      {layout === "imageOnly" && (
                        <img
                          src={ImageSymbol}
                          alt="image"
                          className="w-3 h-3"
                        />
                      )}
                    </button>
                  ))}
                </div>
              </div>
            )}

            <div
              className={`${
                layoutIndex % 2 === 0
                  ? "grid grid-cols-2"
                  : "flex flex-col space-y-3"
              } mt-4`}
            >
              {layoutIndex !== 4 && (
                <div
                  className={`${
                    layoutIndex === 0
                      ? "col-span-1 h-60"
                      : layoutIndex === 1
                      ? "w-full h-80 mb-1"
                      : layoutIndex === 2
                      ? "col-span-1 h-60 order-2"
                      : "w-full h-80 order-2 mt-1"
                  } group relative overflow-hidden rounded-[0.625rem] bg-black`}
                >
                  {imageSrc ? (
                    <img
                      src={imageSrc}
                      alt="image"
                      className="absolute inset-0 w-full h-full rounded-[0.625rem] object-cover"
                    />
                  ) : (
                    <div className="w-full h-full flex justify-center items-center text-white">
                      Upload Image
                    </div>
                  )}
                </div>
              )}

              {layoutIndex !== 4 && (
                <div
                  className={`${
                    layoutIndex === 0
                      ? "col-span-1 h-60 ml-1"
                      : layoutIndex === 1
                      ? "w-full h-60"
                      : layoutIndex === 2
                      ? "col-span-1 h-60 order-1 mr-1"
                      : "w-full h-60 order-1"
                  }`}
                >
                  <textarea
                    value={text}
                    onChange={handleTextChange}
                    placeholder="Enter text"
                    className="px-2 text-sm rounded-lg w-full h-full overflow-y-scroll no-scrollbar"
                  />
                  {/* {text !== undefined && (
                    <RichTextEditor
                      placeholder="Enter Acknowledgement Text"
                      value={text}
                      onChange={(content, contentLength) => {
                        console.log(content);
                        setText(content);
                      }}
                    />
                  )} */}
                </div>
              )}

              {layoutIndex === 4 && (
                <div className="col-span-2 relative overflow-hidden rounded-[0.625rem] bg-black h-80 group">
                  {imageSrc ? (
                    <img
                      src={imageSrc}
                      alt="image"
                      className="absolute inset-0 w-full h-full rounded-[0.625rem] object-cover"
                    />
                  ) : (
                    <div className="w-full h-full flex justify-center items-center text-white">
                      Upload Video
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ImageWithText;
