import React, { memo, useEffect, useState } from "react";
import Layout from "../Layout";
import Auth from "../Auth";
import axios from "axios";
import { useIsAuthenticated } from "@azure/msal-react";
import Table from "src/utils/Table";
import { setActiveSidebar } from "src/features/activeSidebar/activeSidebarSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

type course = {
  _id: string;
  authorId: string;
  title: string;
  imageUrl: string;
  createdOn: string;
  modifiedOn: string;
};

const Shared = memo((props) => {
  const [courses, setCourses] = useState<Array<course>>([]);
  const isAuthenticated = useIsAuthenticated()
  const dispatch = useDispatch()
  const { accessToken } = useSelector((state: any) => state.accessToken);

  useEffect(() => {
    dispatch(setActiveSidebar("shared"))
  }, [])

  useEffect(() => {
    (async () => await getSharedPrograms())()
  }, [])

  async function getSharedPrograms() {
    console.log("accessToken:", accessToken)
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_BASE_URL}/api/v1/program/share/get`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );

      if (response && response.data) {
        console.log("courses:", response.data.data);
        setCourses(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <Auth>
      <Layout type="Shared">

        {(callback) =>
          <div className="col-span-12 overflow-auto w-full h-[calc(100% - 7.75rem)] rounded-[1.25rem] mt-[1.25rem] mb-[0.875rem] mr-[0.625rem] px-[0.63rem] py-[1.25rem] shadow-lg text-secondary bg-[#FFF]">
            <section className="flex flex-col space-y-[0.81rem] overflow-auto w-full h-full no-scrollbar">
              <div className="text-[1.5rem] font-[600] px-[1.37rem] leading-normal text-[#000]">Shared</div>
              <Table type="Shared" data={courses} />
              {
                courses.length <= 0 &&
                <div className="flex flex-col pt-[19.8rem] px-[19.5rem] whitespace-nowrap items-center justify-center text-center">
                  <div className="text-[#1rem] font-[600] leading-normal text-[#242424] ">No shared materials</div>
                  <div className="text-[#0.875rem] font-[600] leading-normal text-[#929292]">All materials shared with you will appear here.</div>
                </div>
              }
            </section>
          </div>}
      </Layout>
    </Auth>
  );
});

export default Shared;
