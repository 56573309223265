import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  activeSidebar: "dashboard",
};
export const sidebarSlice = createSlice({
  name: "activeSidebar",
  initialState,
  reducers: {
    setActiveSidebar: (state, action) => {
      state.activeSidebar = action.payload;
    },
  },
});

export const { setActiveSidebar } = sidebarSlice.actions;
export default sidebarSlice.reducer;
