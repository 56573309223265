import React, { ReactElement, useEffect } from 'react'

type Props = {
    onOpened: boolean;
    onClose: () => void;
    title: string;
    children: ReactElement;
    height?: string
    // width?: string
    passUnitIDtoDelete?: string
    // className?: string
}

export default function Drawer({ onOpened, onClose, title, children, height }: Props) {

    const keydownHandler = ({ key }: { key: string }) => {
        switch (key) {
            case 'Escape':
                onClose();
                break;
            default:
        }
    };

    useEffect(() => {
        document.addEventListener('keydown', keydownHandler);
        return () => document.removeEventListener('keydown', keydownHandler);
    });

    return !onOpened ? null :
        <div className={`drawer ${height && "!-top-[1.06rem] !py-0"}`}>
            <div className={`drawer-dialogue ${height && `!h-full`}`}>
                <div className='modal-Dtitle text-left font-[600]  leading-normal text-[#242424] bg-[#F5F5F5]'>
                    <h1>{title}</h1>
                    <button className='pr-[0.31rem]' onClick={onClose}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                        <path d="M25 7L7 25" stroke="black" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M25 25L7 7" stroke="black" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    </button>
                </div>
                <div className='modal-body'>
                    {children}
                </div>
            </div>
        </div>
}