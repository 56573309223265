// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rdw-dropdownoption-default {
  min-height: 25px;
  display: flex;
  align-items: center;
  padding: 0 5px;
}
.rdw-dropdownoption-highlighted {
  background: #F1F1F1;
}
.rdw-dropdownoption-active {
  background: #f5f5f5;
}
.rdw-dropdownoption-disabled {
  opacity: 0.3;
  cursor: default;
}
`, "",{"version":3,"sources":["webpack://./src/utils/RTE/components/Dropdown/DropdownOption/styles.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,aAAa;EACb,mBAAmB;EACnB,cAAc;AAChB;AACA;EACE,mBAAmB;AACrB;AACA;EACE,mBAAmB;AACrB;AACA;EACE,YAAY;EACZ,eAAe;AACjB","sourcesContent":[".rdw-dropdownoption-default {\n  min-height: 25px;\n  display: flex;\n  align-items: center;\n  padding: 0 5px;\n}\n.rdw-dropdownoption-highlighted {\n  background: #F1F1F1;\n}\n.rdw-dropdownoption-active {\n  background: #f5f5f5;\n}\n.rdw-dropdownoption-disabled {\n  opacity: 0.3;\n  cursor: default;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
