import React from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import useFolder from "src/hooks/useFolder";
import Folder from "./Folder";
import File from "./File";
import FolderBreadcrums from "./FolderBreadcrums";
import Table from "src/utils/Table";
import MediaTable from "src/utils/MediaTable";

type Props = {
  onHandleCheckedItems: (items: any) => void
  searchResults: any[];
  onHandleClickedSearchResult: () => void;
  searchInput: string;
};

function Main({onHandleCheckedItems, searchResults, onHandleClickedSearchResult, searchInput}: Props) {
  const [ searchParams, setSearchParams ]: any = useSearchParams();
  const folderId = searchParams.get("folderId");
  const { folder } = useSelector((state: any) => state.fileFolder);

  const {fileFolder} = useFolder({ folderId: (folderId === "null" ? null : folderId), folder });

//   console.log(fileFolder)

  return (
    <div className="flex flex-col">
      <div className="border-b border-tertiary p-2 bg-[#36BFFA] text-white font-bold">
        <FolderBreadcrums searchInput={searchInput} currentFolder={fileFolder.folder} onHandleClickedSearchResult={onHandleClickedSearchResult}/>
      </div>

      <MediaTable data={searchResults.length > 0 ? searchResults : [...fileFolder.childFolders, ...fileFolder.childFiles]} onChange={(data) => {}} checkedItems={(items) => onHandleCheckedItems(items)} onHandleClickedSearchResult={onHandleClickedSearchResult} />
      {/* <div>
        {fileFolder?.childFolders && fileFolder.childFolders.length > 0 && (
          <div className="d-flex flex-wrap">
            {fileFolder.childFolders.map((childFolder: any) => (
              <div
                key={childFolder._id}
                style={{ maxWidth: "250px" }}
                className="p-2"
              >
                 <Folder folder={childFolder} />
              </div>
            ))}
          </div>
        )}
        {fileFolder?.childFiles && fileFolder.childFiles.length > 0 && (
          <div className="d-flex flex-wrap">
            {fileFolder.childFiles.map((childFile: any) => (
              <div
                key={childFile._id}
                style={{ maxWidth: "250px" }}
                className="p-2"
              >
                <File file={childFile} />
              </div>
            ))}
          </div>
        )}
      </div> */}
    </div>
  );
}

export default Main;
