import React, { useEffect, useRef, useState } from "react";
import EditorJS from "@editorjs/editorjs";
import Header from "@editorjs/header";
// @ts-ignore
import List from "@editorjs/list";
import MCQTool from "./MCQTool";
import VideoTool from "./VideoUpload/VideoTool";
import VerticalTabsTool from "./Tabs/VerticalTabs/VerticalTabsTool";
import HorizontalTabsTool from "./Tabs/HorizontalTabs/HorizontalTabsTool";
// @ts-ignore
import DragDrop from "editorjs-drag-drop";
import AudioTool from "./AudioUpload/AudioTool";
import ImageTool from "./ImageUpload/ImageTool";
import { useSelector } from "react-redux";
import ColumnsTool from "./Columns/ColumnsTool";
import TabsTool from "./Tabs/TabsTool";
import ClickToRevealTool from "./ClickToReveal/ClickToRevealTool";
// @ts-ignore
import LinkTool from '@editorjs/link';
// @ts-ignore
import Delimiter from '@editorjs/delimiter';
// @ts-ignore
import Quote from '@editorjs/quote';
// @ts-ignore
import Checklist from '@editorjs/checklist';
// @ts-ignore
import Embed from '@editorjs/embed';
// @ts-ignore
import CodeTool from '@editorjs/code';
// @ts-ignore
import InlineCode from '@editorjs/inline-code';
// @ts-ignore
import Table from '@editorjs/table'
// @ts-ignore
import Marker from '@editorjs/marker';
// @ts-ignore
import RawTool from '@editorjs/raw';
// @ts-ignore
import LinkAutocomplete from '@editorjs/link-autocomplete';
// @ts-ignore
import Underline from '@editorjs/underline';
import { useParams } from "react-router-dom";
import FlipCardsTool from "./Flipcards/FlipCardsTool";
import AccordionsTool from "./Accordions/AccordionsTool";
import DragAndDropTool from "./DragAndDrop/DragAndDropTools";
import { DropResult } from "react-beautiful-dnd";



type EditorProps = {
    value?: string;
    topic: any;
    onChange: (value: string) => void;
    handleIsTopicSelected: () => void;
    isTopicSelected: boolean;
};

export default function Editor({
    value,
    topic,
    onChange,
    handleIsTopicSelected,
    isTopicSelected,
}: EditorProps) {
    const editorRef = useRef<EditorJS | null>(null);
    const [editorData, setEditorData] = useState(value ? JSON.parse(value) : {});

    const { pageState } = useSelector((state: any) => state.templatePageState);
    const { businessUnitId, userId, pageId } = useParams();

    useEffect(() => {
        if (editorRef.current) {
            editorRef.current.destroy();
        }

        editorRef.current = new EditorJS({
            holder: "editorjs",
            tools: {
                header: {
                    //@ts-ignore
                    class: Header,
                    inlineToolbar: ["link", "bold", "italic"],
                },
                list: {
                    class: List,
                    inlineToolbar: true,
                },
                mcq: MCQTool,
                video: VideoTool,
                audio: AudioTool,
                image: {
                    //@ts-ignore
                    class: ImageTool,
                    config: {
                        params: { businessUnitId, userId, pageId },
                        topic,
                    },
                },
                tabs: TabsTool,
                clickToReveal: ClickToRevealTool,
                flipcards: FlipCardsTool,
                accordion: AccordionsTool,
                link: LinkTool,
                delimiter: Delimiter,
                quote: {
                    class: Quote,
                    inlineToolbar: true,
                    shortcut: 'CMD+SHIFT+O',
                    config: {
                        quotePlaceholder: 'Enter a quote',
                        captionPlaceholder: 'Quote\'s author',
                    },
                },
                checklist: {
                    class: Checklist,
                    inlineToolbar: true,
                },
                embed: {
                    //@ts-ignore
                    class: Embed,
                },
                code: CodeTool,
                inlineCode: {
                    class: InlineCode,
                    shortcut: 'CMD+SHIFT+M',
                },
                table: {
                    class: Table,
                    inlineToolbar: true,
                    config: {
                        rows: 2,
                        cols: 3,
                    },
                },
                Marker: {
                    class: Marker,
                    shortcut: 'CMD+SHIFT+M',
                },
                raw: RawTool,
                _link: {
                    class: LinkAutocomplete,
                    config: {
                        endpoint: 'http://localhost:3000/',
                        queryParam: 'search'
                    }
                },
                underline: Underline,
                columns: {
                    //@ts-ignore
                    class: ColumnsTool,
                    config: {
                        tools: {
                            header: {
                                //@ts-ignore
                                class: Header,
                                inlineToolbar: ["link", "bold", "italic"],
                            },
                            list: {
                                class: List,
                                inlineToolbar: true,
                            },
                            mcq: MCQTool,
                            video: VideoTool,
                            audio: AudioTool,
                            image: {
                                //@ts-ignore
                                class: ImageTool,
                                config: {
                                    params: { businessUnitId, userId, pageId },
                                    topic,
                                },
                            },
                            tabs: TabsTool,
                            link: LinkTool,
                            delimiter: Delimiter,
                            quote: {
                                class: Quote,
                                inlineToolbar: true,
                                shortcut: 'CMD+SHIFT+O',
                                config: {
                                    quotePlaceholder: 'Enter a quote',
                                    captionPlaceholder: 'Quote\'s author',
                                },
                            },
                            checklist: {
                                class: Checklist,
                                inlineToolbar: true,
                            },
                            embed: {
                                //@ts-ignore
                                class: Embed,
                            },
                            code: CodeTool,
                            inlineCode: {
                                class: InlineCode,
                                shortcut: 'CMD+SHIFT+M',
                            },
                            table: {
                                class: Table,
                                inlineToolbar: true,
                                config: {
                                    rows: 2,
                                    cols: 3,
                                },
                            },
                            Marker: {
                                class: Marker,
                                shortcut: 'CMD+SHIFT+M',
                            },
                            raw: RawTool,
                            _link: {
                                class: LinkAutocomplete,
                                config: {
                                    endpoint: 'http://localhost:3000/',
                                    queryParam: 'search'
                                }
                            },
                            underline: Underline,
                        },
                        EditorJsLibrary: EditorJS
                    },
                },
                dragAndDrop: DragAndDropTool,
            },
            data: value && JSON.parse(value),
            onReady: () => {
                new DragDrop(editorRef.current);
                console.log("Editor.js is ready to work!");
               
            },
            onChange: async () => {
                const savedData = await editorRef.current!.save();
                console.log(savedData);
                setEditorData(savedData);
                onChange(JSON.stringify(savedData));
            },
            placeholder: "Enter your text here...",
        });

        return () => {
            if (editorRef.current) {
                editorRef.current.destroy();
                editorRef.current = null;
            }
        };
    }, []);

    useEffect(() => {
        // If the value prop changes, re-render the editor with new data
        console.log("value:", value, "isTopicSelected:", isTopicSelected);
        if (editorRef.current && value && isTopicSelected) {
            const newData = JSON.parse(value);
            editorRef.current.isReady
                .then(() => {
                    // editorRef.current.blocks.clear();
                    editorRef.current!.blocks.render(newData);
                    handleIsTopicSelected();
                })
                .catch((err) => console.error("Editor.js is not ready yet", err));
        }
    }, [value]);

    // useEffect(() => {
    //     const handleImageSelection = async (event: any) => {
    //         const imageUrl =
    //             pageState?.file.url ||
    //             "https://images.unsplash.com/photo-1505533321630-975218a5f66f?fm=jpg&w=3000&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D";

    //         const blockId = "yQOrAsqfHa";

    //         console.log("Image URL:", imageUrl, "pageState:", pageState, "blockId:", blockId);

    //         if (imageUrl && editorRef.current && value) {
    //             let block_data = JSON.parse(value)
    //             console.log("Block Data:", block_data);
    //             let find_index = block_data.blocks.findIndex((block: any) => block.id === blockId);
    //             console.log("Find Index:", find_index);
    //             let old_data = block_data.blocks[find_index];
    //             console.log("Old Data:", old_data);
    //             let new_data = { ...old_data, data: { ...old_data.data, imageSrc: imageUrl } };
    //             console.log("New Data:", new_data);
    //             block_data.blocks[find_index] = new_data;
    //             console.log("Block Data:", block_data);
    //             editorRef.current.isReady
    //                 .then(() => {
    //                     // editorRef.current.blocks.clear();
    //                     editorRef.current!.blocks.render(block_data);
    //                     handleIsTopicSelected();
    //                 })
    //                 .catch((err) => console.error("Editor.js is not ready yet", err));

    //             //@ts-ignore
    //             // const blockIndex = 2;
    //             // const blockId = editorRef.current.blocks?.getBlockByIndex(blockIndex)?.id;

    //             // console.log("Block ID:", blockId);

    //             // if (blockId) {
    //             //   const newData = { url: imageUrl }; // Structure this according to your editor's requirements for image block data
    //             //   editorRef.current.blocks.update(blockId, newData);
    //             // }
    //         }
    //     };
    //     handleImageSelection("imageSelected");

    //     // window.addEventListener("imageSelected", handleImageSelection);

    //     // return () => {
    //     //   window.removeEventListener("imageSelected", handleImageSelection);
    //     // };
    // }, []);

    return (
        <div id="editorjs" className="w-full max-w-full h-full border revert " />
    );
}
