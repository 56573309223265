// ColumnsTool.tsx
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import EditorJS, { API, BlockTool, BlockToolConstructorOptions, OutputData } from '@editorjs/editorjs';

interface ColumnsToolData {
  cols: OutputData[];
}

interface ColumnsToolConfig {
  tools: Record<string, any>;
  EditorJsLibrary: typeof EditorJS;
}

type EditorType = {
  numberOfColumns: number;
  cols: EditorJS[];

}

class ColumnsTool implements BlockTool {

  static get enableLineBreaks() {
    return true;
  }

  private api: API;
  private readOnly: boolean;
  private config: ColumnsToolConfig;
  private _CSS: Record<string, string>;
  private editors: EditorType;
  private colWrapper: HTMLElement | undefined;
  private data: ColumnsToolData;
  // private wrapper: HTMLElement | null;

  constructor({ data, api, config, readOnly }: BlockToolConstructorOptions<ColumnsToolData, ColumnsToolConfig>) {
    this.api = api;
    this.readOnly = readOnly;
    this.config = config || { tools: {}, EditorJsLibrary: EditorJS };

    this._CSS = {
      block: this.api.styles.block,
      wrapper: 'ce-columns-wrapper',
    }

    if (!this.readOnly) {
      this.onKeyUp = this.onKeyUp.bind(this);
    }

    this.editors = {
      numberOfColumns: 2,
      cols: [],
    };

    this.colWrapper = undefined;

    this.data = data;
    // this.wrapper = null;

    if (!Array.isArray(this.data.cols)) {
      this.data.cols = [];
      this.editors.numberOfColumns = 2;
    } else {
      this.editors.numberOfColumns = this.data.cols.length;
    }
  }

  static get isReadOnlySupported() {
    return true;
  }


  onKeyUp(e: KeyboardEvent) {
    // console.log(e)
    // console.log("heyup")
    if (e.code !== "Backspace" && e.code !== "Delete") {
      return;
    }
  }

  get CSS() {
    return {
      settingsButton: this.api.styles.settingsButton,
      settingsButtonActive: this.api.styles.settingsButtonActive,
    };
  }

  renderSettings() {
    return [
      {
        icon: "2",
        label: "2 Columns",
        onActivate: () => { this._updateCols(2) }
      },
      {
        icon: "3",
        label: "3 Columns",
        onActivate: () => { this._updateCols(3) }
      },
      {
        icon: "R",
        label: "Roll Colls",
        onActivate: () => { this._rollCols() }
      },
    ]
  }

  _rollCols() {
    // this shifts or "rolls" the columns
    const poppedCol = this.data.cols.pop();
    if (poppedCol !== undefined) {
      this.data.cols.unshift(poppedCol);
    }

    const poppedEditor = this.editors.cols.pop();
    if (poppedEditor !== undefined) {
      this.editors.cols.unshift(poppedEditor);
    }

    this._rerender();
  }

  async _updateCols(num: number) {
    // Should probably update to make number dynamic... but this will do for now
    if (num == 2) {
      if (this.editors.numberOfColumns == 3) {
        // let resp = await Swal.fire({
        //   title: "Are you sure?",
        //   text: "This will delete Column 3!",
        //   icon: "warning",
        //   showCancelButton: true,
        //   confirmButtonColor: "#3085d6",
        //   cancelButtonColor: "#d33",
        //   confirmButtonText: "Yes, delete it!",
        // });

        // if (resp.isConfirmed) {
        this.editors.numberOfColumns = 2;
        this.data.cols.pop();
        this.editors.cols.pop();
        this._rerender();
        // }
      }
    }
    if (num == 3) {
      this.editors.numberOfColumns = 3;
      this._rerender();
      // console.log(3);
    }
  }

  async _rerender() {
    await this.save();
    // console.log(this.colWrapper);

    for (let index = 0; index < this.editors.cols.length; index++) {
      this.editors.cols[index].destroy();
    }
    this.editors.cols = [];

    if (this.colWrapper) this.colWrapper.innerHTML = "";

    // console.log("Building the columns");

    for (let index = 0; index < this.editors.numberOfColumns; index++) {
      // console.log("Start column, ", index);
      let col = document.createElement("div");
      col.classList.add("ce-editorjsColumns_col");
      col.classList.add("editorjs_col_" + index);

      let editor_col_id = uuidv4();
      // console.log("generating: ", editor_col_id);
      col.id = editor_col_id;

      if (this.colWrapper) this.colWrapper.appendChild(col);

      let editorjs_instance = new this.config.EditorJsLibrary({
        defaultBlock: "paragraph",
        holder: editor_col_id,
        tools: this.config.tools,
        data: this.data.cols[index],
        readOnly: this.readOnly,
        minHeight: 50,
      });

      this.editors.cols.push(editorjs_instance);
    }
  }


  render(): HTMLElement {
    this.colWrapper = document.createElement("div");
    this.colWrapper.classList.add("ce-editorjsColumns_wrapper");



    // astops the double paste issue
    this.colWrapper.addEventListener('paste', (event) => {
      // event.preventDefault();
      event.stopPropagation();
    }, true);
    this.colWrapper.addEventListener('keydown', (event) => {

      if (event.key === "Enter") {
        event.preventDefault();
        event.stopImmediatePropagation();
        event.stopPropagation();
      }
      if (event.key === "Tab") {
        event.preventDefault();
        event.stopImmediatePropagation();
        event.stopPropagation();
      }
    });

    for (let index = 0; index < this.editors.cols.length; index++) {
      this.editors.cols[index].destroy();
    }

    // console.log(this.editors.cols);
    this.editors.cols = []; //empty the array of editors
    // console.log(this.editors.cols);

    // console.log("Building the columns");

    for (let index = 0; index < this.editors.numberOfColumns; index++) {
      // console.log("Start column, ", index);
      let col = document.createElement("div");
      col.classList.add("ce-editorjsColumns_col");
      col.classList.add("editorjs_col_" + index);

      let editor_col_id = uuidv4();
      // console.log("generating: ", editor_col_id);
      col.id = editor_col_id;

      this.colWrapper.appendChild(col);

      let editorjs_instance = new this.config.EditorJsLibrary({
        defaultBlock: "paragraph",
        holder: editor_col_id,
        tools: this.config.tools,
        data: this.data.cols[index],
        readOnly: this.readOnly,
        minHeight: 50,
        placeholder: "Enter your text here..."
      });

      this.editors.cols.push(editorjs_instance);
      // console.log("End column, ", index);
    }
    return this.colWrapper;
  }

  async save() {
    if (!this.readOnly) {
      // console.log("Saving");
      for (let index = 0; index < this.editors.cols.length; index++) {
        let colData = await this.editors.cols[index].save();
        this.data.cols[index] = colData;
      }
    }
    return this.data;
  }

  static get toolbox() {
    return {
      icon: "<svg>...</svg>",
      title: "Columns",
    };
  }
}

export default ColumnsTool;
