import React, { memo, useState } from "react";
import Layout from "../Layout";
import { useDrop } from "react-dnd";
import { useDispatch, useSelector } from "react-redux";
import { addBlocks, setBlocks } from "../../features/blocks/bockSlice";
import { v4 as uuidv4 } from "uuid";
import Widgets from "../Widgets";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { OverflowMenuVertical } from "@carbon/icons-react";
import { setEditorState } from "../../features/editorState/editorStateSlice";
import { ContentState, EditorState, convertFromHTML } from "draft-js";

const DragDropWidget = memo((props) => {
  const blocks = useSelector((state: any) => state.block.blocks);
  const sidebar = useSelector((state: any) => state.sidebar);
  const dispatch = useDispatch();

  const [{ isOver }, dropRef] = useDrop({
    accept: [
      "simple-text",
      "text-with-heading",
      "two-col-text",
      "three-col-text",
      "text-with-hyperlink",
    ],
    drop: (item: any, monitor) => {
      // setId(item.ID);
      const blockId = uuidv4();
      const value =
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.";
      dispatch(
        addBlocks({ serialId: item.ID, blockId })
        // setEditorState({
        //   blockId,
        //   editorState: EditorState.createWithContent(
        //     ContentState.createFromBlockArray(
        //       convertFromHTML(value).contentBlocks
        //     )
        //   ),
        // })
      );
      dispatch(
        setEditorState({
          blockId,
          editorState: EditorState.createWithContent(
            ContentState.createFromBlockArray(
              convertFromHTML(value).contentBlocks
            )
          ),
        })
      );
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });

  function handleOnDragEnd(result: any) {
    if (!result.destination) return;

    const items = Array.from(blocks);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    dispatch(setBlocks(items));
  }

  return (
    <Layout type="dragdropwidget">
      {(callback) => 
      <div
        className={`${
          sidebar.leftPanelCollapsed
            ? sidebar.rightPanelCollapsed
              ? "col-span-10"
              : "col-span-8"
            : sidebar.rightPanelCollapsed
            ? "col-span-8"
            : "col-span-6"
        } p-4 w-full text-secondary overflow-y-scroll max-h-[calc(100vh-56px)] no-scrollbar`}
      >
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="blocks" direction="vertical">
            {(provided: any) => (
              <ul {...provided.droppableProps} ref={provided.innerRef}>
                <li className="h-full ">
                  <div
                    ref={dropRef}
                    className={`border ${isOver && "border-green-400 bg-green-200/20 h-40"}`}
                  ></div>
                </li>
                {blocks.map(
                  (
                    block: { blockId: number; serialId: number },
                    idx: number
                  ) => {
                    return (
                      <Draggable
                        key={block.blockId}
                        index={idx}
                        draggableId={(block.blockId).toString()}
                      >
                        {(provided: any) => (
                          <li
                            ref={provided.innerRef}
                            className="h-full flex w-full"
                            {...provided.draggableProps}
                          >
                            <div
                              className="h-full flex items-center justify-center bg-black text-white"
                              {...provided.dragHandleProps}
                            >
                              <OverflowMenuVertical size={24} />
                            </div>
                            <Widgets block={block} />
                          </li>
                        )}
                      </Draggable>
                    );
                  }
                )}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>
      </div>}
    </Layout>
  );
});

export default DragDropWidget;
