import { DraftInlineStyle, convertFromRaw, CompositeDecorator, ContentBlock, ContentState, EditorState, Modifier, RichUtils, convertFromHTML, convertToRaw, DraftStyleMap, RawDraftContentState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import React, { useState, useEffect, useRef, useCallback } from 'react'
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import htmlToDraft from 'html-to-draftjs';

type Props = {
  onChange: (content: string, contentLength: number) => void;
  value?: string;
  placeholder?: string;
}

type ErrorResult = {
  error: Boolean;
  message: String;
  timestamp: Number;
};

export default function RichTextEditor({
  onChange,
  value = "",
  placeholder,
}: Props) {
  const [error, setError] = useState<ErrorResult>();
  const [text, setText] = useState<string>("");
  const [showToolbar, setShowToolbar] = useState(true); // Initially show toolbar
  const [selectedText, setSelectedText] = useState("");

  //sir
  //const [editorState, setEditorState] = useState<EditorState>(() => value ? EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(value).contentBlocks)) : EditorState.createEmpty());
  const [editorState, setEditorState] = useState(() => {
    // Check if there is any saved editor state in localStorage
    // const savedState = localStorage.getItem('editorState');
    // if (savedState) {
    //   const rawState = JSON.parse(savedState);
    //   return EditorState.createWithContent(convertFromRaw(rawState));
    // }
    // return EditorState.createEmpty();

    // const contentState = convertFromHTML(value);
    console.log("value", value);
    if (value) {
      const blocksFromHtml = htmlToDraft(value);
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
      return EditorState.createWithContent(contentState);
    } else {
      return EditorState.createEmpty();
    }
  });

  // Initialize editorState using useEffect with empty dependency array
  // useEffect(() => {
  //   if (value) {
  //     const contentState = convertFromRaw(JSON.parse(value));
  //     setEditorState(EditorState.createWithContent(contentState));
  //   } else {
  //     setEditorState(EditorState.createEmpty());
  //   }
  // }, []);

  const editor = useRef<Editor>(null);

  useEffect(() => {
    const selectionState = editorState.getSelection();
    const anchorKey = selectionState.getAnchorKey();
    const currentContent = editorState.getCurrentContent();
    const currentContentBlock = currentContent.getBlockForKey(anchorKey);
    const start = selectionState.getStartOffset();
    const end = selectionState.getEndOffset();
    const selectedText = currentContentBlock.getText().slice(start, end);
    setSelectedText(selectedText);
  }, [editorState.getSelection()]);

  const [{ content, wordCount }, setContent] = useState({
    content: "",
    wordCount: 0,
  });

  // useEffect(() => {
  //   console.log("value", value);
  //   // Update editor state when the provided value changes
  // // Convert HTML to ContentState
  // const contentState = convertFromHTML(value);
  // if (contentState) {
  //   const editorContentState = ContentState.createFromBlockArray(contentState.contentBlocks, contentState.entityMap);
  //   setEditorState(EditorState.createWithContent(editorContentState));
  // } else {
  //   setEditorState(EditorState.createEmpty());
  // }
  // }, [value]);

  useEffect(() => {
    // const contentState = editorState.getCurrentContent();
    // const content = JSON.stringify(convertToRaw(contentState));
    if (editorState.getCurrentContent().getPlainText().length > 5000) {
      setError({
        error: true,
        message: "Content length exceeded 5000 chars",
        timestamp: Date.now(),
      });
      setEditorState(EditorState.undo(editorState));
    }
    const content = editorState.getCurrentContent();
    // const rawContentState = convertToRaw(content);
    // localStorage.setItem('editorState', JSON.stringify(rawContentState));

    // const htmlBlocks = rawContentState.blocks.map(block => convertRawBlockToHtml(block));

    // const htmlContent = htmlBlocks.join('');

    // console.log(htmlContent + "FINAL CONTENT" + JSON.stringify(rawContentState));

    const options = {
      blockRenderer,
      inlineStyles: generateStyleMap(customToolbar),
    };

    console.log('Generated style map:', options);

    const html: string = stateToHTML(content, options);
    console.log('Generated HTML:', html);

    onChange(html, content.getPlainText().length)

    const selection = editorState.getSelection();
    setShowToolbar(selection.isCollapsed()); // Toggle toolbar based on text selection

    if (`${stateToHTML(editorState.getCurrentContent(), {
      // @ts-ignore: null is not assigned to defaultBlockTag as its type
      defaultBlockTag: null,
    })}` !== '<br>')
      //onChange(htmlContent, content.getPlainText().length);
      console.log("rendering...")
  }, [editorState])

  function blockRenderer(block: ContentBlock): { style: { [key: string]: string } } | undefined {
    const type = block.getType();
    console.log(type + "type")
    if (type.startsWith('textalign-')) {
      const textAlign = type.replace('textalign-', '');
      return { style: { textAlign } };
    }
  }

  function generateStyleMap(customToolbar: CustomToolbar): { [key: string]: { style: { [key: string]: string } } } {
    const styleMap: { [key: string]: { style: { [key: string]: string } } } = {};

    // Handle font sizes
    for (const fontSize of customToolbar.fontSize.options) {
      styleMap[`fontsize-${fontSize}`] = { style: { fontSize: `${fontSize}px` } };
    }

    // Handle font families
    for (const fontFamily of customToolbar.fontFamily.options) {
      styleMap[`fontfamily-${fontFamily}`] = { style: { fontFamily } };
    }

    // Handle colors
    for (const color of customToolbar.colorPicker.colors) {
      styleMap[`color-${color}`] = { style: { color } };
    }

    // Handle background colors
    for (const bgcolor of customToolbar.colorPicker.colors) {
      styleMap[`bgcolor-${bgcolor}`] = { style: { backgroundColor: bgcolor } };
    }

    // Handle text alignment
    for (const textAlign of customToolbar.textAlign.options) {
      styleMap[`textalign-${textAlign}`] = { style: { textAlign } };
    }

    // Add more styles as needed

    return styleMap;
  }

  interface InlineStyleRange {
    offset: number;
    length: number;
    style: string;
    styleValue?: string
  }

  interface RawContentState {
    blocks: {
      text: string;
      type: string;
      inlineStyleRanges: InlineStyleRange[];
    }[];
    entityMap?: any;
  }

  function convertRawBlockToHtml(block: RawContentState['blocks'][0]): string {
    let html = '';

    switch (block.type) {
      case 'ordered-list-item':
        html += '<ol>';
        break;
      case 'unordered-list-item':
        html += '<ul>';
        break;

      default:
        break;
    }
    if (block.type === 'ordered-list-item' || block.type === 'unordered-list-item') {
      html += `<li>${addSpanToText(block.text, block.type, block.inlineStyleRanges)}</li>`;
    } else
      if (!(block.text).trim()) {
        html += `<p></br></p>`;
      } else {
        html += `<p>${addSpanToText(block.text, block.type, block.inlineStyleRanges)}</p>`;
      }
    // Close the appropriate HTML tag based on block type
    switch (block.type) {
      case 'ordered-list-item':
        html += '</ol>';
        break;
      case 'unordered-list-item':
        html += '</ul>';
        break;
      default:
        break;
    }
    return html;
  }

  function addSpanToText(
    text: string,
    blockType: string,
    inlineStyleRanges: InlineStyleRange[]
  ): string {
    let result = text;
    let styles: string[] = [];

    // Check if any styles have been applied
    if (inlineStyleRanges.length > 0) {
      // Iterate through each inline style range
      inlineStyleRanges.forEach(({ offset, length, style }) => {
        // Check if the current style is applied to the selected text
        switch (style) {
          case 'BOLD':
            styles.push('font-weight: bold;');
            break;
          case 'ITALIC':
            styles.push('font-style: italic;');
            break;
          case 'UNDERLINE':
            // Check if STRIKETHROUGH is also present
            const hasStrikethrough = inlineStyleRanges.some(s => s.style === 'STRIKETHROUGH');
            // Only add underline if strikethrough is not present
            if (hasStrikethrough) {
              styles.push('text-decoration: underline line-through;');
            } else {
              styles.push('text-decoration: underline;');
            }
            break;
          case 'STRIKETHROUGH':
            const hasUnderline = inlineStyleRanges.some(s => s.style === 'UNDERLINE');
            // Only add strikethrough if undeline is not present
            if (hasUnderline) {
              styles.push('text-decoration: underline line-through;');
            } else {
              styles.push('text-decoration: line-through;');
            }
            break;
          default:
            if (style.startsWith('fontsize-')) {
              const fontSize = parseInt(style.split('-')[1], 10);
              styles.push("font-size:" + fontSize + "px;")
            }
            if (style.startsWith('fontfamily-')) {
              const fontFamily = style.split('-')[1];
              console.log(fontFamily + "fontFamily")
              styles.push("font-family:" + fontFamily + ";")
            }
            if (style.startsWith('color-')) {
              const textColor = style.split('-')[1];
              console.log(textColor + "textColor")
              styles.push("color:" + textColor + ";")
            }
            if (style.startsWith('bgcolor-')) {
              const bgColor = style.split('-')[1];
              console.log(bgColor + "bgColor")
              styles.push("background-color:" + bgColor + ";")
            }
            break;
          // Handle other inline styles as needed
        }
        if (offset >= 0 && offset + length <= text.length) {
          // Apply the style to the selected text
          const selectedText = text.substr(offset, length);
          const styleAttribute = styles.join(" ");
          result = result.replace(
            selectedText,
            `<span style="${styleAttribute}">${selectedText}</span>`
          );
        }
      });
    }

    return result;
  }

  // Function to convert HTML string to Draft.js ContentState
  function convertHtmlToContentState(htmlContent: string): RawDraftContentState {
    const doc = new DOMParser().parseFromString(htmlContent, 'text/html');
    const contentBlocks: any[] = [];

    Array.from(doc.body.childNodes).forEach(node => {
      let type = 'unstyled';
      let text = '';
      const inlineStyleRanges: InlineStyleRange[] = [];

      switch (node.nodeName.toLowerCase()) {
        case 'p':
          type = 'unstyled';
          text = node.textContent || '';
          break;
        case 'span':
          type = 'unstyled';
          text = node.textContent || '';
          const spanNode = node as HTMLElement;
          Array.from(spanNode.style).forEach(style => {
            let styleValue = spanNode.style.getPropertyValue(style);
            console.log(styleValue + "styleValue")
            switch (style) {
              case 'font-weight':
                inlineStyleRanges.push({ offset: 0, length: text.length, style: 'BOLD' });
                break;
              case 'font-style':
                inlineStyleRanges.push({ offset: 0, length: text.length, style: 'ITALIC' });
                break;
              case 'text-decoration':
                if (styleValue.includes('underline')) {
                  inlineStyleRanges.push({ offset: 0, length: text.length, style: 'UNDERLINE' });
                }
                if (styleValue.includes('line-through')) {
                  inlineStyleRanges.push({ offset: 0, length: text.length, style: 'STRIKETHROUGH' });
                }
                break;
              case 'font-size':
                inlineStyleRanges.push({ offset: 0, length: text.length, style: `FONTSIZE-${styleValue}` });
                break;
              case 'font-family':
                inlineStyleRanges.push({ offset: 0, length: text.length, style: `FONTFAMILY-${styleValue}` });
                break;
              case 'color':
                inlineStyleRanges.push({ offset: 0, length: text.length, style: `COLOR-${styleValue}` });
                break;
              case 'background-color':
                inlineStyleRanges.push({ offset: 0, length: text.length, style: `BGCOLOR-${styleValue}` });
                break;
              // Handle other styles as needed
              default:
                // Do nothing for unsupported styles
                break;
            }
          });
          break;
        // Add more cases to handle other HTML elements as needed
      }

      contentBlocks.push({
        type,
        text,
        inlineStyleRanges,
      });
    });

    return {
      blocks: contentBlocks,
      entityMap: {},
    };
  }

  const onEditorStateChange = function (editorState: EditorState) {
    setEditorState(editorState);
    const { blocks } = convertToRaw(editorState.getCurrentContent());
    let text = blocks.reduce((acc: string, item: any) => {
      acc += item.text + "\n"; // Adding newline character for each block
      return acc;
    }, "");
    setText(text)

  };

  interface CustomToolbar {
    options: string[];
    inline: {
      options: string[];
    };
    list: {
      options: string[];
    };
    fontSize: {
      options: number[];
    };
    fontFamily: {
      options: string[];
    };
    textAlign: {
      options: string[];
    };
    colorPicker: {
      colors: string[];
    };
  }


  const customToolbar = {
    options: [
      "inline",
      "fontSize",
      "fontFamily",
      "list",
      "textAlign",
      "colorPicker",
    ],
    inline: {
      options: ["bold", "italic", "underline", "strikethrough"],
    },
    list: {
      options: ["unordered", "ordered"],
    },
    fontSize: {
      options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
    },
    fontFamily: {
      options: [
        "Arial",
        "Georgia",
        "Impact",
        "Tahoma",
        "Times New Roman",
        "Verdana",
      ],
    },
    textAlign: {
      options: ["left", "center", "right"],
    },
    colorPicker: {
      colors: [
        "rgb(97,189,109)",
        "rgb(26,188,156)",
        "rgb(84,172,210)",
        "rgb(44,130,201)",
        "rgb(147,101,184)",
        "rgb(71,85,119)",
        "rgb(204,204,204)",
        "rgb(65,168,95)",
        "rgb(0,168,133)",
        "rgb(61,142,185)",
        "rgb(41,105,176)",
        "rgb(85,57,130)",
        "rgb(40,50,78)",
        "rgb(0,0,0)",
        "rgb(247,218,100)",
        "rgb(251,160,38)",
        "rgb(235,107,86)",
        "rgb(226,80,65)",
        "rgb(163,143,132)",
        "rgb(239,239,239)",
        "rgb(255,255,255)",
        "rgb(250,197,28)",
        "rgb(243,121,52)",
        "rgb(209,72,65)",
        "rgb(184,49,47)",
        "rgb(124,112,107)",
        "rgb(209,213,216)",
      ],
    },
  };

  return (
    <div className="relative">
      <div style={{ height: "300px", border: "1px solid black", overflow: "auto" }} className='ppg-rte'>

        <Editor
          editorState={editorState}
          toolbarClassName="custom-toolbar"
          wrapperClassName="wrapperClassName"
          editorClassName="editorClassName"
          toolbar={customToolbar}
          toolbarHidden={showToolbar}
          placeholder={placeholder}
          onBlur={() => setShowToolbar(false)}
          onEditorStateChange={onEditorStateChange}

          mention={{
            separator: " ",
            trigger: "@",
            suggestions: [
              { text: "APPLE", value: "apple" },
              { text: "BANANA", value: "banana", url: "banana" },
              { text: "CHERRY", value: "cherry", url: "cherry" },
              { text: "DURIAN", value: "durian", url: "durian" },
              { text: "EGGFRUIT", value: "eggfruit", url: "eggfruit" },
              { text: "FIG", value: "fig", url: "fig" },
              { text: "GRAPEFRUIT", value: "grapefruit", url: "grapefruit" },
              { text: "HONEYDEW", value: "honeydew", url: "honeydew" }
            ]
          }}
          toolbarStyle={{ position: 'absolute', 'z-index': '99', 'box-shadow': '0px 0px 5px 2px', top: placeholder === 'Description Text' || placeholder === 'Transcript Text' ? '-75px' : '-50px', width: placeholder === 'Description Text' || placeholder === 'Transcript Text' ? '100%': '50%', 'margin-left': placeholder === 'Description Text' || placeholder === 'Transcript Text' ? '0': '260px',}}
        />
      </div>
    </div>
  );
}
