import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import DownArrow from "../../assets/down.png";
import { setSaveChanges } from "src/features/saveChanges/saveChangesSlice";
import axios from "axios";
import TemplateHeading from "src/utils/TextInput/TemplateHeading";
import { setSelectFile } from "src/features/fileFolder/fileFolderSlice";
import TextArea from "src/utils/TextInput/TextArea";
import UploadButton from "src/utils/UploadButton";
import UploadDropdowns from "../UploadDropdowns";
import CreatableSelectDropdown from "src/utils/CreatableSelectDropdown";
import InputText from "src/utils/TextInput/InputText";
import { setPageState } from "src/features/templatePageState/templatePageStateSlice";
import LocateSectionDrawer from "src/utils/LocateSectionDrawer";
import { cn } from "src/components/lib/utils";
import { setLog } from "src/features/errorLog/errorLogSlice";

function LocationDiagram() {
  const [title, setTitle] = useState<string>("");
  const [sections, setSections] = useState<Array<any>>([]);
  const [equipments, setEquipments] = useState<Array<any>>([]);
  const [locations, setLocations] = useState<Array<any>>([]);
  const [documents, setDocuments] = useState<Array<any>>([]);
  const [file, setFile] = useState<any>(null);
  const [aircraftDocuments, setAircraftDocuments] = useState<Array<any>>([]);
  const [content, setContent] = useState<any>();
  const [saving, setSaving] = useState(false);
  const { accessToken } = useSelector((state: any) => state.accessToken);
  // const { aircrafts, users } = useSelector((state: any) => state.aircraftsUsers);
  const [users, setUsers] = useState<any>([]);
  const [aircrafts, setAircrafts] = useState<any>([]);

  const [openAircraft, setOpenAircraft] = useState(false);
  const [selectAircraft, setSelectAircraft] = useState<any>(null);

  const [openLocateSections, setOpenLocateSections] = useState(false);

  const [filteredOptions, setFilteredOptions] = useState<Array<any>>([]);

  const [state, setState] = useState<any>(null);
  const { pageState } = useSelector((state: any) => state.templatePageState);

  const [sectionLocations, setSectionLocations] = useState<Array<any>>([]);
  const [selectedSectionLocation, setSelectedSectionLocation] =
    useState<any>(null);
  const [selectedHeight, setSelectedHeight] = useState("");
  const [selectedStart, setSelectedStart] = useState("");
  const [selectedPosition, setSelectedPosition] = useState("");
  const [bgFile, setbgFile] = useState<any>(null);

  const dispatch = useDispatch();

  const {
    fileFolder: { file: bgfile },
  } = useSelector((state: any) => state.fileFolder);

  const { templateId } = useParams();

  useEffect(() => {
    console.log(pageState, state);
    if (pageState) {
      if (pageState.sections) setSections(pageState.sections);
      if (pageState.equipments) setEquipments(pageState.equipments);
      if (pageState.locations) setLocations(pageState.locations);
      if (pageState.selectAircraft) {
        setSelectAircraft(pageState.selectAircraft);
      }
      if (
        pageState.file &&
        !pageState.openCreateModal &&
        !pageState.openEditModal
      )
        setFile(pageState.file);
      if (
        pageState.outerFile &&
        (pageState.openCreateModal || pageState.openEditModal)
      )
        setFile(pageState.outerFile);
      setState(pageState);
      dispatch(setPageState(null));
    }
  }, [selectAircraft]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      dispatch(setSaveChanges(true));
      axios
        .post(
          `${process.env.REACT_APP_SERVER_BASE_URL}/api/v1/template/save/${templateId}`,
          { content },
          {
            headers: { Authorization: `Bearer ${accessToken}` },
          }
        )
        .then((response) => {
          dispatch(setSelectFile({ file: null }));
          dispatch(setSaveChanges(false));
        })
        .catch((error) => {
          console.error("Auto-save failed:", error);
          dispatch(setLog({ mode: "failure", message: error }));
          setSaving(false);
          dispatch(setSaveChanges(false));
        });
    }, 5000);
    return () => clearTimeout(timeoutId);
  }, [content]);

  useEffect(() => {
    console.log("documents: ", aircraftDocuments);
    handleContentChange();
  }, [
    sections,
    locations,
    equipments,
    file,
    documents,
    aircraftDocuments,
    bgFile,
  ]);

  useEffect(() => {
    console.log(selectAircraft);
  }, [selectAircraft]);

  useEffect(() => {
    console.log(selectedSectionLocation, selectedHeight);
  }, [selectedSectionLocation]);

  useEffect(() => {
    getContent();
  }, []);

  async function getContent() {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_BASE_URL}/api/v1/template/content/${templateId}`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );
      if (response && response.data) {
        console.log("template details:", response.data.data);
        // setTitle(response.data.data.content.title);
        setAircraftDocuments(response.data.data.content.aircraftDocuments);
        // setSections(response.data.data.content.section);
        setAircrafts(response.data.data.content.aircrafts);
        setUsers(response.data.data.content.users);
        setbgFile(response.data.data.content.bgfile);
        // setFile(response.data.data.content.file);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handleContentChange = () => {
    console.log(
      "here is our document: ",
      aircraftDocuments,
      sections,
      locations,
      equipments,
      file,
      documents
    );

    const newAircraftDocs = aircraftDocuments.map((item: any) => {
      if (item.aircraftId === selectAircraft?.id) {
        return {
          ...item,
          aircraftDoc: { sections, locations, equipments, file, documents },
        };
      }
      return item;
    });

    console.log(newAircraftDocs);
    if (bgfile) setbgFile(bgfile);
    const newContent = {
      aircraftDocuments: newAircraftDocs,
      bgfile: bgFile,
    };
    setContent(newContent);
  };

  function handleAircraftSelection(aircraft: any) {
    setSelectAircraft(null);
    setSelectAircraft({
      id: aircraft.id,
      name: aircraft.name,
    });
    setOpenAircraft(false);

    const filteredAircraftDoc = aircraftDocuments.filter(
      (item: any) => item.aircraftId === aircraft.id
    )[0];

    console.log(filteredAircraftDoc);
    if (filteredAircraftDoc) {
      setSections(filteredAircraftDoc.aircraftDoc.sections);
      setLocations(filteredAircraftDoc.aircraftDoc.locations);
      setEquipments(filteredAircraftDoc.aircraftDoc.equipments);
      setDocuments(filteredAircraftDoc.aircraftDoc.documents);
      setFile(filteredAircraftDoc.aircraftDoc.file);
      return;
    }

    const newAircraftDoc = {
      id: aircraft.id,
      sections: [],
      locations: [],
      equipments: [],
      documents: [],
      file: null,
    };
    console.log(newAircraftDoc);

    return setAircraftDocuments([
      ...aircraftDocuments,
      {
        aircraftId: aircraft.id,
        _id: new Date().getTime(),
        aircraftDoc: newAircraftDoc,
      },
    ]);
  }

  const menuRef = React.createRef<HTMLDivElement>();

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!menuRef?.current?.contains(event.target as Node)) {
        setOpenAircraft(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      // Clean up the event listener when the component unmounts
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuRef]);

  function handleSearch(searchTerm: string) {
    const searchResults = aircrafts?.filter((data: any) => {
      const optionName = data.name.toLowerCase();
      const searchTermChars = searchTerm.toLowerCase().split("");

      let searchIndex = 0;
      for (const char of optionName) {
        if (char === searchTermChars[searchIndex]) {
          searchIndex++;
        }

        if (searchIndex === searchTermChars.length) {
          return true; // All characters in the search term found in order
        }
      }

      return false;
    });

    if (searchResults) setFilteredOptions(searchResults);
  }

  return (
    <div className=" ">
      <div className="">
        <TemplateHeading title="Location Diagram" />

        <div className="grid grid-cols-3 mx-[0.49rem] items-center">
          <div className="col-span-1 font-[600]  text-[1rem] text-[#242424] leading-normal">
            Background Image
          </div>
          <div className="col-span-2 flex space-x-[0.75rem] items-center">
            <UploadButton
              name="bgImageFile"
              type="image"
              handleUploadFromDevice={(file) => {
                setbgFile(file);
                // console.log(file);
              }}
            />
            <UploadDropdowns
              name="bgImageFile"
              type="image"
              handleCropFile={(cropFile) => setbgFile(cropFile)}
              File={bgFile}
            />
          </div>
        </div>

        <div className="grid grid-cols-3 mt-[1.25rem] mx-[0.49rem] items-center">
          <div className="col-span-1 font-[600]  text-[1rem] text-[#242424] leading-normal">
            Select Aircraft
          </div>
          <div ref={menuRef} className="relative col-span-2 flex flex-col">
            <button
              onClick={() => {
                handleSearch("");
                setOpenAircraft(!openAircraft);
              }}
              className="flex w-[11.875rem] bg-[#AB0273] px-[0.63rem] py-[0.3125rem] rounded-[1.25rem] border border-[#AB0273] items-center justify-between"
            >
              <span className="text-[1rem] font-[600]  text-[#FFFFFF] leading-normal">
                Select Aircraft
              </span>
              <span>
                {/* <img src={DownArrow} alt="logo" /> */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                >
                  <path
                    d="M26 12L16 22L6 12"
                    stroke="white"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
            </button>

            {openAircraft && (
              <div className="absolute top-[2.87rem] z-10 w-[11.875rem] border-2 border-[#000000]/30 bg-[#F5F5F5]  rounded-[1.8rem] px-[1.12rem] py-[0.7rem]">
                <input
                  type="text"
                  placeholder="Search..."
                  className="flex w-full bg-[#F5F5F5]  text-[#242424] text-[0.875rem] font-[500]  px-[0.58rem] pb-[0.94rem] text-left  items-center outline-none"
                  // value={searchTerm}
                  onChange={(e) => handleSearch(e.target.value)}
                />
                <div className="max-h-[151.5px] overflow-y-scroll no-scrollbar">
                  {filteredOptions?.map((aircraft: any) => (
                    <>
                      <button
                        onClick={() => {
                          handleAircraftSelection(aircraft);
                        }}
                        className="flex w-full text-[#242424] text-[1rem] font-[500]  px-[0.58rem] py-[0.94rem] text-left border-t-[0.0625rem] border-[#2C2C2C]  items-center"
                      >
                        <div className="overflow-hidden w-[10.875rem]">
                          {aircraft.name}
                        </div>
                      </button>
                    </>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>

        {selectAircraft ? (
          <div className="w-full px-[0.49rem]">
            <div className="flex items-center space-x-2 my-[0.56rem]">
              <span className="text-[#AB0273] font-bold text-lg">
                {selectAircraft.name}
              </span>
              <div className="grow h-[1px] bg-[#242424]"></div>
            </div>

            <div className="w-full space-y-[1.25rem]">
              <div className="grid grid-cols-3 items-center">
                <div className="col-span-1 font-[600]  text-[1rem] text-[#242424] leading-normal">
                  Aircraft Location image
                </div>
                <div className="col-span-2 flex space-x-[0.75rem] items-center">
                  <UploadButton
                    type="image"
                    pageState={{
                      // ...state,
                      file,
                      selectAircraft,
                      sections,
                      equipments,
                      locations,
                    }}
                    handleUploadFromDevice={(file) => {
                      setFile(file);
                      console.log(file);
                    }}
                  />
                  <UploadDropdowns
                    File={file}
                    handleCropFile={(cropFile) => setFile(cropFile)}
                  />
                </div>
              </div>

              <div className="grid grid-cols-3 items-center pt-[1rem]">
                <div className="col-span-1 font-[600]  text-[1rem] text-[#242424] leading-normal">
                  Add Section
                </div>
                <div className="col-span-2">
                  <CreatableSelectDropdown
                    pageState={
                      state
                        ? {
                            ...state,
                            outerFile: file,
                            file:
                              state.openCreateModal || state.openEditModal
                                ? state.file
                                : null,
                          }
                        : null
                    }
                    onHandleState={() => setState(null)}
                    outerFile={file}
                    sections={sections}
                    equipments={equipments}
                    locations={locations}
                    AircraftId={selectAircraft.id}
                    selectAircraft={selectAircraft}
                    AddMenu="Add Section"
                    CreateNewMenu="Create new section"
                    onEditedData={(data: any) => {
                      const newLocations = locations.map((item: any) => {
                        if (item.section?.id === data?.id) {
                          return { ...item, section: data };
                        }
                        return item;
                      });
                      setLocations(newLocations);
                    }}
                    onDataChange={(data: any[]) => {
                      setSections(data);
                      console.log(data);
                    }}
                    allData={sections}
                  />
                </div>
              </div>

              <div className="grid grid-cols-3 items-center pt-[1rem]">
                <div className="col-span-1 font-[600]  text-[1rem] text-[#242424] leading-normal">
                  Add Equipments
                </div>
                <div className="col-span-2">
                  {/* * */}
                  {/* * */}
                  {/* * */}
                  {/* * */}
                  {/* * */}
                  {/* * */}
                  {/*Note: changes are not done in the backend (only from the frontend) */}
                  {/* * */}
                  {/* * */}
                  {/* * */}
                  {/* * */}
                  {/* * */}
                  {/* * */}

                  <CreatableSelectDropdown
                    pageState={
                      state
                        ? {
                            ...state,
                            outerFile: file,
                            file:
                              state.openCreateModal || state.openEditModal
                                ? state.file
                                : null,
                          }
                        : null
                    }
                    onHandleState={() => setState(null)}
                    outerFile={file}
                    sections={sections}
                    equipments={equipments}
                    locations={locations}
                    AircraftId={selectAircraft.id}
                    selectAircraft={selectAircraft}
                    AddMenu="Add Equipments"
                    CreateNewMenu="Create new equipments"
                    // onEditedData={(data: any) => {
                    //   const newLocations = locations.map((item: any) => {
                    //     if(item.equipments === data.id) {
                    //       return {...item, equipments: {data}}
                    //     }
                    //     return item
                    //   })
                    //   setLocations(newLocations)
                    // }}
                    onDataChange={(data: any) => setEquipments(data)}
                    allData={equipments}
                  />
                </div>
              </div>

              <div className="grid grid-cols-3 items-center pt-[1rem]">
                <div className="col-span-1 font-[600]  text-[1rem] text-[#242424] leading-normal">
                  Add Location
                </div>
                <div className="col-span-2">
                  <CreatableSelectDropdown
                    pageState={state}
                    outerFile={file}
                    onHandleState={() => setState(null)}
                    sections={sections}
                    equipments={equipments}
                    locations={locations}
                    AircraftId={selectAircraft.id}
                    selectAircraft={selectAircraft}
                    AddMenu="Add Location"
                    CreateNewMenu="Create new location"
                    onDataChange={(data: any) => setLocations(data)}
                    allData={locations}
                    documents={documents}
                    onHandleDocuments={(documents) => setDocuments(documents)}
                  />
                </div>
              </div>

              <div className="grid grid-cols-3 items-center font-[600] pt-[1rem] ">
                <div className="col-span-1 text-[1rem] text-[#242424] leading-normal">
                  Locate Sections
                </div>
                <div className="col-span-2">
                  <button
                    className="px-6 py-2 text-[1rem] text-[#FFFFFF] bg-[#AB0273] rounded-[1.875rem]"
                    onClick={() => setOpenLocateSections(true)}
                  >
                    View
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="grow flex flex-col whitespace-nowrap items-center justify-center">
            <div className="w-full h-[1px] bg-[#242424] my-[1.25rem]"></div>
            <div className="text-[1rem] text-[#242424] font-[500]  leading-normal mt-52">
              You haven't selected any aircraft yet.
            </div>
          </div>
        )}
      </div>
      {selectAircraft && (
        <LocateSectionDrawer
          onOpened={openLocateSections}
          onClose={() => setOpenLocateSections(false)}
          title={selectAircraft.name}
        >
          <>
            <div className="w-full h-full overflow-y-scroll no-scrollbar flex justify-center ">
              {/* <div>Aircraft Location Diagram Image</div> */}
              <div className="relative w-[45rem] flex justify-center">
                {file && (
                  <img
                    className="h-[723px] aspect-auto"
                    src={file.url}
                    alt="img"
                  />
                )}
                {selectedSectionLocation && (
                  <div
                    style={{
                      height: selectedHeight || "0px",
                      top: selectedStart || "0px",
                    }}
                    className={cn(
                      `w-[306px] absolute flex flex-col`,
                      `${selectedPosition}`
                    )}
                  >
                    <div
                      className={`w-full flex ${
                        selectedSectionLocation.section?.position === "Left"
                          ? "justify-end"
                          : "justify-start"
                      } `}
                    >
                      <span className="bg-[#AB0273] text-white px-2.5 py-[2.5px] text-xl font-bold uppercase">
                        {selectedSectionLocation.section?.name}
                      </span>
                    </div>
                    <div
                      className={`bg-[#D9D9D966] w-full h-full flex flex-col space-y-2 ${
                        selectedSectionLocation.section?.position === "Left"
                          ? "items-start"
                          : "items-end"
                      } px-2.5 py-1.5`}
                    >
                      {selectedSectionLocation.locations.map(
                        (location: any) => {
                          return (
                            <ul
                              className="font-semibold text-white list-disc pl-4"
                              key={location.id}
                            >
                              <li>{location.name}</li>
                            </ul>
                          );
                        }
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </>
          <>
            <SectionLocationContainer
              sections={sections}
              locations={locations}
              onHandleSections={(sections) => setSections(sections)}
              onEditSection={(section) => {
                const newLocations = locations.map((item: any) => {
                  if (item.section?.id === section?.id) {
                    return { ...item, section };
                  }
                  return item;
                });
                setLocations(newLocations);
              }}
              onHandleSelectedSectionLocation={(
                selectedSectionLocation: any
              ) => {
                setSelectedSectionLocation(selectedSectionLocation);
                setSelectedHeight(
                  `${
                    selectedSectionLocation.section?.endY -
                    selectedSectionLocation.section?.startY
                  }px`
                );
                setSelectedStart(`${selectedSectionLocation.section.startY}px`);
                setSelectedPosition(
                  selectedSectionLocation.section?.position === "Left"
                    ? "left-0"
                    : "right-0"
                );
              }}
              onHandleDone={() => setOpenLocateSections(false)}
            />
          </>
        </LocateSectionDrawer>
      )}
    </div>
  );
}

type Position = {
  id: number | null;
  value: string | number;
};

const SectionLocationContainer = ({
  sections,
  locations,
  onHandleSections,
  onEditSection,
  onHandleSelectedSectionLocation,
  onHandleDone,
}: {
  sections: any[];
  locations: any[];
  onHandleSections: (sections: any[]) => void;
  onEditSection: (editedSection: any) => void;
  onHandleSelectedSectionLocation: (selectedSectionLocation: any) => void;
  onHandleDone: () => void;
}) => {
  const [sectionLocations, setSectionLocations] = useState<Array<any>>([]);
  const [selectSection, setSelectSection] = useState<number | null>(null);
  const [position, setPosition] = useState<string | number>(-1);
  const [startY, setStartY] = useState<number | null>(null);
  const [endY, setEndY] = useState<number | null>(null);

  useEffect(() => {
    sectionlocation();
  }, [locations]);

  function sectionlocation() {
    const arr = locations.reduce((acc, cur) => {
      const section = cur.section;
      const arr1 = acc[section?.id] || { section, locations: [] };
      // const found = acc.find(a => a === `${section.id}`);
      acc[`${section?.id}`] = { section, locations: [...arr1.locations, cur] };
      //  console.log(acc)
      return acc;
    }, {});

    console.log(Object.values(arr));
    setSectionLocations(Object.values(arr));
  }

  function handleSave(
    id: number,
    position: string | number,
    startY: number | null,
    endY: number | null
  ) {
    let editedSection = null;
    console.log(id, position, startY, endY);
    const newSections = sections.map((section) => {
      if (section?.id === id) {
        editedSection = { ...section, position, startY, endY };
        return editedSection;
      }
      return section;
    });
    console.log(newSections, editedSection);
    onHandleSections(newSections);
    onEditSection(editedSection);
  }

  function handleChange(
    sectionLocaton: any,
    position: string | number,
    startY: number,
    endY: number
  ) {
    const newSectionLocation = {
      ...sectionLocaton,
      section: { ...sectionLocaton.section, position, startY, endY },
    };
    onHandleSelectedSectionLocation(newSectionLocation);
  }

  return (
    <div className="flex flex-col px-[0.94rem] h-full">
      <div className="grow py-[0.69rem] space-y-2 h-full overflow-y-scroll no-scrollbar">
        {sectionLocations && (
          <>
            {sectionLocations.map((item) => {
              return (
                <button
                  className={`${
                    selectSection === item.section?.id && "border-[#929292]"
                  } flex flex-col w-full pb-[1.12rem] border-b border-[#929292] hover:bg-[#F5F5F5] group`}
                  key={item.section?.id}
                  onClick={() => {
                    if (selectSection !== item.section?.id) {
                      setSelectSection(item.section?.id);
                      setPosition(item.section?.position);
                      setStartY(item.section?.startY);
                      setEndY(item.section?.endY);
                      handleChange(
                        item,
                        item.section?.position,
                        item.section?.startY,
                        item.section?.endY
                      );
                    }
                  }}
                >
                  <div className="w-full flex justify-between">
                    <div className="overflow-hidden">
                      <div className="flex text-lg font-bold mb-1 items-start">
                        {item.section?.name}
                      </div>
                      <ul className="flex flex-col items-start justify-start">
                        {item.locations.map((location: any) => {
                          return (
                            <li
                              className="flex space-x-1 items-center text-sm whitespace-nowrap"
                              key={location.id}
                            >
                              <span className="align-middle">*</span>
                              <span>{location.name}</span>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                    {/* {selectSection === item.section.id && ( */}
                    <div className="space-y-2">
                      <div className="">
                        <select
                          disabled={selectSection !== item.section?.id}
                          className="outline-none p-1 border w-[11.25rem] rounded-[0.375rem] border-[#586A84] font-[600] text-[1rem] text-[#586A84]"
                          value={
                            selectSection === item.section?.id
                              ? position
                              : item.section?.position
                          }
                          onChange={(e) => {
                            setPosition(e.target.value);
                            handleChange(
                              item,
                              e.target.value,
                              startY || 0,
                              endY || 0
                            );
                          }}
                        >
                          <option value={-1}>Select One</option>
                          <option value={"Left"}>Left</option>
                          <option value={"Right"}>Right</option>
                        </select>
                      </div>
                      <div className="flex flex-col space-y-1">
                        <div className="space-x-1 items-center">
                          {/* <span className="col-span-1 ">StartY</span> */}
                          <input
                            disabled={selectSection !== item.section?.id}
                            placeholder="Start Y"
                            className="outline-none border p-1 w-[11.25rem] rounded-[0.375rem] border-[#586A84] font-[600] text-[1rem] text-[#586A84]"
                            value={
                              selectSection === item.section?.id
                                ? startY
                                : item.section?.startY ?? 0
                            }
                            onChange={(e) => {
                              setStartY(parseInt(e.target.value));
                              handleChange(
                                item,
                                position,
                                parseInt(e.target.value),
                                endY || 0
                              );
                            }}
                            type="number"
                            name=""
                            id=""
                          />
                        </div>
                        <div className="space-x-1 items-center">
                          {/* <span className="col-span-1 ">EndY</span> */}
                          <input
                            disabled={selectSection !== item.section?.id}
                            placeholder="End Y"
                            className="outline-none border p-1 w-[11.25rem] rounded-[0.375rem] border-[#586A84] text-[#586A84] font-[600] text-[1rem]"
                            value={
                              selectSection === item.section?.id
                                ? endY
                                : item.section?.endY ?? 0
                            }
                            onChange={(e) => {
                              setEndY(parseInt(e.target.value));
                              handleChange(
                                item,
                                position,
                                startY || 0,
                                parseInt(e.target.value)
                              );
                            }}
                            type="number"
                            name=""
                            id=""
                          />
                        </div>
                      </div>
                      <div className="flex pt-[0.62rem] justify-end">
                        <button
                          disabled={selectSection !== item.section?.id}
                          className="disabled:group-hover:pointer-events-none border border-tertiary px-[1.37rem] py-[0.28rem] text-[#FFFFFF] bg-tertiary hover:bg-tertiary/80 rounded-[1.25rem]"
                          onClick={() => {
                            handleSave(item.section?.id, position, startY, endY);
                          }}
                        >
                          save
                        </button>
                      </div>
                    </div>
                    {/* )} */}
                  </div>
                </button>
              );
            })}
          </>
        )}
      </div>
      <div className=" border-t border-[#2C2C2C] px-[0.49rem] py-5 flex justify-end items-center">
        <button
          onClick={() => {
            onHandleDone();
          }}
          className="px-7 py-3 text-[1rem] text-[#FFFFFF] bg-[#AB0273] rounded-[1.875rem]"
        >
          Done
        </button>
      </div>
    </div>
  );
};

export default LocationDiagram;
