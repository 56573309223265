import React, { useEffect, useRef, useState } from "react";
//@ts-ignore
import defaultPlayerImage from "src/assets/Course/defaultPlayerImage.png";
// import { Button } from "src/components/ui/button";
import { Button } from "src/components/ui/button";
// @ts-ignore
import {ReactComponent as Playback} from "src/assets/Course/play_btn.svg";
// @ts-ignore
import {ReactComponent as TableOfContent} from "src/assets/Course/toc_btn.svg";
import { GetPreviewTemplate } from "../Templates/Preview/utils/GetPreviewTempalte";
import { DefaultTemplateData } from "../Templates/Authoring/utils/DefaultTemplateData";

type Props = {
  title: string,
  logoFile: any,
  bgColor: string,
  priAccent: string,
  secAccent: string,
  topic: any;
  isPlaybackOn: boolean,
  isNavigationOn: boolean,
  isPaginationOn: boolean,
  isTOCon: boolean,
  alignment: 'left' | 'center' | 'right';
};

function Player({ title, logoFile, bgColor, priAccent, secAccent, topic, isPlaybackOn, isNavigationOn, isPaginationOn, isTOCon, alignment }: Props) {
  const playerRef = useRef<HTMLDivElement>(null);
  const [playerDimensions, setplayerDimensions] = useState({
    width: 0,
    height: 0,
  });

  const playerContainerRef = useRef<HTMLDivElement>(null);
  const [playerContainerDimensions, setplayerContainerDimensions] = useState({
    width: 0,
    height: 0,
  });

  const handleNext = () => {
    // Handle Next button logic
    console.log("Next button clicked");
  };

  const handlePrevious = () => {
    // Handle Previous button logic
    console.log("Previous button clicked");
  };

  useEffect(() => {
    const handleResize = () => {
      const player = playerRef.current;
      const playerContainer = playerContainerRef.current;
      if (playerContainer) {
        const playerContainerWidth = playerContainer.offsetWidth;
        const playerContainerHeight = (playerContainerWidth * 9) / 16 + 100;
        setplayerContainerDimensions({
          width: playerContainerWidth,
          height: playerContainerHeight,
        });
      }
      if (player) {
        const playerWidth = player.offsetWidth;
        const playerHeight = player.offsetHeight;
        setplayerDimensions({ width: playerWidth, height: playerHeight });
      }
    };

    // Initial resize
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const renderTemplate = () => {
    const TemplateComponent = GetPreviewTemplate.get(topic?.template?.type);
    
    if (TemplateComponent) {
      return (
        <TemplateComponent
          data={topic?.template?.data}
          canvasDimensions={playerDimensions}
          istabtemplate={true}
          nestedTemplate={true}
        />
      );
    }

    // Fallback to default template if no matching template is found
    const DefaultTemplate = GetPreviewTemplate.get("scr");
    const defaultTemplateData = DefaultTemplateData.get("scr");
    return DefaultTemplate ? (
      <DefaultTemplate data={defaultTemplateData} canvasDimensions={playerDimensions} />
    ) : null;
  };

  return (
    <div
      ref={playerContainerRef}
      // style={{
      //   width: `${playerContainerDimensions.width}px`,
      //   height: `${playerContainerDimensions.height}px`,
      // }}
      style={{ backgroundColor: bgColor || "#ffffff" }}
      className="flex flex-col items-center canvas !rounded-t-none"
    >
      {logoFile || title ? (
        <div className={`flex space-x-5 items-center w-full p-3 border-t-2 border-[#ccc] ${alignment === 'center' ? 'justify-center' : alignment === 'left' ? 'justify-start' : 'justify-end'}`}>
          {logoFile && (
            <div className="w-[2.5rem] h-[2.5rem]">
              <img src={logoFile.url} alt={logoFile.name} className="!w-full !h-full object-cover"/>
            </div>
          )}

          {title && (
            <div className="space-x-5 text-lg font-medium">
              {title}
            </div>
          )}
        </div>
      ) : null }
      
      <div className="w-full aspect-[16/9]">
        <div className="relative w-full h-full flex mx-auto overflow-hidden font-poppins ">
          <div className="w-full h-full flex justify-center items-center">
            <div
              ref={playerRef}
              style={{ height: `${(playerDimensions.width) * 9 / 16}px` }} className='canvas !border-none !rounded-b-none !rounded-none'
            >
              <div className="w-full h-full flex">
                {renderTemplate()}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Player Controls */}

      {isPlaybackOn || isPaginationOn || isNavigationOn || isTOCon ? (
        <div className="flex justify-between items-center w-full p-3 border-t-2 border-[#ccc]">
          <div className="flex space-x-5 items-center">

            {isPlaybackOn && (
              <Button
                style={{
                  backgroundColor: priAccent || "#AB0273",
                }}
                className="bg-tertiary hover:bg-tertiary/50 text-white px-4 py-2 rounded "
              >
                <Playback className=""/>
              </Button>
            )}

            {isTOCon && (
              <Button
                style={{
                  backgroundColor: priAccent || "#AB0273",
                }}
                className="bg-tertiary hover:bg-tertiary/50 text-white px-4 py-2 rounded"
              >
                <TableOfContent className=""/>
              </Button>
            )}
          </div>

          <div className="flex space-x-5 items-center">

            {isPaginationOn && (
              <div 
                style={{
                  color: secAccent || "#d1d1d1",
                }} 
                className="text-gray-400">
                  1 0f 20
              </div>
            )}

            {isNavigationOn && (
              <>
                <Button
                  style={{
                    backgroundColor: priAccent || "#AB0273",
                  }}
                  className="bg-tertiary hover:bg-tertiary/50 text-white px-4 py-2 rounded"
                >
                  Previous
                </Button>
                <Button
                  style={{
                    backgroundColor: priAccent || "#AB0273",
                  }}
                  className="bg-tertiary hover:bg-tertiary/50 text-white px-4 py-2 rounded"
                >
                  Next
                </Button>
              </>
            )}
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default Player;
