// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.Crop-Controls {
  margin-bottom: 10px;
}
.Crop-Controls > * {
  margin-bottom: 3px;
}

img {
  max-width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/UploadDropdowns/ImageCropComponents/index.css"],"names":[],"mappings":";AACA;EACE,mBAAmB;AACrB;AACA;EACE,kBAAkB;AACpB;;AAEA;EACE,eAAe;AACjB","sourcesContent":["\n.Crop-Controls {\n  margin-bottom: 10px;\n}\n.Crop-Controls > * {\n  margin-bottom: 3px;\n}\n\nimg {\n  max-width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
