import axios from "axios";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  Module,
  setCurrentPath,
  setModules,
} from "src/features/trackedModules/trackedModulesSlice";

export const storeTrackingData = async (modules: Module[], businessUnitId: string, sepmId: string, accessToken: string) => {
  console.log("inside store tracking data");
  const res = await axios.post(
    `${process.env.REACT_APP_SERVER_BASE_URL}/api/v1/tracking/storedata`,
    {
      businessUnitId,
      sepmId,
      data: modules,
    },
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    }
  );
  if (res && res.data) {
    console.log(res.data.data);
  }
};

export const getTrackingData = async (accessToken: string, sepmId: string) => {
  const res = await axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}/api/v1/tracking/getdata/${sepmId}`, {
    headers: { Authorization: `Bearer ${accessToken}` },
  });

  if (res && res.data) {
    return res.data.data[0].data;
  }
};

