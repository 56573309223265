import React, { useEffect, useState } from 'react'
import Question from './common/Question'
import { Question as QuestionType } from './utils/DefaultTemplateData'
import { set } from 'lodash';

type Props = {
    handleDataChange: (data: any) => void;
    data: QuestionType;
    optionQuantity: number;
    handleImageOrOptionClicked: (id: string) => void;
}

function Mcr({ data, handleDataChange, optionQuantity, handleImageOrOptionClicked }: Props) {
    // const [changes, setChanges] = useState(false);

    useEffect(() => {
        console.log("data", data);
        // setChanges(!changes);
      }, [data]);
    
      
    return (
        <div className="grow overflow-y-scroll">
            <Question type='mcr' optionQuantity={optionQuantity} data={data} handleDataChange={(data) => {
                handleDataChange(data);
            }} handleImageOrOptionClicked={handleImageOrOptionClicked} />
        </div>
    )
}

export default Mcr