import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { setCanvasActiveComponent } from 'src/features/canvasActiveComponent/canvasActiveComponent';
// @ts-ignore
import DefaultImage from "src/assets/Course/textwithimage/defaultImage.png";

type Props = {
    data: any;
    handleDataChange?: (data: any) => void;
    preview?: boolean;
}

function TextWithImageLayout({ data, handleDataChange, preview }: Props) {

    const { canvasActiveComponent } = useSelector((state: any) => state.canvasActiveComponent);
    const dispatch = useDispatch();

    useEffect(() => {
        if(handleDataChange) handleDataChange(data);
    }, [data]);
    return (
        <div style={{ 
            ...data.styles.globalBG,
                backgroundImage: (data.layoutOption === "text-image-centered" && data.imageFile?.url) ? "url(" + data.imageFile?.url + ")" : "none" 
            }} 
            className={`flex ${data.layoutOption === 'text-right-image-left' && "flex-row-reverse"} h-full w-full ${data.layoutOption === "text-image-centered" && data.imageFile?.url && ``} justify-center items-center`}>

            <div
                style={{ ...data.styles.textContainer, backgroundColor: data.layoutOption === "text-image-centered" ? "rgba(0,0,0,0.5)" : data.styles.textContainer.backgroundColor }}
                onClick={(e) => {
                    e.stopPropagation();
                    if (canvasActiveComponent?.templateType === "textwithimage" && canvasActiveComponent?.componentType === 'text') {
                        dispatch(setCanvasActiveComponent(null));
                    } else {
                        dispatch(setCanvasActiveComponent({
                            templateType: "textwithimage",
                            componentType: 'text'
                        }));
                    }
                }}
                className={`flex flex-col w-full h-full ${!preview && canvasActiveComponent?.templateType === "textwithimage" && canvasActiveComponent?.componentType === 'text' ? 'border-2 border-dashed border-blue' : ''}`}
            >
                {data.logoImageFile?.url &&
                    <div
                        style={data.styles.logoImageContainer}
                        className='flex w-full'
                    >
                        <img style={data.styles.logoImage} src={data.logoImageFile?.url} alt="logo image" className="w-[10rem] aspect-[16/9] object-contain" />
                    </div>
                }
                <div
                    style={data.styles.text}
                    className="flex flex-wrap grow">
                    <pre className='whitespace-pre-wrap' dangerouslySetInnerHTML={{ __html: data.text }}></pre>
                </div>
            </div>

            {
                (data.layoutOption !== "only-text" && data.layoutOption !== "text-image-centered") && (
                    <div
                        style={{ ...data.styles.imageContainer, width: `${100 - parseInt(data.styles.textContainer.width.replace(/\D/g, ''))}%` }}
                        className={`flex w-full h-full ${!preview && canvasActiveComponent?.templateType === "textwithimage" && canvasActiveComponent?.componentType === 'image' ? 'border-2 border-dashed border-blue' : ''}`}
                        onClick={(e) => {
                            e.stopPropagation();
                            if (canvasActiveComponent?.templateType === "textwithimage" && canvasActiveComponent?.componentType === 'image') {
                                dispatch(setCanvasActiveComponent(null));
                            } else {
                                dispatch(setCanvasActiveComponent({
                                    templateType: "textwithimage",
                                    componentType: 'image'
                                }));
                            }
                        }}
                    >
                        <img
                            style={data.styles.image}
                            src={data.imageFile?.url ? data.imageFile?.url : DefaultImage}
                            alt="Image"
                            className='w-full h-full object-contain' />

                    </div>
                )
            }

        </div>
    )
}

export default TextWithImageLayout