import React, { useState, useEffect, useRef } from "react";
import ReactCountryFlag from "react-country-flag";
import { ChevronRight } from "@carbon/icons-react";
import { ChevronLeft } from "@carbon/icons-react";
import PreviewModal from "src/utils/PreviewModal";
import PdfViewer from "src/utils/PdfViewer";
// @ts-ignore
import PAannouncementBG from "../../../assets/PAannouncement.jfif";
// @ts-ignore
import EyeLogo from "../../../assets/preview/Eye.svg";
// @ts-ignore
import MicrophoneLogo from "../../../assets/preview/Microphone.svg";
// @ts-ignore
import Mute from "../../../assets/preview/mute.png";
// @ts-ignore
import DownArrow from "../../../assets/preview/CaretDown.svg";
// @ts-ignore
import PauseLogo from "../../../assets/preview/Pause.svg";
// @ts-ignore
import PlayLogo from "../../../assets/play.png";
// @ts-ignore
import Speaker from "../../../assets/preview/Speaker.svg";
// @ts-ignore
import Repeat from "../../../assets/preview/Repeat_.svg";
// @ts-ignore
import EnglishLogo from "../../../assets/preview/EnglishLogo.png";
import Slider from "rc-slider";
import { useSelector } from "react-redux";
import { Box } from "@chakra-ui/react";
import Loader from "src/utils/Loader";
import { Module, setModules } from "src/features/trackedModules/trackedModulesSlice";
import { useDispatch } from "react-redux";
import { Trash } from "lucide-react";
import Modal from "src/utils/Modal";
import { useTemplateTracking } from "src/hooks/useTemplateTracking";
import { useParams } from "react-router-dom";

type Props = {
  data: any;
  onNext: (nextTemplateId: string) => void;
  //   handleSelectMenu: (menu: string) => void;
  //   selectedAircraft: any;
};

export function PAannouncement({ data, onNext }: Props) {
  const [openModal, setOpenModal] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState<any>();
  const [announcement, setAnnouncement] = useState<any>();
  const [filteredDoc, setFilteredDoc] = useState<any>();
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isVolumeOpen, setIsVolumeOpen] = useState(false);
  const [currentTime, setCurrentTime] = useState<number | undefined>(0);
  const [audioDuration, setAudioDuration] = useState<number | undefined>(0);

  const [recording, setRecording] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(
    null
  );
  const [audioUrl, setAudioUrl] = useState("");
  const [audio, setAudio] = useState<HTMLAudioElement | null>(null);
  const [audioChunks, setAudioChunks] = useState<BlobPart[]>([]);
  const [startTime, setStartTime] = useState<number | null>(null);
  const [timer, setTimer] = useState(0);
  const [savedRecordings, setSavedRecordings] = useState<{ [key: string]: { recordings: any[]; count: number } }>({});
  const [selectedRecordingUrl, setSelectedRecordingUrl] = useState<string | null>(null);
  const [recordingMessage, setRecordingMessage] = useState<string | null>(null);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [replaceIndex, setReplaceIndex] = useState<number | null>(null);

  const { themeColor, headingFont, bodyFont, headingColor, thumbnailHeadingColor, thumbnailTitleColor } = useSelector(
    (state: any) => state.programSettings
  );

  const { modules, currentPath } = useSelector((state: any) => state.trackedModules);

  const MAX = 20;

  const audioChunksRef = useRef<BlobPart[]>([]);
  const startTimeRef = useRef<number | null>(null);
  const intervalIdRef = useRef<NodeJS.Timer | null>(null);
  const { accessToken } = useSelector((state: any) => state.accessToken);
  const { businessUnitId, sepmId } = useParams();

  useTemplateTracking({ moduleId: data._id, businessUnitId, sepmId, accessToken });

  useEffect(() => {
    if (recording) {
      navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
        const newMediaRecorder = new MediaRecorder(stream);
        setMediaRecorder(newMediaRecorder);

        newMediaRecorder.start();

        newMediaRecorder.ondataavailable = (event) => {
          setAudioChunks((prev) => [...prev, event.data]);
          audioChunksRef.current = [...audioChunksRef.current, event.data];
        };

        newMediaRecorder.onstop = () => {
          console.log("audio chunks: ", audioChunksRef.current);
          const audioBlob = new Blob(audioChunksRef.current);
          // const audioBlob = new Blob(audioChunks);
          const newAudioUrl = URL.createObjectURL(audioBlob);
          console.log(newAudioUrl);
          setAudioUrl(newAudioUrl);
        };
      });
    }
  }, [recording]);

  useEffect(() => {
    audioChunksRef.current = audioChunks;
  }, [audioChunks]);

  useEffect(() => {
    console.log(audioUrl);
    if (audioUrl) {
      saveAudio();
    }
  }, [audioUrl]);

  useEffect(() => {
    console.log(data);
  }, []);

  useEffect(() => {
    console.log("selectedRecording: ", selectedRecordingUrl)
  }, [selectedRecordingUrl])

  useEffect(() => {
    handleClickLanguage(data.content.languages[0], announcement);
    console.log(data.content.languages[0], announcement);
  }, [announcement]);

  useEffect(() => {
    console.log(filteredDoc);
  }, [filteredDoc]);

  useEffect(() => {
    const recordings = JSON.parse(sessionStorage.getItem("recordings") || "[]");
    setSavedRecordings(recordings);
  }, []);

  function handleClickPA(data: any) {
    console.log(data);
    setAnnouncement(data);
    setOpenModal(true);
  }

  function handleClickLanguage(lang: any, announcement: any) {
    if (announcement) {
      console.log(lang, announcement);
      setSelectedLanguage(lang);
      const filteredLangDoc = announcement.languageDoc?.filter(
        (data: any) => data?.id === lang?.id
      );

      console.log(filteredLangDoc[0]);
      setFilteredDoc(filteredLangDoc[0]);

      // Reset audio and progress bar
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
        audioRef.current.src = filteredLangDoc[0]?.file?.url ?? "";
        setCurrentTime(0);
        setAudioDuration(0);
        setIsPlaying(false);
      }

      setOpenDropdown(false);
    }
  }

  const audioRef = useRef<HTMLAudioElement>(null);

  const handlePlayPause = () => {
    if (isPlaying) {
      audioRef.current?.pause();
    } else {
      audioRef.current?.play();
    }

    setIsPlaying((currentPlayStatus) => !currentPlayStatus);
  };

  const onTimeUpdate = () => {
    setCurrentTime(audioRef.current?.currentTime ?? 0);
  };

  const formatTime = (duration: number) => {
    const OneSec = 60;
    const minutes = Math.floor(duration / OneSec);
    const seconds = Math.floor(duration - minutes * OneSec);

    const formatedTime = `${tenPad(minutes)}:${tenPad(seconds)}`;

    return formatedTime;
  };

  const tenPad = (time: number) => {
    if (time < 10) return `0${time}`;
    else return time;
  };

  const onVolumeChange = (value: number) => {
    if (audioRef.current) {
      audioRef.current.volume = value / 100;
    }
  };

  function handleVolume(e: React.ChangeEvent<HTMLInputElement>) {
    const { value } = e.target;
    const volume = Number(value) / MAX;
    if (audioRef.current) {
      audioRef.current.volume = volume;
    }
  }

  const recordFormatTime = (time: number) => {
    const totalSeconds = Math.floor(time / 1000);
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };

  // ✅ Start/Stop Recording Toggle
  const toggleRecording = async () => {
    if (recording) {
      stopRecording();
    } else {
      const announcementId = announcement?._id;
      if (!announcementId) return;

      const recordings = savedRecordings[announcementId]?.recordings || [];
      if (recordings.length >= 3) {
        const replaceIdx = savedRecordings[announcementId].count % 3;
        setReplaceIndex(replaceIdx);
        setIsConfirmationModalOpen(true);
      } else {
        startRecording();
      }
    }
  };

  const startRecording = () => {
    setRecording(true);
    setAudioChunks([]);
    audioChunksRef.current = [];
    const start = Date.now();
    setStartTime(start);
    startTimeRef.current = start;
    intervalIdRef.current = setInterval(() => {
      if (startTimeRef.current) {
        setTimer(Date.now() - startTimeRef.current);
      }
    }, 1000);
  };

  const handleConfirmRecording = () => {
    setIsConfirmationModalOpen(false);
    startRecording();
  };

  const stopRecording = () => {
    setRecording(false);
    mediaRecorder?.stop();
    setStartTime(null);
    if (intervalIdRef.current) {
      clearInterval(intervalIdRef.current);
      intervalIdRef.current = null;
    }
  };

  const saveAudio = async () => {
    const announcementId = announcement?._id;
    if (!announcementId) return;

    const updatedRecordings = { ...savedRecordings };
    if (!updatedRecordings[announcementId]) {
      updatedRecordings[announcementId] = { recordings: [], count: 0 };
    }

    const replaceIndex = updatedRecordings[announcementId].count % 3;
    const newRecording = {
      name: `Rec ${replaceIndex + 1}`,
      timestamp: new Date().toLocaleString(),
      url: audioUrl,
    };

    if (updatedRecordings[announcementId].recordings?.length >= 3) {
      setRecordingMessage(`Your recording has been replaced by recording ${replaceIndex + 1}.`);
      updatedRecordings[announcementId].recordings[replaceIndex] = newRecording;
    } else {
      updatedRecordings[announcementId].recordings?.push(newRecording);
    }

    updatedRecordings[announcementId].count += 1;

    sessionStorage.setItem("recordings", JSON.stringify(updatedRecordings));
    setSavedRecordings(updatedRecordings);
  };

  useEffect(() => {
    console.log("savedRecordings: ", savedRecordings[announcement?._id]);
  }, [savedRecordings])

  useEffect(() => {
    console.log("themeColor", themeColor, "headingColor", headingColor);
  }, [themeColor, headingColor]);

  const getModuleByPath = (modulesList: Module[], path: string[]): Module | null => {
    let currentModules = modulesList;
    let targetModule: Module | null = null;

    for (let id of path) {
      targetModule = currentModules.find(module => module.id === id) || null;

      if (!targetModule) return null; // Return null if any module in the path is missing

      currentModules = targetModule.children || []; // Move to the next level
    }

    return targetModule; // Return the final module
  };

  useEffect(() => {
    if (recordingMessage) {
      const timer = setTimeout(() => {
        setRecordingMessage(null);
      }, 3000);

      // Clear the timeout if the component unmounts or if recordingMessage changes
      return () => clearTimeout(timer);
    }
  }, [recordingMessage]);

  return (
    <div
      className={`relative w-full h-[calc(100%-2.625rem)] md:h-[calc(100%-3.625rem)] lg:h-[calc(100%-4.625rem)] xl:h-[calc(100%-5.25rem)] pt-[1.5rem] md:pt-[1.88rem] pl-[1.7rem] md:pl-[2.5rem] lg:pl-[3.75rem] pr-[1.7rem] md:pr-[2.44rem] ${bodyFont && `font-${bodyFont}`
        }`}
    >
      <div className="grow w-full h-full flex flex-col space-y-[3rem] md:space-y-[3.4rem] lg:space-y-[5rem]">
        <div
          className={`text-[1.5rem] md:text-[1.7rem] lg:text-[2rem] font-semibold ${headingFont && `font-${headingFont}`
            }`}
        >
          <div
            style={{ color: headingColor, borderColor: headingColor }}
            className={`border-l-4 md:border-l-[7px] pl-[1rem] md:pl-[1.5rem] leading-normal ${!headingColor && "text-[#FFFFFF] border-[#FFFFFF]"}`}>
            {data.title}
          </div>
        </div>

        <div className="grow min-h-0 overflow-y-auto no-scrollbar">
          <div className="grid grid-cols-2 md:grid-cols-3 gap-x-[2rem] gap-y-[3rem] lg:grid-cols-4 lg:gap-y-[5.06rem] lg:gap-x-[4.94rem] pl-[0rem] lg:pl-[1.5rem]">
            {data.content.documents.map((data: any, idx: number) => {
              const currentModules = getModuleByPath(modules, currentPath)?.children;
              const moduleStatus = currentModules && currentModules.find((module: Module) => module.id === data._id)?.status;
              return (
                <PAannouncementDocs
                  key={data._id}
                  status={moduleStatus}
                  data={data}
                  index={idx + 1}
                  themeColor={themeColor}
                  thumbnailHeadingColor={thumbnailHeadingColor}
                  thumbnailTitleColor={thumbnailTitleColor}
                  handleClick={(data: any) => {
                    handleClickPA(data);
                  }}
                />
              )
            })}
          </div>
        </div>
      </div>

      <div>
        <PreviewModal
          onOpened={openModal}
          onClose={() => setOpenModal(false)}
          showCrossIcon={false}
        >
          <>
            <div
              style={{
                background: `linear-gradient(0deg, rgba(0, 0, 0, 0.70) 0%, rgba(0, 0, 0, 0.70) 100%), url(${data.content.bgfile?.url})`,
                backgroundPosition: "50%",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundColor: "lightgray",
                color: "#146793",
              }}
              className="w-full h-full flex flex-col"
            >
              {/* <div className="md:px-[1rem]">
                <div className="text-[#FFFFFF] text-[1.2rem] md:text-[1.6rem] lg:text-[2rem] font-semibold  border-l-4 md:border-l-[7px] pl-[0.8rem] md:pl-[1.3rem] leading-normal flex xs:flex-col sm:flex-col md:flex-row xs:items-start sm:items-start md:items-center md:space-x-1">
                  <span className="">{data.title} - </span>
                  <span className=""> {announcement?.title}</span>
                </div>
              </div> */}

              {recordingMessage && (
                <div className="absolute text-white text-center top-0 right-5 bg-black p-2">
                  {recordingMessage}
                </div>
              )}

              <Box className="bg-white w-full h-[2.75rem] md:h-[3rem] xl:h-[3.5rem] px-4 py-[2px] md:py-1 lg:py-1.5 ">
                <div className="h-full flex space-x-4 justify-start items-center">
                  <button
                    onClick={() => {
                      // navigate(`/user/${userId}/businessunit/${businessUnitId}/sepm/${sepmId}`);
                      setOpenModal(false);
                    }}
                    className="text-black"
                  >
                    <ChevronLeft size={32} />
                  </button>
                  <div className="text-black text-lg md:text-xl lg:text-2xl font-semibold ">
                    {data.title} - {announcement?.title}
                  </div>
                </div>
              </Box>

              <div className="md:px-[2rem] lg:px-[14.69rem] grow flex w-full justify-center items-center overflow-hidden p-4">
                <div className="flex flex-col w-full h-full justify-center items-center ">
                  <div className="w-full flex max-w-[53.125rem] h-[10.625rem] border-2 border-solid border-[#454544]/90 rounded-[0.625rem] bg-black/80 p-[1rem]">
                    <div className="w-full flex flex-col space-y-[1rem] md:space-y-[1.31rem] md:max-w-[37rem] h-full mx-[1rem] md:mx-auto">
                      <div className="w-full">
                        <audio
                          src={filteredDoc?.file?.url ?? ""}
                          itemType={filteredDoc?.file?.type ?? ""}
                          ref={audioRef}
                          className="w-full"
                          onWaiting={() => setIsLoading(true)}
                          onCanPlayThrough={() => {
                            // alert(audioRef.current?.duration)
                            // setAudioDuration(audioRef.current?.duration);
                            setIsLoading(false);
                          }}
                          onLoadedMetadata={() => {
                            console.log("this is the audio duration", audioRef.current?.duration);
                            setAudioDuration(audioRef.current?.duration ?? 0);
                            // Play the audio once it is ready
                            audioRef.current?.play();
                            setIsPlaying(true);
                          }}
                          onEnded={() => setIsPlaying(false)}
                          onTimeUpdate={onTimeUpdate}
                        >
                          {/* <source
                        src={filteredDoc?.file?.url ?? ""}
                        type={filteredDoc?.file?.type ?? ""}
                      ></source>   */}
                        </audio>

                        <div className="flex w-full space-x-[1rem] justify-center items-center mt-[2.94rem]">
                          {/* current time */}
                          <span className="text-[#FFFFFF] text-[0.625rem] font-[400] leading-normal">
                            {formatTime(currentTime ?? 0)}
                          </span>

                          {/* Progress bar */}
                          <div className="bg-[#FFFFFF]/50 h-[4px] w-full max-w-[31.25rem] mx-auto rounded-full">
                            <div
                              className={`${!themeColor && "bg-[#AB0273]"
                                } h-full w-[0rem] rounded-full ${currentTime !== audioDuration
                                  ? "progress-bar-inner"
                                  : ""
                                }`}
                              style={{
                                backgroundColor: themeColor,
                                opacity: 0.8,
                                width: `${((currentTime ?? 0) / (audioDuration ?? 1)) * 100}%`,
                                animationPlayState: isPlaying
                                  ? "running"
                                  : "paused",
                                animationDuration: isLoading
                                  ? "0s"
                                  : `${audioRef.current?.duration}s`,
                              }}
                            ></div>
                          </div>

                          {/* Audio Duration */}
                          <span className="text-[#FFFFFF] text-[0.625rem] font-[400] leading-normal">
                            {formatTime(audioDuration ?? 0)}
                          </span>
                        </div>
                      </div>
                      <div className="grid grid-cols-3 items-center">
                        <div className="col-span-1 flex items-center space-x-[0.56rem]">
                          <button>
                            <img src={Repeat} alt="repeat" />
                          </button>
                          <div className="group flex items-center">
                            <button
                              onClick={() => setIsVolumeOpen(!isVolumeOpen)}
                            >
                              <img src={Speaker} alt="sound" />
                            </button>
                            <input
                              type="range"
                              style={{ accentColor: themeColor }}
                              className={`${!themeColor && "accent-[#AB0273]"
                                } h-[4px] w-[3.125rem]`}
                              min={0}
                              max={MAX}
                              onChange={(e) => handleVolume(e)}
                            />
                          </div>
                        </div>
                        <div className="col-span-1 flex justify-center">
                          <button
                            onClick={handlePlayPause}
                            style={{ borderColor: themeColor, opacity: 0.7 }}
                            className={`w-[3.25] h-[3.25] p-[0.625rem] flex items-center justify-center border-[3px] border-solid ${themeColor && "border-[#AB0273]/70"
                              } rounded-full`}
                          >
                            {isPlaying ? (
                              <img src={PauseLogo} alt="pause" />
                            ) : (
                              <img
                                className="w-[2rem] h-[2rem] p-[0.4rem]"
                                src={PlayLogo}
                                alt="play"
                              />
                            )}
                          </button>
                        </div>
                        <div className="col-span-1 relative flex flex-col items-end">
                          <button
                            onClick={() => setOpenDropdown(!openDropdown)}
                            style={{ borderColor: themeColor, opacity: 0.7 }}
                            className={`flex py-[0.5rem] px-[0.75rem] w-full max-w-[8rem] lg:max-w-[9rem] h-[2.65rem] items-center justify-between space-x-[0.625rem] border-2 border-solid ${!themeColor && "border-[#AB0273]/70"
                              } rounded-[1.875rem]`}
                          >
                            {/* <img src={EnglishLogo} alt="logo" /> */}
                            {/* <span className="text-[0.875rem] md:text-[1rem] text-[#FFFFFF] line-clamp-1">{selectedLanguage?.name}</span> */}
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <img
                                src={`https://flagcdn.com/w40/${selectedLanguage?.countryCode?.toLowerCase()}.png`}
                                alt={selectedLanguage?.name}
                                style={{
                                  width: 25,
                                  height: 25,
                                  marginRight: 10,
                                  borderRadius: 9999,
                                }}
                                onError={(e) =>
                                  (e.currentTarget.style.display = "none")
                                }
                              />
                              <span className="text-[0.875rem] md:text-[1rem] text-[#FFFFFF] line-clamp-1">
                                {selectedLanguage?.name}
                              </span>
                            </div>
                            <img
                              className="w-[1.75rem] h-[1.75rem]"
                              src={DownArrow}
                              alt="arrowdown"
                            />
                          </button>
                          {openDropdown && (
                            <div className="flex flex-col w-[9rem] md:w-[10rem] xl:w-[12.4375rem] dropDownLanguage xs:right-0">
                              <ul className="flex flex-col p-[0.44rem]">
                                {data.content.languages.map(
                                  (lang: any, idx: number) => (
                                    <li
                                      className={`flex text-[0.8rem] md:text-[1rem] xl:text-[1.5rem] text-black px-3 py-2 text-left items-center border-t border-[#000]/30 ${idx === 0 && "border-t-0"
                                        }`}
                                    >
                                      <button
                                        onClick={() =>
                                          handleClickLanguage(
                                            lang,
                                            announcement
                                          )
                                        }
                                        className="flex space-x-[0.625rem] items-center justify-center"
                                      >
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          <img
                                            src={`https://flagcdn.com/w40/${lang?.countryCode?.toLowerCase()}.png`}
                                            alt={lang.name}
                                            style={{
                                              width: 27,
                                              height: 27,
                                              marginRight: 12,
                                              borderRadius: 9999,
                                            }}
                                            onError={(e) =>
                                            (e.currentTarget.style.display =
                                              "none")
                                            }
                                          />
                                          {lang.name}
                                        </div>
                                        {/* <span className="">
                                      {lang.name}
                                    </span> */}
                                      </button>
                                    </li>
                                  )
                                )}
                              </ul>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="w-full max-w-[53.125rem] h-[19rem] md:h-[21.25rem] p-[1rem] border-2 border-solid border-[#454544]/90 rounded-[0.625rem] bg-black/70 overflow-y-scroll no-scrollbar">
                    {filteredDoc && (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: filteredDoc.transcriptText,
                        }}
                        className="text-[#FFFFFF] text-[0.875rem]"
                      />
                    )}
                  </div>

                  <div className="w-full max-w-[53.125rem] h-[6.0625rem] flex items-center justify-center border-2 border-solid border-[#454544]/90 rounded-[0.625rem] bg-black/60 p-[1rem]">
                    {/* <div
                      style={{ borderColor: themeColor, opacity: 0.7 }}
                      className={`inline-flex p-[0.625rem] space-x-[0.52rem] items-center justify-center border-[2px] md:border-[3px] border-solid ${!themeColor && "border-[#AB0273]/70"
                        } rounded-[12.5rem]`}
                    >
                      <span className="w-[1.8rem] h-[1.8rem] md:w-[2rem] md:h-[2rem]">
                        <img src={MicrophoneLogo} alt="mike" />
                      </span>
                      <span className="text-[1rem] text-[#FFFFFF] font-[500] leading-normal">
                        Practice Mode
                      </span>
                    </div> */}

                    {/* recording */}
                    <div>
                      <div className="flex items-center justify-around">
                        <button
                          style={{
                            borderColor: recording ? 'red' : themeColor || '#AB0273',
                            backgroundColor: recording ? 'red' : themeColor || '#AB0273'
                          }}
                          className={`text-white inline-flex p-[0.625rem] space-x-[0.52rem] items-center justify-center border-[2px] md:border-[3px] border-solid rounded-[12.5rem]`}
                          onClick={toggleRecording}
                        // disabled={recording}
                        >
                          <span className="w-[1.8rem] h-[1.8rem] md:w-[2rem] md:h-[2rem]">
                            {recording ? <img src={Mute} alt="mute" /> : <img src={MicrophoneLogo} alt="mike" />}
                          </span>
                          <span className="text-[1rem] text-[#FFFFFF] font-[500] leading-normal">
                            {`${recording ? "Stop Recording" : "Practice Mode"}`}
                          </span>
                        </button>
                        {/* <div className="text-lg font-bold">
                          {recordFormatTime(timer)}
                        </div> */}
                        {/* <button
                          className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                          onClick={() => {stopRecording()}}
                          disabled={!recording}
                        >
                          Stop
                        </button> */}
                      </div>
                      {/* <button
                        className="bg-purple-500 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded"
                        onClick={saveAudio}
                        disabled={recording || !audio}
                      >
                        Save
                      </button> */}
                    </div>
                  </div>
                </div>

                <div className="w-full h-full max-w-[13.125rem] bg-black/60 border-[#454544]/90 rounded-[0.625rem] p-2">
                  {savedRecordings[announcement?._id]?.recordings?.length > 0 ? (
                    savedRecordings[announcement._id].recordings?.map((recording, index) => (
                      <button onClick={() => {
                        setSelectedRecordingUrl(recording.url);
                        setIsPlaying(true);
                        if (audioRef.current) {
                          audioRef.current.pause();
                          audioRef.current.currentTime = 0;
                          audioRef.current.src = recording.url;
                          setCurrentTime(0);
                          setAudioDuration(0);
                          audioRef.current.play();
                        }
                      }} key={index} className="w-full p-2 flex flex-col mb-2 border-2 rounded-md">
                        <div className="flex items-center justify-between">
                          <span className="text-white text-sm">
                            {recording.name}
                          </span>
                          <button
                            className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded "
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              const updatedRecordings = savedRecordings[announcement._id].recordings.filter(
                                (_, i) => i !== index
                              );
                              sessionStorage.setItem(
                                "recordings",
                                JSON.stringify({
                                  ...savedRecordings, [announcement._id]: {
                                    ...savedRecordings[announcement._id],
                                    recordings: updatedRecordings
                                  }
                                })
                              );
                              setSavedRecordings({
                                ...savedRecordings, [announcement._id]: {
                                  ...savedRecordings[announcement._id],
                                  recordings: updatedRecordings
                                }
                              });
                            }}
                          >
                            <Trash size={16} />
                          </button>
                        </div>
                        <div className="flex items-center justify-between">
                          <span className="text-gray-400 text-xs">
                            {recording.timestamp}
                          </span>

                        </div>
                      </button>
                    ))
                  ) : (
                    <span className="w-full flex justify-center text-white text-sm">
                      No recordings saved
                    </span>
                  )}
                </div>
              </div>
            </div>

            <Modal
              onOpened={isConfirmationModalOpen}
              onClose={() => setIsConfirmationModalOpen(false)}
              title="Confirm Replace"
              height="calc(100%-400px)"
              width="calc(100%-600px)"
            >
              <div className="flex flex-col h-full items-center  pt-4 px-4 space-y-4">
                <div className="flex items-center justify-center w-full py-5">
                  <span>{`Your recording will replace recording ${replaceIndex !== null ? replaceIndex + 1 : ''}.`}</span>
                </div>

                <div className=" border-t-[0.0625rem] border-[#2C2C2C] py-5 w-full flex justify-center space-x-[4rem] items-center">
                  <button
                    onClick={() => setIsConfirmationModalOpen(false)}
                    className="text-[#FFFFFF] px-5 py-2 text-[16px] rounded-[1.875rem] bg-[#929292]"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={handleConfirmRecording}
                    className="text-[#FFFFFF] px-5 py-2 text-[16px] rounded-[1.875rem] bg-blue"
                  >
                    OK
                  </button>
                </div>
              </div>
            </Modal>
          </>
        </PreviewModal>
      </div>
    </div>
  );
}

const PAannouncementDocs: React.FC<{
  data: any;
  status?: string;
  index: number;
  themeColor: any;
  thumbnailHeadingColor: any;
  thumbnailTitleColor: any;
  handleClick: (data: any) => void;
}> = ({ data, index, status, themeColor, thumbnailHeadingColor, thumbnailTitleColor, handleClick }) => {
  const [isLoading, setIsLoading] = useState(true);

  const { modules, currentPath } = useSelector((state: any) => state.trackedModules);

  const dispatch = useDispatch();

  useEffect(() => {
    const img = new Image();
    img.src = PAannouncementBG ?? "";
    img.onload = () => setIsLoading(false);
  }, [PAannouncementBG]);

  // Function to update session storage
  const updateSessionStorage = (updatedModules: Module[]) => {
    sessionStorage.setItem("modules", JSON.stringify(updatedModules));
  };

  // Function to handle document opening
  const handleOpenDocument = (docId: string) => {
    // Find moduleId associated with the document
    const module = findModuleByDocumentId(modules, docId);

    if (module) {
      handleLastDepthClick(module.id);
    }
  };

  // Function to find module by document ID (assuming each module has a documentId field)
  const findModuleByDocumentId = (modules: Module[], docId: string): Module | null => {
    for (let module of modules) {
      if (module.id === docId) return module;
      if (module.children) {
        const found = findModuleByDocumentId(module.children, docId);
        if (found) return found;
      }
    }
    return null;
  };

  // Function to mark last depth items as completed and update parents
  const handleLastDepthClick = (moduleId: string) => {
    const updateModulesRecursive = (modules: Module[]): Module[] => {
      return modules.map((module) => {
        if (module.id === moduleId) {
          return { ...module, status: "green" };
        }
        if (module.children) {
          const updatedChildren = updateModulesRecursive(module.children);
          const allGreen = updatedChildren.every((child) => child.status === "green");
          const anyGreen = updatedChildren.some((child) => child.status === "green" || child.status === "orange");

          return {
            ...module,
            children: updatedChildren,
            status: allGreen ? "green" : anyGreen ? "orange" : "gray",
          };
        }
        return module;
      });
    };

    const updatedModules = updateModulesRecursive(modules);

    dispatch(setModules(updatedModules));
    updateSessionStorage(updatedModules);
  };

  return (
    // {

    <button
      onClick={() => {
        handleOpenDocument(data._id);
        if (handleClick) handleClick(data);
      }}
      style={{ borderColor: `${themeColor}CC` }}
      className={`flex flex-col relative border-2 ${!themeColor && "border-[#2F0220]/80"}`}
    >
      <div
        style={{
          backgroundColor: "lightgray", // Always keep a default background
          backgroundImage: isLoading
            ? "none" // No image when loading
            : `linear-gradient(0deg, rgba(0, 0, 0, 0.70) 0%, rgba(0, 0, 0, 0.70) 100%), url(${PAannouncementBG})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          transition: "background-image 0.3s ease-in-out", // Smooth transitionyy
          color: thumbnailHeadingColor,
        }}
        className={`w-full flex flex-col max-w-[16.875rem] h-[9.375rem]  bg-[#000000]/80 ${!thumbnailHeadingColor && "text-[#FFFFFF]/[80]"}`}
      >
        {isLoading ? (
          <div className="w-full h-full flex items-center justify-center">
            <Loader loading={isLoading} />
          </div>
        ) : (
          <div className="grow w-full max-w-[16.875rem] h-[9.375rem] text-[1.25rem] flex  items-center justify-center font-[500] leading-[1.5rem] tracking-[0.00188rem]">
            {data.title}
          </div>
        )}
      </div>
      <div
        style={{ backgroundColor: `${themeColor}CC`, color: thumbnailTitleColor }}
        className={`flex w-full max-w-[16.875rem] h-[1.875rem] justify-center items-center py-[0.31rem] flex-shrink-0 font-semibold text-[1.25rem] leading-[1.25rem] tracking-[0.00188rem] ${!thumbnailTitleColor && "text-[#FFFFFF]"} ${!themeColor && "bg-[#2F0220]/80"}`}
      >
        {data.date}
      </div>
      <span className="absolute top-0 right-[5px] text-[rgba(255,255,255,0.3)] text-4xl font-extrabold">
        {index}
      </span>
      <span className={`absolute top-2 left-2 h-4 w-4 rounded-full ${status && status === "gray" ? "bg-gray-500" : status === "orange" ? "bg-orange-500" : "bg-green-500"}`}></span>
    </button>
    // }
  );
};

{
  /* <span className="flex justify-end pr-[0.63rem]">
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
  >
    <path
      d="M28.182 17H24.182C23.6515 17 23.1428 17.2107 22.7678 17.5858C22.3927 17.9609 22.182 18.4696 22.182 19V24C22.182 24.5304 22.3927 25.0391 22.7678 25.4142C23.1428 25.7893 23.6515 26 24.182 26H26.182C26.7124 26 27.2211 25.7893 27.5962 25.4142C27.9713 25.0391 28.182 24.5304 28.182 24V17ZM28.182 17C28.182 15.4165 27.8686 13.8486 27.2598 12.3868C26.651 10.925 25.759 9.59807 24.635 8.48261C23.5111 7.36714 22.1775 6.48516 20.7111 5.88751C19.2447 5.28985 17.6745 4.98833 16.091 5.00034C14.5075 4.98833 12.9373 5.28985 11.4709 5.88751C10.0045 6.48516 8.6709 7.36714 7.54695 8.48261C6.423 9.59807 5.53093 10.925 4.92217 12.3868C4.31341 13.8486 4 15.4165 4 17V24C4 24.5304 4.21071 25.0391 4.58579 25.4142C4.96086 25.7893 5.46957 26 6 26H8C8.53043 26 9.03914 25.7893 9.41421 25.4142C9.78929 25.0391 10 24.5304 10 24V19C10 18.4696 9.78929 17.9609 9.41421 17.5858C9.03914 17.2107 8.53043 17 8 17H4"
      stroke="white"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</span> */
}
