import React from "react";

type Props = {
  value: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  labelTitle?: string;
  autoFocus?: boolean;
  disabled?: boolean;
};

function InputText({
  value,
  onChange,
  placeholder,
  labelTitle,
  disabled,
}: Props) {
  return (
    <div className="flex flex-col space-y-[1.25rem] px-[0.49rem]">
      {labelTitle && <label className="font-[600] text-[1rem] text-[#242424] leading-normal" htmlFor="input">
        {labelTitle}
      </label>}
      <div
        className={`flex items-center overflow-hidden rounded-[0.375rem] border border-[#586A84] ${!disabled && "hover:border-tertiary"}`}
      >
        <input
          disabled={disabled}
          className="grow outline-none px-[1.25rem] py-[0.75rem] text-[1rem] text-[#242424] font-[400]  leading-normal bg-[#F5F5F5]"
          value={value}
          onChange={(e) => {
            if (onChange) onChange(e);
          }}
          placeholder={placeholder}
          name="input"
        />
      </div>
    </div>
  );
}

export default InputText;
