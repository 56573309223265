import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import useFolder, { ROOT_FOLDER } from "src/hooks/useFolder";
import { v4 as uuidV4 } from "uuid";
import { ListObjects, getObectURL, putObectURL } from "src/helpers/AWS_S3";
import axios from "axios";
import { setAddToChildFiles } from "src/features/fileFolder/fileFolderSlice";
import { useDispatch } from "react-redux";
//import UploadLogo from "../../../assets/export2.png";

type Props = {};

function AddFileButton({}: Props) {
  const [uploadingFiles, setUploadingFiles] = useState<any>([]);
  const [url, setUrl] = useState<any>("");
  const { accessToken } = useSelector((state: any) => state.accessToken);
  const [searchParams, setSearchParams]: any = useSearchParams();
  const folderId = searchParams.get("folderId");
  const { folder } = useSelector((state: any) => state.fileFolder);
  const { authUser } = useSelector((state: any) => state.user);

  const dispatch = useDispatch();

  const { fileFolder } = useFolder({
    folderId: folderId === "null" ? null : folderId,
    folder,
  });
  const currentFolder = fileFolder?.folder;

  useEffect(() => {
    async function init() {
      // console.log("this is the URL: ", await getObectURL("mysvg.svg"))
      // console.log("list of objects: ", await ListObjects("user-upload/"))
      // console.log("uploaded image ", await putObectURL("user-upload/", "image/jpeg"))
    }
    init();
  }, []);

  async function handleUpload(e: React.ChangeEvent<HTMLInputElement>) {
    // const file = e.target.files[0]
    // if (e.target.files && e.target.files.length > 0) { const file = e.target.files[0]}
    const file = (e.target as HTMLInputElement).files![0];

    if (currentFolder == null || file == null) return;

    const id = uuidV4();
    setUploadingFiles((prevUploadingFiles: any) => [
      ...prevUploadingFiles,
      { id: id, name: file.name, progress: 0, error: false },
    ]);
    const filePath =
      currentFolder === ROOT_FOLDER
        ? `${currentFolder.path.map((item: any) => item.name).join("/")}/${
            file.name
          }`
        : `${
            currentFolder.path.map((item: any) => item.name).join("/") &&
            `/${currentFolder.path.map((item: any) => item.name).join("/")}`
          }/${currentFolder.name}/${file.name}`;

    const UploadUrl = await putObectURL(
      `uploads/users/${authUser.oid}${filePath}`,
      file.type
    );
    console.log("upload url: ", UploadUrl, authUser.oid, filePath, file);
    axios
      .put(`${UploadUrl}`, file, {
        onUploadProgress: (ProgressEvent) => console.log(ProgressEvent.loaded),
      })
      .then((response) => console.log(response))
      .catch((err) => console.log(err));

    const SignedUrl = await getObectURL(
      `uploads/users/${authUser.oid}${filePath}`
    );

    console.log("this is the signed url: ", SignedUrl);

    setUrl(SignedUrl.split("?")[0]);

    const response = await axios.post(
      `${process.env.REACT_APP_SERVER_BASE_URL}/api/v1/file/`,
      {
        name: file.name,
        type: file.type,
        size: file.size,
        folderId,
        url: SignedUrl.split("?")[0],
      },
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    );
    console.log("Added files", response.data.data);
    dispatch(setAddToChildFiles(response.data.data));

    // const storageRef = ref(storage, `/files/${authUser.oid}/${filePath}`)

    // const uploadTask = uploadBytesResumable(storageRef, file)

    // uploadTask.on("state_changed", (snapshot) => {
    //   const progress = snapshot.bytesTransferred / snapshot.totalBytes
    //   setUploadingFiles(prevUploadingFiles => {
    //     return prevUploadingFiles.map(uploadFile => {
    //       if (uploadFile.id === id) {
    //         return { ...uploadFile, progress: progress }
    //       }
    //       return uploadFile
    //     })
    //   })
    // }, () => {
    //   setUploadingFiles(prevUploadingFiles => {
    //     return prevUploadingFiles.map(uploadFile => {
    //       if (uploadFile.id === id) {
    //         return { ...uploadFile, error: true }
    //       }
    //       return uploadFile
    //     })
    //   })
    // }, () => {
    //   setUploadingFiles(prevUploadingFiles => {
    //     return prevUploadingFiles.filter(uploadFile => {
    //       return uploadFile.id !== id
    //     })
    //   })
    //   getDownloadURL(uploadTask.snapshot.ref).then(url => {
    //     console.log(url)
    //     try {
    //       addDoc(collection(database, "files"), {
    //         url: url,
    //         name: file.name,
    //         createdAt: new Date().getTime(),
    //         folderId: currentFolder.id,
    //         userId: currentUser.uid,
    //       })
    //       // console.log("this is the response: ", res)
    //     } catch (e) {
    //       console.log(e)
    //     }
    //   })
    // })
  }

  return (
    <>
      <input
        className="hidden "
        onChange={(e) => handleUpload(e)}
        type="file"
        name=""
        id="file-upload"
      />
      <label
        htmlFor="file-upload"
        className="flex bg-[#AB0273] text-[#FFFFFF] rounded-lg px-2 py-2 hover:bg-[#AB0273]/80 justify-end"
      >
        <div className="whitespace-nowrap">File Upload</div>
      </label>
    </>
  );
}

export default AddFileButton;
