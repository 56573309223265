import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  Draggable,
  Droppable,
  DroppableStateSnapshot,
} from "react-beautiful-dnd";
import { ChevronDown } from "lucide-react";

type Item = {
  id: string;
  content: string;
  droppableId: string;
};

type DropBox = {
  id: string;
  item: Item | null;
  matchId: string | null; // itemId
  text: string;
};

type CompleteDroppableArray = DropBox[];

type Data = {
  activeDroppable: {
    items: Item[];
  };
  completeDroppable: CompleteDroppableArray;
  type: string;
};

type Props = {
  data: Data & { completeDroppable: CompleteDroppableArray };
  onDataChange: (data: Data) => void;
};

function SingleDrag({ data, onDataChange }: Props) {
  const [newItemContent, setNewItemContent] = useState<string>("");
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  const [indicationBox, setIndicationBox] = useState<string>("");
  const [selectedItem, setSelectedItem] = useState<string>("");
  const [openDropdowns, setOpenDropdowns] = useState<{
    [key: string]: boolean;
  }>({});

  useEffect(() => {
    console.log("data", data);
  }, [data]);

  const handleAddItem = () => {
    if (newItemContent.trim() === "") return;

    const newItem: Item = {
      id: (data.activeDroppable.items.length + 1).toString(),
      content: newItemContent,
      droppableId: "",
    };

    const newBox: DropBox = {
      id: uuidv4(),
      item: null,
      matchId: null,
      text: "",
    };

    const newData: Data = {
      ...data,
      activeDroppable: { items: [...data.activeDroppable.items, newItem] },
      completeDroppable: [...data.completeDroppable, newBox],
    };

    onDataChange(newData);
    setNewItemContent("");
  };

  function handleDeleteItem(id: string) {
    const updatedItems = data.activeDroppable.items.filter(
      (item) => item.id !== id
    );

    const updatedData: Data = {
      ...data,
      activeDroppable: { items: updatedItems },
    };

    onDataChange(updatedData);
  }

  function handleDroppableText(index: number, newText: string) {
    const updatedDroppables = data.completeDroppable.map((droppable, idx) =>
      idx === index ? { ...droppable, text: newText } : droppable
    );

    onDataChange({ ...data, completeDroppable: updatedDroppables });
  }

  function handleSelectDraggableItem(droppableId: string, item: Item) {
    setIndicationBox(droppableId);

    if (droppableId) {
      const updatedActiveDroppablesItems = data.activeDroppable.items.map(
        (itm) =>
          itm.id === item.id
            ? { ...itm, droppableId }
            : itm.droppableId === droppableId
            ? { ...itm, droppableId: "" }
            : itm
      );
      const updatedCompleteDroppables = data.completeDroppable.map(
        (droppable) =>
          droppable.id === droppableId
            ? { ...droppable, matchId: item.id }
            : droppable.matchId === item.id
            ? { ...droppable, matchId: null }
            : droppable
      );

      onDataChange({
        ...data,
        activeDroppable: { items: updatedActiveDroppablesItems },
        completeDroppable: updatedCompleteDroppables,
      });
    }
  }

  const handleMouseEnter = (index: number) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  const toggleDropdown = (id: string) => {
    setOpenDropdowns((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  return (
    <>
      <div className="flex items-center justify-between my-6">
        <input
          type="text"
          value={newItemContent}
          onChange={(e) => setNewItemContent(e.target.value)}
          placeholder="Enter new item..."
          className="flex-1 mr-2 p-3 bg-gray-300 text-black placeholder-gray-600 border border-gray-700 rounded-md"
        />
        <button
          onClick={handleAddItem}
          className="px-3 py-2 bg-[#AB0273] border border-[#AB0273]/30 rounded-md text-white"
        >
          Add Item +
        </button>
      </div>

      <div className="flex w-full bg-gray-200 border rounded-md">
        <div className={`w-[32%]`}>
          {data?.activeDroppable?.items?.map((item, index) => (
            <div
              className={`bg-[#E0CBD9] flex justify-between items-center m-2 p-4 min-h-[50px] ${
                item.droppableId && "!bg-[#AB0273] text-white"
              }`}
            >
              <span>{item.content}</span>
              <button onClick={() => handleDeleteItem(item.id)}>X</button>
            </div>
          ))}
        </div>

        <div className="w-[66%] m-2 flex flex-col space-y-2">
          {data?.completeDroppable?.map((droppable, index) => (
            <div className="w-full flex space-x-5">
              <div className={`w-full`}>
                <input
                  type="text"
                  placeholder="Enter your text here..."
                  value={droppable.text}
                  onChange={(e) => handleDroppableText(index, e.target.value)}
                  className="bg-transparent placeholder-black w-full p-1"
                />
                <div
                  onMouseEnter={() => handleMouseEnter(index)}
                  onMouseLeave={handleMouseLeave}
                  // onClick={() => handleClickOnDropBox(droppable.id)}
                  className={`h-[70px] border flex justify-between items-center p-4 border-slate-600 
                      } ${droppable.matchId && "bg-[#AB0273]"}`}
                >
                  {hoveredIndex === index &&
                    !droppable.item &&
                    droppable.matchId === null && (
                      <div className="text-white">
                        {data.activeDroppable.items.map((item, idx) =>
                          item.id === droppable.matchId ? item.content : null
                        )}
                      </div>
                    )}

                  <span className="text-white">{droppable.item?.content}</span>
                </div>
              </div>
              <div className="relative w-full">
                <button
                  onClick={() => {
                    toggleDropdown(droppable.id);
                  }}
                  className="flex w-full justify-between rounded-md bg-[#AB0273] text-white p-1"
                >
                  {droppable.matchId ? (
                    <span>{droppable.matchId}</span>
                  ) : (
                    <span>Select Draggable</span>
                  )}
                  <ChevronDown />
                </button>
                {openDropdowns[droppable.id] && (
                  <div className="absolute flex flex-col p-1 border border-slate-300 w-full z-10 bg-white rounded-md">
                    {data.activeDroppable.items
                      .filter((item, idx) => item.id !== droppable.matchId)
                      .map((item, idx) => (
                        <span
                          onClick={() =>
                            handleSelectDraggableItem(droppable.id, item)
                          }
                          className={`w-full rounded-md py-1 px-3 hover:bg-[#E0CBD9] border-b border-slate-300 ${
                            idx === data.activeDroppable.items.length - 1 &&
                            "border-b-0"
                          }`}
                        >
                          {item.id}
                        </span>
                      ))}
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default SingleDrag;
