import React, { CSSProperties } from 'react'
import ClipLoader from "react-spinners/ClipLoader";

type Props = {
    loading: boolean;
    color?: string;
}

const override: CSSProperties = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
};

function Loader({ loading, color = "#ffffff" }: Props) {
    return (
        <ClipLoader
            color={color}
            loading={loading}
            // cssOverride={override}
            size={150}
            aria-label="Loading Spinner"
            data-testid="loader"
        />
    )
}

export default Loader