import React, { createRef, useEffect, useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import { useDispatch } from "react-redux";
import { addBlocks, delBlocks } from "../../../features/blocks/bockSlice";
import { v4 as uuidv4 } from "uuid";

type Props = {
  id: Number
}

function SimpleText(props: Props) {
  const [text, setText] = useState(
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
  );
  const dispatch = useDispatch();
  const [{ isOver }, dropRef] = useDrop({
    accept: [
      "simple-text",
      "text-with-heading",
      "two-col-text",
      "three-col-text",
      "text-with-hyperlink",
    ],
    drop: (item: any, monitor) => {
      // setId(item.ID);
      // @ts-ignore: null is not assigned to defaultBlockTag as its type
      const { targetId, internalMonitor: {registry: {dropTargets}} } = monitor;
      // console.log(targetId, dropTargets, typeof dropTargets, monitor);
      let position = Array.from(dropTargets.keys()).indexOf(targetId)
      console.log("this is the position: ", position)
      dispatch(addBlocks({ serialId: item.ID, blockId: uuidv4(), position }));
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });

  function handleDelete() {
    console.log(props.id);
    dispatch(delBlocks(props.id));
  }

  return (
    <div className="relative grow group block ">
      <textarea
        className="outline-none h-[200px] w-full p-4 resize-none"
        placeholder="Type something here..."
        onChange={(e) => setText(e.target.value)}
        value={text}
      />
      <button
        onClick={() => handleDelete()}
        className="absolute top-0 right-0 bg-red-500 text-white p-2 border rounded-sm hidden group-hover:block"
      >
        Delete
      </button>
      <div ref={dropRef} className={`border ${isOver && "border-green-400 bg-green-200/20 h-40"}`}></div>
    </div>
  );
}

export default SimpleText;
