import { createSlice } from "@reduxjs/toolkit";

type TemplateType = {
  _id: string;
  programId: string;
  authorId: string;
  title: string;
  type: string;
  modifiedOn: number;
  createdOn: number;
  content: any;
};

const initialState = {
  catalogueCourses: [] as TemplateType[],
};
export const catalogueCoursesSlice = createSlice({
  name: "catalogueCourses",
  initialState,
  reducers: {
    setCatalogueCourses: (state, action) => {
      state.catalogueCourses = action.payload;
    },
    setAddCatalogueCourse: (state, action) => {
      state.catalogueCourses.splice(0, 0, action.payload)
    },
    setDelCatalogueCourse: (state, action) => {
      state.catalogueCourses = state.catalogueCourses.filter(
        (catalogueCourse) => catalogueCourse._id !== action.payload
      );
    },
    setEditCatalogueCourse: (state, action) => {
        state.catalogueCourses.map(
          (catalogueCourse) => {
            if(catalogueCourse._id === action.payload._id){
              return action.payload
            }
            return catalogueCourse
          }
        );
      },
  },
});

export const { setCatalogueCourses, setAddCatalogueCourse, setDelCatalogueCourse, setEditCatalogueCourse } = catalogueCoursesSlice.actions;
export default catalogueCoursesSlice.reducer;