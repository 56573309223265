import React, { useEffect, useState } from "react";
import Layout from "./Layout";
import {
  Acknowledgement,
  SelectUser,
  Splash,
  MenuScreen,
  UserAircraftMenu,
  QrhScreen,
  PreFlightScreen,
  FlightOpsTrainingScreen,
  ACtypeSystemDiffScreen,
  PAannouncement,
  EmergencyEquipScreen,
  VideoScreen,
  LocationDiagram,
  ThreeDModelScreen,
} from "./Screens";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import SplashDefaultBg from "../../assets/preview/splash_bg.jfif";
import AckDefaultBg from "../../assets/preview/Acknowledge_bg.jfif";
import UserDefaulyBg from "../../assets/preview/user_bg.jfif";
import AircraftDefaultBg from "../../assets/preview/aircraft_bg.jfif";
import PaAnnDefaultBG from "../../assets/paAnnDefaultBG.jfif";
import QrhDefaultBG from "../../assets/QRHDefaultBG.jfif";
import { SelectAircraft } from "./Screens/SelectAircraft";
import Auth from "../Auth";
import {
  setBodyFont,
  setHeadingFont,
  setLogoImageFile,
  setThemeColor,
} from "src/features/programSettings/programSettingsSlice";
// import MenuScreen from "./Screens/MenuScreen";

const DefaultBackgroundImages = new Map([
  ["splash", SplashDefaultBg],
  ["acknowledgement", AckDefaultBg],
  ["multiple user", UserDefaulyBg],
  ["multiple user", AircraftDefaultBg],
  ["aircraft", AircraftDefaultBg],
  ["qrh", QrhDefaultBG],
  // ["pre-flight", <PreFlightScreen />],
  // ["flight-ops", <FlightOpsTrainingScreen />],
  // ["announcement", PaAnnDefaultBG],
  // ["modal", <ThreeDModalScreen />],
  // ["emergency", <EmergencyEquipmentScreen />],
  // ["videos", <VideosScreen />],
  // ["location diagram", <LocationDiagram />],
  // ["system diff", <ACtypeSystemDiff />],
]);
const BackgroundImagesFileName = new Map([
  ["splash", "bgFile"],
  ["acknowledgement", "bgFile"],
  ["multiple user", "userbgFile"],
  // ["multiple user", AircraftDefaultBg],
  // ["aircraft", AircraftDefaultBg],
  // ["qrh", <QRHScreen />],
  // ["pre-flight", <PreFlightScreen />],
  // ["flight-ops", <FlightOpsTrainingScreen />],
  // ["announcement", <PAannouncementScreen />],
  // ["modal", <ThreeDModalScreen />],
  // ["emergency", <EmergencyEquipmentScreen />],
  // ["videos", <VideosScreen />],
  // ["location diagram", <LocationDiagram />],
  // ["system diff", <ACtypeSystemDiff />],
]);

function Preview({
  device,
  programID,
}: {
  device?: string;
  programID?: string;
}) {
  const { sepmId } = useParams();
  const { accessToken } = useSelector((state: any) => state.accessToken);
  const [currentScreenId, setCurrentScreenId] = useState<any>();
  const [previewData, setPreviewData] = useState<Array<any>>();
  const [currentScreenData, setCurrentScreenData] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);
  const [userType, setUserType] = useState<any | null>(null);
  const [aircraftType, setAircraftType] = useState<any | null>(null);
  const [menuType, setMenuType] = useState<any | null>(null);
  const [activeScreen, setActiveScreen] = useState<string>("user");
  const [selectedUser, setSelectedUser] = useState<any | null>(null);
  const [selectedAircraft, setSelectedAircraft] = useState<any | null>(null);
  const [filteredData, setFilteredData] = useState<Array<any>>([]);

  const [backgroundImage, setBackgroundImage] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    console.log(backgroundImage);
  }, [backgroundImage]);

  useEffect(() => {
    getPreview();
    getProgram();
  }, []);

  async function getProgram() {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_BASE_URL}/api/v1/program/detail/${sepmId}`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );

      if (response && response.data) {
        console.log(response.data.data);
        dispatch(setLogoImageFile(response.data.data.program.logoImageFile));
        dispatch(setThemeColor(response.data.data.program.color));
        dispatch(setHeadingFont(response.data.data.program.headingFont));
        dispatch(setBodyFont(response.data.data.program.bodyFont));
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function getPreview() {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_BASE_URL}/api/v1/preview/get/${
          programID || sepmId
        }`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );

      if (response && response.data) {
        console.log(
          response.data.data,
          response.data.data[0].templatesId[0],
          response.data.data[0]
        );
        setPreviewData(response.data.data);
        setCurrentScreenId(response.data.data[0].templatesId[0]);
        setCurrentScreenData(response.data.data[0]);
        setBackgroundImage(response.data.data[0].content.bgfile?.url ?? "");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    console.log(userType, aircraftType, menuType);
    console.log(currentScreenData);

    if (menuType) {
      const filteredByType = previewData?.filter((data) => {
        return data.type === menuType.type;
      });
      console.log(filteredByType);
      if (
        filteredByType &&
        filteredByType[0].type !== "location diagram" &&
        filteredByType &&
        filteredByType[0].type !== "modal"
      ) {
        const filtereddocsbyAircraft =
          filteredByType[0]?.content.documents.filter(
            (data: any) => data.aircraftId === parseInt(aircraftType.id)
          );
        console.log(filtereddocsbyAircraft);

        const filtereddocsbyUsers = filtereddocsbyAircraft.filter(
          (data: any) => {
            // console.log(data.selectedOptions);
            const temp = data.selectedOptions.filter(
              (data: any) => data.id === parseInt(userType.id)
            );
            return temp.length > 0;
          }
        );

        console.log(filtereddocsbyUsers);

        setFilteredData(filtereddocsbyUsers);

        console.log({
          ...currentScreenData,
          content: {
            ...currentScreenData.content,
            documents: filtereddocsbyUsers,
          },
        });

        setCurrentScreenData({
          ...currentScreenData,
          content: {
            ...currentScreenData.content,
            documents: filtereddocsbyUsers,
          },
        });
      } else if (
        filteredByType &&
        filteredByType[0].type === "location diagram"
      ) {
        const filtereddocsbyAircraft =
          filteredByType[0]?.content.aircraftDocuments.filter(
            (data: any) => data.aircraftId === parseInt(aircraftType.id)
          );
        console.log(filtereddocsbyAircraft);

        setFilteredData(filtereddocsbyAircraft);

        console.log({
          ...currentScreenData,
          content: {
            ...currentScreenData.content,
            aircraftDocuments: filtereddocsbyAircraft,
          },
        });

        setCurrentScreenData({
          ...currentScreenData,
          content: {
            ...currentScreenData.content,
            aircraftDocuments: filtereddocsbyAircraft,
          },
        });
      } else if (filteredByType && filteredByType[0].type === "modal") {
        // const filtereddocsbyAircraft =
        //   filteredByType[0]?.content.aircraftDocuments.filter(
        //     (data: any) => data.aircraftId === parseInt(aircraftType.id)
        //   );
        // console.log(filtereddocsbyAircraft);

        // setFilteredData(filtereddocsbyAircraft);

        // console.log({ ...currentScreenData, content: { ...currentScreenData.content, aircraftDocuments: filtereddocsbyAircraft } });

        setCurrentScreenData({ ...currentScreenData });
      }
    }
  }, [userType, aircraftType, menuType]);

  function next(nextTemplateId: string) {
    if (previewData) {
      const currentTemplateIndex =
        currentScreenData.templatesId.indexOf(nextTemplateId);
      console.log(
        "this is the current : ",
        nextTemplateId,
        currentTemplateIndex
      );
      const newScreen = previewData[currentTemplateIndex];
      console.log("this is the current screen: ", newScreen.content.bgfile);
      setBackgroundImage(newScreen.content.bgfile?.url ?? "");
      setCurrentScreenData(newScreen);
      setCurrentScreenId(nextTemplateId);
    }
  }

  function handleUserAircraft() {
    if (previewData) {
      const CurrentScreen = previewData.filter(
        (template) => template.type === "multiple user"
      )[0];
      console.log("this is the current screen: ", CurrentScreen);
      setCurrentScreenData(CurrentScreen);
      setCurrentScreenId(CurrentScreen._id);
      setBackgroundImage(CurrentScreen.content.bgfile?.url ?? "");
    }
  }

  // Write a function to get the program details by providing programId in a "program/detail" api used in navProgram component
  // Store all the four properties in a local useState.
  // Pass all these four states to apply the changes on UI.
  // Use the appropriate tailwind class for color and font conditionally using their states value respectively

  if (isLoading) return <div>Loading...</div>;

  return (
    <Auth>
      <Layout
        programName="Sample Program "
        BackgroundImage={
          backgroundImage ||
          DefaultBackgroundImages.get(currentScreenData?.type)
        }
        device={device}
        gotoMenuScreen={() => {
          setActiveScreen("menu");
          handleUserAircraft();
        }}
        gotoAircraftScreen={() => {
          setActiveScreen("aircraft");
          handleUserAircraft();
          setMenuType(null);
        }}
        gotoUserScreen={() => {
          setActiveScreen("user");
          handleUserAircraft();
          setAircraftType(null);
          setMenuType(null);
        }}
        user={userType}
        aircraft={aircraftType}
        menu={menuType}
      >
        <>
          {currentScreenData && currentScreenData.type === "splash" && (
            <Splash
              data={currentScreenData}
              onNext={(nextTemplateId) => {
                next(nextTemplateId);
              }}
            />
          )}
          {currentScreenData &&
            currentScreenData.type === "acknowledgement" && (
              <Acknowledgement
                data={currentScreenData}
                onNext={(nextTemplateId) => {
                  next(nextTemplateId);
                }}
              />
            )}
          {currentScreenData && currentScreenData.type === "multiple user" && (
            <UserAircraftMenu
              data={currentScreenData}
              AllTemplateData={previewData}
              handleChangeBackgroundImage={(image) => setBackgroundImage(image)}
              active={activeScreen}
              selectedUser={userType}
              selectedAircraft={aircraftType}
              onNext={(nextTemplateId) => {
                next(nextTemplateId);
              }}
              handleUserAircraftMenu={(user, aircraft, menu) => {
                setUserType(user);
                setAircraftType(aircraft);
                setMenuType(menu);
              }}
            />
          )}
          {currentScreenData && currentScreenData.type === "qrh" && (
            <QrhScreen
              data={currentScreenData}
              // menuType={menuType}
              // title={currentScreenData.title}
              onNext={(nextTemplateId) => {
                next(nextTemplateId);
              }}
            />
          )}
          {currentScreenData && currentScreenData.type === "pre-flight" && (
            <PreFlightScreen
              data={currentScreenData}
              // menuType={menuType}
              // title={currentScreenData.title}
              onNext={(nextTemplateId) => {
                next(nextTemplateId);
              }}
            />
          )}
          {currentScreenData && currentScreenData.type === "flight-ops" && (
            <FlightOpsTrainingScreen
              data={currentScreenData}
              // menuType={menuType}
              // title={currentScreenData.title}
              onNext={(nextTemplateId) => {
                next(nextTemplateId);
              }}
            />
          )}
          {currentScreenData && currentScreenData.type === "system diff" && (
            <ACtypeSystemDiffScreen
              data={currentScreenData}
              // menuType={menuType}
              // title={currentScreenData.title}
              onNext={(nextTemplateId) => {
                next(nextTemplateId);
              }}
            />
          )}
          {currentScreenData && currentScreenData.type === "announcement" && (
            <PAannouncement
              data={currentScreenData}
              onNext={(nextTemplateId) => {
                next(nextTemplateId);
              }}
            />
          )}
          {currentScreenData && currentScreenData.type === "videos" && (
            <VideoScreen
              data={currentScreenData}
              onNext={(nextTemplateId) => {
                next(nextTemplateId);
              }}
            />
          )}
          {currentScreenData && currentScreenData.type === "emergency" && (
            <EmergencyEquipScreen
              data={currentScreenData}
              onNext={(nextTemplateId) => {
                next(nextTemplateId);
              }}
            />
          )}
          {currentScreenData &&
            currentScreenData.type === "location diagram" && (
              <LocationDiagram
                data={currentScreenData}
                selectedAircraft={aircraftType}
                onNext={(nextTemplateId) => {
                  next(nextTemplateId);
                }}
              />
            )}
          {currentScreenData && currentScreenData.type === "modal" && (
            <ThreeDModelScreen
              data={currentScreenData}
              onNext={(nextTemplateId) => {
                next(nextTemplateId);
              }}
            />
          )}
        </>
      </Layout>
    </Auth>
  );
}

export default Preview;
