import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
};
export const loadingStateSlice = createSlice({
  name: "loadingState",
  initialState,
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
});

export const { setIsLoading } = loadingStateSlice.actions;
export default loadingStateSlice.reducer;