import React, { useEffect, useState } from 'react'
import FrontCard from './FrontCard/FrontCard';
import BackCard from './BackCard/BackCard';

type Props = {
    buttonText: string;
    frontData: string;
    backData: string;
    onChange: (buttonText: string, frontData: string, backData: string) => void;
};

function FlipCards({buttonText, frontData, backData, onChange} : Props) {
    const[ currentFrontData, setCurrentFrontData] = useState<string>(frontData);
    const[ currentBackData, setCurrentBackData] = useState<string>(backData);
    const [buttonTxt, setButtonTxt] = useState<string>(buttonText)
    const [cardType, setCardType] = useState<string>("front")

    useEffect(() => {
        console.log(frontData, backData);
        onChange(buttonTxt, currentFrontData, currentBackData)
      }, [buttonTxt, currentFrontData, currentBackData]);

    function handleCardsTypeChange(e: React.ChangeEvent<HTMLSelectElement>) {
        const newCardType = e.target.value;
        setCardType(newCardType);
    };

  return (
    <div className="flex flex-col mt-10">
      <label className="grid grid-cols-2">
        <span className="col-span- font-semibold font-800 text-[1rem] text-[#242424]">Select Card Type</span>
        <select
            className="col-span-1 w-48 border border-[#d7d7d7] font-semibold text-[#AB0273] px-2 py-1 hover:border-purple-600 rounded-md"
            value={cardType}
            onChange={handleCardsTypeChange}
        >
            <option className="text-[#AB0273] hover:bg-[#E0CBD9] bg-gray text-[1rem] font-semibold" value="front">Front</option>
            <option className="text-[#AB0273] hover:bg-[#E0CBD9] bg-gray text-[1rem] font-semibold" value="back">Back</option>
        </select>
      </label>

      <div className='bg-[#EFEFEF] relative p-5 pt-0 mt-8 rounded-lg'>
        {cardType === "front" ? (
            <FrontCard currentFrontData={currentFrontData} onChange={(currentFrontData) => setCurrentFrontData(currentFrontData)} />
        ) : (
            <BackCard currentBackData={currentBackData} onChange={(currentBackData) => setCurrentBackData(currentBackData)} />
        )}
            <input type="text" placeholder="flip card text here..." value={buttonText} onChange={(e) => setButtonTxt(e.target.value)} 
            className="bg-[#AB0273] text-[1rem] font-[400] text-white px-5 py-3 rounded-[1.5rem] placeholder-white focus:outline-none focus:border-transparent"
            style={{ minWidth: '100px', maxWidth: '250px' }}
            />
      </div>

    </div>
  )
}

export default FlipCards;
