import React, { useEffect, useState } from "react";
import InputText from "src/utils/TextInput/InputText";
import TemplateHeading from "src/utils/TextInput/TemplateHeading";
import TextArea from "src/utils/TextInput/TextArea";
import debounce from "lodash/debounce";
import axios from "axios";
import { useDispatch } from "react-redux";
import { setSaveChanges } from "src/features/saveChanges/saveChangesSlice";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import UploadButton from "src/utils/UploadButton";
import { setSelectFile } from "src/features/fileFolder/fileFolderSlice";
import VideoDropdown from "../UploadDropdowns/Video";
import { setPageState } from "src/features/templatePageState/templatePageStateSlice";
import UploadDropdowns from "../UploadDropdowns";
import RichTextEditor from "src/utils/RichTextEditor";

type Props = {};

function SplashScreen({ }: Props) {
  const [title, setTitle] = useState<string>("");
  const [buttonText, setButtonText] = useState<string>("");
  const [copyrightText, setCopyrightText] = useState<string>("");
  const [File, setFile] = useState<any | null>(null);
  const [bgFile, setbgFile] = useState<any | null>(null);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [uploadButtonFor, setUploadButtonFor] = useState<string | null>(null);

  const [content, setContent] = useState<any>();
  const [saving, setSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [state, setState] = useState<any>(null);
  const { pageState } = useSelector((state: any) => state.templatePageState);

  const { accessToken } = useSelector((state: any) => state.accessToken);
  // const {
  //   fileFolder: { file },
  // } = useSelector((state: any) => state.fileFolder);

  const dispatch = useDispatch();

  const { templateId } = useParams();

  // const debouncedAutoSave = debounce((newContent: any) => {
  //   setSaving(true);
  //   dispatch(setSaveChanges(true));
  //   // Send a POST request to your server to save the content
  //   axios
  //     .post(
  //       `http://localhost:8080/api/v1/template/save/${templateId}`,
  //       { content: newContent },
  //       {
  //         headers: { Authorization: `Bearer ${accessToken}` },
  //       }
  //     )
  //     .then(() => {
  //       setSaving(false);
  //       dispatch(setSaveChanges(false));
  //     })
  //     .catch((error) => {
  //       console.error("Auto-save failed:", error);
  //       setSaving(false);
  //       dispatch(setSaveChanges(false));
  //     });
  // }, 1000);

  // useEffect(() => {

  // }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      dispatch(setSaveChanges(true));
      setIsLoading(true);
      axios
        .post(
          `${process.env.REACT_APP_SERVER_BASE_URL}/api/v1/template/save/${templateId}`,
          { content },
          {
            headers: { Authorization: `Bearer ${accessToken}` },
          }
        )
        .then(() => {
          // dispatch(setSelectFile({ file: null }));
          dispatch(setSaveChanges(false));
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Auto-save failed:", error);
          setSaving(false);
          dispatch(setSaveChanges(false));
          setIsLoading(false);
        });
    }, 5000);
    return () => clearTimeout(timeoutId);
  }, [content]);

  // useEffect(() => {
  //   handleContentChange()
  //   console.log(state, uploadButtonFor)
  // }, [uploadButtonFor])

  useEffect(() => {
    handleContentChange();
  }, [title, buttonText, copyrightText, File, bgFile]);


  useEffect(() => {

    console.log(pageState, state);
    if (pageState) {
      if (pageState.uploadButtonFor === "VideoFile" && pageState.file) {
        setFile(pageState.file); setbgFile(pageState.bgFile);
      }
      if (!pageState.file) {
        setFile(pageState.File); setbgFile(pageState.bgFile);
      }
      if (pageState.uploadButtonFor === "bgImageFile" && pageState.file) {
        setbgFile(pageState.file); setFile(pageState.File);
      }
      if (pageState.title) setTitle(pageState.title);
      if (pageState.buttonText) setButtonText(pageState.buttonText);
      if (pageState.copyrightText) setCopyrightText(pageState.copyrightText);

      setState(pageState);
      dispatch(setPageState(null));
    }
    else {
      getContent();
    }
  }, []);

  async function getContent() {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_BASE_URL}/api/v1/template/content/${templateId}`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );

      if (response && response.data) {
        console.log("template details:", response.data.data);
        setTitle(response.data.data.content.title);
        setButtonText(response.data.data.content.buttonText);
        setCopyrightText(response.data.data.content.copyrightText);
        setFile(response.data.data.content.file);
        setbgFile(response.data.data.content.bgfile);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handleContentChange = () => {
    console.log("this is file and button: ", File, bgFile, uploadButtonFor);
    const newContent = {
      title,
      buttonText,
      copyrightText,
      file: File,
      bgfile: bgFile,
    };
    setContent(newContent);
    // Call the debounced auto-save function with the new content
    // debouncedAutoSave(newContent);
  };

  return (
    <div>
      <TemplateHeading title="Splash" />
      <div>
        <InputText
          value={title}
          onChange={(e) => {
            setTitle((prevState: string) => {
              if (prevState !== e.target.value) return e.target.value;
              return prevState;
            });
          }}
          placeholder="Enter Title"
          labelTitle="Title"
        />
        {/* <RichTextEditor value={title} onChange={(html, length) => setTitle(html)} /> */}
      </div>

      <div className="grid grid-cols-4 mt-[1.25rem] mx-[0.49rem]">
        <div className="col-span-1 font-[600]  text-[1rem] text-[#242424] leading-normal">Background Image</div>
        <div className="col-span-3 flex space-x-[0.75rem] items-center">
          <UploadButton
            name="bgImageFile"
            // handleClick={(name) => {console.log(name); setUploadButtonFor(name)}}
            type="image"
            pageState={{
              // ...state,
              uploadButtonFor: "bgImageFile",
              File,
              bgFile,
              title,
              buttonText,
              copyrightText
            }}
            handleUploadFromDevice={(file) => {
              setbgFile(file);
              // console.log(file);
            }}
          />
          <UploadDropdowns
            name="bgImageFile"
            type="image"
            pageState={{
              // ...state,
              uploadButtonFor: "bgImageFile",
              File,
              bgFile,
              title,
              buttonText,
              copyrightText
            }}
            handleCropFile={(cropFile) => setbgFile(cropFile)}
            File={bgFile} />
        </div>
      </div>

      <div className="grid grid-cols-4 mt-[1.25rem] mx-[0.49rem]">
        <div className="col-span-1 font-[600]  text-[1rem] text-[#242424] leading-normal">Add a Video</div>
        <div className="col-span-3 flex space-x-[0.75rem] items-center">
          <UploadButton
            name="VideoFile"
            // handleClick={(name) => {
            //   setUploadButtonFor(name);
            // }}
            type="video"
            pageState={{
              // ...state,
              uploadButtonFor: "VideoFile",
              File,
              bgFile,
              title,
              buttonText,
              copyrightText
            }}
            handleWebLink={(url) => {
              setFile({ url: url });
            }}
          />
          <UploadDropdowns
            name="VideoFile"
            type="video"
            pageState={{
              // ...state,
              uploadButtonFor: "VideoFile",
              File,
              bgFile,
              title,
              buttonText,
              copyrightText
            }}
            handleCropFile={(cropFile) => setFile(cropFile)}
            File={File} />
        </div>
      </div>

      {/* <div className="flex mt-4 p-4">
        <div className="flex space-x-8 items-center">
          <div className="flex space-x-8 items-center">
            <span className="text-[14px] font-bold mt-3">Add a Video</span>
            <UploadButton type="video"/>
          </div>
          <UploadDropdowns File={File}/>
        </div>
      </div> */}

      <div className="mt-[1.25rem]">
        <InputText
          value={buttonText}
          onChange={(e) => {
            setButtonText((prevState: string) => {
              if (prevState !== e.target.value) return e.target.value;
              return prevState;
            });
          }}
          placeholder="Enter Button Text"
          labelTitle="Button"
        />
      </div>

      <div className="mt-[1.25rem]">
        <TextArea
          value={copyrightText}
          onChange={(e) => {
            setCopyrightText((prevState: string) => {
              if (prevState !== e.target.value) return e.target.value;
              return prevState;
            });
          }}
          placeholder="Enter Copyright Text"
          labelTitle="Copyright Text"
        />
      </div>
    </div>
  );
}

export default SplashScreen;
