import { createSlice } from "@reduxjs/toolkit";
import { ContentState, EditorState, convertFromHTML } from "draft-js";

const value =
  "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.";

console.log(
  "this is editor state: ",
  EditorState.createWithContent(
    ContentState.createFromBlockArray(convertFromHTML(value).contentBlocks)
  )
);

const defaultstate = EditorState.createWithContent(
  ContentState.createFromBlockArray(convertFromHTML(value).contentBlocks)
);
// console.log("this is editor state: ", EditorState.createEmpty());

// const initialState = {
//   blockId: null,
//   // editorState: EditorState.createEmpty(),
//   editorState: defaultstate,
// };

const initialState = {
  blockId: null,
  editorState: null
}
export const editorStateSlice = createSlice({
  name: "editorState",
  initialState,
  reducers: {
    setEditorState: (state, action) => {
      if (action.payload.blockId) {
        console.log("editor state: ", action.payload);
        state.blockId = action.payload.blockId;
        state.editorState = action.payload.editorState;
      }
    },
  },
});

export const { setEditorState } = editorStateSlice.actions;
export default editorStateSlice.reducer;
