import React, { useState, useEffect, useRef } from "react";
import PreviewModal from "src/utils/PreviewModal";
// @ts-ignore
import PlayVideo from "../../../assets/preview/PlayCircle.svg";
import Video from "src/components/MediaLibrary/FileType/Video";
// @ts-ignore
import VideoBG from "../../../assets/videoBG.png";
import { useSelector } from "react-redux";

type Props = {
  data: any;
  onNext: (nextTemplateId: string) => void;
  //   handleSelectMenu: (menu: string) => void;
  //   selectedAircraft: any;
};

export function VideoScreen({ data, onNext }: Props) {
  const [openModal, setOpenModal] = useState(false);
  const [videoDoc, setVideoDoc] = useState<any>();
  const [filteredDoc, setFilteredDoc] = useState<any>();

  const { themeColor, headingFont, bodyFont } = useSelector((state: any) => state.programSettings);

  useEffect(() => {
    console.log(data);
  }, []);

  useEffect(() => {
    handleClickLanguage(data.content.languages[0], videoDoc);
    console.log(data.content.languages[0], videoDoc);
  }, [videoDoc]);

  useEffect(() => {
    console.log(filteredDoc);
  }, [filteredDoc]);

  function handleClickPA(data: any) {
    console.log(data);
    setVideoDoc(data);
    setOpenModal(true);
  }

  function handleClickLanguage(lang: any, videoDocument: any) {
    if (videoDocument) {
      console.log(videoDocument);
      const filteredLangDoc = videoDocument.languageDoc.filter(
        (data: any) => data.id === lang.id
      );

      console.log(filteredLangDoc[0]);
      setFilteredDoc(filteredLangDoc[0]);
    }
  }

  return (
    <div className={`relative w-full xs:h-[calc(100%-3.5rem)] sm:h-[calc(100%-4rem)] md:h-[calc(100%-4.6rem)] lg:h-[calc(100%-5.125rem)] xl:h-[calc(100%-5.625rem)] pt-[1.5rem] md:pt-[1.88rem] pl-[1.7rem] md:pl-[2.5rem] lg:pl-[3.75rem] pr-[1.7rem] md:pr-[2.44rem] ${bodyFont && `font-${bodyFont}`}`}>
      <div className="w-full h-full flex flex-col space-y-[3rem] md:space-y-[3.4rem] lg:space-y-[5rem]">
        <div className={`text-[#FFFFFF] text-[1.7rem] md:text-[2rem] lg:text-[2.5rem] font-semibold  border-l-4 md:border-l-[7px] pl-[1rem] md:pl-[1.5rem] leading-normal ${headingFont && `font-${headingFont}`}`}>
          {data.title}
        </div>
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-[2rem] gap-y-[3rem] lg:gap-y-[5rem] lg:gap-x-[4.81rem] pl-[0rem] lg:pl-[1.5rem] overflow-y-scroll no-scrollbar">
          {data.content.documents.map((data: any, idx: number) => (
            <VideoDocs
              key={data._id}
              data={data}
              index={idx + 1}
              themeColor={themeColor}
              handleClick={(data: any) => {
                handleClickPA(data);
              }}
            />
          ))}
        </div>
      </div>

      <div>
        <PreviewModal
          onOpened={openModal}
          onClose={() => setOpenModal(false)}
          showCrossIcon={true}
        >
          <>
            <div
              style={{
                background: `linear-gradient(0deg, rgba(0, 0, 0, 0.70) 0%, rgba(0, 0, 0, 0.70) 100%), url(${data.content.bgfile?.url ?? ""})`,
                backgroundPosition: "50%",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundColor: "lightgray",
                color: "#146793",
              }}
              className="w-full h-full pt-[1.5rem] md:pt-[1.88rem] px-[1.5rem] md:px-[2.5rem] flex flex-col space-y-[1.38rem] overflow-y-scroll no-scrollbar"
            >
              <div className="md:px-[1rem]">
                <div className="text-[#FFFFFF] text-[1.2rem] md:text-[1.6rem] lg:text-[2rem] font-semibold  border-l-4 md:border-l-[7px] pl-[0.8rem] md:pl-[1.3rem] leading-normal flex xs:flex-col sm:flex-col md:flex-row xs:items-start sm:items-start md:items-center md:space-x-1">
                  <span className="">{data.title} - </span>
                  <span className=""> {videoDoc?.title}</span>
                </div>
              </div>
              <div className="h-full flex items-center">
                <Video themeColor={themeColor} file={filteredDoc?.file} />
              </div>
            </div>
          </>
        </PreviewModal>
      </div>
    </div>
  );
}

const VideoDocs: React.FC<{
  data: any;
  index: number;
  themeColor: any;
  handleClick: (data: any) => void;
}> = ({ data, index, themeColor, handleClick }) => {
  return (
    // {

    <div className=" flex flex-col relative">
      <div 
      style={{
        background: `linear-gradient(0deg, rgba(0, 0, 0, 0.30) 0%, rgba(0, 0, 0, 0.30) 100%), url(${VideoBG})`,
        backgroundPosition: "50%",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundColor: "lightgray",
      }}
      className="w-full flex flex-col max-w-[16.875rem] h-[9.375rem]  bg-[#000000]/[55] text-[#FFFFFF]/[87]">
        {/* <div className="w-[16.875rem] text-[2rem] pr-1 pt-1 text-[#FFFFFF]/30 font-extrabold leading-[1.5rem] flex-shrink-0 flex justify-end items-start">
          {count + 1}
        </div> */}
        <div className="grow w-full max-w-[16.875rem] h-[9.375rem] p-[0.5rem] text-[1.25rem] flex  items-center justify-center font-[500] leading-[1.5rem] tracking-[0.00188rem]">
          {data.title}
        </div>
        <button
          onClick={() => {
            if (handleClick) handleClick(data);
          }}
          className="flex justify-center w-full"
        >
          <img src={PlayVideo} alt="playvideo" />
        </button>
      </div>
      <div style={{backgroundColor: themeColor, opacity: 0.8}} className={`flex w-full max-w-[16.875rem] h-[1.875rem] justify-center items-center flex-shrink-0 text-[#E1E1E1] font-semibold text-[1.25rem] leading-[1.25rem] tracking-[0.00188rem] ${!themeColor && "bg-[#2F0220]/80"}`}>
        {data.date}
      </div>
      <span className="absolute top-0 right-[5px] text-[rgba(255,255,255,0.3)] text-4xl font-extrabold">{index}</span>
    </div>
    // }
  );
};
