import React, { useEffect, useState, useRef } from "react";
import Auth from "../Auth";
import Layout from "../Layout";
import { useParams } from "react-router-dom";
import { useAccount, useMsal } from "@azure/msal-react";
import axios from "axios";
import Table from "src/utils/Table";
import CopyLogo from "../../assets/copy.svg";
import MoveLogo from "../../assets/move.svg";
import StarredLogo from "../../assets/star.svg";
import DeleteLogo from "../../assets/delete.svg";
import ShareLogo from "../../assets/share.png";
import CheckedLogo from "../../assets/check-box-bold.png";
import DownArrow from "../../assets/down-arrow.png";
import Modal from "src/utils/Modal";
import MultipleCheck from "src/utils/MultipleCheck";
import CatalogueIcon from "../../assets/SideBar/Book.svg";
import CreateIcon from "../../assets/Plus.svg";

import { useSelector, useDispatch } from "react-redux";
import {
  setAddCatalogueCourse,
  setCatalogueCourses,
} from "src/features/catalogueCourses/catalogueCoursesSlice";
import { setActiveSidebar } from "src/features/activeSidebar/activeSidebarSlice";
import { setLog } from "src/features/errorLog/errorLogSlice";
import { BucketLocationConstraint } from "@aws-sdk/client-s3";

type Props = {
  handleProgram?: (program: any) => void;
};

function BusinessUnit({ handleProgram }: Props) {
  const [businessUnit, setBusinessUnit] = useState<any>();

  const { businessUnitId } = useParams();

  const { instance, accounts } = useMsal();

  const { accessToken } = useSelector((state: any) => state.accessToken);
  const { catalogueCourses } = useSelector(
    (state: any) => state.catalogueCourses
  );

  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [openCoursePopup, setOpenCoursePopup] = useState(false);

  const [checkedItems, setCheckedItems] = useState<Array<any>>([]);
  const [checkedAllItems, setCheckedAllItems] = useState<any>([]);

  const [openMoveTo, setOpenMoveTo] = useState(false);
  const [openCopyTo, setOpenCopyTo] = useState(false);
  const [crossFunction, setCrossFunction] = useState(false);
  const [menuActive, setMenuActive] = useState<String>("");

  const [catalogues, setCatalogues] = useState<Array<any>>([]);

  const [disable, setDisable] = useState(false);
  const [tableData, setTableData] = useState<Array<any>>([]);

  const [openCreateDropdown, setOpenCreateDropdown] = useState(false);

  const dispatch = useDispatch();

  const account = useAccount(accounts[0] || {});

  useEffect(() => {
    dispatch(setActiveSidebar(businessUnitId));
  }, [businessUnitId]);

  useEffect(() => {
    getPrograms();
  }, [businessUnitId]);

  useEffect(() => {
    getBusinessUnits();
  }, []);

  useEffect(() => {
    setTableData(catalogueCourses);
  }, [catalogueCourses]);

  async function getBusinessUnits() {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_BASE_URL}/api/v1/course/getbusinessunit`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );

      if (response && response.data) {
        // const myUnits = response.data.data as BusinessUnitType[]
        // setPrograms(response.data.data.programs);
        // setBusinessUnit(response.data.data.businessUnit);
        setCatalogues(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function getPrograms() {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_BASE_URL}/api/v1/course/getallcourses/${businessUnitId}`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );

      if (response && response.data) {
        // const myUnits = response.data.data as BusinessUnitType[]
        // setPrograms(response.data.data.programs);
        setBusinessUnit(response.data.data.businessUnit);
        dispatch(setCatalogueCourses(response.data.data.combinedData));
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function handleCreate() {
    let max = -1;
    catalogueCourses.forEach((item: any) => {
      if (item.title.includes("Sepm")) {
        const value = parseInt(item.title.split("(")[1] || 0);
        if (max < value) max = value;
      }
    });
    setDisable(true);
    await axios
      .post(
        `${process.env.REACT_APP_SERVER_BASE_URL}/api/v1/program/${businessUnitId}`,
        {
          title: "Sepm" + `${max !== -1 ? `(${max + 1})` : ""}`,
          imageUrl: "",
          modifiedOn: new Date().getTime(),
        },
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      )
      .then((res) => res.data)
      .then((response) => {
        // setPrograms((prevState: any) => [response.data, ...prevState])
        dispatch(setAddCatalogueCourse(response.data));
        dispatch(setLog({ mode: "success", message: response.message }));
        setDisable(false);
      })
      .catch((err) => {
        console.error(err);
        dispatch(
          setLog({ mode: "failure", message: err.response.data.message })
        );
        setDisable(false);
      });
  }

  async function handleCreatePage() {
    let max = -1;
    catalogueCourses.forEach((item: any) => {
      if (item.title.includes("Page")) {
        const value = parseInt(item.title.split("(")[1] || 0);
        if (max < value) max = value;
      }
    });
    setDisable(true);
    await axios
      .post(
        `${process.env.REACT_APP_SERVER_BASE_URL}/api/v1/page/${businessUnitId}`,
        {
          title: "Page" + `${max !== -1 ? `(${max + 1})` : ""}`,
          imageUrl: "",
          modifiedOn: new Date().getTime(),
        },
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      )
      .then((res) => res.data)
      .then((response) => {
        // setPrograms((prevState: any) => [response.data, ...prevState])
        dispatch(setAddCatalogueCourse(response.data));
        dispatch(setLog({ mode: "success", message: response.message }));
        setDisable(false);
      })
      .catch((err) => {
        console.error(err);
        dispatch(
          setLog({ mode: "failure", message: err.response.data.message })
        );
        setDisable(false);
      });
  }

  async function handleCreateCourse() {
    let max = -1;
    catalogueCourses.forEach((item: any) => {
      if (item.title.includes("Course")) {
        const value = parseInt(item.title.split("(")[1] || 0);
        if (max < value) max = value;
      }
    });
    setDisable(true);
    await axios
      .post(
        `${process.env.REACT_APP_SERVER_BASE_URL}/api/v1/course1/${businessUnitId}`,
        {
          title: "Course" + `${max !== -1 ? `(${max + 1})` : ""}`,
          imageUrl: "",
          modifiedOn: new Date().getTime(),
        },
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      )
      .then((res) => res.data)
      .then((response) => {
        // setPrograms((prevState: any) => [response.data, ...prevState])
        dispatch(setAddCatalogueCourse(response.data));
        dispatch(setLog({ mode: "success", message: response.message }));
        setDisable(false);
      })
      .catch((err) => {
        console.error(err);
        dispatch(
          setLog({ mode: "failure", message: err.response.data.message })
        );
        setDisable(false);
      });
    setOpenCoursePopup(false);
  }

  //Delete Functionality
  function handleDeletePopup() {
    setOpenDeletePopup(true);
  }

  async function handleDelete() {
    await axios
      .post(
        `${process.env.REACT_APP_SERVER_BASE_URL}/api/v1/program/delete/many`,
        {
          programs: checkedItems,
        },
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      )
      .then((res) => res.data)
      .then((response) => {
        const newPrograms = catalogueCourses.filter((course: any) => {
          const deletedItems = checkedItems.filter(
            (item) => item._id === course._id
          );
          if (deletedItems.length === 0) return course;
        });
        dispatch(setCatalogueCourses(newPrograms));
        setCheckedItems([]);
        dispatch(setLog({ mode: "success", message: response.message }));
        window.location.reload();
        setOpenDeletePopup(false);
      })
      .catch((err) => {
        console.error(err);
        dispatch(
          setLog({ mode: "failure", message: err.response.data.message })
        );
      });
  }

  async function handleMoveTo(catalogue: any) {
    await axios
      .post(
        `${process.env.REACT_APP_SERVER_BASE_URL}/api/v1/program/moveto/catalogue`,
        {
          catalogue: catalogue,
          programs: checkedItems,
        },
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      )
      .then((res) => res.data)
      .then((response) => {
        dispatch(setLog({ mode: "success", message: response.message }));
        window.location.reload();
        setOpenMoveTo(false);
      })
      .catch((err) => {
        console.log(err);
        dispatch(
          setLog({ mode: "failure", message: err.response.data.message })
        );
      });
  }

  async function handleCopyTo(catalogue: any) {
    await axios
      .post(
        `${process.env.REACT_APP_SERVER_BASE_URL}/api/v1/program/copyto/catalogue`,
        {
          catalogue: catalogue,
          programs: checkedItems,
        },
        {
          headers: { Authorization: `bearer ${accessToken}` },
        }
      )
      .then((res) => res.data)
      .then((Response) => {
        dispatch(setLog({ mode: "success", message: Response.message }));
        window.location.reload();
        setOpenCopyTo(false);
      })
      .catch((err) => {
        console.error(err);
        dispatch(
          setLog({ mode: "failure", message: err.response.data.message })
        );
      });
  }

  async function handleFavorite() {
    await axios
      .post(
        `${process.env.REACT_APP_SERVER_BASE_URL}/api/v1/program/multifavorite/post`,
        {
          programs: checkedItems,
        },
        {
          headers: { Authorization: `bearer ${accessToken}` },
        }
      )
      .then((res) => res.data)
      .then((Response) => {
        dispatch(setLog({ mode: "success", message: Response.message }));
        window.location.reload();
      })
      .catch((err) => {
        console.error(err);
        dispatch(
          setLog({ mode: "failure", message: err.response.data.message })
        );
      });
  }

  const moveRef = React.createRef<HTMLDivElement>();

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!moveRef?.current?.contains(event.target as Node)) {
        setOpenMoveTo(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      // Clean up the event listener when the component unmounts
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [moveRef]);

  const copyRef = React.createRef<HTMLDivElement>();

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!copyRef?.current?.contains(event.target as Node)) {
        setOpenCopyTo(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      // Clean up the event listener when the component unmounts
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [copyRef]);

  function handleSearch(searchTerm: string = "") {
    const searchResults =
      catalogueCourses.length !== 0
        ? catalogueCourses.filter((data: any) => {
          const optionName = data.title.toLowerCase();
          const searchTermChars = searchTerm.toLowerCase().split("");

          let searchIndex = 0;
          for (const char of optionName) {
            if (char === searchTermChars[searchIndex]) {
              searchIndex++;
            }

            if (searchIndex === searchTermChars.length) {
              return true; // All characters in the search term found in order
            }
          }

          return false;
        })
        : [];

    if (searchResults) setTableData(searchResults);
  }

  const menuRef = React.createRef<HTMLDivElement>();

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!menuRef?.current?.contains(event.target as Node)) {
        setOpenCreateDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      // Clean up the event listener when the component unmounts
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuRef]);


  return (
    <Auth>
      <Layout type="businessunit">
        {(callback) => (
          <>
            <div className="col-span-12 overflow-auto w-full h-[calc(100% - 7.75rem)] rounded-[1.25rem] mt-[1.25rem] mb-[0.875rem] mr-[0.625rem] px-[0.63rem] py-[1.25rem] shadow-lg text-secondary bg-[#FFF]">
              <section className="flex flex-col space-y-[0.81rem]  overflow-auto w-full h-full no-scrollbar">
                <div className="flex flex-col px-[1.37rem]">
                  <div className="text-[1.5rem] font-[600] leading-normal text-[#000]">
                    {businessUnit?.title}
                  </div>
                  <div className="flex items-center justify-between py-[1.25rem]">
                    {checkedItems.length > 0 ? (
                      <div className="flex space-x-[0.94rem] grow">
                        <form className="group relative w-full max-w-[10rem]">
                          <input
                            className="focus:ring-2 focus:ring-blue-500 focus:outline-none appearance-none w-full text-[1.25rem] font-[400] leading-normal text-[#242424] placeholder-[#7D8FA9] rounded-[0.375rem] py-[0.75rem] pl-[1.25rem] ring-1 ring-[#586A84] bg-[#F5F5F5] shadow-sm"
                            type="text"
                            aria-label="Search"
                            placeholder="Search....."
                            onChange={(e) => handleSearch(e.target.value)}
                          />
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="32"
                            height="32"
                            viewBox="0 0 32 32"
                            fill="none"
                            className="absolute right-[0.75rem] top-[0.56rem]  pointer"
                          >
                            <path
                              d="M14.4995 25C20.2985 25 24.9995 20.299 24.9995 14.5C24.9995 8.70101 20.2985 4 14.4995 4C8.70052 4 3.99951 8.70101 3.99951 14.5C3.99951 20.299 8.70052 25 14.4995 25Z"
                              stroke="#929292"
                              stroke-width="3"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M21.9238 21.925L27.9989 28.0001"
                              stroke="#929292"
                              stroke-width="3"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </form>
                        <div className="grow flex space-x-[1.81rem]">
                          <div className="flex items-center space-x-[1.56rem]">
                            <button
                              onClick={() => handleDeletePopup()}
                              className="flex space-x-[0.5rem] p-1 rounded-md hover:bg-[#E0CBD9]"
                            >
                              <span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  <path
                                    d="M20.25 5.625L3.75 5.62501"
                                    stroke="#C2262E"
                                    stroke-width="3"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M8.25 1.875H15.75"
                                    stroke="#C2262E"
                                    stroke-width="3"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M18.75 5.625V19.5C18.75 19.6989 18.671 19.8897 18.5303 20.0303C18.3897 20.171 18.1989 20.25 18 20.25H6C5.80109 20.25 5.61032 20.171 5.46967 20.0303C5.32902 19.8897 5.25 19.6989 5.25 19.5V5.625"
                                    stroke="#C2262E"
                                    stroke-width="3"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>
                              </span>
                              <span className="text-[#C2262E] text-[1rem] font-[600] leading-normal">
                                Delete
                              </span>
                            </button>
                            <button
                              onClick={handleFavorite}
                              className="flex space-x-[0.5rem] p-1 rounded-md hover:bg-[#E0CBD9]"
                            >
                              <span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  <path
                                    d="M12.4135 17.8812L17.1419 20.8769C17.7463 21.2598 18.4967 20.6903 18.3173 19.9847L16.9512 14.6108C16.9127 14.4611 16.9173 14.3036 16.9643 14.1564C17.0114 14.0092 17.0991 13.8783 17.2172 13.7787L21.4573 10.2496C22.0144 9.78588 21.7269 8.86126 21.0111 8.81481L15.4738 8.45544C15.3247 8.44479 15.1816 8.39198 15.0613 8.30317C14.941 8.21437 14.8484 8.09321 14.7943 7.95382L12.7292 2.75323C12.673 2.60528 12.5732 2.4779 12.443 2.38802C12.3127 2.29814 12.1582 2.25 12 2.25C11.8418 2.25 11.6873 2.29814 11.557 2.38802C11.4268 2.4779 11.327 2.60528 11.2708 2.75323L9.20568 7.95382C9.15157 8.09321 9.05897 8.21437 8.93868 8.30317C8.81838 8.39198 8.67533 8.44479 8.52618 8.45544L2.98894 8.81481C2.27315 8.86126 1.9856 9.78588 2.54272 10.2496L6.78278 13.7787C6.90095 13.8783 6.9886 14.0092 7.03566 14.1564C7.08272 14.3036 7.08727 14.4611 7.0488 14.6108L5.78188 19.5945C5.56667 20.4412 6.46715 21.1246 7.19243 20.6651L11.5865 17.8812C11.71 17.8025 11.8535 17.7607 12 17.7607C12.1465 17.7607 12.29 17.8025 12.4135 17.8812V17.8812Z"
                                    stroke="black"
                                    stroke-width="3"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>
                              </span>
                              <span className="text-black text-[1rem] font-[600] leading-normal">
                                Favorite
                              </span>
                            </button>
                            {checkedItems.length === 1 && (
                              <button className="flex space-x-[0.5rem] p-1 rounded-md hover:bg-[#E0CBD9]">
                                <span>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                  >
                                    <path
                                      d="M16.5 14.25L21 9.75L16.5 5.25"
                                      stroke="black"
                                      stroke-width="3"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M18 20.25H3.75C3.55109 20.25 3.36032 20.171 3.21967 20.0303C3.07902 19.8897 3 19.6989 3 19.5V8.25"
                                      stroke="black"
                                      stroke-width="3"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M7.0332 16.5C7.53271 14.568 8.65985 12.8567 10.2376 11.635C11.8153 10.4132 13.7542 9.75015 15.7497 9.75H20.9997"
                                      stroke="black"
                                      stroke-width="3"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                </span>
                                <span className="text-black text-[1rem] font-[600] leading-normal">
                                  Share
                                </span>
                              </button>
                            )}
                            <div
                              ref={moveRef}
                            // className={`hover:bg-tertiary rounded-md ${
                            //   openCopyTo && "bg-tertiary"
                            // }`}
                            >
                              <button
                                disabled={catalogues.length == 1}
                                className={`flex space-x-[0.5rem] p-1 rounded-md hover:bg-[#E0CBD9] disabled:bg-transparent ${openMoveTo && "bg-[#E0CBD9]"
                                  } `}
                                onClick={() => setOpenMoveTo(!openMoveTo)}
                              >
                                <span>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                  >
                                    <path
                                      d="M14.6519 19.0984L12.0003 21.75L9.34863 19.0984"
                                      stroke="black"
                                      stroke-width="3"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M12 15V21.75"
                                      stroke="black"
                                      stroke-width="3"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M9.34863 4.90163L12.0003 2.25L14.6519 4.90163"
                                      stroke="black"
                                      stroke-width="3"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M12 9V2.25"
                                      stroke="black"
                                      stroke-width="3"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M4.90163 14.6516L2.25 12L4.90163 9.34839"
                                      stroke="black"
                                      stroke-width="3"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M9 12H2.25"
                                      stroke="black"
                                      stroke-width="3"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M19.0986 9.34839L21.7503 12L19.0986 14.6516"
                                      stroke="black"
                                      stroke-width="3"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M15 12H21.75"
                                      stroke="black"
                                      stroke-width="3"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                </span>
                                <span className="text-black text-[1rem] font-[600] leading-normal">
                                  Move to
                                </span>
                              </button>
                              {openMoveTo && (
                                <div className="overflow-hidden z-10 absolute top-[9rem] right-auto bg-white border shadow-lg p-2 rounded-lg min-w-[12rem]">
                                  <ul className="w-full flex flex-col items-start justify-center">
                                    {catalogues
                                      .filter(
                                        (catalogue) =>
                                          catalogue._id !== businessUnitId
                                      )
                                      .map((catalogue) => (
                                        <li className="cursor-pointer flex items-center hover:text-[#242424] w-full rounded-md p-1 hover:bg-[#E0CBD9] space-x-3">
                                          <img
                                            className="w-5 h-5"
                                            src={CatalogueIcon}
                                            alt="logo"
                                          />
                                          <button
                                            onClick={() =>
                                              handleMoveTo(catalogue)
                                            }
                                          >
                                            <div>{catalogue.title}</div>
                                          </button>
                                        </li>
                                      ))}
                                  </ul>
                                </div>
                              )}
                            </div>
                            <div
                              ref={copyRef}
                            // className={`hover:bg-tertiary rounded-md ${
                            //   openCopyTo && "bg-tertiary"
                            // }`}
                            >
                              <button
                                disabled={catalogues.length == 1}
                                className={`flex space-x-[0.5rem] p-1 rounded-md hover:bg-[#E0CBD9] disabled:bg-transparent ${openCopyTo && "bg-[#E0CBD9]"
                                  }`}
                                onClick={() => setOpenCopyTo(!openCopyTo)}
                              >
                                <span>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                  >
                                    <path
                                      d="M15.75 15.7493H20.25V3.74933H8.25V8.24933"
                                      stroke="black"
                                      stroke-width="3"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M15.75 8.24945H3.75V20.2495H15.75V8.24945Z"
                                      stroke="black"
                                      stroke-width="3"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                </span>
                                <span className="text-black text-[1rem] font-[600] leading-normal">
                                  Copy to
                                </span>
                              </button>
                              {openCopyTo && (
                                <div className="overflow-hidden z-10 absolute top-[9rem] right-auto bg-white border shadow-lg p-2 rounded-lg min-w-[12rem]">
                                  <ul className="w-full flex flex-col items-start justify-center">
                                    {catalogues
                                      .filter(
                                        (catalogue) =>
                                          catalogue._id !== businessUnitId
                                      )
                                      .map((catalogue) => (
                                        <li className="cursor-pointer flex items-center hover:text-[#242424] w-full rounded-md p-1 hover:bg-[#E0CBD9] space-x-3">
                                          <img
                                            className="w-5 h-5"
                                            src={CatalogueIcon}
                                            alt="logo"
                                          />
                                          <button
                                            onClick={() =>
                                              handleCopyTo(catalogue)
                                            }
                                          >
                                            <div>{catalogue.title}</div>
                                          </button>
                                        </li>
                                      ))}
                                  </ul>
                                </div>
                              )}
                            </div>
                          </div>
                          <button
                            className=""
                            onChange={(e) => {
                              setCheckedAllItems([]);
                            }}
                            onClick={() => setCrossFunction(true)}
                          >
                            <div className="flex border-2 border-solid border-black rounded-[1.25rem] p-[0.3125rem] space-x-2 items-center justify-center">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <path
                                  d="M18.75 5.25L5.25 18.75"
                                  stroke="black"
                                  stroke-width="3"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M18.75 18.75L5.25 5.25"
                                  stroke="black"
                                  stroke-width="3"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                              <span className="text-black font-[500] text-[1rem] leading-normal">
                                {checkedItems.length} selected
                              </span>
                            </div>
                          </button>
                        </div>
                      </div>
                    ) : (
                      <form className="group w-full max-w-[20.625rem] lg:max-w-[38.625rem] xl:max-w-[46.625rem] 2xl:max-w-[50.625rem] relative">
                        <input
                          className="focus:ring-2 focus:ring-blue-500 focus:outline-none appearance-none w-full text-[1.25rem] font-[400] leading-normal text-[#242424] placeholder-[#7D8FA9] rounded-[0.375rem] py-[0.75rem] pl-[1.25rem] ring-1 ring-[#586A84] bg-[#F5F5F5] shadow-sm"
                          type="text"
                          aria-label="Search"
                          placeholder="Search....."
                          onChange={(e) => handleSearch(e.target.value)}
                        />
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="32"
                          height="32"
                          viewBox="0 0 32 32"
                          fill="none"
                          className="absolute right-[0.75rem] top-[0.56rem]  pointer"
                        >
                          <path
                            d="M14.4995 25C20.2985 25 24.9995 20.299 24.9995 14.5C24.9995 8.70101 20.2985 4 14.4995 4C8.70052 4 3.99951 8.70101 3.99951 14.5C3.99951 20.299 8.70052 25 14.4995 25Z"
                            stroke="#929292"
                            stroke-width="3"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M21.9238 21.925L27.9989 28.0001"
                            stroke="#929292"
                            stroke-width="3"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </form>
                    )}

                    <div ref={menuRef} className="flex relative ">
                      <button
                        disabled={disable}
                        // onClick={() => handleCreate()}
                        onClick={() => setOpenCreateDropdown(!openCreateDropdown)}
                        className="flex items-center justify-center space-x-1 rounded-[1.875rem] px-3 py-2 font-[600] border-tertiary border-2 text-[#FFFFFF] text-[1rem] hover:bg-tertiary/80 bg-tertiary leading-normal"
                      >
                        <img src={CreateIcon} alt="createProgram" />
                        <span>New</span>{" "}
                      </button>
                      {openCreateDropdown &&
                        <div className="absolute w-[9.5rem] top-[50px] left-[-16px] px-[0.7rem] py-[0.65rem] space-y-1 flex flex-col border border-black/30 bg-[#F5F5F5] font-medium text-[1rem] text-[#242424] hover:text-black leading-normal rounded-[1.25rem] z-10">
                          <button onClick={() => handleCreate()} className="flex justify-start items-center rounded-md p-1 hover:bg-[#E0CBD9] hover:rounded[1.25rem]">SEPM</button>
                          <button onClick={() => setOpenCoursePopup(true)} className="flex justify-start items-center rounded-md p-1 hover:bg-[#E0CBD9] hover:rounded[1.25rem]">Course</button>
                          <button onClick={() => handleCreatePage()} className="flex justify-start items-center rounded-md p-1 hover:bg-[#E0CBD9] hover:rounded[1.25rem]">Page</button>
                          <button className="flex justify-start items-center rounded-md p-1 hover:bg-[#E0CBD9] hover:rounded[1.25rem]">Assessment</button>
                          {/* <button className="flex justify-start items-center rounded-md p-1 hover:bg-[#E0CBD9] hover:rounded[1.25rem]">Import PPT</button> */}
                          {/* <button className="flex justify-start items-center rounded-md p-1 hover:bg-[#E0CBD9] hover:rounded[1.25rem]">Import epub</button> */}
                        </div>
                      }
                    </div>
                  </div>
                </div>
                {
                  tableData.length > 0 ?
                    <Table
                      businessUnitTitle={businessUnit?.title}
                      deSelectAll={crossFunction}
                      checkedItems={(data: any) => {
                        setCheckedItems(data);
                        setCrossFunction(false);
                      }}
                      handleProgram={(program) => {
                        if (callback) callback(program);
                      }}
                      checkedAllItems={(data: any) => setCheckedAllItems(data)}
                      data={tableData}
                      onChange={(data: any) => {
                        dispatch(setCatalogueCourses(data));
                      }}
                    />
                    :
                    <div className="h-full flex items-center justify-center">
                      You have no Courses/Resources, add new to get started
                    </div>
                }
              </section>

              <Modal
                onOpened={openDeletePopup}
                onClose={() => setOpenDeletePopup(false)}
                title="Confirm Delete"
                height="calc(100%-400px)"
              >
                <div className="flex flex-col h-full items-center pt-4 px-4 space-y-4">
                  <div className="flex items-center justify-center w-full py-5">
                    <span>Are you sure you want to delete this Program?</span>
                  </div>

                  <div className=" border-t-[0.0625rem] border-[#2C2C2C] py-5 w-full flex justify-center space-x-[4rem] items-center">
                    <button
                      onClick={() => setOpenDeletePopup(false)}
                      className="text-[#FFFFFF] px-5 py-2 text-[16px] rounded-[1.875rem] bg-[#929292]"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={() => handleDelete()}
                      className="text-[#FFFFFF] px-5 py-2 text-[16px] rounded-[1.875rem] bg-red-600"
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </Modal>
              <Modal
                onOpened={openCoursePopup}
                onClose={() => setOpenCoursePopup(false)}
                title="Create Course"
                height="calc(100%-400px)"
              >
                <div className="flex flex-col h-full items-start p-6 space-y-4">
                  <button onClick={() => handleCreateCourse()} className="flex items-center justify-start w-full p-2 hover:bg-gray-300 text-[1.2rem]">
                    <span>Create Blank Course</span>
                  </button>
                  <button className="flex items-center justify-start w-full p-2 hover:bg-gray-300 text-[1.2rem]">
                    <span>Import PPT</span>
                  </button>
                  <button className="flex items-center justify-start w-full p-2 hover:bg-gray-300 text-[1.2rem]">
                    <span>Import EPUB</span>
                  </button>
                </div>
              </Modal>
            </div>
          </>
        )}
      </Layout>
    </Auth>
  );
}

export default BusinessUnit;
