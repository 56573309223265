export const uuid = () => {
    let dt = new Date().getTime();
  
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g,
      function (c) {
        var r = (dt + Math.random() * 16) % 16 | 0;
        dt = Math.floor(dt / 16);
        return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
      },
    );
  };

  // Creating a function to get timestamp in milliseconds and return a string of date, time and day of the week in the format of "2022-01-01 12:00:00 AM, Saturday"
  export const getFormattedDate = (timestamp: number) => {
    const date = new Date(timestamp);
    const options: any = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true,
      weekday: 'long',
    };
    
    return date.toLocaleString('en-US', options);
  };