import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from "react-router-dom";
import { Button } from 'src/components/ui/button';
// @ts-ignore
import {ReactComponent as Playback} from "src/assets/Course/play_btn.svg";
// @ts-ignore
import {ReactComponent as TableOfContent} from "src/assets/Course/toc_btn.svg";
import { FileSystemNode } from '../Navigation';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from 'src/components/ui/dropdown-menu'
import { ChevronDown, ChevronRight, File } from 'lucide-react';

type Props = {
  children: React.ReactNode;
  handleBack: () => void;
  handleNext: () => void; 
  isSubmitType?: any;
  isNextType?: any;
  haveResponse?: any;
  haveSubmitted?: any;
  data: any;
  submitted?: boolean;
  coursePreview?: boolean;
  handleTopicChange: (topic: any) => void;
  courseData: any;
};


const CoursePreviewPlayer = ({ children, handleBack, handleNext, handleTopicChange, courseData, isSubmitType, isNextType, haveResponse, haveSubmitted, data, submitted, coursePreview }: Props) => {

  const [timer, setTimer] = useState<number>(data?.timer);
  const [isTimeOver, setIsTimeOver] = useState<boolean>(false);
  const [tocOpen, setTocOpen] = useState(false);
  const navigate = useNavigate();
  const { userId, courseId } = useParams();

  const playerRef = useRef<HTMLDivElement>(null);
  const [playerDimensions, setplayerDimensions] = useState({
    width: 0,
    height: 0,
  });

  const playerContainerRef = useRef<HTMLDivElement>(null);
  const [playerContainerDimensions, setplayerContainerDimensions] = useState({
    width: 0,
    height: 0,
  });

  const { accessToken } = useSelector((state: any) => state.accessToken);
  const [courseSettings, setCourseSettings] = useState<any>();

  const sidebarRef = useRef<HTMLDivElement>(null);

  const [expandedNodes, setExpandedNodes] = useState<Set<string>>(new Set());

  useEffect(() => {
    const handleResize = () => {
      const player = playerRef.current;
      const playerContainer = playerContainerRef.current;
      if (playerContainer) {
        const playerContainerWidth = playerContainer.offsetWidth;
        const playerContainerHeight = (playerContainerWidth * 9) / 16 + 100;
        setplayerContainerDimensions({
          width: playerContainerWidth,
          height: playerContainerHeight,
        });
      }
      if (player) {
        const playerWidth = playerContainerDimensions.width - 20;
        const playerHeight = (playerWidth * 9) / 16;
        setplayerDimensions({ width: playerWidth, height: playerHeight });
      }
    };

    // Initial resize
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    (async () => {
        try {
            const res = await axios.get(
                `${process.env.REACT_APP_SERVER_BASE_URL}/api/v1/course1/get/${courseId}`,
                {
                    headers: { Authorization: `Bearer ${accessToken}` },
                }
            );
            if (res && res.data) {
                // console.log("check response", res.data.data.course);
                const courseData = res.data.data.course;
                setCourseSettings(courseData.settings);
            }
        } catch (error) {
            console.log(error);
        }
    })();
}, [courseId, accessToken]);


  useEffect(()=> {
    // console.log("coursePreview",coursePreview, "settings", courseSettings);
  },[coursePreview, courseSettings]);

  useEffect(() => {
    setTimer(data?.timer);
  }, [data?.timer]);

  useEffect(() => {
    if (coursePreview && timer > 0 && !submitted) {
      const countdown = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);

      return () => clearInterval(countdown);
    }
  }, [timer, coursePreview, submitted]);

  useEffect(() => {
    if (timer === 0) {
      setIsTimeOver(true); 
    }
  }, [timer]);

  // Function to format time in MM:SS format
  const formatTime = (timeInSeconds: number): string => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };

  useEffect(() => {
    // console.log(haveResponse, isSubmitType, isNextType, haveSubmitted);
  },[haveResponse, isSubmitType, isNextType, haveSubmitted]);

  const submitCase = !haveResponse || (haveResponse && isSubmitType && !haveSubmitted) ;
  const nextCase = !haveResponse || (haveResponse && !isNextType && !haveSubmitted) ;
  const isNextDisabled = submitCase || nextCase;

  const toggleNodeExpand = (id: string) => {
    const newSet = new Set(expandedNodes);
    if (newSet.has(id)) {
      newSet.delete(id);
    } else {
      newSet.add(id);
    }
    setExpandedNodes(newSet);
  };

  const renderStructure = (nodes: FileSystemNode[], depth: number = 0) => (
    <ul className="w-full ">
      {nodes?.map((node) => (
        <li
          key={node.id}
          // className={` w-full ${node.id === searchParams.get('templateId') ? "bg-[#dedede]" : ""}`}
          className={` w-full `}
        >
          <div
            onClick={() => {
              if (node.type === "file") {
                handleTopicChange(node);
                // setSearchParams({ templateId: node.id });
              }
            }}
            className={`relative w-full ${node.depth === 0
              ? "pl-[16px]"
              : node.depth === 1
                ? "pl-[32px]"
                : node.depth === 2
                  ? "pl-[48px]"
                  : "pl-[64px]"
              } pr-4 py-1.5 hover:bg-[#dedede] flex items-center space-x-2 group`}
          >
            <div className='w-full flex items-center space-x-1'>
              {node.type === "folder" ? (
                <div onClick={() => toggleNodeExpand(node.id)}>
                  {expandedNodes.has(node.id) ? (
                    <ChevronDown size={20} />
                  ) : (
                    <ChevronRight size={20} />
                  )}
                </div>
              ) : (
                <div>
                  <File color="#333333" size={16} />
                </div>
              )}
              <span className="text-xl">{node.name}</span>
            </div>
          </div>
          {expandedNodes.has(node.id) &&
            node.children &&
            // node.children.length > 0 &&
            renderStructure(node.children, depth + 1)
          }
        </li>
      ))}
    </ul>
  );
  
  return (
    // <div className="small-container ">
    //   {coursePreview && (
    //     <>
    //     {data?.isTimer && (
    //       <div
    //         className={` absolute right-[4rem] top-0 border-2 w-52 rounded-md p-1 mt-1 ${
    //           submitted
    //             ? "border-black"
    //             : !isTimeOver
    //             ? "border-green-500"
    //             : "border-red-500"
    //         }`}
    //       >
    //         <div
    //           className={`flex justify-between font-semibold ${
    //             submitted
    //               ? "text-black"
    //               : !isTimeOver
    //               ? "text-green-500"
    //               : "text-red-500"
    //           } `}
    //         >
    //           <span>
    //             {!isTimeOver ? "Time remaining: " : "Timer has ended: "}
    //           </span>
    //           <span>{formatTime(timer)}</span>
    //         </div>
    //       </div>
    //     )}
          


    //       {/* try to comment this button before you create the bundle for scorm */}
    //       <button
    //         onClick={() => navigate(`/user/${userId}/course/${courseId}`)}
    //         className="absolute right-2 top-2 mt-1 z-10 px-3 py-1 rounded-md bg-tertiary hover:bg-tertiary/60 text-white"
    //       >
    //         Exit
    //       </button>
    //     </>
    //   )}
      
    //   <div className="w-full border-b border-gray-300 ">
    //     {children}
    //   </div>
    //   <div className="flex justify-between m-2 ">
    //     <button
    //       className="px-4 ml-6 py-2 bg-[#AB0273] text-white rounded hover:bg-[#8e006e] focus:outline-none focus:ring-[#AB0273]"
    //       onClick={handleBack}>← Previous
    //     </button>
    //     <button
    //       className={`px-4 py-2 bg-[#AB0273] text-white rounded hover:bg-[#8e006e] focus:outline-none focus:ring-[#AB0273] disabled:bg-tertiary/40 ${!isNextDisabled ? 'animate-pulse' : ''}`}
    //       onClick={handleNext}
    //       disabled={isNextDisabled}>Next →
    //     </button>
    //   </div>
    // </div>

    <main className=' w-full max-w-[100vw] h-screen max-h-[100vh] mx-auto overflow-hidden font-poppins'>
      <div className='flex w-full h-[3rem] px-[1.25rem] py-[0.5625] items-center'>preview</div>

      <section className='relative w-full h-[calc(100%-3rem)] bg-[#d1d1d1] flex items-center justify-center'>

      {coursePreview && (
        <>
        {data?.isTimer && (
          <div
            className={` absolute right-[4rem] top-0 border-2 w-52 rounded-md p-1 mt-1 ${
              submitted
                ? "border-black"
                : !isTimeOver
                ? "border-green-500"
                : "border-red-500"
            }`}
          >
            <div
              className={`flex justify-between font-semibold ${
                submitted
                  ? "text-black"
                  : !isTimeOver
                  ? "text-green-500"
                  : "text-red-500"
              } `}
            >
              <span>
                {!isTimeOver ? "Time remaining: " : "Timer has ended: "}
              </span>
              <span>{formatTime(timer)}</span>
            </div>
          </div>
        )}
          
        {/* try to comment this button before you create the bundle for scorm */}
          <button
            onClick={() => navigate(`/user/${userId}/course/${courseId}`)}
            className="absolute right-2 top-2 mt-1 z-10 px-3 py-1 rounded-md bg-tertiary hover:bg-tertiary/60 text-white"
          >
            Exit
          </button>
        </>
      )}
        
        <div className=' containerplayer '>
          <div
            ref={playerContainerRef}
            // style={{
            //   height: `${playerContainerDimensions.height}px`,
            // }}
            style={{ backgroundColor: courseSettings?.backgroundColor || "#ffffff" }}
            className="flex flex-col items-center canvas !rounded-t-none"
          >

            {courseSettings?.logoImageFile || courseSettings?.title ? (
              <div className={`flex space-x-5 items-center w-full p-3 border-t-2 border-[#ccc] ${courseSettings?.alignment === 'center' ? 'justify-center' : courseSettings?.alignment === 'left' ? 'justify-start' : 'justify-end'}`}>
                {courseSettings?.logoImageFile && (
                  <div className="w-[2.5rem] h-[2.5rem]">
                    <img src={courseSettings?.logoImageFile?.url} alt={courseSettings?.logoImageFile?.name} className="!w-full !h-full object-cover"/>
                  </div>
                )}

                {courseSettings?.title && (
                  <div className="space-x-5 text-lg font-medium">
                    {courseSettings?.title}
                  </div>
                )}
              </div>
            ) : null }

            <div className="w-full aspect-[16/9]">
              {children}
            </div>

            {/* Player Controls */}
            {courseSettings?.havePlayback || courseSettings?.haveNavigation || courseSettings?.havePagination || courseSettings?.haveTOC ? (
              <div className="flex justify-between items-center w-full p-3 border-t-2 border-[#ccc]">
                <div className="flex space-x-5 items-center">

                  {courseSettings?. havePlayback && (
                    <Button
                      style={{
                        backgroundColor: courseSettings?.primaryAccent || "#AB0273",
                      }}
                      className="bg-tertiary hover:bg-tertiary/50 text-white px-4 py-2 rounded "
                    >
                      <Playback className=""/>
                    </Button>
                  )}

                  {courseSettings?.haveTOC && (
                    <div className='relative top-[5px]'>
                      <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                          <Button
                            style={{
                              backgroundColor: courseSettings?.primaryAccent || "#AB0273",
                            }}
                            className="bg-tertiary hover:bg-tertiary/50 text-white px-4 py-2 rounded"
                            onClick={() => setTocOpen(!tocOpen)}
                          >
                            <TableOfContent />
                          </Button>
                        </DropdownMenuTrigger>

                        <DropdownMenuContent align="start" side="bottom" sideOffset={14} className="bg-[#ffffff] shadow-md" style={{ height: `${(playerContainerDimensions.width) * 9 / 16}px` }}>
                          {renderStructure(courseData, 0)}
                        </DropdownMenuContent>
                      </DropdownMenu>
                    </div>
                    )}
                </div>

                <div className="flex space-x-5 items-center">

                  {courseSettings?.havePagination && (
                    <div 
                      style={{
                        color: courseSettings?.secondaryAccent || "#d1d1d1",
                      }} 
                      className="text-gray-400">
                        1 0f 20
                    </div>
                  )}

                  {courseSettings?.haveNavigation && (
                    <>
                      <Button
                        style={{
                          backgroundColor: courseSettings?.primaryAccent || "#AB0273",
                        }}
                        className="bg-tertiary hover:bg-tertiary/50 text-white px-4 py-2 rounded"
                        onClick={handleBack}
                      >
                        Previous
                      </Button>
                      <Button
                      disabled={isNextDisabled}
                        style={{
                          backgroundColor: courseSettings?.primaryAccent || "#AB0273",
                        }}
                        className="bg-tertiary hover:bg-tertiary/50 text-white px-4 py-2 rounded"
                        onClick={handleNext}
                      >
                        Next
                      </Button>
                    </>
                  )}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </section>
    </main>
  );
};

export default CoursePreviewPlayer;
