import {
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  Cross,
  CrossIcon,
  MenuIcon,
  Plus,
  X,
} from "lucide-react";
import React, { ElementRef, useEffect, useRef, useState } from "react";
import { useMediaQuery } from "usehooks-ts";
import { cn } from "../lib/utils";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
import { OverflowMenuHorizontal } from "@carbon/icons-react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../ui/dropdown-menu";
import { Button } from "../ui/button";
import RenameLogo from "../../assets/TableMenu/PencilLine.svg";
import DeleteLogo from "../../assets/TableMenu/TrashSimple.svg";
import ListLogo from "../../assets/list_2.png";
import CopyLogo from "../../assets/TableMenu/Copy.svg";
import MoveUp from "../../assets/move_up.png";
import MoveDown from "../../assets/move_down.png";
import RemoveLogo from "../../assets/remove1.png";
import Modal from "src/utils/Modal";
import Text from "src/utils/Text/Txt";
import { useDispatch } from "react-redux";
import { setLog } from "src/features/errorLog/errorLogSlice";

type Props = {
  topicContent: string;
  handleTopic: (topic: any) => void;
};

function Navigation({ topicContent, handleTopic }: Props) {
  const pathname = window.location.pathname;
  const isMobile = useMediaQuery("(max-width: 768px)");

  const [data, setData] = useState<any>([]);
  const [topic, setTopic] = useState<any>();
  const debounceTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const isResizingRef = useRef(false);
  const sidebarRef = useRef<ElementRef<"aside">>(null);
  const navbarRef = useRef<ElementRef<"div">>(null);

  const [isResetting, setIsResetting] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(isMobile);

  const { pageId, userId, businessUnitId } = useParams();
  const { accessToken } = useSelector((state: any) => state.accessToken);

  const [openAddDropdown, setOpenAddDropdown] = useState(false);
  const [openOptions, setOpenOptions] = useState("");
  const [tableMenuActive, setTableMenuActive] = useState<string>("");
  const [expandedChapters, setExpandedChapters] = useState<Set<number>>(
    new Set()
  );
  const [openRenamePopup, setOpenRenamePopup] = useState(false);
  const [renamedTitle, setRenamedTitle] = useState<string>("");
  const [renamedId, setRenamedId] = useState<string>("");
  const [itemType, setItemType] = useState<any>();

  const [item, setItem] = useState<string>("");
  const [chapterIdx, setChapterIdx] = useState<number>();
  const [topicIdx, setTopicIdx] = useState<number | null>();

  const dispatch = useDispatch();

  useEffect(() => {
    if (isMobile) {
      collapse();
    } else {
      resetWidth();
    }
  }, [isMobile]);

  useEffect(() => {
    const pageState = localStorage.getItem("pageState")
      ? JSON.parse(localStorage.getItem("pageState")!)
      : null;

    if (pageState && pageState.topic) {
      setTopic(pageState.topic);
      localStorage.removeItem("pageState");
    }
  }, []);

  useEffect(() => {
    if (topic) {
      handleTopic(topic);
    }
  }, [topic]);

  useEffect(() => {
    console.log("topicContent", topicContent);
    const newData = data.map((item: any) => {
      if (item.id === topic?.id) {
        item.content = topicContent;
      } else {
        item.topics?.map((item: any) => {
          // Use optional chaining to solve the run time error of map
          if (item.id === topic?.id) {
            item.content = topicContent;
          }
          return item;
        });
      }
      return item;
    });
    console.log("newData", newData);
    setData(newData);
  }, [topicContent]);

  useEffect(() => {
    (async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_SERVER_BASE_URL}/api/v1/page/get/${pageId}`,
          {
            headers: { Authorization: `Bearer ${accessToken}` },
          }
        );
        if (res && res.data) {
          console.log(res.data);
          setData(res.data.data.page?.data || []); // Ensure data is set as an array
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  useEffect(() => {
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    debounceTimeoutRef.current = setTimeout(async () => {
      try {
        const res = await axios.put(
          `${process.env.REACT_APP_SERVER_BASE_URL}/api/v1/page/${pageId}`,
          {
            data,
          },
          {
            headers: { Authorization: `Bearer ${accessToken}` },
          }
        );
        if (res && res.data) console.log(res.data);
      } catch (error) {
        console.log(error);
      }
    }, 500); // 500ms debounce time

    return () => {
      if (debounceTimeoutRef.current) {
        clearTimeout(debounceTimeoutRef.current);
      }
    };
  }, [data]);

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();

    isResizingRef.current = true;
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  };

  const handleMouseMove = (e: MouseEvent) => {
    if (!isResizingRef.current) return;
    let newWidth = e.clientX;

    if (newWidth < 240) newWidth = 240;
    if (newWidth > 480) newWidth = 480;

    if (sidebarRef.current && navbarRef.current) {
      sidebarRef.current.style.width = `${newWidth}px`;
      navbarRef.current.style.setProperty("left", `${newWidth}px`);
      navbarRef.current.style.setProperty(
        "width",
        `calc(100% - ${newWidth}px)`
      );
    }
  };

  const handleMouseUp = () => {
    isResizingRef.current = false;
    document.removeEventListener("mousemove", handleMouseMove);
    document.removeEventListener("mouseup", handleMouseUp);
  };

  const resetWidth = () => {
    if (sidebarRef.current && navbarRef.current) {
      setIsCollapsed(false);
      setIsResetting(true);
      sidebarRef.current.style.width = isMobile ? "100%" : "20rem";
      navbarRef.current.style.setProperty("left", isMobile ? "100%" : "20rem");
      navbarRef.current.style.setProperty(
        "width",
        isMobile ? "0" : "calc(100% - 20rem)"
      );
      setTimeout(() => {
        setIsResetting(false);
      }, 300);
    }
  };

  const collapse = () => {
    if (sidebarRef.current && navbarRef.current) {
      setIsCollapsed(true);
      setIsResetting(true);
      sidebarRef.current.style.width = "0";
      navbarRef.current.style.setProperty("left", "0");
      navbarRef.current.style.setProperty("width", "100%");
      setTimeout(() => {
        setIsResetting(false);
      }, 300);
    }
  };

  function AddChapterOrTopic(type: string, id?: number) {
    if (type === "chapter") {
      setData([
        ...data,
        {
          type: "chapter",
          title: "Chapter",
          topics: [], // Initialize topics as an empty array
          id: Math.round(Math.random() * (10000 - 1)) + 1,
        },
      ]);
      setOpenAddDropdown(false);
    } else if (id && type === "topic") {
      const newData = data.map((item: any) => {
        if (item.id === id) {
          item.topics.push({
            type: "topic",
            title: "Topic",
            content: `{ "time": ${new Date().getTime()}, "blocks": [], "version": "2.29.1" }`,
            id: Math.round(Math.random() * (10000 - 1)) + 1,
          });
        }
        return item;
      });
      setExpandedChapters(new Set([...expandedChapters, id])); // Automatically expand the chapter
      setData(newData);
      setOpenAddDropdown(false);
    } else if (!id && type === "topic") {
      const d = [
        ...data,
        {
          type: "topic",
          title: "Topic",
          content: `{ "time": ${new Date().getTime()}, "blocks": [], "version": "2.29.1" }`,
          id: Math.round(Math.random() * (10000 - 1)) + 1,
        },
      ];
      console.log("d", d);
      setData(d);
      setOpenAddDropdown(false);
    }
  }

  // Creating a function to handle expanding and collapsing chapters
  const toggleChapterExpand = (id: number) => {
    const newSet = new Set(expandedChapters);
    if (newSet.has(id)) {
      newSet.delete(id);
    } else {
      newSet.add(id);
    }
    setExpandedChapters(newSet);
  };

  const tableRef = React.createRef<HTMLDivElement>();

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!tableRef?.current?.contains(event.target as Node)) {
        setOpenOptions("");
        setTableMenuActive("");
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      // Clean up the event listener when the component unmounts
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [tableRef]);

  const menuRef = React.createRef<HTMLDivElement>();

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!menuRef?.current?.contains(event.target as Node)) {
        setOpenAddDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      // Clean up the event listener when the component unmounts
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuRef]);

  function handleRenamePopup(
    item: any,
    chapterIdx: number,
    topicIdx: number | null
  ) {
    setOpenRenamePopup(true);
    setRenamedTitle(item.title);
    setRenamedId(item.id);
    setItemType(item.type);
    setChapterIdx(chapterIdx);
    setTopicIdx(topicIdx);
  }

  // there are two scenarios for any item at first (it can bea topic or it can be a chapter)
  // match all the items with their id and also check whether the item is chapter or not (i.e if(item.id !== itemId && item.type === "chapter"))

  async function handleRename(id: any, title: any) {
    await axios
      .post(
        `${process.env.REACT_APP_SERVER_BASE_URL}/api/v1/page/rename/${pageId}`,
        {
          title,
          chapterIdx,
          topicIdx,
        },
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      )
      .then((res) => res.data)
      .then((response) => {
        console.log(response.data);
        setData(response.data.data);
        setOpenRenamePopup(false);
        let message = "";
        if (topicIdx !== null) {
          message = "Topic name has been renamed!";
        } else if (itemType === "topic") {
          message = "Topic name has been renamed!";
        } else {
          message = "Chapter name has been renamed!";
        }
        dispatch(
          setLog({
            mode: "success",
            message: message,
          })
        );
      })
      .catch((err) => {
        console.error(err);
        dispatch(setLog({ mode: "failure", message: err }));
      });
  }

  const moveItem = (type: string, id: string, direction: string) => {
    const newData = [...data];

    if (type === "topic") {
      // Find the parent chapter index of the topic
      const parentChapterIndex = newData.findIndex(
        (item) =>
          item.type === "chapter" &&
          item.topics.some((topic: any) => topic.id === id)
      );

      if (parentChapterIndex === -1) return; // Parent chapter not found

      const parentChapter = newData[parentChapterIndex];
      const topics = parentChapter.topics;
      const topicIndex = topics.findIndex((topic: any) => topic.id === id);

      if (topicIndex === -1) return; // Topic not found within chapter

      const newTopicIndex =
        direction === "up" ? topicIndex - 1 : topicIndex + 1;

      // Check if newTopicIndex is within bounds
      if (newTopicIndex < 0 || newTopicIndex >= topics.length) return;

      console.log(
        newData,
        newData[parentChapterIndex].topics[topicIndex],
        newData[parentChapterIndex].topics[newTopicIndex]
      );
      // Swap the topics within the parent chapter
      [
        newData[parentChapterIndex].topics[topicIndex],
        newData[parentChapterIndex].topics[newTopicIndex],
      ] = [
          newData[parentChapterIndex].topics[newTopicIndex],
          newData[parentChapterIndex].topics[topicIndex],
        ];
      console.log(
        newData,
        newData[parentChapterIndex].topics[topicIndex],
        newData[parentChapterIndex].topics[newTopicIndex]
      );
      setData(newData);
    } else {
      const index = newData.findIndex((item) => item.id === id);

      if (index === -1) return; // Item not found

      const newIndex = direction === "up" ? index - 1 : index + 1;

      // Check if newIndex is within bounds
      if (newIndex < 0 || newIndex >= newData.length) return;

      // Swap the items in the array
      [newData[index], newData[newIndex]] = [newData[newIndex], newData[index]];

      setData(newData);
    }
  };

  // Function to move item up
  const handleMoveUp = (type: string, id: string) => {
    moveItem(type, id, "up");
  };

  // Function to move item down
  const handleMoveDown = (type: string, id: string) => {
    moveItem(type, id, "down");
  };

  function handleRemove(
    item: any,
    chapterIdx: number,
    topicIdx: number | null
  ) {
    let newData = [...data];
    console.log(chapterIdx, topicIdx);

    if (topicIdx !== null) {
      let newTopics = [...newData[chapterIdx].topics];
      newTopics.splice(topicIdx, 1);
      newData[chapterIdx] = { ...newData[chapterIdx], topics: newTopics };
      console.log(newData[chapterIdx]);
    } else {
      newData.splice(chapterIdx, 1);
    }
    console.log(newData);
    setData(newData);
  }

  async function handleDuplicate(
    item: any,
    chapterIdx: number,
    topicIdx: number | null
  ) {
    await axios
      .post(
        `${process.env.REACT_APP_SERVER_BASE_URL}/api/v1/page/duplicate/${pageId}`,
        {
          item,
          chapterIdx,
          topicIdx,
        },
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      )
      .then((res) => res.data)
      .then((response) => {
        console.log(response.data);
        console.log(response.data.data);
        setData(response.data.data);
        setOpenRenamePopup(false);
        dispatch(
          setLog({
            mode: "success",
            message: `A ${topicIdx ? "Topic" : "Chapter"} has been duplicated!`,
          })
        );
      })
      .catch((err) => {
        console.error(err);
        dispatch(setLog({ mode: "failure", message: err }));
      });
  }

  return (
    <>
      <aside
        ref={sidebarRef}
        className={cn(
          "group/sidebar h-full bg-[#EFEFEF] overflow-y-auto relative flex w-full max-w-[20rem] flex-col z-[99999]",
          isResetting && "tansition-all ease-in-out duration-300",
          isMobile && "w-0"
        )}
      >
        <div
          onClick={collapse}
          role="button"
          className={cn(
            "h-6 w-6 text-muted-foreground rounded-sm hover:bg-neutral-300 dark:hover:bg-neutral-600 absolute top-1 right-2 opacity-0 group-hover/sidebar:opacity-100 transition",
            isMobile && "opacity-100"
          )}
        >
          <X className="h-7 w-7" />
        </div>

        <div
          ref={menuRef}
          className="relative w-full flex items-center justify-between mt-9 ml-5 text-[#242424] font-medium text-[1rem]"
        >
          <button
            className=" w-full max-w-[15.9390rem] flex items-center text-center justify-between bg-[#AB0273] text-[#FFFFFF] text-[1.25rem] font-[500] leading-normal px-[1.28rem] py-[0.5rem] rounded-[3.125rem]"
            onClick={() => setOpenAddDropdown(!openAddDropdown)}
          >
            <span className="flex-1 text-center">Create New</span>
            <span className="relative rounded-[50%] p-2 bg-[#AB0273] border border-[#AB0273] ml-2 tooltip-container">
              <Plus className="" />
              {/* <span className="tooltip-text">Chapter/Topic</span> */}
            </span>
          </button>
          {openAddDropdown && (
            <div
              className="flex flex-col absolute left-0 w-full max-w-[15.9390rem] top-full bg-[#F5F5F5] border-2 border-black/30 rounded-[1.25rem] mt-1 px-[0.7rem] py-[0.5rem] z-10"
            >
              <button
                onClick={(e) => {
                  // e.stopPropagation();
                  AddChapterOrTopic("chapter");
                }}
                className="w-full text-left px-2 py-1 flex items-start justify-center hover:bg-[#e6e6e6] text-[#000000] rounded-lg"
              >
                Chapter
              </button>
              <button
                onClick={(e) => {
                  // e.stopPropagation();
                  AddChapterOrTopic("topic");
                }}
                className="w-full text-left px-2 py-1 flex items-start justify-center hover:bg-[#e6e6e6] text-[#000000] rounded-lg"
              >
                Topic
              </button>
            </div>
          )}
        </div>

        <div>
          {data.map((item: any, chapterIdx: number) => {
            return (
              <div key={item.id} className="mt-4 flex flex-col">
                {item.type === "chapter" && (
                  <>
                    <div className="group flex items-center justify-between pl-5 pr-2 hover:bg-[#e6e6e6]">
                      <button
                        onClick={() => toggleChapterExpand(item.id)}
                        className="flex space-x-3"
                      >
                        {expandedChapters.has(item.id) ? (
                          <ChevronDown className="h-6 w-6" />
                        ) : (
                          <ChevronRight className="h-6 w-6" />
                        )}
                        <h3 className="text-lg font-medium">{item.title}</h3>
                      </button>
                      <div
                        className={`${tableMenuActive !== `chapter-${item.id}`
                            ? "invisible"
                            : "visible"
                          } group-hover:visible flex space-x-2 items-center relative`}
                      >
                        <button
                          onClick={() => AddChapterOrTopic("topic", item.id)}
                          className="tooltip-container py-2"
                        >
                          <Plus className="" />
                          <span className="tooltip-text">Add Topic</span>
                        </button>
                        <DropdownMenu>
                          <DropdownMenuTrigger asChild>
                            <Button
                              size={"sm"}
                              onClick={() => {
                                if (!openOptions) {
                                  setOpenOptions(`chapter-${item.id}`);
                                  setTableMenuActive(`chapter-${item.id}`);
                                  setItem(item);
                                } else setOpenOptions("");
                              }}
                              className={``}
                              variant="destructive"
                            >
                              <OverflowMenuHorizontal size="24" />
                            </Button>
                          </DropdownMenuTrigger>
                          {openOptions === `chapter-${item.id}` && (
                            <DropdownMenuContent
                              ref={tableRef}
                              className="relative right-[5.2rem] flex flex-col w-[12.4375rem] h-[15rem] bg-[#F5F5F5] border-2 border-black/30 rounded-[1.25rem] px-[0.7rem] pt-[0.65rem] space-y-[1.94rem] z-[99999]"
                            >
                              <DropdownMenuGroup>
                                <DropdownMenuItem className="flex items-center">
                                  <button
                                    className="flex items-center space-x-[1.2rem] text-[1rem] font-[500] text-[#242424] hover:text-black leading-normal w-full rounded-md p-1 hover:bg-[#e6e6e6] hover:rounded[1.25rem]"
                                    onClick={() =>
                                      handleRenamePopup(item, chapterIdx, null)
                                    }
                                  >
                                    <span>
                                      <img src={RenameLogo} alt="logo" />
                                    </span>
                                    <span>Rename</span>
                                  </button>
                                </DropdownMenuItem>
                                <DropdownMenuItem className="flex items-center">
                                  <button
                                    onClick={() =>
                                      handleMoveUp(item.type, item.id)
                                    }
                                    className="flex items-center space-x-[1.2rem] text-[1rem] font-[500] text-[#242424] hover:text-black leading-normal w-full rounded-md p-1 hover:bg-[#e6e6e6] hover:rounded[1.25rem]"
                                  >
                                    <span>
                                      <img
                                        src={MoveUp}
                                        alt="logo"
                                        className="w-7 h-7"
                                      />
                                    </span>
                                    <span>Move Up</span>
                                  </button>
                                </DropdownMenuItem>
                                <DropdownMenuItem className="flex items-center">
                                  <button
                                    onClick={() =>
                                      handleMoveDown(item.type, item.id)
                                    }
                                    className="flex items-center space-x-[1.2rem] text-[1rem] font-[500] text-[#242424] hover:text-black leading-normal w-full rounded-md p-1 hover:bg-[#e6e6e6] hover:rounded[1.25rem]"
                                  >
                                    <span>
                                      <img
                                        src={MoveDown}
                                        alt="logo"
                                        className="w-7 h-7"
                                      />
                                    </span>
                                    <span>Move Down</span>
                                  </button>
                                </DropdownMenuItem>
                                <DropdownMenuItem className="flex items-center">
                                  <button
                                    onClick={() =>
                                      handleDuplicate(item, chapterIdx, null)
                                    }
                                    className="flex items-center space-x-[1.2rem] text-[1rem] font-[500] text-[#242424] hover:text-black leading-normal w-full rounded-md p-1 hover:bg-[#e6e6e6] hover:rounded[1.25rem]"
                                  >
                                    <span>
                                      <img src={CopyLogo} alt="logo" />
                                    </span>
                                    <span>Duplicate</span>
                                  </button>
                                </DropdownMenuItem>
                                {/* <DropdownMenuSeparator className="bg-[#2C2C2C]" /> */}
                                <DropdownMenuItem className="flex items-center">
                                  <button
                                    onClick={() =>
                                      handleRemove(item, chapterIdx, null)
                                    }
                                    className="flex items-center space-x-[1.2rem] text-[1rem] font-[500] text-[#242424] hover:text-black leading-normal w-full rounded-md p-1 hover:bg-[#e6e6e6] hover:rounded[1.25rem]"
                                  //   onClick={() => handleDeletePopup(unit._id)}
                                  >
                                    <span>
                                      <img
                                        src={RemoveLogo}
                                        alt="logo"
                                        className="w-6 h-6"
                                      />
                                    </span>
                                    <span>Remove</span>
                                  </button>
                                </DropdownMenuItem>
                              </DropdownMenuGroup>
                            </DropdownMenuContent>
                          )}
                        </DropdownMenu>
                      </div>
                    </div>
                    {expandedChapters.has(item.id) &&
                      item.topics.map((item: any, topicIdx: number) => {
                        return (
                          <div
                            key={item.id}
                            className={`group mt-4 flex items-center justify-between pl-10 pr-2 py-2 hover:bg-[#e6e6e6] ${item.id === topic?.id && "bg-[#e6e6e6]"
                              }`}
                          >
                            <button
                              onClick={() => setTopic(item)}
                              key={item.id}
                              className="flex space-x-3 justify-start w-full"
                            >
                              <img
                                src={ListLogo}
                                alt="logo"
                                className="w-5 h-5"
                              />
                              <h4 className="text-md font-medium">
                                {item.title}
                              </h4>
                            </button>
                            <DropdownMenu>
                              <DropdownMenuTrigger asChild>
                                <Button
                                  size={"sm"}
                                  onClick={() => {
                                    if (!openOptions) {
                                      setOpenOptions(`topic-${item.id}`);
                                      setTableMenuActive(`topic-${item.id}`);
                                      setItem(item);
                                    } else setOpenOptions("");
                                  }}
                                  className={`${tableMenuActive !== `topic-${item.id}`
                                      ? "invisible"
                                      : "visible"
                                    } group-hover:visible`}
                                  variant="destructive"
                                >
                                  <OverflowMenuHorizontal size="24" />
                                </Button>
                              </DropdownMenuTrigger>
                              {openOptions === `topic-${item.id}` && (
                                <DropdownMenuContent
                                  ref={tableRef}
                                  className="relative right-[5.2rem] flex flex-col w-[12.4375rem] h-[15rem] bg-[#F5F5F5] border-2 border-black/30 rounded-[1.25rem] px-[0.7rem] pt-[0.65rem] space-y-[1.94rem] z-[99999]"
                                >
                                  <DropdownMenuGroup>
                                    <DropdownMenuItem className="flex items-center">
                                      <button
                                        onClick={() =>
                                          handleRenamePopup(
                                            item,
                                            chapterIdx,
                                            topicIdx
                                          )
                                        }
                                        className="flex items-center space-x-[1.2rem] text-[1rem] font-[500] text-[#242424] hover:text-black leading-normal w-full rounded-md p-1 hover:bg-[#e6e6e6] hover:rounded[1.25rem]"
                                      >
                                        <span>
                                          <img src={RenameLogo} alt="logo" />
                                        </span>
                                        <span>Rename</span>
                                      </button>
                                    </DropdownMenuItem>
                                    <DropdownMenuItem className="flex items-center">
                                      <button
                                        onClick={() =>
                                          handleMoveUp(item.type, item.id)
                                        }
                                        className="flex items-center space-x-[1.2rem] text-[1rem] font-[500] text-[#242424] hover:text-black leading-normal w-full rounded-md p-1 hover:bg-[#e6e6e6] hover:rounded[1.25rem]"
                                      >
                                        <span>
                                          <img
                                            src={MoveUp}
                                            alt="logo"
                                            className="w-7 h-7"
                                          />
                                        </span>
                                        <span>Move Up</span>
                                      </button>
                                    </DropdownMenuItem>
                                    <DropdownMenuItem className="flex items-center">
                                      <button
                                        onClick={() =>
                                          handleMoveDown(item.type, item.id)
                                        }
                                        className="flex items-center space-x-[1.2rem] text-[1rem] font-[500] text-[#242424] hover:text-black leading-normal w-full rounded-md p-1 hover:bg-[#e6e6e6] hover:rounded[1.25rem]"
                                      >
                                        <span>
                                          <img
                                            src={MoveDown}
                                            alt="logo"
                                            className="w-7 h-7"
                                          />
                                        </span>
                                        <span>Move Down</span>
                                      </button>
                                    </DropdownMenuItem>
                                    <DropdownMenuItem className="flex items-center">
                                      <button
                                        onClick={() =>
                                          handleDuplicate(
                                            item,
                                            chapterIdx,
                                            topicIdx
                                          )
                                        }
                                        className="flex items-center space-x-[1.2rem] text-[1rem] font-[500] text-[#242424] hover:text-black leading-normal w-full rounded-md p-1 hover:bg-[#e6e6e6] hover:rounded[1.25rem]"
                                      >
                                        <span>
                                          <img src={CopyLogo} alt="logo" />
                                        </span>
                                        <span>Duplicate</span>
                                      </button>
                                    </DropdownMenuItem>
                                    {/* <DropdownMenuSeparator className="bg-[#2C2C2C]" /> */}
                                    <DropdownMenuItem className="flex items-center">
                                      <button
                                        onClick={() =>
                                          handleRemove(
                                            item,
                                            chapterIdx,
                                            topicIdx
                                          )
                                        }
                                        className="flex items-center space-x-[1.2rem] text-[1rem] font-[500] text-[#242424] hover:text-black leading-normal w-full rounded-md p-1 hover:bg-[#e6e6e6] hover:rounded[1.25rem]"
                                      >
                                        <span>
                                          <img
                                            src={RemoveLogo}
                                            alt="logo"
                                            className="w-6 h-6"
                                          />
                                        </span>
                                        <span>Remove</span>
                                      </button>
                                    </DropdownMenuItem>
                                  </DropdownMenuGroup>
                                </DropdownMenuContent>
                              )}
                            </DropdownMenu>
                          </div>
                        );
                      })}
                  </>
                )}

                {item.type === "topic" && (
                  <div className={`group flex items-center justify-between pl-6 pr-2 py-2 hover:bg-[#e6e6e6] ${item.id === topic?.id && "bg-[#e6e6e6]"
                    }`}>
                    <button
                      onClick={() => setTopic(item)}
                      className="flex space-x-3 justify-start w-full"
                    >
                      <img src={ListLogo} alt="logo" className="w-5 h-5" />
                      <h3 className="text-lg font-medium">{item.title}</h3>
                    </button>
                    <DropdownMenu>
                      <DropdownMenuTrigger asChild>
                        <Button
                          size={"sm"}
                          onClick={() => {
                            if (!openOptions) {
                              setOpenOptions(`topic-${item.id}`);
                              setTableMenuActive(`topic-${item.id}`);
                            } else setOpenOptions("");
                          }}
                          className={`${tableMenuActive !== `topic-${item.id}`
                              ? "invisible"
                              : "visible"
                            } group-hover:visible`}
                          variant="destructive"
                        >
                          <OverflowMenuHorizontal size="24" />
                        </Button>
                      </DropdownMenuTrigger>
                      {openOptions === `topic-${item.id}` && (
                        <DropdownMenuContent
                          ref={tableRef}
                          className="relative right-[5.2rem] flex flex-col w-[12.4375rem] h-[15rem] bg-[#F5F5F5] border-2 border-black/30 rounded-[1.25rem] px-[0.7rem] pt-[0.65rem] space-y-[1.94rem] z-[99999]"
                        >
                          <DropdownMenuGroup>
                            <DropdownMenuItem className="flex items-center">
                              <button onClick={() => handleRenamePopup(item, chapterIdx, null)} className="flex items-center space-x-[1.2rem] text-[1rem] font-[500] text-[#242424] hover:text-black leading-normal w-full rounded-md p-1 hover:bg-[#e6e6e6] hover:rounded[1.25rem]">
                                <span>
                                  <img src={RenameLogo} alt="logo" />
                                </span>
                                <span>Rename</span>
                              </button>
                            </DropdownMenuItem>
                            <DropdownMenuItem className="flex items-center">
                              <button
                                onClick={() => handleMoveUp(item.type, item.id)}
                                className="flex items-center space-x-[1.2rem] text-[1rem] font-[500] text-[#242424] hover:text-black leading-normal w-full rounded-md p-1 hover:bg-[#e6e6e6] hover:rounded[1.25rem]"
                              >
                                <span>
                                  <img
                                    src={MoveUp}
                                    alt="logo"
                                    className="w-7 h-7"
                                  />
                                </span>
                                <span>Move Up</span>
                              </button>
                            </DropdownMenuItem>
                            <DropdownMenuItem className="flex items-center">
                              <button
                                onClick={() =>
                                  handleMoveDown(item.type, item.id)
                                }
                                className="flex items-center space-x-[1.2rem] text-[1rem] font-[500] text-[#242424] hover:text-black leading-normal w-full rounded-md p-1 hover:bg-[#e6e6e6] hover:rounded[1.25rem]"
                              >
                                <span>
                                  <img
                                    src={MoveDown}
                                    alt="logo"
                                    className="w-7 h-7"
                                  />
                                </span>
                                <span>Move Down</span>
                              </button>
                            </DropdownMenuItem>
                            <DropdownMenuItem className="flex items-center">
                              <button className="flex items-center space-x-[1.2rem] text-[1rem] font-[500] text-[#242424] hover:text-black leading-normal w-full rounded-md p-1 hover:bg-[#e6e6e6] hover:rounded[1.25rem]">
                                <span>
                                  <img src={CopyLogo} alt="logo" />
                                </span>
                                <span>Duplicate</span>
                              </button>
                            </DropdownMenuItem>
                            {/* <DropdownMenuSeparator className="bg-[#2C2C2C]" /> */}
                            <DropdownMenuItem className="flex items-center">
                              <button onClick={() => handleRemove(item, chapterIdx, null)} className="flex items-center space-x-[1.2rem] text-[1rem] font-[500] text-[#242424] hover:text-black leading-normal w-full rounded-md p-1 hover:bg-[#e6e6e6] hover:rounded[1.25rem]">
                                <span>
                                  <img
                                    src={RemoveLogo}
                                    alt="logo"
                                    className="w-6 h-6"
                                  />
                                </span>
                                <span>Remove</span>
                              </button>
                            </DropdownMenuItem>
                          </DropdownMenuGroup>
                        </DropdownMenuContent>
                      )}
                    </DropdownMenu>
                  </div>
                )}
              </div>
            );
          })}
        </div>
        <div
          onMouseDown={handleMouseDown}
          onClick={resetWidth}
          className="opacity-0 group-hover/sidebar:opacity-100 transition cursor-ew-resize absolute h-full w-1 bg-tertiary/10 right-0 top-0"
        />
      </aside>

      <div
        ref={navbarRef}
        className={cn(
          "absolute top-0 z-[99999] left-60 w-[calc(100%-20rem)]",
          isResetting && "transition-all ease-in-out duration-300",
          isMobile && "left-0 w-full"
        )}
      >
        <nav className="bg-transparent px-3 py-2 w-full">
          {isCollapsed && (
            <MenuIcon
              onClick={resetWidth}
              role="button"
              className="h-6 w-6 text-muted-foreground"
            />
          )}
        </nav>
      </div>

      <Modal
        onOpened={openRenamePopup}
        onClose={() => setOpenRenamePopup(false)}
        title="Rename"
        height="calc(100%-400px)"
      >
        <div className="flex flex-col h-full">
          <div className="grow">
            <Text
              value={renamedTitle}
              onChange={(e) => setRenamedTitle(e.target.value)}
              labelTitle="Rename Title"
              autoFocus={true}
            />
          </div>
          <div className="pt-[2.31rem] pb-[0.81rem] space-x-[3.94rem] flex justify-center items-center">
            <button
              onClick={() => setOpenRenamePopup(false)}
              className="p-[0.72rem] border-2 border-tertiary rounded-[1.875rem]"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="33"
                height="33"
                viewBox="0 0 33 33"
                fill="none"
              >
                <path
                  d="M25.5 7.5L7.5 25.5"
                  stroke="#AB0273"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M25.5 25.5L7.5 7.5"
                  stroke="#AB0273"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
            <button
              onClick={() => {
                handleRename(renamedId, renamedTitle);
              }}
              className="p-[0.72rem] border-2 border-tertiary rounded-[1.875rem]"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="33"
                height="33"
                viewBox="0 0 33 33"
                fill="none"
              >
                <path
                  d="M27.5 9.50063L13.5 23.5L6.5 16.5006"
                  stroke="#AB0273"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default Navigation;
