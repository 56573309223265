import React, { useEffect, useState } from "react";
import { ChevronRight } from "@carbon/icons-react";
import { ChevronLeft } from "@carbon/icons-react";
import { useSelector } from "react-redux";
import Loader from "src/utils/Loader";
import { Module } from "src/features/trackedModules/trackedModulesSlice";

type Props = {
  data: any;
  title: string;
  handleSelectAircraft: (aircraft: any) => void;
};

export function SelectAircraft({ data, handleSelectAircraft, title }: Props) {
  const { modules, currentPath } = useSelector((state: any) => state.trackedModules);

  const { themeColor, headingFont, bodyFont, headingColor, thumbnailHeadingColor, thumbnailTitleColor } = useSelector((state: any) => state.programSettings);

  // useEffect(() => {
  //     const storedModules = sessionStorage.getItem("modules");
  //     if (storedModules) {
  //       dispatch(setModules(JSON.parse(storedModules)));
  //       dispatch(setCurrentPath([]));
  //     } else {
  //       console.log("here is the user data: ", data);
  //       const initialModules: Module[] = data.map((module: any) => {
  //         return {
  //           id: module.id,
  //           title: module.name,
  //           status: "gray",
  //           type: "module",
  //         }
  //       })
  //       sessionStorage.setItem("modules", JSON.stringify(initialModules));
  //       dispatch(setModules(initialModules))
  //       dispatch(setCurrentPath([]));
  //     }
  //   }, []);

  //   Code for PAGINATION : -->>
  //   const aircraftsPerPage = 5;

  //   const [currentPage, setCurrentPage] = useState(1);

  //   const startIndex = (currentPage - 1) * aircraftsPerPage;
  //   const endIndex = startIndex + aircraftsPerPage;

  //   // Get the users for the current page
  //   const displayedAircrafts = aircrafts.slice(startIndex, endIndex);

  //   const goToNextPage = () => {
  //     setCurrentPage((prevPage) => prevPage + 1);
  //   };

  //   const goToPreviousPage = () => {
  //     setCurrentPage((prevPage) => Math.max(prevPage - 1, 1)); // Ensure not to go below page 1
  //   };

const getModuleByPath = (modulesList: Module[], path: string[]): Module | null => {
    let currentModules = modulesList;
    let targetModule: Module | null = null;
  
    for (let id of path) {
      targetModule = currentModules.find(module => module.id === id) || null;
      
      if (!targetModule) return null; // Return null if any module in the path is missing
      
      currentModules = targetModule.children || []; // Move to the next level
    }
    
    return targetModule; // Return the final module
  };

  return (
    <div className={`grow w-full h-full flex flex-col space-y-[3rem] md:space-y-[3.4rem] lg:space-y-[5rem] ${bodyFont && `font-${bodyFont}`}`}>
      <div className={` text-[1.5rem] md:text-[1.7rem] lg:text-[2rem] font-semibold ${headingFont && `font-${headingFont}`}`}>
        <div 
        style={{color: headingColor, borderColor: headingColor}}
        className={`border-l-4 md:border-l-[7px] pl-[1rem] md:pl-[1.5rem] leading-normal ${!headingColor && "text-[#FFFFFF] border-[#FFFFFF]"}`}>
          {title}
        </div>
      </div>

      <div className="flex-1 min-h-0 overflow-y-auto no-scrollbar">
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-[2rem] gap-y-[3rem] lg:gap-y-[5rem] lg:gap-x-[4.69rem] pl-0 lg:pl-[1.5rem]">
          {data.filter((aircraft: any) => !aircraft.isVariant).map((aircraft: any) => {
            const currentModules = getModuleByPath(modules, currentPath)?.children;
            const moduleStatus = currentModules && currentModules.find((module: Module) => module.id === aircraft.id)?.status;
            return (
              <AircraftCard key={aircraft._id} status={moduleStatus} themeColor={themeColor} thumbnailHeadingColor={thumbnailHeadingColor} thumbnailTitleColor={thumbnailTitleColor} aircraft={aircraft} handleSelect={() => handleSelectAircraft(aircraft)} />
            )
          })}
        </div>
      </div>
    </div>
  );
}

const AircraftCard: React.FC<{ aircraft: any; status: string | undefined; themeColor: any; thumbnailHeadingColor: any, thumbnailTitleColor: any, handleSelect: () => void }> = ({ aircraft, status, themeColor, thumbnailHeadingColor, thumbnailTitleColor, handleSelect }) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const img = new Image();
    img.src = aircraft.file?.url ?? "";
    img.onload = () => setIsLoading(false);
  }, [aircraft.file?.url]);

  return (
    <button onClick={() => { handleSelect() }} className="relative flex flex-col">
      <div 
      style={{ borderColor: `${themeColor}CC` }}
      className={`w-full max-w-[16.875rem] h-[9.375rem] flex-shrink-0 overflow-hidden border-2 border-b-0 ${!themeColor && "border-[#2F0220]/80"}`}>
        {isLoading ? (
          <div className="w-full h-full flex items-center justify-center bg-gray-200">
            <Loader loading={isLoading} />
          </div>
        ) : (
          <img
            src={aircraft.file?.url ?? ""}
            alt={`Profile of ${aircraft.name}`}
            className="w-full h-full object-cover flex"
            loading="lazy"
          />
        )}
      </div>
      <div
        style={{ backgroundColor: `${themeColor}CC`, borderColor: `${themeColor}CC`, color: thumbnailTitleColor }}
        className={`flex w-full max-w-[16.875rem] p-[0.625rem] justify-center items-center flex-shrink-0 ${!thumbnailTitleColor && "text-[#FFFFFF]"} text-center font-semibold text-[1.25rem] border-2 border-t-0 ${!themeColor && "bg-[#2F0220]/80"} border-[#2F0220]/80`}
      >
        {aircraft.name}
      </div>
      <span className={`absolute top-2 left-2 h-4 w-4 rounded-full ${status && status === "gray" ? "bg-gray-500" : status === "orange" ? "bg-orange-500" : "bg-green-500"}`}></span>
    </button>
  );
};

{
  /* <div className="overflow-y-scroll no-scrollbar w-full h-full flex flex-col space-y-[3.69rem] pt-[1rem] md:pt-[2.12rem] px-[3.75rem] ">
  <div className="text-[#FFFFFF] text-[2rem] md:text-[2.5rem] lg:text-[3rem] font-semibold border-l-[6px] md:border-l-8 pl-[1.37rem]">
    {data.content.title}
  </div>

  <div className="grid grid-cols-12">
    <div className="relative col-span-12">
      <button
        className={`absolute top-1/2 left-0 text-[#FFFFFF] ${
          aircrafts.length > 5 ? "block" : "hidden"
        }`}
        onClick={goToPreviousPage}
        disabled={currentPage === 1}
      >
        <ChevronLeft size={40} className="font-bold" />
      </button>

      <div className="grid grid-cols-5 gap-x-[2.19rem]">
        {displayedAircrafts.map((aircraft: any) => (
          <AircraftCard key={aircraft._id} aircraft={aircraft} />
        ))}
      </div>

      <button
        className={`absolute top-1/2 right-0 text-[#FFFFFF] ${
          aircrafts.length > 5 ? "block" : "hidden"
        }`}
        onClick={goToNextPage}
        disabled={endIndex >= aircrafts.length}
      >
        <ChevronRight size={40} className="font-bold" />
      </button>
    </div>
  </div>
</div>; */
}
